import React from 'react';
import { Link } from 'react-router-dom';
import { useDarkMode } from '../contexts/DarkModeContext';
import { ChevronLeft } from 'lucide-react';

const TermsOfService = () => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-[#0A0A0A] text-white' : 'bg-white text-gray-900'}`}>
      {/* Back Button */}
      <div className="fixed top-0 left-0 z-10" style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)', paddingLeft: '1rem' }}>
        <Link
          to="/"
          className={`inline-flex items-center justify-center w-10 h-10 rounded-full transition-all duration-200 ${
            isDarkMode 
              ? 'bg-white/10 hover:bg-white/20' 
              : 'bg-black/5 hover:bg-black/10'
          }`}
        >
          <ChevronLeft className={isDarkMode ? 'text-white' : 'text-gray-900'} size={20} />
        </Link>
      </div>

      <div className="max-w-3xl mx-auto px-4 py-12" style={{ paddingTop: 'calc(env(safe-area-inset-top) + 3rem)' }}>
        <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
        
        <div className="space-y-6">
          <section>
            <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
            <p className="mb-3">Welcome to Ummahti. By using our app, you agree to these terms. Please read them carefully.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">2. Definitions</h2>
            <p className="mb-3">"App" refers to Ummahti, including both our mobile application and website.</p>
            <p className="mb-3">"Service" refers to the features and functionality provided through the App.</p>
            <p className="mb-3">"User" refers to any individual who accesses or uses the App.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">3. Use of Service</h2>
            <p className="mb-3">Ummahti is a prayer and lifestyle app designed to help Muslims maintain their daily prayers and religious practices. The app provides:</p>
            <ul className="list-disc pl-6 mb-3">
              <li>Prayer time calculations and notifications</li>
              <li>Qibla direction</li>
              <li>Islamic content and resources</li>
              <li>Community features</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">4. User Accounts</h2>
            <p className="mb-3">To use certain features of the App, you must create an account. You agree to:</p>
            <ul className="list-disc pl-6 mb-3">
              <li>Provide accurate information</li>
              <li>Maintain the security of your account</li>
              <li>Not share your account credentials</li>
              <li>Notify us of any unauthorized use</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">5. User Content</h2>
            <p className="mb-3">When you submit content to the App, you retain ownership but grant us a license to use, store, and share that content as necessary to provide our services.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">6. Acceptable Use</h2>
            <p className="mb-3">You agree not to:</p>
            <ul className="list-disc pl-6 mb-3">
              <li>Use the App for any illegal purpose</li>
              <li>Share inappropriate or harmful content</li>
              <li>Attempt to gain unauthorized access</li>
              <li>Interfere with the App's operation</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">7. Prayer Times and Religious Content</h2>
            <p className="mb-3">While we strive for accuracy in prayer times and religious content, we recommend verifying critical information with local religious authorities. Prayer times are calculated based on geographical location and selected calculation methods.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">8. Intellectual Property</h2>
            <p className="mb-3">The App and its original content are protected by copyright, trademark, and other laws. Our religious content is provided with utmost respect for Islamic traditions and sources.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">9. Third-Party Services</h2>
            <p className="mb-3">The App may integrate with third-party services. Your use of such services is subject to their respective terms and conditions.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">10. Disclaimer</h2>
            <p className="mb-3">The App is provided "as is" without warranties of any kind. We are not responsible for any errors or inaccuracies in prayer times or religious content.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">11. Limitation of Liability</h2>
            <p className="mb-3">To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the App.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">12. Changes to Terms</h2>
            <p className="mb-3">We may modify these terms at any time. Continued use of the App after changes constitutes acceptance of the new terms.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">13. Contact Information</h2>
            <p className="mb-3">For questions about these terms, please contact us at support@ummahti.com</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService; 