import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const ElegantLandscapeTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  // Use the hook
  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Handle background image slideshow
  useEffect(() => {
    if (!settings.simpleDashboard?.backgroundImages?.length) return;
    
    const interval = setInterval(() => {
      setPreviousBackgroundIndex(currentBackgroundIndex);
      setCurrentBackgroundIndex(prev => 
        (prev + 1) % settings.simpleDashboard.backgroundImages.length
      );
    }, (settings.simpleDashboard.slideshowDuration || 60) * 1000);

    return () => clearInterval(interval);
  }, [currentBackgroundIndex, settings.simpleDashboard?.backgroundImages, settings.simpleDashboard?.slideshowDuration]);

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Image */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Main Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/50 to-black/90 z-10" />

      {/* Content Wrapper */}
      <div className="relative h-full flex flex-col z-20">
        {/* Top Section */}
        <div className="flex-1 p-8 flex">
          {/* Left Column */}
          <div className="w-1/2 flex flex-col justify-between">
            {/* Location and Date Group */}
            <div className="space-y-4">
              {/* Location */}
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4 text-white/60" />
                <p className="text-base sm:text-lg xl:text-xl font-light text-white/60">
                  {settings.location.split(',')[0]}
                </p>
              </div>
              
              {/* Dates */}
              <div className="flex gap-8 mb-8">
                <SimpleDateCard 
                  dateTime={dateTime}
                  onDateChange={onDateChange}
                  render={({ gregorian, hijri }) => (
                    <>
                      <div>
                        <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Gregorian</p>
                        <p className="text-sm lg:text-base">{gregorian}</p>
                      </div>
                      <div>
                        <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Hijri</p>
                        <p className="text-sm lg:text-base">{hijri}</p>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            {/* Hadith */}
            <div className="flex flex-col items-center">
              <div className={`w-px h-16 bg-gradient-to-b from-transparent ${accentColor.via} to-transparent mb-6`}></div>
              <p className="text-4xl text-white/60 leading-relaxed font-uthmani text-center">
                {duas?.[currentDuaIndex]?.arabic}
              </p>
            </div>
          </div>

          {/* Right Column - Time */}
          <div className="w-1/2 flex flex-col items-end justify-between text-right">
            <div>
              <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-2 lg:mb-4">Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className="text-[12rem] font-thin tracking-tight leading-none">
                    {time}
                  </div>
                )}
              />
            </div>

            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div>
                  <div className={`inline-flex items-center gap-2 lg:gap-3 ${accentColor.bg} rounded-full px-3 py-1.5 lg:px-4 lg:py-2 mb-4 lg:mb-6`}>
                    <div className={`w-1.5 h-1.5 lg:w-2 lg:h-2 rounded-full ${accentColor.base}`}></div>
                    <p className={`${accentColor.base} text-xs sm:text-sm tracking-widest uppercase`}>
                      Next Prayer: {nextPrayer}
                    </p>
                  </div>
                  <div className={`text-[8rem] font-thin tracking-tight leading-none ${accentColor.base}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        {/* Prayer Times Bar */}
        <div className="h-40 border-t border-white/10">
          <div className="h-full flex">
            {/* Frosted Glass Panel */}
            <div className="flex-1 backdrop-blur-md bg-black/20">
              <div className="h-full grid grid-cols-5">
                <SimplePrayerTimesCard
                  prayerTimes={prayerTimes}
                  settings={settings}
                  render={({ prayers }) => (
                    <>
                      {prayers.map(([prayer, time], index) => {
                        const Icon = prayer === 'Fajr' ? Sunrise :
                                   prayer === 'Maghrib' ? Sunset :
                                   prayer === 'Isha' ? Moon : Sun;
                        
                        const isNext = prayer === nextPrayer;
                        const isLast = index === prayers.length - 1;
                        
                        return (
                          <div 
                            key={prayer} 
                            className={`flex flex-col items-center justify-center h-full ${
                              !isLast ? 'border-r border-white/10' : ''
                            } ${isNext ? `${accentColor.bg}` : ''}`}
                          >
                            <div className="flex items-center gap-2 mb-3">
                              <Icon className={`w-4 h-4 ${isNext ? accentColor.base : 'text-white/40'}`} />
                              <p className={`${isNext ? accentColor.base : 'text-white/40'} text-sm tracking-widest uppercase`}>
                                {prayer}
                              </p>
                            </div>
                            <p className={`text-3xl font-light ${isNext ? accentColor.base : 'text-white/40'}`}>
                              {time}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElegantLandscapeTemplate; 