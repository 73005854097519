import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import addDays from 'date-fns/addDays';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button, Label, Alert, TextInput } from 'flowbite-react';
import Select from 'react-select';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { HiPencil, HiTrash, HiCheck } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDarkMode } from '../contexts/DarkModeContext';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const goalTypes = [
  { value: 'performDhikr', label: 'Perform Dhikr', color: '#047857' },
  { value: 'readQuran', label: 'Read Quran', color: '#0369a1' },
  { value: 'memorizeQuran', label: 'Memorize Quran', color: '#7c3aed' },
  { value: 'learn99Names', label: 'Learn 99 Names of Allah', color: '#b91c1c' },
];

const dhikrOptions = [
  { value: 'fullTasbih', label: 'Full tasbih' },
  { value: 'subhanAllah33', label: 'Subhan Allah 33x' },
  { value: 'alhamdulillah33', label: 'Alhamdulillah 33x' },
  { value: 'allahuAkbar33', label: 'Allahu Akbar 33x' },
];

const quranReadOptions = [
  { value: 'read1Juz', label: 'Read 1 Juz' },
  { value: 'read5Verses', label: 'Read 5 verses' },
  { value: 'read10Verses', label: 'Read 10 verses' },
  { value: 'read15Verses', label: 'Read 15 verses' },
  { value: 'read20Verses', label: 'Read 20 verses' },
];

const namesOptions = [
  { value: 'practice10', label: 'Practice 10 names' },
  { value: 'practice20', label: 'Practice 20 names' },
  { value: 'practice30', label: 'Practice 30 names' },
  { value: 'practice40', label: 'Practice 40 names' },
  { value: 'practice50', label: 'Practice 50 names' },
  { value: 'practice60', label: 'Practice 60 names' },
  { value: 'practice70', label: 'Practice 70 names' },
  { value: 'practice80', label: 'Practice 80 names' },
  { value: 'practice90', label: 'Practice 90 names' },
  { value: 'practice99', label: 'Practice 99 names' },
];

const recurringOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'biweekly', label: 'Bi-weekly' },
];

const GoalPlanner = () => {
  const [events, setEvents] = useState([]);
  const { currentUser } = useAuth();
  const { control, handleSubmit, reset, setValue } = useForm();
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedGoalType, setSelectedGoalType] = useState(null);
  const { isDarkMode } = useDarkMode();

  // Custom styles for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#1A1A1A' : 'white',
      borderColor: state.isFocused ? '#10B981' : isDarkMode ? '#374151' : '#D1D5DB',
      boxShadow: state.isFocused ? '0 0 0 1px #10B981' : null,
      color: isDarkMode ? 'white' : 'black',
      '&:hover': {
        borderColor: '#10B981'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected 
        ? '#10B981' 
        : state.isFocused 
          ? isDarkMode ? '#1A1A1A' : '#E5E7EB'
          : isDarkMode ? '#111111' : null,
      color: state.isSelected 
        ? 'white' 
        : isDarkMode 
          ? 'white' 
          : 'black',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#111111' : 'white',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? 'white' : 'black',
    }),
  };

  useEffect(() => {
    const loadGoals = async () => {
      if (currentUser) {
        const userGoalsRef = doc(db, 'userGoals', currentUser.uid);
        try {
          const docSnap = await getDoc(userGoalsRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            // Convert Firestore Timestamps to Date objects
            const loadedEvents = data.goals.map(event => ({
              ...event,
              start: event.start.toDate(),
              end: event.end.toDate()
            }));
            setEvents(loadedEvents);
          }
        } catch (error) {
          console.error("Error loading goals:", error);
          setError("Failed to load goals. Please try refreshing the page.");
        }
      }
    };

    loadGoals();
  }, [currentUser]);

  const generateRecurringEvents = (goal, startDate, endDate, recurringType) => {
    const events = [];
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
    const lastDate = new Date(endDate);
    lastDate.setHours(23, 59, 59, 999);
    const recurringId = goal.recurringId || uuidv4();

    while (currentDate <= lastDate) {
      events.push({
        ...goal,
        id: uuidv4(),
        recurringId: recurringId,
        start: new Date(currentDate),
        end: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999),
        completed: false, // Add this line
      });

      switch (recurringType) {
        case 'daily':
          currentDate = addDays(currentDate, 1);
          break;
        case 'weekly':
          currentDate = addDays(currentDate, 7);
          break;
        case 'biweekly':
          currentDate = addDays(currentDate, 14);
          break;
        default:
          currentDate = addDays(currentDate, 1);
      }
    }

    return events;
  };

  const onSubmit = async (data) => {
    setError(null);
    if (!startDate || !endDate) {
      setError("Please select both start and end dates.");
      return;
    }

    if (endDate < startDate) {
      setError("End date must be after start date.");
      return;
    }

    const newGoal = {
      id: uuidv4(),
      title: data.goalType.value,
      allDay: true,
      recurringType: data.recurringType.value,
      goalDetails: data.goalDetails ? data.goalDetails.value : data.memorizeVerses,
    };

    try {
      const newEvents = generateRecurringEvents(
        newGoal,
        startDate,
        endDate,
        data.recurringType.value
      );

      const updatedEvents = [...events, ...newEvents];
      setEvents(updatedEvents);

      if (currentUser) {
        const userGoalsRef = doc(db, 'userGoals', currentUser.uid);
        await setDoc(userGoalsRef, { goals: updatedEvents }, { merge: true });
        console.log("Goals saved successfully");
      }

      reset();
      setDateRange([null, null]);
    } catch (error) {
      console.error("Error saving goals:", error);
      setError("An error occurred while saving your goal. Please try again.");
    }
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setValue('goalType', event.title);
    setValue('recurringType', event.recurringType);
    setDateRange([new Date(event.start), new Date(event.end)]);
    setIsEditPanelOpen(true);
  };

  const handleEditSubmit = async (data) => {
    if (!selectedEvent) return;

    // Remove all events with the same recurringId
    const updatedEvents = events.filter(e => e.recurringId !== selectedEvent.recurringId);

    const updatedEvent = {
      ...selectedEvent,
      title: data.goalType.value,
      recurringType: data.recurringType.value,
    };

    const newEvents = generateRecurringEvents(
      updatedEvent,
      startDate,
      endDate,
      data.recurringType.value
    );

    const finalEvents = [...updatedEvents, ...newEvents];
    setEvents(finalEvents);

    try {
      if (currentUser) {
        const userGoalsRef = doc(db, 'userGoals', currentUser.uid);
        await setDoc(userGoalsRef, { goals: finalEvents }, { merge: true });
        console.log("Goal updated successfully");
      }
      setIsEditPanelOpen(false);
      setSelectedEvent(null);
      reset();
      setDateRange([null, null]);
    } catch (error) {
      console.error("Error updating goal:", error);
      setError("An error occurred while updating your goal. Please try again.");
    }
  };

  const handleDeleteEvent = async () => {
    if (!selectedEvent) return;

    const updatedEvents = events.filter(e => e.id !== selectedEvent.id);
    await updateEvents(updatedEvents);
  };

  const handleDeleteAllEvents = async () => {
    if (!selectedEvent) return;

    const updatedEvents = events.filter(e => e.recurringId !== selectedEvent.recurringId);
    await updateEvents(updatedEvents);
  };

  const updateEvents = async (updatedEvents) => {
    setEvents(updatedEvents);
    try {
      if (currentUser) {
        const userGoalsRef = doc(db, 'userGoals', currentUser.uid);
        await setDoc(userGoalsRef, { goals: updatedEvents }, { merge: true });
        console.log("Goal(s) updated successfully");
      }
      closeEditPanel();
    } catch (error) {
      console.error("Error updating goal(s):", error);
      setError("An error occurred while updating your goal(s). Please try again.");
    }
  };

  const closeEditPanel = () => {
    setIsEditPanelOpen(false);
    setSelectedEvent(null);
    reset();
    setDateRange([null, null]);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const goalType = goalTypes.find(type => type.value === event.title);
    const backgroundColor = goalType ? goalType.color : '#047857';
    const style = {
      backgroundColor: event.completed ? '#10B981' : backgroundColor, // Change color if completed
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2px 5px',
    };
    return { style };
  };

  const EventComponent = ({ event }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <span>{event.title}</span>
      <div>
        {event.completed && <HiCheck className="mr-1" />}
        <HiPencil />
      </div>
    </div>
  );

  const getReadableGoalType = (goalType) => {
    const goal = goalTypes.find(g => g.value === goalType);
    return goal ? goal.label : goalType;
  };

  const getReadableGoalDetails = (goalType, goalDetails) => {
    switch (goalType) {
      case 'performDhikr':
        return dhikrOptions.find(o => o.value === goalDetails)?.label || goalDetails;
      case 'readQuran':
        return quranReadOptions.find(o => o.value === goalDetails)?.label || goalDetails;
      case 'learn99Names':
        return namesOptions.find(o => o.value === goalDetails)?.label || goalDetails;
      case 'memorizeQuran':
        return `${goalDetails} verses`;
      default:
        return goalDetails;
    }
  };

  const toggleEventCompletion = async (event) => {
    const updatedEvents = events.map(e => 
      e.id === event.id ? { ...e, completed: !e.completed } : e
    );
    setEvents(updatedEvents);
    await updateEvents(updatedEvents);
  };

  return (
    <div className={`container mx-auto ${isDarkMode ? 'bg-[#0A0A0A] text-white' : 'bg-gray-100 text-gray-900'} px-4 sm:px-6 lg:px-8 py-8`}>
      <h1 className="text-3xl font-bold mb-6">Goal Planner</h1>
      {error && (
        <Alert color="failure" className="mb-4">
          {error}
        </Alert>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className={`lg:col-span-1 transition-all duration-200 rounded-[24px] p-6 ${
          isDarkMode 
            ? 'bg-[#111111] shadow-lg shadow-black/5' 
            : 'bg-white shadow-sm'
        } flex flex-col h-full`}>
          <div className="flex-grow">
            <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Add New Goal</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div>
                <Label htmlFor="goalType" className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>Goal Type</Label>
                <Controller
                  name="goalType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={goalTypes}
                      styles={customStyles}
                      className="mt-1"
                      onChange={(option) => {
                        field.onChange(option);
                        setSelectedGoalType(option.value);
                      }}
                    />
                  )}
                />
              </div>
              
              {/* Goal Details */}
              <div className={`transition-all duration-300 ease-in-out ${selectedGoalType ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0 overflow-hidden'}`}>
                {selectedGoalType === 'performDhikr' && (
                  <div>
                    <Label htmlFor="goalDetails" className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>Dhikr Type</Label>
                    <Controller
                      name="goalDetails"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={dhikrOptions}
                          styles={customStyles}
                          className="mt-1"
                        />
                      )}
                    />
                  </div>
                )}
                
                {selectedGoalType === 'readQuran' && (
                  <div>
                    <Label htmlFor="goalDetails" className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>Reading Goal</Label>
                    <Controller
                      name="goalDetails"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={quranReadOptions}
                          styles={customStyles}
                          className="mt-1"
                        />
                      )}
                    />
                  </div>
                )}
                
                {selectedGoalType === 'learn99Names' && (
                  <div>
                    <Label htmlFor="goalDetails" className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>Names to Practice</Label>
                    <Controller
                      name="goalDetails"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={namesOptions}
                          styles={customStyles}
                          className="mt-1"
                        />
                      )}
                    />
                  </div>
                )}
                
                {selectedGoalType === 'memorizeQuran' && (
                  <div>
                    <Label htmlFor="memorizeVerses" className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>Number of Verses to Memorize</Label>
                    <Controller
                      name="memorizeVerses"
                      control={control}
                      rules={{ required: true, min: 1 }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          type="number"
                          min="1"
                          className={`mt-1 w-full px-4 py-2.5 rounded-xl border transition-colors ${
                            isDarkMode 
                              ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50' 
                              : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
                          }`}
                        />
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <Label htmlFor="dateRange" className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>Date Range</Label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select date range"
                  className={`w-full px-4 py-2.5 rounded-xl border transition-colors ${
                    isDarkMode 
                      ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50' 
                      : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
                  }`}
                />
              </div>
              <div>
                <Label htmlFor="recurringType" className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>Recurring</Label>
                <Controller
                  name="recurringType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={recurringOptions}
                      styles={customStyles}
                      className="mt-1"
                    />
                  )}
                />
              </div>
            </form>
          </div>
          <div className="pt-6">
            <Button type="submit" color="success" className="w-full" onClick={handleSubmit(onSubmit)}>
              Add Goal
            </Button>
          </div>
        </div>
        <div className={`lg:col-span-2 transition-all duration-200 rounded-[24px] p-6 ${
          isDarkMode 
            ? 'bg-[#111111] shadow-lg shadow-black/5' 
            : 'bg-white shadow-sm'
        }`}>
          <div className="p-6">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ 
                height: 500,
                backgroundColor: isDarkMode ? '#111111' : 'white',
                color: isDarkMode ? 'white' : 'black',
              }}
              className={isDarkMode ? 'dark-calendar' : ''}
              onSelectEvent={handleEventSelect}
              eventPropGetter={eventStyleGetter}
              components={{
                event: EventComponent,
                toolbar: props => (
                  <div className={`rbc-toolbar ${isDarkMode ? 'dark-toolbar' : ''}`}>
                    {/* ... default toolbar content ... */}
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
      
      {/* Goal Overview and Edit Panel */}
      <div className={`fixed inset-y-0 right-0 w-96 ${
        isDarkMode 
          ? 'bg-[#111111] border-l border-gray-800' 
          : 'bg-white border-l border-gray-200'
      } shadow-lg transform ${
        isEditPanelOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out z-50 overflow-y-auto`}>
        <div className="p-6 space-y-6">
          <h2 className="text-2xl font-bold">Goal Details</h2>
          {selectedEvent && (
            <div className="space-y-6">
              <div className={`bg-${isDarkMode ? '[#1A1A1A]' : 'green-50'} p-4 rounded-lg space-y-2`}>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}><strong>Type:</strong> {getReadableGoalType(selectedEvent.title)}</p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}><strong>Amount:</strong> {getReadableGoalDetails(selectedEvent.title, selectedEvent.goalDetails)}</p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}><strong>Start:</strong> {format(selectedEvent.start, 'PP')}</p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}><strong>End:</strong> {format(selectedEvent.end, 'PP')}</p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}><strong>Recurring:</strong> {selectedEvent.recurringType}</p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}><strong>Status:</strong> {selectedEvent.completed ? 'Completed' : 'Pending'}</p>
              </div>
              
              <div className={`${
                isDarkMode ? 'bg-[#111111]' : 'bg-white'
              } p-4 rounded-lg shadow-sm`}>
                <h3 className="text-xl font-semibold mb-4">Edit Goal</h3>
                <form onSubmit={handleSubmit(handleEditSubmit)} className="space-y-4">
                  <div className="flex flex-col">
                    <Label htmlFor="dateRange" className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>Date Range</Label>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                      placeholderText="Select date range"
                      className={`w-full px-4 py-2.5 rounded-xl border transition-colors ${
                        isDarkMode 
                          ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50' 
                          : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
                      }`}
                    />
                  </div>
                  <Button type="submit" color="success" className="w-full">Update Goal</Button>
                  <Button 
                    color={selectedEvent.completed ? "gray" : "success"} 
                    onClick={() => toggleEventCompletion(selectedEvent)}
                    className="w-full mt-4"
                  >
                    {selectedEvent.completed ? "Mark as Incomplete" : "Mark as Complete"}
                  </Button>
                </form>
              </div>
              
              <div className="flex space-x-2 px-4">
                <Button color="failure" onClick={handleDeleteEvent} className="flex-1">
                  <HiTrash className="mr-2" />
                  Delete event
                </Button>
                <Button color="failure" onClick={handleDeleteAllEvents} className="flex-1">
                  <HiTrash className="mr-2" />
                  Delete all
                </Button>
              </div>
            </div>
          )}
          <Button color="gray" onClick={closeEditPanel} className="w-full">Close</Button>
        </div>
      </div>
    </div>
  );
};

export default GoalPlanner;
