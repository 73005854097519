import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';
import { Mail, MessageSquare, Globe, ArrowRight } from 'lucide-react';

const ContactPage = () => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className={`transition-colors duration-200 ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      <div className={`${
        isDarkMode 
          ? 'bg-gradient-to-b from-[#0A0A0A] via-[#0A0A0A] to-[#041E1B]' 
          : 'bg-gradient-to-b from-gray-50 via-white to-emerald-50/30'
      }`}>
        {/* Hero Section */}
        <div className="relative pt-[72px] -mt-[72px] pb-0 overflow-hidden">
          {/* Background Effects */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute top-1/4 left-1/4 w-64 h-64 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float ${
              isDarkMode ? 'bg-emerald-900/20' : 'bg-emerald-200/30'
            }`} />
            <div className={`absolute bottom-1/4 right-1/4 w-96 h-96 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float-delayed ${
              isDarkMode ? 'bg-emerald-800/20' : 'bg-emerald-100/30'
            }`} />
          </div>

          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center max-w-3xl mx-auto pt-[96px] lg:pt-[192px]">
              <h1 className={`text-4xl lg:text-6xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Get in{' '}
                <span className={`inline-block bg-gradient-to-r ${
                  isDarkMode 
                    ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                    : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                }`}>Touch</span>
              </h1>
              <p className={`text-xl lg:text-2xl mb-12 leading-relaxed ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                We're here to help and answer any questions you might have
              </p>
            </div>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="py-6 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
              {/* Contact Form - Left Side */}
              <div className={`transition-all duration-200 rounded-[24px] p-8 ${
                isDarkMode 
                  ? 'bg-[#111111] shadow-lg shadow-black/5 border border-white/10' 
                  : 'bg-white shadow-sm border border-gray-200'
              }`}>
                <form className="space-y-6">
                  <div>
                    <label className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Name
                    </label>
                    <input
                      type="text"
                      className={`w-full px-4 py-3 rounded-xl border transition-colors ${
                        isDarkMode 
                          ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50' 
                          : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
                      }`}
                      placeholder="Your name"
                    />
                  </div>

                  <div>
                    <label className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Email
                    </label>
                    <input
                      type="email"
                      className={`w-full px-4 py-3 rounded-xl border transition-colors ${
                        isDarkMode 
                          ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50' 
                          : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
                      }`}
                      placeholder="your@email.com"
                    />
                  </div>

                  <div>
                    <label className={`block text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Message
                    </label>
                    <textarea
                      rows="6"
                      className={`w-full px-4 py-3 rounded-xl border transition-colors ${
                        isDarkMode 
                          ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50' 
                          : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
                      }`}
                      placeholder="How can we help?"
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className={`group w-full px-8 py-4 rounded-full text-lg font-medium transition-all duration-500 relative overflow-hidden ${
                      isDarkMode 
                        ? 'bg-gradient-to-r from-emerald-500 to-emerald-400 text-black hover:shadow-2xl hover:shadow-emerald-500/20' 
                        : 'bg-gradient-to-r from-gray-900 to-gray-800 text-white hover:shadow-2xl hover:shadow-gray-500/20'
                    }`}
                  >
                    <span className="flex items-center justify-center gap-2 relative z-10">
                      Send Message
                      <ArrowRight className="w-5 h-5 transition-transform duration-500 group-hover:translate-x-2" />
                    </span>
                  </button>
                </form>
              </div>

              {/* Contact Info - Right Side */}
              <div className={`h-full transition-all duration-200 rounded-[24px] p-8 ${
                isDarkMode 
                  ? 'bg-[#111111] shadow-lg shadow-black/5 border border-white/10' 
                  : 'bg-white shadow-sm border border-gray-200'
              }`}>
                <div className="flex flex-col h-full">
                  <h2 className={`text-2xl font-bold mb-6 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Other Ways to Connect</h2>

                  {/* Contact Methods - Now in a grid */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-auto">
                    {[
                      {
                        icon: <Mail className="w-6 h-6" />,
                        title: "Email Us",
                        description: "support@ummahti.com",
                        action: "Send an email"
                      },
                      {
                        icon: <Globe className="w-6 h-6" />,
                        title: "Follow Us",
                        description: "@ummahti",
                        action: "Visit profile"
                      }
                    ].map((method) => (
                      <div
                        key={method.title}
                        className={`flex items-start gap-4 p-4 rounded-xl transition-all duration-200 ${
                          isDarkMode 
                            ? 'hover:bg-white/5' 
                            : 'hover:bg-gray-50'
                        }`}
                      >
                        <div className={`w-12 h-12 rounded-xl flex items-center justify-center ${
                          isDarkMode ? 'bg-emerald-500/10 text-emerald-400' : 'bg-emerald-50 text-emerald-600'
                        }`}>
                          {method.icon}
                        </div>
                        <div className="flex-1">
                          <h3 className={`text-lg font-medium mb-1 ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          }`}>{method.title}</h3>
                          <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                            {method.description}
                          </p>
                          <a 
                            href="#" 
                            className={`inline-flex items-center mt-2 text-sm font-medium ${
                              isDarkMode ? 'text-emerald-400 hover:text-emerald-300' : 'text-emerald-600 hover:text-emerald-700'
                            }`}
                          >
                            {method.action}
                            <ArrowRight className="w-4 h-4 ml-1" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Join Community Section - Now at the bottom */}
                  <div className={`mt-auto pt-8 border-t ${
                    isDarkMode ? 'border-gray-800' : 'border-gray-100'
                  }`}>
                    <h3 className={`text-lg font-medium mb-2 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>Join Our Community</h3>
                    <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      Connect with other Muslims and stay updated with our latest features and announcements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage; 