import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

const DailyHadithCard = ({ dailyHadith, onNext, onPrevious, hideTitle, isLoading }) => {
  const { isDarkMode } = useDarkMode();
  
  // Safe handlers to prevent errors if props are missing
  const handleNext = (e) => {
    e.preventDefault();
    console.log('[DailyHadithCard] Next button clicked', { hasHandler: typeof onNext === 'function' });
    if (typeof onNext === 'function') {
      try {
        onNext();
      } catch (error) {
        console.error("[DailyHadithCard] Error in onNext handler:", error);
      }
    } else {
      console.warn("[DailyHadithCard] onNext handler is not defined");
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    console.log('[DailyHadithCard] Previous button clicked', { hasHandler: typeof onPrevious === 'function' });
    if (typeof onPrevious === 'function') {
      try {
        onPrevious();
      } catch (error) {
        console.error("[DailyHadithCard] Error in onPrevious handler:", error);
      }
    } else {
      console.warn("[DailyHadithCard] onPrevious handler is not defined");
    }
  };

  const renderContent = () => {
    if (isLoading || !dailyHadith) {
      return (
        <div className="animate-pulse space-y-8">
          {/* Arabic text placeholder */}
          <div className="relative mb-8">
            <div className="h-32 bg-gray-200 dark:bg-gray-800 rounded-lg" />
          </div>
          {/* Translation placeholder */}
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 dark:bg-gray-800 rounded w-3/4 mx-auto" />
            <div className="h-4 bg-gray-200 dark:bg-gray-800 rounded w-1/2 mx-auto" />
          </div>
        </div>
      );
    }

    return (
      <>
        {/* Arabic text with decorative elements */}
        <div className="relative mb-8">
          <div className={`absolute top-0 left-1/2 -translate-x-1/2 w-20 h-px
            bg-gradient-to-r from-transparent ${
              isDarkMode 
                ? 'via-emerald-500/50' 
                : 'via-emerald-600/30'
            } to-transparent`} />
          <p className={`text-4xl text-center font-['Decotype_Thuluth'] leading-loose py-2 mt-8 mb-8 ${
            isDarkMode ? 'text-white' : 'text-gray-800'
          }`}>
            {dailyHadith.arabic}
          </p>
          <div className={`absolute bottom-0 left-1/2 -translate-x-1/2 w-20 h-px
            bg-gradient-to-r from-transparent ${
              isDarkMode 
                ? 'via-emerald-500/50' 
                : 'via-emerald-600/30'
            } to-transparent`} />
        </div>

        {/* Translation with elegant typography */}
        <div className="space-y-4">
          <p className={`text-center text-lg font-light leading-relaxed ${
            isDarkMode ? 'text-gray-300' : 'text-gray-600'
          }`}>
            {dailyHadith.translation}
          </p>
          {dailyHadith.source && (
            <p className={`text-center text-sm font-medium ${
              isDarkMode ? 'text-emerald-400' : 'text-emerald-700'
            }`}>
              {dailyHadith.source}
            </p>
          )}
        </div>
      </>
    );
  };
  
  return (
    <div className="relative">
      <div className={`relative rounded-2xl overflow-hidden backdrop-blur-xl ${
        isDarkMode 
          ? 'bg-white/5' 
          : 'bg-white/60 shadow-[inset_0_0_1px_1px_rgba(255,255,255,0.9)]'
      }`}>
        {/* Header */}
        <div className="relative px-8 pt-6 pb-6">
          <div className="flex items-center space-x-2">
            <span className={`text-sm uppercase tracking-wider font-medium ${
              isDarkMode ? 'text-emerald-400' : 'text-emerald-700'
            }`}>
              Daily Hadith
            </span>
          </div>
        </div>

        {/* Content section */}
        <div className={`relative px-8 py-10 ${
          isDarkMode 
            ? '' 
            : 'bg-gradient-to-b from-white/40 to-transparent'
        }`}>
          {renderContent()}
        </div>

        {/* Footer navigation */}
        <div className={`px-8 py-6 border-t ${
          isDarkMode ? 'border-white/5' : 'border-gray-100'
        }`}>
          <div className="flex justify-between items-center">
            <button 
              onClick={handlePrevious}
              disabled={isLoading}
              className={`flex items-center space-x-2 transition-colors ${
                isDarkMode 
                  ? 'text-gray-400 hover:text-emerald-400'
                  : 'text-gray-500 hover:text-emerald-700'
              } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <CaretLeft size={20} weight="thin" />
              <span className="text-sm">Previous</span>
            </button>
            <button 
              onClick={handleNext}
              disabled={isLoading}
              className={`flex items-center space-x-2 transition-colors ${
                isDarkMode 
                  ? 'text-gray-400 hover:text-emerald-400'
                  : 'text-gray-500 hover:text-emerald-700'
              } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <span className="text-sm">Next</span>
              <CaretRight size={20} weight="thin" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyHadithCard;