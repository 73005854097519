import React from 'react';
import { Monitor } from 'lucide-react';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { dashboardTemplates } from '../../../config/dashboardTemplates';
import { TemplateGrid } from './components/TemplateGrid';
import { TemplateToggle } from './components/TemplateToggle';
import { sectionStyles } from './styles';

export const DisplaySettings = ({ 
  showImageTemplates, 
  setShowImageTemplates, 
  selectedTemplate, 
  setSelectedTemplate,
  onSave,
  settings
}) => {
  const { isDarkMode } = useDarkMode();
  const styles = sectionStyles(isDarkMode);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template.id);
    const newSettings = {
      ...settings.simpleDashboard,
      templateId: template.id,
      ...template.defaultSettings,
      userSettings: settings.simpleDashboard?.userSettings || {}
    };
    
    onSave(newSettings);
  };

  return (
    <section className={`${styles.container} relative z-0`}>
      <div className={styles.header}>
        <Monitor className={styles.headerIcon} />
        <h2 className={styles.headerText}>Display Settings</h2>
      </div>
      <div className={styles.card}>
        <TemplateToggle 
          showImageTemplates={showImageTemplates}
          setShowImageTemplates={setShowImageTemplates}
        />
        <TemplateGrid 
          templates={showImageTemplates ? dashboardTemplates.imageTemplates : dashboardTemplates.basicTemplates}
          selectedTemplate={selectedTemplate}
          onSelect={handleTemplateSelect}
        />
      </div>
    </section>
  );
}; 