const CACHE_NAME = 'ummahti-image-cache-v2';

// Add this function to clear the cache
export const clearImageCache = async () => {
  try {
    // Delete the entire cache
    await caches.delete(CACHE_NAME);
    console.log('Image cache cleared');
    return true;
  } catch (error) {
    console.error('Error clearing image cache:', error);
    return false;
  }
};

// Function to check if a URL has expired (Firebase URLs with token)
export const isUrlExpired = (url) => {
  try {
    // Check if it's a Firebase Storage URL with a token
    if (!url || typeof url !== 'string') return false;
    
    // If the URL contains a Firebase token, check its expiration
    if (url.includes('&token=') || url.includes('&Expires=')) {
      // Extract the token expiry parameter from Google Storage URLs
      let expiryTime;
      
      // Trying to extract the expiry time from the URL
      if (url.includes('&Expires=')) {
        // Format: &Expires=1615448400
        const match = url.match(/[&?]Expires=(\d+)/);
        if (match && match[1]) {
          expiryTime = parseInt(match[1], 10) * 1000; // Convert to milliseconds
        }
      } else if (url.includes('&token=')) {
        // Some Firebase URLs use tokens with embedded expiry
        // This is a more complex check but we'll assume most tokens expire in 1 hour
        const now = Date.now();
        // Most Firebase tokens expire in 1 hour, we'll check if it's been more than 50 minutes
        return now > (global._lastFirebaseUrlRefresh || 0) + 50 * 60 * 1000;
      }
      
      if (expiryTime) {
        return Date.now() > expiryTime;
      }
    }
    
    return false;
  } catch (error) {
    console.warn('Error checking URL expiry:', error);
    return false;
  }
};

export const cacheImage = async (url) => {
  if (!url || typeof url !== 'string') return url;
  
  try {
    const cache = await caches.open(CACHE_NAME);
    
    // Store when we last refreshed Firebase URLs
    if (url.includes('firebase') || url.includes('googleapis.com')) {
      global._lastFirebaseUrlRefresh = Date.now();
    }
    
    // Using no-cors mode to avoid CORS issues - note this returns an opaque response
    const response = await fetch(url, { 
      mode: 'no-cors',
      cache: 'no-store' // Bypass browser cache to ensure fresh content
    });
    
    if (response.ok || response.type === 'opaque') {
      await cache.put(url, response.clone());
      return url;
    } else {
      console.warn('Failed to cache image, server returned:', response.status);
      return url;
    }
  } catch (error) {
    console.error('Error caching image:', error);
    return url;
  }
};

export const getCachedImage = async (url) => {
  if (!url || typeof url !== 'string') return url;
  
  try {
    const cache = await caches.open(CACHE_NAME);
    const cachedResponse = await cache.match(url);
    
    // If we have a cached version and the URL hasn't expired, use it
    if (cachedResponse && !isUrlExpired(url)) {
      console.log('Using cached image:', url);
      return url;
    }

    // If not in cache or expired, fetch and cache it
    console.log('Fetching fresh image:', url);
    try {
      return await cacheImage(url);
    } catch (cacheError) {
      console.warn('Failed to cache image, using direct URL:', cacheError);
      // If caching fails, return the direct URL
      return url;
    }
  } catch (error) {
    console.error('Error getting cached image:', error);
    return url;
  }
};

// Clean old cached images - runs once a day
export const cleanImageCache = async () => {
  try {
    // Only clean once per day
    const lastClean = localStorage.getItem('lastCacheClean');
    const now = Date.now();
    
    if (lastClean && now - parseInt(lastClean, 10) < 24 * 60 * 60 * 1000) {
      return; // Skip if already cleaned in the last 24 hours
    }
    
    const cache = await caches.open(CACHE_NAME);
    const keys = await cache.keys();
    const oneWeekAgo = now - (7 * 24 * 60 * 60 * 1000);
    
    let cleanedCount = 0;
    for (const request of keys) {
      const response = await cache.match(request);
      const headers = response?.headers;
      const date = headers?.get('date');
      
      if (date && new Date(date).getTime() < oneWeekAgo) {
        await cache.delete(request);
        cleanedCount++;
      }
    }
    
    console.log(`Cleaned ${cleanedCount} old items from image cache`);
    localStorage.setItem('lastCacheClean', now.toString());
  } catch (error) {
    console.error('Error cleaning cache:', error);
  }
};

// Utility for caching and managing Firebase Storage URLs
class ImageCache {
  constructor() {
    this.cache = new Map();
    this.lastCleaned = Date.now();
    this.expiryCheckInterval = 60 * 60 * 1000; // Check expiry every hour
    this.expiryThreshold = 24 * 60 * 60 * 1000; // URLs older than 24 hours are considered expired
    
    // Try to load cache from localStorage
    try {
      const savedCache = localStorage.getItem('imageUrlCache');
      if (savedCache) {
        const { entries, lastCleaned } = JSON.parse(savedCache);
        
        if (entries && Array.isArray(entries)) {
          entries.forEach(([key, value]) => {
            if (key && value && typeof value === 'object') {
              this.cache.set(key, value);
            }
          });
        }
        
        if (lastCleaned) {
          this.lastCleaned = lastCleaned;
        }
        
        console.log(`Loaded ${this.cache.size} entries from image cache`);
        
        // Clean the cache on load if it's been a while
        if (Date.now() - this.lastCleaned > this.expiryCheckInterval) {
          this.cleanCache();
        }
      }
    } catch (error) {
      console.error('Error loading image cache from localStorage:', error);
    }
  }
  
  // Save the current cache to localStorage
  persistCache() {
    try {
      const cacheData = {
        entries: Array.from(this.cache.entries()),
        lastCleaned: this.lastCleaned
      };
      localStorage.setItem('imageUrlCache', JSON.stringify(cacheData));
    } catch (error) {
      console.error('Error saving image cache to localStorage:', error);
    }
  }
  
  // Check if a URL is a Firebase Storage URL
  isFirebaseStorageUrl(url) {
    return url && (
      url.includes('firebasestorage.googleapis.com') || 
      url.includes('.firebasestorage.app')
    );
  }
  
  // Check if a Firebase Storage URL is expired or will expire soon
  isUrlExpired(url) {
    if (!this.isFirebaseStorageUrl(url)) return false;
    
    try {
      const urlObj = new URL(url);
      const expiresParam = urlObj.searchParams.get('Expires');
      
      if (!expiresParam) return false;
      
      const expiresTimestamp = parseInt(expiresParam, 10) * 1000; // Convert to ms
      const now = Date.now();
      
      // Consider URLs that will expire in the next hour as "expired"
      const oneHourInMs = 60 * 60 * 1000;
      return now > (expiresTimestamp - oneHourInMs);
    } catch (e) {
      console.warn('Error parsing URL for expiration check:', e);
      return false;
    }
  }
  
  // Get a URL from the cache, considering expiration
  get(key) {
    if (!key) return null;
    
    const cachedData = this.cache.get(key);
    if (!cachedData) return null;
    
    // Check if the cached URL is expired
    const { url, timestamp } = cachedData;
    
    if (this.isUrlExpired(url)) {
      console.log(`Cached URL for ${key} is expired or expiring soon`);
      return null;
    }
    
    // Check if it's time to clean the cache
    if (Date.now() - this.lastCleaned > this.expiryCheckInterval) {
      this.cleanCache();
    }
    
    return url;
  }
  
  // Set a URL in the cache with the current timestamp
  set(key, url) {
    if (!key || !url) return;
    
    this.cache.set(key, {
      url,
      timestamp: Date.now()
    });
    
    // Persist the updated cache
    this.persistCache();
  }
  
  // Remove a key from the cache
  remove(key) {
    if (!key) return;
    this.cache.delete(key);
    this.persistCache();
  }
  
  // Clean expired entries from the cache
  cleanCache() {
    console.log('Cleaning image URL cache...');
    const now = Date.now();
    let removed = 0;
    
    for (const [key, data] of this.cache.entries()) {
      // Remove if:
      // 1. The data is older than the expiry threshold
      // 2. The URL is a Firebase Storage URL and it's expired
      const isOld = now - data.timestamp > this.expiryThreshold;
      const isExpired = this.isUrlExpired(data.url);
      
      if (isOld || isExpired) {
        this.cache.delete(key);
        removed++;
      }
    }
    
    console.log(`Removed ${removed} expired entries from image cache`);
    this.lastCleaned = now;
    this.persistCache();
  }
  
  // Clear the entire cache
  clear() {
    this.cache.clear();
    this.lastCleaned = Date.now();
    this.persistCache();
    console.log('Image cache cleared');
    return true;
  }
  
  // Get cache statistics
  getStats() {
    return {
      size: this.cache.size,
      lastCleaned: new Date(this.lastCleaned).toLocaleString()
    };
  }
}

// Create and export a singleton instance
const imageCache = new ImageCache();
export default imageCache; 