import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ChevronRight, Book, Settings } from 'lucide-react';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { motion } from 'framer-motion';
import { MobilePageTransition } from './MobilePageTransition';

const QuranNavigationPage = ({ 
  surahs, 
  onSurahSelect, 
  selectedSurah,
  getMemorizedCount,
  onSettingsClick,
  onClose,
  animate = undefined,
  isSettingsOpen = false,
  quranMode,
  getReadingProgress,
  lastReadingSession
}) => {
  const { isDarkMode } = useDarkMode();
  const [activeTab, setActiveTab] = useState('surah');
  const selectedSurahRef = useRef(null);
  const [localSelectedSurah, setLocalSelectedSurah] = useState(selectedSurah);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setLocalSelectedSurah(selectedSurah);
  }, [selectedSurah]);

  const handleSurahSelect = (surahId) => {
    setLocalSelectedSurah(surahId);
    onSurahSelect(surahId);
  };

  useEffect(() => {
    if (selectedSurahRef.current) {
      setTimeout(() => {
        selectedSurahRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }, 100);
    }
  }, []);

  // Filter surahs based on search query
  const filteredSurahs = useMemo(() => {
    return surahs.filter(surah => {
      const searchLower = searchQuery.toLowerCase();
      return (
        surah.name_simple.toLowerCase().includes(searchLower) ||
        surah.name_arabic.includes(searchQuery) ||
        surah.id.toString().includes(searchQuery)
      );
    });
  }, [surahs, searchQuery]);

  // Update the slideVariants to handle both cases
  const slideVariants = {
    enterFromTop: {
      y: '-100%',
      x: 0
    },
    enterFromLeft: {
      y: 0,
      x: '-100%'
    },
    visible: {
      y: 0,
      x: 0
    },
    exitToLeft: {
      y: 0,
      x: '-100%'
    }
  };

  // Navigation section component
  const MenuSection = ({ children }) => (
    <div className={`mx-4rounded-xl overflow-hidden ${
      isDarkMode ? 'bg-[#111111]' : 'bg-white'
    }`}>
      {children}
    </div>
  );

  // Menu item component
  const MenuItem = ({ 
    surah, 
    isActive, 
    onClick, 
    quranMode,
    getMemorizedCount,
    getReadingProgress 
  }) => {
    const getSubheaderText = () => {
      if (quranMode === 'memorization') {
        return `${getMemorizedCount(surah.id.toString())} verses memorized`;
      } else {
        const progress = getReadingProgress ? getReadingProgress(surah.id.toString()) : { readCount: 0 };
        return `${progress.readCount || 0} of ${surah.verses_count} verses read`;
      }
    };

    return (
      <button
        ref={isActive ? selectedSurahRef : null}
        onClick={onClick}
        className={`w-full flex items-center px-4 py-3.5 border-b transition-colors ${
          isDarkMode 
            ? 'border-gray-800 hover:bg-[#1A1A1A] active:bg-[#222222]' 
            : 'border-gray-100 hover:bg-gray-50 active:bg-gray-100'
        } ${isActive 
          ? isDarkMode 
            ? 'bg-emerald-500/10' 
            : 'bg-emerald-50'
          : ''
        }`}
      >
        <div className="flex items-center justify-between flex-1">
          <div className="flex flex-col items-start">
            <span className={`${isActive 
              ? isDarkMode 
                ? 'text-emerald-400' 
                : 'text-emerald-600'
              : isDarkMode 
                ? 'text-white' 
                : 'text-gray-900'
            }`}>
              {surah.id}. {surah.name_simple}
            </span>
            <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {getSubheaderText()}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <span className={`text-right font-arabic text-lg ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              {surah.name_arabic}
            </span>
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          </div>
        </div>
      </button>
    );
  };

  // Move formatLastRead inside the component
  const formatLastRead = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    
    if (diff < 60000) return 'Just now';
    if (diff < 3600000) return `${Math.floor(diff/60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff/3600000)}h ago`;
    return `${Math.floor(diff/86400000)}d ago`;
  };

  // Move ReadingPlanCTA inside to access surahs and formatLastRead
  const ReadingPlanCTA = ({ isDarkMode, lastSession }) => {
    if (!lastSession?.surahId || !surahs) return null;
    
    const surah = surahs.find(s => s.id.toString() === lastSession.surahId);
    if (!surah) return null;

    return (
      <div className={`relative overflow-hidden rounded-xl p-4 mb-4 border transition-all duration-200 ${
        isDarkMode ? 'border-emerald-500/10' : 'border-emerald-500/10'
      }`}>
        <div className="absolute inset-0">
          <img
            src="/images/headerbg.png"
            alt=""
            className="absolute inset-0 w-full h-full object-cover opacity-30 scale-150"
          />
          <div className={`absolute inset-0 bg-gradient-to-br ${
            isDarkMode
              ? 'from-emerald-600/30 via-emerald-600/20 to-transparent'
              : 'from-emerald-500/20 via-emerald-500/10 to-emerald-500/5'
          }`}></div>
        </div>

        <div className="relative z-10">
          <div className="flex items-center gap-2 mb-1">
            <Book className={isDarkMode ? 'text-emerald-400' : 'text-emerald-600'} size={16} />
            <h3 className={`text-sm font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Continue Reading</h3>
          </div>
          <p className={`text-xs ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            {surah?.name_simple} • Verse {lastSession.verseId?.split(':')[1] || '1'}
            <span className="ml-2 text-xs opacity-60">
              {formatLastRead(lastSession.timestamp)}
            </span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <motion.div
      {...(animate === true ? {
        variants: slideVariants,
        initial: "enterFromTop",
        animate: "visible",
        exit: "exitToLeft",
      } : animate === false ? {
        variants: slideVariants,
        initial: "enterFromLeft",
        animate: "visible",
        exit: "exitToLeft",
      } : {
        initial: false,
        animate: { x: 0, y: 0 }
      })}
      transition={{ 
        type: 'spring',
        bounce: 0,
        duration: 0.5
      }}
      className="fixed inset-0 z-40 flex flex-col"
    >
      <div className={`flex flex-col h-full overflow-hidden ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
        {/* Fixed Header Section */}
        <div 
          className="fixed top-0 left-0 right-0 z-50"
          style={{ 
            paddingBottom: '1rem',
            background: isDarkMode 
              ? 'linear-gradient(180deg, rgb(10, 10, 10) 0%, rgba(10, 10, 10, 0.95) 80%, rgba(10, 10, 10, 0) 100%)'
              : 'linear-gradient(180deg, rgb(249, 250, 251) 0%, rgba(249, 250, 251, 0.95) 80%, rgba(249, 250, 251, 0) 100%)'
          }}
        >
          <header className="relative px-4">
            <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="py-4">
              <div className="flex items-center justify-between">
                <h1 className={`text-2xl font-semibold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Quran</h1>
                <button
                  onClick={onSettingsClick}
                  className={`p-2 rounded-full transition-colors ${
                    isDarkMode 
                      ? 'hover:bg-gray-800 text-gray-400 hover:text-white' 
                      : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                  }`}
                >
                  <Settings className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* Search Input */}
            <div className="relative mb-4">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search surah by name or number..."
                className={`w-full h-[42px] px-4 rounded-xl border transition-all duration-200 ${
                  isDarkMode 
                    ? 'bg-[#111111]/80 border-white/5 text-white placeholder-gray-500 focus:border-emerald-500/50' 
                    : 'bg-white/90 border-black/5 text-gray-900 placeholder-gray-400 focus:border-emerald-500/50'
                } focus:ring-emerald-500 focus:border-emerald-500 outline-none backdrop-blur-sm`}
              />
            </div>

            {/* Tab Pills */}
            <div className="mb-4">
              <div className={`flex items-center px-4 py-2 rounded-full transition-all duration-300 mb-4 overflow-hidden ${
                isDarkMode ? 'bg-[#111111]' : 'bg-white'
              }`}>
                <div className="flex items-center rounded-full transition-all duration-300 relative w-full">
                  {['surah', 'juz', 'hizb'].map((tab) => (
                    <button 
                      key={tab}
                      onClick={() => setActiveTab(tab)} 
                      className={`flex-1 py-2.5 text-center rounded-full transition-all duration-300 capitalize relative z-10
                        ${activeTab === tab 
                          ? isDarkMode 
                            ? 'text-black font-medium' 
                            : 'text-white font-medium'
                          : isDarkMode 
                            ? 'text-gray-400 hover:text-white' 
                            : 'text-gray-600 hover:text-gray-900'
                        }`}
                    >
                      {tab}
                    </button>
                  ))}
                  {/* Animated background pill */}
                  <div 
                    className={`absolute inset-0 transition-all duration-500 ease-in-out rounded-full ${
                      isDarkMode 
                        ? 'bg-emerald-500 shadow-lg shadow-emerald-500/20' 
                        : 'bg-emerald-600'
                    }`}
                    style={{
                      left: `${(activeTab === 'surah' ? 0 : activeTab === 'juz' ? 33.33 : 66.66)}%`,
                      width: '33.33%',
                      transform: 'translateX(0)',
                    }}
                  />
                </div>
              </div>
            </div>
          </header>
        </div>

        {/* Scrollable Content Area */}
        <div 
          className="flex-1 overflow-y-auto"
          style={{ 
            height: '100%',
            paddingTop: 'calc(env(safe-area-inset-top) + 200px)',
            paddingBottom: '2rem'
          }}
        >
          {activeTab === 'surah' && (
            <MenuSection>
              {filteredSurahs.map((surah) => (
                <MenuItem
                  key={surah.id}
                  surah={surah}
                  isActive={localSelectedSurah === surah.id.toString()}
                  onClick={() => handleSurahSelect(surah.id.toString())}
                  quranMode={quranMode}
                  getMemorizedCount={getMemorizedCount}
                  getReadingProgress={getReadingProgress}
                />
              ))}
            </MenuSection>
          )}

          {activeTab === 'juz' && (
            <div className="flex justify-center items-center h-40 text-gray-500">
              Juz view coming soon
            </div>
          )}

          {activeTab === 'hizb' && (
            <div className="flex justify-center items-center h-40 text-gray-500">
              Hizb view coming soon
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default QuranNavigationPage; 