import React, { useState, useEffect } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { getCountdown } from '../../utils/prayerTimeUtils';

const SimplePrayerCountdownCard = ({ prayerTimes, render }) => {
  const { settings } = useSettings();
  const [countdown, setCountdown] = useState({ nextPrayer: "Unknown", countdown: "--:--:--" });

  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0 && settings?.tunedTimes) {
        const now = new Date();
        const newCountdown = getCountdown(prayerTimes.times, now, settings.tunedTimes);
        setCountdown(newCountdown);
      } else {
        setCountdown({ nextPrayer: "Loading...", countdown: "--:--:--" });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, settings?.tunedTimes]);

  return render({ 
    countdown: countdown.countdown,
    nextPrayer: countdown.nextPrayer 
  });
};

export default SimplePrayerCountdownCard; 