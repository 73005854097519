import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';
import { Shield, Lock, Eye } from 'lucide-react';

const PrivacyPolicyPage = () => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className={`transition-colors duration-200 ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      <div className={`${
        isDarkMode 
          ? 'bg-gradient-to-b from-[#0A0A0A] via-[#0A0A0A] to-[#041E1B]' 
          : 'bg-gradient-to-b from-gray-50 via-white to-emerald-50/30'
      }`}>
        {/* Hero Section */}
        <div className="relative pt-[72px] -mt-[72px] pb-20 overflow-hidden">
          {/* Background Effects */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute top-1/4 left-1/4 w-64 h-64 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float ${
              isDarkMode ? 'bg-emerald-900/20' : 'bg-emerald-200/30'
            }`} />
            <div className={`absolute bottom-1/4 right-1/4 w-96 h-96 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float-delayed ${
              isDarkMode ? 'bg-emerald-800/20' : 'bg-emerald-100/30'
            }`} />
          </div>

          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center max-w-3xl mx-auto pt-[96px] lg:pt-[192px]">
              <h1 className={`text-4xl lg:text-6xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Your Privacy is{' '}
                <span className={`inline-block bg-gradient-to-r ${
                  isDarkMode 
                    ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                    : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                }`}>Our Priority</span>
              </h1>
              <p className={`text-xl lg:text-2xl mb-12 leading-relaxed ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Learn how we protect your data and respect your privacy
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="py-6 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: <Shield className="w-8 h-8" />,
                  title: "Data Protection",
                  description: "Your data is encrypted and stored securely using industry-standard security measures."
                },
                {
                  icon: <Lock className="w-8 h-8" />,
                  title: "Privacy First",
                  description: "We collect only the minimal information necessary to provide our services."
                },
                {
                  icon: <Eye className="w-8 h-8" />,
                  title: "Transparency",
                  description: "Clear information about how we use and protect your data."
                }
              ].map((item) => (
                <div 
                  key={item.title}
                  className={`p-8 rounded-2xl transition-all duration-300 ${
                    isDarkMode 
                      ? 'bg-gradient-to-br from-white/10 to-white/5 hover:from-white/15 hover:to-white/10 border border-white/10' 
                      : 'bg-white hover:shadow-xl hover:shadow-emerald-100/50 border border-gray-200'
                  }`}
                >
                  <div className={`w-16 h-16 rounded-xl flex items-center justify-center mb-6 ${
                    isDarkMode ? 'bg-emerald-500/10 text-emerald-400' : 'bg-emerald-50 text-emerald-600'
                  }`}>
                    {item.icon}
                  </div>
                  <h3 className={`text-xl font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>{item.title}</h3>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {item.description}
                  </p>
                </div>
              ))}
            </div>

            <div className={`mt-16 transition-all duration-200 rounded-[24px] p-8 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5 border border-white/10' 
                : 'bg-white shadow-sm border border-gray-200'
            }`}>
              <div className="space-y-12">
                <section>
                  <h2 className={`text-2xl font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Information We Collect</h2>
                  <div className="space-y-4">
                    <p className={`text-lg leading-relaxed ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-600'
                    }`}>
                      We collect minimal information necessary to provide you with the best possible experience:
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                      <li className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                        Account information (email, name)
                      </li>
                      <li className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                        Prayer times preferences and location (with your permission)
                      </li>
                      <li className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                        App usage data to improve our services
                      </li>
                    </ul>
                  </div>
                </section>

                <section>
                  <h2 className={`text-2xl font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>How We Use Your Information</h2>
                  <div className="space-y-4">
                    {[
                      {
                        title: "Personalization",
                        description: "To provide accurate prayer times and customize your experience"
                      },
                      {
                        title: "Communication",
                        description: "To send important updates about our services"
                      },
                      {
                        title: "Improvement",
                        description: "To enhance our app and develop new features"
                      }
                    ].map((item) => (
                      <div key={item.title} className={`p-6 rounded-xl ${
                        isDarkMode ? 'bg-white/5' : 'bg-gray-50'
                      }`}>
                        <h3 className={`text-xl font-medium mb-2 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>{item.title}</h3>
                        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                          {item.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </section>

                <section>
                  <h2 className={`text-2xl font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Data Protection</h2>
                  <p className={`text-lg leading-relaxed ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    We implement industry-standard security measures to protect your personal information. Your data is encrypted and stored securely.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage; 