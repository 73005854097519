export const greetings = [
  "May Allah (SWT) bless your day with peace and productivity",
  "May Allah's (SWT) light guide your path today and always",
  "May your day be filled with the blessings of Allah (SWT)",
  "As-salaam-alaikum! May peace and mercy be upon you today",
  "May Allah (SWT) shower His infinite mercy upon your day",
  "May Allah (SWT) grant you strength and wisdom this blessed day",
  "Bismillah - Begin your day with the name of Allah (SWT)",
  "May Allah's (SWT) barakah fill your life with joy and purpose",
  "May Allah (SWT) ease your affairs and lighten your heart today",
  "May the Almighty protect you and guide your steps",
  "May Allah's (SWT) rahma bring tranquility to your soul",
  "Wishing you a day filled with Allah's (SWT) countless blessings",
  "May Allah (SWT) grant you success in your worldly affairs and akhirah",
  "May Allah's (SWT) grace illuminate your path today",
  "May Allah (SWT) bring peace to your heart and clarity to your mind",
  "May Allah's (SWT) wisdom guide your decisions today",
  "May Allah (SWT) strengthen your imaan throughout this day",
  "May Allah's (SWT) mercy accompany you in all your endeavors",
  "May the blessing of Allah (SWT) brighten your morning and evening",
  "May Allah (SWT) grant you the best of this world and the hereafter",
  "May Allah's (SWT) protection be with you throughout this day",
  "May Allah (SWT) fill your day with opportunities for good deeds",
  "May Allah (SWT) grant you the sweetness of iman today",
  "May Allah's (SWT) barakat bring abundance to your life",
  "May Allah (SWT) guide you to what is best for your deen and dunya",
  "May Allah's (SWT) peace descend upon your heart today",
  "May Allah (SWT) make your day a means of earning His pleasure",
  "May Allah's (SWT) nur illuminate your path in times of uncertainty",
  "May Allah (SWT) grant you the company of righteous friends today",
  "May Allah (SWT) make your tasks easy and rewarding",
  "May Allah's (SWT) blessings accompany your every step",
  "May Allah (SWT) grant you beneficial knowledge today",
  "May Allah (SWT) protect you from harm and guide you to good",
  "May Allah (SWT) fill your day with opportunities for worship",
  "May Allah's (SWT) mercy be your constant companion",
  "May Allah (SWT) grant you the strength to overcome challenges",
  "May Allah (SWT) make your day a source of reward",
  "May Allah's (SWT) guidance lead you to success",
  "May Allah (SWT) fill your heart with gratitude and contentment",
  "May Allah (SWT) bless your time with productivity and purpose",
  "May Allah's (SWT) help be with you in all your affairs",
  "May Allah (SWT) grant you the best of both worlds",
  "May Allah (SWT) make your day a means of drawing closer to Him",
  "May Allah's (SWT) peace descend upon your home and heart",
  "May Allah (SWT) grant you the wisdom to make right choices",
  "May Allah (SWT) protect you from everything that harms",
  "May Allah (SWT) make your day a source of continuous blessings",
  "May Allah's (SWT) light guide you through every moment",
  "May Allah (SWT) grant you success in your noble intentions",
  "May Allah (SWT) fill your day with moments of spiritual growth",
  "May Allah's (SWT) blessings be upon you morning and night"
];

export const getRandomGreeting = () => {
  return greetings[Math.floor(Math.random() * greetings.length)];
}; 