import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';
import DashboardLoadingScreen from './DashboardLoadingScreen';

const DeviceLinkingScreen = ({ code, isLoading }) => {
  const { isDarkMode } = useDarkMode();

  if (isLoading) {
    return <DashboardLoadingScreen />;
  }

  return (
    <div className="h-screen w-full overflow-hidden">
      <div className={`absolute inset-0 transition-colors duration-200 ${
        isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
      }`}>
        <div className="absolute inset-0">
          <div className={`absolute top-0 left-1/3 w-[800px] h-[800px] rounded-full mix-blend-soft-light blur-3xl animate-pulse transition-colors duration-200 ${
            isDarkMode ? 'bg-emerald-500/10' : 'bg-emerald-100/60'
          }`}></div>
          <div className={`absolute bottom-0 right-0 w-[800px] h-[800px] rounded-full mix-blend-soft-light blur-3xl transition-colors duration-200 ${
            isDarkMode ? 'bg-emerald-500/5' : 'bg-blue-50/40'
          }`}></div>
        </div>
      </div>

      <div className="relative h-full flex">
        <div className={`w-1/3 flex items-center justify-center transition-colors duration-200 ${
          isDarkMode 
            ? 'bg-[#0A2A1F]' 
            : 'bg-emerald-900'
        }`}>
          <div className="w-[40rem] h-[40rem] flex items-center justify-center">
            <img 
              src="/logo_adhan.png" 
              alt="Ummahti Logo" 
              className="w-[35rem] h-[35rem] object-contain"
            />
          </div>
        </div>

        <div className={`w-2/3 backdrop-blur-xl transition-colors duration-200 ${
          isDarkMode 
            ? 'bg-[#111111]/40' 
            : 'bg-white/40'
        }`}>
          <div className="h-full flex flex-col items-center justify-center p-8">
            <div className="max-w-3xl w-full space-y-8 text-center">
              <div className="space-y-3">
                <h2 className={`text-3xl sm:text-4xl font-light tracking-tight transition-colors duration-200 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Device Linking Required
                </h2>
                <p className={`text-base sm:text-lg transition-colors duration-200 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Go to <span className="font-medium">ummahti.net &gt; settings &gt; adhan clock</span> to link your clock
                </p>
              </div>

              <div className={`backdrop-blur-2xl rounded-3xl py-8 sm:py-12 px-6 sm:px-8 border shadow-2xl transition-all duration-200 ${
                isDarkMode 
                  ? 'bg-[#111111]/70 border-gray-800 shadow-emerald-500/5' 
                  : 'bg-white/70 border-white'
              }`}>
                <div className={`text-7xl sm:text-8xl lg:text-[10rem] font-extralight tracking-wider leading-none text-center transition-colors duration-200 ${
                  isDarkMode ? 'text-white' : 'text-gray-800'
                }`}>
                  {code || '------'}
                </div>
              </div>

              <div className="flex flex-col items-center gap-4">
                <div className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-emerald-600 animate-pulse"></div>
                  <p className={`text-sm sm:text-base tracking-wider transition-colors duration-200 ${
                    isDarkMode ? 'text-emerald-400' : 'text-emerald-700'
                  }`}>
                    Waiting for connection
                  </p>
                </div>
                <div className="w-32 h-px bg-gradient-to-r from-transparent via-emerald-500/30 to-transparent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceLinkingScreen; 