const LOG_LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3
};

// Get minimum log level from environment
const getMinLogLevel = () => {
  if (process.env.REACT_APP_LOG_LEVEL) {
    return LOG_LEVELS[process.env.REACT_APP_LOG_LEVEL.toUpperCase()] || LOG_LEVELS.ERROR;
  }
  // In production, only show errors by default
  return process.env.NODE_ENV === 'production' ? LOG_LEVELS.ERROR : LOG_LEVELS.DEBUG;
};

const logger = {
  debug: (...args) => {
    if (getMinLogLevel() <= LOG_LEVELS.DEBUG) {
      console.log('[DEBUG]', ...args);
    }
  },

  info: (...args) => {
    if (getMinLogLevel() <= LOG_LEVELS.INFO) {
      console.info('[INFO]', ...args);
    }
  },

  warn: (...args) => {
    if (getMinLogLevel() <= LOG_LEVELS.WARN) {
      console.warn('[WARN]', ...args);
    }
  },

  error: (...args) => {
    // Always log errors
    console.error('[ERROR]', ...args);
  },

  // For API related logs
  api: {
    request: (method, url, data) => {
      if (getMinLogLevel() <= LOG_LEVELS.DEBUG) {
        console.log(`[API Request] ${method} ${url}`, data || '');
      }
    },
    response: (method, url, response) => {
      if (getMinLogLevel() <= LOG_LEVELS.DEBUG) {
        console.log(`[API Response] ${method} ${url}`, response);
      }
    },
    error: (method, url, error) => {
      console.error(`[API Error] ${method} ${url}`, error);
    }
  },

  // For Firebase related logs
  firebase: {
    auth: (...args) => {
      if (getMinLogLevel() <= LOG_LEVELS.DEBUG) {
        console.log('[Firebase Auth]', ...args);
      }
    },
    firestore: (...args) => {
      if (getMinLogLevel() <= LOG_LEVELS.DEBUG) {
        console.log('[Firebase Firestore]', ...args);
      }
    },
    error: (...args) => {
      console.error('[Firebase Error]', ...args);
    }
  }
};

export default logger; 