import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useDarkMode } from '../contexts/DarkModeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, XCircle } from 'lucide-react';

const FeedbackForm = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const { currentUser } = useAuth();
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!message.trim()) {
      setErrorMessage('Please enter a message');
      setShowErrorToast(true);
      setTimeout(() => setShowErrorToast(false), 3000);
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      // Create the feedback document in Firestore
      await addDoc(collection(db, 'feedback'), {
        subject: subject.trim() || 'No Subject',
        message: message.trim(),
        email: email.trim() || (currentUser?.email || ''),
        userName: currentUser?.displayName || '',
        userId: currentUser?.uid || '',
        createdAt: serverTimestamp(),
        emailSent: false
      });
      
      setShowSuccessToast(true);
      setTimeout(() => setShowSuccessToast(false), 3000);
      
      // Reset form
      setSubject('');
      setMessage('');
      setEmail('');
      
      // Redirect after successful submission
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setErrorMessage('Failed to submit feedback. Please try again.');
      setShowErrorToast(true);
      setTimeout(() => setShowErrorToast(false), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`min-h-screen flex flex-col relative ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-100/40'
        }`} />
        <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-50/60'
        }`} />
        
        {/* Additional light mode decorative elements */}
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
      </div>

      {/* Header */}
      <header className="relative z-10 flex-none">
        <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
          <div className="flex items-center justify-between">
            <h1 className={`text-2xl font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Feedback</h1>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="relative z-10 flex-1 flex flex-col px-4 pb-20" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 80px)' }}>
        <div className={`flex-1 rounded-[24px] overflow-hidden backdrop-blur-xl ${
          isDarkMode 
            ? 'bg-white/[0.03] border border-white/[0.05]' 
            : 'bg-white/60 shadow-[inset_0_0_1px_1px_rgba(255,255,255,0.9)]'
        }`}>
          <div className="px-6 py-8">
            <p className={`text-sm mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              We value your input! Please share your thoughts, suggestions, or report any issues you've encountered.
            </p>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="subject" className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  Subject (optional)
                </label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className={`block w-full px-4 py-3 rounded-lg ${
                    isDarkMode 
                      ? 'bg-white/5 border-white/10 text-white placeholder-gray-500'
                      : 'bg-white/80 border-gray-200 text-gray-900 placeholder-gray-400'
                  } border focus:ring-emerald-500 focus:border-emerald-500`}
                  placeholder="What's this about?"
                />
              </div>
              
              {!currentUser?.email && (
                <div>
                  <label htmlFor="email" className={`block text-sm font-medium mb-1 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>
                    Your Email (optional)
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`block w-full px-4 py-3 rounded-lg ${
                      isDarkMode 
                        ? 'bg-white/5 border-white/10 text-white placeholder-gray-500'
                        : 'bg-white/80 border-gray-200 text-gray-900 placeholder-gray-400'
                    } border focus:ring-emerald-500 focus:border-emerald-500`}
                    placeholder="In case we need to follow up"
                  />
                </div>
              )}
              
              <div>
                <label htmlFor="message" className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  Message *
                </label>
                <textarea
                  id="message"
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className={`block w-full px-4 py-3 rounded-lg ${
                    isDarkMode 
                      ? 'bg-white/5 border-white/10 text-white placeholder-gray-500'
                      : 'bg-white/80 border-gray-200 text-gray-900 placeholder-gray-400'
                  } border focus:ring-emerald-500 focus:border-emerald-500`}
                  placeholder="Tell us what's on your mind..."
                  required
                ></textarea>
              </div>
              
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className={`px-5 py-2.5 rounded-lg transition-colors ${
                    isDarkMode
                      ? 'bg-white/5 hover:bg-white/10 text-gray-300'
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                  }`}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-5 py-2.5 rounded-lg transition-colors ${
                    isDarkMode
                      ? 'bg-emerald-600/80 hover:bg-emerald-600 text-white'
                      : 'bg-emerald-600 hover:bg-emerald-700 text-white'
                  }`}
                >
                  {isSubmitting ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Sending...
                    </span>
                  ) : (
                    'Send Feedback'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Success Toast */}
      <AnimatePresence>
        {showSuccessToast && (
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed bottom-24 left-0 right-0 mx-auto z-50"
            style={{ width: "max-content" }}
          >
            <div className={`flex items-center p-4 max-w-sm rounded-lg shadow-lg ${
              isDarkMode 
                ? 'bg-gray-800 text-green-300 border border-green-500/10' 
                : 'bg-white text-green-600 border border-green-100'
            }`}>
              <CheckCircle className="w-6 h-6 mr-2" />
              <span>Thank you for your feedback!</span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Error Toast */}
      <AnimatePresence>
        {showErrorToast && (
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed bottom-24 left-0 right-0 mx-auto z-50"
            style={{ width: "max-content" }}
          >
            <div className={`flex items-center p-4 max-w-sm rounded-lg shadow-lg ${
              isDarkMode 
                ? 'bg-gray-800 text-red-300 border border-red-500/10' 
                : 'bg-white text-red-600 border border-red-100'
            }`}>
              <XCircle className="w-6 h-6 mr-2" />
              <span>{errorMessage}</span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FeedbackForm; 