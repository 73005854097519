export const defaultSettings = {
  theme: 'light',
  dashboardColumns: 3,
  dashboardPadding: 'p-4',
  dashboardGap: 'gap-4',
  order: [
    { type: 'component', id: 'dateTime' },
    { type: 'component', id: 'prayerTimes' },
    { type: 'section', id: 'section1' },
    { type: 'component', id: 'dailyHadith' },
    { type: 'component', id: 'quranProgress' },
    { type: 'component', id: 'islamicNews' },
    { type: 'component', id: 'adhkarTracker' },
    { type: 'component', id: 'goals' }
  ],
  components: {
    dateTime: {
      name: 'Date & Time',
      enabled: true,
      width: 1,
      showGreeting: true,
      showDate: true,
      showHijriDate: true,
      showPrayerCountdown: true,
      greetingFontSize: 'text-xl',
      dateFontSize: 'text-lg',
      hijriDateFontSize: 'text-lg',
      timeFontSize: 'text-2xl',
      countdownFontSize: 'text-6xl',
      customStyles: {}
    },
    prayerTimes: {
      name: 'Prayer Times',
      enabled: true,
      width: 1,
      showTitle: true,
      showPlaceName: true,
      titleFontSize: 'text-xl',
      prayerNameFontSize: 'text-base',
      prayerTimeFontSize: 'text-base',
      locationFontSize: 'text-sm'
    },
    dailyDua: {
      name: 'Daily Dua',
      enabled: true,
      width: 1,
      showTitle: true,
      showButtons: true,
      showReference: true,
      showTranslation: true,
      arabicFontSize: 'text-2xl',
      translationFontSize: 'text-lg',
      customStyles: {}
    },
    dailyHadith: {
      name: 'Daily Hadith',
      enabled: true,
      width: 1,
      showTitle: true,
      showButtons: true,
      showSource: true,
      showTranslation: true,
      arabicFontSize: 'text-2xl',
      translationFontSize: 'text-lg',
      customStyles: {}
    },
    quranProgress: {
      name: 'Quran Progress',
      enabled: true,
      width: 1,
      showTitle: true,
      customStyles: {}
    },
    islamicNews: {
      name: 'Islamic News',
      enabled: true,
      width: 1,
      showTitle: true,
      customStyles: {}
    },
    adhkarTracker: {
      name: 'Adhkar Tracker',
      enabled: true,
      width: 1,
      showTitle: true,
      customStyles: {}
    },
    goals: {
      name: 'Goals',
      enabled: true,
      width: 1,
      showTitle: true,
      customStyles: {}
    },
    emptyColumn: {
      customStyles: {}
    }
  },
  columnSections: {
    section1: {
      id: 'section1',
      width: 2,
      backgroundImage: null,
      backgroundColor: null,
      fullHeight: false,
      components: [
        { type: 'component', id: 'dailyDua' }
      ]
    }
  },
};
