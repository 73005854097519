import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { useSettings } from '../../../contexts/SettingsContext';
import { Toast } from 'flowbite-react';
import { Check } from 'lucide-react';
import { DeviceConnection } from './DeviceConnection';
import { DisplaySettings } from './DisplaySettings';
import { BackgroundSettings } from './BackgroundSettings';
import { sectionStyles } from './styles';
import { clearImageCache } from '../../../utils/imageCache';

// Helper function for deep equality check of objects
const isEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (!obj1 || !obj2) return false;
  
  // Convert to JSON and compare
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch (e) {
    // If can't stringify (e.g., circular references), fall back to simple comparison
    return obj1 === obj2;
  }
};

const SimpleDashboardSettings = () => {
  const { isDarkMode } = useDarkMode();
  const { settings, updateSettings } = useSettings();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  
  // Track component initialization
  const isInitialMount = useRef(true);
  const settingsUpdateInProgress = useRef(false);
  const previousTemplateRef = useRef(null);
  
  // Initialize template state
  const [selectedTemplate, setSelectedTemplate] = useState(() => {
    const template = settings.simpleDashboard?.templateId || null;
    previousTemplateRef.current = template;
    console.log('Initializing selectedTemplate:', template);
    return template;
  });
  
  const [showImageTemplates, setShowImageTemplates] = useState(true);
  
  // Initialize selectedImages state only once on component mount
  const [selectedImages, setSelectedImages] = useState(() => {
    console.log('Initializing selectedImages from settings');
    return settings.simpleDashboard?.backgroundImages || [];
  });
  
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState('');
  const [toastMessage, setToastMessage] = useState('Settings saved successfully');

  // Update selectedTemplate when settings change, but only after initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    
    // Avoid updating if the change was triggered by this component
    if (settingsUpdateInProgress.current) {
      settingsUpdateInProgress.current = false;
      return;
    }
    
    // Only update selectedTemplate if it differs from current state
    const newTemplate = settings.simpleDashboard?.templateId || null;
    if (newTemplate !== selectedTemplate && newTemplate !== previousTemplateRef.current) {
      console.log('Updating template from settings:', newTemplate);
      setSelectedTemplate(newTemplate);
      previousTemplateRef.current = newTemplate;
    }
    
    // Don't update selectedImages here - that's managed by BackgroundSettings
  }, [settings.simpleDashboard, selectedTemplate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSaveSettings = useCallback((newSettings) => {
    console.log('Saving settings...', newSettings);
    
    // Mark that we're doing the update to avoid loops
    settingsUpdateInProgress.current = true;
    
    updateSettings({
      simpleDashboard: {
        ...settings.simpleDashboard,
        ...newSettings
      }
    });

    // If we're updating the template, update the local state too
    if (newSettings.templateId !== undefined) {
      previousTemplateRef.current = newSettings.templateId;
    }

    setToastMessage('Settings saved successfully');
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  }, [settings.simpleDashboard, updateSettings]);

  const handleSuccess = useCallback((message) => {
    setToastMessage(message || 'Operation completed successfully');
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  }, []);

  const handleError = useCallback((errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(''), 3000);
  }, []);

  // Mobile view
  if (isMobileView) {
    return (
      <div className={`min-h-screen pb-24 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
        <div className="max-w-xl mx-auto space-y-12">
          <DeviceConnection onError={handleError} />
          <DisplaySettings 
            showImageTemplates={showImageTemplates}
            setShowImageTemplates={setShowImageTemplates}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            onSave={handleSaveSettings}
            settings={settings}
          />
          {selectedTemplate && showImageTemplates && (
            <BackgroundSettings 
              selectedTemplate={selectedTemplate}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              onSave={handleSaveSettings}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          )}
        </div>

        {/* Toast Notification */}
        {showToast && (
          <div className="fixed bottom-20 right-5 z-50">
            <Toast>
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-emerald-100 text-emerald-500">
                <Check className="h-5 w-5" />
              </div>
              <div className="ml-3 text-sm font-normal">
                {toastMessage}
              </div>
            </Toast>
          </div>
        )}

        {/* Error Toast */}
        {error && (
          <div className="fixed bottom-20 right-5 z-50 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg">
            {error}
          </div>
        )}
      </div>
    );
  }

  // Desktop view
  return (
    <div className="space-y-12 py-8">
      <DeviceConnection onError={handleError} />
      <DisplaySettings 
        showImageTemplates={showImageTemplates}
        setShowImageTemplates={setShowImageTemplates}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        onSave={handleSaveSettings}
        settings={settings}
      />
      {selectedTemplate && showImageTemplates && (
        <BackgroundSettings 
          selectedTemplate={selectedTemplate}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          onSave={handleSaveSettings}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      )}

      {/* Toast Notification */}
      {showToast && (
        <div className="fixed bottom-20 right-5 z-50">
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-emerald-100 text-emerald-500">
              <Check className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">
              {toastMessage}
            </div>
          </Toast>
        </div>
      )}

      {/* Error Toast */}
      {error && (
        <div className="fixed bottom-20 right-5 z-50 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg">
          {error}
        </div>
      )}
    </div>
  );
};

export default SimpleDashboardSettings; 