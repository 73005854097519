// Utility function to initialize action logs for daily goal tracking
export function initializeActionLogsForGoal(startDate, endDate, expectedAmount) {
  const logs = {};
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);
  
  // Create a log entry for each day between startDate and endDate (inclusive)
  while (currentDate <= lastDate) {
    const dateStr = currentDate.toISOString().split("T")[0];
    logs[dateStr] = { expected: expectedAmount, completed: 0, timestamp: null };
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return logs;
} 