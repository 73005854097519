import React from 'react';
import { Palette } from 'lucide-react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';
import { ACCENT_COLORS } from '../../../../config/dashboardTemplates';
import { useSettings } from '../../../../contexts/SettingsContext';

export const ColorPicker = () => {
  const { isDarkMode } = useDarkMode();
  const { settings, updateSettings } = useSettings();
  const accentColor = settings.simpleDashboard?.accentColor || 'emerald';

  const handleColorChange = (colorId) => {
    updateSettings({
      simpleDashboard: {
        ...settings.simpleDashboard,
        accentColor: colorId
      }
    });
  };

  return (
    <div className="mb-8">
      <div className="flex items-center gap-3 mb-4">
        <Palette className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
        <h3 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Accent Color
        </h3>
      </div>
      <div className="flex gap-3">
        {Object.entries(ACCENT_COLORS).map(([colorId, color]) => (
          <button
            key={colorId}
            onClick={() => handleColorChange(colorId)}
            className={`w-10 h-10 rounded-xl transition-all duration-200 ${color.bg} ${
              accentColor === colorId ? `ring-2 ${color.base} ring-offset-2` : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
}; 