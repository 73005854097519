import React, { useState, useEffect } from 'react';
import { fetchDateTime } from '../../services/dateTimeService';

const SimpleDateCard = ({ dateTime, render, onDateChange }) => {
  const [currentDateTime, setCurrentDateTime] = useState(dateTime);

  useEffect(() => {
    setCurrentDateTime(dateTime);
  }, [dateTime]);

  useEffect(() => {
    const checkMidnight = async () => {
      const now = new Date();
      if (now.getHours() === 0 && now.getMinutes() === 0) {
        try {
          const newDateTime = await fetchDateTime();
          setCurrentDateTime(newDateTime);
          // Notify parent component of date change
          if (onDateChange) {
            onDateChange(newDateTime);
          }
        } catch (err) {
          console.error('Error updating date:', err);
        }
      }
    };

    const interval = setInterval(checkMidnight, 60000);
    checkMidnight();

    return () => clearInterval(interval);
  }, [onDateChange]);

  return render({
    gregorian: currentDateTime?.gregorian,
    hijri: currentDateTime?.hijri?.western
  });
};

export default SimpleDateCard; 