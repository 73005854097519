import React from 'react';
import { Modal } from 'flowbite-react';
import { X } from '@phosphor-icons/react';
import { motion, AnimatePresence } from 'framer-motion';

const WelcomeModal = ({ show, onClose, isDarkMode }) => {
  const isMobile = window.innerWidth < 768;

  if (isMobile) {
    return (
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "spring", damping: 25, stiffness: 200 }}
            className={`fixed inset-0 z-50 ${
              isDarkMode ? 'bg-[#111111]' : 'bg-white'
            }`}
            style={{ 
              paddingTop: 'env(safe-area-inset-top)',
              paddingBottom: 'env(safe-area-inset-bottom)',
              height: '100dvh' // dynamic viewport height
            }}
          >
            {/* Header */}
            <div className={`sticky top-0 flex items-center justify-between px-4 py-4 ${
              isDarkMode ? 'border-gray-800 bg-[#111111]' : 'border-gray-100 bg-white'
            } border-b`}>
              <div className="w-16" /> {/* Spacer for centering */}
              <h3 className={`text-lg font-medium ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Welcome to Ummahti
              </h3>
              <button
                onClick={onClose}
                className={`px-4 py-2 text-sm font-medium ${
                  isDarkMode 
                    ? 'text-emerald-400'
                    : 'text-emerald-600'
                }`}
              >
                Done
              </button>
            </div>

            {/* Content */}
            <div className={`overflow-y-auto px-6 py-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              <div className="space-y-6">
                <p>
                  Assalamu alaikum! We're thrilled to have you join our community. 🎉
                </p>
                
                <div className={`p-4 rounded-xl ${
                  isDarkMode 
                    ? 'bg-emerald-900/20 text-emerald-400'
                    : 'bg-emerald-50 text-emerald-600'
                }`}>
                  <p className="font-medium mb-2">📝 Beta Notice</p>
                  <p className="text-sm">
                    Ummahti is currently in beta, which means you'll notice things changing and improving quickly in the coming days. Your experience and feedback are invaluable to us in making Ummahti better for everyone.
                  </p>
                </div>

                <div>
                  <p className="mb-4">
                    We'd love to hear from you! If you:
                  </p>
                  <ul className="list-disc list-inside space-y-2 ml-4">
                    <li>Have any questions about using Ummahti</li>
                    <li>Notice any bugs or issues</li>
                    <li>Want to suggest new features or improvements</li>
                  </ul>
                </div>

                <p>
                  Please don't hesitate to reach out to us. Your feedback helps make Ummahti better for everyone.
                </p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  // Desktop modal
  return (
    <Modal
      show={show}
      onClose={onClose}
      size="xl"
      className="!z-50 h-screen flex items-center pt-12"
      position="center"
      theme={{
        content: {
          base: "relative flex max-h-[90dvh] flex-col rounded-3xl bg-white shadow dark:bg-gray-700",
          inner: "relative flex max-h-[90dvh] flex-col rounded-3xl bg-white shadow dark:bg-gray-700"
        }
      }}
    >
      <div className={`rounded-3xl overflow-hidden transition-colors duration-200 w-full max-w-3xl mx-auto ${
        isDarkMode ? 'bg-[#111111]' : 'bg-white'
      }`}>
        {/* Modal Header */}
        <div className={`flex items-center justify-between p-6 border-b ${
          isDarkMode ? 'border-gray-800' : 'border-gray-100'
        }`}>
          <h3 className={`text-xl font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Welcome to Ummahti! 🌟
          </h3>
          <button
            onClick={onClose}
            className={`p-2 rounded-full transition-colors ${
              isDarkMode 
                ? 'hover:bg-[#1A1A1A] text-gray-400 hover:text-white'
                : 'hover:bg-gray-100 text-gray-600'
            }`}
          >
            <X size={24} weight="thin" />
          </button>
        </div>

        {/* Modal Body - Same content as mobile */}
        <div className={`p-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          <div className="space-y-6">
            <p>
              Assalamu alaikum! We're thrilled to have you join our community. 🎉
            </p>
            
            <div className={`p-4 rounded-xl ${
              isDarkMode 
                ? 'bg-emerald-900/20 text-emerald-400'
                : 'bg-emerald-50 text-emerald-600'
            }`}>
              <p className="font-medium mb-2">📝 Beta Notice</p>
              <p className="text-sm">
                Ummahti is currently in beta, which means you'll notice things changing and improving quickly in the coming days. Your experience and feedback are invaluable to us in making Ummahti better for everyone.
              </p>
            </div>

            <div>
              <p className="mb-4">
                We'd love to hear from you! If you:
              </p>
              <ul className="list-disc list-inside space-y-2 ml-4">
                <li>Have any questions about using Ummahti</li>
                <li>Notice any bugs or issues</li>
                <li>Want to suggest new features or improvements</li>
              </ul>
            </div>

            <p>
              Please don't hesitate to reach out to us. Your feedback helps make Ummahti better for everyone.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal; 