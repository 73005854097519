import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const HorizontalAdhanClockTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Image */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/60 to-black/80 z-10" />

      {/* Main Content */}
      <div className="relative h-full flex flex-col z-20">
        {/* Prayer Times Bar - Moved to top */}
        <div className="w-full border-b border-white/10 bg-black/20 backdrop-blur-sm">
          <div className="container mx-auto">
            <div className="grid grid-cols-5 border-x border-white/10">
              <SimplePrayerTimesCard
                prayerTimes={prayerTimes}
                settings={settings}
                render={({ prayers }) => (
                  <>
                    {prayers.map(([prayer, time], index, array) => {
                      const Icon = prayer === 'Fajr' ? Sunrise :
                                 prayer === 'Maghrib' ? Sunset :
                                 prayer === 'Isha' ? Moon : Sun;
                      
                      const isNext = prayer === nextPrayer;
                      const isFirst = index === 0;
                      const isLast = index === array.length - 1;
                      
                      return (
                        <div 
                          key={prayer} 
                          className={`
                            py-12 px-8 
                            ${isNext ? accentColor.bg : ''} 
                            border-r
                            border-white/10
                            text-center
                          `}
                        >
                          <div className="flex items-center justify-center gap-3 mb-3">
                            <Icon className={`w-5 h-5 ${isNext ? accentColor.base : 'text-white/40'}`} />
                            <p className={`${isNext ? accentColor.base : 'text-white/40'} text-sm tracking-widest uppercase`}>
                              {prayer}
                            </p>
                          </div>
                          <p className={`text-4xl font-light ${isNext ? accentColor.base : 'text-white/40'}`}>
                            {time}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </div>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 px-8 flex flex-col">
          {/* Dua - Prominent placement */}
          <div className="my-16">
            <p className="text-2xl sm:text-3xl lg:text-5xl text-white/80 text-center leading-loose font-['Decotype_Thuluth'] mx-auto">
              {duas?.[currentDuaIndex]?.arabic}
            </p>
          </div>

          {/* Flexible Space */}
          <div className="flex-grow" />

          {/* Time and Countdown Row */}
          <div className="flex justify-between items-end">
            {/* Countdown */}
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div className="flex flex-col justify-end">
                  <div className="flex items-baseline gap-3 sm:gap-4 lg:gap-6 mb-2 lg:mb-4">
                    <p className="text-white/40 text-xs sm:text-sm lg:text-base tracking-widest uppercase">Time Until</p>
                    <p className={`text-lg sm:text-xl lg:text-3xl font-light ${accentColor.base}`}>{nextPrayer}</p>
                  </div>
                  <div className={`text-4xl sm:text-5xl lg:text-6xl xl:text-[8rem] font-thin tracking-tight leading-[0.75] pb-[0.15em] ${accentColor.base}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />

            {/* Current Time */}
            <div className="text-right flex flex-col justify-end">
              <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-2 lg:mb-4">Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className="text-5xl sm:text-6xl lg:text-8xl xl:text-[12rem] font-thin tracking-tight leading-[0.75]">
                    {time}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="grid grid-cols-3 border-t border-white/10">
          <SimpleDateCard 
            dateTime={dateTime}
            onDateChange={onDateChange}
            render={({ gregorian, hijri }) => (
              <>
                <div className="p-4 sm:p-6 lg:p-8 border-r border-white/10">
                  <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-1">Gregorian</p>
                  <p className="text-base sm:text-lg lg:text-xl">{gregorian}</p>
                </div>
                <div className="p-4 sm:p-6 lg:p-8 border-r border-white/10">
                  <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-1">Hijri</p>
                  <p className="text-base sm:text-lg lg:text-xl">{hijri}</p>
                </div>
              </>
            )}
          />
          {/* Location in bottom right - adjusted to align with date text */}
          <div className="p-4 sm:p-6 lg:p-8 text-right flex flex-col justify-end">
            <h1 className="text-lg sm:text-xl xl:text-2xl font-light leading-none mb-1">{settings.location}</h1>
            <p className="text-white/60 text-xs sm:text-sm">{settings.region} {settings.country}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalAdhanClockTemplate; 