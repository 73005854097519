// Surah information with name, number, and total verses
export const SURAHS = [
  { number: 1, name: "Al-Fatiha", nameArabic: "الفاتحة", totalVerses: 7 },
  { number: 2, name: "Al-Baqarah", nameArabic: "البقرة", totalVerses: 286 },
  { number: 3, name: "Al-Imran", nameArabic: "آل عمران", totalVerses: 200 },
  { number: 4, name: "An-Nisa", nameArabic: "النساء", totalVerses: 176 },
  { number: 5, name: "Al-Maidah", nameArabic: "المائدة", totalVerses: 120 },
  { number: 6, name: "Al-Anam", nameArabic: "الأنعام", totalVerses: 165 },
  { number: 7, name: "Al-Araf", nameArabic: "الأعراف", totalVerses: 206 },
  { number: 8, name: "Al-Anfal", nameArabic: "الأنفال", totalVerses: 75 },
  { number: 9, name: "At-Tawbah", nameArabic: "التوبة", totalVerses: 129 },
  { number: 10, name: "Yunus", nameArabic: "يونس", totalVerses: 109 },
  { number: 11, name: "Hud", nameArabic: "هود", totalVerses: 123 },
  { number: 12, name: "Yusuf", nameArabic: "يوسف", totalVerses: 111 },
  { number: 13, name: "Ar-Rad", nameArabic: "الرعد", totalVerses: 43 },
  { number: 14, name: "Ibrahim", nameArabic: "ابراهيم", totalVerses: 52 },
  { number: 15, name: "Al-Hijr", nameArabic: "الحجر", totalVerses: 99 },
  { number: 16, name: "An-Nahl", nameArabic: "النحل", totalVerses: 128 },
  { number: 17, name: "Al-Isra", nameArabic: "الإسراء", totalVerses: 111 },
  { number: 18, name: "Al-Kahf", nameArabic: "الكهف", totalVerses: 110 },
  { number: 19, name: "Maryam", nameArabic: "مريم", totalVerses: 98 },
  { number: 20, name: "Ta-Ha", nameArabic: "طه", totalVerses: 135 },
  { number: 21, name: "Al-Anbiya", nameArabic: "الأنبياء", totalVerses: 112 },
  { number: 22, name: "Al-Hajj", nameArabic: "الحج", totalVerses: 78 },
  { number: 23, name: "Al-Muminun", nameArabic: "المؤمنون", totalVerses: 118 },
  { number: 24, name: "An-Nur", nameArabic: "النور", totalVerses: 64 },
  { number: 25, name: "Al-Furqan", nameArabic: "الفرقان", totalVerses: 77 },
  { number: 26, name: "Ash-Shuara", nameArabic: "الشعراء", totalVerses: 227 },
  { number: 27, name: "An-Naml", nameArabic: "النمل", totalVerses: 93 },
  { number: 28, name: "Al-Qasas", nameArabic: "القصص", totalVerses: 88 },
  { number: 29, name: "Al-Ankabut", nameArabic: "العنكبوت", totalVerses: 69 },
  { number: 30, name: "Ar-Rum", nameArabic: "الروم", totalVerses: 60 },
  { number: 31, name: "Luqman", nameArabic: "لقمان", totalVerses: 34 },
  { number: 32, name: "As-Sajdah", nameArabic: "السجدة", totalVerses: 30 },
  { number: 33, name: "Al-Ahzab", nameArabic: "الأحزاب", totalVerses: 73 },
  { number: 34, name: "Saba", nameArabic: "سبإ", totalVerses: 54 },
  { number: 35, name: "Fatir", nameArabic: "فاطر", totalVerses: 45 },
  { number: 36, name: "Ya-Sin", nameArabic: "يس", totalVerses: 83 },
  { number: 37, name: "As-Saffat", nameArabic: "الصافات", totalVerses: 182 },
  { number: 38, name: "Sad", nameArabic: "ص", totalVerses: 88 },
  { number: 39, name: "Az-Zumar", nameArabic: "الزمر", totalVerses: 75 },
  { number: 40, name: "Ghafir", nameArabic: "غافر", totalVerses: 85 },
  { number: 41, name: "Fussilat", nameArabic: "فصلت", totalVerses: 54 },
  { number: 42, name: "Ash-Shura", nameArabic: "الشورى", totalVerses: 53 },
  { number: 43, name: "Az-Zukhruf", nameArabic: "الزخرف", totalVerses: 89 },
  { number: 44, name: "Ad-Dukhan", nameArabic: "الدخان", totalVerses: 59 },
  { number: 45, name: "Al-Jathiyah", nameArabic: "الجاثية", totalVerses: 37 },
  { number: 46, name: "Al-Ahqaf", nameArabic: "الأحقاف", totalVerses: 35 },
  { number: 47, name: "Muhammad", nameArabic: "محمد", totalVerses: 38 },
  { number: 48, name: "Al-Fath", nameArabic: "الفتح", totalVerses: 29 },
  { number: 49, name: "Al-Hujurat", nameArabic: "الحجرات", totalVerses: 18 },
  { number: 50, name: "Qaf", nameArabic: "ق", totalVerses: 45 },
  { number: 51, name: "Adh-Dhariyat", nameArabic: "الذاريات", totalVerses: 60 },
  { number: 52, name: "At-Tur", nameArabic: "الطور", totalVerses: 49 },
  { number: 53, name: "An-Najm", nameArabic: "النجم", totalVerses: 62 },
  { number: 54, name: "Al-Qamar", nameArabic: "القمر", totalVerses: 55 },
  { number: 55, name: "Ar-Rahman", nameArabic: "الرحمن", totalVerses: 78 },
  { number: 56, name: "Al-Waqiah", nameArabic: "الواقعة", totalVerses: 96 },
  { number: 57, name: "Al-Hadid", nameArabic: "الحديد", totalVerses: 29 },
  { number: 58, name: "Al-Mujadila", nameArabic: "المجادلة", totalVerses: 22 },
  { number: 59, name: "Al-Hashr", nameArabic: "الحشر", totalVerses: 24 },
  { number: 60, name: "Al-Mumtahanah", nameArabic: "الممتحنة", totalVerses: 13 },
  { number: 61, name: "As-Saff", nameArabic: "الصف", totalVerses: 14 },
  { number: 62, name: "Al-Jumuah", nameArabic: "الجمعة", totalVerses: 11 },
  { number: 63, name: "Al-Munafiqun", nameArabic: "المنافقون", totalVerses: 11 },
  { number: 64, name: "At-Taghabun", nameArabic: "التغابن", totalVerses: 18 },
  { number: 65, name: "At-Talaq", nameArabic: "الطلاق", totalVerses: 12 },
  { number: 66, name: "At-Tahrim", nameArabic: "التحريم", totalVerses: 12 },
  { number: 67, name: "Al-Mulk", nameArabic: "الملك", totalVerses: 30 },
  { number: 68, name: "Al-Qalam", nameArabic: "القلم", totalVerses: 52 },
  { number: 69, name: "Al-Haqqah", nameArabic: "الحاقة", totalVerses: 52 },
  { number: 70, name: "Al-Maarij", nameArabic: "المعارج", totalVerses: 44 },
  { number: 71, name: "Nuh", nameArabic: "نوح", totalVerses: 28 },
  { number: 72, name: "Al-Jinn", nameArabic: "الجن", totalVerses: 28 },
  { number: 73, name: "Al-Muzzammil", nameArabic: "المزمل", totalVerses: 20 },
  { number: 74, name: "Al-Muddaththir", nameArabic: "المدثر", totalVerses: 56 },
  { number: 75, name: "Al-Qiyamah", nameArabic: "القيامة", totalVerses: 40 },
  { number: 76, name: "Al-Insan", nameArabic: "الانسان", totalVerses: 31 },
  { number: 77, name: "Al-Mursalat", nameArabic: "المرسلات", totalVerses: 50 },
  { number: 78, name: "An-Naba", nameArabic: "النبإ", totalVerses: 40 },
  { number: 79, name: "An-Naziat", nameArabic: "النازعات", totalVerses: 46 },
  { number: 80, name: "Abasa", nameArabic: "عبس", totalVerses: 42 },
  { number: 81, name: "At-Takwir", nameArabic: "التكوير", totalVerses: 29 },
  { number: 82, name: "Al-Infitar", nameArabic: "الإنفطار", totalVerses: 19 },
  { number: 83, name: "Al-Mutaffifin", nameArabic: "المطففين", totalVerses: 36 },
  { number: 84, name: "Al-Inshiqaq", nameArabic: "الإنشقاق", totalVerses: 25 },
  { number: 85, name: "Al-Buruj", nameArabic: "البروج", totalVerses: 22 },
  { number: 86, name: "At-Tariq", nameArabic: "الطارق", totalVerses: 17 },
  { number: 87, name: "Al-Ala", nameArabic: "الأعلى", totalVerses: 19 },
  { number: 88, name: "Al-Ghashiyah", nameArabic: "الغاشية", totalVerses: 26 },
  { number: 89, name: "Al-Fajr", nameArabic: "الفجر", totalVerses: 30 },
  { number: 90, name: "Al-Balad", nameArabic: "البلد", totalVerses: 20 },
  { number: 91, name: "Ash-Shams", nameArabic: "الشمس", totalVerses: 15 },
  { number: 92, name: "Al-Lail", nameArabic: "الليل", totalVerses: 21 },
  { number: 93, name: "Ad-Duha", nameArabic: "الضحى", totalVerses: 11 },
  { number: 94, name: "Ash-Sharh", nameArabic: "الشرح", totalVerses: 8 },
  { number: 95, name: "At-Tin", nameArabic: "التين", totalVerses: 8 },
  { number: 96, name: "Al-Alaq", nameArabic: "العلق", totalVerses: 19 },
  { number: 97, name: "Al-Qadr", nameArabic: "القدر", totalVerses: 5 },
  { number: 98, name: "Al-Bayyinah", nameArabic: "البينة", totalVerses: 8 },
  { number: 99, name: "Az-Zalzalah", nameArabic: "الزلزلة", totalVerses: 8 },
  { number: 100, name: "Al-Adiyat", nameArabic: "العاديات", totalVerses: 11 },
  { number: 101, name: "Al-Qariah", nameArabic: "القارعة", totalVerses: 11 },
  { number: 102, name: "At-Takathur", nameArabic: "التكاثر", totalVerses: 8 },
  { number: 103, name: "Al-Asr", nameArabic: "العصر", totalVerses: 3 },
  { number: 104, name: "Al-Humazah", nameArabic: "الهمزة", totalVerses: 9 },
  { number: 105, name: "Al-Fil", nameArabic: "الفيل", totalVerses: 5 },
  { number: 106, name: "Quraish", nameArabic: "قريش", totalVerses: 4 },
  { number: 107, name: "Al-Maun", nameArabic: "الماعون", totalVerses: 7 },
  { number: 108, name: "Al-Kawthar", nameArabic: "الكوثر", totalVerses: 3 },
  { number: 109, name: "Al-Kafirun", nameArabic: "الكافرون", totalVerses: 6 },
  { number: 110, name: "An-Nasr", nameArabic: "النصر", totalVerses: 3 },
  { number: 111, name: "Al-Masad", nameArabic: "المسد", totalVerses: 5 },
  { number: 112, name: "Al-Ikhlas", nameArabic: "الإخلاص", totalVerses: 4 },
  { number: 113, name: "Al-Falaq", nameArabic: "الفلق", totalVerses: 5 },
  { number: 114, name: "An-Nas", nameArabic: "الناس", totalVerses: 6 }
];

// Hizb ranges with structured data for easier parsing
export const HIZB_RANGES = [
  {
    hizb: 1,
    range: {
      start: { surah: 1, name: "Al-Fatiha", verse: 1 },
      end: { surah: 2, name: "Al-Baqarah", verse: 74 }
    },
    fullRange: "Al-Fatiha 1:1 - Al-Baqarah 2:74"
  },
  {
    hizb: 2,
    range: {
      start: { surah: 2, name: "Al-Baqarah", verse: 75 },
      end: { surah: 2, name: "Al-Baqarah", verse: 141 }
    },
    fullRange: "Al-Baqarah 2:75 - 2:141"
  },
  {
    hizb: 3,
    range: {
      start: { surah: 2, name: "Al-Baqarah", verse: 142 },
      end: { surah: 2, name: "Al-Baqarah", verse: 202 }
    },
    fullRange: "Al-Baqarah 2:142 - 2:202"
  },
  {
    hizb: 4,
    range: {
      start: { surah: 2, name: "Al-Baqarah", verse: 203 },
      end: { surah: 2, name: "Al-Baqarah", verse: 252 }
    },
    fullRange: "Al-Baqarah 2:203 - 2:252"
  },
  {
    hizb: 5,
    range: {
      start: { surah: 2, name: "Al-Baqarah", verse: 253 },
      end: { surah: 3, name: "Al-Imran", verse: 14 }
    },
    fullRange: "Al-Baqarah 2:253 - Al-Imran 3:14"
  },
  {
    hizb: 6,
    range: {
      start: { surah: 3, name: "Al-Imran", verse: 15 },
      end: { surah: 3, name: "Al-Imran", verse: 92 }
    },
    fullRange: "Al-Imran 3:15 - 3:92"
  },
  {
    hizb: 7,
    range: {
      start: { surah: 3, name: "Al-Imran", verse: 93 },
      end: { surah: 3, name: "Al-Imran", verse: 170 }
    },
    fullRange: "Al-Imran 3:93 - 3:170"
  },
  {
    hizb: 8,
    range: {
      start: { surah: 3, name: "Al-Imran", verse: 171 },
      end: { surah: 4, name: "An-Nisa", verse: 23 }
    },
    fullRange: "Al-Imran 3:171 - An-Nisa 4:23"
  },
  {
    hizb: 9,
    range: {
      start: { surah: 4, name: "An-Nisa", verse: 24 },
      end: { surah: 4, name: "An-Nisa", verse: 87 }
    },
    fullRange: "An-Nisa 4:24 - 4:87"
  },
  {
    hizb: 10,
    range: {
      start: { surah: 4, name: "An-Nisa", verse: 88 },
      end: { surah: 4, name: "An-Nisa", verse: 147 }
    },
    fullRange: "An-Nisa 4:88 - 4:147"
  },
  {
    hizb: 11,
    range: {
      start: { surah: 4, name: "An-Nisa", verse: 148 },
      end: { surah: 5, name: "Al-Maidah", verse: 26 }
    },
    fullRange: "An-Nisa 4:148 - Al-Maidah 5:26"
  },
  {
    hizb: 12,
    range: {
      start: { surah: 5, name: "Al-Maidah", verse: 27 },
      end: { surah: 5, name: "Al-Maidah", verse: 81 }
    },
    fullRange: "Al-Maidah 5:27 - 5:81"
  },
  {
    hizb: 13,
    range: {
      start: { surah: 5, name: "Al-Maidah", verse: 82 },
      end: { surah: 6, name: "Al-Anam", verse: 35 }
    },
    fullRange: "Al-Maidah 5:82 - Al-Anam 6:35"
  },
  {
    hizb: 14,
    range: {
      start: { surah: 6, name: "Al-Anam", verse: 36 },
      end: { surah: 6, name: "Al-Anam", verse: 110 }
    },
    fullRange: "Al-Anam 6:36 - 6:110"
  },
  {
    hizb: 15,
    range: {
      start: { surah: 6, name: "Al-Anam", verse: 111 },
      end: { surah: 6, name: "Al-Anam", verse: 165 }
    },
    fullRange: "Al-Anam 6:111 - 6:165"
  },
  {
    hizb: 16,
    range: {
      start: { surah: 7, name: "Al-Araf", verse: 1 },
      end: { surah: 7, name: "Al-Araf", verse: 87 }
    },
    fullRange: "Al-Araf 7:1 - 7:87"
  },
  {
    hizb: 17,
    range: {
      start: { surah: 7, name: "Al-Araf", verse: 88 },
      end: { surah: 7, name: "Al-Araf", verse: 170 }
    },
    fullRange: "Al-Araf 7:88 - 7:170"
  },
  {
    hizb: 18,
    range: {
      start: { surah: 7, name: "Al-Araf", verse: 171 },
      end: { surah: 8, name: "Al-Anfal", verse: 40 }
    },
    fullRange: "Al-Araf 7:171 - Al-Anfal 8:40"
  },
  {
    hizb: 19,
    range: {
      start: { surah: 8, name: "Al-Anfal", verse: 41 },
      end: { surah: 9, name: "At-Tawbah", verse: 33 }
    },
    fullRange: "Al-Anfal 8:41 - At-Tawbah 9:33"
  },
  {
    hizb: 20,
    range: {
      start: { surah: 9, name: "At-Tawbah", verse: 34 },
      end: { surah: 9, name: "At-Tawbah", verse: 92 }
    },
    fullRange: "At-Tawbah 9:34 - 9:92"
  },
  {
    hizb: 21,
    range: {
      start: { surah: 9, name: "At-Tawbah", verse: 93 },
      end: { surah: 10, name: "Yunus", verse: 25 }
    },
    fullRange: "At-Tawbah 9:93 - Yunus 10:25"
  },
  {
    hizb: 22,
    range: {
      start: { surah: 10, name: "Yunus", verse: 26 },
      end: { surah: 11, name: "Hud", verse: 5 }
    },
    fullRange: "Yunus 10:26 - Hud 11:5"
  },
  {
    hizb: 23,
    range: {
      start: { surah: 11, name: "Hud", verse: 6 },
      end: { surah: 11, name: "Hud", verse: 83 }
    },
    fullRange: "Hud 11:6 - 11:83"
  },
  {
    hizb: 24,
    range: {
      start: { surah: 11, name: "Hud", verse: 84 },
      end: { surah: 12, name: "Yusuf", verse: 52 }
    },
    fullRange: "Hud 11:84 - Yusuf 12:52"
  },
  {
    hizb: 25,
    range: {
      start: { surah: 12, name: "Yusuf", verse: 53 },
      end: { surah: 13, name: "Ar-Rad", verse: 18 }
    },
    fullRange: "Yusuf 12:53 - Ar-Rad 13:18"
  },
  {
    hizb: 26,
    range: {
      start: { surah: 13, name: "Ar-Rad", verse: 19 },
      end: { surah: 14, name: "Ibrahim", verse: 52 }
    },
    fullRange: "Ar-Rad 13:19 - Ibrahim 14:52"
  },
  {
    hizb: 27,
    range: {
      start: { surah: 15, name: "Al-Hijr", verse: 1 },
      end: { surah: 16, name: "An-Nahl", verse: 50 }
    },
    fullRange: "Al-Hijr 15:1 - An-Nahl 16:50"
  },
  {
    hizb: 28,
    range: {
      start: { surah: 16, name: "An-Nahl", verse: 51 },
      end: { surah: 16, name: "An-Nahl", verse: 128 }
    },
    fullRange: "An-Nahl 16:51 - 16:128"
  },
  {
    hizb: 29,
    range: {
      start: { surah: 17, name: "Al-Isra", verse: 1 },
      end: { surah: 17, name: "Al-Isra", verse: 98 }
    },
    fullRange: "Al-Isra 17:1 - 17:98"
  },
  {
    hizb: 30,
    range: {
      start: { surah: 17, name: "Al-Isra", verse: 99 },
      end: { surah: 18, name: "Al-Kahf", verse: 74 }
    },
    fullRange: "Al-Isra 17:99 - Al-Kahf 18:74"
  },
  {
    hizb: 31,
    range: {
      start: { surah: 18, name: "Al-Kahf", verse: 75 },
      end: { surah: 19, name: "Maryam", verse: 98 }
    },
    fullRange: "Al-Kahf 18:75 - Maryam 19:98"
  },
  {
    hizb: 32,
    range: {
      start: { surah: 20, name: "Ta-Ha", verse: 1 },
      end: { surah: 20, name: "Ta-Ha", verse: 135 }
    },
    fullRange: "Ta-Ha 20:1 - 20:135"
  },
  {
    hizb: 33,
    range: {
      start: { surah: 21, name: "Al-Anbiya", verse: 1 },
      end: { surah: 21, name: "Al-Anbiya", verse: 112 }
    },
    fullRange: "Al-Anbiya 21:1 - 21:112"
  },
  {
    hizb: 34,
    range: {
      start: { surah: 22, name: "Al-Hajj", verse: 1 },
      end: { surah: 22, name: "Al-Hajj", verse: 78 }
    },
    fullRange: "Al-Hajj 22:1 - 22:78"
  },
  {
    hizb: 35,
    range: {
      start: { surah: 23, name: "Al-Muminun", verse: 1 },
      end: { surah: 24, name: "An-Nur", verse: 20 }
    },
    fullRange: "Al-Muminun 23:1 - An-Nur 24:20"
  },
  {
    hizb: 36,
    range: {
      start: { surah: 24, name: "An-Nur", verse: 21 },
      end: { surah: 25, name: "Al-Furqan", verse: 20 }
    },
    fullRange: "An-Nur 24:21 - Al-Furqan 25:20"
  },
  {
    hizb: 37,
    range: {
      start: { surah: 25, name: "Al-Furqan", verse: 21 },
      end: { surah: 26, name: "Ash-Shuara", verse: 110 }
    },
    fullRange: "Al-Furqan 25:21 - Ash-Shuara 26:110"
  },
  {
    hizb: 38,
    range: {
      start: { surah: 26, name: "Ash-Shuara", verse: 111 },
      end: { surah: 27, name: "An-Naml", verse: 55 }
    },
    fullRange: "Ash-Shuara 26:111 - An-Naml 27:55"
  },
  {
    hizb: 39,
    range: {
      start: { surah: 27, name: "An-Naml", verse: 56 },
      end: { surah: 28, name: "Al-Qasas", verse: 50 }
    },
    fullRange: "An-Naml 27:56 - Al-Qasas 28:50"
  },
  {
    hizb: 40,
    range: {
      start: { surah: 28, name: "Al-Qasas", verse: 51 },
      end: { surah: 29, name: "Al-Ankabut", verse: 45 }
    },
    fullRange: "Al-Qasas 28:51 - Al-Ankabut 29:45"
  },
  {
    hizb: 41,
    range: {
      start: { surah: 29, name: "Al-Ankabut", verse: 46 },
      end: { surah: 31, name: "Luqman", verse: 21 }
    },
    fullRange: "Al-Ankabut 29:46 - Luqman 31:21"
  },
  {
    hizb: 42,
    range: {
      start: { surah: 31, name: "Luqman", verse: 22 },
      end: { surah: 33, name: "Al-Ahzab", verse: 30 }
    },
    fullRange: "Luqman 31:22 - Al-Ahzab 33:30"
  },
  {
    hizb: 43,
    range: {
      start: { surah: 33, name: "Al-Ahzab", verse: 31 },
      end: { surah: 34, name: "Saba", verse: 23 }
    },
    fullRange: "Al-Ahzab 33:31 - Saba 34:23"
  },
  {
    hizb: 44,
    range: {
      start: { surah: 34, name: "Saba", verse: 24 },
      end: { surah: 36, name: "Ya-Sin", verse: 27 }
    },
    fullRange: "Saba 34:24 - Ya-Sin 36:27"
  },
  {
    hizb: 45,
    range: {
      start: { surah: 36, name: "Ya-Sin", verse: 28 },
      end: { surah: 37, name: "As-Saffat", verse: 144 }
    },
    fullRange: "Ya-Sin 36:28 - As-Saffat 37:144"
  },
  {
    hizb: 46,
    range: {
      start: { surah: 37, name: "As-Saffat", verse: 145 },
      end: { surah: 39, name: "Az-Zumar", verse: 31 }
    },
    fullRange: "As-Saffat 37:145 - Az-Zumar 39:31"
  },
  {
    hizb: 47,
    range: {
      start: { surah: 39, name: "Az-Zumar", verse: 32 },
      end: { surah: 40, name: "Ghafir", verse: 40 }
    },
    fullRange: "Az-Zumar 39:32 - Ghafir 40:40"
  },
  {
    hizb: 48,
    range: {
      start: { surah: 40, name: "Ghafir", verse: 41 },
      end: { surah: 41, name: "Fussilat", verse: 46 }
    },
    fullRange: "Ghafir 40:41 - Fussilat 41:46"
  },
  {
    hizb: 49,
    range: {
      start: { surah: 41, name: "Fussilat", verse: 47 },
      end: { surah: 43, name: "Az-Zukhruf", verse: 23 }
    },
    fullRange: "Fussilat 41:47 - Az-Zukhruf 43:23"
  },
  {
    hizb: 50,
    range: {
      start: { surah: 43, name: "Az-Zukhruf", verse: 24 },
      end: { surah: 45, name: "Al-Jathiyah", verse: 37 }
    },
    fullRange: "Az-Zukhruf 43:24 - Al-Jathiyah 45:37"
  },
  {
    hizb: 51,
    range: {
      start: { surah: 46, name: "Al-Ahqaf", verse: 1 },
      end: { surah: 48, name: "Al-Fath", verse: 17 }
    },
    fullRange: "Al-Ahqaf 46:1 - Al-Fath 48:17"
  },
  {
    hizb: 52,
    range: {
      start: { surah: 48, name: "Al-Fath", verse: 18 },
      end: { surah: 51, name: "Adh-Dhariyat", verse: 30 }
    },
    fullRange: "Al-Fath 48:18 - Adh-Dhariyat 51:30"
  },
  {
    hizb: 53,
    range: {
      start: { surah: 51, name: "Adh-Dhariyat", verse: 31 },
      end: { surah: 54, name: "Al-Qamar", verse: 55 }
    },
    fullRange: "Adh-Dhariyat 51:31 - Al-Qamar 54:55"
  },
  {
    hizb: 54,
    range: {
      start: { surah: 55, name: "Ar-Rahman", verse: 1 },
      end: { surah: 57, name: "Al-Hadid", verse: 29 }
    },
    fullRange: "Ar-Rahman 55:1 - Al-Hadid 57:29"
  },
  {
    hizb: 55,
    range: {
      start: { surah: 58, name: "Al-Mujadila", verse: 1 },
      end: { surah: 61, name: "As-Saff", verse: 14 }
    },
    fullRange: "Al-Mujadila 58:1 - As-Saff 61:14"
  },
  {
    hizb: 56,
    range: {
      start: { surah: 62, name: "Al-Jumuah", verse: 1 },
      end: { surah: 66, name: "At-Tahrim", verse: 12 }
    },
    fullRange: "Al-Jumuah 62:1 - At-Tahrim 66:12"
  },
  {
    hizb: 57,
    range: {
      start: { surah: 67, name: "Al-Mulk", verse: 1 },
      end: { surah: 71, name: "Nuh", verse: 28 }
    },
    fullRange: "Al-Mulk 67:1 - Nuh 71:28"
  },
  {
    hizb: 58,
    range: {
      start: { surah: 72, name: "Al-Jinn", verse: 1 },
      end: { surah: 77, name: "Al-Mursalat", verse: 50 }
    },
    fullRange: "Al-Jinn 72:1 - Al-Mursalat 77:50"
  },
  {
    hizb: 59,
    range: {
      start: { surah: 78, name: "An-Naba", verse: 1 },
      end: { surah: 86, name: "At-Tariq", verse: 17 }
    },
    fullRange: "An-Naba 78:1 - At-Tariq 86:17"
  },
  {
    hizb: 60,
    range: {
      start: { surah: 87, name: "Al-Ala", verse: 1 },
      end: { surah: 114, name: "An-Nas", verse: 6 }
    },
    fullRange: "Al-Ala 87:1 - An-Nas 114:6"
  }
];

// Helper functions for working with Quran data
export const quranHelpers = {
  // Find which hizb a verse belongs to
  findHizbForVerse: (surahNumber, verseNumber) => {
    return HIZB_RANGES.find(hizb => {
      const start = hizb.range.start;
      const end = hizb.range.end;
      
      if (surahNumber < start.surah || surahNumber > end.surah) return false;
      if (surahNumber === start.surah && verseNumber < start.verse) return false;
      if (surahNumber === end.surah && verseNumber > end.verse) return false;
      
      return true;
    });
  },

  // Get total verses in a range
  getVerseCount: (startSurah, startVerse, endSurah, endVerse) => {
    if (startSurah === endSurah) {
      return endVerse - startVerse + 1;
    }
    
    let count = 0;
    for (let i = startSurah; i <= endSurah; i++) {
      const surah = SURAHS.find(s => s.number === i);
      if (!surah) continue;
      
      if (i === startSurah) {
        count += surah.totalVerses - startVerse + 1;
      } else if (i === endSurah) {
        count += endVerse;
      } else {
        count += surah.totalVerses;
      }
    }
    return count;
  },

  // Format a verse range for display
  formatVerseRange: (start, end) => {
    if (start.surah === end.surah) {
      return `${start.name} ${start.verse} to ${end.verse}`;
    }
    return `${start.name} ${start.verse} to ${end.name} ${end.verse}`;
  }
};

// Quran structure constants
export const QURAN_STRUCTURE = {
  TOTAL_SURAHS: 114,
  TOTAL_JUZS: 30,
  TOTAL_HIZBS: 60,
  VERSES_PER_PAGE: 15, // Approximate
  PAGES_PER_JUZ: 20,  // Approximate
  HIZBS_PER_JUZ: 2
}; 