import React from 'react';
import { Link } from 'react-router-dom';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { 
  BookOpen, 
  HandHeart, 
  Target,
  List,
  ChartLineUp
} from '@phosphor-icons/react';
import { TasbihIcon, Quran01Icon, RamadhanMonthIcon, DuaIcon } from "hugeicons-react";

const DeenHelpers = () => {
  const { isDarkMode } = useDarkMode();

  const helpers = [
    {
      title: 'Quran Reader',
      description: 'Read, reflect, and track your daily Quran reading progress',
      icon: Quran01Icon,
      link: '/quran',
      color: 'emerald'
    },
    {
      title: 'Digital Tasbih',
      description: 'Keep track of your dhikr with an elegant digital counter',
      icon: TasbihIcon,
      link: '/dhikr-helper',
      color: 'emerald'
    },
    {
      title: 'Goal Planner',
      description: 'Set and track your spiritual goals',
      icon: RamadhanMonthIcon,
      link: '/mobile-goals',
      color: 'emerald'
    },
    {
      title: 'Analytics',
      description: 'Track your progress and view detailed insights',
      icon: ChartLineUp,
      link: '/analytics',
      color: 'emerald'
    }
  ];

  return (
    <div className={`min-h-screen relative overflow-hidden ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-100/40'
        }`} />
        <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-50/60'
        }`} />
        
        {/* Additional light mode decorative elements */}
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
      </div>

      {/* Header */}
      <header className="sticky top-0 z-40">
        <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
          <div className="flex items-center justify-between">
            <h1 className={`text-2xl font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Deen Helpers</h1>
          </div>
        </div>
      </header>

      {/* Content */}
      <div className="relative z-10 px-4 py-6">
        <div className="grid grid-cols-2 gap-4">
          {helpers.map((helper, index) => {
            const Icon = helper.icon;
            return (
              helper.isComingSoon ? (
                <div
                  key={index}
                  className={`group relative overflow-hidden rounded-2xl p-4 transition-all duration-300 ${
                    isDarkMode 
                      ? 'bg-white/5 backdrop-blur-xl' 
                      : 'bg-white/60 backdrop-blur-xl'
                  }`}
                >
                  <div className="relative z-10">
                    <div className={`w-12 h-12 mb-4 rounded-xl flex items-center justify-center ${
                      isDarkMode
                        ? 'bg-emerald-500/10'
                        : 'bg-emerald-50'
                    }`}>
                      <Icon size={24} weight="thin" className={`w-6 h-6 ${
                        isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                      }`} />
                    </div>
                    <h3 className={`text-base font-medium mb-1 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {helper.title}
                    </h3>
                    <p className={`text-sm ${
                      isDarkMode ? 'text-white/60' : 'text-gray-600'
                    }`}>
                      {helper.description}
                    </p>
                  </div>
                </div>
              ) : (
                <Link
                  key={index}
                  to={helper.link}
                  className={`group relative overflow-hidden rounded-2xl p-4 transition-all duration-300 ${
                    isDarkMode 
                      ? 'bg-white/5 hover:bg-white/10 backdrop-blur-xl' 
                      : 'bg-white/60 hover:bg-white/80 backdrop-blur-xl'
                  }`}
                >
                  <div className="relative z-10">
                    <div className={`w-12 h-12 mb-4 rounded-xl flex items-center justify-center transition-transform duration-300 group-hover:scale-110 ${
                      isDarkMode
                        ? 'bg-emerald-500/10'
                        : 'bg-emerald-50'
                    }`}>
                      <Icon size={24} weight="thin" className={`w-6 h-6 ${
                        isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                      }`} />
                    </div>
                    <h3 className={`text-base font-medium mb-1 ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {helper.title}
                    </h3>
                    <p className={`text-sm ${
                      isDarkMode ? 'text-white/60' : 'text-gray-600'
                    }`}>
                      {helper.description}
                    </p>
                  </div>
                </Link>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DeenHelpers; 