import axios from 'axios';
import { SURAHS } from '../constants/quranConstants';

const API_URL = 'https://api.quran.com/api/v4';
export const AUDIO_BASE_URL = 'https://verses.quran.com/';

let surahsCache = null;

export const fetchSurahs = async () => {
  try {
    // Return cached data if available
    if (surahsCache) {
      console.log("Returning surahs from cache");
      return surahsCache;
    }

    console.log("Fetching surahs from:", `${API_URL}/chapters`);
    const response = await axios.get(`${API_URL}/chapters`);
    console.log("Surahs response:", response.data);
    
    // Cache the response
    surahsCache = response.data;
    return response.data;
  } catch (error) {
    console.error("Error fetching surahs:", error.response || error);
    throw error;
  }
};

// Add a function to clear cache if needed
export const clearSurahsCache = () => {
  surahsCache = null;
};

export const fetchSurah = async (surahNumber, translationId, languageCode = 'en') => {
  try {
    console.log('Fetching surah with params:', {
      surahNumber,
      translationId,
      languageCode,
      translations: `${translationId},57`
    });
    
    // Get total verses for this surah from the SURAHS constant
    const surahInfo = SURAHS.find(s => s.number.toString() === surahNumber.toString());
    const totalVerses = surahInfo ? surahInfo.totalVerses : 0;
    
    // Fetch the surah with translations directly, getting all verses at once
    const response = await axios.get(`${API_URL}/verses/by_chapter/${surahNumber}`, {
      params: {
        language: languageCode,
        words: true,
        word_fields: 'text_uthmani',
        translations: `${translationId},57`,
        translation_fields: 'text,resource_name,footnotes,slug',
        fields: 'text_uthmani,verse_key,verse_number',
        per_page: totalVerses // Set per_page to total verses count
      }
    });
    
    console.log('API Response:', response.data);
    console.log('First verse translations:', response.data.verses?.[0]?.translations);
    
    return response.data;
  } catch (error) {
    console.error("Error fetching surah:", error);
    throw error;
  }
};

export const fetchReciters = async () => {
  try {
    console.log("Fetching reciters...");
    const response = await axios.get(`${API_URL}/resources/recitations`);
    console.log("Reciters response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching reciters:", error.response || error);
    throw error;
  }
};

export const fetchAudioTimings = async (recitationId, surahNumber) => {
  try {
    // Get total verses for this surah from the SURAHS constant
    const surahInfo = SURAHS.find(s => s.number.toString() === surahNumber.toString());
    const totalVerses = surahInfo ? surahInfo.totalVerses : 0;

    const response = await axios.get(`${API_URL}/recitations/${recitationId}/by_chapter/${surahNumber}`, {
      params: {
        per_page: totalVerses // Set per_page to total verses count
      }
    });
    console.log("Audio timings response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching audio timings:", error);
    throw error;
  }
};

export const fetchTranslations = async () => {
  try {
    const response = await axios.get(`${API_URL}/resources/translations`);
    return response.data;
  } catch (error) {
    console.error("Error fetching translations:", error);
    throw error;
  }
};

export const fetchLanguages = async () => {
  try {
    const response = await axios.get(`${API_URL}/resources/languages`);
    return response.data;
  } catch (error) {
    console.error("Error fetching languages:", error);
    throw error;
  }
};

export const fetchTafsirs = async (verseKey) => {
  try {
    const response = await axios.get(`${API_URL}/tafsirs/${verseKey}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tafsirs:", error);
    throw error;
  }
};

export const fetchVersesByKey = async (verseKeys, translationId, languageCode = 'en') => {
  try {
    // Request both the selected translation and the transliteration (id "57")
    const translationsParam = `${translationId},57`;
    const promises = verseKeys.map(verseKey =>
      axios.get(`${API_URL}/verses/by_key/${verseKey}`, {
        params: {
          language: languageCode,
          words: true,
          word_fields: 'text_uthmani,text_transliteration',
          transliteration: 1,
          translations: translationsParam,
          translation_fields: 'text,resource_name,footnotes,slug',
          fields: 'text_uthmani,verse_key,verse_number,words'
        }
      })
    );

    const responses = await Promise.all(promises);
    return responses.map(response => response.data.verse);
  } catch (error) {
    console.error("Error fetching verses by key:", error);
    throw error;
  }
};

export const fetchBismillah = async () => {
  try {
    const response = await axios.get(`${API_URL}/verses/by_key/1:1`, {
      params: {
        fields: 'text_uthmani'
      }
    });
    return response.data.verse;
  } catch (error) {
    console.error("Error fetching bismillah:", error);
    throw error;
  }
};

// Remove these functions:
// export const fetchTafsirsList = async () => { ... }
// export const fetchTafsir = async (tafsirId, verseKey) => { ... }