import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

const AppLanding = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen relative overflow-hidden bg-[#0A0A0A]">
      {/* Background Image with Gradient Overlay */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Background Image Layer */}
        <img
          src="/images/headerbg.png"
          alt=""
          className="absolute top-0 left-1/2 -translate-x-1/2 w-[1600px] max-w-none h-full object-cover opacity-30 object-top"
        />
        {/* Primary Gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-[#111111]/95 to-[#111111]" />
        {/* Accent Gradient */}
        <div className="absolute inset-0 bg-gradient-to-t from-transparent via-emerald-500/[0.03] to-transparent" />
      </div>

      {/* Gradient Orbs */}
      <div className="fixed top-1/4 left-1/4 w-[600px] h-[600px] rounded-full blur-3xl -translate-x-1/2 bg-emerald-500/[0.07]" />
      <div className="fixed bottom-1/4 right-1/4 w-[600px] h-[600px] rounded-full blur-3xl translate-x-1/2 bg-emerald-500/[0.07]" />

      {/* Content */}
      <div className="relative flex flex-col items-center justify-center flex-1 px-8 py-16" 
           style={{ paddingTop: 'calc(env(safe-area-inset-top) + 4rem)' }}>
        {/* Logo */}
        <div className="w-32 h-32 mb-12">
          <img
            src="/images/ummahti_white_transparent.png"
            alt="Ummahti"
            className="w-full transition-opacity duration-300 opacity-90"
          />
        </div>

        {/* Welcome Text */}
        <h1 className="text-4xl font-light mb-4 text-center text-white">
          Welcome to Ummahti
        </h1>
        <p className="text-lg mb-12 text-center max-w-sm text-gray-300">
          Your companion for strengthening your connection with Allah (SWT)
        </p>

        {/* Action Buttons */}
        <div className="space-y-4 w-full max-w-sm">
          <button
            onClick={() => navigate('/login')}
            className="w-full py-4 px-6 rounded-2xl backdrop-blur-md transition-all duration-300 bg-emerald-500 hover:bg-emerald-400 text-black font-medium"
          >
            Login
          </button>
          
          <button
            onClick={() => navigate('/signup')}
            className="w-full py-4 px-6 rounded-2xl backdrop-blur-md transition-all duration-300 bg-white/10 hover:bg-white/20 text-white border border-white/[0.05]"
          >
            Create Account
          </button>
        </div>
      </div>

      {/* Footer Text */}
      <div className="relative pb-8 px-8 text-center" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 2rem)' }}>
        <p className="text-sm text-gray-400">
          By continuing, you agree to our{' '}
          <Link 
            to="/app/terms" 
            className="text-emerald-400 hover:text-emerald-300"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link 
            to="/app/privacy" 
            className="text-emerald-400 hover:text-emerald-300"
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AppLanding; 