import React, { useState, useEffect, useRef } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const CrossfadingBackground = () => {
  const { settings } = useSettings();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1400);
  const [loadedUrls, setLoadedUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Extract background images from settings; fallback to empty array
  const images = settings.simpleDashboard?.backgroundImages || [];

  // Simplified function to convert path to URL - always get a fresh Firebase URL
  const convertPathToUrl = async (imagePath) => {
    if (!imagePath) return '';
    
    try {
      // Get a fresh URL from Firebase
      const storage = getStorage();
      const storageRef = ref(storage, imagePath);
      return await getDownloadURL(storageRef);
    } catch (error) {
      console.error('Error converting path to URL:', imagePath, error);
      return '';
    }
  };

  // Get the best path property from the image object based on screen size
  const getImagePath = (image) => {
    if (!image) return '';
    
    // If image is a string, assume it's already a path
    if (typeof image === 'string') return image;
    
    // Always prioritize path properties over URL properties
    if (isLargeScreen) {
      // For large screens: prefer the original full-size image path
      return image.path || image.originalPath || image.displayPath || image.thumbnailPath;
    } else {
      // For smaller screens: prefer the display or thumbnail version path
      return image.displayPath || image.thumbnailPath || image.path || image.originalPath;
    }
  };

  // Load URLs for all images when component mounts or images change
  useEffect(() => {
    const loadAllImageUrls = async () => {
      if (images.length === 0) {
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      const urls = [];
      
      console.log(`Loading ${images.length} background images...`);
      let successCount = 0;
      
      for (let i = 0; i < images.length; i++) {
        try {
          const imagePath = getImagePath(images[i]);
          
          if (imagePath) {
            console.log(`Processing image ${i+1}/${images.length}, path:`, imagePath);
            const url = await convertPathToUrl(imagePath);
            
            if (url) {
              urls.push(url);
              successCount++;
            }
          }
        } catch (error) {
          console.error(`Error processing image ${i+1}:`, error);
        }
      }
      
      console.log(`Successfully loaded ${successCount} background images`);
      setLoadedUrls(urls);
      setIsLoading(false);
    };
    
    loadAllImageUrls();
  }, [images, isLargeScreen]);

  // Handle screen size changes
  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth >= 1400);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Preload images to ensure smoother loading
  useEffect(() => {
    if (loadedUrls.length > 0 && !isLoading) {
      loadedUrls.forEach(url => {
        const img = new Image();
        img.crossOrigin = "anonymous";  // Add CORS header
        img.src = url;
      });
    }
  }, [loadedUrls, isLoading]);

  // Duration from settings (in ms) and fadeDuration (in ms). Use settings if provided.
  const duration = settings.simpleDashboard?.slideshowDuration
    ? settings.simpleDashboard.slideshowDuration * 1000
    : 60000;
  const fadeDuration = settings.simpleDashboard?.slideshowFadeDuration
    ? settings.simpleDashboard.slideshowFadeDuration
    : 1000;

  // Configure the slideshow properties
  const slideProperties = {
    duration: duration,
    transitionDuration: fadeDuration,
    indicators: false,
    arrows: false,
    pauseOnHover: false,
    canSwipe: false,
    easing: 'ease-in-out',
    autoplay: true,
    infinite: true
  };

  if (images.length === 0 || isLoading || loadedUrls.length === 0) return null;

  // If only one image, just render it statically
  if (loadedUrls.length === 1) {
    return (
      <div className="fixed inset-0 w-full h-full">
        <div
          className="absolute inset-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${loadedUrls[0]})`,
          }}
        ></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 w-full h-full">
      <Fade {...slideProperties}>
        {loadedUrls.map((url, index) => (
          <div key={index} className="each-fade h-full w-full">
            <div
              className="h-full w-full bg-center bg-cover"
              style={{ backgroundImage: `url(${url})` }}
            />
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default CrossfadingBackground; 