import React, { useState, useEffect } from 'react';
import { Smartphone } from 'lucide-react';
import { LinkedDevicesTable } from './components/LinkedDevicesTable';
import { useDeviceLinking } from './hooks/useDeviceLinking';
import { sectionStyles } from './styles';
import { useDarkMode } from '../../../contexts/DarkModeContext';

const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';
  if (timestamp.seconds) {
    return new Date(timestamp.seconds * 1000).toLocaleString();
  }
  return new Date(timestamp).toLocaleString();
};

const shortenDeviceId = (id) => {
  if (!id) return '';
  if (id.length <= 6) return id;
  return `${id.slice(0, 3)}...${id.slice(-3)}`;
};

export const DeviceConnection = () => {
  const { isDarkMode } = useDarkMode();
  const styles = sectionStyles(isDarkMode);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const {
    linkingCode,
    setLinkingCode,
    linkedDevices,
    isLoading,
    handleLink,
    handleUnlink
  } = useDeviceLinking();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`${styles.container} relative z-0`}>
      <div className={styles.header}>
        <Smartphone className={styles.headerIcon} />
        <h2 className={styles.headerText}>Device Connection</h2>
      </div>
      <div className={styles.card}>
        <div className="flex flex-col gap-3">
          <input
            type="text"
            value={linkingCode}
            onChange={(e) => setLinkingCode(e.target.value)}
            placeholder="Enter 6-digit code"
            maxLength={6}
            pattern="\d{6}"
            required
            className={`w-full px-4 py-3 rounded-xl text-center text-lg tracking-widest ${
              isDarkMode 
                ? 'bg-[#1A1A1A] border-gray-800 text-white placeholder-gray-600' 
                : 'bg-gray-100 border-gray-200 text-gray-900 placeholder-gray-400'
            }`}
          />
          <button 
            onClick={handleLink}
            className={`w-full py-3 px-4 rounded-xl font-medium transition-colors ${
              isDarkMode
                ? 'bg-emerald-500 text-black hover:bg-emerald-400'
                : 'bg-emerald-600 text-white hover:bg-emerald-700'
            }`}
          >
            Link Device
          </button>
        </div>

        <div className="mt-8">
          <h4 className={`text-base font-medium mb-6 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Linked Devices</h4>
          <LinkedDevicesTable
            isLoading={isLoading}
            linkedDevices={linkedDevices}
            onUnlink={handleUnlink}
            formatTimestamp={formatTimestamp}
            shortenDeviceId={shortenDeviceId}
            isMobileView={isMobileView}
          />
        </div>
      </div>
    </section>
  );
}; 