import React, { forwardRef } from 'react';
import { Plus } from 'lucide-react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';

export const UploadButton = forwardRef(({ uploading, progress, onUpload }, ref) => {
  const { isDarkMode } = useDarkMode();

  return (
    <>
      <input
        type="file"
        ref={ref}
        onChange={onUpload}
        accept="image/*"
        multiple
        className="hidden"
      />
      <button
        onClick={() => ref.current?.click()}
        disabled={uploading}
        className={`relative z-10 w-full px-4 py-2 mb-4 mt-4 rounded-xl font-medium transition-colors ${
          isDarkMode
            ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
            : 'bg-emerald-600 text-white hover:bg-emerald-700'
        }`}
      >
        {uploading ? (
          <>
            <div 
              className="absolute inset-0 bg-emerald-500/20"
              style={{ width: `${progress}%` }}
            />
            <span className="relative z-10">Uploading... {Math.round(progress)}%</span>
          </>
        ) : (
          <>
            <Plus size={18} className="inline mr-2" />
            Upload Custom Images
          </>
        )}
      </button>
    </>
  );
}); 