import React, { createContext, useContext, useState, useEffect } from 'react';

const LocationContext = createContext();

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleLocationUpdate = (event) => {
      if (!event?.detail?.coords) return;
      const { coords } = event.detail;
      if (typeof coords.latitude !== 'number' || typeof coords.longitude !== 'number') return;
      setLocation(coords);
    };

    if (window.isUmmahtiNative) {
      window.addEventListener('native-location-update', handleLocationUpdate);
      return () => {
        window.removeEventListener('native-location-update', handleLocationUpdate);
      };
    } else {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation(position.coords);
          },
          (err) => {
            setError(err.message);
          }
        );
      } else {
        setError('Geolocation is not supported by your browser');
      }
    }
  }, []);

  return (
    <LocationContext.Provider value={{ location, error }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext; 