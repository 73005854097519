import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { TextInput, Alert } from 'flowbite-react';
import { FcGoogle } from 'react-icons/fc';
import { ChevronLeft } from 'lucide-react';
import { signInWithGoogle } from '../../services/authService';

const AppLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Log the environment
      console.log('Environment check:', {
        isUmmahtiNative: window.isUmmahtiNative,
        hasUmmahti: Boolean(window.ummahti),
        hasGoogleSignIn: Boolean(window.ummahti?.signInWithGoogle)
      });

      await signInWithGoogle();
      navigate('/');
    } catch (error) {
      console.error('Google Sign-In error:', error);
      // Make error message more user-friendly
      if (error.code === 'auth/popup-blocked') {
        setError('Please enable popups for this website to sign in with Google');
      } else if (error.code === 'auth/cancelled-popup-request') {
        setError('Sign-in was cancelled');
      } else {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen relative overflow-hidden bg-[#0A0A0A]">
      {/* Back Button */}
      <div className="absolute top-0 left-0 z-10" style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)', paddingLeft: '1rem' }}>
        <Link
          to="/"
          className="inline-flex items-center justify-center w-10 h-10 rounded-full transition-all duration-200 bg-white/10 hover:bg-white/20"
        >
          <ChevronLeft className="text-white" size={20} />
        </Link>
      </div>

      {/* Background Image with Gradient Overlay */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Background Image Layer */}
        <img
          src="/images/headerbg.png"
          alt=""
          className="absolute top-0 left-1/2 -translate-x-1/2 w-[1600px] max-w-none h-full object-cover opacity-30 object-top"
        />
        {/* Primary Gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-[#111111]/95 to-[#111111]" />
        {/* Accent Gradient */}
        <div className="absolute inset-0 bg-gradient-to-t from-transparent via-emerald-500/[0.03] to-transparent" />
      </div>

      {/* Gradient Orbs */}
      <div className="fixed top-1/4 left-1/4 w-[600px] h-[600px] rounded-full blur-3xl -translate-x-1/2 bg-emerald-500/[0.07]" />
      <div className="fixed bottom-1/4 right-1/4 w-[600px] h-[600px] rounded-full blur-3xl translate-x-1/2 bg-emerald-500/[0.07]" />

      {/* Content */}
      <div className="relative flex flex-col items-center justify-center flex-1 px-8 mt-16" 
           style={{ paddingTop: 'calc(env(safe-area-inset-top) + 2rem)' }}>
        <div className="w-full max-w-sm bg-transparent">
          <div>
            {/* Logo */}
            <div className="flex justify-center mb-12">
              <div className="w-32 h-32">
                <img
                  src="/images/ummahti_white_transparent.png"
                  alt="Ummahti"
                  className="w-full transition-opacity duration-300 opacity-90"
                />
              </div>
            </div>

            <h2 className="text-2xl font-light mb-8 text-center text-white">
              Welcome back
            </h2>
            
            {error && (
              <Alert 
                color="failure" 
                className="mb-6"
                onDismiss={() => setError(null)}
              >
                {error}
              </Alert>
            )}

            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <TextInput
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="[&>input]:rounded-2xl [&>input]:px-6 [&>input]:py-4 [&>input]:backdrop-blur-md"
                />
              </div>
              <div>
                <TextInput
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="[&>input]:rounded-2xl [&>input]:px-6 [&>input]:py-4 [&>input]:backdrop-blur-md"
                />
              </div>
              <button 
                type="submit" 
                className="w-full py-4 px-6 rounded-2xl backdrop-blur-md transition-all duration-300 bg-emerald-500 hover:bg-emerald-400 text-black font-medium"
              >
                Sign in
              </button>
            </form>

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-800"></div>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="px-2 backdrop-blur-md text-gray-400">
                  Or continue with
                </span>
              </div>
            </div>

            <button
              className="w-full py-4 px-6 rounded-2xl backdrop-blur-md transition-all duration-300 bg-white/10 hover:bg-white/20 text-white border border-white/[0.05]"
              onClick={handleGoogleSignIn}
              disabled={isLoading}
            >
              <div className="flex items-center justify-center">
                <FcGoogle className="mr-2 h-5 w-5" />
                {isLoading ? 'Signing in...' : 'Sign in with Google'}
              </div>
            </button>

            <p className="mt-6 text-center text-sm text-gray-400">
              Don't have an account?{' '}
              <button 
                onClick={() => navigate('/signup')}
                className="text-emerald-400 hover:text-emerald-300"
              >
                Sign up
              </button>
            </p>
          </div>
        </div>
      </div>

      {/* Footer Text */}
      <div className="relative pb-8 px-8 text-center" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 2rem)' }}>
        <p className="text-sm text-gray-400">
          By continuing, you agree to our{' '}
          <Link 
            to="/app/terms" 
            className="text-emerald-400 hover:text-emerald-300"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link 
            to="/app/privacy" 
            className="text-emerald-400 hover:text-emerald-300"
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AppLogin; 