/**
 * Calculates the Qibla direction in degrees from true north
 * @param {number} latitude - User's latitude in decimal degrees
 * @param {number} longitude - User's longitude in decimal degrees
 * @returns {number} Qibla direction in degrees from true north
 */
export function calculateQibla(latitude, longitude) {
  // Kaaba coordinates
  const kaabaLat = 21.422487;
  const kaabaLng = 39.826206;

  // Convert all coordinates to radians
  const userLat = latitude * Math.PI / 180;
  const userLng = longitude * Math.PI / 180;
  const kaabaLatRad = kaabaLat * Math.PI / 180;
  const kaabaLngRad = kaabaLng * Math.PI / 180;

  // Calculate the angle
  const dLng = kaabaLngRad - userLng;
  const y = Math.sin(dLng);
  const x = Math.cos(userLat) * Math.tan(kaabaLatRad) - Math.sin(userLat) * Math.cos(dLng);
  
  // Convert to degrees and normalize to 0-360
  let qibla = Math.atan2(y, x) * 180 / Math.PI;
  qibla = (qibla + 360) % 360;

  return qibla;
} 