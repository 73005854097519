import { useState, useEffect } from 'react';
import { auth } from '../../../../firebase';
import { linkDevice, getLinkedDevices, unlinkDevice } from '../../../../services/deviceLinkingService';
import { getDeviceId } from '../../../../utils/deviceIdentifier';

export const useDeviceLinking = () => {
  const [linkingCode, setLinkingCode] = useState('');
  const [linkedDevices, setLinkedDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadDevices = async () => {
      try {
        const devices = await getLinkedDevices();
        setLinkedDevices(devices);
      } catch (error) {
        console.error('Error loading devices:', error);
        setError('Failed to load linked devices');
      } finally {
        setIsLoading(false);
      }
    };

    loadDevices();
  }, []);

  const handleLink = async (e) => {
    e.preventDefault();
    try {
      await linkDevice(linkingCode);
      const devices = await getLinkedDevices();
      setLinkedDevices(devices);
      setLinkingCode('');
    } catch (error) {
      console.error('Error linking device:', error);
      setError('Failed to link device');
    }
  };

  const handleUnlink = async (deviceId) => {
    if (!window.confirm('Are you sure you want to unlink this device?')) return;

    try {
      const currentDeviceId = await getDeviceId();
      const isCurrentDevice = deviceId === currentDeviceId;
      
      await unlinkDevice(deviceId);
      setLinkedDevices(devices => devices.filter(d => d.id !== deviceId));

      if (isCurrentDevice) {
        await auth.signOut();
      }
    } catch (error) {
      console.error('Error unlinking device:', error);
      setError('Failed to unlink device');
    }
  };

  return {
    linkingCode,
    setLinkingCode,
    linkedDevices,
    isLoading,
    error,
    handleLink,
    handleUnlink
  };
}; 