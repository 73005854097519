import { useState, useRef, useEffect, useCallback } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { auth } from '../../../../firebase';
import { getCachedImage, clearImageCache, isUrlExpired } from '../../../../utils/imageCache';
import { dashboardTemplates } from '../../../../config/dashboardTemplates';
import { getAuth } from 'firebase/auth';
import imageCache from '../../../../utils/imageCache';

// Cache to prevent duplicate fetches
const URL_REFRESH_CACHE = {
  lastRefresh: 0,
  // Store a timestamp when refreshing URLs to prevent refreshing more than once per minute
  urls: new Map()
};

export const useImageUpload = (selectedTemplate, onSuccess, onError) => {
  const fileInputRef = useRef(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [userImages, setUserImages] = useState([]);
  const [error, setError] = useState('');
  const [refreshingUrls, setRefreshingUrls] = useState(false);
  
  // Initialize image loading on mount
  useEffect(() => {
    // Load background images for the selected template
    if (selectedTemplate) {
      fetchBackgrounds();
      fetchUserImages();
    }
  }, [selectedTemplate]);

  // Function to refresh a storage URL if needed - optimized with caching
  const refreshStorageUrl = useCallback(async (path) => {
    if (!path) return null;
    
    // Check if this URL was recently refreshed (within the last 5 minutes)
    const now = Date.now();
    const cacheEntry = URL_REFRESH_CACHE.urls.get(path);
    
    if (cacheEntry && now - cacheEntry.timestamp < 5 * 60 * 1000) {
      return cacheEntry.url;
    }
    
    try {
      const storage = getStorage();
      const storageRef = ref(storage, path);
      const freshUrl = await getDownloadURL(storageRef);
      
      // Cache the result
      URL_REFRESH_CACHE.urls.set(path, {
        url: freshUrl,
        timestamp: now
      });
      
      return freshUrl;
    } catch (error) {
      console.error('Error refreshing URL for path:', path, error);
      return null;
    }
  }, []);

  // Optimized function to check for and refresh expired URLs - with throttling
  const refreshExpiredUrls = useCallback(async () => {
    // Prevent multiple refreshes happening at once
    if (refreshingUrls) {
      return { refreshed: 0, expired: 0 };
    }
    
    // Throttle refreshes to once per minute maximum
    const now = Date.now();
    if (now - URL_REFRESH_CACHE.lastRefresh < 60 * 1000) {
      console.log('URL refresh throttled - last refresh was less than a minute ago');
      return { refreshed: 0, expired: 0 };
    }
    
    setRefreshingUrls(true);
    URL_REFRESH_CACHE.lastRefresh = now;
    
    try {
      console.log('Checking for expired URLs in user images...');
      const storage = getStorage();
      let urlsRefreshed = 0;
      let expiredUrls = 0;
      
      // Function to check if URL is a Firebase Storage URL that might be expired
      const isExpiredStorageUrl = (url) => {
        if (!url) return false;
        
        // Check if it's a Firebase Storage URL
        const isFirebaseUrl = url.includes('firebasestorage.googleapis.com') || 
                             url.includes('ummahti-backend.firebasestorage.app');
        
        if (!isFirebaseUrl) return false;
        
        // Check for token expiration
        try {
          // Parse URL to extract token
          const urlObj = new URL(url);
          const expiresParam = urlObj.searchParams.get('Expires');
          
          if (!expiresParam) return false;
          
          // Convert to timestamp and check if expired
          const expiresTimestamp = parseInt(expiresParam, 10) * 1000; // Convert to milliseconds
          
          // Consider URLs that will expire in the next hour as "expired" to be proactive
          const oneHourInMs = 60 * 60 * 1000;
          return now > (expiresTimestamp - oneHourInMs);
        } catch (e) {
          return false;
        }
      };
      
      // Refresh both user and background images
      const allImages = [...userImages, ...backgroundImages];
      
      // Find all images with expired URLs - only check original URLs for efficiency
      const expiredOriginals = allImages.filter(img => 
        isExpiredStorageUrl(img.url || img.original) && img.path
      );
      
      expiredUrls = expiredOriginals.length;
      
      if (expiredUrls === 0) {
        console.log('No expired URLs found in user images');
        return { refreshed: 0, expired: 0 };
      }
      
      console.log(`Found ${expiredUrls} expired URLs to refresh`);
      
      // Refresh original URLs
      await Promise.all(expiredOriginals.map(async (image) => {
        try {
          if (!image.path) {
            return;
          }
          
          // Get fresh URL from Firebase Storage
          const freshUrl = await refreshStorageUrl(image.path);
          if (!freshUrl) return;
          
          // Update image cache
          if (imageCache && typeof imageCache.set === 'function') {
            imageCache.set(image.path, freshUrl);
          }
          
          // Update in our local state arrays
          if (image.isDefault) {
            setBackgroundImages(prev => prev.map(img => 
              img.path === image.path ? {...img, url: freshUrl, display: freshUrl, original: freshUrl} : img
            ));
          } else {
            setUserImages(prev => prev.map(img => 
              img.path === image.path ? {...img, url: freshUrl, display: freshUrl, original: freshUrl} : img
            ));
          }
          
          urlsRefreshed++;
        } catch (error) {
          console.error(`Failed to refresh URL for image:`, error);
        }
      }));
      
      console.log(`Refreshed ${urlsRefreshed} URLs out of ${expiredUrls} expired URLs`);
      return { refreshed: urlsRefreshed, expired: expiredUrls };
    } catch (error) {
      console.error('Error refreshing expired URLs:', error);
      return { refreshed: 0, expired: 0, error };
    } finally {
      setRefreshingUrls(false);
    }
  }, [userImages, backgroundImages, refreshingUrls, refreshStorageUrl]);

  // Fetch background images
  const fetchBackgrounds = useCallback(async () => {
    try {
      console.log('Fetching background images...');
      const storage = getStorage();
      const backgroundsRef = ref(storage, 'default_backgrounds');
      const backgroundsList = await listAll(backgroundsRef);
      
      // Filter out thumbnails and non-image files
      const imageFiles = backgroundsList.items.filter(item => 
        !item.fullPath.includes('/thumbnails/') && 
        (item.name.endsWith('.jpg') || item.name.endsWith('.png') || item.name.endsWith('.jpeg'))
      );

      console.log(`Found ${imageFiles.length} background images to process`);

      const backgrounds = await Promise.all(
        imageFiles.map(async (imageRef) => {
          try {
            // Get the download URL for the original image
            const imageUrl = await getDownloadURL(imageRef);
            
            // Check for a thumbnail
            const thumbnailPath = `default_backgrounds/thumbnails/thumb_${imageRef.name}`;
            const thumbnailRef = ref(storage, thumbnailPath);
            let thumbnailUrl;
            
            try {
              thumbnailUrl = await getDownloadURL(thumbnailRef);
            } catch (thumbError) {
              thumbnailUrl = imageUrl;
            }
            
            return {
              path: imageRef.fullPath,
              url: imageUrl,
              original: imageUrl,
              display: imageUrl,
              thumbnail: thumbnailUrl,
              name: imageRef.name,
              isDefault: true
            };
          } catch (error) {
            console.error(`Error processing background image ${imageRef.name}:`, error);
            return null;
          }
        })
      );

      // Filter out any null entries and set state
      const validBackgrounds = backgrounds.filter(Boolean);
      setBackgroundImages(validBackgrounds);
      
      return validBackgrounds;
    } catch (error) {
      console.error('Error fetching background images:', error);
      setError('Failed to load background images');
      return [];
    }
  }, []);

  // Fetch user uploaded images
  const fetchUserImages = useCallback(async () => {
    try {
      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        console.warn('No authenticated user found for fetching user images');
        return [];
      }
      
      const userId = currentUser.uid;
      const userImagesPath = `user_images/${userId}/original`;
      
      const storage = getStorage();
      const userImagesRef = ref(storage, userImagesPath);
      
      try {
        const userImagesList = await listAll(userImagesRef);
        
        // Filter out thumbnails and non-image files
        const imageFiles = userImagesList.items.filter(item => 
          (item.name.endsWith('.jpg') || item.name.endsWith('.png') || item.name.endsWith('.jpeg'))
        );
        
        if (imageFiles.length === 0) {
          console.log('No user images found');
          return [];
        }
        
        console.log(`Found ${imageFiles.length} user images to process`);
        
        const userImagesData = await Promise.all(
          imageFiles.map(async (imageRef) => {
            try {
              // Get the paths for display and thumbnail versions
              const displayPath = `user_images/${userId}/original/display/${imageRef.name}`;
              const thumbnailPath = `user_images/${userId}/original/thumbnails/${imageRef.name}`;
              
              // Get the download URL for each version
              const originalUrl = await getDownloadURL(imageRef);
              
              // Try to get display version
              let displayUrl = originalUrl;
              try {
                const displayRef = ref(storage, displayPath);
                displayUrl = await getDownloadURL(displayRef);
              } catch (displayError) {
                // Use original if display not available
              }
              
              // Try to get thumbnail version
              let thumbnailUrl = displayUrl;
              try {
                const thumbnailRef = ref(storage, thumbnailPath);
                thumbnailUrl = await getDownloadURL(thumbnailRef);
              } catch (thumbnailError) {
                // Use display if thumbnail not available
              }
              
              // Extract timestamp from filename if possible
              let timestamp = Date.now();
              const timestampMatch = imageRef.name.match(/^(\d+)-/);
              if (timestampMatch && timestampMatch[1]) {
                timestamp = parseInt(timestampMatch[1], 10);
              }
              
              return {
                path: imageRef.fullPath,
                displayPath,
                thumbnailPath,
                original: originalUrl,
                display: displayUrl,
                thumbnail: thumbnailUrl,
                name: imageRef.name,
                timestamp,
                isDefault: false
              };
            } catch (error) {
              console.error(`Error processing user image ${imageRef.name}:`, error);
              return null;
            }
          })
        );
        
        // Filter out any failed images and sort by timestamp (newest first)
        const validUserImages = userImagesData
          .filter(Boolean)
          .sort((a, b) => b.timestamp - a.timestamp);
        
        setUserImages(validUserImages);
        return validUserImages;
      } catch (listError) {
        console.error('Error listing user images:', listError);
        return [];
      }
    } catch (error) {
      console.error('Error fetching user images:', error);
      return [];
    }
  }, []);

  // Handler for uploading a custom image
  const handleCustomImageUpload = useCallback(async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      onError('You must be logged in to upload images');
      return;
    }
    
    setUploadingImage(true);
    setUploadProgress(0);
    
    try {
      const userId = currentUser.uid;
      const uploadPath = `user_images/${userId}/original`;
      const storage = getStorage();
      
      // Process each file
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        
        // Check file size (limit to 5MB)
        if (file.size > 5 * 1024 * 1024) {
          onError(`File ${file.name} is too large. Maximum size is 5MB.`);
          continue;
        }
        
        // Check file type
        if (!file.type.startsWith('image/')) {
          onError(`File ${file.name} is not an image.`);
          continue;
        }
        
        // Create a unique filename with timestamp
        const timestamp = Date.now();
        const filename = `${timestamp}-${file.name}`;
        const imageRef = ref(storage, `${uploadPath}/${filename}`);
        
        // Upload the file
        await uploadBytes(imageRef, file);
        setUploadProgress(((i + 1) / files.length) * 100);
      }
      
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      
      // Refresh the user images list
      const newUserImages = await fetchUserImages();
      
      // Update settings to include the new images
      onSuccess();
    } catch (error) {
      console.error('Error uploading image:', error);
      onError('Failed to upload image. Please try again.');
    } finally {
      setUploadingImage(false);
      setUploadProgress(0);
    }
  }, [fetchUserImages, onSuccess, onError]);

  // Handler for selecting/deselecting an image
  const handleImageSelect = useCallback((image, setSelectedImages) => {
    if (!image) return;
    
    // Determine the identifier for this image
    const imageIdentifier = image.isDefault 
      ? (image.url || image.original || image.display || image.path)
      : (image.url || image.original || image.display || image.path);
    
    if (!imageIdentifier) {
      console.error('Cannot select image without identifier', image);
      return;
    }
    
    setSelectedImages(prev => {
      // Get a clean array (filter out any undefined/null entries)
      const currentImages = prev.filter(Boolean);
      
      // Check if the image is already selected
      const imageIndex = currentImages.findIndex(selected => {
        if (typeof selected === 'string') {
          return selected === imageIdentifier;
        } else if (selected && typeof selected === 'object') {
          return (selected.url || selected.original || selected.display || selected.path) === imageIdentifier;
        }
        return false;
      });
      
      // If image is already selected, remove it
      if (imageIndex !== -1) {
        return [...currentImages.slice(0, imageIndex), ...currentImages.slice(imageIndex + 1)];
      }
      
      // If not selected, add it (but enforce a maximum of 20 images)
      if (currentImages.length >= 20) {
        onError('Maximum of 20 images allowed');
        return currentImages;
      }
      
      // Add the new image
      return [...currentImages, imageIdentifier];
    });
  }, [onError]);

  // Handler for deleting a user image
  const handleDeleteImage = useCallback(async (image, setSelectedImagesFunction) => {
    if (!image || image.isDefault) return;
    
    try {
      const storage = getStorage();
      
      // Delete the original image
      const originalRef = ref(storage, image.path);
      await deleteObject(originalRef);
      
      // Try to delete the display version if it exists
      if (image.displayPath) {
        try {
          const displayRef = ref(storage, image.displayPath);
          await deleteObject(displayRef);
        } catch (displayError) {
          console.warn('Display image not found or already deleted');
        }
      }
      
      // Try to delete the thumbnail if it exists
      if (image.thumbnailPath) {
        try {
          const thumbnailRef = ref(storage, image.thumbnailPath);
          await deleteObject(thumbnailRef);
        } catch (thumbError) {
          console.warn('Thumbnail not found or already deleted');
        }
      }
      
      // Update the local state
      setUserImages(prev => prev.filter(img => img.path !== image.path));
      
      // Also remove from selected images if needed
      const imageIdentifier = image.url || image.original || image.display || image.path;
      if (imageIdentifier && typeof setSelectedImagesFunction === 'function') {
        setSelectedImagesFunction(prev => {
          return prev.filter(selected => {
            if (typeof selected === 'string') {
              return selected !== imageIdentifier;
            } else if (selected && typeof selected === 'object') {
              return (selected.url || selected.original || selected.display || selected.path) !== imageIdentifier;
            }
            return true;
          });
        });
      }
      
      // Show success message
      onSuccess('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
      onError('Failed to delete image');
    }
  }, [onSuccess, onError]);

  return {
    fileInputRef,
    uploadingImage,
    uploadProgress,
    backgroundImages,
    userImages,
    error,
    handleCustomImageUpload,
    handleImageSelect,
    handleDeleteImage,
    refreshExpiredUrls,
    fetchBackgrounds,
    fetchUserImages
  };
}; 