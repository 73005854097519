import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';

const BentoBoxAdhanClockTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  // Handle background image slideshow
  useEffect(() => {
    if (!settings.simpleDashboard?.backgroundImages?.length) return;
    
    const interval = setInterval(() => {
      setPreviousBackgroundIndex(currentBackgroundIndex);
      setCurrentBackgroundIndex(prev => 
        (prev + 1) % settings.simpleDashboard.backgroundImages.length
      );
    }, (settings.simpleDashboard.slideshowDuration || 60) * 1000);

    return () => clearInterval(interval);
  }, [currentBackgroundIndex, settings.simpleDashboard?.backgroundImages, settings.simpleDashboard?.slideshowDuration]);

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Image */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Main Overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-black/95 via-black/85 to-black/95 z-10" />

      {/* Content Container */}
      <div className="relative h-screen w-full z-20">
        {/* Bento Grid Layout */}
        <div className="h-full grid grid-cols-12 grid-rows-8 gap-3 p-4 sm:p-6 lg:p-8">
          {/* Location + Date Card */}
          <div className="col-span-6 row-span-1 bg-white/5 backdrop-blur-sm rounded-2xl xl:rounded-3xl p-4 border border-white/10">
            <div className="flex justify-between items-center h-full">
              {/* Location */}
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4 text-white/60" />
                <h1 className="text-base sm:text-lg xl:text-xl font-light text-white/60">
                  {settings.location.split(',')[0]}
                </h1>
              </div>
              {/* Date Cards */}
              <div className="flex gap-8">
                <SimpleDateCard 
                  dateTime={dateTime}
                  onDateChange={onDateChange}
                  render={({ gregorian, hijri }) => (
                    <>
                      <div>
                        <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Gregorian</p>
                        <p className="text-sm lg:text-base">{gregorian}</p>
                      </div>
                      <div>
                        <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Hijri</p>
                        <p className="text-sm lg:text-base">{hijri}</p>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>

          {/* Prayer Times Layout */}
          <div className="col-span-6 row-span-4 grid grid-cols-5 gap-3">
            {/* Regular Prayers - Left Side in 2x2 Grid */}
            <div className="col-span-4 grid grid-cols-2 grid-rows-2 gap-3">
              <SimplePrayerTimesCard
                prayerTimes={prayerTimes}
                settings={settings}
                render={({ prayers }) => (
                  <>
                    {prayers
                      .filter(([prayer]) => prayer !== nextPrayer)
                      .map(([prayer, time]) => {
                        const Icon = prayer === 'Fajr' ? Sunrise :
                                   prayer === 'Maghrib' ? Sunset :
                                   prayer === 'Isha' ? Moon : Sun;
                        
                        return (
                          <div key={prayer} className="bg-white/5 backdrop-blur-sm rounded-2xl xl:rounded-3xl p-4 border border-white/10">
                            <div className="flex items-center justify-end gap-2 mb-1">
                              <p className="text-white text-xs tracking-widest uppercase">
                                {prayer}
                              </p>
                              <Icon className="w-3 h-3 lg:w-4 lg:h-4 text-white" />
                            </div>
                            <p className="text-lg sm:text-xl lg:text-2xl font-light text-right text-white">
                              {time}
                            </p>
                          </div>
                        );
                    })}
                  </>
                )}
              />
            </div>

            {/* Next Prayer - Right Column */}
            {prayerTimes && Object.entries(prayerTimes)
              .filter(([prayer]) => prayer === nextPrayer)
              .map(([prayer, time]) => {
                const Icon = prayer === 'Fajr' ? Sunrise :
                           prayer === 'Maghrib' ? Sunset :
                           prayer === 'Isha' ? Moon : Sun;
                
                return (
                  <div key={prayer} 
                    className={`col-span-1 row-span-1 ${accentColor.bg} backdrop-blur-sm rounded-2xl xl:rounded-3xl p-4 border ${accentColor.border} flex flex-col justify-center`}
                  >
                    <div className="flex items-center justify-end gap-2 mb-1">
                      <p className={`${accentColor.base} text-xs tracking-widest uppercase`}>
                        {prayer}
                      </p>
                      <Icon className={`w-3 h-3 lg:w-4 lg:h-4 ${accentColor.base}`} />
                    </div>
                    <p className={`text-lg sm:text-xl lg:text-2xl font-light text-right ${accentColor.base}`}>
                      {time}
                    </p>
                  </div>
                );
            })}
          </div>

          {/* Current Time - Large */}
          <div className="col-span-6 row-span-5 bg-white/5 backdrop-blur-sm rounded-2xl xl:rounded-3xl p-4 sm:p-6 lg:p-8 border border-white/10">
            <div className="h-full flex flex-col justify-between">
              <p className="text-white/40 text-xs tracking-widest uppercase">Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className="text-8xl sm:text-9xl tall:text-[10rem] lg:text-[11rem] xl:text-[13rem] font-thin tracking-tight leading-none">
                    {time}
                  </div>
                )}
              />
            </div>
          </div>

          {/* Countdown Timer */}
          <div className={`col-span-6 row-span-2 ${accentColor.bg} backdrop-blur-sm rounded-2xl xl:rounded-3xl p-4 sm:p-6 lg:p-8 border ${accentColor.border}`}>
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div className="h-full flex flex-col justify-between">
                  <div className="flex items-center gap-2">
                    <div className={`w-1.5 h-1.5 lg:w-2 lg:h-2 rounded-full ${accentColor.bg}`}></div>
                    <p className={`${accentColor.base} text-xs sm:text-sm tracking-widest uppercase`}>
                      Time Until {nextPrayer}
                    </p>
                  </div>
                  <div className={`text-4xl sm:text-5xl lg:text-6xl xl:text-[7rem] font-thin tracking-tight leading-none ${accentColor.base}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />
          </div>

          {/* Hadith Card */}
          <div className="col-span-12 row-span-2 bg-white/5 backdrop-blur-sm rounded-2xl xl:rounded-3xl p-4 border border-white/10">
            <div className="h-full flex items-center justify-center">
              <p className="text-xl sm:text-2xl lg:text-3xl xl:text-4xl text-white/60 leading-relaxed text-center font-uthmani">
                {duas?.[currentDuaIndex]?.arabic}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BentoBoxAdhanClockTemplate; 