import React from 'react';
import { TbPlayerTrackNext, TbPlayerTrackPrev, TbPlayerPlay, TbPlayerPause, TbVolume, TbVolumeOff, TbRepeat, TbRepeatOnce } from "react-icons/tb";

const AudioPlayer = ({
  isDarkMode,
  isPlaying,
  togglePlay,
  handleVerseClick,
  currentVerse,
  repeatMode,
  handleRepeatClick,
  isMuted,
  toggleMute,
  volume,
  handleVolumeChange,
  selectedSurah,
  surahs,
  surahContent,
}) => {
  return (
    <div className={`fixed bottom-0 left-0 right-0 ${
      isDarkMode ? 'bg-[#111111] border-gray-800' : 'bg-white border-gray-200'
    } border-t p-4 shadow-lg z-40`}>
      <div className="max-w-4xl mx-auto flex items-center justify-between">
        <div className="flex items-center justify-center space-x-6 flex-1">
          <button 
            className="text-green-600 dark:text-green-400 hover:text-green-800 dark:hover:text-green-200" 
            onClick={() => handleVerseClick(currentVerse - 1)}
          >
            <TbPlayerTrackPrev className="h-6 w-6" />
          </button>
          <button 
            className="text-green-600 dark:text-green-400 hover:text-green-800 dark:hover:text-green-200" 
            onClick={togglePlay}
          >
            {isPlaying ? <TbPlayerPause className="h-8 w-8" /> : <TbPlayerPlay className="h-8 w-8" />}
          </button>
          <button 
            className="text-green-600 dark:text-green-400 hover:text-green-800 dark:hover:text-green-200" 
            onClick={() => handleVerseClick(currentVerse + 1)}
          >
            <TbPlayerTrackNext className="h-6 w-6" />
          </button>
          <button 
            className={`text-green-600 dark:text-green-400 hover:text-green-800 dark:hover:text-green-200 ${repeatMode !== 'none' ? 'opacity-100' : 'opacity-50'}`} 
            onClick={handleRepeatClick}
            title={repeatMode === 'none' ? 'No repeat' : repeatMode === 'one' ? 'Repeat one' : 'Repeat all'}
          >
            {repeatMode === 'one' ? <TbRepeatOnce className="h-6 w-6" /> : <TbRepeat className="h-6 w-6" />}
          </button>
        </div>
        <div className="flex-1 mx-4">
          <div className={`${
            isDarkMode ? 'bg-[#1A1A1A]' : 'bg-gray-200'
          } rounded-lg p-2 text-center relative`}>
            <div className="text-gray-700 dark:text-gray-300 text-sm font-medium truncate">
              {surahs.find(s => s.id.toString() === selectedSurah)?.name_simple || 'No surah selected'}
            </div>
            <div className="text-gray-600 dark:text-gray-400 text-xs">
              {surahContent && surahContent[currentVerse] ? `Verse ${surahContent[currentVerse].verse_key.split(':')[1]}` : ''}
            </div>
            {repeatMode !== 'none' && (
              <div className="absolute bottom-1 right-2 text-xs text-gray-500 dark:text-gray-400">
                {repeatMode === 'one' ? 'Repeat one' : 'Repeat all'}
              </div>
            )}
          </div>
        </div>
        
        <div className="flex items-center space-x-4 flex-1 justify-end">
          <button 
            className="text-gray-600 dark:text-gray-300 hover:text-green-600 dark:hover:text-green-400" 
            onClick={toggleMute}
          >
            {isMuted ? <TbVolumeOff className="h-6 w-6" /> : <TbVolume className="h-6 w-6" />}
          </button>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
            className="w-24 accent-green-600"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(AudioPlayer);
