import React from 'react';

const SkeletonVerse = ({ isDarkMode }) => {
  return (
    <div className={`
      block w-full p-4 border-b
      ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}
      relative group
    `}>
      <div className="flex flex-col space-y-4">
        <div className="flex-grow">
          {/* Arabic text skeleton */}
          <div className="flex justify-end">
            <div className={`
              h-8 w-3/4 rounded
              animate-pulse
              ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}
            `}></div>
          </div>

          {/* Translation skeleton */}
          <div className="mt-4 space-y-2">
            <div className={`
              h-4 w-full rounded
              animate-pulse
              ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}
            `}></div>
            <div className={`
              h-4 w-5/6 rounded
              animate-pulse
              ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}
            `}></div>
          </div>
        </div>

        {/* Controls skeleton */}
        <div className="flex justify-start pt-2">
          <div className={`
            backdrop-blur-sm rounded-xl
            ${isDarkMode ? 'bg-gray-700/30 ring-1 ring-gray-700/50' : 'bg-white/80 ring-1 ring-gray-200/50'}
          `}>
            <div className="flex items-center space-x-3 px-4 py-2">
              {/* Verse number skeleton */}
              <div className={`
                h-4 w-10 rounded
                animate-pulse
                ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}
              `}></div>

              {/* Action buttons skeleton */}
              <div className="flex space-x-3 border-l border-gray-200/10 dark:border-gray-700/30 pl-4">
                {[1, 2, 3, 4].map((_, i) => (
                  <div
                    key={i}
                    className={`
                      h-6 w-6 rounded
                      animate-pulse
                      ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'}
                    `}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonVerse; 