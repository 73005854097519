import { db, storage } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

export const getAdhanFiles = async () => {
  try {
    const adhansRef = collection(db, 'adhans');
    const snapshot = await getDocs(adhansRef);
    
    const adhans = [];
    
    for (const doc of snapshot.docs) {
      const data = doc.data();
      
      // Only add if both regular and fajr versions exist
      if (data.regularPath && data.fajrPath) {
        adhans.push({
          id: data.id,
          name: data.name,
          regularPath: data.regularPath,
          fajrPath: data.fajrPath
        });
      }
    }

    return adhans;
  } catch (error) {
    console.error('Error fetching adhan files:', error);
    return [];
  }
};

// Migration helper
export const migrateAdhanSettings = async (oldSettings) => {
  if (!oldSettings.adhan) return null;

  try {
    const adhans = await getAdhanFiles();
    
    // Try to find matching adhan in new system
    const matchingAdhan = adhans.find(adhan => 
      adhan.name.toLowerCase().includes(oldSettings.adhan.toLowerCase())
    );

    if (matchingAdhan) {
      return {
        id: matchingAdhan.id,
        name: matchingAdhan.name,
        regularPath: matchingAdhan.regularPath,
        fajrPath: matchingAdhan.fajrPath
      };
    }

    // If no match found, return first available adhan
    return adhans.length > 0 ? {
      id: adhans[0].id,
      name: adhans[0].name,
      regularPath: adhans[0].regularPath,
      fajrPath: adhans[0].fajrPath
    } : null;

  } catch (error) {
    console.error('Error migrating adhan settings:', error);
    return null;
  }
};
