export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 
  (process.env.NODE_ENV === 'development' 
    ? 'https://192.168.178.158:8000'
    : 'https://api.ummahti.net');

// Add timeout configuration
export const API_TIMEOUT = 15000; // 15 seconds

// Add some debugging
if (process.env.NODE_ENV === 'production') {
  console.log('Production environment detected');
  console.log('API URL:', API_BASE_URL);
  console.log('Environment variables:', {
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL ? 'Present' : 'Missing',
    NODE_ENV: process.env.NODE_ENV
  });
}

console.log('Environment:', process.env.NODE_ENV);
console.log('API_BASE_URL:', API_BASE_URL);
console.log('REACT_APP_API_BASE_URL present:', !!process.env.REACT_APP_API_BASE_URL);
console.log('hoi');