import React, { useState, useEffect, useRef } from 'react';
import { MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';
import PortraitSlideshow from '../shared/PortraitSlideshow';

const VerticalHorizonTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  const isDayTime = new Date().getHours() >= 6 && new Date().getHours() < 18;

  return (
    <div className={`w-full h-screen overflow-hidden flex ${isDayTime ? 'bg-gray-50' : 'bg-neutral-950'}`}>
      {/* Left Content Section */}
      <div className="flex-1 flex">
        {/* Prayer Times - Vertical Stack */}
        <SimplePrayerTimesCard
          prayerTimes={prayerTimes}
          settings={settings}
          render={({ prayers }) => (
            <div className="w-36 h-full bg-black/10 backdrop-blur-sm flex flex-col py-16">
              {prayers.map(([prayer, time]) => {
                const isNext = prayer === nextPrayer;
                return (
                  <div 
                    key={prayer}
                    className={`px-6 py-6 relative
                              ${isNext 
                                ? `${isDayTime 
                                    ? `${accentColor.base}/20` 
                                    : `${accentColor.base}/30`}`
                                : 'hover:bg-white/5'}
                              transition-colors duration-300`}
                  >
                    <p className={`text-xs tracking-wider mb-2
                      ${isNext 
                        ? accentColor.light
                        : isDayTime 
                          ? `${accentColor.base}/70`
                          : `${accentColor.base}/60`}`}>
                      {prayer.toUpperCase()}
                    </p>
                    <p className={`text-lg font-light
                      ${isNext 
                        ? accentColor.light
                        : isDayTime 
                          ? 'text-gray-800' 
                          : 'text-white/80'
                      }`}>
                      {time}
                    </p>
                    {isNext && (
                      <div className={`absolute left-0 top-0 bottom-0 w-1 ${accentColor.light}`} />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        />

        {/* Main Content Area */}
        <div className="flex-1 flex flex-col p-12">
          {/* Top Section */}
          <div className="flex justify-between items-center">
            {/* Location */}
            <div className="flex items-center gap-3">
              <MapPin className={`w-5 h-5 ${accentColor.light}`} />
              <span className={`text-lg ${isDayTime ? 'text-gray-600' : 'text-white/80'}`}>
                {settings.location?.split(',')[0]}
              </span>
            </div>

            {/* Dates Section */}
            <SimpleDateCard 
              dateTime={dateTime}
              onDateChange={onDateChange}
              render={({ gregorian, hijri }) => (
                <div className="flex gap-6 items-end">
                  <div>
                    <p className={`${accentColor.base}/60 text-xs tracking-wider mb-1`}>GREGORIAN</p>
                    <p className={`text-base font-light ${isDayTime ? 'text-gray-700' : 'text-white/80'}`}>
                      {gregorian}
                    </p>
                  </div>
                  <div>
                    <p className={`${accentColor.base}/60 text-xs tracking-wider mb-1`}>HIJRI</p>
                    <p className={`text-base font-light ${isDayTime ? 'text-gray-700' : 'text-white/80'}`}>
                      {hijri}
                    </p>
                  </div>
                </div>
              )}
            />
          </div>

          {/* Dua Card */}
          <div className="max-w-2xl mt-8" dir="rtl">
            <p className={`text-3xl leading-relaxed ${isDayTime ? 'text-gray-700' : 'text-white'}`}>
              {duas?.[currentDuaIndex]?.arabic}
            </p>
          </div>

          {/* Center Content */}
          <div className="flex-1 flex flex-col justify-end">
            {/* Countdown */}
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div className={`inline-block px-6 py-4 mb-8 ${isDayTime ? 'bg-black/5' : 'bg-white/5'} rounded-lg`}>
                  <p className={`${accentColor.base}/70 text-sm tracking-wider mb-2`}>
                    UNTIL {nextPrayer}
                  </p>
                  <p className={`text-4xl font-light ${accentColor.light}`}>
                    {countdown}
                  </p>
                </div>
              )}
            />

            {/* Large Time Display */}
            <div>
              <p className={`${accentColor.base}/70 text-sm tracking-wider mb-4`}>CURRENT TIME</p>
              <SimpleTimeCard 
                render={({ time }) => {
                  const [hours, minutes] = time.split(':');
                  return (
                    <h1 className={`text-[200px] font-thin leading-none tracking-tighter ${
                      isDayTime ? 'text-gray-800' : 'text-white'
                    }`}>
                      {hours}<span className="mx-4">:</span><span className={isDayTime ? 'text-gray-700' : 'text-white/80'}>{minutes}</span>
                    </h1>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Right Portrait Section */}
      <div className="w-[600px]">
        <div className="h-full">
          <PortraitSlideshow
            images={settings.simpleDashboard?.backgroundImages || []}
            duration={settings.simpleDashboard?.slideshowDuration || 60}
          />
        </div>
      </div>
    </div>
  );
};

export default VerticalHorizonTemplate; 