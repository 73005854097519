import axios from 'axios';

export const searchLocations = async (query) => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`
    );
    
    console.log("Raw Nominatim response:", response.data);
    
    const mappedLocations = response.data.map(location => ({
      display_name: location.display_name,
      lat: parseFloat(location.lat),
      lon: parseFloat(location.lon)
    }));
    
    console.log("Mapped locations:", mappedLocations);
    return mappedLocations;
  } catch (error) {
    console.error('Error searching locations:', error);
    throw error;
  }
};

export const getLocationName = async (lat, lon) => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&addressdetails=1&lat=${lat}&lon=${lon}`
    );

    const { address, display_name } = response.data;

    if (address) {
      if (address.city) {
        return address.city;
      } else if (address.town) {
        return address.town;
      } else if (address.village) {
        return address.village;
      } else if (address.hamlet) {
        return address.hamlet;
      } else {
        // Fallback: return first part of display_name
        return display_name.split(',')[0].trim();
      }
    }

    return display_name;
  } catch (error) {
    console.error('Error getting location name:', error);
    throw error;
  }
};