import { useEffect, useRef, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { isExactPrayerTime } from '../utils/prayerTimeUtils';

export const useAdhanPlayer = (prayerTimes, settings) => {
  const [adhanLoaded, setAdhanLoaded] = useState(false);
  const [adhanPlayed, setAdhanPlayed] = useState(false);
  const audioRef = useRef(new Audio());
  const lastPlayedTimeRef = useRef(null);

  useEffect(() => {
    const loadAdhan = async () => {
      if (!settings.selectedAdhan?.regularPath || !settings.selectedAdhan?.fajrPath) {
        return;
      }

      try {
        // Pre-load both adhans
        const regularUrl = await getDownloadURL(ref(storage, settings.selectedAdhan.regularPath));
        const fajrUrl = await getDownloadURL(ref(storage, settings.selectedAdhan.fajrPath));
        
        // Store URLs for later use
        audioRef.current.dataset.regularUrl = regularUrl;
        audioRef.current.dataset.fajrUrl = fajrUrl;
        
        setAdhanLoaded(true);
      } catch (error) {
        console.error('Error loading adhan audio:', error);
      }
    };

    loadAdhan();
  }, [settings.selectedAdhan]);

  useEffect(() => {
    const checkAndPlayAdhan = () => {
      if (!prayerTimes?.times || !adhanLoaded) return;

      const now = new Date();
      const { isPrayerTime, currentPrayer } = isExactPrayerTime(prayerTimes.times, now, settings.tunedTimes);

      if (isPrayerTime && !adhanPlayed && lastPlayedTimeRef.current !== now.getTime()) {
        // Determine which adhan to play
        const isFajr = currentPrayer === 'Fajr';
        const url = isFajr ? 
          audioRef.current.dataset.fajrUrl : 
          audioRef.current.dataset.regularUrl;

        audioRef.current.src = url;
        audioRef.current.play()
          .then(() => {
            setAdhanPlayed(true);
            lastPlayedTimeRef.current = now.getTime();
          })
          .catch(error => console.error('Error playing adhan:', error));
      } else if (!isPrayerTime && adhanPlayed) {
        setAdhanPlayed(false);
      }
    };

    const interval = setInterval(checkAndPlayAdhan, 1000);
    return () => clearInterval(interval);
  }, [prayerTimes, adhanLoaded, adhanPlayed, settings.tunedTimes]);

  return { adhanLoaded, adhanPlayed };
}; 