import React, { useState, useEffect, useCallback } from 'react';
import { getFirestore, collection, query, where, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { linkTVDevice } from '../../services/tvLinkingService';
import { useSettings } from '../../contexts/SettingsContext';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import { SketchPicker } from 'react-color';
// import { fetchTVReciters } from '../../services/tvQuranService';
import { 
  Monitor, 
  Layout, 
  Image as ImageIcon, 
  Clock,
  Settings as SettingsIcon,
  Palette,
  // Users,
  Link,
  Unlink,
  Plus,
  Check,
  AlertCircle,
  Trash2,
  Save,
  UploadCloud,
  RefreshCw
} from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import { useImageUpload } from './SimpleDashboardSettings/hooks/useImageUpload';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

const TEMPLATES = [
  { 
    id: 'default', 
    name: 'Template 1', 
    description: 'Horizontal prayer times in the bottom right corner with the date in the bottom left corner and the time in the top right corner',
    theme: 'default'
  },
  { 
    id: 'three-column', 
    name: 'Template 2', 
    description: 'Vertical prayer times in the right bottom corner with a big clock in the top left corner and prominent islamic and gregorian dates',
    theme: 'three-column'
  },
  {
    id: 'template3',
    name: 'Template 3',
    description: 'Vertical prayer times in the bottom left corner with a big clock in the top left corner',
    theme: 'template3'
  },
  {
    id: 'template4',
    name: 'Template 4',
    description: 'Vertical prayer times in the bottom left corner with a big clock in the top left corner and dates below it',
    theme: 'template4'
  },
  {
    id: 'elegant-landscape',
    name: 'Elegant Landscape',
    description: 'Modern design with vertical prayer times spanning the bottom of the screen, current time at top right, and prayer countdown below it, keeping the middle empty for content',
    theme: 'elegant-landscape'
  }
];

/* 
const POPULAR_RECITER_IDS = [
  54,  // Abdulrahman Alsudaes
  123, // Mishary Alafasi
  51,  // Abdulbasit Abdulsamad
  31,  // Saud Al-Shuraim
  74,  // Ali Alhuthaifi
  102, // Maher Al Muaiqly
  30,  // Saad Al-Ghamdi
  118, // Mahmoud Khalil Al-Hussary
  89,  // Hani Arrifai
  92,  // Yasser Al-Dosari
  111, // Mohammed Jibreel
  112, // Mohammed Siddiq Al-Minshawi
  109, // Mohammed Ayyub
  4,   // Shaik Abu Bakr Al Shatri
  5,   // Ahmad Al-Ajmy
  1,   // Ibrahim Al-Akdar
  59,  // Abdullah Al-Mattrod
  32,  // Sahl Yassin
  35,  // Sami Al-Dosari
  41   // Saleh Al-Talib
];
*/

const getBackgroundImages = async () => {
  try {
    // Fetch default backgrounds from Firebase Storage
    const storage = getStorage();
    const backgroundsRef = ref(storage, 'default_backgrounds');
    let firebaseBackgrounds = [];
    
    try {
      const backgroundsList = await listAll(backgroundsRef);
      
      const imageFiles = backgroundsList.items.filter(item => 
        !item.fullPath.includes('/thumbnails/') && 
        (item.name.endsWith('.jpg') || item.name.endsWith('.png'))
      );

      firebaseBackgrounds = await Promise.all(
        imageFiles.map(async (imageRef) => {
          try {
            const imageUrl = await getDownloadURL(imageRef);
            const thumbnailRef = ref(storage, `default_backgrounds/thumbnails/thumb_${imageRef.name}`);
            let thumbnailUrl;
            
            try {
              thumbnailUrl = await getDownloadURL(thumbnailRef);
            } catch {
              // If thumbnail doesn't exist, use the full image
              thumbnailUrl = imageUrl;
            }
            
            return {
              path: imageUrl,
              thumbnail: thumbnailUrl,
              name: imageRef.name,
              isDefault: true
            };
          } catch (error) {
            console.error('Error processing image:', imageRef.name, error);
            return null;
          }
        })
      );
      
      firebaseBackgrounds = firebaseBackgrounds.filter(img => img !== null);
      return firebaseBackgrounds;
    } catch (error) {
      console.error('Error fetching Firebase background images:', error);
      return [];
    }
  } catch (error) {
    console.error('Error fetching background images:', error);
    return [];
  }
};

const TVSettingsPage = () => {
  const [linkingCode, setLinkingCode] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [linkedTVs, setLinkedTVs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMainColorPicker, setShowMainColorPicker] = useState(false);
  const [showAccentColorPicker, setShowAccentColorPicker] = useState(false);
  const { settings, updateSettings } = useSettings();
  const [backgroundImages, setBackgroundImages] = useState([]);
  const { isDarkMode } = useDarkMode();
  const [userImages, setUserImages] = useState([]);
  const [localTickerText, setLocalTickerText] = useState('');
  const [isTickerSaving, setIsTickerSaving] = useState(false);
  const [localOpacity, setLocalOpacity] = useState(0.5);
  const [isOpacitySaving, setIsOpacitySaving] = useState(false);
  const [slideshowEnabled, setSlideshowEnabled] = useState(false);
  const [localSlideshowDuration, setLocalSlideshowDuration] = useState(60);
  const [selectedSlideshowImages, setSelectedSlideshowImages] = useState([]);
  const [isSlideshowDurationSaving, setIsSlideshowDurationSaving] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  
  // Define success and error handlers for the useImageUpload hook
  const handleSuccess = (message) => {
    setStatus(message);
    setError('');
  };
  
  const handleError = (message) => {
    setError(message);
    setStatus('');
  };

  const { fileInputRef, uploadingImage, uploadProgress, backgroundImages: imageBackgrounds, userImages: imageUserImages, error: imageError, handleCustomImageUpload, handleImageSelect: selectImage, handleDeleteImage: deleteImage, refreshExpiredUrls, clearImageCache } = useImageUpload(settings?.tvTemplate, handleSuccess, handleError);

  // Initialize slideshow settings from stored settings
  useEffect(() => {
    setSlideshowEnabled(settings?.tvSlideshowEnabled ?? false);
    setLocalSlideshowDuration(settings?.tvSlideshowDuration ?? 60);
    setSelectedSlideshowImages(settings?.tvSlideshowImages ?? []);
  }, [settings?.tvSlideshowEnabled, settings?.tvSlideshowDuration, settings?.tvSlideshowImages]);

  // Initialize the local ticker text state from settings
  useEffect(() => {
    setLocalTickerText(settings?.tvTickerText || '');
  }, [settings?.tvTickerText]);

  // Initialize the local opacity state from settings
  useEffect(() => {
    setLocalOpacity(settings?.tvOverlayOpacity || 0.5);
  }, [settings?.tvOverlayOpacity]);

  // Remove the debounced function and add a direct save function
  const saveTickerText = async () => {
    try {
      setIsTickerSaving(true);
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      await updateDoc(userSettingsDoc, {
        tvTickerText: localTickerText
      });
      
      // Update settings context
      updateSettings({
        ...settings,
        tvTickerText: localTickerText
      });
      
      setStatus('Ticker text saved successfully!');
      
      // Clear status message after 2 seconds
      setTimeout(() => {
        setStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error saving ticker text:', error);
      setError('Failed to save ticker text');
    } finally {
      setIsTickerSaving(false);
    }
  };

  // Add these style constants based on the design system
  const sectionStyles = {
    container: `space-y-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`,
    header: `flex items-center gap-3 mb-6`,
    headerIcon: isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600",
    headerText: `text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`,
    card: `p-6 rounded-[24px] border transition-all duration-200 ${
      isDarkMode 
        ? 'bg-[#111111] border-gray-800 shadow-lg shadow-black/5' 
        : 'bg-white border-gray-100 shadow-sm'
    }`,
    button: `px-4 py-2.5 rounded-xl transition-all duration-300 shadow-sm hover:shadow-md flex items-center gap-2 ${
      isDarkMode
        ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
        : 'bg-emerald-600 text-white hover:bg-emerald-700'
    }`,
    input: `w-full px-4 py-2.5 rounded-xl border transition-colors ${
      isDarkMode
        ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50'
        : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
    }`
  };

  useEffect(() => {
    fetchLinkedTVs();
  }, []);

  useEffect(() => {
    const loadBackgroundImages = async () => {
      const images = await getBackgroundImages();
      console.log('Loaded background images:', images);
      setBackgroundImages(images);
    };
    loadBackgroundImages();
  }, []);

  useEffect(() => {
    const fetchUserImages = async () => {
      try {
        console.log('Fetching user images for TV settings...');
        const auth = getAuth();
        const db = getFirestore();
        const storage = getStorage();
        const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
        const docSnap = await getDoc(userSettingsDoc);
        
        if (docSnap.exists() && docSnap.data().userImages) {
          const images = docSnap.data().userImages;
          console.log(`Found ${images.length} user images in Firestore`);
          
          // Filter out any invalid images or duplicates
          const validImages = images.filter((image, index, self) => {
            // Skip if this image has no valid path
            if (!image.path) {
              console.warn('Skipping image with no valid path:', image);
              return false;
            }
            
            // Filter out duplicates based on path
            return index === self.findIndex(img => img.path === image.path);
          });
          
          // Convert paths to URLs for each image
          const processedImages = await Promise.all(validImages.map(async (image) => {
            try {
              // Create a normalized image object with fallbacks
              const normalizedImage = { ...image };
              
              // For each path, get a download URL if not already available
              if (image.path && (!image.url && !image.original)) {
                try {
                  const imageRef = ref(storage, image.path);
                  const url = await getDownloadURL(imageRef);
                  normalizedImage.url = url;
                  normalizedImage.original = url; // For backward compatibility
                } catch (error) {
                  console.error(`Error getting URL for path ${image.path}:`, error);
                }
              }
              
              // Get thumbnail URL if not already available
              if (image.thumbnailPath && !image.thumbnail) {
                try {
                  const thumbnailRef = ref(storage, image.thumbnailPath);
                  normalizedImage.thumbnail = await getDownloadURL(thumbnailRef);
                } catch (error) {
                  console.warn(`Error getting thumbnail URL, using main image:`, error);
                  // Fallback to the main image URL
                  normalizedImage.thumbnail = normalizedImage.url || normalizedImage.original;
                }
              }
              
              // Get display version URL if not already available
              if (image.displayPath && (!image.display)) {
                try {
                  const displayRef = ref(storage, image.displayPath);
                  normalizedImage.display = await getDownloadURL(displayRef);
                } catch (error) {
                  console.warn(`Error getting display URL:`, error);
                  // Fallback to the main image URL
                  normalizedImage.display = normalizedImage.url || normalizedImage.original;
                }
              }
              
              // Ensure all images have a URL field with fallbacks for backward compatibility
              normalizedImage.url = normalizedImage.url || normalizedImage.original || normalizedImage.display;
              normalizedImage.thumbnail = normalizedImage.thumbnail || normalizedImage.url;
              
              return normalizedImage;
            } catch (e) {
              console.error('Error processing image:', e);
              return image; // Return original image as fallback
            }
          }));
          
          console.log(`Processed ${processedImages.length} valid user images`);
          setUserImages(processedImages.filter(img => img.url || img.original || img.display));
        } else {
          console.log('No user images found in Firestore');
        }
      } catch (error) {
        console.error('Error fetching user images:', error);
      }
    };

    fetchUserImages();
  }, [settings.userImages]);

  const fetchLinkedTVs = async () => {
    try {
      setIsLoading(true);
      const auth = getAuth();
      const db = getFirestore();
      
      const tvQuery = query(
        collection(db, 'tv_devices'),
        where('user_id', '==', auth.currentUser.uid)
      );

      const querySnapshot = await getDocs(tvQuery);
      const tvs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        linked_at: doc.data().linked_at?.toDate()?.toLocaleString() || 'Unknown'
      }));

      setLinkedTVs(tvs);
      
      // Update settings with linked TVs
      updateSettings({
        tvDevices: tvs.reduce((acc, tv) => ({
          ...acc,
          [tv.device_id]: {
            linked_at: tv.linked_at,
            device_id: tv.device_id
          }
        }), {})
      });
    } catch (err) {
      console.error('Error fetching linked TVs:', err);
      setError('Failed to fetch linked TVs');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlink = async (deviceId) => {
    try {
      if (!window.confirm('Are you sure you want to unlink this TV device?')) {
        return;
      }

      const db = getFirestore();
      // Only delete from tv_devices collection for TV devices
      await deleteDoc(doc(db, 'tv_devices', deviceId));
      
      // Update settings to remove the unlinked TV
      const updatedTVDevices = { ...settings.tvDevices };
      delete updatedTVDevices[deviceId];
      updateSettings({ tvDevices: updatedTVDevices });
      
      setStatus(`TV device ${deviceId} successfully unlinked`);
      // Refresh the list
      await fetchLinkedTVs();
    } catch (err) {
      console.error('Error unlinking TV:', err);
      setError('Failed to unlink TV device');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await linkTVDevice(linkingCode);
      setStatus('TV successfully linked!');
      setError('');
      setLinkingCode('');
      
      // Update settings with the newly linked TV
      if (result && result.device_id) {
        updateSettings({
          tvDevices: {
            ...settings.tvDevices,
            [result.device_id]: {
              linked_at: new Date().toLocaleString(),
              device_id: result.device_id
            }
          }
        });
      }
      
      // Refresh the list of linked TVs
      await fetchLinkedTVs();
    } catch (err) {
      setError('Failed to link TV. Please check the code and try again.');
      setStatus('');
    }
  };

  const handleImageUpload = async (e, imageType) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsImageUploading(true);
      const auth = getAuth();
      const storage = getStorage();
      
      // Check if image with same name already exists
      const existingImage = userImages.find(img => img.name === file.name);
      if (existingImage) {
        setError('An image with this name already exists');
        return;
      }

      // Create a unique filename
      const timestamp = Date.now();
      const filename = `${timestamp}-${file.name}`;
      
      // Use the consistent folder structure like SimpleDashboardSettings 
      const originalPath = `user_images/${auth.currentUser.uid}/original/${filename}`;
      const thumbnailPath = `user_images/${auth.currentUser.uid}/original/thumbnails/${filename}`;
      
      const storageRef = ref(storage, originalPath);
      
      // Upload the file
      const snapshot = await uploadBytes(storageRef, file);
      
      // Get the download URL
      const imageUrl = await getDownloadURL(snapshot.ref);

      // Create new image object with the updated structure
      const newImage = {
        path: originalPath,
        url: imageUrl,
        name: file.name,
        thumbnail: imageUrl, // Initially use the full image as thumbnail
        thumbnailPath: thumbnailPath,
        timestamp,
        isDefault: false
      };
      
      // Update Firestore with new image
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      await updateDoc(userSettingsDoc, {
        userImages: [...userImages, newImage]
      });

      // Update local state
      setUserImages(prev => [...prev, newImage]);
      
      // If this is being set as background image, update that too
      if (imageType === 'tvBackgroundImage') {
        await updateDoc(userSettingsDoc, {
          tvBackgroundImage: imageUrl
        });
        updateSettings({
          ...settings,
          tvBackgroundImage: imageUrl
        });
      }

      setStatus('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image:', error);
      setError(`Failed to upload image: ${error.message}`);
    } finally {
      setIsImageUploading(false);
    }
  };

  const handleDeleteImage = async (image) => {
    try {
      const auth = getAuth();
      const storage = getStorage();
      
      // Validate the image object
      if (!image || !image.path) {
        console.error('Invalid image object for deletion:', image);
        setError('Cannot delete this image (invalid image data)');
        return;
      }
      
      // Reference to the original file
      const imageRef = ref(storage, image.path);

      // Delete original image from Storage
      await deleteObject(imageRef);
      
      // If there's a thumbnail path, try to delete that too
      if (image.thumbnailPath) {
        try {
          const thumbnailRef = ref(storage, image.thumbnailPath);
          await deleteObject(thumbnailRef);
          console.log('Thumbnail deleted successfully');
        } catch (thumbnailError) {
          console.warn('Thumbnail might not exist, continuing:', thumbnailError);
          // Continue even if thumbnail deletion fails
        }
      }

      // Determine the correct image URL with fallbacks for comparison
      const imageUrl = image.isDefault ? image.path : (image.url || image.original || image.display);

      // Update Firestore
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      const updatedImages = userImages.filter(img => img.path !== image.path);
      await updateDoc(userSettingsDoc, {
        userImages: updatedImages
      });

      // If this was the background image, remove it
      if (settings.tvBackgroundImage === imageUrl) {
        await updateDoc(userSettingsDoc, {
          tvBackgroundImage: null
        });
        updateSettings({
          ...settings,
          tvBackgroundImage: null
        });
      }

      // Update local state
      setUserImages(updatedImages);
      setStatus('Image deleted successfully!');
    } catch (error) {
      console.error('Error deleting image:', error);
      setError(`Failed to delete image: ${error.message}`);
    }
  };

  const handleTemplateChange = async (templateId) => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      await updateDoc(userSettingsDoc, {
        tvTemplate: templateId
      });
      
      // Update local settings
      updateSettings({
        ...settings,
        tvTemplate: templateId
      });
      
      setStatus('Template updated successfully!');
    } catch (error) {
      console.error('Error updating template:', error);
      setError('Failed to update template');
    }
  };

  const handleColorChange = async (colorType, color) => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      const fieldName = colorType === 'main' ? 'tvMainColor' : 'tvAccentColor';
      
      await updateDoc(userSettingsDoc, {
        [fieldName]: color.hex
      });
      
      // Update local settings
      updateSettings({
        ...settings,
        [fieldName]: color.hex
      });
      
      setStatus(`${colorType === 'main' ? 'Main' : 'Accent'} color updated successfully!`);
    } catch (error) {
      console.error('Error updating color:', error);
      setError('Failed to update color');
    }
  };

  // Add this function to initialize default colors if they don't exist
  useEffect(() => {
    const initializeDefaultColors = async () => {
      try {
        const auth = getAuth();
        const db = getFirestore();
        const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
        
        // If tvMainColor doesn't exist, set it to white
        if (!settings.tvMainColor) {
          await updateDoc(userSettingsDoc, {
            tvMainColor: '#FFFFFF'
          });
          
          updateSettings({
            ...settings,
            tvMainColor: '#FFFFFF'
          });
        }
        
        // If tvAccentColor doesn't exist, set it to green
        if (!settings.tvAccentColor) {
          await updateDoc(userSettingsDoc, {
            tvAccentColor: '#4CAF50'
          });
          
          updateSettings({
            ...settings,
            tvAccentColor: '#4CAF50'
          });
        }
      } catch (error) {
        console.error('Error initializing default colors:', error);
      }
    };

    initializeDefaultColors();
  }, []);

  const handleTickerToggle = async () => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      const newValue = !settings?.tvTickerEnabled;
      
      await updateDoc(userSettingsDoc, {
        tvTickerEnabled: newValue
      });
      
      // Update local settings
      updateSettings({
        ...settings,
        tvTickerEnabled: newValue
      });
      
      setStatus('Ticker setting updated successfully!');
    } catch (error) {
      console.error('Error updating ticker setting:', error);
      setError('Failed to update ticker setting');
    }
  };

  const handleOverlayToggle = async () => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      const newValue = !settings?.tvOverlayEnabled;
      
      await updateDoc(userSettingsDoc, {
        tvOverlayEnabled: newValue
      });
      
      // Update local settings
      updateSettings({
        ...settings,
        tvOverlayEnabled: newValue
      });
      
      setStatus('Overlay setting updated successfully!');
    } catch (error) {
      console.error('Error updating overlay setting:', error);
      setError('Failed to update overlay setting');
    }
  };

  // Update the handleOpacityChange function to only update local state
  const handleOpacityChange = (value) => {
    // Ensure value is between 0 and 1
    const opacity = Math.min(Math.max(parseFloat(value) || 0, 0), 1);
    setLocalOpacity(opacity);
  };

  // Add a new function to save opacity to Firebase when the slider interaction is complete
  const saveOpacity = async () => {
    try {
      setIsOpacitySaving(true);
      
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      await updateDoc(userSettingsDoc, {
        tvOverlayOpacity: localOpacity
      });
      
      // Update settings context
      updateSettings({
        ...settings,
        tvOverlayOpacity: localOpacity
      });
      
      setStatus('Overlay opacity saved successfully!');
      
      // Clear status message after 2 seconds
      setTimeout(() => {
        setStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error saving overlay opacity:', error);
      setError('Failed to save overlay opacity');
    } finally {
      setIsOpacitySaving(false);
    }
  };

  // Add this useEffect to initialize default values for both ticker and overlay
  useEffect(() => {
    const initializeDefaultSettings = async () => {
      try {
        const auth = getAuth();
        const db = getFirestore();
        const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
        
        const updates = {};
        
        // Set default ticker enabled if not set
        if (settings?.tvTickerEnabled === undefined) {
          updates.tvTickerEnabled = true;
        }
        
        // Set default overlay enabled if not set
        if (settings?.tvOverlayEnabled === undefined) {
          updates.tvOverlayEnabled = true;
        }
        
        // Set default overlay opacity if not set
        if (settings?.tvOverlayOpacity === undefined) {
          updates.tvOverlayOpacity = 0.5;
        }
        
        if (Object.keys(updates).length > 0) {
          await updateDoc(userSettingsDoc, updates);
          updateSettings({
            ...settings,
            ...updates
          });
        }
      } catch (error) {
        console.error('Error initializing default settings:', error);
      }
    };

    initializeDefaultSettings();
  }, []);

  const handleImageSelect = async (image) => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      // Validate the image object
      if (!image) {
        console.error('Invalid image object:', image);
        setError('Invalid image selected');
        return;
      }
      
      // Get the correct path/url to store - support both old and new formats with fallbacks
      const imageUrl = image.isDefault 
        ? image.path 
        : (image.url || image.original || image.display);
      
      // Validate the imageUrl
      if (!imageUrl) {
        console.error('Invalid image URL for image:', image);
        setError('This image cannot be selected (invalid URL)');
        return;
      }
      
      // If slideshow is enabled, handle selection for slideshow
      if (slideshowEnabled) {
        handleSlideshowImageSelect(image);
        return;
      }
      
      await updateDoc(userSettingsDoc, {
        tvBackgroundImage: imageUrl
      });
      
      // Update local settings
      updateSettings({
        ...settings,
        tvBackgroundImage: imageUrl
      });
      
      setStatus('Background image updated successfully!');
    } catch (error) {
      console.error('Error updating background image:', error);
      setError(`Failed to update background image: ${error.message}`);
    }
  };
  
  // New function to handle slideshow toggle
  const handleSlideshowToggle = async () => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      const newValue = !slideshowEnabled;
      setSlideshowEnabled(newValue);
      
      await updateDoc(userSettingsDoc, {
        tvSlideshowEnabled: newValue
      });
      
      // Update settings context
      updateSettings({
        ...settings,
        tvSlideshowEnabled: newValue
      });
      
      setStatus('Slideshow setting updated successfully!');
    } catch (error) {
      console.error('Error updating slideshow setting:', error);
      setError('Failed to update slideshow setting');
    }
  };
  
  // New function to handle slideshow duration change and save
  const saveSlideshowDuration = async () => {
    try {
      setIsSlideshowDurationSaving(true);
      
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      await updateDoc(userSettingsDoc, {
        tvSlideshowDuration: localSlideshowDuration
      });
      
      // Update settings context
      updateSettings({
        ...settings,
        tvSlideshowDuration: localSlideshowDuration
      });
      
      setStatus('Slideshow duration saved successfully!');
      
      // Clear status message after 2 seconds
      setTimeout(() => {
        setStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error saving slideshow duration:', error);
      setError('Failed to save slideshow duration');
    } finally {
      setIsSlideshowDurationSaving(false);
    }
  };
  
  // New function to handle selecting images for slideshow
  const handleSlideshowImageSelect = async (image) => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      
      // Get the image URL
      const imageUrl = image.isDefault 
        ? image.path 
        : (image.url || image.original || image.display);
      
      // Check if image is already selected
      const isSelected = selectedSlideshowImages.includes(imageUrl);
      let updatedImages;
      
      if (isSelected) {
        // Remove from selection
        updatedImages = selectedSlideshowImages.filter(url => url !== imageUrl);
      } else {
        // Add to selection
        updatedImages = [...selectedSlideshowImages, imageUrl];
      }
      
      // Update state
      setSelectedSlideshowImages(updatedImages);
      
      // Update Firestore
      await updateDoc(userSettingsDoc, {
        tvSlideshowImages: updatedImages
      });
      
      // Update settings context
      updateSettings({
        ...settings,
        tvSlideshowImages: updatedImages
      });
      
      setStatus(`Image ${isSelected ? 'removed from' : 'added to'} slideshow`);
    } catch (error) {
      console.error('Error updating slideshow images:', error);
      setError('Failed to update slideshow images');
    }
  };
  
  // Function to check if an image is selected for slideshow
  const isImageSelectedForSlideshow = (image) => {
    const imageUrl = image.isDefault 
      ? image.path 
      : (image.url || image.original || image.display);
    
    return selectedSlideshowImages.includes(imageUrl);
  };

  useEffect(() => {
    // Call refreshExpiredUrls when the component mounts and whenever userImages change
    if (userImages.length > 0) {
      refreshExpiredUrls();
    }
  }, [userImages.length, refreshExpiredUrls]);
  
  // Add error recovery function
  const handleImageLoadingIssues = async () => {
    try {
      setStatus('Refreshing images...');
      setError('');
      
      // First clear the image cache
      await clearImageCache();
      
      // Get fresh state from Firestore
      const auth = getAuth();
      const db = getFirestore();
      const storage = getStorage();
      const userSettingsDoc = doc(db, 'userSettings', auth.currentUser.uid);
      const docSnap = await getDoc(userSettingsDoc);
      
      if (docSnap.exists() && docSnap.data().userImages) {
        const images = docSnap.data().userImages;
        
        // Generate fresh URLs for all images
        const refreshedImages = await Promise.all(images.map(async (image) => {
          try {
            if (!image.path) return image; // Skip if no path
            
            // Generate fresh URLs for all paths
            const newImage = { ...image };
            
            // Main image URL
            try {
              const imageRef = ref(storage, image.path);
              newImage.url = await getDownloadURL(imageRef);
              newImage.original = newImage.url; // For backward compatibility
            } catch (e) {
              console.error('Error refreshing main image URL:', e);
            }
            
            // Display image URL
            if (image.displayPath) {
              try {
                const displayRef = ref(storage, image.displayPath);
                newImage.display = await getDownloadURL(displayRef);
              } catch (e) {
                console.error('Error refreshing display image URL:', e);
                newImage.display = newImage.url; // Fallback
              }
            }
            
            // Thumbnail URL
            if (image.thumbnailPath) {
              try {
                const thumbnailRef = ref(storage, image.thumbnailPath);
                newImage.thumbnail = await getDownloadURL(thumbnailRef);
              } catch (e) {
                console.error('Error refreshing thumbnail URL:', e);
                newImage.thumbnail = newImage.url; // Fallback
              }
            }
            
            return newImage;
          } catch (error) {
            console.error('Error refreshing image:', error);
            return image; // Return original as fallback
          }
        }));
        
        // Update state with fresh images
        setUserImages(refreshedImages);
        
        // Also refresh expired URLs from useImageUpload hook
        if (refreshExpiredUrls) {
          await refreshExpiredUrls();
        }
      }
      
      setStatus('Images refreshed. Your images should now display correctly.');
    } catch (error) {
      console.error('Error handling image loading issues:', error);
      setError('Failed to refresh images. Please try again.');
    }
  };

  return (
    <div className="space-y-12 py-8">
      {/* TV Connection Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <Monitor className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>TV Connection</h3>
        </div>

        <div className={sectionStyles.card}>
          <h4 className="text-base font-medium mb-4">Link New TV</h4>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                value={linkingCode}
                onChange={(e) => setLinkingCode(e.target.value)}
                placeholder="Enter 6-digit code"
                maxLength={6}
                pattern="\d{6}"
                required
                className={`${sectionStyles.input} w-32 text-center text-xl tracking-widest`}
              />
              <button 
                type="submit" 
                className={`${sectionStyles.button} sm:w-[200px] justify-center`}
              >
                <Link size={18} />
                <span>Link TV</span>
              </button>
            </div>
          </form>

          {/* Linked TVs Table */}
          <div className="mt-8">
            <h4 className="text-base font-medium mb-4">Linked TVs</h4>
            {isLoading ? (
              <div className="text-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500 mx-auto"></div>
              </div>
            ) : linkedTVs.length > 0 ? (
              <div className="overflow-x-auto">
                <table className={`min-w-full divide-y ${
                  isDarkMode ? 'divide-gray-800' : 'divide-gray-200'
                }`}>
                  <thead className={isDarkMode ? 'bg-[#161616]' : 'bg-gray-50'}>
                    <tr>
                      <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Device ID
                      </th>
                      <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Linked At
                      </th>
                      <th className={`px-6 py-3 text-right text-xs font-medium uppercase tracking-wider ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className={`divide-y ${
                    isDarkMode 
                      ? 'bg-[#111111] divide-gray-800' 
                      : 'bg-white divide-gray-200'
                  }`}>
                    {linkedTVs.map((tv) => (
                      <tr key={tv.id}>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-900'
                        }`}>
                          {tv.device_id}
                        </td>
                        <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          {tv.linked_at}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleUnlink(tv.device_id)}
                            className={`transition-colors ${
                              isDarkMode
                                ? 'text-red-400 hover:text-red-300'
                                : 'text-red-600 hover:text-red-900'
                            }`}
                          >
                            Unlink
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className={`text-center py-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                No TVs linked yet
              </p>
            )}
          </div>
        </div>
      </section>

      {/* Layout Settings Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <Layout className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Layout Settings</h3>
        </div>

        <div className={sectionStyles.card}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {TEMPLATES.map((template) => (
              <div
                key={template.id}
                className={`p-4 rounded-lg border-2 cursor-pointer transition-all ${
                  settings?.tvTemplate === template.id
                    ? isDarkMode
                      ? 'border-emerald-500/50 bg-emerald-500/10'  // Dark mode selected state
                      : 'border-emerald-500 bg-emerald-50'         // Light mode selected state
                    : isDarkMode
                      ? 'border-gray-800 hover:border-emerald-500/30 hover:bg-emerald-500/5'  // Dark mode hover
                      : 'border-gray-200 hover:border-emerald-200'  // Light mode hover
                }`}
                onClick={() => handleTemplateChange(template.id)}
              >
                <h4 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {template.name}
                </h4>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mt-1`}>
                  {template.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Ticker Settings Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <Clock className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Ticker Settings</h3>
        </div>

        <div className={sectionStyles.card}>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Enable ticker</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings?.tvTickerEnabled ?? true}
                  onChange={handleTickerToggle}
                  className="sr-only peer"
                />
                <div className={`w-11 h-6 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all ${
                  isDarkMode 
                    ? 'bg-gray-700 peer-checked:bg-emerald-500' 
                    : 'bg-gray-200 peer-checked:bg-emerald-600'
                }`}></div>
              </label>
            </div>

            {settings?.tvTickerEnabled && (
              <div>
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  Ticker Text
                </label>
                <div className="space-y-2">
                  <textarea
                    value={localTickerText}
                    onChange={(e) => {
                      // Only update local state without saving to Firebase
                      setLocalTickerText(e.target.value);
                    }}
                    placeholder="Enter text for the ticker..."
                    className={sectionStyles.input}
                    rows={3}
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={saveTickerText}
                      disabled={isTickerSaving}
                      className={`${sectionStyles.button} ${
                        isTickerSaving ? 'opacity-70 cursor-not-allowed' : ''
                      }`}
                    >
                      {isTickerSaving ? (
                        <div className="flex items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                          <span>Saving...</span>
                        </div>
                      ) : (
                        <>
                          <Save size={16} />
                          <span>Save Text</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Overlay Settings Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <SettingsIcon className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Overlay Settings</h3>
        </div>

        <div className={sectionStyles.card}>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Enable background overlay</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={settings?.tvOverlayEnabled ?? true}
                  onChange={handleOverlayToggle}
                  className="sr-only peer"
                />
                <div className={`w-11 h-6 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all ${
                  isDarkMode 
                    ? 'bg-gray-700 peer-checked:bg-emerald-500' 
                    : 'bg-gray-200 peer-checked:bg-emerald-600'
                }`}></div>
              </label>
            </div>

            {settings?.tvOverlayEnabled && (
              <div>
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>
                  Overlay Opacity: {localOpacity.toFixed(2)}
                </label>
                <div className="space-y-2">
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.05"
                    value={localOpacity}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    onChange={(e) => handleOpacityChange(e.target.value)}
                  />
                  <div className="flex justify-end">
                    <button
                      onClick={saveOpacity}
                      disabled={isOpacitySaving}
                      className={`${sectionStyles.button} ${
                        isOpacitySaving ? 'opacity-70 cursor-not-allowed' : ''
                      }`}
                    >
                      {isOpacitySaving ? (
                        <div className="flex items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                          <span>Saving...</span>
                        </div>
                      ) : (
                        <>
                          <Save size={16} />
                          <span>Save Opacity</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Background Image Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <ImageIcon className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Background Image</h3>
        </div>

        <div className={sectionStyles.card}>
          {/* Slideshow Toggle */}
          <div className="flex items-center justify-between mb-6">
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>Enable background slideshow</span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={slideshowEnabled}
                onChange={handleSlideshowToggle}
                className="sr-only peer"
              />
              <div className={`w-11 h-6 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all ${
                isDarkMode 
                  ? 'bg-gray-700 peer-checked:bg-emerald-500' 
                  : 'bg-gray-200 peer-checked:bg-emerald-600'
              }`}></div>
            </label>
          </div>
          
          {/* Slideshow Duration */}
          {slideshowEnabled && (
            <div className="mb-6 p-4 rounded-lg bg-opacity-10 bg-emerald-500">
              <div className="flex items-center gap-3 mb-4">
                <Clock className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
                <h3 className={`text-base font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                  Slideshow Duration: {localSlideshowDuration} seconds
                </h3>
              </div>
              <div className="flex flex-col gap-2">
                <input
                  type="range"
                  min="10"
                  max="300"
                  step="5"
                  value={localSlideshowDuration}
                  onChange={(e) => setLocalSlideshowDuration(Number(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />
                <div className="flex justify-between text-xs text-gray-500 px-1">
                  <span>10s</span>
                  <span>60s</span>
                  <span>120s</span>
                  <span>180s</span>
                  <span>300s</span>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    onClick={saveSlideshowDuration}
                    disabled={isSlideshowDurationSaving}
                    className={`${sectionStyles.button} ${
                      isSlideshowDurationSaving ? 'opacity-70 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSlideshowDurationSaving ? (
                      <div className="flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                        <span>Saving...</span>
                      </div>
                    ) : (
                      <>
                        <Save size={16} />
                        <span>Save Duration</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
              
              {slideshowEnabled && selectedSlideshowImages.length === 0 && (
                <div className="mt-4 p-3 bg-yellow-500/10 border border-yellow-500/30 rounded-lg">
                  <div className="flex items-center gap-2 text-yellow-500">
                    <AlertCircle size={16} />
                    <p className="text-sm">Select at least one image for the slideshow below</p>
                  </div>
                </div>
              )}
              
              {slideshowEnabled && selectedSlideshowImages.length > 0 && (
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {selectedSlideshowImages.length} {selectedSlideshowImages.length === 1 ? 'image' : 'images'} selected for slideshow
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Help text based on mode */}
          <div className="mb-4 text-sm text-gray-500">
            {slideshowEnabled ? (
              <p>Select multiple images below to include in the slideshow. Click an image to add or remove it.</p>
            ) : (
              <p>Click an image to set it as the background. Enable slideshow above to use multiple images.</p>
            )}
          </div>

          {/* Image Grid */}
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
            {[...backgroundImages, ...userImages].map((image) => {
              // Determine image URL and thumbnail based on image type with fallbacks
              const imageUrl = image.isDefault 
                ? image.path 
                : (image.url || image.original || image.display);
                
              // Skip rendering invalid images
              if (!imageUrl) {
                console.warn('Skipping invalid image in grid:', image);
                return null;
              }
              
              const thumbnailUrl = image.thumbnail || imageUrl;
              // Change how selected is determined based on mode
              const isSelected = slideshowEnabled 
                ? isImageSelectedForSlideshow(image)
                : settings?.tvBackgroundImage === imageUrl;
              
              return (
                <div 
                  key={imageUrl}
                  className={`
                    relative aspect-video rounded-xl overflow-hidden cursor-pointer
                    hover:ring-2 hover:ring-emerald-500 transition-all
                    ${isSelected ? 'ring-4 ring-emerald-500' : ''}
                  `}
                  onClick={() => handleImageSelect(image)}
                >
                  <img 
                    src={thumbnailUrl}
                    alt={image.name || "Background image"}
                    className="w-full h-full object-cover"
                  />
                  {isSelected && (
                    <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                      <Check className="w-8 h-8 text-white" />
                    </div>
                  )}
                  {/* Delete button for user images */}
                  {!image.isDefault && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteImage(image);
                      }}
                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1.5 hover:bg-red-600 transition-colors"
                    >
                      <Trash2 size={14} />
                    </button>
                  )}
                </div>
              );
            }).filter(Boolean)} {/* Filter out null entries from invalid images */}
          </div>

          {/* Upload Button */}
          <div className="mt-4">
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => handleImageUpload(e, 'tvBackgroundImage')}
              className="hidden"
              id="background-image-upload"
            />
            <label
              htmlFor="background-image-upload"
              className={`cursor-pointer inline-flex items-center gap-2 px-4 py-2 rounded-xl ${
                isDarkMode
                  ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
                  : 'bg-emerald-600 text-white hover:bg-emerald-700'
              }`}
            >
              <Plus size={18} />
              Upload Image
            </label>
            
            {/* Add refresh button next to upload */}
            <button
              onClick={handleImageLoadingIssues}
              className="flex items-center gap-2 px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
              disabled={isImageUploading || uploadingImage}
            >
              <RefreshCw size={18} />
              Refresh Images
            </button>
          </div>
        </div>
      </section>

      {/* Color Settings Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <Palette className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Color Settings</h3>
        </div>

        <div className={sectionStyles.card}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Main Color */}
            <div>
              <label className={`block text-sm font-medium mb-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Main Color
              </label>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => setShowMainColorPicker(!showMainColorPicker)}
                  className={`w-12 h-12 rounded-xl border-2 transition-colors ${
                    isDarkMode ? 'border-gray-800' : 'border-gray-200'
                  }`}
                  style={{ backgroundColor: settings?.tvMainColor || '#FFFFFF' }}
                />
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {settings?.tvMainColor || '#FFFFFF'}
                </span>
              </div>
              {showMainColorPicker && (
                <div className="absolute mt-2 z-10">
                  <div
                    className="fixed inset-0"
                    onClick={() => setShowMainColorPicker(false)}
                  />
                  <div className={isDarkMode ? 'dark-picker' : ''}>
                    <SketchPicker
                      color={settings?.tvMainColor || '#FFFFFF'}
                      onChange={(color) => handleColorChange('main', color)}
                      styles={{
                        default: {
                          picker: {
                            backgroundColor: isDarkMode ? '#1A1A1A' : '#FFFFFF',
                            boxShadow: isDarkMode ? '0 0 0 1px #333' : undefined,
                          },
                          input: {
                            backgroundColor: isDarkMode ? '#111111' : '#FFFFFF',
                            boxShadow: isDarkMode ? '0 0 0 1px #333' : undefined,
                            color: isDarkMode ? '#FFFFFF' : '#000000',
                          },
                          label: {
                            color: isDarkMode ? '#FFFFFF' : '#000000',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Accent Color */}
            <div>
              <label className={`block text-sm font-medium mb-2 ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Accent Color
              </label>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => setShowAccentColorPicker(!showAccentColorPicker)}
                  className={`w-12 h-12 rounded-xl border-2 transition-colors ${
                    isDarkMode ? 'border-gray-800' : 'border-gray-200'
                  }`}
                  style={{ backgroundColor: settings?.tvAccentColor || '#4CAF50' }}
                />
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {settings?.tvAccentColor || '#4CAF50'}
                </span>
              </div>
              {showAccentColorPicker && (
                <div className="absolute mt-2 z-10">
                  <div
                    className="fixed inset-0"
                    onClick={() => setShowAccentColorPicker(false)}
                  />
                  <div className={isDarkMode ? 'dark-picker' : ''}>
                    <SketchPicker
                      color={settings?.tvAccentColor || '#4CAF50'}
                      onChange={(color) => handleColorChange('accent', color)}
                      styles={{
                        default: {
                          picker: {
                            backgroundColor: isDarkMode ? '#1A1A1A' : '#FFFFFF',
                            boxShadow: isDarkMode ? '0 0 0 1px #333' : undefined,
                          },
                          input: {
                            backgroundColor: isDarkMode ? '#111111' : '#FFFFFF',
                            boxShadow: isDarkMode ? '0 0 0 1px #333' : undefined,
                            color: isDarkMode ? '#FFFFFF' : '#000000',
                          },
                          label: {
                            color: isDarkMode ? '#FFFFFF' : '#000000',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* 
        Reciters Management Section removed for now but code kept in the source
        for future reference if needed
      */}

      {/* Status Messages */}
      <AnimatePresence>
        {(status || error) && (
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={`fixed bottom-5 right-5 p-4 rounded-lg shadow-lg z-50 flex items-center gap-2 ${
              error
                ? isDarkMode 
                  ? 'bg-red-500/20 text-red-400' 
                  : 'bg-red-500 text-white'
                : isDarkMode 
                  ? 'bg-emerald-500/20 text-emerald-400' 
                  : 'bg-emerald-500 text-white'
            }`}
          >
            {error ? <AlertCircle size={18} /> : <Check size={18} />}
            {error || status}
          </motion.div>
        )}
      </AnimatePresence>

      {error && (
        <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          <p>{error}</p>
          <button 
            onClick={handleImageLoadingIssues} 
            className="mt-2 bg-red-600 text-white px-3 py-1 rounded text-sm hover:bg-red-700"
          >
            Refresh Images
          </button>
        </div>
      )}
    </div>
  );
};

export default TVSettingsPage;