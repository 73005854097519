import React from 'react';
import { Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

// We'll only use the components for their data rendering logic
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';

const AdhanClockTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  
  useAdhanPlayer(prayerTimes, settings);

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr'; // Return first prayer or default to Fajr
  };

  const nextPrayer = getNextPrayer();

  const formatTime = (timeString) => {
    const time = new Date();
    return time.toLocaleTimeString('en-US', { 
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).split(':').map((part, index) => 
      index === 2 ? `<span class="text-4xl text-white/40">:${part}</span>` : part
    ).join(':');
  };

  return (
    <div className="h-screen w-full relative text-white overflow-hidden">
      <CrossfadingBackground />
      
      {/* Fixed gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/95 to-transparent z-10" />

      {/* Content Wrapper */}
      <div className="relative h-full flex z-20">
        {/* Main Content - Left Side */}
        <div className="w-2/3 p-4 sm:p-6 lg:p-8 flex flex-col">
          {/* Location info */}
          <div className="flex items-center gap-2">
            <MapPin className="w-4 h-4 text-white/60" />
            <h1 className="text-sm sm:text-xl font-light text-white/60">
              {settings.location?.split(',')[0] || 'Location not set'}
            </h1>
          </div>

          {/* Flexible space */}
          <div className="flex-grow flex items-center justify-center">
            {/* Dua - Centered */}
            <div className="text-center w-full">
              <p className="text-4xl lg:text-5xl text-left text-white/60 leading-loose font-['Decotype_Thuluth']">
                {duas?.[currentDuaIndex]?.arabic || 'Loading...'}
              </p>
            </div>
          </div>

          {/* Large Countdown */}
          <SimplePrayerCountdownCard
            prayerTimes={prayerTimes}
            render={({ countdown, nextPrayer }) => (
              <div className="mb-0">
                <p className="text-white/40 text-xs tracking-widest uppercase mb-1">
                  TIME UNTIL {nextPrayer}
                </p>
                <div className={`text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-thin tracking-tighter ${accentColor.base} leading-none`}>
                  {countdown}
                </div>
              </div>
            )}
          />

          {/* Date */}
          <div className="flex items-center gap-4 mt-2">
            <SimpleDateCard 
              dateTime={dateTime}
              onDateChange={onDateChange}
              render={({ gregorian, hijri }) => (
                <>
                  <div className="text-white/40">
                    <p className="text-[10px] tracking-widest uppercase mb-0.5">Gregorian</p>
                    <p className="text-xs">{gregorian}</p>
                  </div>
                  <div className="text-white/40">
                    <p className="text-[10px] tracking-widest uppercase mb-0.5">Hijri</p>
                    <p className="text-xs">{hijri}</p>
                  </div>
                </>
              )}
            />
          </div>
        </div>

        {/* Prayer Times - Right Side */}
        <div className="w-1/3 relative">
          <div className="absolute right-0 top-0 bottom-0 w-[calc(100%+100px)] bg-gradient-to-l from-black/95 to-transparent">
            <div className="h-full flex flex-col p-4 sm:p-6 lg:p-8">
              {/* Prayer Times */}
              <div className="flex-1 space-y-3 sm:space-y-4 lg:space-y-6">
                <SimplePrayerTimesCard
                  prayerTimes={prayerTimes}
                  settings={settings}
                  render={({ prayers }) => (
                    <>
                      {prayers.map(([prayer, time]) => {
                        const Icon = prayer === 'Fajr' ? Sunrise :
                                    prayer === 'Maghrib' ? Sunset :
                                    prayer === 'Isha' ? Moon : Sun;
                        
                        const isNext = prayer === nextPrayer;
                        
                        return (
                          <div key={prayer}>
                            <div className="flex items-center justify-end gap-1.5 mb-0.5">
                              <p className={`${isNext ? accentColor.base : 'text-white'} text-xs tracking-widest uppercase`}>
                                {prayer}
                              </p>
                              <Icon className={`w-3.5 h-3.5 sm:w-4 sm:h-4 ${isNext ? accentColor.base : 'text-white'}`} />
                            </div>
                            <p className={`text-xl sm:text-2xl lg:text-3xl font-light text-right ${isNext ? accentColor.base : 'text-white'}`}>
                              {time}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}
                />
              </div>

              {/* Current Time */}
              <div className="mt-4">
                <p className="text-white/40 text-lg tracking-widest uppercase mb-1 text-right">CURRENT TIME</p>
                <SimpleTimeCard 
                  render={({ time }) => (
                    <div className="text-[10rem] font-extralight tracking-tight leading-[0.8] text-right">
                      {time}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdhanClockTemplate; 