import React from 'react';
import LoadingAnimation from './LoadingAnimation';
import AdhanClockTemplate from './templates/AdhanClockTemplate';
import ModernAdhanClockTemplate from './templates/ModernAdhanClockTemplate';
import ModernDramaticTemplate from './templates/ModernDramaticTemplate';
import MinimalistAdhanClockTemplate from './templates/MinimalistAdhanClockTemplate';
import HorizontalAdhanClockTemplate from './templates/HorizontalAdhanClockTemplate';
import BentoBoxAdhanClockTemplate from './templates/BentoBoxAdhanClockTemplate';
import ElegantLandscapeTemplate from './templates/ElegantLandscapeTemplate';
import ModernMinimalistTemplate from './templates/ModernMinimalistTemplate';
import ModernBasicTemplate from './templates/ModernBasicTemplate';
import ModernVerticalTemplate from './templates/ModernVerticalTemplate';
import ModernGlassTemplate from './templates/ModernGlassTemplate';
import LightAndBrightTemplate from './templates/LightAndBrightTemplate';
import PinkMinimalistTemplate from './templates/PinkMinimalistTemplate';
import AvantGardeTemplate from './templates/AvantGardeTemplate';
import PortraitGalleryTemplate from './templates/PortraitGalleryTemplate';
import VerticalHorizonTemplate from './templates/VerticalHorizonTemplate';

const DashboardTemplate = ({ templateId, dashboardData, onDuaChange }) => {
  if (!dashboardData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingAnimation />
      </div>
    );
  }

  const templates = {
    adhanClock: AdhanClockTemplate,
    modernAdhanClock: ModernAdhanClockTemplate,
    modernDramatic: ModernDramaticTemplate,
    minimalistAdhanClock: MinimalistAdhanClockTemplate,
    horizontalAdhanClock: HorizontalAdhanClockTemplate,
    bentoBoxAdhanClock: BentoBoxAdhanClockTemplate,
    elegantLandscape: ElegantLandscapeTemplate,
    modernMinimalist: ModernMinimalistTemplate,
    modernBasic: ModernBasicTemplate,
    modernVertical: ModernVerticalTemplate,
    modernGlass: ModernGlassTemplate,
    lightAndBright: LightAndBrightTemplate,
    pinkMinimalist: PinkMinimalistTemplate,
    avantGarde: AvantGardeTemplate,
    portraitGallery: PortraitGalleryTemplate,
    verticalHorizon: VerticalHorizonTemplate
  };

  const Template = templates[templateId] || templates.adhanClock;

  return (
    <Template
      prayerTimes={dashboardData.prayerTimes}
      tomorrowPrayerTimes={dashboardData.tomorrowPrayerTimes}
      dateTime={dashboardData.dateTime}
      duas={dashboardData.duas}
      currentDuaIndex={dashboardData.currentDuaIndex}
      onDuaChange={onDuaChange}
      hadiths={dashboardData.hadiths}
      currentHadithIndex={dashboardData.currentHadithIndex}
      settings={dashboardData.settings}
    />
  );
};

export default DashboardTemplate; 