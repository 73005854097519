import React, { useState, useEffect } from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';

const CustomAvatar = ({ src, alt, initials, size = 32 }) => {
  const { isDarkMode } = useDarkMode();
  const [imageError, setImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (src && src.includes('googleusercontent.com')) {
      // Add a cache buster for Google URLs to prevent rate limiting
      const cacheBuster = `?t=${Date.now()}`;
      setImageUrl(`${src}${cacheBuster}`);
    } else {
      setImageUrl(src);
    }
    setImageError(false);
  }, [src]);

  // Handle image load error
  const handleError = () => {
    console.log('Avatar image failed to load:', imageUrl);
    setImageError(true);
  };

  // If image failed to load or no src, show initials
  if (imageError || !imageUrl) {
    return (
      <div 
        className={`flex items-center justify-center rounded-full ${
          isDarkMode 
            ? 'bg-emerald-500/20 text-emerald-400' 
            : 'bg-emerald-100 text-emerald-600'
        }`}
        style={{ width: size, height: size }}
      >
        <span className="text-sm font-medium">{initials}</span>
      </div>
    );
  }

  // Show image if available
  return (
    <img
      src={imageUrl}
      alt={alt}
      onError={handleError}
      className="rounded-full object-cover"
      style={{ width: size, height: size }}
      crossOrigin="anonymous"
      referrerPolicy="no-referrer"
    />
  );
};

export default CustomAvatar;
