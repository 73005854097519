import React from 'react';
import QuranSettings from '../Quran/components/QuranSettings';
import useQuranSettings from '../Quran/hooks/useQuranSettings';
import { useNavigate } from 'react-router-dom';

const QuranSettingsWrapper = ({ inSettingsPage, onBack }) => {
  const navigate = useNavigate();
  const {
    loading,
    reciterOptions,
    languageOptions,
    translationOptions,
    selectedReciter,
    selectedLanguage,
    selectedTranslation,
    handleReciterChange,
    handleLanguageChange,
    handleTranslationChange,
    settings,
    updateQuranSettings
  } = useQuranSettings();

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleBackFallback = inSettingsPage 
    ? () => navigate(-1) 
    : onBack || (() => updateQuranSettings({ showSettings: false }));

  return (
    <QuranSettings
      onBack={handleBackFallback}
      hideHeader={inSettingsPage}
      // Options and handlers
      reciterOptions={reciterOptions}
      languageOptions={languageOptions}
      translationOptions={translationOptions}
      selectedReciter={selectedReciter}
      selectedLanguage={selectedLanguage}
      selectedTranslation={selectedTranslation}
      onReciterChange={handleReciterChange}
      onLanguageChange={handleLanguageChange}
      onTranslationChange={handleTranslationChange}
      // Display settings
      displayMode={settings.quran?.displayMode || 'with-translation'}
      showTransliteration={settings.quran?.showTransliteration || false}
      fontSize={settings.quran?.fontSize || 'text-lg'}
      quranMode={settings.quran?.mode || 'reading'}
      onDisplayModeChange={(mode) => updateQuranSettings({ displayMode: mode })}
      onTransliterationToggle={(show) => updateQuranSettings({ showTransliteration: show })}
      onQuranModeChange={(mode) => updateQuranSettings({ mode })}
      onFontSizeChange={(size) => updateQuranSettings({ fontSize: size })}
      // Reset handlers
      onResetMemorization={() => updateQuranSettings({ memorization: {} })}
      onResetReadingProgress={() => updateQuranSettings({ readingProgress: {} })}
    />
  );
};

export default QuranSettingsWrapper; 