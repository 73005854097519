import React, { useState, useEffect, useRef, useCallback } from 'react';
import { HiCheck, HiOutlineBookmark, HiOutlineAnnotation, HiRefresh, HiPencil, HiEye } from 'react-icons/hi';
import { loadFont, loadBismillahFont } from '../../../utils/fontLoader';
import useVerseReadTracking from '../../../hooks/useVerseReadTracking';

const VerseDisplay = React.forwardRef(({
  verse,
  index,
  isDarkMode,
  displayMode = 'with-translation',
  currentVerse,
  handleVerseClick,
  selectedTranslation,
  formatFootnotes,
  memorizedVerses = {},
  handleMemorizedToggle = () => {},
  handleNoteClick = () => {},
  repeatingVerse,
  toggleRepeat = () => {},
  notes = {},
  isRead,
  fontSize = 'text-base',
  showTransliteration = false,
  onVerseRead,
}, ref) => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const [isBismillahFontLoaded, setIsBismillahFontLoaded] = useState(false);
  const verseRef = useRef(null);

  const onVerseClick = useCallback(() => {
    handleVerseClick(index);
  }, [handleVerseClick, index]);

  const getMushafFont = (pageNumber) => {
    const paddedNumber = pageNumber.toString().padStart(3, '0');
    return `QCF_P${paddedNumber}`;
  };

  useEffect(() => {
    if (verse?.page_number) {
      loadFont(verse.page_number).then(() => {
        setIsFontLoaded(true);
      });
    }

    if (verse?.page_number) {
      loadBismillahFont().then(() => {
        setIsBismillahFontLoaded(true);
      });
    }
  }, [verse?.page_number]);

  if (!verse || !isFontLoaded || (verse?.page_number && !isBismillahFontLoaded)) return null;

  const surahNumber = parseInt(verse.verse_key?.split(':')[0]);
  const verseNumber = parseInt(verse.verse_key?.split(':')[1]);
  const isFirstVerseOfFatiha = surahNumber === 1 && verseNumber === 1;

  const isArabicOnly = displayMode === 'arabic-only';
  const translation = verse.translations?.find(t => t.resource_id?.toString() === selectedTranslation);
  const verseKey = verse.verse_key;
  const isMemorized = memorizedVerses[verseKey] || false;
  const hasNote = notes[verseKey];
  
  const renderBismillah = () => {
    const shouldShowBismillah = verseNumber === 1 && surahNumber !== 1 && surahNumber !== 9;
    
    if (!shouldShowBismillah) return null;
    
    return (
      <div 
        className={`text-center mb-8 text-xl ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}
        style={{
          fontFamily: getMushafFont(verse.page_number)
        }}
      >
        ﷽
      </div>
    );
  };

  const renderFatihaFirstVerse = () => {
    if (!isFirstVerseOfFatiha) return verse.text_uthmani;

    const [bismillah, ...rest] = verse.text_uthmani.split(' ');
    return (
      <>
        <span>{verse.text_uthmani}</span>
      </>
    );
  };

  const renderStyledVerseNumber = () => (
    <span className={`
      ${fontSize} 
      font-arabic 
      select-none
      opacity-70
      mx-2
    `}
    style={{
      fontFamily: 'KfgqpcHafsUthmanicScriptRegular-1jGEe'
    }}>
      {verseNumber?.toString().replace(/[0-9]/g, d => '٠١٢٣٤٥٦٧٨٩'[d])}
    </span>
  );

  if (isArabicOnly) {
    return (
      <>
        {renderBismillah()}
        <span
          ref={(element) => {
            verseRef.current = element;
            if (typeof ref === 'function') ref(element);
            else if (ref) ref.current = element;
          }}
          data-verse-id={verseKey}
          className={`
            ${fontSize === 'text-sm' ? 'text-base' :
              fontSize === 'text-base' ? 'text-lg' :
              fontSize === 'text-lg' ? 'text-xl' :
              fontSize === 'text-xl' ? 'text-2xl' :
              fontSize === 'text-2xl' ? 'text-3xl' :
              fontSize === 'text-3xl' ? 'text-4xl' :
              fontSize === 'text-4xl' ? 'text-5xl' :
              fontSize === 'text-5xl' ? 'text-6xl' :
              fontSize === 'text-6xl' ? 'text-7xl' : 'text-lg'}
            font-uthmani 
            cursor-pointer
            inline
            transition-colors duration-300
            ${isRead
              ? isDarkMode ? 'text-emerald-400/80' : 'text-emerald-600/80'
              : currentVerse === index 
                ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                : isDarkMode ? 'text-white' : 'text-black'}
          `}
          style={{
            fontFamily: getMushafFont(verse.page_number),
            direction: 'rtl',
            textAlign: 'right'
          }}
          onClick={onVerseClick}
        >
          {isFirstVerseOfFatiha ? renderFatihaFirstVerse() : verse.text_uthmani}
          {renderStyledVerseNumber()}
        </span>
      </>
    );
  }

  return (
    <div
      ref={(element) => {
        verseRef.current = element;
        if (typeof ref === 'function') ref(element);
        else if (ref) ref.current = element;
      }}
      data-verse-id={verseKey}
      className={`
        ${displayMode === 'arabic-only' ? 'inline-block' : 'block w-full p-4 border-b'}
        ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}
        ${currentVerse === index ? isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50' : ''}
        relative group
      `}
      onClick={onVerseClick}
    >
      {renderBismillah()}
      <div className="flex flex-col space-y-4">
        <div className="flex-grow">
          <div 
            className={`
              ${fontSize === 'text-sm' ? 'text-base' :
                fontSize === 'text-base' ? 'text-lg' :
                fontSize === 'text-lg' ? 'text-xl' :
                fontSize === 'text-xl' ? 'text-2xl' :
                fontSize === 'text-2xl' ? 'text-3xl' :
                fontSize === 'text-3xl' ? 'text-4xl' :
                fontSize === 'text-4xl' ? 'text-5xl' :
                fontSize === 'text-5xl' ? 'text-6xl' :
                fontSize === 'text-6xl' ? 'text-7xl' : 'text-lg'}
              font-uthmani 
              text-right
              leading-loose
              transition-colors duration-300
              ${isRead
                ? isDarkMode ? 'text-emerald-400/80' : 'text-emerald-600/80'
                : currentVerse === index 
                  ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                  : isDarkMode ? 'text-white' : 'text-gray-900'}
            `}
            style={{
              fontFamily: getMushafFont(verse.page_number),
              direction: 'rtl'
            }}
          >
            {isFirstVerseOfFatiha ? renderFatihaFirstVerse() : verse.text_uthmani}
            {renderStyledVerseNumber()}
          </div>

          {showTransliteration && (
            <p className="text-lg text-gray-600 dark:text-gray-400 mb-2 italic">
              {(() => {
                console.log('Verse translations:', verse.translations);
                const transliteration = verse.translations?.find(t => {
                  console.log('Checking translation:', t);
                  return t.resource_name?.toLowerCase() === 'transliteration';
                });
                console.log('Found transliteration:', transliteration);
                return transliteration?.text || 'Transliteration not available';
              })()}
            </p>
          )}

          {translation && (
            <div 
              className={`
                mt-2 
                ${fontSize === 'text-sm' ? 'text-xs' :
                  fontSize === 'text-base' ? 'text-sm' :
                  fontSize === 'text-lg' ? 'text-base' :
                  fontSize === 'text-xl' ? 'text-lg' :
                  fontSize === 'text-2xl' ? 'text-xl' :
                  fontSize === 'text-3xl' ? 'text-2xl' :
                  fontSize === 'text-4xl' ? 'text-3xl' :
                  fontSize === 'text-5xl' ? 'text-4xl' :
                  fontSize === 'text-6xl' ? 'text-5xl' : 'text-base'}
                ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
              `}
            >
              {formatFootnotes(translation.text)}
            </div>
          )}
        </div>
        <div className="flex justify-start pt-2">
          <div className={`backdrop-blur-sm ${isDarkMode ? 'bg-gray-800/30 ring-1 ring-gray-800/50' : 'bg-white/80 ring-1 ring-gray-200/50'} rounded-xl`}>
            <div className="flex items-center space-x-3 px-4 py-2">
              <span 
                className={`
                  text-sm font-medium
                  ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                `}
                style={{
                  fontFamily: 'KfgqpcHafsUthmanicScriptRegular-1jGEe'
                }}
              >
                {`${parseInt(verse.verse_key?.split(':')[0])}:${parseInt(verse.verse_key?.split(':')[1])}`}
              </span>
              <div className="flex space-x-3 border-l border-gray-200/10 dark:border-gray-700/30 pl-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMemorizedToggle(verseKey);
                  }}
                  className={`
                    p-1.5 rounded-lg
                    backdrop-blur-sm
                    transition-all duration-200 ease-in-out
                    hover:scale-110
                    ${isMemorized 
                      ? 'text-green-600 dark:text-green-400 bg-green-50/50 dark:bg-green-500/10'
                      : isDarkMode 
                        ? 'text-gray-400 hover:text-white hover:bg-white/5' 
                        : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50/80'}
                  `}
                  title="Mark as Memorized"
                >
                  <HiCheck className="w-4 h-4" />
                </button>
                <button
                  className={`
                    p-1.5 rounded-lg
                    backdrop-blur-sm
                    transition-all duration-200 ease-in-out
                    hover:scale-110
                    ${isRead
                      ? isDarkMode 
                        ? 'text-emerald-400 bg-emerald-500/10' 
                        : 'text-emerald-600 bg-emerald-50/50'
                      : isDarkMode 
                        ? 'text-gray-400 hover:text-white hover:bg-white/5' 
                        : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50/80'}
                  `}
                  title={isRead ? "Read" : "Not Read Yet"}
                >
                  <HiEye className="w-4 h-4" />
                </button>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNoteClick(verseKey);
                  }}
                  className={`
                    p-1.5 rounded-lg
                    backdrop-blur-sm
                    transition-all duration-200 ease-in-out
                    hover:scale-110
                    ${hasNote
                      ? 'text-yellow-600 dark:text-yellow-400 bg-yellow-50/50 dark:bg-yellow-500/10'
                      : isDarkMode 
                        ? 'text-gray-400 hover:text-white hover:bg-white/5' 
                        : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50/80'}
                  `}
                  title="Add/Edit Note"
                >
                  {hasNote ? <HiPencil className="w-4 h-4" /> : <HiOutlineAnnotation className="w-4 h-4" />}
                </button>
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleRepeat(index);
                  }}
                  className={`
                    p-1.5 rounded-lg
                    backdrop-blur-sm
                    transition-all duration-200 ease-in-out
                    hover:scale-110
                    ${repeatingVerse === index
                      ? 'text-blue-600 dark:text-blue-400 bg-blue-50/50 dark:bg-blue-500/10'
                      : isDarkMode 
                        ? 'text-gray-400 hover:text-white hover:bg-white/5' 
                        : 'text-gray-400 hover:text-gray-600 hover:bg-gray-50/80'}
                  `}
                  title="Repeat"
                >
                  <HiRefresh className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(VerseDisplay);
