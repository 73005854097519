import React from 'react';
import { Card, Checkbox } from 'flowbite-react';
import { Moon } from '@phosphor-icons/react';

const AdhkarTrackerCard = ({ adhkarCompleted, onToggleAdhkar, hideTitle }) => {
  if (!adhkarCompleted || Object.keys(adhkarCompleted).length === 0) {
    return (
      <Card className="col-span-1 bg-white shadow-lg rounded-3xl overflow-hidden">
        <div className="bg-gradient-to-r from-indigo-500 to-purple-600 p-4 rounded-t-3xl">
          <h5 className="text-xl font-bold text-white mb-2 flex items-center">
            <Moon size={20} weight="thin" className="mr-2" />
            Adhkar Tracker
          </h5>
        </div>
        <div className="p-4">
          <p className="text-gray-600">No prayer times set. Please configure your prayer times.</p>
        </div>
      </Card>
    );
  }

  return (
    <Card id="adhkarTrackerCard" className="col-span-1 bg-white shadow-lg rounded-2xl overflow-hidden">
      <div className="p-4 rounded-t-2xl p-6">
        <h5 className="text-xl flex items-center">
          Adhkar Tracker
        </h5>
      </div>
      <div className="p-4 space-y-3">
        {Object.entries(adhkarCompleted).map(([prayer, completed]) => (
          <div 
            key={prayer} 
            className={`flex justify-between items-center p-3 rounded-xl transition-colors duration-200 ${
              completed ? 'bg-indigo-100' : 'bg-gray-50 hover:bg-indigo-50'
            }`}
          >
            <span className={`${completed ? 'text-indigo-600' : 'text-gray-700'}`}>
              {prayer} Adhkar
            </span>
            <Checkbox 
              checked={completed} 
              onChange={() => onToggleAdhkar(prayer)}
              className="text-indigo-600 focus:ring-indigo-500"
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default AdhkarTrackerCard;