import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Card, Button } from 'flowbite-react';
import { Moon } from '@phosphor-icons/react';
import { useAuth } from '../../contexts/AuthContext';
import { useSettings } from '../../contexts/SettingsContext';
import { getCountdown, isExactPrayerTime, adjustPrayerTimes } from '../../utils/prayerTimeUtils';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { debounce } from 'lodash';
import { fetchDateTime } from '../../services/dateTimeService';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';

// Check if running in native iOS environment
const isNativeBridgeAvailable = () => {
  return Boolean(window.isUmmahtiNative && window.ummahti && window.webkit?.messageHandlers?.adhanHandler);
};

const DateTimeCard = ({ 
  dateTime, 
  prayerTimes,
  isLoading,
  className = "",
  timeClassName = "",
  untilClassName = "",
  dateClassName = "",
  dividerClassName = ""
}) => {
  const [countdown, setCountdown] = useState({ nextPrayer: "Loading...", countdown: "--:--:--" });
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const [isSimulatedPrayerTime, setIsSimulatedPrayerTime] = useState(false);
  const { currentUser } = useAuth();
  const { settings } = useSettings();
  const audioRef = useRef(new Audio());
  const [adhanPlayed, setAdhanPlayed] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const clickCountRef = useRef(0);
  const clickTimerRef = useRef(null);
  const prayerTimeoutRef = useRef(null);
  const [adhanLoaded, setAdhanLoaded] = useState(false);
  const lastPlayedPrayerRef = useRef(null);
  const { isDarkMode } = useDarkMode();
  const [currentTime, setCurrentTime] = useState(() => {
    // Try to get stored time first
    const stored = localStorage.getItem('dateTime');
    if (stored) {
      const { data, timestamp } = JSON.parse(stored);
      // Use stored data if it's from today
      if (new Date(timestamp).toDateString() === new Date().toDateString()) {
        return data;
      }
    }
    return dateTime;
  });
  const [countdownInitialized, setCountdownInitialized] = useState(false);
  const isNative = isNativeBridgeAvailable();

  // Apply tuned prayer times only if they exist and are valid
  const adjustedPrayerTimes = useMemo(() => {
    if (!prayerTimes || !prayerTimes.times || Object.keys(prayerTimes.times).length === 0) {
      return null;
    }
    return adjustPrayerTimes(prayerTimes.times, settings.tunedTimes);
  }, [prayerTimes, settings.tunedTimes]);

  useEffect(() => {
    const loadAdhan = async () => {
      if (!settings.selectedAdhan?.regularPath || !settings.selectedAdhan?.fajrPath) {
        console.log("No adhan selected");
        return;
      }
      
      try {
        // Pre-load both adhans
        const regularUrl = await getDownloadURL(ref(storage, settings.selectedAdhan.regularPath));
        const fajrUrl = await getDownloadURL(ref(storage, settings.selectedAdhan.fajrPath));
        
        // Store URLs for later use
        audioRef.current.dataset.regularUrl = regularUrl;
        audioRef.current.dataset.fajrUrl = fajrUrl;
        
        // If running in native iOS app, download the adhan files
        if (isNative && window.ummahtiIosBridge?.downloadAdhan) {
          // Extract file names from paths
          const regularFileName = settings.selectedAdhan.regularPath.split('/').pop();
          const fajrFileName = settings.selectedAdhan.fajrPath.split('/').pop();
          
          // Download both adhan files to the native app
          window.ummahtiIosBridge.downloadAdhan(regularFileName, regularUrl);
          window.ummahtiIosBridge.downloadAdhan(fajrFileName, fajrUrl);
          
          console.log("Adhan files downloaded to native app");
        }
        
        setAdhanLoaded(true);
        console.log("Adhan audio loaded successfully");
      } catch (error) {
        console.error('Error loading adhan audio:', error);
      }
    };

    loadAdhan();

    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, [settings.selectedAdhan, isNative]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (adjustedPrayerTimes && Object.keys(adjustedPrayerTimes).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(adjustedPrayerTimes, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && !isSimulatedPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          setAdhanPlayed(false);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
          playAdhan();
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime && !isSimulatedPrayerTime && !audioRef.current.playing) {
          resetPrayerState();
        }

        if (!isPrayerTime && !isSimulatedPrayerTime) {
          const newCountdown = getCountdown(adjustedPrayerTimes, now);
          setCountdown(newCountdown);
          if (!countdownInitialized) {
            setCountdownInitialized(true);
          }
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
      if (prayerTimeoutRef.current) {
        clearTimeout(prayerTimeoutRef.current);
      }
    };
  }, [adjustedPrayerTimes, isPrayerTime, adhanLoaded, isSimulatedPrayerTime, countdownInitialized]);

  // Schedule adhans for the day in native app
  useEffect(() => {
    const scheduleAdhansInNativeApp = () => {
      if (isNative && window.ummahtiIosBridge?.scheduleAdhan && adjustedPrayerTimes && Object.keys(adjustedPrayerTimes).length > 0) {
        // Cancel any existing scheduled adhans
        window.ummahtiIosBridge.cancelAllScheduledAdhans();
        
        // Get today's date
        const today = new Date();
        const todayStr = today.toISOString().split('T')[0]; // YYYY-MM-DD
        
        // Schedule each prayer's adhan
        Object.entries(adjustedPrayerTimes).forEach(([prayer, time]) => {
          if (!time) return; // Skip if time is undefined
          
          const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
          if (isNaN(hours) || isNaN(minutes)) return; // Skip if time is invalid
          
          // Create a date object for the prayer time
          const prayerDate = new Date(today);
          prayerDate.setHours(hours, minutes, 0, 0);
          
          // Only schedule if the prayer time is in the future
          if (prayerDate > today) {
            // Determine which adhan file to use
            const isFajr = prayer === 'Fajr';
            const fileName = isFajr 
              ? settings.selectedAdhan.fajrPath.split('/').pop()
              : settings.selectedAdhan.regularPath.split('/').pop();
            
            // Schedule the adhan
            window.ummahtiIosBridge.scheduleAdhan(
              prayer,
              prayerDate.toISOString(),
              fileName
            );
            
            console.log(`Scheduled ${prayer} adhan for ${prayerDate.toLocaleTimeString()}`);
          }
        });
      }
    };
    
    if (adhanLoaded) {
      scheduleAdhansInNativeApp();
    }
  }, [adjustedPrayerTimes, adhanLoaded, isNative, settings.selectedAdhan, settings.tunedTimes]);

  const playAdhan = () => {
    if (isNative && window.ummahtiIosBridge?.playAdhan) {
      // Determine which adhan to play
      const isFajr = currentPrayer === 'Fajr';
      const fileName = isFajr 
        ? settings.selectedAdhan.fajrPath.split('/').pop()
        : settings.selectedAdhan.regularPath.split('/').pop();
      
      // Get the URL as fallback
      const url = isFajr 
        ? audioRef.current.dataset.fajrUrl
        : audioRef.current.dataset.regularUrl;
      
      // Play adhan using native functionality
      window.ummahtiIosBridge.playAdhan(fileName, url);
      console.log("Playing adhan using native functionality");
      setAdhanPlayed(true);
    } else if (audioRef.current && adhanLoaded) {
      console.log("Attempting to play Adhan in web");
      // Determine which adhan to play
      const isFajr = currentPrayer === 'Fajr';
      const url = isFajr ? 
        audioRef.current.dataset.fajrUrl : 
        audioRef.current.dataset.regularUrl;
      
      audioRef.current.src = url;
      audioRef.current.currentTime = 0;
      audioRef.current.play().then(() => {
        console.log("Adhan started playing");
        setAdhanPlayed(true);
      }).catch(e => {
        console.error("Error playing Adhan:", e);
      });
    } else {
      console.error("Adhan audio ref is null or not loaded");
    }
  };

  const resetPrayerState = () => {
    // Stop adhan if it's playing in native app
    if (isNative && window.ummahtiIosBridge?.stopAdhan) {
      window.ummahtiIosBridge.stopAdhan();
    }
    
    setIsPrayerTime(false);
    setCurrentPrayer(null);
    setAdhanPlayed(true);
    setIsSimulatedPrayerTime(false);
  };

  const handleCardClick = () => {
    clickCountRef.current += 1;
    
    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
    }

    clickTimerRef.current = setTimeout(() => {
      if (clickCountRef.current === 5) {
        setDebugMode(prevMode => !prevMode);
        console.log("Debug mode toggled:", !debugMode);
      }
      clickCountRef.current = 0;
    }, 500);
  };

  // Debugging function to simulate prayer time
  const simulatePrayerTime = () => {
    if (countdown.nextPrayer && adjustedPrayerTimes[countdown.nextPrayer]) {
      const simulatedTime = new Date();
      const [hours, minutes] = adjustedPrayerTimes[countdown.nextPrayer].split(':');
      simulatedTime.setHours(parseInt(hours));
      simulatedTime.setMinutes(parseInt(minutes));
      simulatedTime.setSeconds(0);
      
      console.log("Simulated prayer time:", simulatedTime);
      
      const prayerTimeCheck = isExactPrayerTime(adjustedPrayerTimes, simulatedTime);
      console.log("Prayer time check result:", prayerTimeCheck);

      setIsPrayerTime(true);
      setCurrentPrayer(countdown.nextPrayer);
      setAdhanPlayed(false);
      setIsSimulatedPrayerTime(true);
      
      console.log("State after simulation:", { isPrayerTime: true, currentPrayer: countdown.nextPrayer, adhanPlayed: false, isSimulatedPrayerTime: true });
      
      playAdhan();

      // Set a timeout to reset the prayer time state after 5 minutes
      if (prayerTimeoutRef.current) clearTimeout(prayerTimeoutRef.current);
      prayerTimeoutRef.current = setTimeout(() => {
        resetPrayerState();
      }, 5 * 60 * 1000); // 5 minutes
    }
  };

  if (isLoading || !countdownInitialized) {
    return (
      <div className={`w-full ${className}`}>
        <div className="flex flex-col animate-pulse">
          <div className={`h-10 w-32 bg-gray-200 dark:bg-gray-700 rounded mb-2 ${timeClassName}`} />
          <div className={`h-6 w-48 bg-gray-200 dark:bg-gray-700 rounded mb-2 ${untilClassName}`} />
          <div className={`h-6 w-64 bg-gray-200 dark:bg-gray-700 rounded ${dateClassName}`} />
        </div>
      </div>
    );
  }

  return (
    <div className={`w-full ${className}`} onClick={handleCardClick}>
      <div className="flex flex-col">
        <span className={timeClassName || "text-2xl font-medium"}>
          {countdown?.countdown || "--:--:--"}
        </span>
        
        <span className={untilClassName || "text-sm text-gray-500"}>
          Until {countdown?.nextPrayer || "next prayer"}
        </span>
        
        <div className={dateClassName || "text-sm text-gray-500"}>
          {dateTime?.gregorian || "Loading..."} 
          <span className={dividerClassName || "text-gray-400"}> | </span> 
          {dateTime?.hijri?.western || "Loading..."}
        </div>
      </div>
      
      {debugMode && (
        <div className="mt-4">
          <Button size="xs" onClick={simulatePrayerTime}>
            Simulate Prayer Time
          </Button>
          <div className="text-xs mt-2">
            <p>Native Bridge: {isNative ? 'Available' : 'Not Available'}</p>
            <p>Current Prayer: {currentPrayer || 'None'}</p>
            <p>Is Prayer Time: {isPrayerTime ? 'Yes' : 'No'}</p>
            <p>Adhan Played: {adhanPlayed ? 'Yes' : 'No'}</p>
            <p>Tuned Times: {JSON.stringify(settings.tunedTimes)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateTimeCard;
