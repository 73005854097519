import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const ModernMinimalistTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Handle background image slideshow
  useEffect(() => {
    if (!settings.simpleDashboard?.backgroundImages?.length) return;
    
    const interval = setInterval(() => {
      setPreviousBackgroundIndex(currentBackgroundIndex);
      setCurrentBackgroundIndex(prev => 
        (prev + 1) % settings.simpleDashboard.backgroundImages.length
      );
    }, (settings.simpleDashboard.slideshowDuration || 60) * 1000);

    return () => clearInterval(interval);
  }, [currentBackgroundIndex, settings.simpleDashboard?.backgroundImages, settings.simpleDashboard?.slideshowDuration]);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Image */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Layered Gradient Overlays */}
      <div className="absolute inset-0 bg-gradient-to-br from-black/95 via-black/70 to-black/90 z-10" />
      
      {/* Main Layout */}
      <div className="relative h-full flex flex-col z-20">
        {/* Top Bar */}
        <div className="px-8 py-10 flex justify-between items-end border-b border-white/10">
          {/* Left - Location */}
          <div>
            <div className="flex items-baseline gap-6">
              <h1 className="text-xl font-light tracking-tight">{settings.location}</h1>
              <div className={`h-px w-32 bg-gradient-to-r ${accentColor.base} to-transparent self-end mb-3`}></div>
            </div>
            <p className="text-white/60 text-sm mt-1">{settings.region} {settings.country}</p>
          </div>

          {/* Right - Dates */}
          <SimpleDateCard 
            dateTime={dateTime}
            onDateChange={onDateChange}
            render={({ gregorian, hijri }) => (
              <div className="flex gap-4">
                <div>
                  <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Gregorian</p>
                  <p className="text-sm">{gregorian}</p>
                </div>
                <div>
                  <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Hijri</p>
                  <p className="text-sm">{hijri}</p>
                </div>
              </div>
            )}
          />
        </div>

        {/* Hadith Section */}
        <div className="px-8 py-8 border-b border-white/10 flex items-center justify-center">
          <p className="py-8 text-3xl sm:text-4xl lg:text-5xl xl:text-6xl text-white/60 text-center leading-loose font-['Decotype_Thuluth']">
            {duas?.[currentDuaIndex]?.arabic}
          </p>
        </div>

        {/* Main Time Display */}
        <div className="flex-1 flex items-end px-8 pb-4">
          <div className="w-full flex justify-between items-end">
            {/* Current Time */}
            <div>
              <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-4">Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className="text-[6rem] sm:text-[7rem] lg:text-[7rem] [min-width:1025px]:text-[11rem] xl:text-[15rem] font-thin tracking-tight leading-[0.75] pb-[0.12em]">
                    {time}
                  </div>
                )}
              />
            </div>

            {/* Countdown - Aligned with bottom of time */}
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div className="text-right flex flex-col justify-end">
                  <div className="flex justify-end mb-4">
                    <div className={`inline-flex items-center gap-2 lg:gap-3 ${accentColor.bg} rounded-full px-3 py-1.5 lg:px-4 lg:py-2`}>
                      <div className={`w-1.5 h-1.5 lg:w-2 lg:h-2 rounded-full ${accentColor.base}`}></div>
                      <p className={`${accentColor.base} text-xs sm:text-sm tracking-widest uppercase whitespace-nowrap`}>Until {nextPrayer}</p>
                    </div>
                  </div>
                  <div className={`text-3xl sm:text-4xl lg:text-6xl xl:text-[8rem] font-thin tracking-tight leading-[0.75] pb-[0.12em] ${accentColor.base}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        {/* Prayer Times Bar */}
        <div className="border-t border-white/10">
          <div className="mx-8 my-6">
            <div className="grid grid-cols-5 gap-6">
              <SimplePrayerTimesCard
                prayerTimes={prayerTimes}
                settings={settings}
                render={({ prayers }) => (
                  <>
                    {prayers.map(([prayer, time]) => {
                      const Icon = prayer === 'Fajr' ? Sunrise :
                                 prayer === 'Maghrib' ? Sunset :
                                 prayer === 'Isha' ? Moon : Sun;
                      
                      const isNext = prayer === nextPrayer;
                      
                      return (
                        <div 
                          key={prayer}
                          className={`${
                            isNext 
                              ? accentColor.bg
                              : 'group bg-white/5 backdrop-blur-sm hover:bg-white/10'
                          } rounded-2xl p-6 transition-colors`}
                        >
                          <div className="flex justify-between items-start mb-4">
                            <p className={`${
                              isNext ? accentColor.base : 'text-white/40'
                            } text-sm tracking-widest uppercase`}>
                              {prayer}
                            </p>
                            <Icon className={`w-5 h-5 ${
                              isNext ? accentColor.base : 'text-white/40'
                            }`} />
                          </div>
                          <p className={`text-4xl font-light ${
                            isNext ? accentColor.base : 'text-white/40'
                          }`}>
                            {time}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModernMinimalistTemplate; 