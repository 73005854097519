const loadedFonts = new Set();

const CDN_URLS = [
  'https://raw.githubusercontent.com/mustafa0x/qpc-fonts/master/mushaf-v2'
];

const loadFontWithFallback = async (fontName, pageNumber) => {
  for (const baseUrl of CDN_URLS) {
    try {
      const fontFace = new FontFace(fontName, 
        `url(${baseUrl}/QCF${pageNumber}.ttf) format('truetype')`
      );
      await fontFace.load();
      document.fonts.add(fontFace);
      return true;
    } catch (error) {
      console.warn(`Failed to load from ${baseUrl}, trying next CDN...`);
      continue;
    }
  }
  throw new Error('All CDNs failed to load font');
};

const loadFont = async (pageNumber) => {
  const paddedNumber = (2000 + pageNumber).toString();
  const fontName = `QCF_P${pageNumber.toString().padStart(3, '0')}`;

  if (loadedFonts.has(fontName)) {
    return;
  }

  try {
    await loadFontWithFallback(fontName, paddedNumber);
    loadedFonts.add(fontName);
  } catch (error) {
    console.error(`Error loading font ${fontName}:`, error);
  }
};

const loadBismillahFont = async () => {
  if (loadedFonts.has('QCF_BSML')) {
    return;
  }

  try {
    const fontFace = new FontFace('QCF_BSML', 
      `url(${CDN_URLS[0]}/QCF2BSML.ttf) format('truetype')`
    );
    await fontFace.load();
    document.fonts.add(fontFace);
    loadedFonts.add('QCF_BSML');
  } catch (error) {
    console.error('Error loading Bismillah font:', error);
    throw new Error('Failed to load Bismillah font');
  }
};

const loadFontsForPages = async (pageNumbers) => {
  const loadPromises = pageNumbers.map(pageNumber => loadFont(pageNumber));
  await Promise.all(loadPromises);
};

export { loadFont, loadBismillahFont, loadFontsForPages, CDN_URLS }; 