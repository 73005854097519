import { useCallback, useRef } from 'react';

const useEnhancedScroll = () => {
  const scrollTimeoutRef = useRef(null);
  const lastScrollAttemptRef = useRef(null);
  
  const scrollToVerse = useCallback((
    verseIndex,
    {
      scrollContainerRef,
      verseRefs,
      onSuccess,
      onError,
      immediate = false
    }
  ) => {
    // Clear any pending scroll attempts
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    // Generate unique ID for this scroll attempt
    const scrollAttemptId = Date.now();
    lastScrollAttemptRef.current = scrollAttemptId;

    const attemptScroll = (retryCount = 0, maxRetries = 15) => {
      // Check if this is still the most recent scroll attempt
      if (lastScrollAttemptRef.current !== scrollAttemptId) {
        return;
      }

      const verse = verseRefs.current[verseIndex];
      const container = scrollContainerRef.current;

      if (!verse || !container) {
        if (retryCount < maxRetries) {
          scrollTimeoutRef.current = setTimeout(() => {
            attemptScroll(retryCount + 1);
          }, Math.min(100 * (retryCount + 1), 1000)); // Cap max delay at 1s
          return;
        }
        
        console.error(`Failed to scroll to verse ${verseIndex} after ${maxRetries} attempts`);
        onError?.();
        return;
      }

      try {
        // Get accurate measurements
        const containerRect = container.getBoundingClientRect();
        const verseRect = verse.getBoundingClientRect();

        // Calculate optimal scroll position
        const scrollPosition = (
          verse.offsetTop - 
          (containerRect.height - verseRect.height) / 2
        );

        // Apply scroll bounds
        const maxScroll = container.scrollHeight - containerRect.height;
        const finalScrollPosition = Math.max(0, Math.min(scrollPosition, maxScroll));

        // Perform scroll
        const isMobile = window.innerWidth <= 768;
        const behavior = (immediate || isMobile) ? 'auto' : 'smooth';

        container.scrollTo({
          top: finalScrollPosition,
          behavior
        });

        // Verify scroll position and verse visibility
        const verifyScroll = () => {
          if (lastScrollAttemptRef.current !== scrollAttemptId) {
            return;
          }

          // Force position if needed
          const currentScroll = container.scrollTop;
          if (Math.abs(currentScroll - finalScrollPosition) > 2) {
            container.scrollTop = finalScrollPosition;
          }

          // Check if verse is visible
          const newVerseRect = verse.getBoundingClientRect();
          const containerVisible = {
            top: container.scrollTop,
            bottom: container.scrollTop + container.clientHeight
          };
          
          const isVisible = (
            newVerseRect.top >= containerVisible.top &&
            newVerseRect.bottom <= containerVisible.bottom
          );

          if (isVisible) {
            onSuccess?.();
          } else if (retryCount < maxRetries) {
            // Retry if verse not visible
            scrollTimeoutRef.current = setTimeout(() => {
              attemptScroll(retryCount + 1);
            }, 100);
          } else {
            onError?.();
          }
        };

        // Verify after scroll
        if (behavior === 'auto') {
          verifyScroll();
        } else {
          // For smooth scrolling, wait for animation
          const scrollDuration = 500; // Typical smooth scroll duration
          scrollTimeoutRef.current = setTimeout(verifyScroll, scrollDuration);
        }

      } catch (error) {
        console.error('Scroll error:', error);
        onError?.();
      }
    };

    // Start scroll attempt
    attemptScroll();

    // Return cleanup
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  return {
    scrollToVerse,
    clearScrollAttempts: () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      lastScrollAttemptRef.current = null;
    }
  };
};

export default useEnhancedScroll; 