import React, { useState, useEffect, useMemo } from 'react';
import { Clock, ArrowClockwise, Check } from 'lucide-react';
import { Clock01Icon, CheckCircle, CheckmarkCircle02Icon, RefreshIcon   } from 'hugeicons-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';
import { useDarkMode } from '../contexts/DarkModeContext';
import { SelectionView } from './Quran/components/SelectionView';
import { DHIKR_REGISTRY, DHIKR_CATEGORIES, getDhikrById } from '../types/dhikr';
import { useDhikrProgress } from '../hooks/useDhikrProgress';
import { useGoals } from '../hooks/useGoals';
import { DhikrSelectionView } from './Quran/components/DhikrSelectionView';
import { useSettings } from '../contexts/SettingsContext';
import { fetchPrayerTimes } from '../services/prayerService';
import { format, parse, isAfter, addDays } from 'date-fns';

const DhikrHelper = () => {
  const { isDarkMode } = useDarkMode();
  const { currentUser } = useAuth();
  const { goals, updateGoalProgress } = useGoals();
  const { saveDhikrCompletion, getTodayCompletions, progress } = useDhikrProgress();
  const { settings } = useSettings();
  
  const [selectedDhikr, setSelectedDhikr] = useState(DHIKR_REGISTRY.FULL_TASBIH);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [sets, setSets] = useState(0);
  const [displayMode, setDisplayMode] = useState('arabic');
  const [showCompletionAnimation, setShowCompletionAnimation] = useState(false);
  const [showDhikrSheet, setShowDhikrSheet] = useState(false);
  const [prayerTimes, setPrayerTimes] = useState(null);
  const [isPressing, setIsPressing] = useState(false);

  // Fetch prayer times
  useEffect(() => {
    const loadPrayerTimes = async () => {
      if (settings?.coordinates) {
        try {
          const times = await fetchPrayerTimes(settings);
          console.log('[DhikrHelper] Fetched prayer times:', times);
          setPrayerTimes(times);
        } catch (error) {
          console.error('[DhikrHelper] Error fetching prayer times:', error);
        }
      }
    };
    loadPrayerTimes();
  }, [settings]);

  // Get the prayer statuses including missed prayers from previous days
  const getPrayerStatus = () => {
    if (!prayerTimes) return null;

    const now = new Date();
    const today = new Date().toISOString().split('T')[0];
    
    // Check if prayerTimes has the new structure with a times property
    const prayerTimesData = prayerTimes.times || prayerTimes;
    
    // Make sure we have valid prayer times data before proceeding
    if (!prayerTimesData || typeof prayerTimesData !== 'object' || Object.keys(prayerTimesData).length === 0) {
      console.warn('[DhikrHelper] No valid prayer times data available');
      return null;
    }
    
    const prayers = Object.entries(prayerTimesData);
    const todayCompletions = getTodayCompletions();
    
    // Get yesterday's completions
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split('T')[0];
    const yesterdayCompletions = progress?.history?.filter(entry => {
      if (!entry?.completedAt || typeof entry.completedAt !== 'string') return false;
      return entry.completedAt.split('T')[0] === yesterdayStr;
    }) || [];
    
    // Map prayers to their status
    const prayerStatuses = prayers.map(([name, time]) => {
      // Ensure time is a string before splitting
      if (typeof time !== 'string') {
        console.error(`[DhikrHelper] Invalid prayer time for ${name}:`, time);
        return null;
      }
      
      // Parse prayer time for today
      const [hours, minutes] = time.split(':').map(Number);
      const prayerDateTime = new Date(now);
      prayerDateTime.setHours(hours, minutes, 0);
      
      const isAfterPrayerTime = isAfter(now, prayerDateTime);
      const isCompleted = todayCompletions.some(c => c.prayer === name);
      const wasCompletedYesterday = yesterdayCompletions.some(c => c.prayer === name);
      
      return {
        name,
        time,
        isAfterPrayerTime,
        isCompleted,
        // Only overdue if it's today's prayer and time has passed
        isOverdue: isAfterPrayerTime && !isCompleted,
        // Only missed if it's from yesterday and wasn't completed
        isMissed: !wasCompletedYesterday && isAfter(now, addDays(prayerDateTime, 1)),
        isNext: false // Will be set below
      };
    }).filter(Boolean); // Filter out any null entries from invalid times

    // Find the next prayer (first prayer that hasn't passed its time)
    let foundNext = false;
    for (let i = 0; i < prayerStatuses.length; i++) {
      const status = prayerStatuses[i];
      if (!status.isAfterPrayerTime && !status.isCompleted) {
        status.isNext = true;
        foundNext = true;
        break;
      }
    }

    // If no next prayer found today, mark first prayer of tomorrow
    if (!foundNext && prayerStatuses.length > 0) {
      prayerStatuses[0].isNext = true;
      prayerStatuses[0].isNextDay = true;
    }

    return prayerStatuses;
  };

  // Organize dhikrs by goals and categories
  const organizedDhikrs = useMemo(() => {
    const todayCompletions = getTodayCompletions();
    const activeGoals = goals.filter(g => g.status === 'active');
    const prayerStatuses = getPrayerStatus();
    
    // Get pending goal dhikrs
    const pendingGoalDhikrs = activeGoals.flatMap(goal => {
      if (!goal.details?.requiredDhikrs) return [];

      return goal.details.requiredDhikrs.map(required => {
        const dhikr = getDhikrById(required.dhikrId);
        if (!dhikr) return null;

        const completedCount = todayCompletions.filter(
          c => c.dhikrId === required.dhikrId && c.goalId === goal.id
        ).length;

        const targetCount = required.count * (required.repetitions || 1);

        // For prayer-based goals, create separate entries for each prayer
        if (goal.recurrenceType === 'prayer_based' && prayerStatuses) {
          const prayerEntries = prayerStatuses.map(status => {
            const prayerCompletions = todayCompletions.filter(
              c => c.dhikrId === required.dhikrId && 
                   c.goalId === goal.id && 
                   c.prayer === status.name
            ).length;

            return {
              ...dhikr,
              goalId: goal.id,
              goalTitle: `${goal.title} (${status.name})`,
              prayer: status.name,
              progress: prayerCompletions,
              target: targetCount,
              isComplete: prayerCompletions >= targetCount,
              isNext: status.isNext,
              isOverdue: status.isOverdue,
              isMissed: status.isMissed,
              // Show if it's the next prayer, overdue, or missed
              isAvailable: status.isNext || status.isOverdue || status.isMissed
            };
          });

          return prayerEntries;
        }

        return {
          ...dhikr,
          goalId: goal.id,
          goalTitle: goal.title,
          progress: completedCount,
          target: targetCount,
          isComplete: completedCount >= targetCount
        };
      }).filter(Boolean);
    }).flat();

    // Organize by category
    const byCategory = Object.values(DHIKR_CATEGORIES).reduce((acc, category) => {
      acc[category] = Object.values(DHIKR_REGISTRY)
        .filter(dhikr => dhikr.category === category)
        .map(dhikr => {
          const goalAssociations = activeGoals
            .filter(goal => 
              goal.details?.requiredDhikrs?.some(req => req.dhikrId === dhikr.id)
            )
            .map(goal => {
              const required = goal.details.requiredDhikrs.find(
                req => req.dhikrId === dhikr.id
              );

              if (goal.recurrenceType === 'prayer_based' && prayerStatuses) {
                return prayerStatuses.map(status => {
                  const prayerCompletions = todayCompletions.filter(
                    c => c.dhikrId === dhikr.id && 
                         c.goalId === goal.id && 
                         c.prayer === status.name
                  ).length;

                  return {
                    goalId: goal.id,
                    goalTitle: `${goal.title} (${status.name})`,
                    prayer: status.name,
                    progress: prayerCompletions,
                    target: required.count * (required.repetitions || 1),
                    isNext: status.isNext,
                    isOverdue: status.isOverdue,
                    isMissed: status.isMissed,
                    isAvailable: status.isNext || status.isOverdue || status.isMissed
                  };
                });
              }

              const completedCount = todayCompletions.filter(
                c => c.dhikrId === dhikr.id && c.goalId === goal.id
              ).length;

              return {
                goalId: goal.id,
                goalTitle: goal.title,
                progress: completedCount,
                target: required.count * (required.repetitions || 1)
              };
            }).flat();

          return {
            ...dhikr,
            goalAssociations,
            totalCompletions: todayCompletions.filter(
              c => c.dhikrId === dhikr.id
            ).length
          };
        });
      return acc;
    }, {});

    // Sort pendingGoalDhikrs to show missed prayers first, then overdue, then next
    const sortedPendingGoalDhikrs = pendingGoalDhikrs
      .filter(d => d && !d.isComplete && (!d.prayer || d.isAvailable))
      .sort((a, b) => {
        if (a.isMissed && !b.isMissed) return -1;
        if (!a.isMissed && b.isMissed) return 1;
        if (a.isOverdue && !b.isOverdue) return -1;
        if (!a.isOverdue && b.isOverdue) return 1;
        if (a.isNext && !b.isNext) return -1;
        if (!a.isNext && b.isNext) return 1;
        return 0;
      });

    return {
      pendingGoalDhikrs: sortedPendingGoalDhikrs,
      byCategory
    };
  }, [goals, getTodayCompletions, prayerTimes, progress.history]);

  const incrementCount = () => {
    const currentDhikr = selectedDhikr.type === 'sequence' 
      ? selectedDhikr.sequence[currentSequenceIndex]
      : selectedDhikr;
    
    const newCount = count + 1;
    setCount(newCount);
    setTotalCount(totalCount + 1);
    
    // If we've reached the count for this dhikr
    if (newCount >= currentDhikr.count) {
      // If this is a sequence, move to the next item
      if (selectedDhikr.type === 'sequence') {
        if (currentSequenceIndex < selectedDhikr.sequence.length - 1) {
          setCurrentSequenceIndex(currentSequenceIndex + 1);
          setCount(0);
          setSets(sets + 1);
        } else {
          // Sequence completed
          handleDhikrComplete();
          setCurrentSequenceIndex(0);
          setCount(0);
          setSets(sets + 1);
        }
      } else {
        // Single dhikr completed
        handleDhikrComplete();
        setCount(0);
        setSets(sets + 1);
      }
    }
  };

  const resetCounter = () => {
    setCount(0);
  };

  // Handle dhikr completion
  const handleDhikrComplete = async () => {
    try {
      // Find relevant active goal for this dhikr
      const relevantGoal = goals.find(goal => {
        if (goal.status !== 'active') return false;
        
        const requiredDhikr = goal.details?.requiredDhikrs?.find(
          d => d.dhikrId === selectedDhikr.id
        );
        if (!requiredDhikr) return false;

        const today = format(new Date(), 'yyyy-MM-dd');
        const todayProgress = goal.progress[today] || {};
        const currentTime = new Date().toISOString();

        if (goal.recurrenceType === 'prayer_based') {
          const prayerStatuses = getPrayerStatus();
          
          // Better error handling for missing prayer statuses
          if (!prayerStatuses || !Array.isArray(prayerStatuses) || prayerStatuses.length === 0) {
            console.warn('[DhikrHelper] No valid prayer statuses available for prayer-based goal');
            return false;
          }
          
          const availablePrayer = prayerStatuses.find(s => s.isNext || s.isOverdue || s.isMissed);
          if (!availablePrayer) return false;

          const prayerProgress = todayProgress?.details?.prayers?.[availablePrayer.name];
          const targetCount = requiredDhikr.count * (requiredDhikr.repetitions || 1);
          return !prayerProgress?.completed || prayerProgress.completedCount < targetCount;
        }

        // For regular dhikr goals
        const completedCount = todayProgress?.details?.completedCount || 0;
        const targetCount = requiredDhikr.count * (requiredDhikr.repetitions || 1);
        return completedCount < targetCount;
      });

      if (relevantGoal) {
        const today = format(new Date(), 'yyyy-MM-dd');
        const todayProgress = relevantGoal.progress[today] || {};
        const currentTime = new Date().toISOString();

        if (relevantGoal.recurrenceType === 'prayer_based') {
          const prayerStatuses = getPrayerStatus();
          
          // Better error handling for missing prayer statuses
          if (!prayerStatuses || !Array.isArray(prayerStatuses) || prayerStatuses.length === 0) {
            console.warn('[DhikrHelper] No valid prayer statuses available for prayer-based goal');
            return;
          }
          
          const availablePrayer = prayerStatuses.find(s => s.isNext || s.isOverdue || s.isMissed);
          
          if (availablePrayer) {
            const prayers = { ...todayProgress.details?.prayers };
            const currentPrayer = prayers[availablePrayer.name] || { completed: false, completedCount: 0 };
            
            // Update prayer progress
            prayers[availablePrayer.name] = {
              ...currentPrayer,
              completed: true,
              completedCount: (currentPrayer.completedCount || 0) + 1,
              lastCompleted: currentTime
            };

            console.log(`[DhikrHelper] Updating prayer progress for ${availablePrayer.name}:`, prayers[availablePrayer.name]);

            await updateGoalProgress(relevantGoal.id, {
              date: today,
              details: {
                prayers,
                lastUpdated: currentTime
              }
            });
          } else {
            console.warn('[DhikrHelper] No available prayer found for prayer-based goal');
          }
        } else {
          // Regular dhikr goal
          const currentCount = todayProgress.details?.completedCount || 0;
          const currentSessions = todayProgress.details?.sessions || [];

          await updateGoalProgress(relevantGoal.id, {
            date: today,
            details: {
              completedCount: currentCount + 1,
              sessions: [
                ...currentSessions,
                {
                  timestamp: currentTime,
                  count: 1,
                  dhikrId: selectedDhikr.id
                }
              ],
              lastUpdated: currentTime
            }
          });
        }

        setShowCompletionAnimation(true);
        setTimeout(() => setShowCompletionAnimation(false), 3000);
      }
    } catch (error) {
      console.error('Error completing dhikr:', error);
    }
  };

  const currentDhikr = selectedDhikr.type === 'sequence' 
    ? selectedDhikr.sequence[currentSequenceIndex]
    : selectedDhikr;
    
  // Calculate progress for the circle
  const radius = 100;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (count / currentDhikr.count) * circumference;

  return (
    <div className={`min-h-screen flex flex-col relative ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-100/40'
        }`} />
        <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-50/60'
        }`} />
        
        {/* Additional light mode decorative elements */}
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
      </div>

      {/* Main Content */}
      <div className="relative z-10 flex flex-col h-full">
        {/* Header */}
        <header className="flex-none">
          <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
            <div className="flex items-center justify-between">
              <h1 className={`text-2xl font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Dhikr</h1>
            </div>
          </div>
        </header>

        {/* Main Card */}
        <div className="flex-1 flex flex-col px-4 pb-20" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 84px)' }}>
          <div className={`flex-1 rounded-[24px] overflow-hidden backdrop-blur-xl ${
            isDarkMode 
              ? 'bg-white/[0.03] border border-white/[0.05]' 
              : 'bg-white/60 shadow-[inset_0_0_1px_1px_rgba(255,255,255,0.9)]'
          }`}>
            {/* Top Section with Dhikr Text */}
            <div className="p-6 pb-4">
              {/* Language Toggle */}
              <div className="flex justify-center mb-6">
                <div className={`inline-flex p-0.5 rounded-lg ${
                  isDarkMode ? 'bg-white/5' : 'bg-black/5'
                }`}>
                  <button
                    onClick={() => setDisplayMode('arabic')}
                    className={`px-4 py-1.5 text-xs font-medium rounded-md transition-all duration-200 ${
                      displayMode === 'arabic'
                        ? isDarkMode
                          ? 'bg-emerald-500/20 text-emerald-400'
                          : 'bg-emerald-600 text-white'
                        : isDarkMode
                          ? 'text-white/60 hover:text-white'
                          : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    Arabic
                  </button>
                  <button
                    onClick={() => setDisplayMode('transliteration')}
                    className={`px-4 py-1.5 text-xs font-medium rounded-md transition-all duration-200 ${
                      displayMode === 'transliteration'
                        ? isDarkMode
                          ? 'bg-emerald-500/20 text-emerald-400'
                          : 'bg-emerald-600 text-white'
                        : isDarkMode
                          ? 'text-white/60 hover:text-white'
                          : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    Transliteration
                  </button>
                </div>
              </div>
              
              {/* Dhikr Text */}
              <div className="text-center">
                {displayMode === 'arabic' ? (
                  <>
                    <h2 className={`text-3xl font-amiri font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {currentDhikr.arabicText}
                    </h2>
                    <p className={`text-sm mb-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {currentDhikr.text}
                    </p>
                  </>
                ) : (
                  <>
                    <h2 className={`text-3xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {currentDhikr.text}
                    </h2>
                    <p className={`text-sm font-amiri mb-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {currentDhikr.arabicText}
                    </p>
                  </>
                )}
                <p className={`text-xs ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>
                  {selectedDhikr.type === 'sequence' ? `${currentSequenceIndex + 1} of ${selectedDhikr.sequence.length}` : ''}
                </p>
              </div>
              
              {/* Reset Button */}
              <button 
                onClick={resetCounter}
                className={`absolute top-6 right-6 p-2 rounded-full ${
                  isDarkMode ? 'text-gray-400 hover:text-white hover:bg-white/5' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-100'
                }`}
              >
                <RefreshIcon size={18} />     
              </button>
            </div>
            
            {/* Counter Circle */}
            <div className="flex flex-col items-center justify-center px-4 py-8">
              <motion.div 
                className="relative cursor-pointer"
                whileTap={{ scale: 0.97 }}
                onTouchStart={() => setIsPressing(true)}
                onTouchEnd={() => setIsPressing(false)}
                onClick={incrementCount}
              >
                <svg width="240" height="240" className="transform -rotate-90">
                  {/* Background Circle */}
                  <circle
                    cx="120"
                    cy="120"
                    r={radius}
                    fill="transparent"
                    stroke={isDarkMode ? "#1E3A34" : "#E1F5EE"}
                    strokeWidth="12"
                  />
                  {/* Progress Circle */}
                  <circle
                    cx="120"
                    cy="120"
                    r={radius}
                    fill="transparent"
                    stroke={isDarkMode ? "#34D399" : "#10B981"}
                    strokeWidth="12"
                    strokeDasharray={circumference}
                    strokeDashoffset={progressOffset}
                    strokeLinecap="round"
                  />
                </svg>
                
                {/* Counter Text */}
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <motion.span 
                    key={count}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.2 }}
                    className={`text-6xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                  >
                    {count < 10 ? `0${count}` : count}
                  </motion.span>
                  <span className={`text-sm mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    of {currentDhikr.count}
                  </span>
                </div>
              </motion.div>
            </div>
            
            {/* Stats Section */}
            <div className={`mt-2 px-8 py-4 border-t ${
              isDarkMode ? 'border-white/5' : 'border-gray-100'
            }`}>
              <div className="flex justify-between">
                <div>
                  <p className={`text-xs uppercase tracking-wider ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                    Total Count
                  </p>
                  <p className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {totalCount}
                  </p>
                </div>
                <div className="text-right">
                  <p className={`text-xs uppercase tracking-wider ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                    Sets Completed
                  </p>
                  <p className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {sets}
                  </p>
                </div>
              </div>
            </div>
            
            {/* Select Dhikr Button */}
            <div className="p-4 flex justify-center">
              <button
                onClick={() => setShowDhikrSheet(true)}
                className={`flex items-center py-2 px-4 rounded-full transition-all ${
                  isDarkMode 
                    ? 'bg-emerald-500/10 text-emerald-400 hover:bg-emerald-500/20' 
                    : 'bg-emerald-100 text-emerald-600 hover:bg-emerald-200'
                }`}
              >
                <Clock01Icon size={18} className="mr-2" />
                <span className="text-sm font-medium">Select Dhikr</span>
              </button>
            </div>
          </div>
        </div>

        {/* Selection View */}
        <AnimatePresence>
          {showDhikrSheet && (
            <DhikrSelectionView
              show={true}
              onClose={() => setShowDhikrSheet(false)}
              title="Select Dhikr"
              isDarkMode={isDarkMode}
              pendingGoalDhikrs={organizedDhikrs.pendingGoalDhikrs}
              categorizedDhikrs={organizedDhikrs.byCategory}
              onSelectDhikr={(dhikr) => {
                setSelectedDhikr(dhikr);
                setCurrentSequenceIndex(0);
                setCount(0);
                setShowDhikrSheet(false);
              }}
            />
          )}
        </AnimatePresence>

        {/* Completion Modal */}
        {showCompletionAnimation && (
          <div className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-50">
            <motion.div 
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              className={`rounded-2xl p-8 text-center ${
                isDarkMode ? 'bg-[#111111]' : 'bg-white'
              }`}
            >
              <div className={`w-16 h-16 mx-auto mb-4 rounded-full flex items-center justify-center ${
                isDarkMode ? 'bg-emerald-500/20' : 'bg-emerald-100'
              }`}>
                <CheckmarkCircle02Icon className={`w-8 h-8 ${
                  isDarkMode ? 'text-emerald-400' : 'text-emerald-500'
                }`} />
              </div>
              <h3 className={`text-xl font-medium mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Goal Completed!
              </h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                Great job on completing your dhikr goal!
              </p>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DhikrHelper;
