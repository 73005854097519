import React, { useState, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, ChevronRight, Calendar, Clock, CheckCircle2, X, BookOpen, Repeat, Trash2, Target } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';
import { useGoals } from '../hooks/useGoals';
import { format, addDays, isSameDay, subDays } from 'date-fns';
import { GOAL_TYPE_CONFIG, goalTypes } from '../types/goals';
import { Link } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { useSettings } from '../contexts/SettingsContext';
import { db } from '../firebase';
import { useMultiGoalProgress } from '../hooks/useGoalProgress';
import { initializeActionLogsForGoal } from '../utils/goalUtils';

const GoalSelectionView = ({ onClose, isDarkMode, onGoalCreated }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleGoalCreate = async () => {
    if (!selectedType || !selectedOption) return;

    const option = selectedType.options.find(o => o.value === selectedOption);
    if (!option) return;

    try {
      await onGoalCreated(
        selectedType.id,
        option.label,
        option.recurrenceType,
        option.details
      );
    } catch (error) {
      console.error('Error creating goal:', error);
    }
  };

  if (selectedType) {
    return (
      <motion.div
        initial={{ opacity: 0, y: '100%' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '100%' }}
        transition={{ type: 'spring', damping: 25, stiffness: 200 }}
        className={`fixed inset-0 z-50 ${isDarkMode ? 'bg-black' : 'bg-white'}`}
      >
        {/* Header */}
        <header className="sticky top-0 z-40">
          <div className={`${
            isDarkMode ? 'bg-black' : 'bg-white'
          } backdrop-blur-xl border-b ${
            isDarkMode ? 'border-white/5' : 'border-black/5'
          }`}>
            <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
              <div className="flex items-center justify-between">
                <button
                  onClick={() => setSelectedType(null)}
                  className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}
                >
                  Back
                </button>
                <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {selectedType.title}
                </h2>
                <button
                  onClick={handleGoalCreate}
                  disabled={!selectedOption}
                  className={`text-sm font-medium ${
                    selectedOption
                      ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                      : isDarkMode ? 'text-gray-600' : 'text-gray-400'
                  }`}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Options */}
        <div className="p-4 space-y-4">
          {selectedType.options.map((option) => (
            <button
              key={option.value}
              onClick={() => setSelectedOption(option.value)}
              className={`w-full p-4 rounded-xl text-left transition-colors ${
                selectedOption === option.value
                  ? isDarkMode
                    ? 'bg-emerald-500/10 border-2 border-emerald-500'
                    : 'bg-emerald-50 border-2 border-emerald-600'
                  : isDarkMode
                    ? 'bg-white/5 border-2 border-transparent hover:border-emerald-500/50'
                    : 'bg-black/5 border-2 border-transparent hover:border-emerald-500/50'
              }`}
            >
              <div className="flex flex-col">
                <span className={`text-base font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {option.label}
                </span>
                <span className={`text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  {option.details.type === 'quran_reading' 
                    ? `${option.details.dailyHizb} hizb per day`
                    : option.details.type === 'memorization'
                      ? `${option.details.dailyVerses} verses per day`
                      : ''}
                </span>
              </div>
            </button>
          ))}
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={{ type: 'spring', damping: 25, stiffness: 200 }}
      className={`fixed inset-0 z-50 ${isDarkMode ? 'bg-black' : 'bg-white'}`}
    >
      {/* Header */}
      <header className="sticky top-0 z-40">
        <div className={`${
          isDarkMode ? 'bg-black' : 'bg-white'
        } backdrop-blur-xl border-b ${
          isDarkMode ? 'border-white/5' : 'border-black/5'
        }`}>
          <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
            <div className="flex items-center justify-between">
              <button
                onClick={onClose}
                className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}
              >
                Cancel
              </button>
              <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Add Goal
              </h2>
              <div className="w-12" /> {/* Spacer for alignment */}
            </div>
          </div>
        </div>
      </header>

      {/* Goal Types */}
      <div className="p-4 space-y-4">
        {goalTypes.map((type) => (
          <button
            key={type.id}
            onClick={() => setSelectedType(type)}
            className={`w-full p-4 rounded-xl transition-all duration-300 ${
              isDarkMode
                ? 'bg-white/5 hover:bg-white/10'
                : 'bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <div className="flex items-center justify-between">
              <div>
                <h3 className={`text-lg font-medium ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {type.title}
                </h3>
              </div>
              <ChevronRight className={
                isDarkMode ? 'text-gray-600' : 'text-gray-400'
              } />
            </div>
          </button>
        ))}
      </div>
    </motion.div>
  );
};

const GoalCard = ({ goal, isDarkMode, onMarkAction }) => {
  if (!goal || !goal.type) {
    return null;
  }

  const todayStr = new Date().toISOString().split('T')[0];
  // Get today's log from actionLogs
  const todayLog = goal.actionLogs ? goal.actionLogs[todayStr] : null;
  const progressPercentage = todayLog
    ? Math.min(100, (todayLog.completed / todayLog.expected) * 100)
    : 0;
  
  // Display text such as "Completed: 0 of 1"
  const getProgressDisplay = () => {
    if (!todayLog) return '';
    return `Completed: ${todayLog.completed} of ${todayLog.expected}`;
  };

  // Handler to mark today's action as done (incrementing "completed")
  const handleMarkToday = async () => {
    if (!todayLog || todayLog.completed >= todayLog.expected) return;
    const newCompleted = todayLog.completed + 1;
    const updatedLog = {
      ...todayLog,
      completed: newCompleted,
      timestamp: new Date().toISOString()
    };
    // Call the update function passed as a prop (expected to interface with your DB hook)
    await onMarkAction(goal.id, todayStr, updatedLog);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`mb-4 rounded-2xl backdrop-blur-xl ${
        isDarkMode ? 'bg-white/5' : 'bg-white/5'
      }`}
    >
      <div className="p-6">
        <div className="flex items-start justify-between">
          <div>
            <h3
              className={`text-lg font-medium mb-2 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
              }`}
            >
              {goal.title}
            </h3>
            <p
              className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}
            >
              {getProgressDisplay()}
            </p>
          </div>
        </div>

        {/* Progress section */}
          <div className="mt-4">
            <div className="flex items-center justify-between text-sm mb-2">
              <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                Today's Progress
              </span>
              <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              {Math.round(progressPercentage)}%
              </span>
            </div>
          <div
            className={`h-2 rounded-full overflow-hidden ${
              isDarkMode ? 'bg-gray-500' : 'bg-gray-200'
            }`}
          >
              <div
                className={`h-full rounded-full ${
                  isDarkMode ? 'bg-emerald-500' : 'bg-emerald-600'
                }`}
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          {todayLog && todayLog.completed < todayLog.expected && (
            <button
              onClick={handleMarkToday}
              className="mt-2 p-2 rounded bg-blue-500 text-white text-sm"
            >
              Mark Today's Action
            </button>
          )}
          </div>
      </div>
    </motion.div>
  );
};

// Add new ConfirmDialog component
const ConfirmDialog = ({ isOpen, onClose, onConfirm, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
    >
      <div className="absolute inset-0 bg-black/50" onClick={onClose} />
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.95 }}
        className={`relative w-full max-w-sm p-6 rounded-2xl ${
          isDarkMode ? 'bg-[#111111]' : 'bg-white'
        }`}
      >
        <h3 className={`text-lg font-semibold mb-2 ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Remove All Goals
        </h3>
        <p className={`text-sm mb-6 ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>
          Are you sure you want to remove all goals? This action cannot be undone.
        </p>
        <div className="flex space-x-3">
          <button
            onClick={onClose}
            className={`flex-1 px-4 py-2 rounded-xl text-sm font-medium transition-colors ${
              isDarkMode
                ? 'bg-gray-800 text-gray-200 hover:bg-gray-700'
                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
            }`}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 rounded-xl text-sm font-medium bg-red-500 text-white hover:bg-red-600 transition-colors"
          >
            Remove All
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default function MobileGoalPlanner() {
  const { isDarkMode } = useDarkMode();
  const { goals, loading, deleteGoal, createGoal, updateGoalProgress } = useGoals();
  const [showAddGoal, setShowAddGoal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [pendingGoalCreation, setPendingGoalCreation] = useState(null);
  const { currentUser } = useAuth();
  const { settings, updateSettings } = useSettings();

  // Get progress for today only
  const allProgress = useMultiGoalProgress(goals, [new Date()]);
  const todayStr = format(new Date(), 'yyyy-MM-dd');

  console.log('Raw goals from useGoals:', goals);

  // Ensure goals is an array and filter out any invalid goals
  const activeGoals = Array.isArray(goals) 
    ? goals.filter(goal => {
        console.log('Checking goal:', goal);
        if (!goal) {
          console.log('Found null/undefined goal');
          return false;
        }
        if (typeof goal !== 'object') {
          console.log('Found non-object goal:', typeof goal);
          return false;
        }
        if (!goal.id) {
          console.log('Found goal without id:', goal);
          return false;
        }
        if (goal.status !== 'active') {
          console.log('Found non-active goal:', goal.status);
          return false;
        }
        return true;
      })
    : [];

  console.log('Filtered active goals:', activeGoals);

  const handleGoalCreated = async (type, title, recurrenceType, details) => {
    if (!type || !title) {
      console.log('Missing required goal fields:', { type, title });
      return;
    }
    
    try {
      // For Quran reading goals, check existing progress
      if (type === 'quran_reading') {
        const existingProgress = settings.quran?.readingProgress?.history || {};
        const hasProgress = Object.keys(existingProgress).length > 0;
        
        if (hasProgress) {
          // Store the goal details and show confirmation dialog
          setPendingGoalCreation({ type, title, recurrenceType, details });
          setShowProgressDialog(true);
          return;
        }
      }
      
      // ---------- New code for initializing action logs ----------
      const today = new Date();
      const startDateStr = today.toISOString().split('T')[0];
      // Use the provided duration or default to 30 days
      const duration = details.duration || 30;
      const endDate = addDays(today, duration - 1);
      const endDateStr = endDate.toISOString().split('T')[0];
      // Determine the expected daily action
      const expectedAction =
        details.dailyHizb ||
        details.dailyPages ||
        details.dailyVerses ||
        (type === 'dhikr' && details.requiredDhikrs
          ? details.requiredDhikrs.reduce((acc, d) => acc + d.count * d.repetitions, 0)
          : 1);
      const actionLogs = initializeActionLogsForGoal(startDateStr, endDateStr, expectedAction);
      const newDetails = { ...details, startDate: startDateStr, endDate: endDateStr, actionLogs };
      // -------------------------------------------------------------

      console.log('Creating new goal:', { type, title, recurrenceType, details: newDetails });
      const newGoal = await createGoal(type, title, recurrenceType, newDetails);
      console.log('Goal created successfully:', newGoal);
      setShowAddGoal(false);
    } catch (error) {
      console.error('Error creating goal:', error);
    }
  };

  const handleProgressConfirmed = async (includeProgress) => {
    if (!pendingGoalCreation) return;
    
    try {
      if (!includeProgress) {
        // Clear existing reading progress
        await updateSettings({
          ...settings,
          quran: {
            ...settings.quran,
            readingProgress: { history: {} },
            lastReadingSession: null
          }
        });
      }
      
      // Create the goal
      const { type, title, recurrenceType, details } = pendingGoalCreation;
      await createGoal(type, title, recurrenceType, details);
      
      // Reset state
      setPendingGoalCreation(null);
      setShowProgressDialog(false);
      setShowAddGoal(false);
    } catch (error) {
      console.error('Error handling progress confirmation:', error);
    }
  };

  const handleRemoveAllGoals = async () => {
    try {
      await Promise.all(activeGoals.map(goal => deleteGoal(goal.id)));
      setShowConfirmDialog(false);
    } catch (error) {
      console.error('Error removing all goals:', error);
    }
  };

  const generateDummyData = async () => {
    if (!currentUser) return;
    
    try {
      // Create sample goals with proper structure
      const sampleGoals = [
        {
          id: Math.random().toString(36).substr(2, 9),
          type: 'quran_reading',
          title: 'Read Quran in 30 days',
          recurrenceType: 'duration_based',
          details: {
            type: 'quran_reading',
            totalAmount: 604,
            duration: 30,
            dailyPages: Math.ceil(604 / 30),
            surah: 2, // Starting with Surah Al-Baqarah
            fromVerse: 1,
            toVerse: 20 // Assuming about 20 verses per page
          },
          status: 'active'
        },
        {
          id: Math.random().toString(36).substr(2, 9),
          type: 'dhikr',
          title: 'After Prayer Dhikr',
          recurrenceType: 'prayer_based',
          details: {
            type: 'dhikr',
            requiredDhikrs: [
              { dhikrId: 'fullTasbih', count: 1, repetitions: 1 }
            ]
          },
          status: 'active'
        }
      ];

      // Remove legacy progress code and add actionLogs
      sampleGoals.forEach(goal => {
        const today = new Date();
        const startDateStr = today.toISOString().split('T')[0];
        const duration = goal.details.duration || 30; // default to 30 days if not present
        const endDateStr = addDays(today, duration - 1).toISOString().split('T')[0];
        
        let expectedAction;
          if (goal.type === 'quran_reading') {
          expectedAction = goal.details.dailyPages || 1;
        } else if (goal.type === 'dhikr' && goal.details.requiredDhikrs) {
          expectedAction = goal.details.requiredDhikrs.reduce((acc, d) => acc + d.count * d.repetitions, 0);
        } else {
          expectedAction = 1;
        }
        
        goal.startDate = startDateStr;
        goal.endDate = endDateStr;
        goal.actionLogs = initializeActionLogsForGoal(startDateStr, endDateStr, expectedAction);
      });

      // Write to Firebase
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: sampleGoals });
      
      // Reload goals using the hook's refresh function
      await createGoal(
        sampleGoals[0].type,
        sampleGoals[0].title,
        sampleGoals[0].recurrenceType,
        sampleGoals[0].details
      );
      await createGoal(
        sampleGoals[1].type,
        sampleGoals[1].title,
        sampleGoals[1].recurrenceType,
        sampleGoals[1].details
      );
    } catch (error) {
      console.error('Error generating dummy data:', error);
    }
  };

  const clearAllProgress = async () => {
    if (!currentUser) return;
    
    try {
      // Get current goals
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      const snapshot = await getDoc(goalsRef);
      
      if (snapshot.exists()) {
        const data = snapshot.data();
        const updatedGoals = data.goals.map(goal => ({
          ...goal,
          progress: {} // Clear all progress
        }));
        
        // Write back to Firebase
        await setDoc(goalsRef, { goals: updatedGoals });
      }
    } catch (error) {
      console.error('Error clearing progress:', error);
    }
  };

  const renderGoalProgress = (goal) => {
    const dayProgress = allProgress.get(todayStr);
    if (!dayProgress) return null;

    const progress = dayProgress.get(goal.id);
    if (!progress) return null;

    return (
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500">
          {goal.type === 'quran_reading' 
            ? `${progress.dailyTarget} per day` 
            : progress.dailyTarget}
        </div>
        <div className="text-sm">
          Today's Progress: {progress.percentComplete}%
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div 
            className="bg-primary h-2 rounded-full transition-all duration-300"
            style={{ width: `${progress.percentComplete}%` }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={`min-h-screen relative overflow-hidden ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-100/40'
        }`} />
        <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-50/60'
        }`} />
        
        {/* Additional light mode decorative elements */}
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
      </div>

      {/* Header */}
      <header className="sticky top-0 z-40">
        <div className={`${
          isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
        } backdrop-blur-xl border-b ${
          isDarkMode ? 'border-white/5' : 'border-black/5'
        }`}>
          <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
            <div className="flex items-center justify-between">
              <h1 className={`text-2xl font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Goals</h1>
              {activeGoals.length > 0 && (
                <button
                  onClick={() => setShowConfirmDialog(true)}
                  className={`p-2 rounded-xl transition-colors ${
                    isDarkMode
                      ? 'text-red-400 hover:bg-red-500/10'
                      : 'text-red-600 hover:bg-red-50'
                  }`}
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Content */}
      <div className="px-4 relative z-10">
        {/* Utility Buttons */}
        <div className="flex justify-between items-center mb-6 mt-4">
          <div className="flex space-x-2">
            <button
              onClick={generateDummyData}
              className={`px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300 ${
                isDarkMode
                  ? 'bg-emerald-500/10 text-emerald-400 hover:bg-emerald-500/20'
                  : 'bg-emerald-50 text-emerald-600 hover:bg-emerald-100'
              }`}
            >
              Generate Test Data
            </button>
            <button
              onClick={clearAllProgress}
              className={`px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300 ${
                isDarkMode
                  ? 'bg-red-500/10 text-red-400 hover:bg-red-500/20'
                  : 'bg-red-50 text-red-600 hover:bg-red-100'
              }`}
            >
              Clear Progress
            </button>
          </div>

          <Link
            to="/analytics"
            className={`px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300 ${
              isDarkMode
                ? 'bg-white/5 text-white hover:bg-white/10'
                : 'bg-black/5 text-gray-900 hover:bg-black/10'
            }`}
          >
            View Analytics
          </Link>
        </div>

        <AnimatePresence mode="sync">
          {activeGoals.map(goal => {
            console.log('Mapping goal:', goal);
            return goal && goal.id ? (
              <GoalCard
                key={goal.id}
                goal={goal}
                isDarkMode={isDarkMode}
                onMarkAction={updateGoalProgress}
              />
            ) : null;
          })}
        </AnimatePresence>
      </div>

      {/* Empty State */}
      {activeGoals.length === 0 && !loading && (
        <div className="px-4 py-12 text-center relative">
          <div className={`relative backdrop-blur-xl rounded-2xl p-8 ${
            isDarkMode ? 'bg-white/[0.02]' : 'bg-white/[0.3]'
          }`}>
            <Target className={`w-16 h-16 mx-auto mb-6 ${
              isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
            }`} />
            <h3 className={`text-xl font-medium mb-3 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Set Your First Goal</h3>
            <p className={`text-sm max-w-xs mx-auto ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              Begin your journey by setting meaningful goals for Quran reading, memorization, or daily dhikr.
            </p>
          </div>
        </div>
      )}

      {/* Floating Action Button */}
      <button
        onClick={() => setShowAddGoal(true)}
        style={{ bottom: 'calc(env(safe-area-inset-bottom) + 5rem)' }}
        className={`fixed right-4 w-24 h-24 rounded-full flex items-center justify-center z-50 ${
          isDarkMode
            ? 'bg-emerald-500 text-black hover:bg-emerald-400'
            : 'bg-gray-900 text-white hover:bg-gray-800'
        } transition-all duration-300 shadow-lg hover:scale-105`}
      >
        <Plus className="w-8 h-8" />
      </button>

      {/* Goal Selection Sheet */}
      <AnimatePresence>
        {showAddGoal && (
          <GoalSelectionView
            onClose={() => setShowAddGoal(false)}
            isDarkMode={isDarkMode}
            onGoalCreated={handleGoalCreated}
          />
        )}
      </AnimatePresence>

      {/* Confirm Dialog */}
      <AnimatePresence>
        {showConfirmDialog && (
          <ConfirmDialog
            isOpen={showConfirmDialog}
            onClose={() => setShowConfirmDialog(false)}
            onConfirm={handleRemoveAllGoals}
            isDarkMode={isDarkMode}
          />
        )}
      </AnimatePresence>

      {/* Progress Confirmation Dialog */}
      <AnimatePresence>
        {showProgressDialog && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              className={`w-full max-w-sm p-6 rounded-2xl ${
                isDarkMode ? 'bg-gray-900' : 'bg-white'
              }`}
            >
              <h3 className={`text-lg font-medium mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Existing Reading Progress
              </h3>
              <p className={`text-sm mb-4 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                You have existing reading progress. Would you like to include it in your new goal?
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => handleProgressConfirmed(false)}
                  className={`px-4 py-2 rounded-xl text-sm font-medium ${
                    isDarkMode
                      ? 'text-red-400 hover:bg-red-500/10'
                      : 'text-red-600 hover:bg-red-50'
                  }`}
                >
                  Clear Progress
                </button>
                <button
                  onClick={() => handleProgressConfirmed(true)}
                  className={`px-4 py-2 rounded-xl text-sm font-medium ${
                    isDarkMode
                      ? 'bg-emerald-500 text-black hover:bg-emerald-400'
                      : 'bg-emerald-600 text-white hover:bg-emerald-500'
                  }`}
                >
                  Keep Progress
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

// Helper function to generate action descriptions
const getActionDescription = (option) => {
  switch (option.recurrenceType) {
    case 'prayer_based':
      return 'After each prayer';
    case 'time_based':
      return option.details.window === 'morning' ? 'Every morning' : 'Every evening';
    case 'duration_based':
      const daily = Math.ceil(option.details.totalAmount / option.details.duration);
      switch (option.details.type) {
        case 'quran_reading':
          return `${daily} pages per day`;
        case 'memorization':
          return `${daily} verses per day`;
        case 'names':
          return `${daily} names per day`;
        default:
          return `${daily} per day`;
      }
    default:
      return '';
  }
};

// Helper function to get target amount for a goal
const getTargetAmount = (goal) => {
  if (goal.type === 'quran_reading') {
    return goal.details.dailyHizb;
  }
  return goal.details.dailyVerses || 0;
}; 