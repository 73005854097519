import React, { useState, useEffect, useCallback } from 'react';
import { Clock } from 'lucide-react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';
import { useSettings } from '../../../../contexts/SettingsContext';
import debounce from 'lodash/debounce';

export const SlideshowSettings = () => {
  const { isDarkMode } = useDarkMode();
  const { settings, updateSettings } = useSettings();
  const [localDuration, setLocalDuration] = useState(
    settings.simpleDashboard?.slideshowDuration || 60
  );

  // Debounced update function
  const debouncedUpdate = useCallback(
    debounce((value) => {
      updateSettings({
        simpleDashboard: {
          ...settings.simpleDashboard,
          slideshowDuration: value
        }
      });
    }, 500),
    [settings.simpleDashboard]
  );

  const handleDurationChange = (e) => {
    const value = Number(e.target.value);
    setLocalDuration(value);
    debouncedUpdate(value);
  };

  // Cleanup
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, []);

  return (
    <div className="mb-8">
      <div className="flex items-center gap-3 mb-4">
        <Clock className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
        <h3 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Slideshow Duration
        </h3>
      </div>
      <div className="px-4 py-3">
        <div className="flex items-center justify-between mb-2">
          <span className={`text-sm ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>
            {localDuration}s
          </span>
        </div>
        <input
          type="range"
          min="10"
          max="300"
          value={localDuration}
          onChange={handleDurationChange}
          className="w-full accent-emerald-500"
        />
      </div>
    </div>
  );
}; 