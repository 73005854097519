import { useState, useRef, useEffect, useCallback } from 'react';
import { AUDIO_BASE_URL, fetchAudioTimings } from '../../../services/quranService';

/*
TODO: Add back the following audio control features that were available in useQuranAudio:
- volume: number - Volume level
- handleVolumeChange: (value: number) => void - Volume control
- isMuted: boolean - Mute state
- toggleMute: () => void - Mute toggle
- repeatMode: string - Current repeat mode
- handleRepeatClick: () => void - Toggle repeat modes
- repeatingVerse: number | null - Currently repeating verse
- toggleRepeat: (index: number) => void - Toggle repeat for specific verse
- audioFiles: Array - List of audio files
*/

// This hook provides gapless audio playback using the Web Audio API.
const useGaplessQuranAudio = (selectedSurah, selectedReciter, currentVerse, setCurrentVerse) => {
  // State
  const [audioContextInitialized, setAudioContextInitialized] = useState(false);
  const [audioBuffers, setAudioBuffers] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [audioProgress, setAudioProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [audioFiles, setAudioFiles] = useState([]);

  // Refs
  const audioContextRef = useRef(null);
  const sourceRef = useRef(null);
  const nextStartTimeRef = useRef(0);
  const currentVerseStartTimeRef = useRef(0);
  const progressAnimationFrameRef = useRef(null);
  const shouldContinuePlayback = useRef(true);
  const scheduledSourcesRef = useRef([]);
  const unlockAudioRef = useRef(false);

  // Detect iOS
  const isIOS = useRef(
    typeof window !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );

  // Function declarations - move all function declarations to the top
  const clearSources = useCallback(() => {
    console.log('Clearing all sources...');
    shouldContinuePlayback.current = false;
    
    // Clear all scheduled sources
    scheduledSourcesRef.current.forEach(source => {
      try {
        source.stop();
        source.disconnect();
      } catch (e) {
        console.error('Error stopping scheduled source:', e);
      }
    });
    scheduledSourcesRef.current = [];

    // Clear current source
    if (sourceRef.current) {
      try {
        sourceRef.current.stop();
        sourceRef.current.disconnect();
        sourceRef.current = null;
      } catch (e) {
        console.error('Error stopping current source:', e);
      }
    }

    // Reset timing references
    nextStartTimeRef.current = 0;
    currentVerseStartTimeRef.current = 0;

    // Clear progress animation
    if (progressAnimationFrameRef.current) {
      cancelAnimationFrame(progressAnimationFrameRef.current);
      progressAnimationFrameRef.current = null;
    }
    setAudioProgress(0);
  }, []);

  const updateProgress = useCallback(() => {
    if (!audioContextRef.current || !currentVerseStartTimeRef.current || !audioDuration) return;
    
    const elapsed = audioContextRef.current.currentTime - currentVerseStartTimeRef.current;
    const progressPercentage = Math.min(100, (elapsed / audioDuration) * 100);
    setAudioProgress(progressPercentage);
    
    if (progressPercentage < 100 && isPlaying) {
      progressAnimationFrameRef.current = requestAnimationFrame(updateProgress);
    }
  }, [audioDuration, isPlaying]);

  const initializeAudioContext = useCallback(async () => {
    console.log('Initializing AudioContext...');
    try {
      if (!audioContextRef.current) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContextRef.current = new AudioContext();
        console.log('AudioContext created:', audioContextRef.current.state);

        if (isIOS.current && !unlockAudioRef.current) {
          const silentBuffer = audioContextRef.current.createBuffer(1, 1, 22050);
          const source = audioContextRef.current.createBufferSource();
          source.buffer = silentBuffer;
          source.connect(audioContextRef.current.destination);
          source.start(0);
          unlockAudioRef.current = true;
        }
      }

      if (audioContextRef.current.state === 'suspended') {
        console.log('Resuming suspended AudioContext...');
        await audioContextRef.current.resume();
        console.log('AudioContext resumed:', audioContextRef.current.state);
      }

      setAudioContextInitialized(true);
      return true;
    } catch (error) {
      console.error('Failed to initialize AudioContext:', error);
      return false;
    }
  }, []);

  const loadVerseBuffer = useCallback(async (verseIndex) => {
    if (!audioFiles[verseIndex] || !audioContextRef.current || audioBuffers[verseIndex]) return;

    try {
      const file = audioFiles[verseIndex];
      let url;

      // Handle different URL formats
      if (file.url.startsWith('http')) {
        // Full URL
        url = file.url;
      } else if (file.url.includes('mirrors.quranicaudio.com')) {
        // Direct quranicaudio URL - ensure https://
        url = `https://${file.url.replace(/^\/+/, '')}`;
      } else {
        // Relative URL - use base URL
        url = `${AUDIO_BASE_URL}${file.url}`;
      }
      
      console.log('Fetching audio file:', url);
      
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      const decoded = await audioContextRef.current.decodeAudioData(arrayBuffer);
      
      setAudioBuffers(prev => ({
        ...prev,
        [verseIndex]: decoded
      }));
      
      return decoded;
    } catch (error) {
      console.error('Error loading verse buffer:', error);
      // If first attempt fails and it's a quranicaudio URL, try everyayah CDN
      if (error.message.includes('404') && audioFiles[verseIndex].url.includes('mirrors.quranicaudio.com')) {
        try {
          const fallbackUrl = `https://everyayah.com/data/${audioFiles[verseIndex].url.split('/').pop()}`;
          console.log('Retrying with fallback URL:', fallbackUrl);
          
          const response = await fetch(fallbackUrl);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const arrayBuffer = await response.arrayBuffer();
          const decoded = await audioContextRef.current.decodeAudioData(arrayBuffer);
          
          setAudioBuffers(prev => ({
            ...prev,
            [verseIndex]: decoded
          }));
          
          return decoded;
        } catch (retryError) {
          console.error('Error on fallback attempt:', retryError);
        }
      }
      return null;
    }
  }, [audioFiles, audioBuffers]);

  const scheduleVerses = useCallback(async (startIndex, count = 2) => {
    if (!audioContextRef.current || !shouldContinuePlayback.current) return;

    const context = audioContextRef.current;
    const schedulingOffset = isIOS.current ? 0.1 : 0;
    let currentTime = Math.max(
      nextStartTimeRef.current || context.currentTime,
      context.currentTime + schedulingOffset
    );
    
    for (let i = 0; i < count && startIndex + i < audioFiles.length; i++) {
      const index = startIndex + i;
      
      let buffer = audioBuffers[index];
      if (!buffer) {
        buffer = await loadVerseBuffer(index);
        if (!buffer) continue;
      }

    const sourceNode = context.createBufferSource();
      sourceNode.buffer = buffer;
      
      const gainNode = context.createGain();
      sourceNode.connect(gainNode);
      gainNode.connect(context.destination);

      console.log(`Scheduling verse ${index} to start at:`, currentTime);
      sourceNode.start(currentTime);

      if (i === 0) {
        currentVerseStartTimeRef.current = currentTime;
        setAudioDuration(buffer.duration);
        sourceRef.current = sourceNode;
      } else {
        scheduledSourcesRef.current.push(sourceNode);
      }

      sourceNode.onended = () => {
        console.log(`Verse ${index} ended`);
        if (shouldContinuePlayback.current && index < audioFiles.length - 1) {
          setCurrentVerse(index + 1);
          if (i === count - 2) {
            setTimeout(() => {
              scheduleVerses(index + 2, 2);
            }, isIOS.current ? 50 : 0);
          }
        } else if (index === audioFiles.length - 1) {
          setIsPlaying(false);
          shouldContinuePlayback.current = false;
        }
      };

      currentTime += buffer.duration;
    }

    nextStartTimeRef.current = currentTime;
  }, [audioFiles, audioBuffers, loadVerseBuffer, setCurrentVerse]);

  const playVerse = useCallback(async (index) => {
    console.log('Attempting to play verse:', index);
    
    if (!audioFiles[index]) {
      console.log('No audio file metadata for verse:', index);
      return;
    }

    if (!audioContextRef.current) {
      console.log('No AudioContext, initializing...');
      const initialized = await initializeAudioContext();
      if (!initialized) {
        console.error('Failed to initialize AudioContext');
        return;
      }
    }

    if (audioContextRef.current.state === 'suspended') {
      console.log('AudioContext suspended, resuming...');
      await audioContextRef.current.resume();
    }

    console.log('AudioContext state:', audioContextRef.current.state);
    
    // Ensure clean state before starting new playback
    clearSources();
    
    // Reset timing references
    nextStartTimeRef.current = audioContextRef.current.currentTime;
    shouldContinuePlayback.current = true;

    try {
      await scheduleVerses(index);
      setCurrentVerse(index);
      setIsPlaying(true);
      progressAnimationFrameRef.current = requestAnimationFrame(updateProgress);
    } catch (error) {
      console.error('Error playing verse:', error);
      setIsPlaying(false);
      shouldContinuePlayback.current = false;
    }
  }, [audioFiles, clearSources, scheduleVerses, updateProgress, initializeAudioContext, setCurrentVerse]);

  const togglePlay = useCallback(async () => {
    console.log('Toggle play called, current state:', { isPlaying });
    
    try {
      if (isPlaying) {
        clearSources();
        setIsPlaying(false);
      } else {
        if (isIOS.current && audioContextRef.current?.state === 'suspended') {
          await audioContextRef.current.resume();
        }

        if (!audioContextRef.current) {
          const initialized = await initializeAudioContext();
          if (!initialized) {
            throw new Error('Failed to initialize AudioContext');
          }
        }

        shouldContinuePlayback.current = true;
        const verseIndex = typeof currentVerse === 'number' ? currentVerse : 0;
        await playVerse(verseIndex);
      }
    } catch (error) {
      console.error('Error in togglePlay:', error);
      setIsPlaying(false);
      shouldContinuePlayback.current = false;
      throw error;
    }
  }, [isPlaying, currentVerse, playVerse, clearSources, initializeAudioContext]);

  // Effects
  useEffect(() => {
    const fetchAudioFiles = async () => {
      if (!selectedSurah || !selectedReciter) return;
      
      // Stop any current playback and clear state
      clearSources();
      setIsPlaying(false);
      setAudioBuffers({});
      
      try {
        const audioData = await fetchAudioTimings(selectedReciter, selectedSurah);
        if (!audioData.audio_files || audioData.audio_files.length === 0) {
          setAudioFiles([]);
          return;
        }
        setAudioFiles(audioData.audio_files);
      } catch (error) {
        console.error('Error fetching audio files:', error);
        setAudioFiles([]);
      }
    };

    fetchAudioFiles();
  }, [selectedSurah, selectedReciter, clearSources]);

  // Add effect to clear audio context when reciter changes
  useEffect(() => {
    // Clear audio context and buffers when reciter changes
    if (audioContextRef.current) {
      clearSources();
      setIsPlaying(false);
      setAudioBuffers({});
      audioContextRef.current.close().catch(console.error);
      audioContextRef.current = null;
      setAudioContextInitialized(false);
    }
  }, [selectedReciter, clearSources]);

  useEffect(() => {
    if (!isIOS.current) return;

    const handleVisibilityChange = () => {
      if (document.hidden && isPlaying) {
        clearSources();
        setIsPlaying(false);
      }
    };

    const handleFocus = async () => {
      if (audioContextRef.current?.state === 'suspended') {
        await audioContextRef.current.resume();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [isPlaying, clearSources]);

  useEffect(() => {
    return () => {
      shouldContinuePlayback.current = false;
      clearSources();
      if (audioContextRef.current) {
        audioContextRef.current.close().catch(console.error);
      }
    };
  }, [clearSources]);

  return {
    isPlaying,
    audioBuffers,
    playVerse,
    togglePlay,
    audioContext: audioContextRef.current,
    audioProgress,
    audioDuration,
    audioContextInitialized,
    isLoading,
    clearSources
  };
};

export default useGaplessQuranAudio; 