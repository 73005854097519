import React, { useEffect } from 'react';
import { RotateCcw } from 'lucide-react';
import { MobilePageTransition } from './MobilePageTransition';
import { useSettings } from '../../../../contexts/SettingsContext';

// Define fixed prayer order
const PRAYER_ORDER = ['Fajr', 'Dhuhr', 'Asr', 'Maghrib', 'Isha'];

// Default values for tuned times
const DEFAULT_TUNED_TIMES = {
  Fajr: 0,
  Dhuhr: 0,
  Asr: 0,
  Maghrib: 0,
  Isha: 0
};

export const TuneView = ({ 
  show, 
  onClose, 
  tunedTimes = DEFAULT_TUNED_TIMES, 
  onTuneChange, 
  onSave, 
  onReset, 
  isDarkMode 
}) => {
  const { settings, updateSettings } = useSettings();
  
  // Ensure tunedTimes has all required prayers
  const safeTunedTimes = { ...DEFAULT_TUNED_TIMES, ...tunedTimes };
  
  // Add handlers for increment and decrement with debounce
  const handleIncrement = (prayer) => {
    const currentValue = parseInt(safeTunedTimes[prayer]) || 0;
    const newValue = currentValue + 1;
    
    // Update local state first to prevent UI jerking
    onTuneChange(prayer, newValue);
    
    // Save to settings context
    const updatedTunedTimes = {
      ...safeTunedTimes,
      [prayer]: newValue
    };
    
    // Create API format for tunedTimes
    const apiTunedTimes = {
      ...updatedTunedTimes,
      Imsak: 0,
      Sunrise: 0,
      Sunset: 0,
      Midnight: 0
    };
    
    // Save to settings
    updateSettings({ 
      tunedTimes: updatedTunedTimes,
      apiTunedTimes
    });
  };

  const handleDecrement = (prayer) => {
    const currentValue = parseInt(safeTunedTimes[prayer]) || 0;
    const newValue = currentValue - 1;
    
    // Update local state first to prevent UI jerking
    onTuneChange(prayer, newValue);
    
    // Save to settings context
    const updatedTunedTimes = {
      ...safeTunedTimes,
      [prayer]: newValue
    };
    
    // Create API format for tunedTimes
    const apiTunedTimes = {
      ...updatedTunedTimes,
      Imsak: 0,
      Sunrise: 0,
      Sunset: 0,
      Midnight: 0
    };
    
    // Save to settings
    updateSettings({ 
      tunedTimes: updatedTunedTimes,
      apiTunedTimes
    });
  };
  
  // Sync with settings when component mounts or settings change
  useEffect(() => {
    if (settings.tunedTimes && Object.keys(settings.tunedTimes).length > 0) {
      // Only update if the values are different to prevent loops
      const needsUpdate = PRAYER_ORDER.some(
        prayer => safeTunedTimes[prayer] !== settings.tunedTimes[prayer]
      );
      
      if (needsUpdate) {
        const syncedTimes = { ...DEFAULT_TUNED_TIMES };
        PRAYER_ORDER.forEach(prayer => {
          syncedTimes[prayer] = settings.tunedTimes[prayer] || 0;
        });
        onTuneChange(null, syncedTimes);
      }
    }
  }, [settings.tunedTimes]);

  // Handle reset with immediate save
  const handleReset = () => {
    onReset();
    // onSave is called within onReset in the parent component
  };

  return (
    <MobilePageTransition
      show={show}
      onClose={() => {
        // Ensure changes are saved when closing
        onSave();
        onClose();
      }}
      title="Fine-tune Prayer Times"
      isDarkMode={isDarkMode}
    >
      <div className={`rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-[#111111]' : 'bg-white'
      }`}>
        {PRAYER_ORDER.map((prayer, index) => (
          <div 
            key={prayer} 
            className={`flex items-center justify-between px-4 py-3 ${
              index !== PRAYER_ORDER.length - 1 
                ? isDarkMode ? 'border-b border-gray-800' : 'border-b border-gray-200'
                : ''
            }`}
          >
            <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
              {prayer}
            </span>
            <div className="flex items-center gap-4">
              <button
                onClick={() => handleDecrement(prayer)}
                className={`w-8 h-8 flex items-center justify-center rounded-full ${
                  isDarkMode
                    ? 'bg-gray-800 text-gray-300 active:bg-gray-700'
                    : 'bg-gray-100 text-gray-700 active:bg-gray-200'
                }`}
              >
                -
              </button>
              <span className={`w-8 text-center ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                {safeTunedTimes[prayer] || 0}
              </span>
              <button
                onClick={() => handleIncrement(prayer)}
                className={`w-8 h-8 flex items-center justify-center rounded-full ${
                  isDarkMode
                    ? 'bg-gray-800 text-gray-300 active:bg-gray-700'
                    : 'bg-gray-100 text-gray-700 active:bg-gray-200'
                }`}
              >
                +
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Reset Button Card */}
      <div className={`mt-6 rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-[#111111]' : 'bg-white'
      }`}>
        <button
          onClick={handleReset}
          className={`w-full px-4 py-3 flex items-center justify-center gap-2 ${
            isDarkMode
              ? 'text-red-400 hover:bg-red-500/10 active:bg-red-500/20'
              : 'text-red-600 hover:bg-red-50 active:bg-red-100'
          } transition-colors`}
        >
          <RotateCcw size={16} />
          Reset
        </button>
      </div>
    </MobilePageTransition>
  );
}; 