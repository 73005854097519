import axios from 'axios';

const cache = new Map();
const CACHE_DURATION = 60000; // 1 minute cache duration
const pendingRequests = new Map();

// Rate limiting configuration
const rateLimits = {
  maxRequests: 30, // Reduced from 50
  timeWindow: 60 * 1000, // 1 minute
  requests: [],
};

// Retry configuration
const retryConfig = {
  maxRetries: 3,
  initialDelay: 2000, // Increased from 1000
  maxDelay: 10000,
};

const clearOldRequests = () => {
  const now = Date.now();
  rateLimits.requests = rateLimits.requests.filter(
    time => now - time < rateLimits.timeWindow
  );
};

const canMakeRequest = () => {
  clearOldRequests();
  return rateLimits.requests.length < rateLimits.maxRequests;
};

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const calculateBackoff = (retryCount) => {
  const delay = Math.min(
    retryConfig.maxDelay,
    retryConfig.initialDelay * Math.pow(2, retryCount)
  );
  return delay + (Math.random() * 1000); // Add jitter
};

export const cachedRequest = async (key, requestFn, options = {}) => {
  const {
    forceFresh = false,
    cacheDuration = CACHE_DURATION,
  } = options;

  // Check cache first
  if (!forceFresh) {
    const cached = cache.get(key);
    if (cached && Date.now() - cached.timestamp < cacheDuration) {
      return cached.data;
    }
  } else {
    // If forceFresh is true, remove any existing cache entry
    cache.delete(key);
  }

  // Check for pending requests
  if (pendingRequests.has(key)) {
    return pendingRequests.get(key);
  }

  // Create the request promise
  const requestPromise = (async () => {
    let retryCount = 0;
    let lastError = null;

    while (retryCount <= retryConfig.maxRetries) {
      try {
        // Rate limiting check
        if (!canMakeRequest()) {
          const waitTime = Math.max(1000, calculateBackoff(retryCount));
          await wait(waitTime);
          continue;
        }

        rateLimits.requests.push(Date.now());
        const response = await requestFn();
        
        // Cache successful response
        cache.set(key, {
          data: response,
          timestamp: Date.now(),
        });
        
        return response;
      } catch (error) {
        lastError = error;
        
        if (
          error.response?.status === 429 ||
          (error.response?.status >= 500 && error.response?.status < 600)
        ) {
          if (retryCount === retryConfig.maxRetries) {
            break;
          }
          
          const backoffTime = calculateBackoff(retryCount);
          await wait(backoffTime);
          retryCount++;
          continue;
        }
        
        throw error;
      }
    }

    throw lastError || new Error('Max retries exceeded');
  })();

  // Store the pending request
  pendingRequests.set(key, requestPromise);

  try {
    const result = await requestPromise;
    return result;
  } finally {
    pendingRequests.delete(key);
  }
};

export const getCachedData = (key) => {
  const cachedItem = cache.get(key);
  if (!cachedItem) return null;

  const now = Date.now();
  if (now - cachedItem.timestamp > CACHE_DURATION) {
    cache.delete(key);
    return null;
  }

  return cachedItem.data;
};

export const setCachedData = (key, data) => {
  cache.set(key, {
    data,
    timestamp: Date.now()
  });
};

export const clearCache = () => {
  cache.clear();
};

export const prefetchData = async (key, requestFn) => {
  try {
    const data = await cachedRequest(key, requestFn);
    return data;
  } catch (error) {
    console.error(`Error prefetching data for key ${key}:`, error);
    return null;
  }
}; 