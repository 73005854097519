import React, { useState, useEffect } from 'react';
import { Card, Button } from 'flowbite-react';
import Select from 'react-select';
import Quiz from 'react-quiz-component';
import { namesOfAllah } from '../data/namesOfAllah';
import { Book, ChevronLeft, ChevronRight } from 'lucide-react';
import './custom-quiz-component.css';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const NamesOfAllah = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [mode, setMode] = useState('flashcard');
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [quizData, setQuizData] = useState(null);
  const sectionSize = 10;
  const totalSections = Math.ceil(namesOfAllah.length / sectionSize);
  const { currentUser } = useAuth();
  const [overallProgress, setOverallProgress] = useState(0);
  const [completedSections, setCompletedSections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProgress = async () => {
      if (currentUser) {
        const userProgressRef = doc(db, 'namesOfAllahProgress', currentUser.uid);
        try {
          const docSnap = await getDoc(userProgressRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setCurrentSection(data.currentSection || 0);
            setMode(data.mode || 'flashcard');
            setCurrentCardIndex(data.currentCardIndex || 0);
            setCompletedSections(data.completedSections || []);
            setOverallProgress(data.overallProgress || 0);
          }
        } catch (error) {
          console.error("Error loading progress:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    loadProgress();
  }, [currentUser]);

  useEffect(() => {
    const saveProgress = async () => {
      if (currentUser) {
        const userProgressRef = doc(db, 'namesOfAllahProgress', currentUser.uid);
        try {
          await setDoc(userProgressRef, {
            currentSection,
            mode,
            currentCardIndex,
            completedSections,
            overallProgress
          });
        } catch (error) {
          console.error("Error saving progress:", error);
        }
      }
    };

    if (!loading) {
      saveProgress();
    }
  }, [currentUser, currentSection, mode, currentCardIndex, completedSections, overallProgress, loading]);

  useEffect(() => {
    if (mode === 'quiz') {
      setQuizData(generateQuizData());
    }
  }, [currentSection, mode]);

  const updateOverallProgress = (completed) => {
    const progress = (completed.length / totalSections) * 100;
    setOverallProgress(progress);
  };

  const handleNextCard = () => {
    if (currentCardIndex < sectionSize - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else {
      setMode('quiz');
      setCurrentCardIndex(0);
    }
  };

  const handlePrevCard = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  const handleSectionComplete = () => {
    const newCompletedSections = [...new Set([...completedSections, currentSection])];
    setCompletedSections(newCompletedSections);
    updateOverallProgress(newCompletedSections);

    if (currentSection < totalSections - 1) {
      setCurrentSection(currentSection + 1);
      setMode('flashcard');
      setCurrentCardIndex(0);
    } else {
      setMode('completed');
    }
  };

  const handleSectionChange = (selectedOption) => {
    const newSection = parseInt(selectedOption.value);
    setCurrentSection(newSection);
    setMode('flashcard');
    setCurrentCardIndex(0);
  };

  const generateQuizData = () => {
    const startIndex = currentSection * sectionSize;
    const endIndex = startIndex + sectionSize;
    const currentNames = namesOfAllah.slice(0, endIndex);

    const questions = currentNames.flatMap((name, index) => [
      {
        question: `What is the transliteration of "${name.arabic}"?`,
        questionType: "text",
        answerSelectionType: "single",
        answers: [
          name.transliteration,
          ...getRandomNames(3, currentNames, index).map(n => n.transliteration)
        ],
        correctAnswer: "1",
        messageForCorrectAnswer: "Correct! Well done.",
        messageForIncorrectAnswer: "Incorrect. The correct answer is " + name.transliteration,
        explanation: `${name.arabic} (${name.transliteration}) means "${name.meaning}".`,
        point: 1
      },
      {
        question: `What is the meaning of "${name.transliteration}"?`,
        questionType: "text",
        answerSelectionType: "single",
        answers: [
          name.meaning,
          ...getRandomNames(3, currentNames, index).map(n => n.meaning)
        ],
        correctAnswer: "1",
        messageForCorrectAnswer: "Correct! Well done.",
        messageForIncorrectAnswer: "Incorrect. The correct answer is " + name.meaning,
        explanation: `${name.arabic} (${name.transliteration}) means "${name.meaning}".`,
        point: 1
      }
    ]);

    return {
      quizTitle: `99 Names of Allah - Section ${currentSection + 1}`,
      quizSynopsis: `Test your knowledge of the names you've learned so far.`,
      nrOfQuestions: "20",
      questions: shuffleArray(questions).slice(0, 20)
    };
  };

  const getRandomNames = (count, names, excludeIndex) => {
    const shuffled = names
      .map((name, index) => ({ name, index }))
      .filter(item => item.index !== excludeIndex)
      .sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count).map(item => item.name);
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const renderFlashcard = () => {
    const startIndex = currentSection * sectionSize;
    const name = namesOfAllah[startIndex + currentCardIndex];
    return (
      <div className="space-y-4 flex flex-col justify-start flex-grow">
        <div className="bg-gradient-to-r from-green-50 to-emerald-50 p-6 rounded-xl shadow-sm">
          <p className="text-center text-4xl font-arabic leading-loose text-green-800">
            {name.arabic}
          </p>
        </div>
        
        <div className="bg-gradient-to-r from-emerald-50 to-green-50 p-6 rounded-xl shadow-sm">
          <p className="text-center text-2xl text-gray-700">
            {name.transliteration}
          </p>
        </div>

        <div className="bg-gradient-to-r from-green-50 to-emerald-50 p-6 rounded-xl shadow-sm">
          <p className="text-center text-xl text-gray-600 italic">
            "{name.meaning}"
          </p>
        </div>
      </div>
    );
  };

  const renderQuiz = () => (
    <div className="p-4">
      {quizData && (
        <Quiz
          quiz={quizData}
          showDefaultResult={false}
          onComplete={handleSectionComplete}
          customResultPage={(obj) => (
            <div className="text-center p-4 bg-gradient-to-r from-green-50 to-emerald-50 rounded-xl shadow-sm">
              <h2 className="text-2xl font-bold mb-4 text-green-800">Section Complete!</h2>
              <p className="mb-4 text-green-700">You scored {obj.correctPoints} out of {obj.totalPoints}.</p>
              {currentSection < totalSections - 1 ? (
                <Button color="green" onClick={handleSectionComplete}>
                  Next Section
                  <ChevronRight size={16} className="ml-2" />
                </Button>
              ) : (
                <Button color="green" onClick={() => {
                  setCurrentSection(0);
                  setMode('flashcard');
                  setCurrentCardIndex(0);
                }}>
                  Start Over
                  <ChevronRight size={16} className="ml-2" />
                </Button>
              )}
            </div>
          )}
        />
      )}
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      borderColor: state.isFocused ? '#10B981' : '#D1D5DB',
      boxShadow: state.isFocused ? '0 0 0 1px #10B981' : null,
      '&:hover': {
        borderColor: '#10B981'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#10B981' : state.isFocused ? '#E5E7EB' : null,
      color: state.isSelected ? 'white' : 'black',
    }),
  };

  const sectionOptions = [...Array(totalSections)].map((_, index) => ({
    value: index.toString(),
    label: `Section ${index + 1} (Names ${index * sectionSize + 1} - ${Math.min((index + 1) * sectionSize, namesOfAllah.length)})`
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="w-full h-full p-0 overflow-hidden bg-white rounded-2xl flex flex-col" id="namesOfAllahCard">
          <h5 className="text-xl text-center p-4">
            99 Names of Allah
          </h5>
          
          <div className="p-4">
            <Select
              options={sectionOptions}
              onChange={handleSectionChange}
              value={sectionOptions.find(option => option.value === currentSection.toString())}
              styles={customStyles}
              placeholder="Select a section"
            />
          </div>

          {mode === 'flashcard' ? (
            <div className="px-4 flex-grow">
              {renderFlashcard()}
              <div className="flex justify-between items-center mt-6">
                <Button color="light" onClick={handlePrevCard} disabled={currentCardIndex === 0}>
                  <ChevronLeft size={16} className="mr-2" />
                  Previous
                </Button>
                <Button color="green" onClick={handleNextCard}>
                  {currentCardIndex === sectionSize - 1 ? "Start Quiz" : "Next"}
                  <ChevronRight size={16} className="ml-2" />
                </Button>
              </div>
            </div>
          ) : renderQuiz()}
        </Card>

        <Card className="w-full h-full p-0 overflow-hidden bg-white rounded-2xl flex flex-col items-center justify-center">
          <h5 className="text-xl text-center p-4">
            Overall Progress
          </h5>
          <div className="w-64 h-64">
            <CircularProgressbar
              value={overallProgress}
              text={`${overallProgress.toFixed(0)}%`}
              styles={buildStyles({
                textSize: '16px',
                pathColor: '#047857',
                textColor: '#047857',
                trailColor: '#d6d6d6',
              })}
            />
          </div>
          <p className="mt-4 text-center text-gray-600">
            You've completed {completedSections.length} out of {totalSections} sections
          </p>
        </Card>
      </div>
    </div>
  );
};

export default NamesOfAllah;
