import React from 'react';
import { Modal, Button, TextInput } from 'flowbite-react';

const NoteModal = ({ 
  showNoteModal, 
  setShowNoteModal, 
  noteText, 
  setNoteText, 
  handleSaveNote 
}) => {
  return (
    <Modal show={showNoteModal} onClose={() => setShowNoteModal(false)}>
      <Modal.Header>Add Note</Modal.Header>
      <Modal.Body>
        <TextInput
          type="text"
          placeholder="Enter your note"
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          className="w-full"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSaveNote} color="green">Save Note</Button>
        <Button color="gray" onClick={() => setShowNoteModal(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoteModal;
