import { fetchSurah } from '../services/quranService';
import { QuranCacheManager } from './QuranCacheManager';

export const PredictiveLoadManager = {
  loadingQueue: new Set(),
  
  async initiatePredictiveLoad(currentSurahId, translationId) {
    // Clear previous queue
    this.loadingQueue.clear();
    
    // Add next and previous surahs to queue
    const nextSurahId = Math.min(114, parseInt(currentSurahId) + 1);
    const prevSurahId = Math.max(1, parseInt(currentSurahId) - 1);
    
    this.queueSurahLoad(nextSurahId, translationId, 'high');
    this.queueSurahLoad(prevSurahId, translationId, 'medium');
    
    // Queue frequently read surahs
    await this.queueFrequentSurahs(translationId);
  },

  async queueSurahLoad(surahId, translationId, priority) {
    if (this.loadingQueue.has(`${surahId}_${translationId}`)) return;
    
    this.loadingQueue.add(`${surahId}_${translationId}`);
    
    // Use requestIdleCallback for background loading
    const callback = async () => {
      try {
        // Check if already cached
        const cached = await QuranCacheManager.getSurahFromCache(surahId, translationId);
        if (cached) return;

        // Fetch and cache
        const data = await fetchSurah(surahId, translationId);
        if (data.verses?.length > 0) {
          await QuranCacheManager.cacheSurah(surahId, translationId, data.verses);
          console.log(`📥 Pre-loaded surah ${surahId} (${priority} priority)`);
        }
      } catch (error) {
        console.error(`Error pre-loading surah ${surahId}:`, error);
      } finally {
        this.loadingQueue.delete(`${surahId}_${translationId}`);
      }
    };

    // Use requestIdleCallback if available, otherwise setTimeout
    if (typeof requestIdleCallback !== 'undefined') {
      requestIdleCallback(callback, { timeout: priority === 'high' ? 1000 : 2000 });
    } else {
      setTimeout(callback, priority === 'high' ? 100 : 500);
    }
  },

  async getFrequentlyReadSurahs() {
    // For now, return some commonly read surahs
    // This could be enhanced later to use actual reading history
    return [1, 36, 67, 78]; // Al-Fatiha, Yasin, Al-Mulk, An-Naba
  },

  async queueFrequentSurahs(translationId) {
    const frequentSurahs = await this.getFrequentlyReadSurahs();
    
    frequentSurahs.forEach(surahId => {
      this.queueSurahLoad(surahId, translationId, 'low');
    });
  }
}; 