class PermissionManager {
  constructor() {
    this.locationPermission = 'unknown';
    this.motionPermission = 'unknown';
    this.listeners = new Set();
    this.initTimeout = null;
    this.retryCount = 0;
    this.maxRetries = 3;
  }

  init() {
    if (window.isUmmahtiNative) {
      // Listen for native permission updates
      window.addEventListener('native-location-update', this.handleLocationUpdate);
      window.addEventListener('native-compass-update', this.handleCompassUpdate);
      
      // Set timeout for initial permission check
      this.initTimeout = setTimeout(() => {
        if (this.locationPermission === 'unknown' || this.motionPermission === 'unknown') {
          this.retryPermissionCheck();
        }
      }, 5000);

      // Request initial state
      if (window.ummahti?.checkPermissions) {
        console.log('[PermissionManager] Requesting initial permissions');
        window.ummahti.checkPermissions();
      } else {
        console.warn('[PermissionManager] window.ummahti.checkPermissions not available');
        this.retryPermissionCheck();
      }
    } else {
      console.log('[PermissionManager] Not running in native environment');
      this.locationPermission = 'web';
      this.motionPermission = 'web';
      this.notifyListeners();
    }
  }

  retryPermissionCheck() {
    if (this.retryCount < this.maxRetries) {
      this.retryCount++;
      console.log(`[PermissionManager] Retrying permission check (${this.retryCount}/${this.maxRetries})`);
      if (window.ummahti?.checkPermissions) {
        window.ummahti.checkPermissions();
        this.initTimeout = setTimeout(() => {
          if (this.locationPermission === 'unknown' || this.motionPermission === 'unknown') {
            this.retryPermissionCheck();
          }
        }, 5000);
      }
    } else {
      console.error('[PermissionManager] Failed to initialize permissions after maximum retries');
      this.locationPermission = 'failed';
      this.motionPermission = 'failed';
      this.notifyListeners();
    }
  }

  handleLocationUpdate = (event) => {
    console.log('[PermissionManager] Location update:', event.detail);
    const { permissionState } = event.detail;
    if (this.locationPermission !== permissionState) {
      this.locationPermission = permissionState;
      this.notifyListeners();
    }
  }

  handleCompassUpdate = (event) => {
    console.log('[PermissionManager] Compass update:', event.detail);
    const { permissionState } = event.detail;
    if (this.motionPermission !== permissionState) {
      this.motionPermission = permissionState;
      this.notifyListeners();
    }
  }

  addListener(callback) {
    this.listeners.add(callback);
    // Immediately notify with current state
    callback({
      location: this.locationPermission,
      motion: this.motionPermission
    });
  }

  removeListener(callback) {
    this.listeners.delete(callback);
  }

  notifyListeners() {
    const state = {
      location: this.locationPermission,
      motion: this.motionPermission
    };
    this.listeners.forEach(callback => {
      if (typeof callback === 'function') {
        callback(state);
      } else {
        console.warn('[PermissionManager] Encountered a non-function listener:', callback);
      }
    });
  }

  cleanup() {
    if (this.initTimeout) {
      clearTimeout(this.initTimeout);
    }
    window.removeEventListener('native-location-update', this.handleLocationUpdate);
    window.removeEventListener('native-compass-update', this.handleCompassUpdate);
    this.listeners.clear();
  }
}

export const permissionManager = new PermissionManager(); 