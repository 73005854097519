import { useEffect, useRef } from 'react';

const useVerseReadTracking = ({ containerRef, verses, isRead, onVerseRead }) => {
  const observerRef = useRef(null);
  const readTimeoutRef = useRef({});
  const readTimestampsRef = useRef({});
  const lastScrollPositionRef = useRef(0);
  const isScrollingRef = useRef(false);
  const scrollTimeoutRef = useRef(null);
  const processedVersesRef = useRef(new Set());
  const seenVersesRef = useRef(new Set()); // Track verses that have been seen but not necessarily marked as read
  const wasInitializedRef = useRef(false);
  const isIOSRef = useRef(/iPad|iPhone|iPod/.test(navigator.userAgent));
  const containerExistsRef = useRef(false);

  useEffect(() => {
    // Guard against undefined values that could cause errors
    if (!containerRef || !containerRef.current || !verses || !Array.isArray(verses) || !isRead || !onVerseRead) {
      return;
    }

    // Adjust timings for iOS
    const MIN_READ_TIME = isIOSRef.current ? 300 : 500; // Even shorter for iOS
    const SCROLL_DEBOUNCE = isIOSRef.current ? 25 : 50; // More frequent updates on iOS
    const viewportHeight = window.innerHeight;

    // Only log initialization once per component mount
    if (!wasInitializedRef.current) {
      console.log('🔍 VerseReadTracking initialized with', { 
        versesCount: verses.length,
        containerExists: !!containerRef.current,
        isIOS: isIOSRef.current
      });
      wasInitializedRef.current = true;
    }

    // Function to check if element is above viewport with iOS-specific margin
    const isAboveViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return rect.bottom < (isIOSRef.current ? -50 : 0); // Add margin for iOS
    };

    // Function to check if element is in viewport with lower threshold for iOS
    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      const visibleHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);
      const threshold = isIOSRef.current ? 0.15 : 0.2; // Lower threshold for iOS
      const visibilityRatio = visibleHeight / rect.height;
      return visibilityRatio > threshold;
    };

    // Function to start tracking read time for a verse
    const startReadTracking = (verseNum) => {
      if (!readTimestampsRef.current[verseNum]) {
        readTimestampsRef.current[verseNum] = Date.now();
        seenVersesRef.current.add(verseNum); // Mark as seen
      }
    };

    // Function to check if verse has been visible long enough
    const hasBeenVisibleLongEnough = (verseNum) => {
      const startTime = readTimestampsRef.current[verseNum];
      return startTime && (Date.now() - startTime) >= MIN_READ_TIME;
    };

    // Function to handle marking a verse as read with improved duplicate checking
    const handleVerseRead = (verseNum) => {
      // Don't mark as read if already read or already processed
      if (isRead[verseNum] || processedVersesRef.current.has(verseNum)) return;

      // Clear any existing timeout for this verse
      if (readTimeoutRef.current[verseNum]) {
        clearTimeout(readTimeoutRef.current[verseNum]);
      }

      // Start tracking if not already tracking
      startReadTracking(verseNum);

      // Only mark as read if visible long enough or if it's a gap fill
      if (hasBeenVisibleLongEnough(verseNum)) {
        // Reduce logging to just meaningful events with batch info
        if (!processedVersesRef.current.has(verseNum)) {
          console.log(`🔖 Marking verse ${verseNum} as read`);
        }
        onVerseRead(verseNum);
        processedVersesRef.current.add(verseNum);
        delete readTimeoutRef.current[verseNum];
        delete readTimestampsRef.current[verseNum];
      } else {
        // Set timeout to check again after the minimum read time
        readTimeoutRef.current[verseNum] = setTimeout(() => {
          if (hasBeenVisibleLongEnough(verseNum)) {
            if (!processedVersesRef.current.has(verseNum)) {
              console.log(`🔖 Marking verse ${verseNum} as read after timeout`);
            }
            onVerseRead(verseNum);
            processedVersesRef.current.add(verseNum);
            delete readTimeoutRef.current[verseNum];
            delete readTimestampsRef.current[verseNum];
          }
        }, MIN_READ_TIME);
      }
    };

    // Improved smart gap filling function - more aggressive with smaller gaps
    const fillReadingGaps = () => {
      // Safety check: ensure containerRef exists and has a current property
      if (!containerRef || !containerRef.current) {
        console.warn('Container ref is null during gap filling');
        return;
      }
      
      // Get all verse elements
      const elements = containerRef.current.querySelectorAll('[data-verse-id]');
      if (!elements || elements.length === 0) return;
      
      // Find the lowest unread verse number that is above the viewport
      let lowestUnreadAbove = Infinity;
      let highestReadBelow = -1;
      
      elements.forEach(element => {
        const verseId = element.getAttribute('data-verse-id');
        if (!verseId) return;
        
        const [_, verseNumStr] = verseId.split(':');
        const verseNum = parseInt(verseNumStr);
        
        if (isAboveViewport(element)) {
          if (!isRead[verseNumStr] && verseNum < lowestUnreadAbove) {
            lowestUnreadAbove = verseNum;
          }
        } else if (!isAboveViewport(element) && !isInViewport(element)) {
          if (isRead[verseNumStr] && verseNum > highestReadBelow) {
            highestReadBelow = verseNum;
          }
        }
      });
      
      const verseNumbers = Object.keys(isRead)
        .map(Number)
        .filter(num => !isNaN(num))
        .sort((a, b) => a - b);

      if (verseNumbers.length < 2) return;

      const versesBefore = [...processedVersesRef.current].length;
      
      // Find all sequences of read verses
      let sequences = [];
      let currentSequence = [verseNumbers[0]];
      
      for (let i = 1; i < verseNumbers.length; i++) {
        if (verseNumbers[i] === verseNumbers[i-1] + 1) {
          currentSequence.push(verseNumbers[i]);
        } else {
          sequences.push([...currentSequence]);
          currentSequence = [verseNumbers[i]];
        }
      }
      
      if (currentSequence.length > 0) {
        sequences.push(currentSequence);
      }
      
      // Fill small gaps between sequences (now max 5 verses instead of 3)
      for (let i = 0; i < sequences.length - 1; i++) {
        const currentSeqEnd = sequences[i][sequences[i].length - 1];
        const nextSeqStart = sequences[i+1][0];
        
        // More aggressive gap filling for small gaps
        if (nextSeqStart - currentSeqEnd <= 6) { // Increased from 4 to 6
          for (let verseNum = currentSeqEnd + 1; verseNum < nextSeqStart; verseNum++) {
            if (!isRead[verseNum] && !processedVersesRef.current.has(verseNum.toString())) {
              onVerseRead(verseNum.toString());
              processedVersesRef.current.add(verseNum.toString());
            }
          }
        }
      }
      
      // Handle skipped verses within a sequence with looser criteria
      sequences.forEach(sequence => {
        // Only require a sequence of 3 verses (down from 5)
        if (sequence.length >= 3) { 
          const start = sequence[0];
          const end = sequence[sequence.length - 1];
          
          for (let verseNum = start; verseNum <= end; verseNum++) {
            if (!isRead[verseNum] && !processedVersesRef.current.has(verseNum.toString())) {
              // Fill any "holes" in sequence, not just those with adjacent read verses
              const hasNearbyVerses = 
                (isRead[verseNum-1] || isRead[verseNum-2]) && 
                (isRead[verseNum+1] || isRead[verseNum+2]);
                
              if (hasNearbyVerses || seenVersesRef.current.has(verseNum.toString())) {
                onVerseRead(verseNum.toString());
                processedVersesRef.current.add(verseNum.toString());
              }
            }
          }
        }
      });
      
      // Special case for isolated verses - try to connect them by filling small gaps
      // This is aggressive but helps ensure continuous reading progress
      const allVerseNums = Array.from(
        new Set([
          ...verseNumbers,
          ...Array.from(seenVersesRef.current).map(v => parseInt(v))
        ])
      ).filter(num => !isNaN(num)).sort((a, b) => a - b);
      
      if (allVerseNums.length < 2) return;
      
      // Connect nearby isolated verses
      for (let i = 0; i < allVerseNums.length - 1; i++) {
        const current = allVerseNums[i];
        const next = allVerseNums[i + 1];
        
        // If there's a small gap (1-2 verses) between seen or read verses, fill it
        if (next - current > 1 && next - current <= 3) {
          for (let verseNum = current + 1; verseNum < next; verseNum++) {
            if (!isRead[verseNum] && !processedVersesRef.current.has(verseNum.toString())) {
              onVerseRead(verseNum.toString());
              processedVersesRef.current.add(verseNum.toString());
            }
          }
        }
      }
      
      // Log only when verses were actually filled
      const versesAfter = [...processedVersesRef.current].length;
      if (versesAfter > versesBefore) {
        console.log(`🧩 Filled ${versesAfter - versesBefore} gaps in verse reading`);
      }
    };

    // Function to handle scroll events with improved iOS handling
    const handleScroll = () => {
      // Only track if we have a container and verses to track
      if (containerRef && containerRef.current && Object.keys(verses).length > 0) {
        if (isScrollingRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }

        isScrollingRef.current = true;

        // Process verses during scrolling (not just after)
        const currentScroll = window.scrollY;
        const scrollingDown = currentScroll > lastScrollPositionRef.current;
        
        // Safety check: ensure containerRef.current exists before querying
        if (!containerRef.current) {
          console.warn('Container ref is null during scroll handling');
          return;
        }
        
        const elements = containerRef.current.querySelectorAll('[data-verse-id]');
        elements.forEach(element => {
          const verseId = element.getAttribute('data-verse-id');
          if (!verseId) return;
          
          const [_, verseNum] = verseId.split(':');
          
          if (!isRead[verseNum] && !processedVersesRef.current.has(verseNum)) {
            if (isAboveViewport(element) && scrollingDown) {
              handleVerseRead(verseNum);
            } else if (isInViewport(element)) {
              startReadTracking(verseNum);
              // More aggressive immediate marking during scrolling, especially on iOS
              const rect = element.getBoundingClientRect();
              const visibilityRatio = (Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0)) / rect.height;
              const threshold = isIOSRef.current ? 0.3 : 0.5; // Lower threshold for iOS
              if (visibilityRatio > threshold) {
                handleVerseRead(verseNum);
              }
            }
          }
        });

        // Fill gaps even during active scrolling with a very small throttle
        if (!scrollTimeoutRef.current) {
          scrollTimeoutRef.current = setTimeout(() => {
            fillReadingGaps();
            scrollTimeoutRef.current = null;
          }, isIOSRef.current ? 50 : 100); // Shorter throttle for iOS
        }
        
        lastScrollPositionRef.current = currentScroll;

        // Reset scrolling state and do final processing after scroll stops
        clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = setTimeout(() => {
          isScrollingRef.current = false;
          
          // One more check of all verses after scrolling stops
          elements.forEach(element => {
            const verseId = element.getAttribute('data-verse-id');
            if (!verseId) return;
            
            const [_, verseNum] = verseId.split(':');
            
            if (!isRead[verseNum] && !processedVersesRef.current.has(verseNum)) {
              if (isInViewport(element) || isAboveViewport(element)) {
                handleVerseRead(verseNum);
              }
            }
          });

          // Final gap filling after scroll has stopped
          fillReadingGaps();
        }, SCROLL_DEBOUNCE);
      }
    };

    // Handle touchend events specifically for iOS scrolling momentum
    const handleTouchEnd = () => {
      setTimeout(handleScroll, 100); // Check after touch scroll momentum ends
    };

    // Set up scroll handling and return cleanup
    const setupScrollHandling = () => {
      // Safety check: ensure we have a valid container
      if (!containerRef || !containerRef.current) {
        console.log('🔍 VerseReadTracking - Container not available for scroll setup');
        return cleanup; // Return cleanup early
      }
      
      // Update flags
      containerExistsRef.current = true;
      
      // Clean up any existing handler
      window.removeEventListener('scroll', handleScroll);
      
      // Add new handler with passive and capture options for iOS
      window.addEventListener('scroll', handleScroll, { 
        passive: true,
        capture: true // Add capture for iOS
      });
      
      // Initial check for verses in viewport without scrolling
      handleScroll();
      
      // On iOS, also listen for touchend events to catch quick flick scrolling
      if (isIOSRef.current) {
        containerRef.current.addEventListener('touchend', handleTouchEnd, { passive: true });
      }
      
      // Count the verses for logging
      const verseCount = containerRef.current.querySelectorAll('[data-verse-id]').length;
      console.log('🔍 VerseReadTracking initialized with', {
        versesCount: verseCount,
        containerExists: !!containerRef.current,
        isIOS: isIOSRef.current
      });
      
      return cleanup;
    };

    setupScrollHandling();

    // Cleanup function to remove all event listeners
    const cleanup = () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll, { capture: true });
      }
      
      // Clean up iOS-specific touch listeners
      if (isIOSRef.current && containerRef && containerRef.current) {
        containerRef.current.removeEventListener('touchend', handleTouchEnd);
      }
      
      // Clear scrolling timeout
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = null;
      }
      
      // Clear all verse read timeouts
      if (readTimeoutRef.current) {
        Object.values(readTimeoutRef.current).forEach(timeout => {
          if (timeout) clearTimeout(timeout);
        });
      }
      
      // Reset container existence flag
      containerExistsRef.current = false;
      
      console.log('🔍 VerseReadTracking - Cleaned up event listeners and timeouts');
    };

    return cleanup;
  }, [verses, isRead, onVerseRead]);

  return null;
};

export default useVerseReadTracking; 