import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSettings } from '../../../contexts/SettingsContext';
import { fetchSurah, fetchSurahs } from '../../../services/quranService';
import { useGoals } from '../../../hooks/useGoals';
import { HIZB_RANGES } from '../../../types/goals';
import debounce from 'lodash/debounce';
import { useReadingProgress } from '../../../hooks/useReadingProgress';
import { QuranCacheManager } from '../../../utils/QuranCacheManager';
import { PredictiveLoadManager } from '../../../utils/PredictiveLoadManager';

const SAFETY_NET_SAVE_INTERVAL = 5 * 60 * 1000; // 5 minutes

// Helper function to parse verse key into numbers
const parseVerseKey = (verseKey) => {
  const [surah, verse] = verseKey.split(':').map(Number);
  return { surah, verse };
};

// Helper function to check if a verse is within a hizb range
const isVerseInHizbRange = (verseKey, hizbRange) => {
  const { surah: currentSurah, verse: currentVerse } = parseVerseKey(verseKey);
  const [fromPart, toPart] = hizbRange.split(' - ');
  
  const fromMatch = fromPart.match(/(\d+):(\d+)$/);
  const toMatch = toPart.match(/(\d+):(\d+)$/);
  
  if (!fromMatch || !toMatch) return false;
  
  const fromSurah = parseInt(fromMatch[1]);
  const fromVerse = parseInt(fromMatch[2]);
  const toSurah = parseInt(toMatch[1]);
  const toVerse = parseInt(toMatch[2]);
  
  if (currentSurah < fromSurah || currentSurah > toSurah) return false;
  if (currentSurah === fromSurah && currentVerse < fromVerse) return false;
  if (currentSurah === toSurah && currentVerse > toVerse) return false;
  
  return true;
};

// Helper function to get total verses in a hizb range
const getVersesInHizbRange = (hizbRange) => {
  const [fromPart, toPart] = hizbRange.split(' - ');
  
  const fromMatch = fromPart.match(/(\d+):(\d+)$/);
  const toMatch = toPart.match(/(\d+):(\d+)$/);
  
  if (!fromMatch || !toMatch) return 0;
  
  const fromSurah = parseInt(fromMatch[1]);
  const fromVerse = parseInt(fromMatch[2]);
  const toSurah = parseInt(toMatch[1]);
  const toVerse = parseInt(toMatch[2]);
  
  if (fromSurah === toSurah) {
    return toVerse - fromVerse + 1;
  }
  
  // For now, use a simple calculation
  // TODO: Use actual verse counts from surahs data for cross-surah ranges
  return toVerse - fromVerse + 1;
};

// Add this at the top level with other constants
const VERSES_PER_PAGE = 10;

// Helper function to get total verses for a surah
const SURAH_VERSE_COUNTS = {
  "1": 7, "2": 286, "3": 200, "4": 176, "5": 120,
  "6": 165, "7": 206, "8": 75, "9": 129, "10": 109,
  "11": 123, "12": 111, "13": 43, "14": 52, "15": 99,
  "16": 128, "17": 111, "18": 110, "19": 98, "20": 135,
  "21": 112, "22": 78, "23": 118, "24": 64, "25": 77,
  "26": 227, "27": 93, "28": 88, "29": 69, "30": 60,
  "31": 34, "32": 30, "33": 73, "34": 54, "35": 45,
  "36": 83, "37": 182, "38": 88, "39": 75, "40": 85,
  "41": 54, "42": 53, "43": 89, "44": 59, "45": 37,
  "46": 35, "47": 38, "48": 29, "49": 18, "50": 45,
  "51": 60, "52": 49, "53": 62, "54": 55, "55": 78,
  "56": 96, "57": 29, "58": 22, "59": 24, "60": 13,
  "61": 14, "62": 11, "63": 11, "64": 18, "65": 12,
  "66": 12, "67": 30, "68": 52, "69": 52, "70": 44,
  "71": 28, "72": 28, "73": 20, "74": 56, "75": 40,
  "76": 31, "77": 50, "78": 40, "79": 46, "80": 42,
  "81": 29, "82": 19, "83": 36, "84": 25, "85": 22,
  "86": 17, "87": 19, "88": 26, "89": 30, "90": 20,
  "91": 15, "92": 21, "93": 11, "94": 8, "95": 8,
  "96": 19, "97": 5, "98": 8, "99": 8, "100": 11,
  "101": 11, "102": 8, "103": 3, "104": 9, "105": 5,
  "106": 4, "107": 7, "108": 3, "109": 6, "110": 3,
  "111": 5, "112": 4, "113": 5, "114": 6
};

const useQuranContent = () => {
  const { settings, updateSettings, isInitialized: isSettingsInitialized } = useSettings();
  const { goals, updateGoalProgress } = useGoals();
  const { readingState, markVerseAsRead } = useReadingProgress();
  
  // Core state
  const [surahs, setSurahs] = useState([]);
  const [selectedSurah, setSelectedSurah] = useState(null);
  const [surahContent, setSurahContent] = useState(null);
  const [currentVerse, setCurrentVerse] = useState(0);
  const [footnotes, setFootnotes] = useState({});
  
  // Loading state
  const [loading, setLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  // Track observed verses in local state (only for intersection observer)
  const [observedVerses, setObservedVerses] = useState(new Set());

  // Use ref to track previous values
  const prevSettingsRef = useRef(settings.quran);
  const prevSelectedSurahRef = useRef(selectedSurah);
  const prevCurrentVerseRef = useRef(currentVerse);

  // Memoize settings to prevent unnecessary updates
  const quranSettings = useMemo(() => settings.quran || {}, [settings.quran]);

  // Add reading mode check
  const isReadingMode = useMemo(() => quranSettings.mode === 'reading', [quranSettings.mode]);

  // Update local state when verse becomes visible
  const handleVerseIntersection = useCallback((entries) => {
    if (!isReadingMode || !selectedSurah || !isSettingsInitialized) return;

    // Ensure readingState is valid
    if (!readingState || typeof readingState !== 'object') {
      console.warn('Invalid reading state detected in handleVerseIntersection');
      return;
    }

    entries.forEach(entry => {
      const verseId = entry.target.getAttribute('data-verse-id');
      if (entry.isIntersecting && !observedVerses.has(verseId)) {
        // Update observed verses set
        setObservedVerses(prev => {
          const newSet = new Set(prev);
          newSet.add(verseId);
          return newSet;
        });

        // Mark verse as read
        try {
          markVerseAsRead(selectedSurah, verseId);
        } catch (error) {
          console.error('Error marking verse as read in intersection handler:', error);
        }

        // Handle goal progress
        const today = new Date().toISOString().split('T')[0];
        const activeGoal = goals?.find(g => {
          if (g.type !== 'quran_reading' || g.status !== 'active') return false;
          const todayProgress = g.progress?.[today];
          if (!todayProgress) return false;
          const rangeToCheck = todayProgress.hizbRange || todayProgress.range;
          return rangeToCheck && isVerseInHizbRange(verseId, rangeToCheck);
        });

        if (activeGoal) {
          const todayProgress = activeGoal.progress?.[today] || { amount: 0, completed: false };
          const newAmount = todayProgress.amount + 1;
          const rangeToCheck = todayProgress.hizbRange || todayProgress.range;
          const totalVerses = getVersesInHizbRange(rangeToCheck);
          
          updateGoalProgress(
            activeGoal.id,
            newAmount >= totalVerses,
            newAmount,
            null,
            today,
            { hizbRange: rangeToCheck }
          );
        }
      }
    });
  }, [isReadingMode, selectedSurah, observedVerses, markVerseAsRead, isSettingsInitialized, goals, updateGoalProgress, readingState]);

  // Load surah content with caching and predictive loading
  const loadSurahContent = useCallback(async (surahId, targetVerse = null) => {
    if (!surahId || !quranSettings.selectedTranslation || !isSettingsInitialized) {
      console.log('⏸️ Skipping surah load - missing requirements');
      return;
    }
    
    setLoading(true);
    try {
      // Get the target verse number
      let targetVerseNumber = 1;
      if (targetVerse) {
        targetVerseNumber = parseInt(targetVerse.split(':')[1]);
      } else {
        // Get the last read verse from reading state
        const surahHistory = readingState.history[surahId];
        if (surahHistory) {
          const verseNumbers = Object.keys(surahHistory).map(Number);
          if (verseNumbers.length > 0) {
            targetVerseNumber = Math.max(...verseNumbers);
          }
        }
      }

      // Try to get content from cache first
      const cachedVerses = await QuranCacheManager.getSurahFromCache(
        surahId,
        quranSettings.selectedTranslation
      );

      let verses;
      if (cachedVerses) {
        console.log('📦 Loading surah from cache:', surahId);
        verses = cachedVerses;
      } else {
        // Load from API if not in cache
        console.log('🌐 Fetching surah from API:', surahId);
        const data = await fetchSurah(
          surahId, 
          quranSettings.selectedTranslation, 
          quranSettings.selectedLanguage
        );
        
        if (data.verses?.length > 0) {
          verses = data.verses;
          // Cache the fetched content
          await QuranCacheManager.cacheSurah(
            surahId,
            quranSettings.selectedTranslation,
            verses
          );
        }
      }

      if (verses?.length > 0) {
        setSurahContent(verses);
        setSelectedSurah(surahId);

        // Set current verse to target position
        const verseIndex = verses.findIndex(v => {
          const verseNum = parseInt(v.verse_key.split(':')[1]);
          return verseNum === targetVerseNumber;
        });
        
        if (verseIndex !== -1) {
          setCurrentVerse(verseIndex);
        } else {
          setCurrentVerse(0);
        }

        // Initialize observed verses with already read verses
        const surahHistory = readingState.history[surahId] || {};
        setObservedVerses(new Set(Object.keys(surahHistory)));
        
        // Extract footnotes
        const extractedFootnotes = {};
        verses.forEach(verse => {
          const translation = verse.translations.find(t => t.resource_id.toString() === quranSettings.selectedTranslation);
          if (translation?.footnotes) {
            Object.assign(extractedFootnotes, translation.footnotes);
          }
        });
        setFootnotes(extractedFootnotes);

        // Start predictive loading of next/previous surahs
        PredictiveLoadManager.initiatePredictiveLoad(surahId, quranSettings.selectedTranslation);
      }
    } catch (error) {
      console.error("Error loading surah:", error);
      setSurahContent(null);
      setCurrentVerse(0);
      setFootnotes({});
      setObservedVerses(new Set());
    } finally {
      setLoading(false);
    }
  }, [
    isSettingsInitialized,
    quranSettings.selectedTranslation,
    quranSettings.selectedLanguage,
    readingState.history
  ]);

  // Load initial surahs only once
  useEffect(() => {
    if (!isInitialized) {
      const loadInitialData = async () => {
        try {
          const surahsData = await fetchSurahs();
          setSurahs(surahsData.chapters || []);
          setIsInitialized(true);
        } catch (error) {
          console.error("Error loading surahs:", error);
          setSurahs([]);
        }
      };

      loadInitialData();
    }
  }, [isInitialized]);

  // Handle settings-triggered reloads
  useEffect(() => {
    if (!isInitialized || !isSettingsInitialized || !selectedSurah) return;

    const prevSettings = prevSettingsRef.current;
    const translationChanged = prevSettings?.selectedTranslation !== quranSettings.selectedTranslation;
    const languageChanged = prevSettings?.selectedLanguage !== quranSettings.selectedLanguage;

    if (translationChanged || languageChanged) {
      // Clear cache when translation changes
      QuranCacheManager.removeSurahFromCache(selectedSurah, prevSettings?.selectedTranslation);
      loadSurahContent(selectedSurah);
    }

    prevSettingsRef.current = quranSettings;
  }, [
    isInitialized,
    isSettingsInitialized,
    selectedSurah,
    quranSettings,
    loadSurahContent
  ]);

  // Update reading progress when current verse changes
  useEffect(() => {
    if (currentVerse === prevCurrentVerseRef.current) return;
    if (!selectedSurah || !surahContent || currentVerse < 0 || !isSettingsInitialized || !isReadingMode) return;

    // Ensure readingState is valid before trying to mark verse as read
    if (!readingState || typeof readingState !== 'object') {
      console.warn('Invalid reading state detected in useQuranContent');
      return;
    }

    const currentVerseData = surahContent[currentVerse];
    if (currentVerseData) {
      try {
        // Mark current verse as read
        markVerseAsRead(selectedSurah, currentVerseData.verse_key);
        
        // Also mark any prior verses as read in case they were missed during scrolling
        // This catches verses that might have been skipped
        if (currentVerse > 0) {
          // Mark up to 3 previous verses to ensure continuity
          const versesToCheck = Math.min(3, currentVerse);
          for (let i = 1; i <= versesToCheck; i++) {
            const prevVerseIndex = currentVerse - i;
            if (prevVerseIndex >= 0 && surahContent[prevVerseIndex]) {
              const prevVerseData = surahContent[prevVerseIndex];
              const prevVerseKey = prevVerseData.verse_key;
              const [surahId, verseNum] = prevVerseKey.split(':');
              
              // Only mark if not already read
              if (!readingState.history?.[surahId]?.[verseNum]) {
                markVerseAsRead(selectedSurah, prevVerseKey);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error marking verse as read:', error);
      }
    }

    prevCurrentVerseRef.current = currentVerse;
  }, [
    currentVerse,
    selectedSurah,
    surahContent,
    isSettingsInitialized,
    isReadingMode,
    markVerseAsRead,
    readingState
  ]);

  // Memoize return value
  return useMemo(() => ({
    // Core data
    surahs,
    selectedSurah,
    surahContent,
    currentVerse,
    footnotes,
    
    // Loading states
    loading,
    
    // Reading progress
    readingState,
    observedVerses,
    
    // Actions
    setSelectedSurah,
    setSurahContent,
    setCurrentVerse,
    loadSurahContent,
    
    // State setters
    setLoading,
    setObservedVerses,
    markVerseAsRead,
    
    // Footnotes
    setFootnotes,
    
    // Settings
    showTransliteration: quranSettings.showTransliteration,
    
    // Initialization state
    isInitialized,
  }), [
    surahs,
    selectedSurah,
    surahContent,
    currentVerse,
    loading,
    readingState,
    observedVerses,
    loadSurahContent,
    footnotes,
    isInitialized,
    markVerseAsRead,
    quranSettings.showTransliteration,
  ]);
};

export default useQuranContent; 