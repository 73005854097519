import React from 'react';
import { useDarkMode } from '../../../contexts/DarkModeContext';

export const MenuSection = ({ children }) => {
  const { isDarkMode } = useDarkMode();
  
  return (
    <div className={`rounded-xl overflow-hidden border ${
      isDarkMode 
        ? 'bg-[#111111] border-gray-800' 
        : 'bg-white border-gray-100'
    }`}>
      {children}
    </div>
  );
}; 