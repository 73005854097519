import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const styles = {
  smallText: 'text-lg [@media(min-height:769px)]:text-3xl',
  dateText: 'text-sm [@media(min-height:769px)]:text-base',
  hadithText: 'text-4xl [@media(min-height:769px)]:text-6xl',
  timeText: 'text-7xl [@media(min-height:601px)]:text-9xl [@media(min-height:801px)]:text-[12rem]',
  countdownLabel: 'text-lg [@media(min-height:601px)]:text-xl [@media(min-height:801px)]:text-xl',
  countdownText: 'text-4xl [@media(min-height:601px)]:text-6xl [@media(min-height:801px)]:text-[6rem]',
  prayerText: 'text-2xl [@media(min-height:769px)]:text-3xl'
};

const LightAndBrightTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  // Replace audio logic with hook
  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="h-screen w-full bg-gradient-to-br from-gray-100 via-white to-gray-50">
      {/* Enhanced Ambient Highlights */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute top-0 left-1/4 w-[800px] h-[800px] ${accentColor.bg} rounded-full opacity-30 blur-3xl`}></div>
        <div className="absolute bottom-0 right-1/4 w-[600px] h-[600px] bg-blue-50/50 rounded-full opacity-40 blur-3xl"></div>
        <div className="absolute top-1/4 right-1/4 w-[400px] h-[400px] bg-gray-100/60 rounded-full opacity-50 blur-2xl"></div>
      </div>

      <div className="relative h-full flex">
        {/* Main Content */}
        <div className="flex-1 px-16 pt-16 pb-4 flex flex-col">
          {/* Header Section with enhanced shadows */}
          <div className="flex justify-between items-center bg-white/60 backdrop-blur-sm rounded-2xl p-6 shadow-lg shadow-gray-100/50">
            {/* Location */}
            <div>
              <div className="flex items-center gap-3">
                <MapPin className={`w-6 h-6 ${accentColor.base}`} />
                <h1 className="text-xl font-medium text-gray-800">
                  {settings.location.split(',')[0]}
                </h1>
              </div>
            </div>

            {/* Date Display - Enhanced styling */}
            <SimpleDateCard 
              dateTime={dateTime}
              onDateChange={onDateChange}
              render={({ gregorian, hijri }) => (
                <div className="flex gap-8">
                  <div>
                    <p className={`${accentColor.base} text-xs tracking-widest uppercase mb-1 font-medium`}>Gregorian</p>
                    <p className="text-gray-800 text-sm lg:text-base font-medium">{gregorian}</p>
                  </div>
                  <div>
                    <p className={`${accentColor.base} text-xs tracking-widest uppercase mb-1 font-medium`}>Hijri</p>
                    <p className="text-gray-800 text-sm lg:text-base font-medium">{hijri}</p>
                  </div>
                </div>
              )}
            />
          </div>

          {/* Dua Section - Enhanced with card design */}
          <div className="flex-1 flex items-center justify-center my-8">
            <div className="bg-white/80 backdrop-blur-sm rounded-3xl p-12 shadow-xl shadow-gray-100/50 max-w-4xl">
              <p className={`${styles.hadithText} text-gray-700 text-center leading-relaxed font-uthmani`}>
                {duas?.[currentDuaIndex]?.arabic}
              </p>
            </div>
          </div>

          {/* Time and Countdown Section - Enhanced with glass morphism */}
          <div className="space-y-8 bg-white/60 backdrop-blur-sm rounded-2xl p-8 shadow-lg shadow-gray-100/50">
            {/* Countdown */}
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div>
                  <div className={`inline-flex items-center gap-3 px-6 py-3 ${accentColor.bg} rounded-xl mb-4 shadow-sm`}>
                    <p className={`${styles.countdownLabel} tracking-widest uppercase ${accentColor.dark} font-medium`}>
                      Until {nextPrayer}
                    </p>
                  </div>
                  <div className={`${styles.countdownText} font-light tracking-tight leading-none ${accentColor.dark}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />
            
            {/* Time */}
            <div>
              <p className={`text-sm tracking-widest uppercase ${accentColor.base} font-medium mb-4`}>Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className={`${styles.timeText} font-light tracking-tight leading-none text-gray-800`}>
                    {time}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        {/* Prayer Times Panel - Enhanced with sophisticated styling */}
        <div className="w-72 [@media(min-height:769px)]:w-80 bg-white/80 backdrop-blur-md shadow-[-8px_0_20px_-5px_rgba(0,0,0,0.05)]">
          <div className="h-full flex flex-col divide-y divide-gray-100">
            <SimplePrayerTimesCard
              prayerTimes={prayerTimes}
              settings={settings}
              render={({ prayers }) => (
                <>
                  {prayers.map(([prayer, time]) => {
                    const Icon = prayer === 'Fajr' ? Sunrise :
                               prayer === 'Maghrib' ? Sunset :
                               prayer === 'Isha' ? Moon : Sun;
                    
                    const isNext = prayer === nextPrayer;
                    
                    return (
                      <div 
                        key={prayer}
                        className={`flex-1 flex flex-col justify-center px-8 py-4 [@media(min-height:769px)]:px-12 [@media(min-height:769px)]:py-6 relative
                          transition-all duration-300
                          ${isNext ? `${accentColor.bg} shadow-[inset_0_0_60px_-20px_rgba(0,0,0,0.1)]` : 'hover:bg-white/40'}`}
                      >
                        {isNext && (
                          <div className={`absolute inset-y-0 left-0 w-1 ${accentColor.base} shadow-[0_0_8px_0px_rgba(0,0,0,0.1)]`}></div>
                        )}
                        <div className="flex items-center gap-3 mb-2">
                          <Icon className={`w-5 h-5 ${isNext ? accentColor.dark : 'text-gray-600'}`} />
                          <p className={`${styles.prayerText} tracking-wider uppercase font-medium
                            ${isNext ? accentColor.dark : 'text-gray-600'}`}>
                            {prayer}
                          </p>
                        </div>
                        <p className={`${styles.prayerText} font-medium
                          ${isNext ? accentColor.dark : 'text-gray-800'}`}>
                          {time}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightAndBrightTemplate; 