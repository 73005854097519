import api from './api';
import { getCachedData, setCachedData } from '../utils/apiCache';
import logger from '../utils/logger';

const HADITHS_CACHE_KEY = 'hadiths';

export const fetchHadiths = async () => {
    try {
        // Check cache first
        const cachedData = getCachedData(HADITHS_CACHE_KEY);
        if (cachedData) {
            return cachedData;
        }

        // If not in cache, make the API request
        logger.api.request('GET', '/hadiths');
        const response = await api.get('/hadiths');
        logger.api.response('GET', '/hadiths', response.data);
        
        // Cache the response
        setCachedData(HADITHS_CACHE_KEY, response.data);
        
        return response.data;
    } catch (error) {
        logger.api.error('GET', '/hadiths', error);
        throw error;
    }
};
