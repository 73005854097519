import { useState, useEffect, useMemo, useCallback } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useAuth } from '../../../contexts/AuthContext';

const useQuranMemorization = (selectedSurah, surahContent, surahs) => {
  const [memorizedVerses, setMemorizedVerses] = useState({});
  const [allMemorizedVerses, setAllMemorizedVerses] = useState({});
  const { currentUser } = useAuth();

  // Load memorized verses for current surah
  useEffect(() => {
    const loadMemorized = async () => {
      if (!currentUser || !selectedSurah) {
        setMemorizedVerses({});
        return;
      }

      try {
        const userMemorizedRef = doc(db, 'userMemorized', currentUser.uid);
        const userMemorizedSnap = await getDoc(userMemorizedRef);
        if (userMemorizedSnap.exists()) {
          const userData = userMemorizedSnap.data();
          setMemorizedVerses(userData[selectedSurah] || {});
        } else {
          setMemorizedVerses({});
        }
      } catch (error) {
        console.error("Error loading memorized status from Firestore:", error);
        setMemorizedVerses({});
      }
    };

    loadMemorized();
  }, [currentUser, selectedSurah]);

  // Load all memorized verses
  useEffect(() => {
    const loadAllMemorized = async () => {
      if (!currentUser) {
        setAllMemorizedVerses({});
        return;
      }

      try {
        const userMemorizedRef = doc(db, 'userMemorized', currentUser.uid);
        const userMemorizedSnap = await getDoc(userMemorizedRef);
        if (userMemorizedSnap.exists()) {
          setAllMemorizedVerses(userMemorizedSnap.data());
        } else {
          setAllMemorizedVerses({});
        }
      } catch (error) {
        console.error("Error loading all memorized verses:", error);
        setAllMemorizedVerses({});
      }
    };

    loadAllMemorized();
  }, [currentUser]);

  // Handle memorized toggle with optimistic update
  const handleMemorizedToggle = useCallback(async (verseKey, isMemorized) => {
    if (!currentUser || !selectedSurah || !surahContent) return;

    const newMemorizedVerses = { 
      ...memorizedVerses, 
      [verseKey]: isMemorized 
    };

    // Optimistic update
    setMemorizedVerses(newMemorizedVerses);

    try {
      const userMemorizedRef = doc(db, 'userMemorized', currentUser.uid);
      await setDoc(userMemorizedRef, { 
        [selectedSurah]: newMemorizedVerses 
      }, { merge: true });

      // Update allMemorizedVerses
      setAllMemorizedVerses(prev => ({
        ...prev,
        [selectedSurah]: newMemorizedVerses
      }));
    } catch (error) {
      console.error("Error saving memorized status:", error);
      // Revert on error
      setMemorizedVerses(memorizedVerses);
    }
  }, [currentUser, selectedSurah, surahContent, memorizedVerses]);

  // Memoized calculations
  const memorizedCount = useMemo(() => {
    if (!selectedSurah) return 0;
    return Object.entries(memorizedVerses)
      .filter(([key, value]) => key.startsWith(`${selectedSurah}:`) && value)
      .length;
  }, [memorizedVerses, selectedSurah]);

  const totalVerses = useMemo(() => {
    return surahContent ? surahContent.length : 0;
  }, [surahContent]);

  const memorizedPercentage = useMemo(() => {
    return totalVerses > 0 ? (memorizedCount / totalVerses) * 100 : 0;
  }, [memorizedCount, totalVerses]);

  // Memoized getMemorizedCount function
  const getMemorizedCount = useCallback((surahId) => {
    if (!surahId || !surahs) return '0/0';

    const surahVerses = allMemorizedVerses[surahId] || {};
    const totalVerses = surahs.find(s => s.id === parseInt(surahId))?.verses_count || 0;
    const memorizedCount = Object.entries(surahVerses)
      .filter(([key, value]) => key.startsWith(`${surahId}:`) && value)
      .length;
    
    const validMemorizedCount = Math.min(memorizedCount, totalVerses);
    return `${validMemorizedCount}/${totalVerses}`;
  }, [allMemorizedVerses, surahs]);

  return {
    memorizedVerses: memorizedVerses || {},
    memorizedCount,
    totalVerses,
    memorizedPercentage,
    getMemorizedCount,
    handleMemorizedToggle,
  };
};

export default useQuranMemorization;
