import React from 'react';
import { Target, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useDarkMode } from '../../contexts/DarkModeContext';
import LoadingState from '../LoadingState';
import { format, isFuture, isToday, isPast, subDays } from 'date-fns';
import { BookOpen, Book, HelpCircle, CheckCircle, XCircle, AlertCircle } from 'lucide-react';

// Match the goalTypes from GoalPlanner.js
const goalTypes = {
  performDhikr: { 
    name: 'Perform Dhikr', 
    icon: BookOpen, 
    color: 'emerald',
    link: '/dhikr-helper',
    getDetails: (goal) => {
      const details = {
        'fullTasbih': 'Full tasbih',
        'subhanAllah33': 'Subhan Allah 33x',
        'alhamdulillah33': 'Alhamdulillah 33x',
        'allahuAkbar33': 'Allahu Akbar 33x'
      };
      return details[goal.goalDetails] || goal.goalDetails;
    }
  },
  readQuran: { 
    name: 'Read Quran', 
    icon: Book, 
    color: 'blue',
    link: '/quran',
    getDetails: (goal) => {
      const details = {
        'read1Juz': 'Read 1 Juz',
        'read5Verses': 'Read 5 verses',
        'read10Verses': 'Read 10 verses',
        'read15Verses': 'Read 15 verses',
        'read20Verses': 'Read 20 verses'
      };
      return details[goal.goalDetails] || goal.goalDetails;
    }
  },
  memorizeQuran: { 
    name: 'Memorize Quran', 
    icon: Book, 
    color: 'purple',
    link: '/quran',
    getDetails: (goal) => `Memorize ${goal.goalDetails} verses`
  },
  learn99Names: { 
    name: 'Learn 99 Names', 
    icon: HelpCircle, 
    color: 'red',
    link: '/names-of-allah',
    getDetails: (goal) => {
      const details = {
        'practice10': 'Practice 10 names',
        'practice20': 'Practice 20 names',
        'practice30': 'Practice 30 names',
        // ... add other practice options as needed
        'practice99': 'Practice all 99 names'
      };
      return details[goal.goalDetails] || goal.goalDetails;
    }
  }
};

const GoalsCard = ({ goals, hideTitle }) => {
  const { isDarkMode } = useDarkMode();
  
  // Show empty state if goals is null/undefined or empty array
  if (!goals || goals.length === 0) {
    return (
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <Target className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
            <span className={`text-lg font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
              Upcoming Goals
            </span>
          </div>
          <Link 
            to="/goal-planner" 
            className={`text-sm px-3 py-1.5 rounded-full transition-colors flex items-center gap-1 ${
              isDarkMode 
                ? 'text-emerald-400 hover:text-emerald-300' 
                : 'text-emerald-600 hover:text-emerald-700'
            }`}
          >
            View All
            <ChevronRight className="w-4 h-4" />
          </Link>
        </div>

        <div className="flex-1 flex flex-col items-center justify-between">
          <div className="pt-12">
            <img 
              src="/images/empty_states/goal_target.svg" 
              alt="No goals set" 
              className={`w-64 h-64 ${isDarkMode ? 'opacity-50' : 'opacity-75'}`}
            />
          </div>
          <div className="flex flex-col items-center gap-4 mb-6">
            <p className={isDarkMode ? "text-center text-gray-400" : "text-center text-gray-600"}>
              No upcoming goals
            </p>
            <Link 
              to="/goal-planner"
              className={`px-4 py-2 rounded-lg transition-colors ${
                isDarkMode
                  ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
                  : 'bg-emerald-600 text-white hover:bg-emerald-700'
              }`}
            >
              Set Goals
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // Rest of the component for when there are goals
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-4">
        <div className={`flex items-center ${hideTitle ? 'hidden' : 'gap-2 mb-4'}`}>
          <Target className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
          <h2 className={`text-lg font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
            Goals
          </h2>
        </div>
        <Link 
          to="/goal-planner" 
          className={`text-sm px-3 py-1.5 rounded-full transition-colors flex items-center gap-1 ${
            isDarkMode 
              ? 'text-emerald-400 hover:text-emerald-300' 
              : 'text-emerald-600 hover:text-emerald-700'
          }`}
        >
          View All
          <ChevronRight className="w-4 h-4" />
        </Link>
      </div>

      <div className="flex-1 flex flex-col items-center justify-between">
        <div className="pt-12">
          <img 
            src="/images/empty_states/goal_target.svg" 
            alt="No goals set" 
            className={`w-64 h-64 ${isDarkMode ? 'opacity-50' : 'opacity-75'}`}
          />
        </div>
        <div className="flex flex-col items-center gap-4 mb-6">
          <p className={isDarkMode ? "text-center text-gray-400" : "text-center text-gray-600"}>
            No upcoming goals
          </p>
          <Link 
            to="/goal-planner"
            className={`px-4 py-2 rounded-lg transition-colors ${
              isDarkMode
                ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
                : 'bg-emerald-600 text-white hover:bg-emerald-700'
            }`}
          >
            Set Goals
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GoalsCard;
