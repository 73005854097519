export const QuranCacheManager = {
  CACHE_KEY: 'quran_surah_cache',
  CACHE_VERSION: '1.0',
  MAX_AGE_DAYS: 30, // Cache TTL

  async getSurahFromCache(surahId, translationId) {
    try {
      const cache = this.getCache();
      const surahCache = cache?.[surahId]?.[translationId];

      if (!surahCache) return null;

      // Check if cache is still valid
      const ageInDays = (Date.now() - surahCache.timestamp) / (1000 * 60 * 60 * 24);
      if (ageInDays > this.MAX_AGE_DAYS) {
        this.removeSurahFromCache(surahId, translationId);
        return null;
      }

      if (surahCache.version !== this.CACHE_VERSION) {
        this.removeSurahFromCache(surahId, translationId);
        return null;
      }

      return surahCache.verses;
    } catch (error) {
      console.error('Error reading from cache:', error);
      return null;
    }
  },

  async cacheSurah(surahId, translationId, verses) {
    try {
      const cache = this.getCache() || {};
      if (!cache[surahId]) cache[surahId] = {};

      cache[surahId][translationId] = {
        verses,
        timestamp: Date.now(),
        version: this.CACHE_VERSION,
        translationId
      };

      localStorage.setItem(this.CACHE_KEY, JSON.stringify(cache));
    } catch (error) {
      console.error('Error writing to cache:', error);
      // If error is QuotaExceededError, clear old entries
      if (error.name === 'QuotaExceededError') {
        this.clearOldestEntries();
      }
    }
  },

  getCache() {
    try {
      return JSON.parse(localStorage.getItem(this.CACHE_KEY) || '{}');
    } catch {
      return {};
    }
  },

  removeSurahFromCache(surahId, translationId) {
    try {
      const cache = this.getCache();
      if (cache[surahId]?.[translationId]) {
        delete cache[surahId][translationId];
        if (Object.keys(cache[surahId]).length === 0) {
          delete cache[surahId];
        }
        localStorage.setItem(this.CACHE_KEY, JSON.stringify(cache));
      }
    } catch (error) {
      console.error('Error removing from cache:', error);
    }
  },

  clearOldestEntries() {
    const cache = this.getCache();
    const entries = [];

    // Flatten cache entries for sorting
    Object.entries(cache).forEach(([surahId, translations]) => {
      Object.entries(translations).forEach(([translationId, data]) => {
        entries.push({
          surahId,
          translationId,
          timestamp: data.timestamp
        });
      });
    });

    // Sort by timestamp and remove oldest 20%
    entries.sort((a, b) => a.timestamp - b.timestamp);
    const toRemove = Math.ceil(entries.length * 0.2);
    
    entries.slice(0, toRemove).forEach(entry => {
      this.removeSurahFromCache(entry.surahId, entry.translationId);
    });
  }
}; 