import React, { useEffect } from 'react';

const CustomSplash = ({ onReady }) => {
  useEffect(() => {
    // Check if running as PWA
    const isStandalone = window.navigator.standalone || 
      window.matchMedia('(display-mode: standalone)').matches;

    const splash = document.getElementById('static-splash');
    
    if (isStandalone && splash) {
      // Show splash screen in PWA mode
      splash.classList.add('show-splash');

      // Wait for app to be ready
      const minDisplayTime = 1000; // Minimum time to show splash screen
      const startTime = Date.now();

      const handleReady = () => {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, minDisplayTime - elapsedTime);

        // Ensure minimum display time and smooth transition
        setTimeout(() => {
          splash.classList.remove('show-splash');
          // Remove the element after transition
          setTimeout(() => {
            splash.remove();
          }, 500);
          if (onReady) onReady();
        }, remainingTime);
      };

      // Wait for everything to be loaded
      if (document.readyState === 'complete') {
        handleReady();
      } else {
        window.addEventListener('load', handleReady);
        return () => window.removeEventListener('load', handleReady);
      }
    } else if (splash) {
      // Not in PWA mode, remove splash screen immediately
      splash.remove();
    }
  }, [onReady]);

  return null;
};

export default CustomSplash; 