export const getCountdown = (prayerTimes, now = new Date(), tunedTimes = {}) => {
  if (!prayerTimes || typeof prayerTimes !== 'object' || Object.keys(prayerTimes).length === 0) {
    console.warn('Invalid prayer times provided to getCountdown:', prayerTimes);
    return { nextPrayer: "Unknown", countdown: "--:--:--" };
  }

  const prayers = Object.entries(prayerTimes);
  const nowTime = now.getTime();

  // Convert prayer times to Date objects
  const prayerDateTimes = prayers
    .filter(([_, time]) => typeof time === 'string' && time.includes(':')) // Filter out invalid time formats
    .map(([name, time]) => {
      try {
        const [hours, minutes] = time.split(':');
        if (isNaN(parseInt(hours, 10)) || isNaN(parseInt(minutes, 10))) {
          console.warn(`Invalid time format for ${name}: ${time}`);
          return null;
        }

        // Get adjustment value from tunedTimes, defaulting to 0 if not set
        const adjustment = tunedTimes[name] || 0;
        
        // Calculate total minutes and handle day rollover
        let totalMinutes = (parseInt(hours, 10) * 60 + parseInt(minutes, 10) + adjustment) % (24 * 60);
        if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle negative adjustments
        
        // Convert back to hours and minutes
        const adjustedHours = Math.floor(totalMinutes / 60);
        const adjustedMinutes = totalMinutes % 60;
        
        const prayerDate = new Date(now);
        prayerDate.setHours(adjustedHours, adjustedMinutes, 0, 0);
        
        // If this prayer time has already passed today, set it to tomorrow
        if (prayerDate.getTime() < nowTime) {
          prayerDate.setDate(prayerDate.getDate() + 1);
        }
        
        return { name, time: prayerDate.getTime() };
      } catch (error) {
        console.error(`Error processing prayer time for ${name}:`, error);
        return null;
      }
    })
    .filter(item => item !== null); // Remove any null entries from failed processing

  if (prayerDateTimes.length === 0) {
    console.warn('No valid prayer times could be processed for countdown');
    return { nextPrayer: "Unknown", countdown: "--:--:--" };
  }

  // Sort prayer times by time (closest first)
  prayerDateTimes.sort((a, b) => a.time - b.time);

  // Find the next prayer (first one after sorting)
  const nextPrayer = prayerDateTimes[0];

  // Calculate time difference
  const diff = nextPrayer.time - nowTime;

  // Convert milliseconds to hours, minutes, seconds
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return {
    nextPrayer: nextPrayer.name,
    countdown: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  };
};

export const isExactPrayerTime = (prayerTimes, now = new Date(), tunedTimes = {}) => {
  if (!prayerTimes || typeof prayerTimes !== 'object' || Object.keys(prayerTimes).length === 0) {
    return { isPrayerTime: false, currentPrayer: null };
  }

  const prayers = Object.entries(prayerTimes);
  const nowTime = now.getTime();

  for (const [prayer, time] of prayers) {
    // Skip invalid time formats
    if (typeof time !== 'string' || !time.includes(':')) {
      console.warn(`Invalid time format for ${prayer}: ${time}`);
      continue;
    }
    
    try {
      // Get adjustment value from tunedTimes, defaulting to 0 if not set
      const adjustment = tunedTimes[prayer] || 0;
      
      const [hours, minutes] = time.split(':');
      if (isNaN(parseInt(hours, 10)) || isNaN(parseInt(minutes, 10))) {
        console.warn(`Invalid time parts for ${prayer}: ${time}`);
        continue;
      }
      
      // Calculate total minutes and handle day rollover
      let totalMinutes = (parseInt(hours, 10) * 60 + parseInt(minutes, 10) + adjustment) % (24 * 60);
      if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle negative adjustments
      
      // Convert back to hours and minutes
      const adjustedHours = Math.floor(totalMinutes / 60);
      const adjustedMinutes = totalMinutes % 60;
      
      const prayerDate = new Date(now);
      prayerDate.setHours(adjustedHours, adjustedMinutes, 0, 0);

      // Check if current time is exactly at prayer time (within 1 second)
      if (Math.abs(nowTime - prayerDate.getTime()) <= 1000) {
        return { isPrayerTime: true, currentPrayer: prayer };
      }
    } catch (error) {
      console.error(`Error checking prayer time for ${prayer}:`, error);
      continue;
    }
  }

  return { isPrayerTime: false, currentPrayer: null };
};

export const adjustPrayerTimes = (prayerTimes, tunedTimes) => {
  if (!prayerTimes) {
    console.warn('No prayer times provided to adjustPrayerTimes');
    return {};
  }
  if (!tunedTimes) return prayerTimes;

  return Object.entries(prayerTimes).reduce((acc, [prayer, time]) => {
    try {
      // Skip if time is not a properly formatted string
      if (typeof time !== 'string' || !time.includes(':')) {
        console.warn(`Invalid time format for ${prayer}: ${time}`);
        acc[prayer] = time; // Keep original value
        return acc;
      }

      // Get adjustment value from tunedTimes, defaulting to 0 if not set
      const adjustment = tunedTimes[prayer] || 0;
      
      if (adjustment === 0) {
        acc[prayer] = time;
        return acc;
      }

      // Parse the time
      const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
      
      // Check if parsing was successful
      if (isNaN(hours) || isNaN(minutes)) {
        console.warn(`Failed to parse time parts for ${prayer}: ${time}`);
        acc[prayer] = time; // Keep original value
        return acc;
      }
      
      // Calculate total minutes and handle day rollover
      let totalMinutes = (hours * 60 + minutes + adjustment) % (24 * 60);
      if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle negative adjustments
      
      // Convert back to hours and minutes
      const adjustedHours = Math.floor(totalMinutes / 60);
      const adjustedMinutes = totalMinutes % 60;
      
      // Format with padding
      const formattedHours = adjustedHours.toString().padStart(2, '0');
      const formattedMinutes = adjustedMinutes.toString().padStart(2, '0');
      
      acc[prayer] = `${formattedHours}:${formattedMinutes}`;
    } catch (error) {
      console.error(`Error adjusting time for ${prayer}:`, error);
      acc[prayer] = time; // Keep original value on error
    }
    return acc;
  }, {});
};

/**
 * Checks if all prayers for the day have passed
 * @param {Object} prayerTimes - Object containing prayer times
 * @param {Date} now - Current date/time
 * @param {Object} tunedTimes - Object containing prayer time adjustments
 * @returns {boolean} - True if all prayers have passed
 */
export const haveAllPrayersPassed = (prayerTimes, now = new Date(), tunedTimes = {}) => {
  if (!prayerTimes || Object.keys(prayerTimes).length === 0) {
    return false;
  }

  // Get the last prayer of the day (Isha)
  const ishaTime = prayerTimes.Isha;
  if (!ishaTime) return false;

  // Apply adjustment if any
  const adjustment = tunedTimes?.Isha || 0;
  
  // Parse the time
  const [hours, minutes] = ishaTime.split(':').map(num => parseInt(num, 10));
  
  // Calculate total minutes and handle day rollover
  let totalMinutes = (hours * 60 + minutes + adjustment) % (24 * 60);
  if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle negative adjustments
  
  // Convert back to hours and minutes
  const adjustedHours = Math.floor(totalMinutes / 60);
  const adjustedMinutes = totalMinutes % 60;
  
  // Create a date object for the adjusted Isha time
  const ishaDate = new Date(now);
  ishaDate.setHours(adjustedHours, adjustedMinutes, 0, 0);
  
  // Add a buffer of 10 minutes to ensure Isha has fully passed
  ishaDate.setMinutes(ishaDate.getMinutes() + 10);
  
  // Check if current time is after Isha
  return now.getTime() > ishaDate.getTime();
};

// Make the function available globally for use in other modules
if (typeof window !== 'undefined') {
  window.haveAllPrayersPassed = haveAllPrayersPassed;
}

// You can also add any other utility functions here
