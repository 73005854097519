import React, { useEffect, useState } from 'react';
import { Flowbite } from 'flowbite-react';
import customTheme from './theme';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { BridgeProvider } from './contexts/BridgeContext';
import { permissionManager } from './services/PermissionManager';
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import Quran from './components/Quran';
import DeenHelpers from './components/dashboard/DeenHelpers';
import GoalPlannerPage from './pages/GoalPlannerPage';
import SimpleDashboard from './components/SimpleDashboard';
import Login from './components/Login';
import SignUp from './components/SignUp';
import SettingsPage from './components/SettingsPage';
import Layout from './components/Layout';
import './styles/smartMirror.css';
import DhikrHelper from './components/DhikrHelper';
import NamesOfAllah from './components/NamesOfAllah';
import GoalPlanner from './components/GoalPlanner';
import TVSettingsPage from './components/settings/TVSettingsPage';
import { DarkModeProvider } from './contexts/DarkModeContext';
import QiblaFinder from './components/QiblaFinder';
import PublicLayout from './components/PublicLayout';
import AboutPage from './pages/AboutPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ContactPage from './pages/ContactPage';
import { cleanImageCache } from './utils/imageCache';
import CustomSplash from './components/CustomSplash';
import MobileGoalPlanner from './components/MobileGoalPlanner';
import AnalyticsPage from './components/analytics/AnalyticsPage';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AppTermsOfService from './components/app/AppTermsOfService';
import AppPrivacyPolicy from './components/app/AppPrivacyPolicy';
import FeedbackForm from './components/FeedbackForm';

const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }
  
  return currentUser ? children : <Navigate to="/landing" replace />;
};

const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return !currentUser ? children : <Navigate to="/dashboard" replace />;
};

// Create a root component that handles the conditional rendering
const RootComponent = () => {
  const { currentUser } = useAuth();
  
  if (currentUser) {
    return <Dashboard />;
  }
  return <LandingPage />;
};

// Create a root layout component that handles the conditional rendering of layouts
const RootLayout = () => {
  const { currentUser } = useAuth();
  
  if (currentUser) {
    return <Layout />;
  }
  return <PublicLayout />;
};

function App() {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    // Initialize permission manager
    permissionManager.init();

    // Clean old cached images once per week
    cleanImageCache();
    const cleanupInterval = setInterval(cleanImageCache, 7 * 24 * 60 * 60 * 1000);
    
    return () => {
      clearInterval(cleanupInterval);
      permissionManager.cleanup();
    };
  }, []);

  return (
    <DarkModeProvider>
      <CustomSplash onReady={() => setIsAppReady(true)} />
      <Flowbite theme={{ theme: customTheme }}>
        <Router>
          <AuthProvider>
            <SettingsProvider>
              <BridgeProvider>
                <Routes>
                  {/* SimpleDashboard route - outside RootLayout but inside SettingsProvider */}
                  <Route path="/simpledashboard" element={<SimpleDashboard />} />

                  <Route element={<RootLayout />}>
                    <Route path="/" element={<RootComponent />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/quran" element={<PrivateRoute><Quran /></PrivateRoute>} />
                    <Route path="/deen-helpers" element={<PrivateRoute><DeenHelpers /></PrivateRoute>} />
                    <Route path="/goal-planner" element={<PrivateRoute><GoalPlanner /></PrivateRoute>} />
                    <Route path="/mobile-goals" element={<PrivateRoute><MobileGoalPlanner /></PrivateRoute>} />
                    <Route path="/analytics" element={<PrivateRoute><AnalyticsPage /></PrivateRoute>} />
                    <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
                    <Route path="/dhikr-helper" element={<PrivateRoute><DhikrHelper /></PrivateRoute>} />
                    <Route path="/names-of-allah" element={<PrivateRoute><NamesOfAllah /></PrivateRoute>} />
                    <Route path="/settings/tv" element={<PrivateRoute><TVSettingsPage /></PrivateRoute>} />
                    <Route path="/qibla-finder" element={<PrivateRoute><QiblaFinder /></PrivateRoute>} />
                    <Route path="/feedback" element={<PrivateRoute><FeedbackForm /></PrivateRoute>} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/app/terms" element={<AppTermsOfService />} />
                    <Route path="/app/privacy" element={<AppPrivacyPolicy />} />
                  </Route>

                  {/* Catch all route */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </BridgeProvider>
            </SettingsProvider>
          </AuthProvider>
        </Router>
      </Flowbite>
    </DarkModeProvider>
  );
}

export default App;
