import { useState, useEffect, useMemo } from 'react';
import { fetchReciters, fetchTranslations, fetchLanguages } from '../../../services/quranService';
import { useSettings } from '../../../contexts/SettingsContext';

const useQuranSettings = () => {
  const { settings, updateSettings } = useSettings();
  const [loading, setLoading] = useState(true);
  
  // Options data
  const [reciters, setReciters] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [languages, setLanguages] = useState([]);

  // Use values from settings.quran instead of root settings
  const selectedReciter = settings.quran?.selectedReciter;
  const selectedLanguage = settings.quran?.selectedLanguage;
  const selectedTranslation = settings.quran?.selectedTranslation;

  // Load options
  useEffect(() => {
    const loadOptions = async () => {
      try {
        setLoading(true);
        const [recitersData, translationsData, languagesData] = await Promise.all([
          fetchReciters(),
          fetchTranslations(),
          fetchLanguages()
        ]);

        setReciters(recitersData.recitations || []);
        setTranslations(translationsData.translations || []);
        setLanguages(languagesData.languages || []);

        // Set initial values if not set
        if (!settings.quran?.selectedReciter && recitersData.recitations?.length) {
          handleReciterChange(settings.lastSelectedReciter || recitersData.recitations[0].id.toString());
        }

        if (!settings.quran?.selectedLanguage && languagesData.languages?.length) {
          handleLanguageChange(settings.lastSelectedLanguage || 'en');
        }
      } catch (error) {
        console.error('Error loading options:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOptions();
  }, []);

  // Computed options
  const reciterOptions = useMemo(() => 
    reciters.map(reciter => ({
      value: reciter.id.toString(),
      label: reciter.reciter_name
    })), [reciters]
  );

  const languageOptions = useMemo(() => 
    languages.map(language => ({
      value: language.iso_code,
      label: language.native_name || language.name || language.iso_code
    })), [languages]
  );

  const translationOptions = useMemo(() => 
    translations
      .filter(translation => {
        const selectedLangName = languages.find(lang => lang.iso_code === selectedLanguage)?.name;
        return translation.language_name?.toLowerCase() === selectedLangName?.toLowerCase();
      })
      .map(translation => ({
        value: translation.id.toString(),
        label: translation.name || `Translation ${translation.id}`
      })), [translations, languages, selectedLanguage]
  );

  // Update handlers
  const updateQuranSettings = (updates) => {
    console.log('useQuranSettings - updateQuranSettings:', updates);
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        ...updates
      }
    });
  };

  const handleReciterChange = (reciterId) => {
    updateQuranSettings({ selectedReciter: reciterId });
  };

  const handleLanguageChange = (languageCode) => {
    const langName = languages.find(l => l.iso_code === languageCode)?.name;
    const defaultTranslation = translations.find(
      t => t.language_name.toLowerCase() === langName?.toLowerCase()
    );

    updateQuranSettings({
      selectedLanguage: languageCode,
      selectedTranslation: defaultTranslation?.id.toString()
    });
  };

  const handleTranslationChange = (translationId) => {
    updateQuranSettings({ selectedTranslation: translationId });
  };

  return {
    loading,
    // Options
    reciterOptions,
    languageOptions,
    translationOptions,
    // Selected values
    selectedReciter,
    selectedLanguage,
    selectedTranslation,
    // Handlers
    handleReciterChange,
    handleLanguageChange,
    handleTranslationChange,
    // Settings
    settings,
    updateQuranSettings
  };
};

export default useQuranSettings; 