import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Target, Check, Calendar, ChevronLeft } from 'lucide-react';
import { useGoals } from '../../hooks/useGoals';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { PRAYER_NAMES } from '../../types/goals';
import { format, addDays, isToday, isTomorrow, isAfter, parse, isYesterday as isDateYesterday } from 'date-fns';
import { useMultiGoalProgress } from '../../hooks/useGoalProgress';
import { quranHelpers, SURAHS } from '../../constants/quranConstants';
import { AnimatePresence, motion } from 'framer-motion';
import { useSettings } from '../../contexts/SettingsContext';

const TaskStatusBadge = ({ status, isOverdue, isMissed }) => {
  const getStatusStyles = () => {
    if (isMissed) return 'bg-red-500/10 text-red-500';
    if (isOverdue) return 'bg-amber-500/10 text-amber-500';
    if (status === 'next') return 'bg-emerald-500/10 text-emerald-400';
    switch (status) {
      case 'completed':
        return 'bg-emerald-500/10 text-emerald-500';
      case 'late':
        return 'bg-red-500/10 text-red-500';
      case 'pending':
        return 'bg-yellow-500/10 text-yellow-500';
      default:
        return 'bg-gray-500/10 text-gray-500';
    }
  };

  const getStatusText = () => {
    if (isMissed) return 'Missed';
    if (isOverdue) return 'Overdue';
    if (status === 'next') return 'Next';
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  return (
    <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${getStatusStyles()}`}>
      {getStatusText()}
    </span>
  );
};

export default function MobileGoalsCard() {
  const { goals, loading, getTodayProgress, updateGoalProgress } = useGoals();
  const { isDarkMode } = useDarkMode();
  const [actions, setActions] = useState([]);
  const [error, setError] = useState(null);
  const { settings, updateSettings } = useSettings();
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(actions.length / itemsPerPage);
  
  // Calculate dates for the next 7 days
  const dates = useMemo(() => {
    const today = new Date();
    return Array.from({ length: 14 }, (_, i) => addDays(today, i));
  }, []);

  // Get today's date string
  const todayStr = format(new Date(), 'yyyy-MM-dd');

  // Get progress for all goals and dates
  const allProgress = useMultiGoalProgress(goals, dates);

  // Format a date for display
  const formatActionDate = (date) => {
    if (isToday(date)) return 'Today';
    if (isTomorrow(date)) return 'Tomorrow';
    if (isDateYesterday(date)) return 'Yesterday';
    return format(date, 'EEE, MMM d'); // e.g. "Mon, Jan 15"
  };

  useEffect(() => {
    if (!goals) return;

    const generateActionsForDate = (date) => {
      const actions = [];
      const dateStr = format(date, 'yyyy-MM-dd');
      const dayProgress = allProgress.get(dateStr);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayStr = format(yesterday, 'yyyy-MM-dd');

      goals.forEach(goal => {
        const progress = goal.progress?.[dateStr];
        const goalProgress = dayProgress?.get(goal.id);

        if (goal.recurrenceType === 'prayer_based') {
          // Track if we've found the next prayer across all prayers
          let foundNextPrayer = false;
          
          // Add an action for each prayer
          PRAYER_NAMES.forEach(prayer => {
            const prayerProgress = progress?.prayers?.[prayer] || {};
            const yesterdayPrayerProgress = goal.progress?.[yesterdayStr]?.prayers?.[prayer] || {};
            const now = new Date();
            const prayerTime = goal.details?.prayerTimes?.[prayer];
            
            // Parse prayer time if available
            let isPrayerTimePassed = false;
            if (prayerTime) {
              const [hours, minutes] = prayerTime.split(':').map(Number);
              const prayerDateTime = new Date(now);
              prayerDateTime.setHours(hours, minutes, 0);
              isPrayerTimePassed = isAfter(now, prayerDateTime);
            }

            // Only check yesterday for missed prayers
            const isMissed = dateStr === yesterdayStr && !yesterdayPrayerProgress.completed;
            
            // Determine if the prayer is overdue (today, time passed, not completed)
            const isOverdue = dateStr === format(today, 'yyyy-MM-dd') && 
                            isPrayerTimePassed && 
                            !prayerProgress.completed;

            // Find the next prayer (first uncompleted prayer that hasn't passed its time)
            const isNext = dateStr === format(today, 'yyyy-MM-dd') && 
                          !isPrayerTimePassed && 
                          !prayerProgress.completed && 
                          !foundNextPrayer;

            // If this prayer is next, mark that we've found the next prayer
            if (isNext) {
              foundNextPrayer = true;
            }
            
            // Only add the action if:
            // 1. It's today's prayer (overdue or not)
            // 2. It's yesterday's missed prayer
            // 3. It's a future prayer
            const isToday = dateStr === format(today, 'yyyy-MM-dd');
            const isYesterday = dateStr === yesterdayStr;
            const isFuture = isAfter(date, today);
            
            if (isToday || (isYesterday && isMissed) || isFuture) {
              actions.push({
                id: `${goal.id}-${prayer}-${dateStr}`,
                title: `Complete full tasbih after ${prayer}`,
                type: 'dhikr',
                prayer,
                date,
                isCompleted: prayerProgress.completed || false,
                goalId: goal.id,
                isOverdue,
                isMissed,
                isNext,
                isActive: isNext || isOverdue || isMissed
              });
            }
          });
        } else if (goal.recurrenceType === 'time_based') {
          // For time-based dhikr (morning/evening adhkar)
          const now = new Date();
          const timeWindow = goal.details.window;
          const windowTime = timeWindow === 'morning' ? '06:00' : '18:00'; // Example times
          const [hours, minutes] = windowTime.split(':').map(Number);
          const windowDateTime = new Date(now);
          windowDateTime.setHours(hours, minutes, 0);
          
          const isPassed = isAfter(now, windowDateTime);
          const isOverdue = dateStr === format(today, 'yyyy-MM-dd') && isPassed && !progress?.completed;
          
          actions.push({
            id: `${goal.id}-${dateStr}`,
            title: timeWindow === 'morning' ? 'Complete morning adhkar' : 'Complete evening adhkar',
            type: 'dhikr',
            window: timeWindow,
            date,
            isCompleted: progress?.completed || false,
            goalId: goal.id,
            isOverdue,
            isNext: dateStr === format(today, 'yyyy-MM-dd') && !isPassed && !progress?.completed,
            isActive: isOverdue || (dateStr === format(today, 'yyyy-MM-dd') && !isPassed)
          });
        } else if (goal.type === 'quran_reading' && goalProgress) {
          // For Quran reading goals
          const isLate = date < today && !goalProgress.isCompleted;
          console.log('Processing quran_reading goal for UI:', {
            goalId: goal.id, 
            dateStr, 
            goalProgress,
            progress: goal.progress?.[dateStr]
          });
          
          // Make sure we capture the complete state correctly, checking both progress structures
          const dayProgress = goal.progress?.[dateStr];
          const isCompleted = 
            // Check new structure (details.completed)
            dayProgress?.details?.completed === true || 
            // Check old structure (completed property)
            dayProgress?.completed === true ||
            // Check progress from hook
            goalProgress.isCompleted === true;
            
          actions.push({
            id: `${goal.id}-${dateStr}`,
            title: `Read ${goalProgress.displayRange}`,
            type: 'quran_reading',
            hizbRange: goalProgress.hizbRange,
            date,
            isCompleted: isCompleted,
            goalId: goal.id,
            progress: goalProgress,
            isOverdue: isLate,
            isNext: dateStr === format(today, 'yyyy-MM-dd') && !isCompleted,
            isActive: dateStr === format(today, 'yyyy-MM-dd') || isLate
          });
        } else if (goal.type === 'memorization' && goalProgress) {
          // For memorization goals
          const isLate = date < today && !goalProgress.isCompleted;
          actions.push({
            id: `${goal.id}-${dateStr}`,
            title: `Memorize ${goalProgress.dailyTarget}`,
            type: 'memorization',
            verses: goal.details.dailyVerses,
            date,
            progress: goalProgress.currentAmount,
            isCompleted: goalProgress.isCompleted,
            goalId: goal.id,
            isOverdue: isLate,
            isNext: dateStr === format(today, 'yyyy-MM-dd') && !goalProgress.isCompleted,
            isActive: dateStr === format(today, 'yyyy-MM-dd') || isLate
          });
        }
      });

      return actions;
    };

    // Get actions for all dates (removing the 5 action limit)
    let allActions = [];
    const daysToCheck = 14; // Show two weeks of actions

    for (let i = 0; i < daysToCheck; i++) {
      const nextDate = addDays(new Date(), i);
      const dateActions = generateActionsForDate(nextDate);
      allActions = [...allActions, ...dateActions];
    }
    
    // Sort actions
    allActions = allActions
      .sort((a, b) => {
        // Sort by date first
        const dateCompare = a.date.getTime() - b.date.getTime();
        if (dateCompare !== 0) return dateCompare;
        
        // Then sort prayers by their order
        if (a.type === 'dhikr' && a.prayer && b.type === 'dhikr' && b.prayer) {
          return PRAYER_NAMES.indexOf(a.prayer) - PRAYER_NAMES.indexOf(b.prayer);
        }
        return 0;
      });

    setActions(allActions);

    // Find the page with the next upcoming goal
    const nextGoalIndex = allActions.findIndex(action => action.isNext || (action.isActive && !action.isCompleted));
    if (nextGoalIndex !== -1) {
      const pageWithNextGoal = Math.floor(nextGoalIndex / itemsPerPage) + 1;
      setCurrentPage(pageWithNextGoal);
    } else {
      setCurrentPage(1); // Default to first page if no next goal
    }
  }, [goals, allProgress, itemsPerPage]);

  const handleActionToggle = async (action) => {
    try {
      const date = format(action.date, 'yyyy-MM-dd');
      console.log('Toggling action:', action);
      
      // Create a progress update object based on the action type
      let progressUpdate = {
        date,
        details: {}
      };
      
      if (action.type === 'dhikr') {
        if (action.prayer) {
          // For prayer-based dhikr
          progressUpdate.details = {
            prayers: {
              [action.prayer]: {
                completed: !action.isCompleted
              }
            }
          };
        } else {
          // For time-based dhikr
          progressUpdate.details = {
            completed: !action.isCompleted
          };
        }
      } else if (action.type === 'quran_reading') {
        // For Quran reading goals
        console.log('Toggling Quran reading goal:', action);
        
        // Fix for proper hizbRange handling - use the first part if it contains multiple ranges
        const hizbRange = action.hizbRange?.split(' - ').slice(0, 2).join(' - ') || 
                         action.progress?.hizbRange;
                         
        const totalVerses = action.progress?.totalAmount || 0;
        
        // When marking as completed, create proper verse references instead of dummy entries
        let versesRead = [];
        if (!action.isCompleted) {
          // Improved regex to extract surah and verse info from the hizbRange
          // Format can be either "Al-Hijr 15:1 - An-Nahl 16:50" or "15:1 - 16:50"
          // We need to extract: startSurah (15), startVerse (1), endSurah (16), endVerse (50)
          const rangeMatch = hizbRange?.match(/(\d+):(\d+)[^-]*-[^0-9]*(\d+):(\d+)/);
          
          if (rangeMatch) {
            const [_, startSurah, startVerse, endSurah, endVerse] = rangeMatch;
            
            console.log('Creating verse references from range:', {
              match: rangeMatch,
              startSurah, startVerse, endSurah, endVerse,
              hizbRange
            });
            
            // Use the SURAHS data to generate accurate verse references
            for (let surahNum = parseInt(startSurah); surahNum <= parseInt(endSurah); surahNum++) {
              // Find the surah data to get total verses
              const surahData = SURAHS.find(s => s.number === surahNum);
              
              if (!surahData) {
                console.error(`Surah ${surahNum} not found in SURAHS data`);
                continue;
              }
              
              const verseStart = surahNum === parseInt(startSurah) ? parseInt(startVerse) : 1;
              const verseEnd = surahNum === parseInt(endSurah) ? 
                Math.min(parseInt(endVerse), surahData.totalVerses) : 
                surahData.totalVerses;
              
              console.log(`Processing Surah ${surahNum} (${surahData.name}): verses ${verseStart}-${verseEnd} of ${surahData.totalVerses} total verses`);
              
              // Loop through all verses in this surah
              for (let verse = verseStart; verse <= verseEnd && versesRead.length < totalVerses; verse++) {
                versesRead.push(`${surahNum}:${verse}`);
              }
            }
            
            console.log(`Generated ${versesRead.length} verse references spanning surahs ${startSurah}-${endSurah}`);
          } else {
            // Try more aggressive regex to find any numbers that could be surah:verse
            const numbers = hizbRange?.match(/(\d+):(\d+)/g);
            
            if (numbers && numbers.length >= 2) {
              // Extract first and last surah:verse
              const startParts = numbers[0].split(':').map(Number);
              const endParts = numbers[numbers.length - 1].split(':').map(Number);
              
              const startSurah = startParts[0];
              const startVerse = startParts[1];
              const endSurah = endParts[0];
              const endVerse = endParts[1];
              
              console.log('Extracted range from multiple matches:', {
                startSurah, startVerse, endSurah, endVerse,
                hizbRange, matches: numbers
              });
              
              // Generate sequential verse references using SURAHS data
              for (let surahNum = startSurah; surahNum <= endSurah; surahNum++) {
                const surahData = SURAHS.find(s => s.number === surahNum);
                
                if (!surahData) {
                  console.error(`Surah ${surahNum} not found in SURAHS data`);
                  continue;
                }
                
                const verseStart = surahNum === startSurah ? startVerse : 1;
                const verseEnd = surahNum === endSurah ? 
                  Math.min(endVerse, surahData.totalVerses) : 
                  surahData.totalVerses;
                
                for (let verse = verseStart; verse <= verseEnd && versesRead.length < totalVerses; verse++) {
                  versesRead.push(`${surahNum}:${verse}`);
                }
              }
              
              console.log(`Generated ${versesRead.length} verse references from alternate method`);
            } else {
              console.log('Could not parse range from:', hizbRange);
              // Handle case where we can't parse the range, using SURAHS data for fallback
              let verseCount = 0;
              let surahIndex = 0;
              
              while (verseCount < totalVerses && surahIndex < SURAHS.length) {
                const surah = SURAHS[surahIndex];
                for (let verse = 1; verse <= surah.totalVerses && verseCount < totalVerses; verse++) {
                  versesRead.push(`${surah.number}:${verse}`);
                  verseCount++;
                }
                surahIndex++;
              }
              
              console.log(`Generated ${versesRead.length} verse references using fallback method`);
            }
          }
          
          // Check if we're still missing verses after all our parsing attempts
          if (versesRead.length < totalVerses) {
            console.log(`Warning: Only generated ${versesRead.length}/${totalVerses} verses. Adding remaining verses from next surahs.`);
            
            // Start from the last surah we processed
            let lastVerseKey = versesRead[versesRead.length - 1] || "1:1";
            let [lastSurahNum, lastVerseNum] = lastVerseKey.split(':').map(Number);
            
            // Find next available verses from subsequent surahs
            let currentSurahIndex = SURAHS.findIndex(s => s.number === lastSurahNum);
            let currentVerse = lastVerseNum + 1;
            
            while (versesRead.length < totalVerses && currentSurahIndex < SURAHS.length) {
              const currentSurah = SURAHS[currentSurahIndex];
              
              // If we've reached the end of the current surah, move to the next
              if (currentVerse > currentSurah.totalVerses) {
                currentSurahIndex++;
                currentVerse = 1;
                continue;
              }
              
              if (currentSurahIndex < SURAHS.length) {
                versesRead.push(`${currentSurah.number}:${currentVerse}`);
                currentVerse++;
              }
            }
          }
        }
          
        progressUpdate.details = {
          completed: !action.isCompleted,
          amount: !action.isCompleted ? totalVerses : 0,
          hizbRange: hizbRange,
          displayRange: action.progress?.displayRange,
          versesRead: versesRead,
          totalVerses: totalVerses,
          // Make sure we set currentHizbIndex if available
          currentHizbIndex: action.progress?.currentHizbIndex || 0
        };
        
        console.log('Quran progress update:', progressUpdate);
        
        // Also update reading progress in settings when completing a Quran reading goal
        if (!action.isCompleted && settings && versesRead.length > 0) {
          // We're marking it as complete, so update the reading progress too
          const readingProgress = settings.quran?.readingProgress || { 
            currentSession: { surah: null, verse: null, timestamp: null },
            history: {}
          };
          
          const updatedHistory = { ...readingProgress.history };
          
          // Group verses by surah for more organized logging
          const versesBySurah = {};
          
          // Add each verse to the reading history
          versesRead.forEach(verseKey => {
            const [surahId, verseNum] = verseKey.split(':');
            
            // Validate verse number against surah length
            const surahData = SURAHS.find(s => s.number === parseInt(surahId));
            if (!surahData || parseInt(verseNum) > surahData.totalVerses) {
              console.log(`Skipping invalid verse ${verseKey} (Surah ${surahId} has ${surahData?.totalVerses || 'unknown'} verses)`);
              return;
            }
            
            // Track verses by surah for logging
            if (!versesBySurah[surahId]) {
              versesBySurah[surahId] = [];
            }
            versesBySurah[surahId].push(parseInt(verseNum));
            
            // Update the reading history
            if (!updatedHistory[surahId]) {
              updatedHistory[surahId] = {};
            }
            updatedHistory[surahId][verseNum] = { 
              timestamp: new Date().toISOString(),
              mode: 'goal'
            };
          });
          
          // Log the verses we're adding to each surah
          console.log('Updating reading progress in settings for surahs:', 
            Object.entries(versesBySurah).map(([surahId, verses]) => {
              const surahData = SURAHS.find(s => s.number === parseInt(surahId));
              const min = Math.min(...verses);
              const max = Math.max(...verses);
              return `Surah ${surahId} (${surahData?.name || 'unknown'}): ${verses.length} verses (${min}-${max} of ${surahData?.totalVerses || 'unknown'} total)`;
            }).join(', ')
          );
          
          // Get last verse read for lastReadingSession (ensuring it's valid)
          let lastVerseKey = null;
          for (let i = versesRead.length - 1; i >= 0; i--) {
            const [surahId, verseNum] = versesRead[i].split(':');
            const surahData = SURAHS.find(s => s.number === parseInt(surahId));
            if (surahData && parseInt(verseNum) <= surahData.totalVerses) {
              lastVerseKey = versesRead[i];
              break;
            }
          }
          
          if (lastVerseKey) {
            const [lastSurahId, lastVerseNum] = lastVerseKey.split(':');
            
            // Create the new settings object with updates
            const newSettingsData = {
              ...settings,
              quran: {
                ...settings.quran,
                readingProgress: {
                  ...readingProgress,
                  history: updatedHistory
                },
                lastReadingSession: {
                  surahId: lastSurahId,
                  verseId: `${lastSurahId}:${lastVerseNum}`,
                  timestamp: Date.now()
                }
              }
            };
            
            // Perform updates in parallel to avoid sequential awaits that might cause UI refreshes
            // First, initiate both updates without awaiting them
            const updateGoalPromise = updateGoalProgress(action.goalId, progressUpdate);
            const updateSettingsPromise = updateSettings(newSettingsData);
            
            // Then wait for both to complete
            await Promise.all([updateGoalPromise, updateSettingsPromise]);
            
            console.log('Updated settings reading progress for completed goal');
            return; // Skip the separate updateGoalProgress call below since we already did it
          } else {
            console.error('No valid verses found to update lastReadingSession');
          }
        }
      } else if (action.type === 'memorization') {
        // For memorization goals
        progressUpdate.details = {
          completed: !action.isCompleted,
          amount: !action.isCompleted ? action.verses || 0 : 0
        };
      }
      
      // Update the goal progress (only reached for non-quran goals or when not marking a quran goal as complete)
      await updateGoalProgress(action.goalId, progressUpdate);
      
    } catch (error) {
      console.error('Error updating goal progress:', error);
      setError('Error updating goal progress');
    }
  };

  const renderGoalProgress = (goal) => {
    const dayProgress = allProgress.get(todayStr);
    if (!dayProgress) return null;

    const progress = dayProgress.get(goal.id);
    if (!progress) return null;

    // For prayer-based goals, show prayer-specific progress
    if (goal.recurrenceType === 'prayer_based') {
      const prayerProgress = goal.progress?.[todayStr]?.prayers || {};
      const completedPrayers = Object.values(prayerProgress).filter(p => p.completed).length;
      const totalPrayers = PRAYER_NAMES.length;
      const percentComplete = Math.round((completedPrayers / totalPrayers) * 100);

      return (
        <div className="flex flex-col gap-1">
          <div className="text-sm text-gray-500">
            {`${completedPrayers} of ${totalPrayers} prayers completed`}
          </div>
          <div className="text-sm">
            Today's Progress: {percentComplete}%
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className="bg-primary h-2 rounded-full transition-all duration-300"
              style={{ width: `${percentComplete}%` }}
            />
          </div>
        </div>
      );
    }

    // For other goal types
    return (
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500">
          {goal.type === 'quran_reading' 
            ? `${progress.dailyTarget} per day` 
            : progress.dailyTarget}
        </div>
        <div className="text-sm">
          Today's Progress: {progress.percentComplete}%
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div 
            className="bg-primary h-2 rounded-full transition-all duration-300"
            style={{ width: `${progress.percentComplete}%` }}
          />
        </div>
      </div>
    );
  };

  // Pagination handlers
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  // Get current page items
  const currentActions = actions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return (
      <div className={`rounded-[24px] px-4 ${
        isDarkMode 
          ? 'bg-white/5 backdrop-blur-xl' 
          : 'bg-white/60 backdrop-blur-xl'
      }`}>
        <div className="animate-pulse p-4">
          <div className="h-6 w-24 bg-gray-200 dark:bg-gray-800 rounded" />
          <div className="space-y-3 mt-4">
            <div className="h-16 bg-gray-100 dark:bg-gray-900 rounded-xl" />
            <div className="h-16 bg-gray-100 dark:bg-gray-900 rounded-xl" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`rounded-[24px] overflow-hidden px-4 ${
      isDarkMode 
        ? 'bg-white/5 backdrop-blur-xl' 
        : 'bg-white/60 backdrop-blur-xl'
    }`}>
      {error && (
        <div className={`p-4 text-sm ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
          {error}
        </div>
      )}
      <div className="relative">
        <AnimatePresence mode="sync">
        {actions.length > 0 ? (
          <>
            <div className="space-y-1">
              {currentActions.map((action, index) => (
                <div
                  key={action.id}
                  className={`flex items-center py-3`}
                >
                  {/* Timeline dot and line */}
                  <div className="relative">
                    {/* Dot */}
                    <div className={`w-3 h-3 rounded-full relative z-10 ${
                      action.isCompleted
                        ? isDarkMode
                          ? 'bg-emerald-500'
                          : 'bg-emerald-600'
                        : action.isActive
                          ? isDarkMode
                            ? 'bg-emerald-500'
                            : 'bg-emerald-600'
                          : isDarkMode
                            ? 'bg-white/20'
                            : 'bg-gray-300'
                    }`} />
                    {/* Single continuous line (except for last item) */}
                    {index !== currentActions.length - 1 && (
                      <div className={`absolute bottom-0 left-1/2 w-0.5 h-full top-3 -translate-x-1/2 ${
                        isDarkMode ? 'bg-white/10' : 'bg-gray-200'
                      }`} />
                    )}
                  </div>

                  {/* Content */}
                  <div className="flex-1 ml-4">
                    <div className="flex items-center justify-between">
                      <div>
                        {/* Action date */}
                        <div className={`flex items-center text-xs font-medium mb-1 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          <Calendar className="w-3 h-3 mr-1" />
                          {formatActionDate(action.date)}
                        </div>
                        
                        <div className="flex items-center gap-2">
                          <span className={`text-sm font-medium ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          }`}>
                            {action.title}
                          </span>
                          {(action.isNext || action.isOverdue || action.isMissed) && (
                            <TaskStatusBadge 
                              status={action.isNext ? 'next' : undefined}
                              isOverdue={action.isOverdue}
                              isMissed={action.isMissed}
                            />
                          )}
                        </div>
                        {action.type === 'dhikr' && action.prayer && (
                          <p className={`text-xs mt-0.5 ${
                            isDarkMode ? 'text-white/60' : 'text-gray-600'
                          }`}>
                            {action.prayer}
                          </p>
                        )}
                      </div>

                      {/* Checkbox */}
                      <button
                        onClick={() => handleActionToggle(action)}
                        aria-label={action.isCompleted ? "Mark as incomplete" : "Mark as complete"}
                        title={action.isCompleted ? "Mark as incomplete" : "Mark as complete"}
                        className={`w-6 h-6 rounded-full border flex items-center justify-center transition-all ${
                          action.isCompleted
                            ? isDarkMode
                              ? 'bg-emerald-500 border-emerald-500'
                              : 'bg-emerald-600 border-emerald-600'
                            : isDarkMode
                              ? 'border-white/20 hover:border-white/40 hover:bg-white/10'
                              : 'border-gray-300 hover:border-gray-400 hover:bg-gray-100'
                        }`}
                      >
                        {action.isCompleted && (
                          <Check className="w-4 h-4 text-white mx-auto" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Pagination controls */}
            {totalPages > 1 && (
              <div className={`flex items-center justify-between py-3 mt-2 border-t ${
                isDarkMode ? 'border-white/10' : 'border-gray-200'
              }`}>
                <button
                  onClick={goToPrevPage}
                  disabled={currentPage === 1}
                  className={`p-1 rounded-full ${
                    currentPage === 1
                      ? isDarkMode ? 'text-gray-600' : 'text-gray-300'
                      : isDarkMode ? 'text-white hover:bg-white/10' : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
                
                <span className={`text-xs font-medium ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  Page {currentPage} of {totalPages}
                </span>
                
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                  className={`p-1 rounded-full ${
                    currentPage === totalPages
                      ? isDarkMode ? 'text-gray-600' : 'text-gray-300'
                      : isDarkMode ? 'text-white hover:bg-white/10' : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="py-12 text-center relative">
              <Target className={`w-16 h-16 mx-auto mb-6 ${
                isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
              }`} />
              <h3 className={`text-xl font-medium mb-3 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Set Your First Goal
              </h3>
              <p className={`text-sm max-w-xs mx-auto ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Begin your journey by setting meaningful goals for Quran reading, memorization, or daily dhikr.
              </p>
          </div>
         )}
        </AnimatePresence>
      </div>
    </div>
  );
}