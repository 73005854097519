import { useState, useEffect, useMemo } from 'react';
import { useGoals } from './useGoals';
import { format, subDays, startOfWeek, startOfMonth, eachDayOfInterval, isWithinInterval } from 'date-fns';

export const useAnalytics = (timeRange = 'week') => {
  const { goals } = useGoals();
  const [analyticsData, setAnalyticsData] = useState({
    stats: {
      totalGoals: 0,
      completionRate: 0,
      currentStreak: 0,
      bestStreak: 0
    },
    categoryPerformance: {},
    activityData: []
  });

  // Calculate date range based on selected timeRange
  const dateRange = useMemo(() => {
    const today = new Date();
    switch (timeRange) {
      case 'week':
        return {
          start: startOfWeek(today),
          end: today
        };
      case 'month':
        return {
          start: startOfMonth(today),
          end: today
        };
      case 'year':
        return {
          start: new Date(today.getFullYear(), 0, 1),
          end: today
        };
      default:
        return {
          start: startOfWeek(today),
          end: today
        };
    }
  }, [timeRange]);

  // Calculate analytics data whenever goals or timeRange changes
  useEffect(() => {
    if (!goals) return;

    const calculateAnalytics = () => {
      // Initialize counters
      let totalCompletions = 0;
      let totalTasks = 0;
      let currentStreak = 0;
      let bestStreak = 0;
      let streak = 0;
      
      // Category performance tracking
      const categories = {
        quran_reading: { completed: 0, total: 0 },
        memorization: { completed: 0, total: 0 },
        dhikr: { completed: 0, total: 0 }
      };

      // Get all days in the selected range
      const daysInRange = eachDayOfInterval(dateRange);
      const activityData = daysInRange.map(date => {
        const dateStr = format(date, 'yyyy-MM-dd');
        let dayCompletions = 0;
        let dayTasks = 0;

        goals.forEach(goal => {
          const progress = goal.progress?.[dateStr];
          
          // Update category stats
          if (goal.type in categories) {
            if (progress?.completed) {
              categories[goal.type].completed++;
            }
            categories[goal.type].total++;
          }

          // Count completions for the day
          if (progress?.completed) {
            dayCompletions++;
          }
          dayTasks++;
        });

        // Update streak calculation
        if (dayCompletions === dayTasks && dayTasks > 0) {
          streak++;
          bestStreak = Math.max(bestStreak, streak);
        } else {
          streak = 0;
        }

        // Update totals
        totalCompletions += dayCompletions;
        totalTasks += dayTasks;

        return {
          date: dateStr,
          completionRate: dayTasks > 0 ? (dayCompletions / dayTasks) * 100 : 0
        };
      });

      // Calculate current streak
      currentStreak = streak;

      // Calculate category performance percentages
      const categoryPerformance = Object.entries(categories).reduce((acc, [category, data]) => {
        acc[category] = data.total > 0 ? (data.completed / data.total) * 100 : 0;
        return acc;
      }, {});

      setAnalyticsData({
        stats: {
          totalGoals: goals.length,
          completionRate: totalTasks > 0 ? (totalCompletions / totalTasks) * 100 : 0,
          currentStreak,
          bestStreak
        },
        categoryPerformance,
        activityData
      });
    };

    calculateAnalytics();
  }, [goals, timeRange, dateRange]);

  return analyticsData;
}; 