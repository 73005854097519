import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getDhikrById } from '../types/dhikr';

export const useDhikrProgress = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState({ history: [] });

  // Load progress from Firestore
  useEffect(() => {
    const loadProgress = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const progressRef = doc(db, 'dhikrProgress', currentUser.uid);
        const progressSnap = await getDoc(progressRef);
        
        if (progressSnap.exists()) {
          setProgress(progressSnap.data());
        } else {
          // Initialize empty progress
          await updateDoc(progressRef, { history: [] });
          setProgress({ history: [] });
        }
      } catch (err) {
        console.error('Error loading dhikr progress:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadProgress();
  }, [currentUser]);

  // Save dhikr completion
  const saveDhikrCompletion = async (dhikrId, goalId = null, prayer = null) => {
    if (!currentUser) return;

    const dhikr = getDhikrById(dhikrId);
    if (!dhikr) return;

    const progressRef = doc(db, 'dhikrProgress', currentUser.uid);
    const timestamp = new Date();

    try {
      const newProgress = {
        dhikrId,
        goalId,
        prayer,
        completedAt: timestamp,
        type: dhikr.type,
        category: dhikr.category
      };

      await updateDoc(progressRef, {
        history: arrayUnion(newProgress)
      });

      // If this is part of a goal, update goal progress
      if (goalId) {
        const goalRef = doc(db, 'goals', currentUser.uid);
        const goalSnap = await getDoc(goalRef);
        
        if (goalSnap.exists()) {
          const goals = goalSnap.data();
          const goal = goals[goalId];
          
          if (goal) {
            const today = new Date().toISOString().split('T')[0];
            const updatedGoal = {
              ...goal,
              progress: {
                ...goal.progress,
                [today]: {
                  ...goal.progress?.[today],
                  dhikrs: {
                    ...goal.progress?.[today]?.dhikrs,
                    [dhikrId]: {
                      count: (goal.progress?.[today]?.dhikrs?.[dhikrId]?.count || 0) + 1,
                      lastCompletedAt: timestamp,
                      prayer
                    }
                  }
                }
              }
            };

            await updateDoc(goalRef, {
              [goalId]: updatedGoal
            });
          }
        }
      }

      // Update local state
      setProgress(prev => ({
        ...prev,
        history: [...(prev.history || []), newProgress]
      }));

    } catch (err) {
      console.error('Error saving dhikr completion:', err);
      setError(err.message);
      throw err;
    }
  };

  // Get progress for a specific goal and date
  const getGoalProgress = (goalId, date = new Date()) => {
    const dateStr = date.toISOString().split('T')[0];
    return progress.history?.filter(entry => 
      entry.goalId === goalId && 
      new Date(entry.completedAt).toISOString().split('T')[0] === dateStr
    ) || [];
  };

  // Get all completions for today
  const getTodayCompletions = () => {
    const today = new Date().toISOString().split('T')[0];
    return progress.history?.filter(entry => {
      try {
        const entryDate = new Date(entry.completedAt);
        if (isNaN(entryDate.getTime())) return false;
        return entryDate.toISOString().split('T')[0] === today;
      } catch (error) {
        console.error('Invalid date in completion history:', entry);
        return false;
      }
    }) || [];
  };

  // Get prayer-specific completions for today
  const getTodayPrayerCompletions = (prayer) => {
    const todayCompletions = getTodayCompletions();
    return todayCompletions.filter(entry => entry.prayer === prayer);
  };

  return {
    loading,
    error,
    progress,
    saveDhikrCompletion,
    getGoalProgress,
    getTodayCompletions,
    getTodayPrayerCompletions
  };
}; 