import { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { getDeviceId } from '../utils/deviceIdentifier';
import { requestLinkingCode } from '../services/deviceLinkingService';

// Action types
const ACTIONS = {
  START_LOADING: 'START_LOADING',
  SET_DEVICE_UNLINKED: 'SET_DEVICE_UNLINKED',
  SET_DEVICE_LINKED: 'SET_DEVICE_LINKED',
  SET_ERROR: 'SET_ERROR'
};

// Initial state
const initialState = {
  isChecking: true,
  isLinked: false,
  linkingCode: null,
  error: null,
  deviceId: null
};

// Reducer for device linking state
function deviceLinkingReducer(state, action) {
  console.log('Device linking reducer:', { action, currentState: state });
  
  switch (action.type) {
    case ACTIONS.START_LOADING:
      return { ...state, isChecking: true, error: null };
    case ACTIONS.SET_DEVICE_UNLINKED:
      const newState = {
        ...state,
        isChecking: false,
        isLinked: false,
        linkingCode: action.payload.linking_code,
        deviceId: action.payload.deviceId,
        error: null
      };
      console.log('New state after unlink:', newState);
      return newState;
    case ACTIONS.SET_DEVICE_LINKED:
      return {
        ...state,
        isChecking: false,
        isLinked: true,
        linkingCode: null,
        deviceId: action.payload.deviceId,
        error: null
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        isChecking: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export function useDeviceLinking() {
  const [state, dispatch] = useReducer(deviceLinkingReducer, initialState);
  const requestInProgress = useRef(false);
  const lastKnownStatus = useRef(null);
  const isAuthenticated = useRef(false);

  const checkDeviceStatus = useCallback(async (deviceId, signal, forceCheck = false) => {
    if ((signal?.aborted || requestInProgress.current) && !forceCheck) return null;
    
    try {
      requestInProgress.current = true;
      const result = await requestLinkingCode(deviceId);
      
      if (result.isLinked) {
        isAuthenticated.current = true;
      }

      if (forceCheck || JSON.stringify(result) !== JSON.stringify(lastKnownStatus.current)) {
        lastKnownStatus.current = result;
        return result;
      }
      return null;
    } finally {
      requestInProgress.current = false;
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    async function setupDeviceLinking() {
      try {
        const deviceId = await getDeviceId();
        console.log('Device linking setup - got deviceId:', deviceId);
        
        // Check device status regardless of ID format
        const initialStatus = await checkDeviceStatus(deviceId, abortController.signal, true);
        console.log('Initial device status:', initialStatus);
        
        if (initialStatus?.isLinked) {
          console.log('Device is linked:', deviceId);
          isAuthenticated.current = true;
          dispatch({ type: ACTIONS.SET_DEVICE_LINKED, payload: { deviceId } });
        } else {
          console.log('Device is not linked:', deviceId);
          dispatch({
            type: ACTIONS.SET_DEVICE_UNLINKED,
            payload: {
              deviceId,
              linking_code: initialStatus?.linkingCode
            }
          });
        }

        const unsubscribe = onSnapshot(
          doc(db, 'device_links', deviceId),
          { includeMetadataChanges: true },
          async (snapshot) => {
            if (abortController.signal.aborted) return;

            try {
              if (!snapshot.exists() && !snapshot.metadata.fromCache) {
                isAuthenticated.current = false;
                if (auth.currentUser) {
                  await auth.signOut();
                }

                const result = await checkDeviceStatus(deviceId, abortController.signal, true);
                console.log('Device status check result:', result);
                if (result) {
                  dispatch({
                    type: ACTIONS.SET_DEVICE_UNLINKED,
                    payload: {
                      deviceId,
                      linking_code: result.linkingCode
                    }
                  });
                }
                return;
              }

              if (snapshot.exists() && 
                  !snapshot.metadata.hasPendingWrites && 
                  !isAuthenticated.current) {
                const result = await checkDeviceStatus(deviceId, abortController.signal);
                if (result) {
                  dispatch({
                    type: result.isLinked ? ACTIONS.SET_DEVICE_LINKED : ACTIONS.SET_DEVICE_UNLINKED,
                    payload: {
                      deviceId,
                      linking_code: result.linkingCode
                    }
                  });
                }
              }
            } catch (error) {
              console.error('Error in device link listener:', error);
              if (!abortController.signal.aborted) {
                dispatch({
                  type: ACTIONS.SET_ERROR,
                  payload: error.message
                });
              }
            }
          }
        );

        return () => unsubscribe();
      } catch (error) {
        console.error('Error in setupDeviceLinking:', error);
        dispatch({
          type: ACTIONS.SET_ERROR,
          payload: error.message
        });
      }
    }

    setupDeviceLinking();

    return () => {
      abortController.abort();
    };
  }, [checkDeviceStatus]);

  return state;
} 