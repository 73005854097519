import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { LocationSettings } from './components/LocationSettings';
import { AdhanSettings } from './components/AdhanSettings';
import { SelectionView } from './components/SelectionView';
import { TuneView } from './components/TuneView';
import { useSettings } from '../../../contexts/SettingsContext';
import { useAdhanSettings } from './hooks/useAdhanSettings';

const DashboardSettings = ({ onTransitionStart, onTransitionEnd }) => {
  const { isDarkMode } = useDarkMode();
  const { settings, updateSettings } = useSettings();
  const {
    selectedAdhan,
    adhanOptions,
    handleAdhanChange,
    handleSaveAdhan,
  } = useAdhanSettings();

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [showCalculationSheet, setShowCalculationSheet] = useState(false);
  const [showReciterSheet, setShowReciterSheet] = useState(false);
  const [showTuneModal, setShowTuneModal] = useState(false);
  const [tunedTimes, setTunedTimes] = useState({
    Fajr: 0,
    Dhuhr: 0,
    Asr: 0,
    Maghrib: 0,
    Isha: 0
  });
  const [calculationMethod, setCalculationMethod] = useState(settings.calculationMethod || 1);
  const adhanAudioRef = useRef(null);
  const [isPlayingAdhan, setIsPlayingAdhan] = useState(false);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setTunedTimes(settings.tunedTimes || {
      Fajr: 0,
      Dhuhr: 0,
      Asr: 0,
      Maghrib: 0,
      Isha: 0
    });
  }, [settings.tunedTimes]);

  const handleTuneChange = (prayer, value) => {
    // If prayer is null and value is an object, it's a bulk update
    if (prayer === null && typeof value === 'object') {
      setTunedTimes(value);
    } else {
      // Individual prayer update
      setTunedTimes(prev => ({
        ...prev,
        [prayer]: parseInt(value)
      }));
    }
  };

  const saveTunedTimes = () => {
    // Ensure all prayer times are integers
    const formattedTunedTimes = {};
    Object.keys(tunedTimes).forEach(prayer => {
      formattedTunedTimes[prayer] = parseInt(tunedTimes[prayer] || 0);
    });

    // Create API format with additional prayer times
    const apiTunedTimes = {
      ...formattedTunedTimes,
      Imsak: 0,
      Sunrise: 0,
      Sunset: 0,
      Midnight: 0
    };

    // Update settings
    updateSettings({ 
      tunedTimes: formattedTunedTimes,
      apiTunedTimes
    });
  };

  const resetTunedTimes = () => {
    const resetTimes = {
      Fajr: 0,
      Dhuhr: 0,
      Asr: 0,
      Maghrib: 0,
      Isha: 0
    };

    const resetApiTimes = {
      ...resetTimes,
      Imsak: 0,
      Sunrise: 0,
      Sunset: 0,
      Midnight: 0
    };

    // Update local state
    setTunedTimes(resetTimes);
    
    // Update settings
    updateSettings({ 
      tunedTimes: resetTimes,
      apiTunedTimes: resetApiTimes
    });
  };

  const calculationMethodOptions = [
    { value: 1, label: 'Muslim World League' },
    { value: 2, label: 'Islamic Society of North America' },
    { value: 3, label: 'Egyptian General Authority of Survey' },
    { value: 4, label: 'Umm Al-Qura University, Makkah' },
    { value: 5, label: 'University of Islamic Sciences, Karachi' },
    { value: 7, label: 'Institute of Geophysics, University of Tehran' },
    { value: 8, label: 'Gulf Region' },
    { value: 9, label: 'Kuwait' },
    { value: 10, label: 'Qatar' },
    { value: 11, label: 'Majlis Ugama Islam Singapura, Singapore' },
    { value: 12, label: 'Union Organization islamic de France' },
    { value: 13, label: 'Diyanet İşleri Başkanlığı, Turkey' },
    { value: 14, label: 'Spiritual Administration of Muslims of Russia' },
    { value: 15, label: 'Moonsighting Committee Worldwide' }
  ];

  const handleCalculationMethodChange = (option) => {
    const newMethod = parseInt(option.value);
    setCalculationMethod(newMethod);
    updateSettings({ calculationMethod: newMethod });
  };

  const handleOpenSheet = (setter) => {
    if (onTransitionStart) onTransitionStart();
    setter(true);
  };

  const handleCloseSheet = (setter) => {
    // Stop any playing adhan when closing sheets
    if (window.innerWidth < 768) {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    }
    
    setter(false);
    if (onTransitionEnd) onTransitionEnd();
  };

  const handleCloseReciterSheet = () => {
    handleCloseSheet(setShowReciterSheet);
  };

  const mainContent = (
    <div className="space-y-12">
      <LocationSettings 
        isDarkMode={isDarkMode} 
        onOpenCalculation={() => handleOpenSheet(setShowCalculationSheet)}
        onOpenTune={() => handleOpenSheet(setShowTuneModal)}
      />
      <AdhanSettings 
        isDarkMode={isDarkMode}
        onOpenReciter={() => handleOpenSheet(setShowReciterSheet)}
      />
    </div>
  );

  if (isMobileView) {
    const isSheetOpen = showCalculationSheet || showReciterSheet || showTuneModal;

    return (
      <div className="relative">
        <motion.div
          initial={false}
          animate={{ 
            x: isSheetOpen ? '-15%' : 0
          }}
          transition={{ 
            type: 'spring',
            bounce: 0,
            duration: 0.4
          }}
          className="relative"
        >
          {mainContent}
        </motion.div>

        <AnimatePresence>
          {showCalculationSheet && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ 
                type: 'spring',
                bounce: 0,
                duration: 0.4
              }}
              className={`fixed inset-0 z-50 ${
                isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
              }`}
            >
              <SelectionView
                show={true}
                onClose={() => handleCloseSheet(setShowCalculationSheet)}
                title="Method"
                options={calculationMethodOptions}
                selectedValue={calculationMethodOptions.find(opt => opt.value === calculationMethod)}
                onSelect={handleCalculationMethodChange}
                isDarkMode={isDarkMode}
              />
            </motion.div>
          )}

          {showReciterSheet && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ 
                type: 'spring',
                bounce: 0,
                duration: 0.4
              }}
              className="fixed inset-0 z-50"
            >
              <SelectionView
                show={true}
                onClose={handleCloseReciterSheet}
                title="Select Reciter"
                options={adhanOptions}
                selectedValue={adhanOptions?.find(opt => opt.value === selectedAdhan)}
                onSelect={(option) => {
                  handleAdhanChange(option.value);
                }}
                isDarkMode={isDarkMode}
              />
            </motion.div>
          )}

          {showTuneModal && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ 
                type: 'spring',
                bounce: 0,
                duration: 0.4
              }}
              className="fixed inset-0 z-50"
            >
              <TuneView
                show={true}
                onClose={() => handleCloseSheet(setShowTuneModal)}
                tunedTimes={tunedTimes}
                onTuneChange={handleTuneChange}
                onSave={saveTunedTimes}
                onReset={resetTunedTimes}
                isDarkMode={isDarkMode}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }

  return mainContent;
};

export default DashboardSettings; 