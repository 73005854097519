import React from 'react';
import { ChevronDown, Settings } from 'lucide-react';
import { useDarkMode } from '../../../contexts/DarkModeContext';

const QuranHeader = ({ 
  surah, 
  onHeaderClick,
  onSettingsClick,
  isDarkMode,
  isNavigationOpen,
  isNavigationPage = false
}) => {
  if (!surah) return null;

  return (
    <header className="sticky top-0 z-40">
      {/* Background with gradient fade */}
      <div 
        className="absolute inset-0"
        style={{ 
          background: isDarkMode 
            ? 'linear-gradient(rgb(10, 10, 10) 45%, rgba(10, 10, 10, 0.45))'
            : 'linear-gradient(rgb(249, 250, 251) 45%, rgba(249, 250, 251, 0.45))',
          backdropFilter: 'blur(8px)'
        }}
      />
      
      {/* Content layer */}
      <div 
        style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} 
        className="relative py-4 px-4"
      >
        <div className="flex items-start justify-between">
          <div className="w-10" />
          <button 
            onClick={onHeaderClick}
            className="flex flex-col items-center flex-1"
          >
            <div className="flex items-center gap-2">
              <h1 className={`${isNavigationPage ? 'text-2xl' : 'text-xl'} font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {surah.name_simple}
              </h1>
              {!isNavigationPage && (
                <ChevronDown className={`w-5 h-5 transition-transform ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                } ${isNavigationOpen ? 'rotate-180' : ''}`} />
              )}
            </div>
            <p className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Surah {surah.id} • {surah.verses_count} verses
            </p>
          </button>
          
          <button
            onClick={onSettingsClick}
            className={`p-2 rounded-full transition-colors ${
              isDarkMode 
                ? 'hover:bg-white/5 text-gray-400 hover:text-white' 
                : 'hover:bg-black/5 text-gray-600 hover:text-gray-900'
            }`}
          >
            <Settings className="w-5 h-5" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default QuranHeader; 