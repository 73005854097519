/* src/components/QiblaFinder.js */
import React, { useState, useEffect, useRef } from 'react';
import { Compass, Clock, MapPin, AlertCircle } from 'lucide-react';
import { ArrowUp01Icon, Kaaba01Icon } from "hugeicons-react";
import { useDarkMode } from '../contexts/DarkModeContext';
import { usePermissions } from '../hooks/usePermissions';
import { calculateQibla } from '../utils/qiblaCalculator';
import { useLocation } from '../contexts/LocationContext';

const QiblaFinder = () => {
  const { isDarkMode } = useDarkMode();
  const { location: locationPermission, motion: motionPermission } = usePermissions();
  const { location, error: locationError } = useLocation();
  const [qiblaDirection, setQiblaDirection] = useState(null);
  const [deviceDirection, setDeviceDirection] = useState(0);
  const [error, setError] = useState(null);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [loading, setLoading] = useState(true);
  const readings = useRef([]);
  const READINGS_BUFFER_SIZE = 10;
  const isNative = typeof window !== 'undefined' && window.isUmmahtiNative;
  const [permissionState, setPermissionState] = useState(isNative ? 'checking' : 'web');
  const hasInitializedRef = useRef(false);
  const [alignmentStatus, setAlignmentStatus] = useState('none');
  const lastVibrationTime = useRef(0);
  
  // More precise thresholds
  const THRESHOLDS = {
    ALIGNED: 1, // ±1 degree - perfect alignment
    CLOSE: 2.5, // ±2.5 degrees - very close
    NEAR: 5, // ±5 degrees - getting close
  };
  const VIBRATION_COOLDOWN = 1000;

  // Check whether the device is mobile and has the required sensors.
  useEffect(() => {
    const checkDevice = async () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const hasOrientation = window.DeviceOrientationEvent !== undefined;
      const hasGeolocation = 'geolocation' in navigator;
      
      setIsMobileDevice(isMobile && hasOrientation && hasGeolocation);
      if (isMobile && (!hasOrientation || !hasGeolocation)) {
        setError('Your device does not support the required sensors');
      }
      setTimeout(() => setLoading(false), 300);
    };
    checkDevice();
  }, []);

  useEffect(() => {
    if (locationPermission === 'granted' && motionPermission === 'granted') {
      // Start watching compass
      if (window.ummahti?.watchCompass) {
        window.ummahti.watchCompass();
      }
    } else if (locationPermission === 'denied' || motionPermission === 'denied') {
      setError('Please enable required permissions in your device settings');
    }

    return () => {
      // Cleanup
      if (window.ummahti?.stopWatchingCompass) {
        window.ummahti.stopWatchingCompass();
      }
    };
  }, [locationPermission, motionPermission]);

  useEffect(() => {
    const handleCompassUpdate = (event) => {
      // Ensure we're getting a valid event with detail
      console.log('[QiblaFinder] Compass update event received:', {
        hasDetail: !!event?.detail,
        fullDetail: event?.detail,
        heading: event?.detail?.heading,
        headingType: event?.detail?.heading ? typeof event.detail.heading : 'undefined'
      });
      
      if (!event?.detail) return;
      
      const { heading } = event.detail;
      if (typeof heading !== 'number' || isNaN(heading)) {
        console.warn('[QiblaFinder] Invalid heading value:', {
          heading,
          type: typeof heading,
          isNaN: isNaN(heading)
        });
        return;
      }
      
      if (window.isUmmahtiNative) {
        // Use the heading directly in iOS since it is already smoothed out by the native system
        setDeviceDirection(heading);
      } else {
        // Continue with smoothing logic for non-native environments
        readings.current.push(heading);
        if (readings.current.length > READINGS_BUFFER_SIZE) {
          readings.current.shift();
        }
        const smoothedHeading = readings.current.reduce((sum, val) => sum + val, 0) / readings.current.length;
        setDeviceDirection(smoothedHeading);
      }
    };

    // Only add event listener if we're in the native environment
    if (window.isUmmahtiNative) {
      window.addEventListener('native-compass-update', handleCompassUpdate);
      return () => {
        window.removeEventListener('native-compass-update', handleCompassUpdate);
      };
    }
  }, []);

  useEffect(() => {
    if (qiblaDirection === null || deviceDirection === null) return;

    // Calculate the difference between current direction and Qibla
    const diff = Math.abs((deviceDirection - qiblaDirection + 360) % 360);
    const normalizedDiff = Math.min(diff, 360 - diff);
    
    // Determine alignment status
    let newStatus = 'none';
    if (normalizedDiff <= THRESHOLDS.ALIGNED) {
      newStatus = 'aligned';
    } else if (normalizedDiff <= THRESHOLDS.CLOSE) {
      newStatus = 'close';
    } else if (normalizedDiff <= THRESHOLDS.NEAR) {
      newStatus = 'near';
    }
    
    setAlignmentStatus(newStatus);

    // Handle vibration only for perfect alignment
    if (newStatus === 'aligned' && window.isUmmahtiNative && window.ummahti?.vibrate) {
      const now = Date.now();
      if (now - lastVibrationTime.current >= VIBRATION_COOLDOWN) {
        try {
          window.ummahti.vibrate();
          lastVibrationTime.current = now;
          console.log('[QiblaFinder] Vibration triggered');
        } catch (error) {
          console.error('[QiblaFinder] Error triggering vibration:', error);
        }
      }
    }
  }, [deviceDirection, qiblaDirection]);

  // Update qiblaDirection whenever location changes
  useEffect(() => {
    if (location && location.latitude != null && location.longitude != null) {
      const qibla = calculateQibla(location.latitude, location.longitude);
      setQiblaDirection(qibla);
    }
  }, [location]);

  if (loading) {
    return (
      <div className={`min-h-screen flex flex-col relative ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
        {/* Gradient Orbs */}
        <div className="absolute inset-0 overflow-hidden">
          <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${isDarkMode ? 'bg-emerald-500/[0.03]' : 'bg-emerald-100/40'}`} />
          <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${isDarkMode ? 'bg-emerald-500/[0.03]' : 'bg-emerald-50/60'}`} />
          {!isDarkMode && (
            <>
              <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
              <div className="absolute inset-0 backdrop-blur-[100px]" />
            </>
          )}
        </div>

        <div className="relative z-10 flex flex-col h-full">
          <header className="flex-none">
            <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
              <div className="flex items-center justify-between">
                <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Qibla Finder</h1>
              </div>
            </div>
          </header>

          <div className="flex-1 flex flex-col items-center justify-center px-4">
            <div className={`w-64 h-64 rounded-full border-4 animate-pulse ${isDarkMode ? 'border-emerald-500/20 bg-emerald-900/5' : 'border-emerald-500/10 bg-emerald-50/80'}`}>
            </div>
            <div className="mt-8 space-y-4 w-full max-w-sm">
              {[1, 2, 3].map((i) => (
                <div key={i} className={`h-14 rounded-xl animate-pulse ${isDarkMode ? 'bg-white/5' : 'bg-gray-50/80'}`}></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!isMobileDevice) {
    return (
      <div className={`flex-1 flex items-center justify-center transition-colors duration-200 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
        <div className={`p-6 rounded-[24px] max-w-md ${isDarkMode ? 'bg-[#111111] text-gray-400' : 'bg-white text-gray-600'}`}>
          <p>Qibla finder is only available on mobile devices with compass sensors.</p>
        </div>
      </div>
    );
  }

  const getAlignmentStyles = () => {
    switch (alignmentStatus) {
      case 'aligned':
        return {
          ring: `ring-6 ${isDarkMode ? 'ring-emerald-500/40' : 'ring-emerald-500/30'}`,
          glow: `${isDarkMode ? 'bg-emerald-500/20' : 'bg-emerald-500/10'}`,
          text: `${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'} font-medium`,
          bg: `${isDarkMode ? 'bg-emerald-500/10' : 'bg-emerald-50'}`
        };
      case 'close':
        return {
          ring: `ring-2 ${isDarkMode ? 'ring-emerald-500/30' : 'ring-emerald-500/20'}`,
          glow: `${isDarkMode ? 'bg-emerald-500/10' : 'bg-emerald-500/5'}`,
          text: `${isDarkMode ? 'text-emerald-400/80' : 'text-emerald-600/80'}`,
          bg: `${isDarkMode ? 'bg-emerald-500/5' : 'bg-emerald-50/80'}`
        };
      case 'near':
        return {
          ring: `ring-1 ${isDarkMode ? 'ring-emerald-500/20' : 'ring-emerald-500/10'}`,
          glow: `${isDarkMode ? 'bg-emerald-500/5' : 'bg-emerald-500/3'}`,
          text: `${isDarkMode ? 'text-emerald-400/60' : 'text-emerald-600/60'}`,
          bg: `${isDarkMode ? 'bg-emerald-500/3' : 'bg-emerald-50/60'}`
        };
      default:
        return {
          ring: '',
          glow: '',
          text: '',
          bg: ''
        };
    }
  };

  const getAlignmentMessage = () => {
    switch (alignmentStatus) {
      case 'aligned':
        return 'Perfectly aligned';
      case 'close':
        return 'Very close to Qibla';
      case 'near':
        return 'Getting close to Qibla';
      default:
        return '';
    }
  };

  return (
    <div className={`min-h-screen flex flex-col relative ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${isDarkMode ? 'bg-emerald-500/[0.03]' : 'bg-emerald-100/40'}`} />
        <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${isDarkMode ? 'bg-emerald-500/[0.03]' : 'bg-emerald-50/60'}`} />
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
      </div>

      <div className="relative z-10 flex flex-col h-full">
        <header className="flex-none">
          <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
            <div className="flex items-center justify-between">
              <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Qibla Finder</h1>
            </div>
          </div>
        </header>

        <div className="flex-1 flex flex-col px-4" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 84px)' }}>
          <div className={`h-full flex flex-col transition-all duration-200 rounded-[24px] p-6 ${isDarkMode ? 'bg-[#111111]/80 backdrop-blur-xl shadow-lg shadow-black/5' : 'bg-white/80 backdrop-blur-xl shadow-sm'}`}>
            <div className="flex-1 flex items-center justify-center min-h-0">
              {error && (
                <div className="text-red-500 mb-4">{error}</div>
              )}

              {locationPermission === 'granted' && motionPermission === 'granted' && (
                <div className="flex flex-col items-center w-full max-w-md">
                  {/* Compass */}
                  <div className="relative aspect-square w-full mb-8">
                    <div 
                      className={`absolute inset-0 rounded-full border-4 transition-all duration-200 ${
                        alignmentStatus === 'aligned' ? (isDarkMode ? 'border-emerald-500 bg-[#111111]/90' : 'border-emerald-500 bg-white/90') : (isDarkMode ? 'border-gray-800 bg-[#111111]/90' : 'border-gray-200 bg-white/90')
                      } backdrop-blur-sm ${getAlignmentStyles().ring}`}
                      style={{ transform: `rotate(${-deviceDirection}deg)`, transition: 'transform 0.1s linear' }}
                    >
                      <div className="absolute inset-0">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className={`absolute w-0.5 h-full bg-gradient-to-b ${isDarkMode ? 'from-transparent via-emerald-500 to-transparent' : 'from-transparent via-emerald-600 to-transparent'}`} />
                            <div className={`absolute w-full h-0.5 bg-gradient-to-r ${isDarkMode ? 'from-transparent via-emerald-500 to-transparent' : 'from-transparent via-emerald-600 to-transparent'}`} />
                          </div>
                          <div className="absolute inset-0 flex items-center justify-center">
                            <span className={`absolute top-4 font-medium text-sm ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>N</span>
                            <span className={`absolute right-4 font-medium text-sm ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>E</span>
                            <span className={`absolute bottom-4 font-medium text-sm ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>S</span>
                            <span className={`absolute left-4 font-medium text-sm ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>W</span>
                          </div>
                          <div className="absolute inset-0 flex items-center justify-center rotate-45">
                            <div className={`absolute w-0.5 h-full bg-gradient-to-b ${isDarkMode ? 'from-transparent via-emerald-500/50 to-transparent' : 'from-transparent via-emerald-600/50 to-transparent'}`} />
                            <div className={`absolute w-full h-0.5 bg-gradient-to-r ${isDarkMode ? 'from-transparent via-emerald-500/50 to-transparent' : 'from-transparent via-emerald-600/50 to-transparent'}`} />
                          </div>
                          {[...Array(24)].map((_, i) => (
                            <div key={i} className="absolute inset-0 flex items-center justify-center" style={{ transform: `rotate(${i * 15}deg)` }}>
                              <div className={`absolute top-0 left-1/2 -translate-x-1/2 w-0.5 h-2 ${isDarkMode ? 'bg-emerald-500/30' : 'bg-emerald-600/30'}`} />
                            </div>
                          ))}
                          <div className={`absolute inset-2 rounded-full border ${alignmentStatus === 'aligned' ? 'border-emerald-500' : (isDarkMode ? 'border-emerald-500/20' : 'border-emerald-600/20')}`} />
                        </div>
                        {qiblaDirection !== null && (
                          <div className="absolute inset-0">
                            <div className="absolute w-full h-full flex items-center justify-center" style={{ transform: `rotate(${qiblaDirection}deg)` }}>
                              <div className="absolute top-0 left-1/2 -translate-x-1/2 flex flex-col items-center">
                                <ArrowUp01Icon className={isDarkMode ? 'text-emerald-500' : 'text-emerald-600'} size={28} />
                                <Kaaba01Icon className={isDarkMode ? 'text-emerald-500' : 'text-emerald-600'} size={20} style={{ marginTop: '-4px' }} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3 rounded-full shadow-lg ${isDarkMode ? 'bg-white' : 'bg-gray-900'}`} />
                    </div>
                  </div>
                  <div className="space-y-4 w-full">
                    <div className={`flex items-center justify-between p-4 rounded-xl backdrop-blur-sm ${isDarkMode ? 'bg-white/5' : 'bg-gray-50/80'}`}>
                      <div className="flex items-center gap-3">
                        <Clock className={isDarkMode ? "text-emerald-400" : "text-emerald-600"} size={20} />
                        <span className={isDarkMode ? "text-white" : "text-gray-900"}>Direction</span>
                      </div>
                      <span className={`font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
                        {Math.round(deviceDirection)}°
                      </span>
                    </div>
                    <div className={`flex items-center justify-between p-4 rounded-xl backdrop-blur-sm ${isDarkMode ? 'bg-white/5' : 'bg-gray-50/80'}`}>
                      <div className="flex items-center gap-3">
                        <Compass className={isDarkMode ? "text-emerald-400" : "text-emerald-600"} size={20} />
                        <span className={isDarkMode ? "text-white" : "text-gray-900"}>Qibla Angle</span>
                      </div>
                      <span className={`font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
                        {qiblaDirection ? `${Math.round(qiblaDirection)}°` : 'Calculating...'}
                      </span>
                    </div>
                    <div className={`flex items-center justify-between p-4 rounded-xl backdrop-blur-sm ${isDarkMode ? 'bg-white/5' : 'bg-gray-50/80'}`}>
                      <div className="flex items-center gap-3">
                        <MapPin className={isDarkMode ? "text-emerald-400" : "text-emerald-600"} size={20} />
                        <span className={isDarkMode ? "text-white" : "text-gray-900"}>Location</span>
                      </div>
                      <span className={`font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
                        {location ? `${location.latitude.toFixed(4)}°, ${location.longitude.toFixed(4)}°` : 'Locating...'}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {alignmentStatus !== 'none' && (
        <div className={`flex items-center justify-center p-4 rounded-xl backdrop-blur-sm ${getAlignmentStyles().bg} transition-all duration-300`}>
          <div className="flex items-center gap-2">
            <Kaaba01Icon className={`w-5 h-5 ${getAlignmentStyles().text}`} />
            <span className={getAlignmentStyles().text}>
              {getAlignmentMessage()}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default QiblaFinder;