import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSettings } from '../contexts/SettingsContext';
import { useDarkMode } from '../contexts/DarkModeContext';
import { ErrorBoundary } from 'react-error-boundary';
import MobileLayout from './dashboard/MobileLayout';
import DesktopLayout from './dashboard/DesktopLayout';
import LoadingAnimation from './LoadingAnimation';
import WelcomeModal from './dashboard/WelcomeModal';
import { getRandomGreeting } from '../data/greetings';
import { fetchDashboardData } from '../services/dashboardService';
import { useLocation } from '../contexts/LocationContext';

const getDeviceType = () => {
  const width = window.innerWidth;
  if (width < 768) return 'mobile';
  if (width < 1024) return 'tablet';
  return 'desktop';
};

const ErrorFallback = ({ error }) => (
  <div className="text-center p-4">
    <h2 className="text-xl font-medium mb-2">Something went wrong</h2>
    <p className="text-sm text-gray-600">{error.message}</p>
  </div>
);

const MemoizedMobileLayout = memo(MobileLayout);
const MemoizedDesktopLayout = memo(DesktopLayout);
const MemoizedWelcomeModal = memo(WelcomeModal);

const Dashboard = () => {
  const { currentUser } = useAuth();
  const { settings, updateSettings, isInitialized } = useSettings();
  const { isDarkMode } = useDarkMode();
  const { location, error: locationError } = useLocation();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    dateTime: null,
    prayerTimes: null,
    duas: [],
    hadiths: [],
    adhkarCompleted: {},
    currentDuaIndex: 0,
    currentHadithIndex: 0
  });
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [greeting] = useState(getRandomGreeting());
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const isLoadingRef = useRef(false);
  const dataTimestampRef = useRef(null);
  const containerRef = useRef(null);

  // Reset scroll position to top when component mounts (mobile only)
  useEffect(() => {
    if (deviceType === 'mobile') {
      console.log('📱 Dashboard: Resetting scroll position to top');
      
      // Function to reset all scroll positions
      const resetAllScrollPositions = () => {
        // Reset window scroll
        window.scrollTo(0, 0);
        
        // Reset container scroll if ref is available
        if (containerRef.current) {
          containerRef.current.scrollTop = 0;
        }
        
        // Reset any scroll containers
        const scrollContainers = document.querySelectorAll('.overflow-auto, .overflow-y-auto, .overflow-scroll, .overflow-y-scroll');
        scrollContainers.forEach(container => {
          if (container.scrollTop > 0) {
            container.scrollTop = 0;
          }
        });
      };
      
      // Initial reset
      resetAllScrollPositions();
      
      // Set up a MutationObserver to detect when content is loaded and reset scroll again
      let observer;
      if (containerRef.current) {
        observer = new MutationObserver((mutations) => {
          // Check if mutations include content being added
          const hasContentChanges = mutations.some(mutation => 
            mutation.type === 'childList' && mutation.addedNodes.length > 0
          );
          
          if (hasContentChanges) {
            console.log('📱 Dashboard: Content changed, resetting scroll position');
            resetAllScrollPositions();
          }
        });
        
        observer.observe(containerRef.current, {
          childList: true,
          subtree: true
        });
      }
      
      // For some browsers/devices, we need a slight delay to ensure the scroll reset works
      const timeoutId = setTimeout(() => {
        resetAllScrollPositions();
      }, 100);
      
      // Also reset after images and other resources load
      const loadHandler = () => {
        resetAllScrollPositions();
      };
      
      window.addEventListener('load', loadHandler);
      
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('load', loadHandler);
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [deviceType]);

  const handleDashboardUpdate = useCallback((newData) => {
    console.log('[Dashboard] handleDashboardUpdate called with:', newData);
    
    // Check if we're updating currentDuaIndex or currentHadithIndex
    if (newData.currentDuaIndex !== undefined || newData.currentHadithIndex !== undefined) {
      console.log('[Dashboard] Updating navigation indices:', {
        currentDuaIndex: newData.currentDuaIndex,
        currentHadithIndex: newData.currentHadithIndex
      });
    }
    
    setDashboardData(prevData => {
      const updatedData = {
        ...prevData,
        ...newData
      };
      console.log('[Dashboard] Updated dashboardData:', updatedData);
      return updatedData;
    });
    
    // If we have the minimum required data, stop showing loading
    if (newData.dateTime && newData.prayerTimes) {
      setLoading(false);
    }
  }, []);

  const loadData = useCallback(async (force = false) => {
    if (!currentUser || (!force && isLoadingRef.current)) {
      return;
    }

    try {
      isLoadingRef.current = true;
      setLoading(true);
      
      await fetchDashboardData(currentUser, settings, handleDashboardUpdate);
      dataTimestampRef.current = Date.now();
    } catch (error) {
      console.error("Error loading dashboard data:", error);
      setLoading(false);
    } finally {
      isLoadingRef.current = false;
    }
  }, [currentUser, settings, handleDashboardUpdate]);

  // Initial data load
  useEffect(() => {
    if (currentUser && isInitialized) {
      loadData(true);
    }
  }, [currentUser, isInitialized, loadData]);

  // Refresh data periodically (every 5 minutes)
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentUser && isInitialized) {
        loadData(true);
      }
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(interval);
  }, [currentUser, isInitialized, loadData]);

  // Refresh data when tab becomes visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Only reload if data is older than 1 minute
        const dataAge = Date.now() - (dataTimestampRef.current || 0);
        if (dataAge > 60 * 1000) {
          loadData(true);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [loadData]);

  useEffect(() => {
    return () => {
      isLoadingRef.current = false;
    };
  }, []);

  const handleResize = useCallback(() => {
    setDeviceType(getDeviceType());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (currentUser && isInitialized && !settings.welcomeShownAt) {
      setShowWelcomeModal(true);
      updateSettings({
        hasSeenWelcome: true,
        welcomeShownAt: new Date().toISOString()
      });
    }
  }, [currentUser, settings.welcomeShownAt, updateSettings, isInitialized]);

  const layoutProps = useMemo(() => ({
    currentUser,
    settings,
    isDarkMode,
    dashboardData,
    greeting,
    setDashboardData: handleDashboardUpdate,
    isLoading: loading || !isInitialized,
    location,
    locationError
  }), [currentUser, settings, isDarkMode, dashboardData, greeting, loading, handleDashboardUpdate, isInitialized, location, locationError]);

  // Always render the layout with skeletons when loading
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div 
        ref={containerRef}
        className={`flex-1 overflow-auto transition-colors duration-200 ${
          isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
        }`}
      >
        {deviceType === 'mobile' ? (
          <MemoizedMobileLayout {...layoutProps} />
        ) : (
          <MemoizedDesktopLayout {...layoutProps} />
        )}
        <MemoizedWelcomeModal 
          show={showWelcomeModal}
          onClose={() => setShowWelcomeModal(false)}
          isDarkMode={isDarkMode}
        />
      </div>
    </ErrorBoundary>
  );
};

export default memo(Dashboard);
