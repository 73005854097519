import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';

const ErrorDisplay = ({ error }) => {
  const { isDarkMode } = useDarkMode();
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50">
      <div className={`p-8 rounded-2xl text-center ${
        isDarkMode ? 'bg-[#111111] text-white' : 'bg-white text-gray-900'
      }`}>
        <h2 className="text-2xl font-bold mb-4 text-red-600">Error</h2>
        <p className="mb-6">{error}</p>
      </div>
    </div>
  );
};

export default ErrorDisplay; 