import React from 'react';
import { Sidebar, Progress } from 'flowbite-react';
import Select from 'react-select';

const QuranSidebar = ({
  isDarkMode,
  isMobile,
  sidebarOpen,
  customStyles,
  surahOptions,
  handleSurahChange,
  selectedSurah,
  reciterOptions,
  handleReciterChange,
  selectedReciter,
  languageOptions,
  handleLanguageChange,
  selectedLanguage,
  translationOptions,
  handleTranslationChange,
  selectedTranslation,
  isReadingMode,
  setIsReadingMode,
  showTransliteration,
  handleTransliterationToggle,
  memorizedPercentage,
  memorizedCount,
  totalVerses,
  displayMode,
  setDisplayMode,
}) => {
  return (
    <div className={`fixed inset-y-0 left-0 z-30 ${
      isMobile ? 'w-full' : 'quran-sidebar w-[350px]'
    } ${
      isDarkMode ? 'bg-[#111111]' : 'bg-white'
    } transition-transform duration-300 ease-in-out transform ${
      sidebarOpen ? 'translate-x-0' : '-translate-x-full'
    } lg:translate-x-0`}>
      <div className={`h-full flex flex-col ${
        isMobile ? 'pb-40 pt-[env(safe-area-inset-top)]' : 'pb-20 pt-16'
      } overflow-y-auto ${
        isDarkMode ? 'bg-[#111111] border-r border-gray-800' : 'bg-white border-r border-gray-200'
      }`}>
        <Sidebar aria-label="Quran Explorer Sidebar" className={`quran-sidebar ${
          isDarkMode ? 'bg-[#111111]' : 'bg-white'
        }`}>
          <Sidebar.Items className={`${isDarkMode ? 'bg-[#111111]' : 'bg-white'} ${
            isMobile ? 'w-full px-6' : 'max-w-sm mx-auto w-full px-4'
          }`}>
            <Sidebar.ItemGroup className="border-none space-y-4">
              <div className="p-2">
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Surah</label>
                <Select 
                  options={surahOptions}
                  onChange={handleSurahChange}
                  value={surahOptions.find(option => option.value === selectedSurah)}
                  styles={customStyles}
                  placeholder="Select Surah"
                />
              </div>
              <div className="p-2">
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Reciter</label>
                <Select 
                  options={reciterOptions}
                  onChange={handleReciterChange}
                  value={reciterOptions.find(option => option.value === selectedReciter)}
                  styles={customStyles}
                  placeholder="Select Reciter"
                />
              </div>
              <div className="p-2">
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Language</label>
                <Select 
                  options={languageOptions}
                  onChange={handleLanguageChange}
                  value={languageOptions.find(option => option.value === selectedLanguage)}
                  styles={customStyles}
                  placeholder="Select Language"
                />
              </div>
              <div className="p-2">
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Translation</label>
                <Select 
                  options={translationOptions}
                  onChange={handleTranslationChange}
                  value={translationOptions.find(option => option.value === selectedTranslation)}
                  styles={customStyles}
                  placeholder="Select Translation"
                />
              </div>
              <div className="p-2">
                <div className={`flex items-center justify-between py-3 rounded-lg`}>
                  <span className={`text-sm font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Arabic Only</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={displayMode === 'arabic-only'}
                      onChange={() => setDisplayMode(displayMode === 'arabic-only' ? 'with-translation' : 'arabic-only')}
                      className="sr-only peer"
                    />
                    <div className={`w-11 h-6 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                    } peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer ${
                      isDarkMode ? 'dark:border-gray-800' : 'dark:border-gray-600'
                    } peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600`}></div>
                  </label>
                </div>
              </div>
              <div className="p-2">
                <div className={`flex items-center justify-between py-3 rounded-lg`}>
                  <span className={`text-sm font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>Show Transliteration</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={showTransliteration}
                      onChange={handleTransliterationToggle}
                      className="sr-only peer"
                    />
                    <div className={`w-11 h-6 ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                    } peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer ${
                      isDarkMode ? 'dark:border-gray-800' : 'dark:border-gray-600'
                    } peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600`}></div>
                  </label>
                </div>
              </div>
              <div className="p-2">
                <label className={`block text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Memorization Progress</label>
                <Progress 
                  progress={memorizedPercentage} 
                  size="lg" 
                  color="green"
                  className={`${isDarkMode ? 'bg-[#1A1A1A]' : 'bg-white bg-opacity-20'}`}
                />
                <p className={`text-center mt-2 text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {memorizedCount}/{totalVerses} verses ({memorizedPercentage.toFixed(2)}%)
                </p>
              </div>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
    </div>
  );
};

export default QuranSidebar;
