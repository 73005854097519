import React from 'react';
import DateTimeCard from './DateTimeCard';
import PrayerTimesCard from './PrayerTimesCard';
import DailyDuaCard from './DailyDuaCard';
import DailyHadithCard from './DailyHadithCard';
import QuranProgressCard from './QuranProgressCard';
import AdhkarTrackerCard from './AdhkarTrackerCard';
import GoalsCard from './GoalsCard';

const DesktopLayout = ({ 
  currentUser, 
  settings, 
  isDarkMode, 
  dashboardData, 
  greeting, 
  setDashboardData,
  isLoading
}) => {
  const handleToggleAdhkar = (prayer) => {
    setDashboardData(prevData => ({
      ...prevData,
      adhkarCompleted: {
        ...prevData.adhkarCompleted,
        [prayer]: !prevData.adhkarCompleted[prayer]
      }
    }));
  };

  const handleNextDua = () => {
    setDashboardData(prevData => ({
      ...prevData,
      currentDuaIndex: (prevData.currentDuaIndex + 1) % prevData.duas.length
    }));
  };

  const handlePreviousDua = () => {
    setDashboardData(prevData => ({
      ...prevData,
      currentDuaIndex: (prevData.currentDuaIndex - 1 + prevData.duas.length) % prevData.duas.length
    }));
  };

  const handleNextHadith = () => {
    setDashboardData(prevData => ({
      ...prevData,
      currentHadithIndex: (prevData.currentHadithIndex + 1) % prevData.hadiths.length
    }));
  };

  const handlePreviousHadith = () => {
    setDashboardData(prevData => ({
      ...prevData,
      currentHadithIndex: (prevData.currentHadithIndex - 1 + prevData.hadiths.length) % prevData.hadiths.length
    }));
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Welcome Section with DateTime */}
      <div className="pt-4 pb-8">
        <div className={`bg-gradient-to-br backdrop-blur-sm p-8 rounded-[32px] border transition-all duration-200 ${
          isDarkMode 
            ? 'from-emerald-500/10 via-emerald-500/5 to-transparent border-emerald-500/10 shadow-lg shadow-emerald-500/5' 
            : 'from-emerald-500/10 via-emerald-500/5 to-transparent border-emerald-500/10 shadow-sm'
        }`}>
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start">
            <div className="w-full lg:w-auto text-left lg:text-right order-1 lg:order-none mb-8 lg:mb-0">
              <h1 className={`text-lg lg:text-2xl font-medium transition-colors duration-200 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {currentUser.displayName}    ٱلسَّلَامُ عَلَيْكمْ
              </h1>
              <p className={`mt-2 text-xs lg:text-sm transition-colors duration-200 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {greeting}
              </p>
            </div>
            
            <div className="w-full lg:w-auto order-2 lg:order-none">
              {settings.dashboardItems.dateTime && (
                <DateTimeCard 
                  dateTime={dashboardData.dateTime} 
                  prayerTimes={dashboardData.prayerTimes}
                  isLoading={isLoading}
                  className="space-y-1"
                  timeClassName="text-4xl font-medium text-white"
                  untilClassName="text-md text-gray-300"
                  dateClassName="text-sm text-white/80"
                  dividerClassName="text-white/40"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="space-y-8">
        {/* Prayer Times, Quran Progress, and Goals Section */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {settings.dashboardItems.prayerTimes && (
            <div className={`transition-all duration-200 rounded-[24px] p-6 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5' 
                : 'bg-white shadow-sm'
            }`}>
              <PrayerTimesCard 
                prayerTimes={dashboardData.prayerTimes} 
                error={dashboardData.prayerTimesError}
                isLoading={isLoading}
              />
            </div>
          )}
          
          {settings.dashboardItems.quranTracker && (
            <div className={`transition-all duration-200 rounded-[24px] p-6 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5' 
                : 'bg-white shadow-sm'
            }`}>
              <QuranProgressCard isLoading={isLoading} />
            </div>
          )}

          {settings.dashboardItems.goals && (
            <div className={`transition-all duration-200 rounded-[24px] p-6 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5' 
                : 'bg-white shadow-sm'
            }`}>
              <GoalsCard goals={dashboardData.goals} isLoading={isLoading} />
            </div>
          )}
        </div>

        {/* Daily Guidance Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {settings.dashboardItems.dailyDua && dashboardData.duas.length > 0 && (
            <div className={`transition-all duration-200 rounded-[24px] p-6 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5' 
                : 'bg-white shadow-sm'
            }`}>
              <DailyDuaCard 
                dailyDua={dashboardData.duas[dashboardData.currentDuaIndex]} 
                onNext={handleNextDua}
                onPrevious={handlePreviousDua}
                isLoading={isLoading}
              />
            </div>
          )}
          
          {settings.dashboardItems.dailyHadith && dashboardData.hadiths.length > 0 && (
            <div className={`transition-all duration-200 rounded-[24px] p-6 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5' 
                : 'bg-white shadow-sm'
            }`}>
              <DailyHadithCard 
                dailyHadith={dashboardData.hadiths[dashboardData.currentHadithIndex]} 
                onNext={handleNextHadith}
                onPrevious={handlePreviousHadith}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>

        {/* Additional Features Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-8">
          {settings.dashboardItems.adhkarTracker && (
            <div className={`transition-all duration-200 rounded-[24px] p-6 ${
              isDarkMode 
                ? 'bg-[#111111] shadow-lg shadow-black/5' 
                : 'bg-white shadow-sm'
            }`}>
              <AdhkarTrackerCard 
                adhkarCompleted={dashboardData.adhkarCompleted} 
                onToggleAdhkar={handleToggleAdhkar}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopLayout; 