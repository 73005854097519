import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const ModernDramaticTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Handle background image slideshow
  useEffect(() => {
    if (!settings.simpleDashboard?.backgroundImages?.length) return;
    
    const interval = setInterval(() => {
      setPreviousBackgroundIndex(currentBackgroundIndex);
      setCurrentBackgroundIndex(prev => 
        (prev + 1) % settings.simpleDashboard.backgroundImages.length
      );
    }, (settings.simpleDashboard.slideshowDuration || 60) * 1000);

    return () => clearInterval(interval);
  }, [currentBackgroundIndex, settings.simpleDashboard?.backgroundImages, settings.simpleDashboard?.slideshowDuration]);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Images */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Gradient Overlays */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/40 to-black/90 z-10" />

      {/* Main Content Container */}
      <div className="relative h-full flex flex-col z-20">
        {/* Main Content Area */}
        <div className="flex-1 flex">
          {/* Left Side - Location, Time and Countdown */}
          <div className="w-2/3 p-4 flex flex-col">
            {/* Location */}
            <div className="mb-4">
              <h1 className="text-2xl sm:text-3xl xl:text-4xl font-light">{settings.location}</h1>
              <p className="text-white/60 mt-1">{settings.region} {settings.country}</p>
            </div>

            {/* Current Time */}
            <div className="mb-8 xl:mb-16">
              <p className="text-white/40 text-sm tracking-widest uppercase mb-2 xl:mb-4">Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl xl:text-[14rem] font-thin tracking-tight leading-none">
                    {time}
                  </div>
                )}
              />
            </div>

            {/* Countdown */}
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div className="mt-auto">
                  <p className="text-white/40 text-sm tracking-widest uppercase mb-2 xl:mb-4">
                    Time Until {nextPrayer}
                  </p>
                  <div className={`text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-[8rem] font-thin tracking-tight leading-none ${accentColor.base}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />
          </div>

          {/* Prayer Times */}
          <div className="w-1/3 p-4">
            <div className="bg-black/30 backdrop-blur-md rounded-2xl xl:rounded-3xl p-6 sm:p-8 xl:p-12">
              <div className="grid grid-cols-2 tall:grid-cols-1 gap-3 tall:gap-6">
                <SimplePrayerTimesCard
                  prayerTimes={prayerTimes}
                  settings={settings}
                  render={({ prayers }) => (
                    <>
                      {prayers.map(([prayer, time], index) => {
                        const Icon = prayer === 'Fajr' ? Sunrise :
                                   prayer === 'Maghrib' ? Sunset :
                                   prayer === 'Isha' ? Moon : Sun;
                        
                        const isNext = prayer === nextPrayer;
                        
                        const colSpanClass = isNext ? 'col-span-2 tall:col-span-1' : 'col-span-1';
                        const order = isNext ? 'order-first tall:order-none' : 'order-none';
                        
                        return (
                          <div 
                            key={prayer}
                            className={`${colSpanClass} ${order} px-3 sm:px-4 xl:px-6 py-2 sm:py-3 xl:py-4 rounded-xl xl:rounded-2xl ${
                              isNext ? `bg-white/10 border ${accentColor.border}` : 'bg-black/20'
                            }`}
                          >
                            <div className="flex items-center justify-end gap-2 mb-1 xl:mb-2">
                              <p className={`${isNext ? accentColor.base : 'text-white'} text-xs xl:text-sm tracking-widest uppercase`}>
                                {prayer}
                              </p>
                              <Icon className={`w-4 h-4 xl:w-5 xl:h-5 ${isNext ? accentColor.base : 'text-white'}`} />
                            </div>
                            <p className={`text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-light text-right ${isNext ? accentColor.base : 'text-white'}`}>
                              {time}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="bg-black/30 backdrop-blur-md border-t border-white/10">
          <div className="grid grid-cols-3 px-6 sm:px-8 lg:px-8 xl:px-12 py-4 sm:py-6 xl:py-8">
            <SimpleDateCard 
              dateTime={dateTime}
              onDateChange={onDateChange}
              render={({ gregorian, hijri }) => (
                <>
                  <div>
                    <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-1">Gregorian</p>
                    <p className="text-base sm:text-lg xl:text-xl">{gregorian}</p>
                  </div>
                  <div>
                    <p className="text-white/40 text-xs sm:text-sm tracking-widest uppercase mb-1">Hijri</p>
                    <p className="text-base sm:text-lg xl:text-xl">{hijri}</p>
                  </div>
                </>
              )}
            />
            <div className="text-right">
              <p className="text-xl sm:text-2xl xl:text-3xl text-white/80 leading-relaxed font-uthmani">
                {duas?.[currentDuaIndex]?.arabic}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModernDramaticTemplate; 