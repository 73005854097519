import { auth } from '../firebase';
import { 
  signInWithCustomToken as firebaseSignIn,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { getDeviceId } from '../utils/deviceIdentifier';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Function to check if native bridge is available and initialized
const isNativeBridgeAvailable = () => {
  return Boolean(window.isUmmahtiNative && window.ummahti && window.ummahti.signInWithGoogle);
};

export const getDeviceAuthToken = async () => {
  try {
    const deviceId = await getDeviceId();
    
    const response = await fetch(`${API_BASE_URL}/tv/auth-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ device_id: deviceId })
    });

    if (!response.ok) {
      throw new Error('Device not authorized');
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error('Error getting device auth token:', error);
    throw error;
  }
};

export const signInWithCustomToken = async (token) => {
  try {
    await firebaseSignIn(auth, token);
  } catch (error) {
    console.error('Error signing in with custom token:', error);
    throw error;
  }
};

export const signInWithGoogle = async () => {
  try {
    // Double-check native bridge availability
    if (isNativeBridgeAvailable()) {
      console.log('Using native Google Sign-In...');
      try {
        // Use native Google Sign-In
        const authData = await window.ummahti.signInWithGoogle();
        console.log('Received auth data from native:', authData);

        // Validate required fields
        if (!authData.idToken) {
          throw new Error('No ID token received from native Google Sign-In');
        }

        // Create the credential using ID token and access token (if available)
        const credential = authData.accessToken
          ? GoogleAuthProvider.credential(authData.idToken, authData.accessToken)
          : GoogleAuthProvider.credential(authData.idToken);

        // Sign in with the credential
        const userCredential = await signInWithCredential(auth, credential);
        
        // Log success with user info
        console.log('Successfully signed in with native Google Sign-In:', {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
          photoURL: userCredential.user.photoURL
        });

        return {
          user: userCredential.user,
          additionalUserInfo: {
            profile: {
              email: authData.email,
              name: authData.displayName,
              picture: authData.photoURL
            },
            providerId: 'google.com'
          }
        };
      } catch (nativeError) {
        console.error('Native Google Sign-In failed:', nativeError);
        throw nativeError;
      }
    } else {
      console.log('Using web-based Google Sign-In...');
      // Fall back to web-based Google Sign-In
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      console.log('Successfully signed in with web Google Sign-In:', {
        uid: result.user.uid,
        email: result.user.email,
        displayName: result.user.displayName,
        photoURL: result.user.photoURL
      });
      return result;
    }
  } catch (error) {
    console.error('Error during Google Sign-In:', error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    // Sign out from Firebase
    await firebaseSignOut(auth);
    
    // If in native app, also trigger native sign out
    if (isNativeBridgeAvailable() && window.ummahti.signOut) {
      await window.ummahti.signOut();
    }
    
    console.log('Successfully signed out');
  } catch (error) {
    console.error('Error during sign out:', error);
    throw error;
  }
}; 