import React, { useState, useRef, useEffect } from 'react';
import { HiChevronDown, HiX } from 'react-icons/hi';
import { TbPlayerTrackNext, TbPlayerTrackPrev, TbPlayerPlay, TbPlayerPause } from "react-icons/tb";
import { Play, Pause, SkipBack, SkipForward } from 'lucide-react';
import { useDarkMode } from '../../../contexts/DarkModeContext';

const slideUpAnimation = "animate-slide-up";
const slideDownAnimation = "animate-slide-down";

const MobilePlayer = ({
  surahContent,
  currentVerse,
  selectedSurah,
  surahs,
  isPlaying,
  togglePlay,
  setCurrentVerse,
  footnotes,
  setActiveFootnote,
  activeFootnote,
  isDarkMode,
  selectedTranslation,
  audioDuration,
  audioProgress
}) => {
  const [isPlayerExpanded, setIsPlayerExpanded] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const userScrollTimeoutRef = useRef(null);

  // Handle play button click with error handling
  const handlePlayClick = (e) => {
    e.preventDefault(); // Prevent default
    e.stopPropagation(); // Prevent event bubbling
    
    // Add a small delay to ensure the event is fully handled
    setTimeout(() => {
      try {
        togglePlay();
      } catch (error) {
        console.error('Error toggling play:', error);
      }
    }, 0);
  };

  // Handle navigation clicks
  const handlePrevVerse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentVerse(prev => Math.max(0, prev - 1));
  };

  const handleNextVerse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentVerse(prev => Math.min((surahContent?.length || 1) - 1, prev + 1));
  };

  // Reset scroll position when verse changes or player expands
  useEffect(() => {
    setScrollPosition(0);
    setIsUserScrolling(false);
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentVerse, isPlayerExpanded]);

  // Handle scroll based on audio progress
  useEffect(() => {
    if (isPlaying && isPlayerExpanded && contentRef.current && !isUserScrolling) {
      const content = contentRef.current;
      const totalScrollHeight = content.scrollHeight - content.clientHeight;
      
      // Calculate scroll position based on audio progress
      const newScrollPosition = (audioProgress / 100) * totalScrollHeight;
      
      // Use requestAnimationFrame for smoother scrolling
      const smoothScroll = () => {
        const currentScroll = content.scrollTop;
        const diff = newScrollPosition - currentScroll;
        
        // If we're close enough, just set to final position
        if (Math.abs(diff) < 1) {
          content.scrollTop = newScrollPosition;
          return;
        }
        
        // Smooth step towards target
        const step = diff * 0.1;
        content.scrollTop = currentScroll + step;
        
        if (isPlaying && !isUserScrolling) {
          requestAnimationFrame(smoothScroll);
        }
      };
      
      requestAnimationFrame(smoothScroll);
    }
  }, [isPlaying, isPlayerExpanded, audioProgress, isUserScrolling]);

  // Handle user scroll interaction
  const handleScroll = () => {
    if (!isUserScrolling) {
      setIsUserScrolling(true);
    }

    // Clear existing timeout
    if (userScrollTimeoutRef.current) {
      clearTimeout(userScrollTimeoutRef.current);
    }

    // Reset user scrolling flag after 2 seconds of no scroll activity
    userScrollTimeoutRef.current = setTimeout(() => {
      setIsUserScrolling(false);
    }, 2000);
  };

  // Clean up timeouts
  useEffect(() => {
    return () => {
      if (userScrollTimeoutRef.current) {
        clearTimeout(userScrollTimeoutRef.current);
      }
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setIsPlayerExpanded(false);
    }, 300);
  };

  if (!surahContent) return null;

  return (
    <>
      {!isPlayerExpanded && (
        <div 
          className={`fixed left-0 right-0 backdrop-blur-xl px-6 py-2 z-40 cursor-pointer transform transition-all duration-300 ${
            isDarkMode
              ? 'bg-black/25 border-t border-white/10'
              : 'bg-white/70 border-t border-emerald-100'
          } ${slideUpAnimation}`}
          style={{
            bottom: window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches
              ? 'calc(env(safe-area-inset-bottom) + 3.2rem)'
              : navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')
                ? 'calc(env(safe-area-inset-bottom) + 50px)'
                : '4rem'
          }}
          onClick={(e) => {
            e.preventDefault();
            setIsPlayerExpanded(true);
          }}
        >
          <div className="flex items-center justify-between px-2">
            <div className="flex items-center space-x-3 flex-1 min-w-0">
              <button 
                className="text-green-600 dark:text-green-400 p-2" 
                onClick={handlePlayClick}
              >
                {isPlaying ? 
                  <TbPlayerPause className="h-6 w-6" /> : 
                  <TbPlayerPlay className="h-6 w-6" />
                }
              </button>
              <div className="truncate">
                <div className="text-sm font-medium truncate dark:text-white">
                  {surahs.find(s => s.id.toString() === selectedSurah)?.name_simple || 'No surah selected'}
                </div>
                <div className="text-xs text-gray-500 dark:text-gray-400 truncate">
                  {surahContent && surahContent[currentVerse] ? `Verse ${surahContent[currentVerse].verse_key.split(':')[1]}` : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPlayerExpanded && (
        <div 
          className={`fixed inset-0 z-[60] flex flex-col pb-[calc(4.5rem_+_env(safe-area-inset-bottom))] transform transition-all duration-300 ${
            isClosing ? slideDownAnimation : slideUpAnimation
          } ${isDarkMode ? 'bg-gray-950' : 'bg-emerald-700'}`}
        >
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-b from-emerald-950 via-gray-950/50 to-gray-950' 
              : 'bg-gradient-to-b from-emerald-600 via-emerald-700/50 to-transparent'
          }`} />

          <div className="relative pt-[calc(env(safe-area-inset-top)_+_1rem)] px-4 pb-4 border-b border-white/10 flex items-center backdrop-blur-sm bg-white/5">
            <button 
              onClick={handleClose}
              className="text-gray-600 dark:text-gray-300 transition-transform duration-200 hover:scale-110 relative z-10"
            >
              <HiChevronDown className="h-6 w-6" />
            </button>
            <div className="absolute left-0 right-0 text-center z-[1]">
              <div className="text-sm font-medium dark:text-gray-300">Now Playing</div>
            </div>
          </div>
          
          <div className="flex-1 flex flex-col p-4 overflow-hidden animate-fade-in" ref={containerRef}>
            {selectedSurah && (
              <div className="flex flex-col items-center h-full">
                <div className="w-full flex flex-col items-center">
                  <div className="w-full flex justify-center -mt-8">
                    <img 
                      src={`/images/quran_chapters/Surah_${selectedSurah}_of_114${isDarkMode ? '_(modified)' : ''}.svg`}
                      alt={`Surah ${selectedSurah}`}
                      className="w-[55%] h-auto"
                    />
                  </div>
                  
                  <div className="text-center -mt-6">
                    <div className="text-xs text-gray-500 dark:text-gray-400 leading-tight">
                      Surah {selectedSurah}
                    </div>
                    <div className="text-sm font-medium text-gray-700 dark:text-gray-200 leading-tight">
                      {surahs.find(s => s.id.toString() === selectedSurah)?.name_simple}
                    </div>
                  </div>
                </div>

                <div className="flex-1 w-full flex flex-col mt-12 overflow-hidden">
                  {surahContent && surahContent[currentVerse] && (
                    <div 
                      ref={contentRef}
                      className="w-full h-full space-y-4 text-center overflow-y-auto scrollbar-none"
                      style={{
                        maskImage: 'linear-gradient(to bottom, transparent, black 10%, black 90%, transparent)',
                        WebkitMaskImage: 'linear-gradient(to bottom, transparent, black 10%, black 90%, transparent)'
                      }}
                      onScroll={handleScroll}
                    >
                      <div className="px-4 py-8">
                        <p 
                          key={`arabic-${currentVerse}`} 
                          className="text-2xl leading-loose font-arabic text-gray-900 dark:text-white transition-opacity duration-300 ease-in-out animate-fade-in" 
                          dir="rtl"
                        >
                          {surahContent[currentVerse].text_uthmani}
                        </p>

                        <div 
                          key={`translation-${currentVerse}`}
                          className="text-base text-gray-600 dark:text-gray-300 px-4 mt-4 transition-opacity duration-300 ease-in-out animate-fade-in"
                        >
                          {surahContent[currentVerse].translations.find(t => 
                            t.resource_id.toString() === selectedTranslation
                          )?.text.split(/<sup.*?<\/sup>/).map((part, i, arr) => (
                            <React.Fragment key={i}>
                              {part}
                              {i < arr.length - 1 && (() => {
                                const footnoteMatch = surahContent[currentVerse].translations
                                  .find(t => t.resource_id.toString() === selectedTranslation)
                                  ?.text
                                  .split(/<sup.*?<\/sup>/)[i + 1]
                                  .match(/<sup foot_note=(\d+)>(\d+)<\/sup>/);
                                if (footnoteMatch) {
                                  const footnoteId = footnoteMatch[1];
                                  return (
                                    <sup
                                      className="cursor-pointer text-green-600 dark:text-green-400 ml-[1px]"
                                      onClick={() => setActiveFootnote(
                                        activeFootnote === footnoteId ? null : footnoteId
                                      )}
                                    >
                                      [{footnoteMatch[2]}]
                                    </sup>
                                  );
                                }
                                return null;
                              })()}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="h-48" />
                      </div>
                    </div>
                  )}
                </div>

                <div 
                  className={`fixed bottom-0 left-0 right-0 w-full backdrop-blur-xl ${
                    isDarkMode 
                      ? 'border-t border-white/5' 
                      : 'border-t border-white/20'
                  }`}
                  style={{
                    height: '120px'
                  }}
                >
                  <div className="flex items-center justify-center space-x-12 h-full">
                    <button 
                      className={`w-12 h-12 flex items-center justify-center transition-colors ${
                        isDarkMode
                          ? 'text-gray-400 hover:text-emerald-400'
                          : 'text-emerald-700/70 hover:text-emerald-600'
                      }`}
                      onClick={handlePrevVerse}
                    >
                      <SkipBack className="w-6 h-6" />
                    </button>

                    <button 
                      className={`w-16 h-16 flex items-center justify-center rounded-full backdrop-blur-sm border transition-all transform hover:scale-105 ${
                        isDarkMode
                          ? 'bg-emerald-950/50 border-emerald-400/10 text-emerald-400 hover:bg-emerald-900/30'
                          : 'bg-emerald-600/10 border-emerald-600/20 text-emerald-700 hover:bg-emerald-600/20'
                      }`}
                      style={{
                        boxShadow: isDarkMode 
                          ? '0 0 30px rgba(52, 211, 153, 0.1)'
                          : '0 0 30px rgba(16, 185, 129, 0.1)'
                      }}
                      onClick={handlePlayClick}
                    >
                      {isPlaying ? 
                        <Pause className="w-8 h-8" /> : 
                        <Play className="w-8 h-8 ml-1" />
                      }
                    </button>

                    <button 
                      className={`w-12 h-12 flex items-center justify-center transition-colors ${
                        isDarkMode
                          ? 'text-gray-400 hover:text-emerald-400'
                          : 'text-emerald-700/70 hover:text-emerald-600'
                      }`}
                      onClick={handleNextVerse}
                    >
                      <SkipForward className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(MobilePlayer);
