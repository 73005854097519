import React, { useEffect, useState, useRef, useCallback, memo, useMemo } from 'react';
import { Check, Trash2 } from 'lucide-react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Keep a cache of image orientations to avoid recalculating
const orientationCache = new Map();

// URL refresh cache to avoid refreshing the same URL repeatedly
const urlRefreshCache = new Map();
const URL_REFRESH_CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

// Debounce function to limit how often a function can be called
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

// Track loaded images to avoid excessive logging
const loadedImages = new Set();
let logCounter = 0;
const MAX_LOGS = 5; // Maximum number of load logs to show per session

const isImageSelected = (image, selectedImages) => {
  if (!image || !selectedImages || !Array.isArray(selectedImages)) {
    return false;
  }
  
  // Support both old and new image formats
  const imageIdentifier = image.isDefault 
    ? (image.url || image.original || image.display || image.path)
    : (image.url || image.original || image.display || image.path);
  
  // Check if the image is in the selected images array
  return selectedImages.some(selected => {
    // Handle different formats of selected items (could be string URLs or image objects)
    if (typeof selected === 'string') {
      return selected === imageIdentifier;
    } else if (selected && typeof selected === 'object') {
      return (selected.url || selected.original || selected.display || selected.path) === imageIdentifier;
    }
    return false;
  });
};

const getImageOrientation = async (imageSrc) => {
  // Check cache first
  if (orientationCache.has(imageSrc)) {
    return orientationCache.get(imageSrc);
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const isPortrait = img.height > img.width;
      const orientation = isPortrait ? 'portrait' : 'landscape';
      // Cache the result
      orientationCache.set(imageSrc, orientation);
      resolve(orientation);
    };
    img.onerror = () => {
      // Default to landscape if we can't determine orientation
      const orientation = 'landscape';
      orientationCache.set(imageSrc, orientation);
      resolve(orientation);
    };
    // Set crossOrigin to anonymous to avoid CORS issues when possible
    img.crossOrigin = 'anonymous';
    img.src = imageSrc;
  });
};

const OrientationDivider = memo(({ text, isDarkMode }) => (
  <div className="col-span-2 flex items-center gap-4 my-4">
    <div className={`h-px flex-1 ${isDarkMode ? 'bg-white/10' : 'bg-gray-200'}`} />
    <span className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
      {text}
    </span>
    <div className={`h-px flex-1 ${isDarkMode ? 'bg-white/10' : 'bg-gray-200'}`} />
  </div>
));

// Memoized Image component to prevent unnecessary re-renders
const GridImage = memo(({ 
  image, 
  index, 
  displayUrl, 
  fallbackUrl, 
  isSelected, 
  isPortrait, 
  onSelect, 
  onDelete, 
  refreshImageUrl,
  logImageLoad 
}) => {
  return (
    <div 
      key={`${image.path || image.url || index}-${index}`}
      className={`relative ${isPortrait ? 'aspect-[3/4]' : 'aspect-video'} rounded-xl overflow-hidden cursor-pointer ${
        isSelected ? 'ring-2 ring-emerald-500' : ''
      }`}
      onClick={() => onSelect(image)}
    >
      <img 
        src={displayUrl}
        alt={image.name || "Image"}
        className="w-full h-full object-cover"
        crossOrigin="anonymous"
        onLoad={() => {
          // Highly limited logging to avoid console flood
          logImageLoad(image.name || index, image.isDefault ? 'default' : 'user');
        }}
        onError={async (e) => {
          e.target.onerror = null; // Prevent infinite loop
          
          try {
            // First try refreshing the thumbnail URL
            if (displayUrl === image.thumbnail && displayUrl !== fallbackUrl) {
              const refreshedThumbnail = await refreshImageUrl(image.thumbnail);
              if (refreshedThumbnail && refreshedThumbnail !== displayUrl) {
                e.target.src = refreshedThumbnail;
                return;
              }
            }
            
            // If thumbnail refresh fails or we're already on the fallback,
            // try the original image URL
            const refreshedOriginal = await refreshImageUrl(image.url || image.original || image.display || image.path);
            e.target.src = refreshedOriginal || fallbackUrl;
          } catch (err) {
            // Use a placeholder as final fallback
            e.target.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9IiNlZWVlZWUiLz48dGV4dCB4PSI1MCUiIHk9IjUwJSIgZm9udC1mYW1pbHk9IkFyaWFsIiBmb250LXNpemU9IjE0IiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBmaWxsPSIjOTk5OTk5Ij5JbWFnZSBmYWlsZWQgdG8gbG9hZDwvdGV4dD48L3N2Zz4=';
          }
        }}
      />
      <div className="absolute inset-0 bg-black/0 hover:bg-black/20 transition-colors">
        <div className="absolute inset-0 flex items-center justify-center">
          {isSelected && (
            <div className="bg-emerald-500 rounded-full p-1">
              <Check className="h-5 w-5 text-white" />
            </div>
          )}
        </div>
      </div>
      
      {!image.isDefault && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (typeof onDelete === 'function') {
              onDelete(image);
            }
          }}
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
        >
          <Trash2 size={14} />
        </button>
      )}
    </div>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function for memoization
  return (
    prevProps.displayUrl === nextProps.displayUrl &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isPortrait === nextProps.isPortrait
  );
});

// Memoized ImageGrid component
const ImageGrid = memo(({ 
  images, 
  selectedImages, 
  onSelect, 
  onDelete, 
  sortedImages, 
  refreshImageUrl,
  refreshedUrls,
  imagesProcessed,
  logImageLoad 
}) => (
  <div className="grid grid-cols-2 gap-3">
    {images.map((image, index) => {
      // Support both old and new image formats for different types of images
      const imagePath = image.isDefault 
        ? (image.url || image.original || image.display)
        : (image.url || image.original || image.display);
      
      const thumbnailPath = image.isDefault 
        ? (image.thumbnail || image.url || image.original || image.display)
        : (image.thumbnail || image.url || image.original || image.display);
      
      // Only log rendering once per component mount
      if (image.isDefault && !imagesProcessed && index === 0) {
        console.log(`Rendering default images...`);
      }
        
      const isPortrait = sortedImages.portrait.includes(image);
      const isSelected = isImageSelected(image, selectedImages);

      // Check if we have refreshed URLs
      const displayUrl = refreshedUrls[thumbnailPath] || thumbnailPath;
      const fallbackUrl = refreshedUrls[imagePath] || imagePath;
      
      return (
        <GridImage
          key={`${image.path || image.url || index}-${index}`}
          image={image}
          index={index}
          displayUrl={displayUrl}
          fallbackUrl={fallbackUrl}
          isSelected={isSelected}
          isPortrait={isPortrait}
          onSelect={onSelect}
          onDelete={onDelete}
          refreshImageUrl={refreshImageUrl}
          logImageLoad={logImageLoad}
        />
      );
    })}
  </div>
));

export const OrientedImageGrid = memo(({ 
  images, 
  selectedImages, 
  onSelect,
  onDelete,
  setSelectedImages 
}) => {
  const { isDarkMode } = useDarkMode();
  const [sortedImages, setSortedImages] = useState({
    landscape: [],
    portrait: []
  });
  const [refreshedUrls, setRefreshedUrls] = useState({});
  const [imagesProcessed, setImagesProcessed] = useState(false);
  const prevImagesRef = useRef(null);
  const processedSetsRef = useRef(false);

  // Debug logging for incoming images - using debounce to limit frequency
  const logImagesInfo = useCallback(
    debounce((imageArray) => {
      // Only log the first time or when there's a significant change
      if (processedSetsRef.current) {
        return;
      }
      
      console.log('OrientedImageGrid received images:', imageArray?.length || 0);
      
      // Count default vs user images
      const defaultImagesCount = imageArray?.filter(img => img.isDefault)?.length || 0;
      const userImagesCount = imageArray?.filter(img => !img.isDefault)?.length || 0;
      
      console.log('Default images:', defaultImagesCount);
      console.log('User images:', userImagesCount);
      
      // Log sample images only once for debugging
      if (imageArray?.length > 0 && !imagesProcessed) {
        processedSetsRef.current = true;
        setImagesProcessed(true);
      }
    }, 500),
    [imagesProcessed]
  );

  useEffect(() => {
    if (images && images.length > 0) {
      logImagesInfo(images);
    }
  }, [images, logImagesInfo]);

  // Sort images by orientation when images array changes - but only if images have changed
  useEffect(() => {
    // Skip processing if the images array hasn't changed
    const currentImagesJSON = JSON.stringify(images?.map(img => img.path || img.url));
    const prevImagesJSON = JSON.stringify(prevImagesRef.current?.map(img => img.path || img.url));
    
    if (currentImagesJSON === prevImagesJSON) {
      return;
    }
    
    prevImagesRef.current = images;
    
    const sortImages = async () => {
      // Safety check for images array
      if (!images || !Array.isArray(images)) {
        console.error('Invalid images array:', images);
        setSortedImages({ landscape: [], portrait: [] });
        return;
      }
      
      const sorted = {
        landscape: [],
        portrait: []
      };

      // Filter out any images with undefined URLs first
      const validImages = images.filter(image => {
        if (!image) return false;
        
        // Support both old and new image formats
        const imageUrl = image.isDefault 
          ? (image.url || image.original || image.display)
          : (image.url || image.original || image.display);
        
        return !!imageUrl; // Only keep images with a valid URL
      });

      console.log('Valid images for sorting:', validImages.length);

      for (const image of validImages) {
        // Support both old and new image formats
        const imageUrl = image.isDefault 
          ? (image.url || image.original || image.display)
          : (image.url || image.original || image.display);
        
        try {
          const orientation = await getImageOrientation(imageUrl);
          sorted[orientation].push(image);
        } catch (error) {
          // Default to landscape if we can't determine orientation
          sorted.landscape.push(image);
        }
      }

      setSortedImages(sorted);
    };

    sortImages();
  }, [images]);

  // Function to refresh a Firebase Storage URL - wrapped in useCallback to maintain reference
  const refreshImageUrl = useCallback(async (path) => {
    if (!path) return null;
    
    // Check cache first to avoid unnecessary refreshes
    const now = Date.now();
    const cacheEntry = urlRefreshCache.get(path);
    if (cacheEntry && (now - cacheEntry.timestamp < URL_REFRESH_CACHE_EXPIRY)) {
      return cacheEntry.url || path;
    }
    
    // If already refreshed and cached, return that
    if (refreshedUrls[path]) {
      // Update cache timestamp
      urlRefreshCache.set(path, {
        url: refreshedUrls[path],
        timestamp: now
      });
      return refreshedUrls[path];
    }
    
    try {
      // Only attempt to refresh if this is a Firebase Storage path
      if (path.includes('gs://') || path.includes('firebase') || path.startsWith('/')) {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const freshUrl = await getDownloadURL(storageRef);
        
        // Store the refreshed URL
        setRefreshedUrls(prev => ({
          ...prev,
          [path]: freshUrl
        }));
        
        // Update cache
        urlRefreshCache.set(path, {
          url: freshUrl,
          timestamp: now
        });
        
        return freshUrl;
      }
      return path;
    } catch (error) {
      // Cache the failure too to avoid repeated failed requests
      urlRefreshCache.set(path, {
        url: path, // Use original as fallback
        timestamp: now,
        error: true
      });
      return path;
    }
  }, [refreshedUrls]);

  // Limited logging function for image loads
  const logImageLoad = useCallback((imageName, type) => {
    // Generate a unique key for this image
    const imageKey = `${type}_${imageName}`;
    
    // If we've already logged this image, or hit the maximum logs, don't log
    if (loadedImages.has(imageKey) || logCounter >= MAX_LOGS) {
      return;
    }
    
    // Otherwise, log and add to the set
    console.log(`Successfully loaded ${type} image:`, imageName);
    loadedImages.add(imageKey);
    logCounter++;
  }, []);

  // Memoize the rendering of each orientation section
  const landscapeSection = useMemo(() => {
    if (sortedImages.landscape.length === 0) return null;
    return (
      <>
        <OrientationDivider text="Landscape Images" isDarkMode={isDarkMode} />
        <ImageGrid 
          images={sortedImages.landscape}
          selectedImages={selectedImages}
          onSelect={onSelect}
          onDelete={onDelete}
          sortedImages={sortedImages}
          refreshImageUrl={refreshImageUrl}
          refreshedUrls={refreshedUrls}
          imagesProcessed={imagesProcessed}
          logImageLoad={logImageLoad}
        />
      </>
    );
  }, [sortedImages.landscape, selectedImages, onSelect, onDelete, isDarkMode, refreshImageUrl, refreshedUrls, imagesProcessed, logImageLoad]);

  const portraitSection = useMemo(() => {
    if (sortedImages.portrait.length === 0) return null;
    return (
      <>
        <OrientationDivider text="Portrait Images" isDarkMode={isDarkMode} />
        <ImageGrid 
          images={sortedImages.portrait}
          selectedImages={selectedImages}
          onSelect={onSelect}
          onDelete={onDelete}
          sortedImages={sortedImages}
          refreshImageUrl={refreshImageUrl}
          refreshedUrls={refreshedUrls}
          imagesProcessed={imagesProcessed}
          logImageLoad={logImageLoad}
        />
      </>
    );
  }, [sortedImages.portrait, selectedImages, onSelect, onDelete, isDarkMode, refreshImageUrl, refreshedUrls, imagesProcessed, logImageLoad]);

  const emptyStateMessage = useMemo(() => {
    if (sortedImages.landscape.length > 0 || sortedImages.portrait.length > 0) return null;
    return (
      <div className={`text-center py-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        No images available
      </div>
    );
  }, [sortedImages.landscape.length, sortedImages.portrait.length, isDarkMode]);

  return (
    <div>
      {landscapeSection}
      {portraitSection}
      {emptyStateMessage}
    </div>
  );
});

OrientedImageGrid.displayName = 'OrientedImageGrid'; 