import React from 'react';

const DashboardLoadingScreen = () => {
  return (
    <div className="fixed inset-0 bg-[#064E3B] flex items-center justify-center">
      <img 
        src="/logo_adhan.png"
        alt="Adhan Logo" 
        className="w-auto h-64 object-contain"
      />
    </div>
  );
};

export default DashboardLoadingScreen; 