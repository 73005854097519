import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, onSnapshot, deleteField } from 'firebase/firestore';
import { getDeviceId } from '../utils/deviceIdentifier';
import { getDeviceAuthToken, signInWithCustomToken } from '../services/authService';
import { migrateAdhanSettings } from '../utils/adhanUtils';

const SettingsContext = createContext();

const getSavedSettings = async () => {
  try {
    if (auth.currentUser) {
      // For logged in users, get settings from their user document
      const userSettingsRef = doc(db, 'userSettings', auth.currentUser.uid);
      const userSettingsDoc = await getDoc(userSettingsRef);
      if (userSettingsDoc.exists()) {
        return userSettingsDoc.data();
      }
    } else {
      // For device-linked users, get settings from device document
      const deviceId = await getDeviceId();
      if (deviceId) {
        const deviceSettingsRef = doc(db, 'deviceSettings', deviceId);
        const deviceSettingsDoc = await getDoc(deviceSettingsRef);
        if (deviceSettingsDoc.exists()) {
          return {
            ...deviceSettingsDoc.data(),
            tunedTimes: deviceSettingsDoc.data().tunedTimes || defaultSettings.tunedTimes
          };
        }
      }
    }

    // If no settings found, return default settings
    return {
      ...defaultSettings,
      tunedTimes: defaultSettings.tunedTimes
    };
  } catch (error) {
    console.error('Error getting saved settings:', error);
    return {
      ...defaultSettings,
      tunedTimes: defaultSettings.tunedTimes
    };
  }
};

export function useSettings() {
  return useContext(SettingsContext);
}

const defaultSettings = {
  location: null,
  coordinates: null,
  selectedAdhan: null,
  calculationMethod: 2,
  tunedTimes: {
    Fajr: 0,
    Dhuhr: 0,
    Asr: 0,
    Maghrib: 0,
    Isha: 0
  },
  hasSeenWelcome: false,
  dashboardItems: {
    dateTime: true,
    prayerTimes: true,
    dailyDua: true,
    dailyHadith: true,
    quranTracker: true,
    news: false,
    adhkarTracker: false,
    goals: true,
  },
  simpleDashboard: {
    theme: 'minimalist',
    dashboardColumns: 3,
    dashboardGap: 'gap-4',
    dashboardPadding: 'p-4',
    columnSections: {
      section1: {
        width: 3,
        backgroundImages: [],
        backgroundImage: null,
        backgroundColor: null,
        fullHeight: false,
        showBorder: true,
        components: [],
        slideshowEnabled: false,
        slideshowDuration: 60,
        overlayOpacity: 0.5,
      }
    },
    components: {
      date: {
        enabled: true,
        name: "Date",
        showTitle: true,
        showDate: true,
        showHijriDate: true,
        dateFontSize: 'text-lg',
        hijriDateFontSize: 'text-lg',
        dateAlign: 'text-center',
        hijriDateAlign: 'text-center',
      },
      prayerCountdown: {
        enabled: true,
        name: "Prayer Countdown",
        showNextPrayer: true,
        showCountdown: true,
        nextPrayerNameFontSize: 'text-xl',
        countdownFontSize: 'text-4xl',
        nextPrayerNameAlign: 'text-center',
        countdownAlign: 'text-center',
      },
      time: {
        enabled: true,
        name: "Time",
        showGreeting: false,
        timeFontSize: 'text-6xl',
        timeAlign: 'text-center',
        greetingFontSize: 'text-xl',
        greetingAlign: 'text-center',
      },
      prayerTimes: {
        enabled: true,
        name: "Prayer Times",
        showTitle: true,
        showPrayerNames: true,
        showPrayerTimes: true,
        showLocation: true,
        titleFontSize: 'text-xl',
        prayerNameFontSize: 'text-base',
        prayerTimeFontSize: 'text-base',
        locationFontSize: 'text-sm'
      },
      dailyDua: {
        enabled: true,
        name: "Daily Dua",
        showTitle: true,
        showArabic: true,
        showTranslation: true,
        showReference: true,
        showButtons: false,
        arabicFontSize: 'text-2xl',
        translationFontSize: 'text-lg',
        arabicAlign: 'text-right',
        translationAlign: 'text-left',
        referenceAlign: 'text-left',
        titleAlign: 'text-left'
      },
      dailyHadith: {
        enabled: true,
        name: "Daily Hadith",
        showTitle: true,
        showArabic: true,
        showTranslation: true,
        showSource: true,
        showButtons: false,
        arabicFontSize: 'text-2xl',
        translationFontSize: 'text-lg',
        arabicAlign: 'text-right',
        translationAlign: 'text-left',
        sourceAlign: 'text-left',
        titleAlign: 'text-left'
      },
      quranTracker: {
        enabled: true,
        name: "Quran Progress",
        width: 1
      },
      goals: {
        enabled: true,
        name: "Goals",
        width: 1
      },
      emptyColumn: {
        enabled: true,
        name: "Empty Column",
        width: 1
      }
    },
    order: [
      { type: 'component', id: 'time', enabled: true },
      { type: 'component', id: 'date', enabled: true },
      { type: 'component', id: 'prayerCountdown', enabled: true },
      { type: 'section', id: 'section1' },
      { type: 'component', id: 'emptyColumn' }
    ],
    templateId: 'adhanClock',
  },
  quran: {
    fontSize: 'text-xl',
    mode: 'reading',
    displayMode: 'with-translation',
    showTransliteration: false,
    selectedReciter: null,
    selectedLanguage: null,
    selectedTranslation: null,
    memorization: {},
    readingProgress: {
      currentSession: {
        surah: null,
        verse: null,
        timestamp: null
      },
      history: {}
    }
  },
};

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useState(defaultSettings);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [showNavigation, setShowNavigation] = useState(true);

  useEffect(() => {
    console.log('SettingsProvider initializing...');
    
    const unsubscribeAuth = auth.onAuthStateChanged(async user => {
      console.log('Auth state changed:', { 
        hasUser: !!user, 
        isWebUser: user && user.providerData.length > 0,
        isDeviceUser: user && user.providerData.length === 0 
      });
      
      if (user) {
        // User is logged in, get their settings
        const userSettingsRef = doc(db, 'userSettings', user.uid);
        const unsubscribe = onSnapshot(userSettingsRef, async (doc) => {
          if (doc.exists()) {
            const userSettings = doc.data();
            console.log('Raw user settings from Firestore:', userSettings);
            
            // Only migrate for web users
            const settingsToUse = user.providerData.length > 0 
              ? await migrateSettings(userSettings)
              : userSettings;

            const mergedSettings = {
              ...defaultSettings,
              ...settingsToUse,
              simpleDashboard: {
                ...defaultSettings.simpleDashboard,
                ...(settingsToUse.simpleDashboard || {})
              }
            };
            
            setSettings(mergedSettings);
          } else {
            setSettings(defaultSettings);
          }
          setIsInitialized(true);
        });

        return () => unsubscribe();
      } else {
        setSettings(defaultSettings);
        setIsInitialized(true);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  // Add this function to clean settings object before saving to Firestore
  const removeUndefinedValues = (obj) => {
    // Create a deep copy of the object
    const cleaned = JSON.parse(JSON.stringify(obj));
    return cleaned;
  };

  const updateSettings = async (newSettings) => {
    console.log('SettingsContext - updateSettings:', newSettings);
    const user = auth.currentUser;
    if (!user) {
      console.log('SettingsContext - No user, cannot update settings');
      return;
    }

    try {
      const userSettingsRef = doc(db, 'userSettings', user.uid);
      
      // First get the current settings
      const currentDoc = await getDoc(userSettingsRef);
      const currentSettings = currentDoc.exists() ? currentDoc.data() : settings;

      console.log('SettingsContext - Current settings:', currentSettings);

      // Deep merge the settings, ensuring Quran settings are properly handled
      const mergedSettings = {
        ...currentSettings,
        ...newSettings,
        quran: {
          ...defaultSettings.quran,
          ...(currentSettings.quran || {}),
          ...(newSettings.quran || {}),
          // Explicitly preserve readingProgress if it exists and isn't being updated
          readingProgress: newSettings.quran?.readingProgress || currentSettings.quran?.readingProgress || defaultSettings.quran.readingProgress,
          // Explicitly preserve memorization if it exists and isn't being updated
          memorization: newSettings.quran?.memorization || currentSettings.quran?.memorization || defaultSettings.quran.memorization
        }
      };

      console.log('SettingsContext - Merged settings:', mergedSettings);

      // If updating Quran settings, also update the last selected values
      if (newSettings.quran) {
        if (newSettings.quran.selectedTranslation) {
          mergedSettings.lastSelectedTranslation = newSettings.quran.selectedTranslation;
        }
      }

      // Clean settings by removing undefined values
      const cleanedSettings = removeUndefinedValues(mergedSettings);

      await setDoc(userSettingsRef, cleanedSettings, { merge: true });
      setSettings(cleanedSettings);
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  const migrateSettings = (settings) => {
    // Simply return the settings without migration
    return settings;
  };

  useEffect(() => {
    const loadSettings = async () => {
      const savedSettings = await getSavedSettings();
      if (savedSettings) {
        const migratedSettings = await migrateSettings(savedSettings);
        
        // Ensure Quran settings are properly initialized
        const mergedSettings = {
          ...migratedSettings,
          quran: {
            ...defaultSettings.quran,
            ...migratedSettings.quran,
            // Use last selected values if current ones aren't set
            selectedReciter: migratedSettings.quran?.selectedReciter || migratedSettings.lastSelectedReciter,
            selectedLanguage: migratedSettings.quran?.selectedLanguage || migratedSettings.lastSelectedLanguage,
            selectedTranslation: migratedSettings.quran?.selectedTranslation || migratedSettings.lastSelectedTranslation,
          }
        };
        
        setSettings(mergedSettings);
      }
      setIsInitialized(true);
    };

    loadSettings();
  }, []);

  const value = {
    settings,
    updateSettings,
    isInitialized,
    showSettings,
    setShowSettings,
    isSettingsOpen,
    setIsSettingsOpen,
    showNavigation,
    setShowNavigation,
    // Quran settings
    reciterOptions: [], // This should be populated from your data source
    selectedReciter: settings.lastSelectedReciter,
    handleReciterChange: (option) => {
      updateSettings({
        ...settings,
        lastSelectedReciter: option.value
      });
    },
    languageOptions: [], // This should be populated from your data source
    selectedLanguage: settings.lastSelectedLanguage,
    handleLanguageChange: (option) => {
      updateSettings({
        ...settings,
        lastSelectedLanguage: option.value
      });
    },
    translationOptions: [], // This should be populated from your data source
    selectedTranslation: settings.lastSelectedTranslation,
    handleTranslationChange: (option) => {
      updateSettings({
        ...settings,
        lastSelectedTranslation: option.value
      });
    },
    displayMode: settings.quran.displayMode,
    setDisplayMode: (mode) => {
      updateSettings({
        ...settings,
        quran: {
          ...settings.quran,
          displayMode: mode
        }
      });
    },
    showTransliteration: settings.quran.showTransliteration,
    handleTransliterationToggle: (value) => {
      updateSettings({
        ...settings,
        quran: {
          ...settings.quran,
          showTransliteration: value
        }
      });
    },
    quranMode: settings.quran.mode,
    setQuranMode: (mode) => {
      updateSettings({
        ...settings,
        quran: {
          ...settings.quran,
          mode: mode
        }
      });
    },
    fontSize: settings.quran.fontSize,
    setFontSize: (size) => {
      updateSettings({
        ...settings,
        quran: {
          ...settings.quran,
          fontSize: size
        }
      });
    },
    onResetMemorization: () => {
      updateSettings({
        ...settings,
        quran: {
          ...settings.quran,
          memorization: {} // Reset memorization data
        }
      });
    },
    onResetReadingProgress: () => {
      // Create a properly structured reading progress object
      const defaultReadingProgress = {
        currentSession: {
          surah: null,
          verse: null,
          timestamp: null
        },
        history: {}
      };

      updateSettings({
        ...settings,
        quran: {
          ...settings.quran,
          readingProgress: defaultReadingProgress
        }
      });
    },
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
