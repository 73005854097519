import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';
import { Button, TextInput, Alert } from 'flowbite-react';
import { FcGoogle } from 'react-icons/fc';
import { useDarkMode } from '../contexts/DarkModeContext';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();

  const handleSignUp = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const isNative = typeof window !== 'undefined' && window.isUmmahtiNative;

  return (
    <div className={`flex flex-col min-h-screen relative overflow-hidden transition-colors duration-200 ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      {isNative ? (
        <>
          {/* Background Image with Gradient Overlay - Native App */}
          <div className="absolute inset-0">
            <img
              src="/images/headerbg.png"
              alt=""
              className="absolute inset-0 w-full h-full object-cover opacity-50"
            />
            <div className={`absolute inset-0 ${
              isDarkMode
                ? 'bg-gradient-to-b from-emerald-900/95 via-[#111111]/98 to-[#111111]'
                : 'bg-gradient-to-b from-emerald-600/95 via-white/98 to-white'
            }`} />
          </div>

          {/* Gradient Orbs - Native App */}
          <div className={`absolute top-1/4 left-1/4 w-[600px] h-[600px] rounded-full blur-3xl -translate-x-1/2 ${
            isDarkMode
              ? 'bg-emerald-500/[0.07]'
              : 'bg-emerald-200/40'
          }`} />
          <div className={`absolute bottom-1/4 right-1/4 w-[600px] h-[600px] rounded-full blur-3xl translate-x-1/2 ${
            isDarkMode
              ? 'bg-emerald-500/[0.07]'
              : 'bg-emerald-100/60'
          }`} />
        </>
      ) : (
        <div className={`absolute inset-0 ${
          isDarkMode 
            ? 'bg-gradient-to-b from-[#0A0A0A] via-[#0A0A0A] to-[#041E1B]' 
            : 'bg-gradient-to-b from-gray-50 via-white to-emerald-50/30'
        }`} />
      )}

      <div className="relative flex-1 flex items-center justify-center px-4" 
           style={{ paddingTop: isNative ? 'calc(env(safe-area-inset-top) + 2rem)' : '2rem' }}>
        <div className={`w-full max-w-md ${
          isNative ? 'bg-transparent' : `rounded-[24px] overflow-hidden ${
            isDarkMode 
              ? 'bg-[#111111] shadow-lg shadow-black/5 border border-white/10' 
              : 'bg-white shadow-sm border border-gray-200'
          }`
        }`}>
          <div className="p-8">
            {/* Logo - Only show in native app */}
            {isNative && (
              <div className="flex justify-center mb-12">
                <div className="w-24 h-24">
                  <img
                    src="/images/ummahti_white_transparent.png"
                    alt="Ummahti"
                    className={`w-full transition-opacity duration-300 ${isDarkMode ? 'opacity-90' : 'opacity-80'}`}
                  />
                </div>
              </div>
            )}

            <h2 className={`text-2xl font-light mb-8 text-center ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Create an account
            </h2>
            
            {error && (
              <Alert 
                color="failure" 
                className="mb-6"
                onDismiss={() => setError(null)}
              >
                {error}
              </Alert>
            )}

            <form onSubmit={handleSignUp} className="space-y-4">
              <div>
                <TextInput
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className={`${isDarkMode ? '[&>input]:bg-[#1A1A1A] [&>input]:border-gray-800' : ''} ${
                    isNative ? '[&>input]:rounded-2xl [&>input]:px-6 [&>input]:py-4 [&>input]:backdrop-blur-md' : ''
                  }`}
                />
              </div>
              <div>
                <TextInput
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className={`${isDarkMode ? '[&>input]:bg-[#1A1A1A] [&>input]:border-gray-800' : ''} ${
                    isNative ? '[&>input]:rounded-2xl [&>input]:px-6 [&>input]:py-4 [&>input]:backdrop-blur-md' : ''
                  }`}
                />
              </div>
              <div>
                <TextInput
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className={`${isDarkMode ? '[&>input]:bg-[#1A1A1A] [&>input]:border-gray-800' : ''} ${
                    isNative ? '[&>input]:rounded-2xl [&>input]:px-6 [&>input]:py-4 [&>input]:backdrop-blur-md' : ''
                  }`}
                />
              </div>
              <Button 
                type="submit" 
                className={`w-full ${
                  isNative ? 'py-4 px-6 rounded-2xl backdrop-blur-md' : ''
                } ${
                  isDarkMode 
                    ? 'bg-emerald-500 hover:bg-emerald-400 text-black font-medium' 
                    : 'bg-gray-900 hover:bg-gray-800 text-white'
                }`}
              >
                Sign up
              </Button>
            </form>

            <div className="relative my-6">
              <div className={`absolute inset-0 flex items-center`}>
                <div className={`w-full border-t ${
                  isDarkMode ? 'border-gray-800' : 'border-gray-200'
                }`}></div>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className={`px-2 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                } ${isNative ? 'backdrop-blur-md' : ''}`}>
                  Or continue with
                </span>
              </div>
            </div>

            <Button 
              color="light"
              className={`w-full ${
                isNative ? 'py-4 px-6 rounded-2xl backdrop-blur-md' : ''
              } ${
                isDarkMode 
                  ? 'bg-white/10 hover:bg-white/20 text-white border border-white/[0.05]' 
                  : 'bg-black/10 hover:bg-black/20 text-gray-900 border border-black/[0.05]'
              }`}
              onClick={handleGoogleSignUp}
            >
              <FcGoogle className="mr-2 h-5 w-5" />
              Sign up with Google
            </Button>

            <p className={`mt-6 text-center text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Already have an account?{' '}
              <Link 
                to="/login" 
                className={isDarkMode ? 'text-emerald-400 hover:text-emerald-300' : 'text-emerald-600 hover:text-emerald-500'}
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>

      {/* Footer Text - Only show in native app */}
      {isNative && (
        <div className="relative pb-8 px-8 text-center" style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 2rem)' }}>
          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            By continuing, you agree to our Terms of Service and Privacy Policy
          </p>
        </div>
      )}
    </div>
  );
};

export default SignUp;