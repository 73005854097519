import React, { useMemo, useState, useEffect } from 'react';
import { Sunrise, Sun, Sunset, Moon, Star, MapPin, Calendar } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { haveAllPrayersPassed } from '../../utils/prayerTimeUtils';

const SimplePrayerTimesCard = ({ prayerTimes, tomorrowPrayerTimes, settings, render }) => {
  const [showTomorrow, setShowTomorrow] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  // Check if all prayers have passed and we should show tomorrow's times
  useEffect(() => {
    if (prayerTimes && Object.keys(prayerTimes).length > 0) {
      const allPrayersPassed = haveAllPrayersPassed(prayerTimes, currentTime, settings.tunedTimes);
      setShowTomorrow(allPrayersPassed && tomorrowPrayerTimes && Object.keys(tomorrowPrayerTimes).length > 0);
    }
  }, [prayerTimes, tomorrowPrayerTimes, currentTime, settings.tunedTimes]);

  // Determine which prayer times to display
  const displayPrayerTimes = showTomorrow ? tomorrowPrayerTimes : prayerTimes;

  // Debug logging for Maghrib time
  useEffect(() => {
    if (displayPrayerTimes?.times?.Maghrib) {
      console.log('[SimplePrayerTimesCard] Maghrib time before adjustment:', {
        time: displayPrayerTimes.times.Maghrib,
        isAdjusted: displayPrayerTimes.isAdjusted,
        tuning: settings.tunedTimes?.Maghrib || 0
      });
    }
  }, [displayPrayerTimes, settings.tunedTimes]);

  // Apply tuned prayer times only if they haven't been adjusted yet
  const adjustedPrayerTimes = useMemo(() => {
    if (!displayPrayerTimes?.times) return {};

    // If times are already adjusted at the API level, return them as is
    if (displayPrayerTimes.isAdjusted) {
      console.log('[SimplePrayerTimesCard] Using pre-adjusted times from API:', displayPrayerTimes.times);
      return displayPrayerTimes.times;
    }

    // Otherwise, apply the adjustments
    const adjusted = Object.entries(displayPrayerTimes.times).reduce((acc, [prayer, time]) => {
      // Get adjustment value from tunedTimes, defaulting to 0 if not set
      const adjustment = settings.tunedTimes?.[prayer] || 0;
      
      if (adjustment === 0) {
        acc[prayer] = time;
        return acc;
      }

      // Parse the time
      const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
      
      // Calculate total minutes and handle day rollover
      let totalMinutes = (hours * 60 + minutes + adjustment) % (24 * 60);
      if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle negative adjustments
      
      // Convert back to hours and minutes
      const adjustedHours = Math.floor(totalMinutes / 60);
      const adjustedMinutes = totalMinutes % 60;
      
      // Format with padding
      const formattedHours = adjustedHours.toString().padStart(2, '0');
      const formattedMinutes = adjustedMinutes.toString().padStart(2, '0');
      
      acc[prayer] = `${formattedHours}:${formattedMinutes}`;
      return acc;
    }, {});

    if (adjusted.Maghrib) {
      console.log('[SimplePrayerTimesCard] Maghrib time after adjustment:', adjusted.Maghrib);
    }

    return adjusted;
  }, [displayPrayerTimes, settings.tunedTimes]);

  return render({
    prayerTimes: adjustedPrayerTimes,
    prayers: Object.entries(adjustedPrayerTimes),
    showTomorrow,
    currentTime
  });
};

export default SimplePrayerTimesCard;
