import api from './api';
import tzlookup from 'tz-lookup';
import { getCachedData, setCachedData } from '../utils/apiCache';

const DATE_TIME_CACHE_KEY = 'date-time';

export const getTimezoneFromCoordinates = (latitude, longitude) => {
  try {
    // Convert coordinates to numbers if they're strings
    const lat = Number(latitude);
    const lon = Number(longitude);

    // Check if coordinates are valid numbers
    if (isNaN(lat) || isNaN(lon)) {
      console.warn('Invalid coordinates:', { latitude, longitude });
      return null;
    }

    // Check if coordinates are within valid ranges
    if (lat < -90 || lat > 90 || lon < -180 || lon > 180) {
      console.warn('Coordinates out of range:', { lat, lon });
      return null;
    }

    return tzlookup(lat, lon);
  } catch (error) {
    console.error('Error getting timezone:', error);
    return null;
  }
};

export const fetchDateTime = async (timezone) => {
  try {
    // Check cache first
    const cachedData = getCachedData(DATE_TIME_CACHE_KEY);
    if (cachedData) {
      return cachedData;
    }

    // If not in cache, make the API request
    const response = await api.get('/date-time');
    
    // Cache the response
    setCachedData(DATE_TIME_CACHE_KEY, response.data);
    
    return response.data;
  } catch (error) {
    console.error("Error fetching date and time:", error);
    throw error;
  }
};

export const getCountdown = (prayerTimes, now = new Date()) => {
  if (!prayerTimes || Object.keys(prayerTimes).length === 0) {
    return { nextPrayer: "Unknown", countdown: "--:--:--" };
  }

  const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  
  const prayers = Object.entries(prayerTimes).map(([name, time]) => {
    const [hours, minutes] = time.split(':').map(Number);
    return { name, time: hours * 3600 + minutes * 60 };
  });

  prayers.sort((a, b) => a.time - b.time);

  let nextPrayer = prayers.find(prayer => prayer.time > currentTime);

  if (!nextPrayer) {
    nextPrayer = prayers[0]; // If no next prayer found, it's Fajr of the next day
  }

  let timeDiff = nextPrayer.time - currentTime;
  if (timeDiff < 0) {
    timeDiff += 24 * 3600; // Add 24 hours if it's Fajr of the next day
  }

  const hours = Math.floor(timeDiff / 3600);
  const minutes = Math.floor((timeDiff % 3600) / 60);
  const seconds = Math.floor(timeDiff % 60);

  const countdown = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  return { nextPrayer: nextPrayer.name, countdown };
};

export const isItPrayerTime = (prayerTimes, now = new Date()) => {
  const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  
  for (const [prayer, time] of Object.entries(prayerTimes)) {
    const [hours, minutes] = time.split(':').map(Number);
    const prayerTime = hours * 3600 + minutes * 60;
    
    if (currentTime >= prayerTime && currentTime < prayerTime + 300) { // 5 minutes window
      return { isPrayerTime: true, currentPrayer: prayer };
    }
  }
  
  return { isPrayerTime: false, currentPrayer: null };
};

export const isExactPrayerTime = (prayerTimes, now = new Date()) => {
  const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  
  for (const [prayer, time] of Object.entries(prayerTimes)) {
    const [hours, minutes] = time.split(':').map(Number);
    const prayerTime = hours * 3600 + minutes * 60;
    
    if (currentTime === prayerTime) {
      return { isPrayerTime: true, currentPrayer: prayer };
    }
  }
  
  return { isPrayerTime: false, currentPrayer: null };
};
