import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const ModernGlassTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  // Add styles object at the top
  const styles = {
    smallText: 'text-lg [@media(min-height:769px)]:text-3xl',
    dateText: 'text-sm [@media(min-height:769px)]:text-base',
    hadithText: 'text-4xl [@media(min-height:769px)]:text-6xl',
    timeText: 'text-7xl [@media(min-height:601px)]:text-9xl [@media(min-height:801px)]:text-[16rem]',
    countdownLabel: 'text-lg [@media(min-height:601px)]:text-xl [@media(min-height:801px)]:text-xl',
    countdownText: 'text-4xl [@media(min-height:601px)]:text-6xl [@media(min-height:801px)]:text-[8rem]',
    prayerText: 'text-2xl [@media(min-height:769px)]:text-3xl'
  };

  return (
    <div className="h-screen w-full bg-gradient-to-br from-slate-950 via-gray-900 to-neutral-900 text-white">
      {/* Background Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute top-0 -left-48 w-96 h-96 ${accentColor.bg} rounded-full blur-3xl opacity-10`}></div>
        <div className={`absolute bottom-48 -right-48 w-96 h-96 ${accentColor.bg} rounded-full blur-3xl opacity-20`}></div>
      </div>

      {/* Content */}
      <div className="relative h-full flex flex-col">
        {/* Main Content */}
        <div className="flex-1 flex">
          {/* Left Panel */}
          <div className="w-2/3 px-16 pt-16 pb-4 flex flex-col">
            {/* Location */}
            <div className="mb-16">
              <div className="inline-flex items-center gap-2 px-6 py-3 rounded-2xl bg-white/5 backdrop-blur-sm border border-white/5">
                <MapPin className="w-4 h-4 text-white/60" />
                <h1 className={`${styles.smallText} font-light`}>
                  {settings.location.split(',')[0]}
                </h1>
              </div>
            </div>

            {/* Time Section */}
            <div className="flex-1 flex flex-col justify-center -mt-24">
              <div>
                <div className="flex items-baseline gap-4 mb-8">
                  <p className="text-base tracking-widest uppercase text-white/40">Current Time</p>
                  <div className="h-px w-16 bg-gradient-to-r from-white/20 to-transparent"></div>
                </div>
                <SimpleTimeCard 
                  render={({ time }) => (
                    <div className={`${styles.timeText} font-thin tracking-tight leading-none bg-gradient-to-b from-white via-white to-white/50 bg-clip-text text-transparent`}>
                      {time}
                    </div>
                  )}
                />
              </div>
            </div>

            {/* Date section */}
            <div className="flex gap-8">
              <SimpleDateCard 
                dateTime={dateTime}
                onDateChange={onDateChange}
                render={({ gregorian, hijri }) => (
                  <>
                    <div>
                      <p className="text-white/40 text-sm tracking-widest uppercase mb-1">Gregorian</p>
                      <p className="text-lg">{gregorian}</p>
                    </div>
                    <div>
                      <p className="text-white/40 text-sm tracking-widest uppercase mb-1">Hijri</p>
                      <p className="text-lg">{hijri}</p>
                    </div>
                  </>
                )}
              />
            </div>
          </div>

          {/* Right Panel */}
          <div className="w-1/3 border-l border-white/5 px-16 pt-16 pb-4 flex flex-col">
            {/* Dua */}
            <div className="mb-auto">
              <div className="p-6 rounded-2xl bg-white/5 backdrop-blur-sm border border-white/5">
                <p className="text-2xl text-white/60 leading-relaxed text-right font-uthmani">
                  {duas?.[currentDuaIndex]?.arabic}
                </p>
              </div>
            </div>

            {/* Countdown */}
            <SimplePrayerCountdownCard
              prayerTimes={prayerTimes}
              render={({ countdown, nextPrayer }) => (
                <div className="text-right">
                  <div className={`inline-flex items-center gap-3 px-4 py-2 ${accentColor.bg} rounded-full mb-6 border ${accentColor.border}`}>
                    <div className={`w-2 h-2 rounded-full ${accentColor.base}`}></div>
                    <p className={`${styles.countdownLabel} tracking-widest uppercase ${accentColor.base}`}>
                      Until {nextPrayer}
                    </p>
                  </div>
                  <div className={`${styles.countdownText} font-thin tracking-tight leading-none ${accentColor.base}`}>
                    {countdown}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        {/* Prayer Times Bar */}
        <div className="border-t border-white/5">
          <div className="mx-16 -mt-px">
            <div className="grid grid-cols-5">
              <SimplePrayerTimesCard
                prayerTimes={prayerTimes}
                settings={settings}
                render={({ prayers }) => (
                  <>
                    {prayers.map(([prayer, time]) => {
                      const Icon = prayer === 'Fajr' ? Sunrise :
                                 prayer === 'Maghrib' ? Sunset :
                                 prayer === 'Isha' ? Moon : Sun;
                      
                      const isNext = prayer === nextPrayer;
                      const isPast = new Date(`${new Date().toDateString()} ${time}`) < new Date();
                      
                      return (
                        <div 
                          key={prayer}
                          className={`py-8 px-12 ${
                            isNext 
                              ? `bg-gradient-to-b ${accentColor.bg} to-transparent border-t-2 ${accentColor.border}`
                              : 'border-t-2 border-transparent'
                          }`}
                        >
                          <div className="flex items-center gap-3 mb-3">
                            <Icon className={`w-5 h-5 ${
                              isNext ? accentColor.base : isPast ? 'text-white/20' : 'text-white/80'
                            }`} />
                            <p className={`text-base tracking-widest uppercase ${
                              isNext ? accentColor.base : isPast ? 'text-white/20' : 'text-white/80'
                            }`}>
                              {prayer}
                            </p>
                          </div>
                          <p className={`text-4xl font-light ${
                            isNext ? accentColor.base : isPast ? 'text-white/20' : 'text-white/80'
                          }`}>
                            {time}
                          </p>
                        </div>
                      );
                    })}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModernGlassTemplate; 