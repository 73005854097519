import React, { useState, useEffect } from 'react';
import { User, Settings, Bell, Tv, ChevronLeft, ChevronRight, Book } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';
import PersonalInfo from './settings/PersonalInfo';
import DashboardSettings from './settings/DashboardSettings';
import SimpleDashboardSettings from './settings/SimpleDashboardSettings';
import TVSettingsPage from './settings/TVSettingsPage';
import QuranSettingsWrapper from './settings/QuranSettingsWrapper';
import { AnimatePresence, motion } from 'framer-motion';

const PromoBanner = ({ title, description, action, isDarkMode }) => (
  <div className={`relative overflow-hidden rounded-[24px] p-6 mb-4 border transition-all duration-200 ${
    isDarkMode 
      ? 'border-emerald-500/10' 
      : 'border-emerald-500/10'
  }`}>
    {/* Background with gradient and image */}
    <div className="absolute inset-0">
      <img
        src="/images/headerbg.png"
        alt=""
        className="absolute inset-0 w-full h-full object-cover opacity-30"
      />
      <div className={`absolute inset-0 bg-gradient-to-br ${
        isDarkMode
          ? 'from-emerald-600/30 via-emerald-600/20 to-transparent'
          : 'from-emerald-500/30 via-emerald-500/20 to-transparent'
      }`}></div>
    </div>

    {/* Content */}
    <div className="relative z-10">
      <h3 className={`text-lg font-medium mb-2 ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>{title}</h3>
      <p className={`text-sm mb-4 ${
        isDarkMode ? 'text-gray-400' : 'text-gray-600'
      }`}>{description}</p>
      <div className="flex items-center">
        <span className={`inline-flex items-center gap-2 text-sm font-medium ${
          isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
        }`}>
          {action}
          <ChevronRight className="w-4 h-4" />
        </span>
      </div>
    </div>
  </div>
);

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [showMenu, setShowMenu] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { isDarkMode } = useDarkMode();

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderContent = () => {
    console.log('Active tab:', activeTab);
    switch (activeTab) {
      case 'personal':
        return <PersonalInfo />;
      case 'dashboard':
        return <DashboardSettings 
          onTransitionStart={() => setIsTransitioning(true)}
          onTransitionEnd={() => setIsTransitioning(false)}
        />;
      case 'quran':
        return <QuranSettingsWrapper 
          inSettingsPage={true} 
          onBack={() => setShowMenu(true)}
        />;
      case 'simpleDashboard':
        console.log('Rendering SimpleDashboardSettings');
        return <SimpleDashboardSettings />;
      case 'tv':
        return <TVSettingsPage />;
      default:
        return null;
    }
  };

  const navItems = [
    { id: 'dashboard', icon: Settings, label: 'General Settings' },
    { id: 'quran', icon: Book, label: 'Quran Settings' },
    { id: 'simpleDashboard', icon: Bell, label: 'Ummahti Adhan' },
    { id: 'tv', icon: Tv, label: 'TV Settings' },
    { id: 'personal', icon: User, label: 'Personal Information' },
  ];

  // Mobile menu section component
  const MenuSection = ({ title, children }) => (
    <div className="mb-6">
      {title !== 'Settings' && (
        <div className="px-4 py-2">
          <p className={`text-xs font-medium uppercase tracking-wider ${
            isDarkMode ? 'text-gray-500' : 'text-gray-500'
          }`}>{title}</p>
        </div>
      )}
      <div className={`mx-4 rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-[#111111]' : 'bg-white'
      }`}>
        {children}
      </div>
    </div>
  );

  // Mobile menu item component
  const MenuItem = ({ icon: Icon, label, onClick, isActive }) => (
    <button
      onClick={onClick}
      className={`w-full flex items-center px-4 py-3.5 border-b transition-colors ${
        isDarkMode 
          ? 'border-gray-800 active:bg-[#1A1A1A]' 
          : 'border-gray-100 active:bg-gray-50'
      } ${isActive ? isDarkMode ? 'bg-[#1A1A1A]' : 'bg-gray-50' : ''}`}
    >
      <div className="flex items-center flex-1">
        <Icon className={`w-5 h-5 mr-3 ${
          isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
        }`} />
        <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>{label}</span>
      </div>
      <ChevronRight className={`w-5 h-5 ${
        isDarkMode ? 'text-gray-600' : 'text-gray-400'
      }`} />
    </button>
  );

  if (isMobileView) {
    return (
      <>
        {activeTab === 'quran' && (
          <style>{`
            .inline-quran-settings .fixed {
              position: relative !important;
              inset: auto !important;
            }
          `}</style>
        )}
        <div className={`min-h-screen overflow-hidden ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
          <div className="h-screen overflow-hidden">
            <div className="relative h-full">
              <motion.div
                initial={false}
                animate={{ x: !showMenu ? '-15%' : 0 }}
                transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
                className={`absolute inset-0 h-full overflow-y-auto pb-[calc(env(safe-area-inset-bottom)+72px)] ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}
              >
                {/* Mobile Header - Menu View */}
                <header className={`sticky top-0 px-4 z-40 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
                  <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="py-4">
                    <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Settings</h1>
                  </div>
                </header>

                {/* Mobile Navigation Menu */}
                <div className="max-w-xl mx-auto">
                  <MenuSection title="Settings">
                    {navItems.map((item) => (
                      <MenuItem
                        key={item.id}
                        icon={item.icon}
                        label={item.label}
                        onClick={() => {
                          setActiveTab(item.id);
                          setShowMenu(false);
                        }}
                        isActive={activeTab === item.id}
                      />
                    ))}
                  </MenuSection>
                </div>

                <div className="px-4 mt-8">
                  <div className="space-y-4">
                    <PromoBanner
                      title="Smart Adhan Clock"
                      description="A minimalist smart clock with built-in adhan notifications and prayer times display."
                      action="Coming Soon"
                      isDarkMode={isDarkMode}
                    />

                    <PromoBanner
                      title="Ummahti TV"
                      description="Islamic content on your Google TV. Lectures, Quran recitations, and more."
                      action="Coming Soon"
                      isDarkMode={isDarkMode}
                    />
                  </div>
                </div>
              </motion.div>

              <AnimatePresence>
                {!showMenu && (
                  <motion.div
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '100%' }}
                    transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
                    className={`absolute inset-0 h-full overflow-y-auto z-50 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}
                  >
                    {/* Mobile Header - Content View */}
                    <motion.header 
                      className={`sticky top-0 px-4 z-50 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}
                      animate={{ x: isTransitioning ? '-15%' : 0, opacity: isTransitioning ? 0 : 1 }}
                      transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
                    >
                      <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="py-4">
                        <div className="flex items-center">
                          <button 
                            onClick={() => setShowMenu(true)} 
                            className={`flex items-center ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'} absolute left-4`}
                          >
                            <ChevronLeft className="w-5 h-5" />
                            <span className="text-xl">Back</span>
                          </button>
                          <h1 className={`text-xl font-semibold flex-1 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            {navItems.find(item => item.id === activeTab)?.label}
                          </h1>
                        </div>
                      </div>
                    </motion.header>

                    {/* Content Area with header supplied by SettingsPage */}
                    {activeTab === 'quran' ? (
                      <div className="inline-quran-settings">
                        {renderContent()}
                      </div>
                    ) : (
                      <div className="px-4">
                        {renderContent()}
                      </div>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Return existing desktop layout
  return (
    <>
      {activeTab === 'quran' && (
        <style>{`
          .inline-quran-settings .fixed {
            position: relative !important;
            inset: auto !important;
          }
        `}</style>
      )}
      <div className={`flex-1 overflow-auto transition-colors duration-200 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
        <div style={{ paddingTop: 'env(safe-area-inset-top)' }}>
          <div className={`container mx-auto px-4 sm:px-6 lg:px-8 py-8`}>
            {/* Settings Container */}
            <div className={`transition-all duration-200 rounded-[24px] overflow-hidden ${isDarkMode ? 'bg-[#111111] shadow-lg shadow-black/5' : 'bg-white shadow-sm'}`}>
              <div className="flex flex-col lg:flex-row">
                {/* Sidebar Navigation */}
                <nav className={`w-full lg:w-72 p-6 border-b lg:border-b-0 lg:border-r transition-colors ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}>
                  <ul className="space-y-2">
                    {navItems.map((item) => {
                      const isActive = activeTab === item.id;
                      return (
                        <li key={item.id}>
                          <button
                            className={`flex items-center w-full px-4 py-3 text-left text-base rounded-xl transition-all duration-200 ${isActive ? isDarkMode ? 'bg-emerald-500 text-black font-medium' : 'bg-gray-900 text-white font-medium' : isDarkMode ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}`}
                            onClick={() => setActiveTab(item.id)}
                          >
                            <item.icon className={`w-5 h-5 mr-3 ${isActive ? isDarkMode ? 'text-black' : 'text-white' : isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            <span>{item.label}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </nav>

                {/* Main Content Area */}
                <main className={`flex-1 ${activeTab === 'quran' ? '' : 'p-6'} ${isDarkMode ? 'bg-[#111111]' : 'bg-white'}`}>
                  <h2 className={`text-xl font-medium mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {navItems.find(item => item.id === activeTab)?.label}
                  </h2>
                  {activeTab === 'quran' ? (
                    <div className="inline-quran-settings">
                      {renderContent()}
                    </div>
                  ) : (
                    renderContent()
                  )}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
