import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Image as ImageIcon, Clock, Palette, RefreshCw } from 'lucide-react';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { useSettings } from '../../../contexts/SettingsContext';
import { useImageUpload } from './hooks/useImageUpload';
import { OrientedImageGrid } from './components/OrientedImageGrid';
import { UploadButton } from './components/UploadButton';
import { ColorPicker } from './components/ColorPicker';
import { SlideshowSettings } from './components/SlideshowSettings';
import { sectionStyles } from './styles';
import { clearImageCache } from '../../../utils/imageCache';

// Helper function to compare arrays of strings or objects
const areImagesEqual = (arr1, arr2) => {
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;
  
  // Convert all items to a standard format for comparison
  const normalize = item => {
    if (typeof item === 'string') return item;
    if (item && typeof item === 'object') {
      return item.url || item.original || item.display || item.path || JSON.stringify(item);
    }
    return String(item);
  };
  
  const normalized1 = arr1.map(normalize).sort();
  const normalized2 = arr2.map(normalize).sort();
  
  return normalized1.every((item, index) => item === normalized2[index]);
};

// Throttle function to limit how often a function can run
const throttle = (func, delay) => {
  let lastCall = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
};

export const BackgroundSettings = ({ 
  selectedTemplate, 
  selectedImages,
  setSelectedImages,
  onError,
  onSuccess
}) => {
  const { isDarkMode } = useDarkMode();
  const { settings, updateSettings } = useSettings();
  const styles = sectionStyles(isDarkMode);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(Date.now());
  const [lastSavedImages, setLastSavedImages] = useState([]);
  
  // Track if we're updating from settings changes to prevent loops
  const updatingFromSettings = useRef(false);
  const pendingUpdateRef = useRef(null);

  const {
    fileInputRef,
    uploadingImage,
    uploadProgress,
    backgroundImages,
    userImages,
    handleCustomImageUpload,
    handleImageSelect,
    handleDeleteImage,
    refreshExpiredUrls
  } = useImageUpload(
    selectedTemplate,
    () => onSuccess('Images uploaded successfully'),
    onError
  );

  // Debug logging with less frequency
  useEffect(() => {
    const logImageInfo = () => {
      console.log('Background settings has background images:', backgroundImages?.length || 0);
      console.log('Background settings has user images:', userImages?.length || 0);
    };
    
    // Only log when images are first loaded or significantly change
    if (backgroundImages?.length > 0 || userImages?.length > 0) {
      logImageInfo();
    }
  }, [backgroundImages?.length, userImages?.length]);

  // Update settings whenever selectedImages changes, with debounce to prevent rapid updates
  useEffect(() => {
    // Skip if we're in the process of updating from settings
    if (updatingFromSettings.current) {
      console.log('Skipping settings update as we are updating from settings');
      updatingFromSettings.current = false;
      return;
    }
    
    // Filter out any undefined or null values before saving
    const validImages = selectedImages.filter(img => img !== undefined && img !== null);
    
    // Use deep comparison to avoid unnecessary updates
    if (areImagesEqual(validImages, lastSavedImages)) {
      // Images are the same, no need to update
      console.log('Images unchanged, skipping settings update');
      return;
    }
    
    // Clear any pending updates
    if (pendingUpdateRef.current) {
      clearTimeout(pendingUpdateRef.current);
    }
    
    // Use a timeout to debounce the updates
    pendingUpdateRef.current = setTimeout(() => {
      console.log('Updating settings with new image selection');
      
      // Update settings with the new selection
      updateSettings({
        simpleDashboard: {
          ...settings.simpleDashboard,
          backgroundImages: validImages
        }
      });
      
      // Store the last saved images for future comparison
      setLastSavedImages(validImages);
      pendingUpdateRef.current = null;
    }, 1000); // Increased debounce time to prevent rapid updates
    
    return () => {
      if (pendingUpdateRef.current) {
        clearTimeout(pendingUpdateRef.current);
      }
    };
  }, [selectedImages, settings.simpleDashboard, updateSettings, lastSavedImages]);

  // When settings change from external sources, update our selection to match
  useEffect(() => {
    const settingsImages = settings.simpleDashboard?.backgroundImages || [];
    
    // Only update if there's a meaningful difference to avoid loops
    if (!areImagesEqual(settingsImages, selectedImages)) {
      console.log('Settings changed externally, updating selected images');
      updatingFromSettings.current = true;
      setSelectedImages(settingsImages);
      setLastSavedImages(settingsImages);
    }
  }, [settings.simpleDashboard?.backgroundImages, setSelectedImages]);

  // Combine background and user images - memoized to prevent unnecessary recalculations
  const allImages = useMemo(() => {
    return [...(backgroundImages || []), ...(userImages || [])];
  }, [backgroundImages, userImages]);

  // Handle refreshing images - wrapped in useCallback to prevent recreation on each render
  const handleRefreshImages = useCallback(
    throttle(async () => {
      // Prevent refreshing more than once per minute
      const now = Date.now();
      if (now - lastRefresh < 60000) {
        onSuccess('Images were recently refreshed');
        return;
      }
      
      setIsRefreshing(true);
      try {
        // Clear image cache
        await clearImageCache();
        
        // Refresh URLs
        if (refreshExpiredUrls) {
          await refreshExpiredUrls();
        }
        
        setLastRefresh(Date.now());
        onSuccess('Images refreshed successfully');
      } catch (error) {
        console.error('Error refreshing images:', error);
        onError('Failed to refresh images');
      } finally {
        setIsRefreshing(false);
      }
    }, 60000), // Throttle to once per minute maximum
    [lastRefresh, refreshExpiredUrls, onSuccess, onError]
  );

  return (
    <section className={`${styles.container} relative z-0`}>
      <div className={styles.header}>
        <ImageIcon className={styles.headerIcon} />
        <h2 className={styles.headerText}>Background Settings</h2>
      </div>
      <div className={styles.card}>
        <SlideshowSettings />
        <ColorPicker />
        <div className="mb-8">
          <div className={styles.header}>
            <ImageIcon className={styles.headerIcon} />
            <h3 className={styles.subheaderText}>Background Images</h3>
          </div>
          
          <div className="flex flex-wrap justify-between items-center mb-4 gap-2">
            <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {selectedImages.length}/20 images selected
            </span>
            
            <button
              onClick={handleRefreshImages}
              disabled={isRefreshing}
              className={`px-3 py-1.5 rounded-lg text-sm flex items-center gap-2 ${
                isDarkMode 
                  ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } ${isRefreshing ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <RefreshCw size={14} className={isRefreshing ? 'animate-spin' : ''} />
              {isRefreshing ? 'Refreshing...' : 'Refresh Images'}
            </button>
          </div>

          <UploadButton
            ref={fileInputRef}
            uploading={uploadingImage}
            progress={uploadProgress}
            onUpload={handleCustomImageUpload}
          />

          {allImages.length > 0 ? (
            <OrientedImageGrid
              images={allImages}
              selectedImages={selectedImages}
              onSelect={(image) => handleImageSelect(image, setSelectedImages)}
              onDelete={(image) => handleDeleteImage(image, setSelectedImages)}
              setSelectedImages={setSelectedImages}
            />
          ) : (
            <div className={`text-center py-12 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {uploadingImage ? 'Uploading images...' : 'No images available. Upload some images or refresh to load default images.'}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}; 