import React from 'react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';

export const LinkedDevicesTable = ({ 
  isLoading, 
  linkedDevices, 
  onUnlink,
  formatTimestamp,
  shortenDeviceId,
  isMobileView
}) => {
  const { isDarkMode } = useDarkMode();

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500 mx-auto"></div>
      </div>
    );
  }

  if (!linkedDevices.length) {
    return (
      <p className={`text-center py-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        No devices linked yet
      </p>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className={`min-w-full divide-y ${
        isDarkMode ? 'divide-gray-800' : 'divide-gray-200'
      }`}>
        <thead className={isDarkMode ? 'bg-[#161616]' : 'bg-gray-50'}>
          <tr>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Device ID
            </th>
            {!isMobileView && (
              <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                Linked At
              </th>
            )}
            <th className={`px-6 py-3 text-right text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className={`divide-y ${
          isDarkMode 
            ? 'bg-[#111111] divide-gray-800' 
            : 'bg-white divide-gray-200'
        }`}>
          {linkedDevices.map((device) => (
            <tr key={device.id}>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-900'
              }`}>
                {shortenDeviceId(device.id)}
              </td>
              {!isMobileView && (
                <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-500'
                }`}>
                  {formatTimestamp(device.linkedAt)}
                </td>
              )}
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  onClick={() => onUnlink(device.id)}
                  className={`transition-colors ${
                    isDarkMode
                      ? 'text-red-400 hover:text-red-300'
                      : 'text-red-600 hover:text-red-900'
                  }`}
                >
                  Unlink
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}; 