import React, { useState, useEffect, useRef } from 'react';
import { Book } from '@phosphor-icons/react';
import { useAuth } from '../../contexts/AuthContext';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { useSettings } from '../../contexts/SettingsContext';
import { fetchSurahs } from '../../services/quranService';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? '100%' : '-100%',
    zIndex: 2
  }),
  center: {
    x: 0,
    zIndex: 2,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30
    }
  },
  exit: (direction) => ({
    x: direction > 0 ? '-100%' : '100%',
    zIndex: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30
    }
  })
};

const QuranProgressCard = ({ hideTitle, isMobile = false }) => {
  const [surahs, setSurahs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMemorization, setShowMemorization] = useState(false);
  const [allMemorizedVerses, setAllMemorizedVerses] = useState({});
  const { currentUser } = useAuth();
  const { isDarkMode } = useDarkMode();
  const { settings } = useSettings();
  const surahsPerPage = 5;
  const [dragStart, setDragStart] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const prevPage = useRef(1);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDragEnd = (event, info) => {
    if (isAnimating) return;

    const dragDistance = info.offset.x;
    const dragThreshold = 50;
    
    if (dragDistance > dragThreshold && currentPage > 1) {
      setDirection(-1);
      prevPage.current = currentPage;
      setCurrentPage(prev => prev - 1);
    } else if (dragDistance < -dragThreshold && currentPage < totalPages) {
      setDirection(1);
      prevPage.current = currentPage;
      setCurrentPage(prev => prev + 1);
    }
  };

  const onPageChange = (page) => {
    if (isAnimating) return;
    
    const newDirection = page > currentPage ? 1 : -1;
    setDirection(newDirection);
    prevPage.current = currentPage;
    setCurrentPage(page);
  };

  const handleSurahClick = (surahNumber) => {
    const surahId = surahNumber.toString();
    const surahHistory = settings.quran?.readingProgress?.history?.[surahId] || {};
    
    // Find the highest verse number that was read
    const verseNumbers = Object.keys(surahHistory)
      .map(num => parseInt(num))
      .filter(num => !isNaN(num));
    
    const highestVerse = verseNumbers.length > 0 ? Math.max(...verseNumbers) : null;
    const formattedVerseId = highestVerse ? `${surahId}:${highestVerse}` : null;

    navigate('/quran', { 
      state: { 
        surahId: surahId,
        verseId: formattedVerseId,
        fromDashboard: true
      } 
    });
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const surahsData = await fetchSurahs();

        if (currentUser) {
          const userMemorizedRef = doc(db, 'userMemorized', currentUser.uid);
          const userMemorizedSnap = await getDoc(userMemorizedRef);
          
          if (userMemorizedSnap?.exists()) {
            setAllMemorizedVerses(userMemorizedSnap.data());
          }
        }
        
        setSurahs(surahsData.chapters || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [currentUser, settings]);

  // Add a debug useEffect to monitor settings changes
  useEffect(() => {
    if (settings?.quran?.readingProgress) {
      const history = settings?.quran?.readingProgress?.history || {};
      
      // Count total verses across all surahs
      let totalVersesRead = 0;
      const surahsWithProgress = [];
      
      Object.entries(history).forEach(([surahId, verses]) => {
        const verseCount = Object.keys(verses).filter(v => !isNaN(parseInt(v))).length;
        if (verseCount > 0) {
          surahsWithProgress.push({ 
            surahId: parseInt(surahId), 
            verseCount 
          });
          totalVersesRead += verseCount;
        }
      });
      
      // Sort by surah number
      surahsWithProgress.sort((a, b) => a.surahId - b.surahId);
      
      console.log('QuranProgressCard: Settings reading progress updated', {
        surahsTracked: Object.keys(history).length,
        totalVersesRead,
        surahDetails: surahsWithProgress.map(s => `${s.surahId}:${s.verseCount}`).join(', '),
        lastUpdated: settings?.quran?.lastReadingSession?.timestamp 
          ? new Date(settings.quran.lastReadingSession.timestamp).toLocaleString()
          : 'Never'
      });
    }
  }, [settings?.quran?.readingProgress, settings?.quran?.lastReadingSession]);

  const filteredSurahs = surahs.filter(surah =>
    surah.name_simple.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const surahsData = Array.isArray(filteredSurahs) ? filteredSurahs.map(surah => {
    const surahId = surah.id.toString();
    const surahVerses = showMemorization 
      ? (allMemorizedVerses[surahId] || {})
      : (settings.quran?.readingProgress?.history?.[surahId] || {});

    // Count only numeric verse keys (some objects might have non-numeric properties)
    const verseEntries = Object.entries(surahVerses)
      .filter(([key]) => !isNaN(parseInt(key)));
    
    const progressCount = verseEntries.length;
    
    // For first 5 surahs, add detailed debug information
    if (surah.id <= 5) {
      // Get verse numbers to show range of verses read
      const verseNumbers = verseEntries.map(([key]) => parseInt(key)).sort((a, b) => a - b);
      const firstVerse = verseNumbers.length > 0 ? verseNumbers[0] : null;
      const lastVerse = verseNumbers.length > 0 ? verseNumbers[verseNumbers.length - 1] : null;
      
      console.log(`Surah ${surahId} (${surah.name_simple}) progress data:`, {
        source: showMemorization ? 'memorization' : 'reading',
        verses: progressCount,
        totalVerses: surah.verses_count,
        percentage: (progressCount / surah.verses_count) * 100,
        verseRange: verseNumbers.length > 0 ? `${firstVerse}-${lastVerse}` : 'none',
        verseGaps: verseNumbers.length > 2 ? findGapsInVerses(verseNumbers) : 'n/a'
      });
    }

    return { 
      surahNumber: surah.id, 
      surahName: surah.name_simple, 
      progressCount, 
      totalAyahs: surah.verses_count, 
      progress: (progressCount / surah.verses_count) * 100 
    };
  }) : [];
  
  // Helper function to find gaps in verse sequences
  function findGapsInVerses(verses) {
    if (verses.length <= 1) return 'no gaps';
    
    const gaps = [];
    for (let i = 1; i < verses.length; i++) {
      if (verses[i] - verses[i-1] > 1) {
        gaps.push(`${verses[i-1]}-${verses[i]}`);
      }
    }
    
    return gaps.length > 0 ? gaps.join(', ') : 'no gaps';
  }

  const sortedSurahs = surahsData.sort((a, b) => {
    if (b.progress === 100 && a.progress !== 100) return 1;
    if (a.progress === 100 && b.progress !== 100) return -1;
    return b.progress - a.progress;
  });

  const totalPages = Math.ceil(sortedSurahs.length / surahsPerPage);
  const displayedSurahs = sortedSurahs.slice(
    (currentPage - 1) * surahsPerPage,
    currentPage * surahsPerPage
  );

  // Show loading state
  if (isLoading) {
    return (
      <div className={`flex flex-col h-full ${hideTitle ? '' : 'space-y-6'}`}>
        {!hideTitle && (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {!isMobile && <Book size={24} weight="thin" />}
              <h2 className={`text-lg font-medium ${
                isMobile ? 'text-white' : isDarkMode ? "text-white" : "text-gray-900"
              }`}>
                Quran Progress
              </h2>
            </div>
          </div>
        )}
        <div className="flex-1 flex flex-col space-y-4 animate-pulse">
          {[...Array(5)].map((_, i) => (
            <div key={i} className={`h-16 rounded-xl ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`} />
          ))}
        </div>
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-6 text-center">
        <p className={`text-sm ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
          {error}
        </p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 text-sm text-white bg-emerald-600 rounded-lg hover:bg-emerald-700"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className={`flex items-center justify-between ${hideTitle ? 'hidden' : 'mb-6'}`}>
        <div className="flex items-center gap-2">
          {(!hideTitle && !isMobile) && (
        <Book size={24} weight="thin" />
          )}
          <h2 className={`text-lg font-medium ${
            isMobile 
              ? 'text-white' 
              : isDarkMode 
                ? "text-white" 
                : "text-gray-900"
          }`}>
          Quran Progress
        </h2>
        </div>
        <div className={`flex items-center p-0.5 rounded-full transition-all duration-300 ${
          isDarkMode ? 'bg-[#1A1A1A]' : 'bg-white/90'
        }`}>
          <button
            onClick={() => setShowMemorization(false)}
            className={`relative px-3 py-1 text-sm rounded-full transition-all duration-200 ${
              !showMemorization
                ? isDarkMode 
                  ? 'bg-emerald-500/20 text-emerald-400'
                  : 'bg-emerald-600 text-white'
                : isDarkMode
                  ? 'text-gray-400 hover:text-gray-300'
                  : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            Reading
          </button>
          <button
            onClick={() => setShowMemorization(true)}
            className={`relative px-3 py-1 text-sm rounded-full transition-all duration-200 ${
              showMemorization
                ? isDarkMode 
                  ? 'bg-emerald-500/20 text-emerald-400'
                  : 'bg-emerald-600 text-white'
                : isDarkMode
                  ? 'text-gray-400 hover:text-gray-300'
                  : 'text-gray-600 hover:text-gray-900'
            }`}
          >
            Memorization
          </button>
        </div>
      </div>

      <div className="py-2">
        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search surah..."
          className={`w-full h-[42px] px-4 rounded-xl border ${isDarkMode ? 'bg-[#1A1A1A] border-gray-800 text-white' : 'bg-white border-gray-200 text-gray-900'} focus:ring-emerald-500 focus:border-emerald-500 outline-none backdrop-blur-sm`}
        />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 grid grid-cols-1 overflow-hidden">
          <AnimatePresence 
            initial={false}
            mode="sync"
            custom={direction}
          >
            <motion.div 
              key={currentPage}
              className="col-start-1 col-end-2 row-start-1 row-end-2 w-full"
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.2}
              onDragEnd={handleDragEnd}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              <div className="space-y-2">
                {displayedSurahs.map(({ surahNumber, surahName, progressCount, totalAyahs, progress }) => (
                  <motion.div 
                    key={surahNumber} 
                    className={`group relative overflow-hidden rounded-2xl backdrop-blur-md backdrop-saturate-150 border ${
                      isDarkMode 
                        ? 'bg-[#111111]/80 border-white/5 hover:bg-[#161616]/80' 
                        : 'bg-white/90 border-black/5 hover:bg-white'
                    }`}
                    onClick={() => handleSurahClick(surahNumber)}
                    whileHover={{ scale: 0.99 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <div className="relative px-4 py-2">
                      <div className="absolute inset-0">
                        <div className={`absolute inset-0 bg-gradient-to-br opacity-20 ${
                          isDarkMode
                            ? progress === 100 ? 'from-emerald-400/30 via-emerald-500/20 to-transparent' :
                              progress > 50 ? 'from-emerald-500/30 via-emerald-600/20 to-transparent' :
                              'from-emerald-600/30 via-emerald-700/20 to-transparent'
                            : progress === 100 ? 'from-emerald-400/40 via-emerald-500/30 to-transparent' :
                              progress > 50 ? 'from-emerald-500/40 via-emerald-600/30 to-transparent' :
                              'from-emerald-600/40 via-emerald-700/30 to-transparent'
                        }`} />
                      </div>
                      <div className="relative z-10">
                        <div className="flex items-center justify-between mb-3">
                      <div className="flex items-center gap-3">
                        <span className={`text-sm font-medium ${
                          isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                        }`}>
                          {surahNumber.toString().padStart(2, '0')}
                        </span>
                            <span className={`font-medium transition-colors ${
                          isDarkMode 
                                ? 'text-gray-200 group-hover:text-emerald-400' 
                                : 'text-gray-900 group-hover:text-emerald-700'
                        }`}>
                          {surahName}
                        </span>
                      </div>
                      <span className={`text-sm font-medium ${
                        isDarkMode
                          ? progress === 100 ? 'text-emerald-400' : 
                            progress > 50 ? 'text-emerald-500' :
                            'text-emerald-600'
                              : progress === 100 ? 'text-emerald-700' : 
                                progress > 50 ? 'text-emerald-600' :
                                'text-emerald-500'
                          }`}>
                            {progressCount}/{totalAyahs}
                      </span>
                    </div>
                        <div className={`relative w-full h-0.5 rounded-full overflow-hidden ${
                          isDarkMode ? 'bg-gray-800/50' : 'bg-gray-100'
                    }`}>
                          <motion.div 
                            className={`absolute top-0 left-0 h-full rounded-full transition-all duration-500 ${
                          isDarkMode
                                ? progress === 100 ? 'bg-gradient-to-r from-emerald-400 via-emerald-500 to-emerald-400' :
                                  progress > 50 ? 'bg-gradient-to-r from-emerald-500 via-emerald-600 to-emerald-500' :
                                  'bg-gradient-to-r from-emerald-600 via-emerald-700 to-emerald-600'
                                : progress === 100 ? 'bg-gradient-to-r from-emerald-500 via-emerald-600 to-emerald-500' :
                                  progress > 50 ? 'bg-gradient-to-r from-emerald-600 via-emerald-700 to-emerald-600' :
                                  'bg-gradient-to-r from-emerald-700 via-emerald-800 to-emerald-700'
                            }`}
                            initial={{ width: 0 }}
                            animate={{ width: `${progress}%` }}
                            transition={{ duration: 0.5, ease: "easeOut" }}
                      />
                    </div>
                  </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {totalPages > 1 && (
          <div className="pt-6 flex justify-center gap-2">
            {[...Array(totalPages)].map((_, i) => (
              <motion.button
                key={i}
                onClick={() => onPageChange(i + 1)}
                className={`w-1.5 h-1.5 rounded-full transition-all ${
                  currentPage === i + 1 
                    ? isDarkMode ? 'bg-emerald-400 w-4' : 'bg-emerald-600 w-4'
                    : isDarkMode ? 'bg-gray-700 hover:bg-emerald-900' : 'bg-gray-200 hover:bg-emerald-200'
                }`}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                animate={{
                  width: currentPage === i + 1 ? 16 : 6,
                  transition: { duration: 0.2 }
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuranProgressCard;
