// Progress Status Types
export const PROGRESS_STATUS = {
  COMPLETED: 'completed',
  PARTIAL: 'partial',
  MISSED: 'missed',
  PENDING: 'pending'
};

// Base Progress Type
export const createBaseProgress = (status = PROGRESS_STATUS.PENDING) => ({
  status,
  completionPercentage: 0,
  lastUpdated: new Date().toISOString()
});

// Quran Reading Progress
export const createQuranProgress = (hizbRange, totalVerses) => ({
  ...createBaseProgress(),
  details: {
    versesRead: [],
    hizbRange,
    totalVerses,
    currentHizbIndex: 0
  }
});

// Dhikr Progress
export const createDhikrProgress = (targetCount) => ({
  ...createBaseProgress(),
  details: {
    completedCount: 0,
    targetCount,
    sessions: []
  }
});

// Prayer Based Progress
export const createPrayerProgress = () => ({
  ...createBaseProgress(),
  details: {
    prayers: Object.fromEntries(
      ['fajr', 'dhuhr', 'asr', 'maghrib', 'isha'].map(prayer => [
        prayer,
        { completed: false }
      ])
    )
  }
});

// Progress Validation Functions
export const validateQuranProgress = (goal, progress) => {
  const { versesRead = [], totalVerses = 0, completed } = progress.details;
  
  // If the user explicitly marked it as completed, we should honor that
  const hasExplicitCompletionStatus = completed !== undefined;
  
  // Calculate percentage based on verses read
  const completionPercentage = totalVerses > 0 
    ? Math.round((versesRead.length / totalVerses) * 100) 
    : 0;
    
  // Determine status based on explicit completion or percentage
  const status = hasExplicitCompletionStatus && completed 
    ? PROGRESS_STATUS.COMPLETED 
    : completionPercentage === 100 
      ? PROGRESS_STATUS.COMPLETED 
      : completionPercentage > 0 
        ? PROGRESS_STATUS.PARTIAL 
        : PROGRESS_STATUS.PENDING;

  return {
    ...progress,
    status,
    completionPercentage: hasExplicitCompletionStatus && completed ? 100 : completionPercentage
  };
};

export const validateDhikrProgress = (goal, progress) => {
  const { completedCount, targetCount } = progress.details;
  const completionPercentage = Math.round((completedCount / targetCount) * 100);
  const status = 
    completionPercentage === 100 
      ? PROGRESS_STATUS.COMPLETED 
      : completionPercentage > 0 
        ? PROGRESS_STATUS.PARTIAL 
        : PROGRESS_STATUS.PENDING;

  return {
    ...progress,
    status,
    completionPercentage
  };
};

export const validatePrayerProgress = (goal, progress) => {
  const { prayers } = progress.details;
  const completedPrayers = Object.values(prayers).filter(p => p.completed).length;
  const totalPrayers = Object.keys(prayers).length;
  const completionPercentage = Math.round((completedPrayers / totalPrayers) * 100);
  const status = 
    completionPercentage === 100 
      ? PROGRESS_STATUS.COMPLETED 
      : completionPercentage > 0 
        ? PROGRESS_STATUS.PARTIAL 
        : PROGRESS_STATUS.PENDING;

  return {
    ...progress,
    status,
    completionPercentage
  };
};

// Progress Merging Functions
export const mergeProgressDetails = (type, existing = {}, updates = {}) => {
  switch (type) {
    case 'quran_reading':
      // Handle explicit completion status
      const completedExplicitly = 'completed' in updates;
      const versesRead = 
        (completedExplicitly && updates.completed === true && updates.versesRead?.length > 0)
          ? updates.versesRead // Use provided versesRead when completing
          : (completedExplicitly && updates.completed === false)
            ? [] // Clear versesRead when explicitly marking incomplete
            : [...new Set([...(existing.versesRead || []), ...(updates.versesRead || [])])]; // Normal merge

      return {
        ...existing,
        ...updates,
        versesRead,
        currentHizbIndex: updates.currentHizbIndex ?? existing.currentHizbIndex
      };
    case 'dhikr':
      return {
        ...existing,
        completedCount: (updates.completedCount ?? 0) + (existing.completedCount ?? 0),
        sessions: [...(existing.sessions || []), ...(updates.sessions || [])]
      };
    case 'prayer_based':
      return {
        ...existing,
        prayers: {
          ...(existing.prayers || {}),
          ...(updates.prayers || {})
        }
      };
    default:
      return { ...existing, ...updates };
  }
};

// Utility function to calculate completion percentage
export const calculateCompletionPercentage = (goal, progress) => {
  switch (goal.type) {
    case 'quran_reading':
      return Math.round((progress.details.versesRead.length / progress.details.totalVerses) * 100);
    case 'dhikr':
      return Math.round((progress.details.completedCount / progress.details.targetCount) * 100);
    case 'prayer_based':
      const completedPrayers = Object.values(progress.details.prayers).filter(p => p.completed).length;
      return Math.round((completedPrayers / 5) * 100);
    default:
      return 0;
  }
}; 