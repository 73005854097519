import { useState, useRef, useEffect } from 'react';
import { useSettings } from '../../../../contexts/SettingsContext';
import { getLocationName, searchLocations } from '../../../../services/locationService';

export const useLocationSettings = () => {
  const { settings, updateSettings } = useSettings();
  const [location, setLocation] = useState(settings.location || '');
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleLocationChange = (event, { newValue }) => {
    setLocation(newValue);
  };

  const handleDeleteLocation = () => {
    updateSettings({ location: null });
    setLocation('');
  };

  const handleAutoDetectLocation = () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const locationName = await getLocationName(latitude, longitude);
            
            const locationUpdate = {
              location: locationName,
              coordinates: {
                lat: latitude,
                lon: longitude
              }
            };
            
            setLocation(locationName);
            updateSettings(locationUpdate);
          } catch (error) {
            console.error("Error getting location name:", error);
          } finally {
            setIsLoading(false);
          }
        },
        (error) => {
          console.error("Error detecting location:", error);
          setIsLoading(false);
        }
      );
    }
  };

  const debounceTimeoutRef = useRef(null);

  const onSuggestionsFetchRequested = ({ value }) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        const results = await searchLocations(value);
        setSuggestions(results);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);
    };
  }, []);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setLocation(suggestion.display_name);
    updateSettings({
      ...settings,
      location: suggestion.display_name,
      coordinates: {
        lat: suggestion.lat,
        lon: suggestion.lon
      }
    });
  };

  const getSuggestionValue = suggestion => suggestion.display_name;

  return {
    location,
    suggestions,
    isLoading,
    settings,
    handleLocationChange,
    handleAutoDetectLocation,
    handleDeleteLocation,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    getSuggestionValue,
  };
}; 