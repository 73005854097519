import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

const DailyDuaCard = ({ dailyDua, onNext, onPrevious, hideTitle, isLoading, error }) => {
  const { isDarkMode } = useDarkMode();
  
  // Safe handlers to prevent errors if props are missing
  const handleNext = (e) => {
    e.preventDefault();
    console.log('[DailyDuaCard] Next button clicked', { hasHandler: typeof onNext === 'function' });
    if (typeof onNext === 'function') {
      try {
        onNext();
      } catch (error) {
        console.error("[DailyDuaCard] Error in onNext handler:", error);
      }
    } else {
      console.warn("[DailyDuaCard] onNext handler is not defined");
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    console.log('[DailyDuaCard] Previous button clicked', { hasHandler: typeof onPrevious === 'function' });
    if (typeof onPrevious === 'function') {
      try {
        onPrevious();
      } catch (error) {
        console.error("[DailyDuaCard] Error in onPrevious handler:", error);
      }
    } else {
      console.warn("[DailyDuaCard] onPrevious handler is not defined");
    }
  };
  
  if (error || !dailyDua) {
    return (
      <div className={`p-6 rounded-xl border ${
        isDarkMode 
          ? 'bg-red-900/20 border-red-900/20 text-red-200' 
          : 'bg-red-50 border-red-100 text-red-600'
      }`}>
        <p>Unable to load daily dua. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className={`relative rounded-2xl overflow-hidden backdrop-blur-xl ${
        isDarkMode 
          ? 'bg-white/5' 
          : 'bg-white/60 shadow-[inset_0_0_1px_1px_rgba(255,255,255,0.9)]'
      }`}>
        {/* Header */}
        <div className="relative px-8 pt-6 pb-6">
          <div className="flex items-center space-x-2">
            <span className={`text-sm uppercase tracking-wider font-medium ${
              isDarkMode ? 'text-emerald-400' : 'text-emerald-700'
            }`}>
              Daily Dua
            </span>
          </div>
        </div>

        {/* Content section */}
        <div className={`relative px-8 py-10 ${
          isDarkMode 
            ? '' 
            : 'bg-gradient-to-b from-white/40 to-transparent'
        }`}>
          {/* Arabic text with decorative elements */}
          <div className="relative mb-8">
            <div className={`absolute top-0 left-1/2 -translate-x-1/2 w-20 h-px
              bg-gradient-to-r from-transparent ${
                isDarkMode 
                  ? 'via-emerald-500/50' 
                  : 'via-emerald-600/30'
              } to-transparent`} />
            <p className={`text-4xl text-center font-['Decotype_Thuluth'] leading-loose py-2 mt-8 mb-8 ${
              isDarkMode ? 'text-white' : 'text-gray-800'
            }`}>
              {dailyDua.arabic}
            </p>
            <div className={`absolute bottom-0 left-1/2 -translate-x-1/2 w-20 h-px
              bg-gradient-to-r from-transparent ${
                isDarkMode 
                  ? 'via-emerald-500/50' 
                  : 'via-emerald-600/30'
              } to-transparent`} />
          </div>

          {/* Translation with elegant typography */}
          <div className="space-y-4">
            <p className={`text-center text-lg font-light leading-relaxed ${
              isDarkMode ? 'text-gray-300' : 'text-gray-600'
            }`}>
              {dailyDua.translation}
            </p>
            {dailyDua.reference && (
              <p className={`text-center text-sm font-medium ${
                isDarkMode ? 'text-emerald-400' : 'text-emerald-700'
              }`}>
                {dailyDua.reference}
              </p>
            )}
          </div>
        </div>

        {/* Footer navigation */}
        <div className={`px-8 py-6 border-t ${
          isDarkMode ? 'border-white/5' : 'border-gray-100'
        }`}>
          <div className="flex justify-between items-center">
            <button 
              onClick={handlePrevious}
              className={`flex items-center space-x-2 transition-colors ${
                isDarkMode 
                  ? 'text-gray-400 hover:text-emerald-400'
                  : 'text-gray-500 hover:text-emerald-700'
              }`}
            >
              <CaretLeft size={20} weight="thin" />
              <span className="text-sm">Previous</span>
            </button>
            <button 
              onClick={handleNext}
              className={`flex items-center space-x-2 transition-colors ${
                isDarkMode 
                  ? 'text-gray-400 hover:text-emerald-400'
                  : 'text-gray-500 hover:text-emerald-700'
              }`}
            >
              <span className="text-sm">Next</span>
              <CaretRight size={20} weight="thin" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyDuaCard;