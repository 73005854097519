import React, { useState } from 'react';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useGoals } from '../../hooks/useGoals';
import { 
  Calendar,
  BarChart3,
  Target,
  BookOpen,
  HeartHandshake,
  ChevronDown,
  ArrowUpRight,
  ArrowDownRight
} from 'lucide-react';
import { format, subDays, startOfWeek, startOfMonth } from 'date-fns';
import { HIZB_RANGES } from '../../types/goals';
import { PROGRESS_STATUS } from '../../types/progress';

export default function AnalyticsPage() {
  const { isDarkMode } = useDarkMode();
  const [timeRange, setTimeRange] = useState('week');
  const [isTimeRangeOpen, setIsTimeRangeOpen] = useState(false);
  const { stats, categoryPerformance, activityData } = useAnalytics(timeRange);
  const todayStr = format(new Date(), 'yyyy-MM-dd');
  const { goals, loading: goalsLoading } = useGoals();

  const timeRangeOptions = [
    { value: 'week', label: 'This Week' },
    { value: 'month', label: 'This Month' },
    { value: 'year', label: 'This Year' }
  ];

  const totalTasks = activityData.reduce((acc, day) => acc + (day.totalTasks || 0), 0);
  const completedTasks = activityData.reduce((acc, day) => acc + (day.completedTasks || 0), 0);
  const missedTasks = activityData.reduce((acc, day) => acc + (day.missedTasks || 0), 0);

  const getDayClass = (day) => {
    if (day.totalTasks > 0) {
      if (day.completedTasks === day.totalTasks) {
        return isDarkMode ? 'bg-emerald-600/30' : 'bg-emerald-300';
      } else if (day.missedTasks > 0) {
        return isDarkMode ? 'bg-red-600/30' : 'bg-red-300';
      } else {
        return isDarkMode ? 'bg-amber-600/30' : 'bg-amber-300';
      }
    }
    return isDarkMode ? 'bg-gray-800' : 'bg-gray-100';
  };

  const getTaskStatusBadge = (progress) => {
    if (!progress) return 'Pending';
    return progress.status.charAt(0).toUpperCase() + progress.status.slice(1);
  };

  const getTaskDetails = (goal, progress, date) => {
    if (!progress?.details) {
      return 'No progress data';
    }

    if (goal.type === 'quran_reading') {
      return `Read ${progress.details.hizbRange} (${progress.completionPercentage}% complete)`;
    } else if (goal.type === 'dhikr') {
      if (goal.recurrenceType === 'prayer_based') {
        const { prayers } = progress.details;
        const completedPrayers = Object.entries(prayers)
          .filter(([_, status]) => status.completed)
          .map(([prayer]) => prayer.charAt(0).toUpperCase() + prayer.slice(1))
          .join(', ');
        return `After Prayer Dhikr${completedPrayers ? `: ${completedPrayers}` : ''}`;
      } else {
        const { completedCount, targetCount } = progress.details;
        return `Dhikr Progress: ${completedCount}/${targetCount}`;
      }
    }
    return 'Complete task';
  };

  const getStatusColor = (status, isDarkMode) => {
    switch (status) {
      case PROGRESS_STATUS.COMPLETED:
        return isDarkMode ? 'bg-emerald-500' : 'bg-emerald-600';
      case PROGRESS_STATUS.PARTIAL:
        return isDarkMode ? 'bg-amber-500' : 'bg-amber-600';
      case PROGRESS_STATUS.MISSED:
        return isDarkMode ? 'bg-red-500' : 'bg-red-600';
      default:
        return isDarkMode ? 'bg-white/20' : 'bg-gray-300';
    }
  };

  const getStatusBadgeColor = (status, isDarkMode) => {
    switch (status) {
      case PROGRESS_STATUS.COMPLETED:
        return isDarkMode 
          ? 'bg-emerald-500/20 text-emerald-400'
          : 'bg-emerald-100 text-emerald-600';
      case PROGRESS_STATUS.PARTIAL:
        return isDarkMode
          ? 'bg-amber-500/20 text-amber-400'
          : 'bg-amber-100 text-amber-600';
      case PROGRESS_STATUS.MISSED:
        return isDarkMode
          ? 'bg-red-500/20 text-red-400'
          : 'bg-red-100 text-red-600';
      default:
        return isDarkMode
          ? 'bg-gray-700 text-gray-300'
          : 'bg-gray-100 text-gray-600';
    }
  };

  return (
    <div className={`min-h-screen relative overflow-hidden ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      {/* Gradient Orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute -top-1/4 -left-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-100/40'
        }`} />
        <div className={`absolute -bottom-1/4 -right-1/4 w-[800px] h-[800px] rounded-full blur-3xl ${
          isDarkMode
            ? 'bg-emerald-500/[0.03]'
            : 'bg-emerald-50/60'
        }`} />
        
        {/* Additional light mode decorative elements */}
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-transparent to-white/40" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
      </div>

      {/* Header */}
      <header className="sticky top-0 z-40">
        <div 
          style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} 
          className={`px-4 py-4 ${
            isDarkMode 
              ? 'bg-black/20 backdrop-blur-xl border-b border-white/5' 
              : 'bg-white/60 backdrop-blur-xl border-b border-black/5'
          }`}
        >
          <div className="flex items-center justify-between">
            <h1 className={`text-2xl font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>Analytics</h1>
            
            {/* Time Range Selector */}
            <div className="relative">
              <button 
                onClick={() => setIsTimeRangeOpen(!isTimeRangeOpen)}
                className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
                  isDarkMode 
                    ? 'bg-white/5 hover:bg-white/10' 
                    : 'bg-black/5 hover:bg-black/10'
                }`}
              >
                <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                  {timeRangeOptions.find(opt => opt.value === timeRange)?.label}
                </span>
                <ChevronDown className={`w-4 h-4 transition-transform ${isTimeRangeOpen ? 'rotate-180' : ''}`} />
              </button>

              {/* Dropdown Menu */}
              {isTimeRangeOpen && (
                <div className={`absolute right-0 mt-2 py-2 w-40 rounded-xl shadow-lg ${
                  isDarkMode 
                    ? 'bg-gray-900 border border-white/10' 
                    : 'bg-white border border-gray-100'
                }`}>
                  {timeRangeOptions.map(option => (
                    <button
                      key={option.value}
                      onClick={() => {
                        setTimeRange(option.value);
                        setIsTimeRangeOpen(false);
                      }}
                      className={`w-full px-4 py-2 text-left hover:bg-emerald-500/10 ${
                        option.value === timeRange
                          ? isDarkMode 
                            ? 'text-emerald-400' 
                            : 'text-emerald-600'
                          : isDarkMode
                            ? 'text-gray-300'
                            : 'text-gray-600'
                      }`}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Content */}
      <div className="relative z-10 px-4 py-6 space-y-6">
        {/* Overview Cards */}
        <div className="grid grid-cols-2 gap-4">
          {/* Completion Rate Card */}
          <div className={`relative overflow-hidden rounded-2xl p-4 ${
            isDarkMode 
              ? 'bg-white/5 backdrop-blur-xl' 
              : 'bg-white/60 backdrop-blur-xl'
          }`}>
            <div className="relative z-10">
              <div className={`w-10 h-10 mb-4 rounded-xl flex items-center justify-center ${
                isDarkMode
                  ? 'bg-emerald-500/10'
                  : 'bg-emerald-50'
              }`}>
                <Target className={`w-5 h-5 ${
                  isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                }`} />
              </div>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Completion Rate
              </p>
              <div className="flex items-baseline space-x-2 mt-1">
                <h3 className={`text-2xl font-semibold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {Math.round(stats.completionRate)}%
                </h3>
                <span className="flex items-center text-xs text-emerald-500">
                  <ArrowUpRight className="w-3 h-3" />
                  12%
                </span>
              </div>
            </div>
          </div>

          {/* Current Streak Card */}
          <div className={`relative overflow-hidden rounded-2xl p-4 ${
            isDarkMode 
              ? 'bg-white/5 backdrop-blur-xl' 
              : 'bg-white/60 backdrop-blur-xl'
          }`}>
            <div className="relative z-10">
              <div className={`w-10 h-10 mb-4 rounded-xl flex items-center justify-center ${
                isDarkMode
                  ? 'bg-emerald-500/10'
                  : 'bg-emerald-50'
              }`}>
                <Calendar className={`w-5 h-5 ${
                  isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                }`} />
              </div>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Current Streak
              </p>
              <div className="flex items-baseline space-x-2 mt-1">
                <h3 className={`text-2xl font-semibold ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  {stats.currentStreak} days
                </h3>
                <span className={`flex items-center text-xs ${
                  stats.currentStreak >= stats.bestStreak 
                    ? 'text-emerald-500' 
                    : 'text-amber-500'
                }`}>
                  {stats.currentStreak >= stats.bestStreak ? (
                    <ArrowUpRight className="w-3 h-3" />
                  ) : (
                    <ArrowDownRight className="w-3 h-3" />
                  )}
                  Best: {stats.bestStreak}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Category Performance */}
        <div className={`rounded-2xl overflow-hidden ${
          isDarkMode 
            ? 'bg-white/5 backdrop-blur-xl' 
            : 'bg-white/60 backdrop-blur-xl'
        }`}>
          <div className="p-4">
            <h2 className={`text-lg font-medium mb-4 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Category Performance
            </h2>
            <div className="space-y-4">
              {/* Quran Reading */}
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <BookOpen className={
                      isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                    } size={16} />
                    <span className={`text-sm ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Quran Reading
                    </span>
                  </div>
                  <span className={`text-sm ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {Math.round(categoryPerformance.quran_reading || 0)}%
                  </span>
                </div>
                <div className="h-2 rounded-full bg-gray-200 dark:bg-gray-700">
                  <div 
                    className="h-full rounded-full bg-emerald-500"
                    style={{ width: `${categoryPerformance.quran_reading || 0}%` }}
                  />
                </div>
              </div>

              {/* Dhikr */}
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <HeartHandshake className={
                      isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                    } size={16} />
                    <span className={`text-sm ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Dhikr
                    </span>
                  </div>
                  <span className={`text-sm ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {Math.round(categoryPerformance.dhikr || 0)}%
                  </span>
                </div>
                <div className="h-2 rounded-full bg-gray-200 dark:bg-gray-700">
                  <div 
                    className="h-full rounded-full bg-emerald-500"
                    style={{ width: `${categoryPerformance.dhikr || 0}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Overall Task Analytics */}
        <div className={`rounded-2xl overflow-hidden ${isDarkMode ? 'bg-white/5 backdrop-blur-xl' : 'bg-white/60 backdrop-blur-xl'}`}>
          <div className="p-4">
            <h2 className={`text-lg font-medium mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Overall Task Analytics
            </h2>
            <div className="grid grid-cols-3 gap-4">
              <div className={`rounded-xl p-4 ${isDarkMode ? 'bg-black/20' : 'bg-white/60'}`}>
                <div className="flex items-center gap-2">
                  <Target className="w-5 h-5" />
                  <span className={`text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    Completed Tasks: {completedTasks}
                  </span>
                </div>
              </div>
              <div className={`rounded-xl p-4 ${isDarkMode ? 'bg-black/20' : 'bg-white/60'}`}>
                <div className="flex items-center gap-2">
                  <BarChart3 className="w-5 h-5" />
                  <span className={`text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    Total Tasks: {totalTasks}
                  </span>
                </div>
              </div>
              <div className={`rounded-xl p-4 ${isDarkMode ? 'bg-black/20' : 'bg-white/60'}`}>
                <div className="flex items-center gap-2">
                  <ArrowDownRight className="w-5 h-5" />
                  <span className={`text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    Missed Tasks: {missedTasks}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Task History */}
        <div className={`rounded-2xl overflow-hidden ${isDarkMode ? 'bg-white/5 backdrop-blur-xl' : 'bg-white/60 backdrop-blur-xl'}`}>
          <div className="p-4">
            <h2 className={`text-lg font-medium mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Task History
            </h2>
            <div className="space-y-6">
              {goals.map((goal) => {
                // Get all dates from goal creation to today
                const startDate = new Date(goal.createdAt);
                const today = new Date();
                const dates = [];
                let currentDate = startDate;
                
                while (currentDate <= today) {
                  dates.push(format(currentDate, 'yyyy-MM-dd'));
                  currentDate.setDate(currentDate.getDate() + 1);
                }
                
                return (
                  <div key={goal.id} className="relative">
                    <h3 className={`text-md font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {goal.title || goal.details?.title || goal.type}
                    </h3>
                    
                    <div className="space-y-4">
                      {dates.map((date, index) => {
                        const progress = goal.progress[date];
                        const formattedDate = new Date(date).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        });
                        
                        return (
                          <div key={date} className="relative">
                            {/* Timeline dot and line */}
                            <div className="absolute left-0 top-2">
                              <div className={`w-3 h-3 rounded-full relative z-10 ${
                                getStatusColor(progress?.status, isDarkMode)
                              }`} />
                              {index !== dates.length - 1 && (
                                <div className={`absolute left-1/2 w-0.5 h-12 -translate-x-1/2 ${
                                  isDarkMode ? 'bg-white/10' : 'bg-gray-200'
                                }`} />
                              )}
                            </div>

                            {/* Content */}
                            <div className="ml-8">
                              <div className="flex items-start justify-between">
                                <div>
                                  <p className={`text-sm font-medium ${
                                    isDarkMode ? 'text-white' : 'text-gray-900'
                                  }`}>
                                    {formattedDate}
                                  </p>
                                  <p className={`text-sm mt-0.5 ${
                                    isDarkMode ? 'text-white/60' : 'text-gray-600'
                                  }`}>
                                    {getTaskDetails(goal, progress, date)}
                                  </p>
                                </div>
                                <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                                  getStatusBadgeColor(progress?.status, isDarkMode)
                                }`}>
                                  {getTaskStatusBadge(progress)}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Detailed Goal Progress */}
        <div className={`rounded-2xl overflow-hidden ${isDarkMode ? 'bg-white/5 backdrop-blur-xl' : 'bg-white/60 backdrop-blur-xl'}`}>
          <div className="p-4">
            <h2 className={`text-lg font-medium mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Detailed Goal Progress
            </h2>
            {goalsLoading ? (
              <p className={`text-sm ${isDarkMode ? 'text-white/60' : 'text-gray-600'}`}>Loading goals...</p>
            ) : goals && goals.length > 0 ? (
              <div className="space-y-4">
                {goals.map((goal) => {
                  const progressData = goal.progress ? goal.progress[todayStr] : null;
                  const percentComplete = progressData && progressData.percentComplete ? progressData.percentComplete : 0;
                  let goalIcon;
                  if (goal.type === 'quran_reading') {
                    goalIcon = <BookOpen className="w-5 h-5 text-emerald-500" />;
                  } else if (goal.type === 'dhikr') {
                    goalIcon = <HeartHandshake className="w-5 h-5 text-emerald-500" />;
                  } else if (goal.type === 'memorization') {
                    goalIcon = <ArrowUpRight className="w-5 h-5 text-emerald-500" />;
                  } else {
                    goalIcon = <Target className="w-5 h-5 text-emerald-500" />;
                  }
                  return (
                    <div key={goal.id} className="p-4 rounded-xl bg-white/10 backdrop-blur-sm shadow-lg">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          {goalIcon}
                          <h3 className={`text-md font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            {goal.title || goal.details?.title || goal.type}
                          </h3>
                        </div>
                        <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${isDarkMode ? 'bg-black text-white' : 'bg-gray-200 text-gray-800'}`}>
                          {goal.type === 'dhikr'
                            ? 'Dhikr'
                            : goal.type === 'quran_reading'
                            ? 'Quran Reading'
                            : goal.type === 'memorization'
                            ? 'Memorization'
                            : 'Other'}
                        </span>
                      </div>
                      <div className="mt-2 flex items-center">
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="h-full rounded-full bg-emerald-500 transition-all duration-300" style={{ width: `${percentComplete}%` }}></div>
                        </div>
                        <span className="ml-2 text-xs text-gray-700">{percentComplete}%</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className={`text-sm ${isDarkMode ? 'text-white/60' : 'text-gray-600'}`}>No goals available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 