import axios from 'axios';
import { auth } from '../firebase';
import { API_BASE_URL } from '../config';

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Add auth token to requests
api.interceptors.request.use(async (config) => {
  try {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error('Error getting auth token:', error);
  }
  return config;
});

// Add response interceptor with better retry logic
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    
    // Only retry once
    if (!config || config._retry) {
      return Promise.reject(error);
    }

    if (error.code === 'ERR_NETWORK') {
      config._retry = true;
      
      // Wait 1 second before retrying
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      try {
        return await api.request(config);
      } catch (retryError) {
        console.error('Retry failed:', retryError);
        return Promise.reject(retryError);
      }
    }

    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      const config = error.config;
      if (!config || !config._retry) {
        config._retry = true;
        return api(config);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
