export const isPWA = () => {
  // Check if the app is running in standalone mode (installed PWA)
  return window.matchMedia('(display-mode: standalone)').matches ||
         window.navigator.standalone === true;
};

export const isMobile = () => {
  // Check if the device is mobile based on screen width and user agent
  const mobileWidth = window.innerWidth <= 768;
  const mobileAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return mobileWidth || mobileAgent;
};

export const shouldUseRedirect = () => {
  // Use redirect for PWA or mobile devices
  return isPWA() || isMobile();
}; 