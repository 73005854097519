import axios from "axios";
import { API_BASE_URL } from '../config';

export const fetchDuas = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/duas`);
    return response.data;
  } catch (error) {
    console.error("Error fetching duas:", error);
    throw error;
  }
};
