import { useCallback, useRef, useEffect, useState } from 'react';
import { useSettings } from '../contexts/SettingsContext';
import { quranHelpers, SURAHS, HIZB_RANGES } from '../constants/quranConstants';
import { useGoals } from '../hooks/useGoals';
import { format } from 'date-fns';

export const useReadingProgress = () => {
  const { settings, updateSettings, isInitialized: isSettingsInitialized } = useSettings();
  const { goals, updateGoalProgress } = useGoals();
  
  // Keep track of reading state locally with better initialization
  const [readingState, setReadingState] = useState(() => {
    const defaultState = {
      currentSession: {
        surah: null,
        verse: null,
        timestamp: null
      },
      history: {}
    };

    // Only initialize from settings if they are available and valid
    if (isSettingsInitialized && settings?.quran?.readingProgress) {
      // Ensure the reading progress has the expected structure
      const readingProgress = settings.quran.readingProgress;
      
      // Check if the structure is valid
      if (readingProgress && 
          typeof readingProgress === 'object' && 
          readingProgress.currentSession && 
          typeof readingProgress.history === 'object') {
        return readingProgress;
      }
    }

    return defaultState;
  });

  // Update reading state when settings are initialized or changed
  useEffect(() => {
    if (isSettingsInitialized) {
      // Ensure we have a valid reading progress object
      const readingProgress = settings?.quran?.readingProgress;
      
      if (readingProgress && 
          typeof readingProgress === 'object' && 
          readingProgress.currentSession && 
          typeof readingProgress.history === 'object') {
        setReadingState(readingProgress);
      } else {
        // If reading progress is invalid, reset to default
        setReadingState({
          currentSession: {
            surah: null,
            verse: null,
            timestamp: null
          },
          history: {}
        });
      }
    }
  }, [isSettingsInitialized, settings?.quran?.readingProgress]);

  // Refs for managing updates
  const pendingUpdatesRef = useRef(new Set());
  const batchTimeoutRef = useRef(null);
  const isSavingRef = useRef(false);
  const hasUnsavedChangesRef = useRef(false);

  // Update reading goals
  const updateReadingGoals = useCallback(async (updates) => {
    if (!goals) return;

    const today = format(new Date(), 'yyyy-MM-dd');
    const readingGoals = goals.filter(goal => goal.type === 'quran_reading');
    
    console.log('⚠️ Updating reading goals with:', {
      updatesCount: Object.keys(updates).length,
      goalsCount: readingGoals.length,
      goals: readingGoals.map(g => g.id)
    });

    for (const goal of readingGoals) {
      try {
        // Get existing progress for today
        const existingProgress = goal.progress[today] || {};
        const existingVersesRead = existingProgress.details?.versesRead || [];
        
        // Format new verses read
        const newVersesRead = Object.entries(updates).flatMap(([surahId, verses]) => 
          Object.keys(verses).map(verse => `${surahId}:${verse}`)
        );

        // Combine existing and new verses, removing duplicates
        const allVersesRead = [...new Set([...existingVersesRead, ...newVersesRead])];

        // Calculate current hizb based on verses read
        let currentHizbIndex = existingProgress.details?.currentHizbIndex || 0;
        const lastVerse = newVersesRead[newVersesRead.length - 1];
        if (lastVerse) {
          const [surahId, verseNum] = lastVerse.split(':').map(Number);
          // Find the hizb that contains this verse
          for (let i = 0; i < HIZB_RANGES.length; i++) {
            const range = HIZB_RANGES[i].range;
            if (
              (surahId > range.start.surah || (surahId === range.start.surah && verseNum >= range.start.verse)) &&
              (surahId < range.end.surah || (surahId === range.end.surah && verseNum <= range.end.verse))
            ) {
              currentHizbIndex = Math.max(currentHizbIndex, i);
              break;
            }
          }
        }
        
        // Check if the progress should be marked as completed
        const targetHizbRange = goal.details.hizbRange;
        const todayHizbRange = goal.progress[today]?.details?.hizbRange;
        const isCompleted = existingProgress.details?.completed || false;
        
        console.log(`⚠️ Reading goal ${goal.id} progress update:`, {
          existingVersesCount: existingVersesRead.length,
          newVersesCount: newVersesRead.length,
          allVersesCount: allVersesRead.length,
          currentHizbIndex,
          targetHizbRange,
          todayHizbRange
        });

        // Update goal progress with new structure
        await updateGoalProgress(goal.id, {
          date: today,
          details: {
            versesRead: allVersesRead,
            currentHizbIndex,
            hizbRange: goal.details.hizbRange,
            totalVerses: goal.details.totalVerses || allVersesRead.length,
            completed: isCompleted // Maintain completion status
          }
        });

        console.log('✅ Updated reading progress:', {
          goalId: goal.id,
          versesRead: allVersesRead.length,
          currentHizbIndex,
          date: today
        });
      } catch (error) {
        console.error('❌ Error updating reading progress:', error);
      }
    }
  }, [goals, updateGoalProgress]);

  // Process batch updates
  const processBatchUpdate = useCallback(async () => {
    if (isSavingRef.current || !isSettingsInitialized) return;
    
    const pendingUpdates = Array.from(pendingUpdatesRef.current);
    if (pendingUpdates.length === 0) return;

    console.log('⏰ Processing batch update:', { 
      pendingUpdates,
      currentReadingState: readingState,
      currentSettings: settings?.quran?.readingProgress
    });
    isSavingRef.current = true;

    try {
      // Safety check for readingState
      if (!readingState || typeof readingState !== 'object' || !readingState.history) {
        console.warn('⚠️ Invalid reading state structure in processBatchUpdate');
        // Reset reading state to default
        const defaultReadingState = {
          currentSession: {
            surah: null,
            verse: null,
            timestamp: null
          },
          history: {}
        };
        setReadingState(defaultReadingState);
        
        // Clear pending updates
        pendingUpdatesRef.current.clear();
        hasUnsavedChangesRef.current = false;
        isSavingRef.current = false;
        return;
      }

      // Create new history with pending updates
      const newHistory = { ...readingState.history };
      let lastSurahId, lastVerseNum;

      // Process all pending updates in order
      pendingUpdates.forEach(key => {
        const [surahId, verseNum] = key.split(':');
        if (!newHistory[surahId]) {
          newHistory[surahId] = {};
        }
        
        // Fill in any gaps if we're adding non-consecutive verses
        // This ensures we don't miss verses when scrolling quickly
        if (lastSurahId === surahId) {
          const lastVerse = parseInt(lastVerseNum);
          const currentVerse = parseInt(verseNum);
          
          // If there's a gap and it's small (1-3 verses), fill it in
          if (currentVerse - lastVerse > 1 && currentVerse - lastVerse <= 3) {
            for (let gapVerse = lastVerse + 1; gapVerse < currentVerse; gapVerse++) {
              console.log(`🧩 Filling batch gap for verse: ${surahId}:${gapVerse}`);
              if (!newHistory[surahId][gapVerse]) {
                newHistory[surahId][gapVerse] = {
                  readCount: 1,
                  lastRead: Date.now()
                };
              }
            }
          }
        }
        
        // Add the current verse
        newHistory[surahId][verseNum] = {
          readCount: (newHistory[surahId][verseNum]?.readCount || 0) + 1,
          lastRead: Date.now()
        };
        
        lastSurahId = surahId;
        lastVerseNum = verseNum;
      });

      // Create new reading state
      const newReadingState = {
        currentSession: {
          surah: lastSurahId,
          verse: lastVerseNum,
          timestamp: Date.now()
        },
        history: newHistory
      };

      console.log('📚 New reading state:', {
        newReadingState,
        lastSurahId,
        lastVerseNum,
        historyForSurah: newHistory[lastSurahId]
      });

      // Update local state
      setReadingState(newReadingState);

      // Update settings with the new state we just created
      const newSettings = {
        ...settings,
        quran: {
          ...settings.quran,
          readingProgress: newReadingState,
          lastReadingSession: {
            surahId: lastSurahId,
            verseId: `${lastSurahId}:${lastVerseNum}`,
            timestamp: Date.now()
          }
        }
      };

      console.log('⚙️ Updating settings with:', {
        newSettings: newSettings.quran.readingProgress,
        lastReadingSession: newSettings.quran.lastReadingSession
      });

      await updateSettings(newSettings);
      await updateReadingGoals(newHistory);

      // Clear pending updates only after successful update
      pendingUpdatesRef.current.clear();
      hasUnsavedChangesRef.current = false;
      console.log('✅ Batch update complete');
    } catch (error) {
      console.error('❌ Error updating reading progress:', error);
      hasUnsavedChangesRef.current = true;
    } finally {
      isSavingRef.current = false;
    }
  }, [settings, updateSettings, updateReadingGoals, isSettingsInitialized, readingState]);

  // Mark verse as read
  const markVerseAsRead = useCallback((surahId, verseId) => {
    if (!isSettingsInitialized) {
      console.warn('⚠️ Settings not initialized');
      return;
    }

    // Safety check for readingState
    if (!readingState || typeof readingState !== 'object' || !readingState.history) {
      console.warn('⚠️ Invalid reading state structure');
      return;
    }

    // Ensure verseId is in the correct format
    const cleanVerseId = verseId.includes(':') ? verseId.split(':')[1] : verseId;
    const verseKey = `${surahId}:${cleanVerseId}`;

    // Check if this verse is already marked as read in the current readingState history
    // Use both history check and pendingUpdates check to prevent duplicate tracking
    if (
      (readingState?.history[surahId] && readingState.history[surahId][cleanVerseId]) ||
      pendingUpdatesRef.current.has(verseKey)
    ) {
      console.log(`🔄 Skipping already marked verse: ${verseKey}`);
      return;
    }

    // Debug logging to track verse reading
    console.log('📝 Marking verse as read:', { 
      verseKey,
      pendingCount: pendingUpdatesRef.current.size,
      alreadyMarked: readingState?.history[surahId]?.[cleanVerseId] ? true : false
    });

    // Add to pending updates with additional protection against duplicates
    pendingUpdatesRef.current.add(verseKey);
    hasUnsavedChangesRef.current = true;

    // Process immediately if too many pending
    if (pendingUpdatesRef.current.size >= 5) { // Reduced from 10 to 5 for more frequent updates
      if (batchTimeoutRef.current) {
        clearTimeout(batchTimeoutRef.current);
        batchTimeoutRef.current = null;
      }
      processBatchUpdate();
      return;
    }

    // Schedule batch update with shorter timeout (500ms instead of 1000ms)
    if (batchTimeoutRef.current) {
      clearTimeout(batchTimeoutRef.current);
    }
    batchTimeoutRef.current = setTimeout(() => {
      processBatchUpdate();
      batchTimeoutRef.current = null;
    }, 200); // Further reduced from 500ms to 200ms for more responsive updates
  }, [isSettingsInitialized, processBatchUpdate, readingState]);

  // Safety net save interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (hasUnsavedChangesRef.current && !isSavingRef.current) {
        processBatchUpdate();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [processBatchUpdate]);

  // Save on visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && hasUnsavedChangesRef.current) {
        processBatchUpdate();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [processBatchUpdate]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (hasUnsavedChangesRef.current) {
        processBatchUpdate();
      }
      if (batchTimeoutRef.current) {
        clearTimeout(batchTimeoutRef.current);
      }
    };
  }, [processBatchUpdate]);

  // Get reading progress helpers
  const getReadingProgress = useCallback((surahId) => {
    return readingState.history[surahId] || {};
  }, [readingState.history]);

  const getRangeProgress = useCallback((startSurah, startVerse, endSurah, endVerse) => {
    let readVerses = 0;
    const totalVerses = quranHelpers.getVerseCount(startSurah, startVerse, endSurah, endVerse);
    
    for (let surahNum = startSurah; surahNum <= endSurah; surahNum++) {
      const surahHistory = readingState.history[surahNum] || {};
      const surah = SURAHS.find(s => s.number === surahNum);
      if (!surah) continue;

      const rangeStart = surahNum === startSurah ? startVerse : 1;
      const rangeEnd = surahNum === endSurah ? endVerse : surah.totalVerses;

      for (let verse = rangeStart; verse <= rangeEnd; verse++) {
        if (surahHistory[verse]) {
          readVerses++;
        }
      }
    }

    return {
      readVerses,
      totalVerses,
      percentComplete: Math.round((readVerses / totalVerses) * 100)
    };
  }, [readingState.history]);

  return {
    readingState,
    markVerseAsRead,
    getReadingProgress,
    getRangeProgress
  };
}; 