export const namesOfAllah = [
    { arabic: "الرَّحْمَنُ", transliteration: "Ar-Rahman", meaning: "The Most Gracious" },
    { arabic: "الرَّحِيمُ", transliteration: "Ar-Raheem", meaning: "The Most Merciful" },
    { arabic: "الْمَلِكُ", transliteration: "Al-Malik", meaning: "The King" },
    { arabic: "الْقُدُّوسُ", transliteration: "Al-Quddus", meaning: "The Holy" },
    { arabic: "السَّلاَمُ", transliteration: "As-Salam", meaning: "The Source of Peace" },
    { arabic: "الْمُؤْمِنُ", transliteration: "Al-Mu’min", meaning: "The Giver of Faith" },
    { arabic: "الْمُهَيْمِنُ", transliteration: "Al-Muhaymin", meaning: "The Protector" },
    { arabic: "الْعَزِيزُ", transliteration: "Al-Aziz", meaning: "The Almighty" },
    { arabic: "الْجَبَّارُ", transliteration: "Al-Jabbar", meaning: "The Compeller" },
    { arabic: "الْمُتَكَبِّرُ", transliteration: "Al-Mutakabbir", meaning: "The Majestic" },
    { arabic: "الْخَالِقُ", transliteration: "Al-Khaliq", meaning: "The Creator" },
    { arabic: "الْبَارِئُ", transliteration: "Al-Bari'", meaning: "The Evolver" },
    { arabic: "الْمُصَوِّرُ", transliteration: "Al-Musawwir", meaning: "The Fashioner" },
    { arabic: "الْغَفَّارُ", transliteration: "Al-Ghaffar", meaning: "The Constant Forgiver" },
    { arabic: "الْقَهَّارُ", transliteration: "Al-Qahhar", meaning: "The All-Subduer" },
    { arabic: "الْوَهَّابُ", transliteration: "Al-Wahhab", meaning: "The Giver of Gifts" },
    { arabic: "الرَّزَّاقُ", transliteration: "Ar-Razzaq", meaning: "The Provider" },
    { arabic: "الْفَتَّاحُ", transliteration: "Al-Fattah", meaning: "The Opener" },
    { arabic: "اَلْعَلِيْمُ", transliteration: "Al-'Alim", meaning: "The All-Knowing" },
    { arabic: "الْقَابِضُ", transliteration: "Al-Qabid", meaning: "The Withholder" },
    { arabic: "الْبَاسِطُ", transliteration: "Al-Basit", meaning: "The Extender" },
    { arabic: "الْخَافِضُ", transliteration: "Al-Khafid", meaning: "The Abaser" },
    { arabic: "الرَّافِعُ", transliteration: "Ar-Rafi'", meaning: "The Exalter" },
    { arabic: "المُعِزُّ", transliteration: "Al-Mu'izz", meaning: "The Honourer" },
    { arabic: "المُذِلُّ", transliteration: "Al-Mudhill", meaning: "The Dishonourer" },
    { arabic: "السَّمِيعُ", transliteration: "As-Sami'", meaning: "The All-Hearing" },
    { arabic: "الْبَصِيرُ", transliteration: "Al-Basir", meaning: "The All-Seeing" },
    { arabic: "الْحَكَمُ", transliteration: "Al-Hakam", meaning: "The Judge" },
    { arabic: "الْعَدْلُ", transliteration: "Al-'Adl", meaning: "The Just" },
    { arabic: "اللَّطِيفُ", transliteration: "Al-Latif", meaning: "The Subtle One" },
    { arabic: "الْخَبِيرُ", transliteration: "Al-Khabir", meaning: "The All-Aware" },
    { arabic: "الْحَلِيمُ", transliteration: "Al-Halim", meaning: "The Forbearing" },
    { arabic: "الْعَظِيمُ", transliteration: "Al-'Azim", meaning: "The Magnificent" },
    { arabic: "الْغَفُورُ", transliteration: "Al-Ghafur", meaning: "The Great Forgiver" },
    { arabic: "الشَّكُورُ", transliteration: "Ash-Shakur", meaning: "The Most Grateful" },
    { arabic: "الْعَلِيُّ", transliteration: "Al-'Aliyy", meaning: "The Most High" },
    { arabic: "الْكَبِيرُ", transliteration: "Al-Kabir", meaning: "The Most Great" },
    { arabic: "الْحَفِيظُ", transliteration: "Al-Hafidh", meaning: "The Preserver" },
    { arabic: "المُقيِت", transliteration: "Al-Muqit", meaning: "The Sustainer" },
    { arabic: "الْحسِيبُ", transliteration: "Al-Hasib", meaning: "The Reckoner" },
    { arabic: "الْجَلِيلُ", transliteration: "Al-Jalil", meaning: "The Majestic" },
    { arabic: "الْكَرِيمُ", transliteration: "Al-Karim", meaning: "The Generous" },
    { arabic: "الرَّقِيبُ", transliteration: "Ar-Raqib", meaning: "The Watchful" },
    { arabic: "المُجِيبُ", transliteration: "Al-Mujib", meaning: "The Responsive" },
    { arabic: "الْوَاسِعُ", transliteration: "Al-Wasi'", meaning: "The All-Encompassing" },
    { arabic: "الْحَكِيمُ", transliteration: "Al-Hakim", meaning: "The Wise" },
    { arabic: "الْوَدُودُ", transliteration: "Al-Wadud", meaning: "The Most Loving" },
    { arabic: "الْمَجِيدُ", transliteration: "Al-Majid", meaning: "The Glorious" },
    { arabic: "الْبَاعِثُ", transliteration: "Al-Ba'ith", meaning: "The Resurrector" },
    { arabic: "الشَّهِيدُ", transliteration: "Ash-Shahid", meaning: "The Witness" },
    { arabic: "الْحَقُ", transliteration: "Al-Haqq", meaning: "The Truth" },
    { arabic: "الْوَكِيلُ", transliteration: "Al-Wakil", meaning: "The Trustee" },
    { arabic: "الْقَوِيُ", transliteration: "Al-Qawiyy", meaning: "The Most Strong" },
    { arabic: "المَتِينُ", transliteration: "Al-Matin", meaning: "The Firm" },
    { arabic: "الْوَلِيُّ", transliteration: "Al-Waliyy", meaning: "The Protecting Friend" },
    { arabic: "الْحَمِيدُ", transliteration: "Al-Hamid", meaning: "The Praiseworthy" },
    { arabic: "المُحصِي", transliteration: "Al-Muhsi", meaning: "The All-Enumerating" },
    { arabic: "المبدِئ", transliteration: "Al-Mubdi'", meaning: "The Originator" },
    { arabic: "المعيد", transliteration: "Al-Mu'id", meaning: "The Restorer" },
    { arabic: "المُحيي", transliteration: "Al-Muhyi", meaning: "The Giver of Life" },
    { arabic: "اَلْمُمِيتُ", transliteration: "Al-Mumit", meaning: "The Creator of Death" },
    { arabic: "الْحَيُّ", transliteration: "Al-Hayy", meaning: "The Ever-Living" },
    { arabic: "الْقَيُّومُ", transliteration: "Al-Qayyum", meaning: "The Sustainer" },
    { arabic: "الْوَاجِدُ", transliteration: "Al-Wajid", meaning: "The Perceiver" },
    { arabic: "الْمَاجِدُ", transliteration: "Al-Majid", meaning: "The Illustrious" },
    { arabic: "الواحِد", transliteration: "Al-Wahid", meaning: "The One" },
    { arabic: "اَلاَحَدُ", transliteration: "Al-Ahad", meaning: "The Unique" },
    { arabic: "الصَّمَدُ", transliteration: "As-Samad", meaning: "The Eternal" },
    { arabic: "الْقَادِرُ", transliteration: "Al-Qadir", meaning: "The Omnipotent" },
    { arabic: "المُقتدِر", transliteration: "Al-Muqtadir", meaning: "The All-Determining" },
    { arabic: "الْمُقَدِّمُ", transliteration: "Al-Muqaddim", meaning: "The Expediter" },
    { arabic: "الْمُؤَخِّرُ", transliteration: "Al-Mu'akhkhir", meaning: "The Delayer" },
    { arabic: "الأوَّلُ", transliteration: "Al-Awwal", meaning: "The First" },
    { arabic: "الآخِرُ", transliteration: "Al-Akhir", meaning: "The Last" },
    { arabic: "الظَّاهِرُ", transliteration: "Az-Zahir", meaning: "The Manifest" },
    { arabic: "البَاطِنُ", transliteration: "Al-Batin", meaning: "The Hidden" },
    { arabic: "الْوَالِي", transliteration: "Al-Wali", meaning: "The Sole Governor" },
    { arabic: "المتعالِي", transliteration: "Al-Muta'ali", meaning: "The Self Exalted" },
    { arabic: "البَرُ", transliteration: "Al-Barr", meaning: "The Source of All Goodness" },
    { arabic: "التَّوَابُ", transliteration: "At-Tawwab", meaning: "The Ever-Pardoning" },
    { arabic: "المُنْتَقِم", transliteration: "Al-Muntaqim", meaning: "The Avenger" },
    { arabic: "العَفُوُ", transliteration: "Al-Afuw", meaning: "The Pardoner" },
    { arabic: "الرؤوف", transliteration: "Ar-Ra'uf", meaning: "The Most Kind" },
    { arabic: "مَالِكُ ٱلْمُلْكُ", transliteration: "Malik-ul-Mulk", meaning: "Master of the Kingdom" },
    { arabic: "ذُوالْجَلاَلِ وَالإكْرَامِ", transliteration: "Dhul-Jalali Wal-Ikram", meaning: "Possessor of Glory and Honour" },
    { arabic: "المُقسِط", transliteration: "Al-Muqsit", meaning: "The Just" },
    { arabic: "الْجَامِعُ", transliteration: "Al-Jami'", meaning: "The Gatherer" },
    { arabic: "ٱلْغَنيُّ", transliteration: "Al-Ghaniyy", meaning: "The Self-Sufficient" },
    { arabic: "المغني", transliteration: "Al-Mughni", meaning: "The Enricher" },
    { arabic: "اَلْمَانِعُ", transliteration: "Al-Mani'", meaning: "The Withholder" },
    { arabic: "الضَّارَ", transliteration: "Ad-Darr", meaning: "The Distresser" },
    { arabic: "النَّافِعُ", transliteration: "An-Nafi'", meaning: "The Benefiter" },
    { arabic: "النُّورُ", transliteration: "An-Nur", meaning: "The Light" },
    { arabic: "الْهَادِي", transliteration: "Al-Hadi", meaning: "The Guide" },
    { arabic: "البدِيعُ", transliteration: "Al-Badi'", meaning: "The Incomparable" },
    { arabic: "البَاقِي", transliteration: "Al-Baqi", meaning: "The Ever-Surviving" },
    { arabic: "ٱلْوَارِثُ", transliteration: "Al-Warith", meaning: "The Inheritor" },
    { arabic: "الرَّشِيدُ", transliteration: "Ar-Rashid", meaning: "The Guide to the Right Path" },
    { arabic: "الصَّبُورُ", transliteration: "As-Sabur", meaning: "The Most Patient" }
  ];
  