import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const ModernAdhanClockTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  // Use the hook
  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Handle background image slideshow
  useEffect(() => {
    if (!settings.simpleDashboard?.backgroundImages?.length) return;
    
    const interval = setInterval(() => {
      setPreviousBackgroundIndex(currentBackgroundIndex);
      setCurrentBackgroundIndex(prev => 
        (prev + 1) % settings.simpleDashboard.backgroundImages.length
      );
    }, (settings.simpleDashboard.slideshowDuration || 60) * 1000);

    return () => clearInterval(interval);
  }, [currentBackgroundIndex, settings.simpleDashboard?.backgroundImages, settings.simpleDashboard?.slideshowDuration]);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Images */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Primary Overlay */}
      <div className="absolute inset-0 bg-black/75 z-10" />

      {/* Main Layout */}
      <div className="relative h-full flex flex-col z-20">
        {/* Top Section */}
        <div className="flex-1 grid grid-cols-2">
          {/* Left Column - Time Display */}
          <div className="border-r border-white/10">
            <div className="p-6 lg:p-8 xl:p-12 h-full flex flex-col">
              {/* Location */}
              <div className="mb-auto">
                <h1 className="text-lg sm:text-xl lg:text-2xl font-light mb-1">{settings.location}</h1>
                <p className="text-white/60 text-sm">{settings.region} {settings.country}</p>
              </div>

              <div className="mb-4 lg:mb-6">
                <p className="text-white/40 text-sm tracking-widest uppercase mb-2">Current Time</p>
                <SimpleTimeCard 
                  render={({ time }) => (
                    <div className="text-5xl sm:text-6xl lg:text-7xl xl:text-8xl font-thin tracking-tight leading-none">
                      {time}
                    </div>
                  )}
                />
              </div>

              <SimplePrayerCountdownCard
                prayerTimes={prayerTimes}
                render={({ countdown, nextPrayer }) => (
                  <div>
                    <p className="text-white/40 text-sm tracking-widest uppercase mb-2">
                      Time Until {nextPrayer}
                    </p>
                    <div className={`text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-thin tracking-tight leading-none ${accentColor.base}`}>
                      {countdown}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>

          {/* Right Column - Prayer Times */}
          <div>
            <div className="p-6 lg:p-8 xl:p-12 h-full">
              <SimplePrayerTimesCard
                prayerTimes={prayerTimes}
                settings={settings}
                render={({ prayers }) => (
                  <div className="grid grid-cols-3 gap-3 h-full">
                    {prayers.map(([prayer, time], index) => {
                      const Icon = prayer === 'Fajr' ? Sunrise :
                               prayer === 'Maghrib' ? Sunset :
                               prayer === 'Isha' ? Moon : Sun;
                      
                      const isNext = prayer === nextPrayer;
                      
                      // Make the next prayer span 2 columns and be larger
                      const colSpanClass = isNext ? 'col-span-2' : 'col-span-1';
                      const heightClass = isNext ? 'row-span-2' : 'row-span-1';
                      
                      return (
                        <div 
                          key={prayer}
                          className={`${colSpanClass} ${heightClass} p-4 lg:p-5 rounded-2xl backdrop-blur-sm transition-all duration-300 ${
                            isNext ? `${accentColor.bg} border ${accentColor.border}` : 'bg-white/5 hover:bg-white/10'
                          }`}
                        >
                          <div className="flex items-center justify-between mb-2 lg:mb-3">
                            <p className={`${isNext ? accentColor.base : 'text-white'} text-sm lg:text-base tracking-widest uppercase`}>
                              {prayer}
                            </p>
                            <Icon className={`w-5 h-5 lg:w-6 lg:h-6 ${isNext ? accentColor.base : 'text-white'}`} />
                          </div>
                          <p className={`text-2xl lg:text-3xl xl:text-4xl font-light ${isNext ? accentColor.base : 'text-white'}`}>
                            {time}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="grid grid-cols-3 border-t border-white/10">
          <div className="p-4 lg:p-6 border-r border-white/10">
            <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Gregorian</p>
            <p className="text-sm lg:text-base">{dateTime?.gregorian}</p>
          </div>
          <div className="p-4 lg:p-6 border-r border-white/10">
            <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Hijri</p>
            <p className="text-sm lg:text-base">{dateTime?.hijri?.western}</p>
          </div>
          <div className="p-4 lg:p-6">
            <p className="text-sm lg:text-base text-white/60 text-right leading-relaxed font-uthmani">
              {duas?.[currentDuaIndex]?.arabic}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModernAdhanClockTemplate; 