import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const MinimalistAdhanClockTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [previousBackgroundIndex, setPreviousBackgroundIndex] = useState(0);
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  // Handle background image slideshow
  useEffect(() => {
    if (!settings.simpleDashboard?.backgroundImages?.length) return;
    
    const interval = setInterval(() => {
      setPreviousBackgroundIndex(currentBackgroundIndex);
      setCurrentBackgroundIndex(prev => 
        (prev + 1) % settings.simpleDashboard.backgroundImages.length
      );
    }, (settings.simpleDashboard.slideshowDuration || 60) * 1000);

    return () => clearInterval(interval);
  }, [currentBackgroundIndex, settings.simpleDashboard?.backgroundImages, settings.simpleDashboard?.slideshowDuration]);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  // Replace audio logic with hook
  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  return (
    <div className="h-screen w-full relative text-white">
      {/* Background Image */}
      <CrossfadingBackground
        images={settings.simpleDashboard?.backgroundImages}
        currentIndex={currentBackgroundIndex}
        previousIndex={previousBackgroundIndex}
      />
      
      {/* Primary Overlay with custom gradient - adjusted opacity */}
      <div className="absolute inset-0 bg-gradient-to-br from-black/80 via-black/70 to-black/80 z-10" />

      {/* Content Layout */}
      <div className="relative h-full grid grid-cols-10 gap-4 z-20">
        {/* Main Content - Left Side */}
        <div className="col-span-6 p-8 flex flex-col justify-between">
          {/* Top Section with Location and Time */}
          <div>
            {/* Location */}
            <div className="mb-4">
              <h1 className="text-2xl font-light tracking-wide">{settings.location || 'Your Location'}</h1>
              <p className="text-white/50 text-sm mt-1">{settings.region} {settings.country}</p>
            </div>

            {/* Date Section */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <SimpleDateCard 
                dateTime={dateTime}
                onDateChange={onDateChange}
                render={({ gregorian, hijri }) => (
                  <>
                    <div>
                      <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Gregorian</p>
                      <p className="text-sm lg:text-lg">{gregorian}</p>
                    </div>
                    <div>
                      <p className="text-white/40 text-xs tracking-widest uppercase mb-1">Hijri</p>
                      <p className="text-sm lg:text-lg">{hijri}</p>
                    </div>
                  </>
                )}
              />
            </div>
          </div>

          {/* Dua - Centered vertically and horizontally */}
          <div className="flex-grow flex items-center justify-center">
            <p className="text-4xl text-center font-['Decotype_Thuluth'] leading-loose text-white/80">
              {duas?.[currentDuaIndex]?.arabic}
            </p>
          </div>

          {/* Bottom Section with Time */}
          <div>
            {/* Current Time - Made bigger */}
            <div>
              <p className="text-white/40 text-xs tracking-widest uppercase mb-2">Current Time</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <div className="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl font-thin tracking-tight leading-none">
                    {time}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        {/* Prayer Times - Right Panel */}
        <div className="col-span-4 relative pl-8">
          <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-white/30 to-transparent"></div>
          <div className="h-full flex flex-col p-8">
            {/* Prayer Times List */}
            <div className="flex-1">
              <div className="space-y-3">
                <SimplePrayerTimesCard
                  prayerTimes={prayerTimes}
                  settings={settings}
                  render={({ prayers }) => (
                    <>
                      {prayers.map(([prayer, time]) => {
                        const Icon = prayer === 'Fajr' ? Sunrise :
                                   prayer === 'Maghrib' ? Sunset :
                                   prayer === 'Isha' ? Moon : Sun;
                        
                        const isNext = prayer === nextPrayer;
                        
                        return (
                          <div key={prayer}>
                            <div className="flex items-center justify-end gap-2 mb-1">
                              <p className={`${isNext ? accentColor.base : 'text-white'} text-sm tracking-wide`}>
                                {prayer}
                              </p>
                              <Icon className={`w-4 h-4 sm:w-5 sm:h-5 ${isNext ? accentColor.base : 'text-white'}`} />
                            </div>
                            <p className={`text-2xl sm:text-3xl lg:text-4xl font-light text-right ${isNext ? accentColor.base : 'text-white'}`}>
                              {time}
                            </p>
                          </div>
                        );
                      })}
                    </>
                  )}
                />
              </div>
            </div>

            {/* Next Prayer Countdown - Moved to bottom right */}
            <div className="mt-auto">
              <SimplePrayerCountdownCard
                prayerTimes={prayerTimes}
                render={({ countdown, nextPrayer }) => (
                  <div>
                    <div className="mb-2">
                      <div className="flex items-center gap-2 justify-end">
                        <div className={`${accentColor.bg} w-2 h-2 rounded-full`}></div>
                        <p className={`${accentColor.base} text-sm tracking-wide`}>
                          Next Prayer: {nextPrayer}
                        </p>
                      </div>
                    </div>
                    <div className={`${accentColor.base} text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-light tracking-wide leading-none text-right`}>
                      {countdown}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinimalistAdhanClockTemplate; 