import { DEFAULT_DHIKR_GOALS } from './dhikr';
import { QURAN_STRUCTURE } from '../constants/quranConstants';

// Constants
export const PRAYER_NAMES = ['fajr', 'dhuhr', 'asr', 'maghrib', 'isha'];

export const TIME_WINDOWS = {
  morning: { start: '04:00', end: '12:00' },
  evening: { start: '15:00', end: '19:00' }
};

export const DEFAULT_DURATIONS = {
  morning_adhkar: { start: 'fajr', end: 'dhuhr' },
  evening_adhkar: { start: 'asr', end: 'maghrib' }
};

export const HIZB_RANGES = [
  { hizb: 1, range: "Al-Fatiha 1:1 - Al-Baqarah 2:74" },
  { hizb: 2, range: "Al-Baqarah 2:75 - 2:141" },
  { hizb: 3, range: "Al-Baqarah 2:142 - 2:202" },
  { hizb: 4, range: "Al-Baqarah 2:203 - 2:252" },
  { hizb: 5, range: "Al-Baqarah 2:253 - Al-Imran 3:14" },
  { hizb: 6, range: "Al-Imran 3:15 - 3:92" },
  { hizb: 7, range: "Al-Imran 3:93 - 3:170" },
  { hizb: 8, range: "Al-Imran 3:171 - An-Nisa 4:23" },
  { hizb: 9, range: "An-Nisa 4:24 - 4:87" },
  { hizb: 10, range: "An-Nisa 4:88 - 4:147" },
  { hizb: 11, range: "An-Nisa 4:148 - Al-Maidah 5:26" },
  { hizb: 12, range: "Al-Maidah 5:27 - 5:81" },
  { hizb: 13, range: "Al-Maidah 5:82 - Al-Anam 6:35" },
  { hizb: 14, range: "Al-Anam 6:36 - 6:110" },
  { hizb: 15, range: "Al-Anam 6:111 - 6:165" },
  { hizb: 16, range: "Al-Araf 7:1 - 7:87" },
  { hizb: 17, range: "Al-Araf 7:88 - 7:170" },
  { hizb: 18, range: "Al-Araf 7:171 - Al-Anfal 8:40" },
  { hizb: 19, range: "Al-Anfal 8:41 - At-Tawbah 9:33" },
  { hizb: 20, range: "At-Tawbah 9:34 - 9:92" },
  { hizb: 21, range: "At-Tawbah 9:93 - Yunus 10:25" },
  { hizb: 22, range: "Yunus 10:26 - Hud 11:5" },
  { hizb: 23, range: "Hud 11:6 - 11:83" },
  { hizb: 24, range: "Hud 11:84 - Yusuf 12:52" },
  { hizb: 25, range: "Yusuf 12:53 - Ar-Rad 13:18" },
  { hizb: 26, range: "Ar-Rad 13:19 - Ibrahim 14:52" },
  { hizb: 27, range: "Al-Hijr 15:1 - An-Nahl 16:50" },
  { hizb: 28, range: "An-Nahl 16:51 - 16:128" },
  { hizb: 29, range: "Al-Isra 17:1 - 17:98" },
  { hizb: 30, range: "Al-Isra 17:99 - Al-Kahf 18:74" },
  { hizb: 31, range: "Al-Kahf 18:75 - Maryam 19:98" },
  { hizb: 32, range: "Ta-Ha 20:1 - 20:135" },
  { hizb: 33, range: "Al-Anbiya 21:1 - 21:112" },
  { hizb: 34, range: "Al-Hajj 22:1 - 22:78" },
  { hizb: 35, range: "Al-Muminun 23:1 - An-Nur 24:20" },
  { hizb: 36, range: "An-Nur 24:21 - Al-Furqan 25:20" },
  { hizb: 37, range: "Al-Furqan 25:21 - Ash-Shuara 26:110" },
  { hizb: 38, range: "Ash-Shuara 26:111 - An-Naml 27:55" },
  { hizb: 39, range: "An-Naml 27:56 - Al-Qasas 28:50" },
  { hizb: 40, range: "Al-Qasas 28:51 - Al-Ankabut 29:45" },
  { hizb: 41, range: "Al-Ankabut 29:46 - Luqman 31:21" },
  { hizb: 42, range: "Luqman 31:22 - Al-Ahzab 33:30" },
  { hizb: 43, range: "Al-Ahzab 33:31 - Saba 34:23" },
  { hizb: 44, range: "Saba 34:24 - Ya-Sin 36:27" },
  { hizb: 45, range: "Ya-Sin 36:28 - As-Saffat 37:144" },
  { hizb: 46, range: "As-Saffat 37:145 - Az-Zumar 39:31" },
  { hizb: 47, range: "Az-Zumar 39:32 - Ghafir 40:40" },
  { hizb: 48, range: "Ghafir 40:41 - Fussilat 41:46" },
  { hizb: 49, range: "Fussilat 41:47 - Az-Zukhruf 43:23" },
  { hizb: 50, range: "Az-Zukhruf 43:24 - Al-Jathiyah 45:37" },
  { hizb: 51, range: "Al-Ahqaf 46:1 - Al-Fath 48:17" },
  { hizb: 52, range: "Al-Fath 48:18 - Adh-Dhariyat 51:30" },
  { hizb: 53, range: "Adh-Dhariyat 51:31 - Al-Qamar 54:55" },
  { hizb: 54, range: "Ar-Rahman 55:1 - Al-Hadid 57:29" },
  { hizb: 55, range: "Al-Mujadila 58:1 - As-Saff 61:14" },
  { hizb: 56, range: "Al-Jumuah 62:1 - At-Tahrim 66:12" },
  { hizb: 57, range: "Al-Mulk 67:1 - Nuh 71:28" },
  { hizb: 58, range: "Al-Jinn 72:1 - Al-Mursalat 77:50" },
  { hizb: 59, range: "An-Naba 78:1 - At-Tariq 86:17" },
  { hizb: 60, range: "Al-Ala 87:1 - An-Nas 114:6" }
];

export const GOAL_TYPE_CONFIG = {
  quran_reading: {
    defaultDailyAmount: 4, // pages
    maxDailyAmount: 20,
    defaultDuration: 30, // days
  },
  memorization: {
    defaultDailyAmount: 3, // verses
    maxDailyAmount: 10,
    defaultDuration: 30,
  },
  dhikr: {
    defaultCount: 33,
    categories: ['morning', 'evening', 'after_prayer'],
    defaultGoals: {
      morning: [
        { type: 'subhanAllah', count: 33 },
        { type: 'alhamdulillah', count: 33 },
        { type: 'allahuAkbar', count: 34 },
      ],
      evening: [
        { type: 'subhanAllah', count: 33 },
        { type: 'alhamdulillah', count: 33 },
        { type: 'allahuAkbar', count: 34 },
      ],
      after_prayer: [
        { type: 'subhanAllah', count: 33 },
        { type: 'alhamdulillah', count: 33 },
        { type: 'allahuAkbar', count: 34 },
      ],
    },
  },
  names: {
    recommendedGroupSize: 3,
    totalNames: 99
  }
};

// Goal Types Configuration
export const goalTypes = [
  {
    id: 'quran_reading',
    title: 'Read Quran',
    options: [
      {
        value: 'read_15_days',
        label: 'Read Quran in 15 days',
        recurrenceType: 'duration_based',
        details: {
          type: 'quran_reading',
          totalHizb: QURAN_STRUCTURE.TOTAL_HIZBS,
          duration: 15,
          dailyHizb: 4 // 4 hizb per day
        }
      },
      {
        value: 'read_30_days',
        label: 'Read Quran in 30 days',
        recurrenceType: 'duration_based',
        details: {
          type: 'quran_reading',
          totalHizb: QURAN_STRUCTURE.TOTAL_HIZBS,
          duration: 30,
          dailyHizb: 2 // 2 hizb per day
        }
      },
      {
        value: 'read_60_days',
        label: 'Read Quran in 60 days',
        recurrenceType: 'duration_based',
        details: {
          type: 'quran_reading',
          totalHizb: QURAN_STRUCTURE.TOTAL_HIZBS,
          duration: 60,
          dailyHizb: 1 // 1 hizb per day
        }
      }
    ],
  },
  {
    id: 'memorization',
    title: 'Memorize Quran',
    options: [
      {
        value: 'memorize_short',
        label: 'Memorize Short Surah',
        recurrenceType: 'duration_based',
        details: {
          type: 'memorization',
          totalAmount: 10, // verses
          duration: 7, // days
          dailyVerses: 2,
        },
      },
      {
        value: 'memorize_juz_amma',
        label: 'Memorize Juz Amma',
        recurrenceType: 'duration_based',
        details: {
          type: 'memorization',
          totalAmount: 250,
          duration: 90,
          dailyVerses: 3,
        },
      },
    ],
  },
  {
    id: 'dhikr',
    title: 'Daily Dhikr',
    options: [
      {
        value: 'morning_adhkar',
        label: 'Morning Adhkar',
        recurrenceType: 'time_based',
        details: {
          type: 'dhikr',
          window: 'morning',
          requiredDhikrs: [
            { type: 'subhanAllah', count: 33 },
            { type: 'alhamdulillah', count: 33 },
            { type: 'allahuAkbar', count: 34 },
          ],
        },
      },
      {
        value: 'evening_adhkar',
        label: 'Evening Adhkar',
        recurrenceType: 'time_based',
        details: {
          type: 'dhikr',
          window: 'evening',
          requiredDhikrs: [
            { type: 'subhanAllah', count: 33 },
            { type: 'alhamdulillah', count: 33 },
            { type: 'allahuAkbar', count: 34 },
          ],
        },
      },
      {
        value: 'after_prayer',
        label: 'After Prayer Dhikr',
        recurrenceType: 'prayer_based',
        details: {
          type: 'dhikr',
          requiredDhikrs: [
            { dhikrId: 'fullTasbih', count: 1, repetitions: 1 }
          ],
        },
      },
    ],
  },
]; 