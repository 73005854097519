import FingerprintJS from '@fingerprintjs/fingerprintjs'

const waitForAndroidInterface = (timeout = 5000) => {
  return new Promise((resolve) => {
    if (window.AndroidInterface) {
      console.log('Android interface already available');
      resolve(window.AndroidInterface);
      return;
    }

    let timeoutId;
    
    const checkInterface = () => {
      if (window.AndroidInterface) {
        console.log('Android interface became available');
        clearTimeout(timeoutId);
        resolve(window.AndroidInterface);
        return;
      }
    };

    // Check every 100ms
    const intervalId = setInterval(checkInterface, 100);

    // Timeout after specified duration
    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      console.log('Android interface not available after timeout');
      resolve(null);
    }, timeout);
  });
};

export const getDeviceId = async () => {
  try {
    // Wait for Android interface
    const androidInterface = await waitForAndroidInterface();
    console.log('Android interface check result:', !!androidInterface);

    if (androidInterface) {
      try {
        console.log('Getting device ID from Android interface');
        const nativeDeviceId = await androidInterface.getDeviceId();
        console.log('Native device ID result:', nativeDeviceId);
        
        if (nativeDeviceId) {
          localStorage.setItem('deviceId', nativeDeviceId);
          console.log('Using native deviceId:', nativeDeviceId);
          return nativeDeviceId;
        }
      } catch (nativeError) {
        console.error('Error getting native deviceId:', nativeError);
      }
    }

    // Check localStorage for existing ID
    const cachedId = localStorage.getItem('deviceId');
    console.log('Checking cached ID:', cachedId);
    if (cachedId) {
      // If the cached ID is from the native implementation
      if (cachedId.startsWith('android_')) {
        console.log('Using cached native deviceId:', cachedId);
        return cachedId;
      }
      
      // If it's an old-style ID and we don't have native access, use it
      if (!androidInterface) {
        console.log('Using legacy cached deviceId:', cachedId);
        return cachedId;
      }
    }

    // If no native ID and no valid cached ID, fall back to fingerprint
    console.log('Falling back to fingerprint generation');
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    
    const deviceId = `device_${result.visitorId}`;
    console.log('Generated new fingerprint deviceId:', deviceId);
    
    localStorage.setItem('deviceId', deviceId);
    
    return deviceId;
  } catch (error) {
    console.error('Error generating device ID:', error);
    return localStorage.getItem('deviceId') || `device_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  }
}; 