import React, { createContext, useContext, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { auth, db } from '../../../firebase';
import { doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { 
  fetchSurahs, 
  fetchSurah, 
  fetchReciters, 
  fetchAudioTimings, 
  fetchTranslations, 
  fetchLanguages,
  fetchVersesByKey,
  fetchBismillah
} from '../../../services/quranService';
import { useSettings } from '../../../contexts/SettingsContext';
import useQuranContent from './useQuranContent';
import useQuranFootnotes from './useQuranFootnotes';
import useGaplessQuranAudio from './useGaplessQuranAudio';
import useQuranMemorization from './useQuranMemorization';
import { useReadingProgress } from '../../../hooks/useReadingProgress';
import { useAuth } from '../../../contexts/AuthContext';

const DEFAULT_SETTINGS = {
  displayMode: 'with-translation',
  showTransliteration: false,
  mode: 'reading',
  fontSize: 'text-xl',
  selectedReciter: null,
  selectedLanguage: null,
  selectedTranslation: null,
  memorization: {},
  readingProgress: {
    currentSession: {
      surah: null,
      verse: null,
      timestamp: null
    },
    history: {}
  },
  lastReadingSession: null
};

const useQuranData = () => {
  const { settings, updateSettings, isInitialized: isSettingsInitialized } = useSettings();
  const quranContent = useQuranContent();
  const { currentUser } = useAuth();
  
  // API data state
  const [apiData, setApiData] = useState({
    reciters: [],
    translations: [],
    languages: []
  });
  
  // Loading states
  const [loading, setLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadingRef = useRef(false);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  // Memoize settings
  const quranSettings = useMemo(() => ({
    ...DEFAULT_SETTINGS,
    ...(settings.quran || {})
  }), [settings.quran]);

  const [notes, setNotes] = useState({});

  const fetchSurahContentV2 = useCallback(async (surahId) => {
    try {
      // Use the fallback mapping to know the total verses
      const fallbackSurahDataMapping = {
        "1": 7, "2": 286, "3": 200, "4": 176, "5": 120,
        "6": 165, "7": 206, "8": 75, "9": 129, "10": 109,
        "11": 123, "12": 111, "13": 43, "14": 52, "15": 99,
        "16": 128, "17": 111, "18": 110, "19": 98, "20": 135,
        "21": 112, "22": 78, "23": 118, "24": 64, "25": 77,
        "26": 227, "27": 93, "28": 88, "29": 69, "30": 60,
        "31": 34, "32": 30, "33": 73, "34": 54, "35": 45,
        "36": 83, "37": 182, "38": 88, "39": 75, "40": 85,
        "41": 54, "42": 53, "43": 89, "44": 59, "45": 37,
        "46": 35, "47": 38, "48": 29, "49": 18, "50": 45,
        "51": 60, "52": 49, "53": 62, "54": 55, "55": 78,
        "56": 96, "57": 29, "58": 22, "59": 24, "60": 13,
        "61": 14, "62": 11, "63": 11, "64": 18, "65": 12,
        "66": 12, "67": 30, "68": 52, "69": 52, "70": 44,
        "71": 28, "72": 28, "73": 20, "74": 56, "75": 40,
        "76": 31, "77": 50, "78": 40, "79": 46, "80": 42,
        "81": 29, "82": 19, "83": 36, "84": 25, "85": 22,
        "86": 17, "87": 19, "88": 26, "89": 30, "90": 20,
        "91": 15, "92": 21, "93": 11, "94": 8, "95": 8,
        "96": 19, "97": 5, "98": 8, "99": 8, "100": 11,
        "101": 11, "102": 8, "103": 3, "104": 9, "105": 5,
        "106": 4, "107": 7, "108": 3, "109": 6, "110": 3,
        "111": 5, "112": 4, "113": 5, "114": 6
      };
      
      const totalVerses = fallbackSurahDataMapping[surahId] || 0;
      
      // Prepare verse keys to fetch all verses
      const verseKeys = [];
      for (let i = 1; i <= totalVerses; i++) {
        verseKeys.push(`${surahId}:${i}`);
      }

      // Fetch verses
      const verses = [];
      try {
        // Pass all verse keys at once since fetchVersesByKey expects an array
        const response = await fetchVersesByKey(
          verseKeys,
          quranSettings.selectedTranslation,
          quranSettings.selectedLanguage
        );
        
        if (Array.isArray(response)) {
          verses.push(...response);
        } else if (response && Array.isArray(response.verses)) {
          verses.push(...response.verses);
        } else {
          console.warn('Unexpected response format:', response);
        }
      } catch (error) {
        console.error('Failed to fetch verses:', error);
      }

      return verses;
    } catch (error) {
      console.error('Error fetching surah content:', error);
      return null;
    }
  }, [quranSettings.selectedTranslation, quranSettings.selectedLanguage]);

  const loadSurahContent = useCallback(async (surahId, targetVerse = null) => {
    if (loadingRef.current) {
      console.log('🚫 Already loading content');
      return;
    }

    if (!quranContent || !quranContent.setSurahContent) {
      console.error('❌ QuranContent not properly initialized');
      return;
    }

    console.log('📖 Loading surah content:', { surahId, targetVerse });
    
    // Use only one loading state
    loadingRef.current = true;
    setLoading(true);
    
    try {
      // First update the surah selection
      quranContent.setSelectedSurah(surahId);
      quranContent.setSurahContent(null); // Clear content while loading

      const verses = await fetchSurahContentV2(surahId);
      
      if (!verses || !Array.isArray(verses)) {
        console.error('❌ Failed to fetch verses or invalid response');
        return;
      }
      
      console.log('📝 Setting surah content:', { verseCount: verses.length });
      
      // Set all content in one batch
      await Promise.resolve(); // Ensure previous state updates are processed
      quranContent.setSurahContent(verses);
      quranContent.setCurrentVerse(targetVerse ? verses.findIndex(v => v.verse_key === targetVerse) : 0);
      
      if (targetVerse) {
        const verseIndex = verses.findIndex(v => v.verse_key === targetVerse);
        if (verseIndex !== -1) {
          console.log('🎯 Found target verse at index:', verseIndex);
        } else {
          console.log('⚠️ Target verse not found in initial load');
        }
      }
      
      console.log('✅ Surah content loaded successfully');
      
    } catch (error) {
      console.error('Error loading surah content:', error);
      // Reset states on error
      quranContent.setSurahContent(null);
      quranContent.setCurrentVerse(0);
    } finally {
      // Clear loading state LAST, after a small delay to ensure content is rendered
      await new Promise(resolve => setTimeout(resolve, 100));
      loadingRef.current = false;
      setLoading(false);
    }
  }, [quranContent, fetchSurahContentV2]);

  // Get footnotes management from useQuranFootnotes
  const footnoteData = useQuranFootnotes(quranContent.surahContent);

  // Get memorization management
  const memorizationData = useQuranMemorization(
    quranContent.selectedSurah,
    quranContent.surahContent,
    quranContent.surahs
  );

  // Get reading progress management
  const readingProgressData = useReadingProgress();

  // Ensure reading progress has the correct structure
  const safeReadingProgress = useMemo(() => {
    const defaultProgress = {
      currentSession: {
        surah: null,
        verse: null,
        timestamp: null
      },
      history: {}
    };

    if (!quranSettings.readingProgress || 
        typeof quranSettings.readingProgress !== 'object' ||
        !quranSettings.readingProgress.currentSession ||
        typeof quranSettings.readingProgress.history !== 'object') {
      return defaultProgress;
    }

    return quranSettings.readingProgress;
  }, [quranSettings.readingProgress]);

  // Load API data and initialize settings if needed
  useEffect(() => {
    if (!isSettingsInitialized) return;

    const loadApiData = async () => {
      try {
        // Only fetch data if we don't have it yet
        if (apiData.reciters.length === 0) {
          const [recitersData, translationsData, languagesData] = await Promise.all([
            fetchReciters(),
            fetchTranslations(),
            fetchLanguages()
          ]);

          const newApiData = {
            reciters: recitersData.recitations || [],
            translations: translationsData.translations || [],
            languages: languagesData.languages || []
          };
          setApiData(newApiData);

          // Initialize settings only if they don't exist
          if (!settings.quran) {
            const defaultLanguage = languagesData.languages.find(lang => lang.iso_code === 'en');
            const defaultTranslation = translationsData.translations.find(
              trans => trans.language_name?.toLowerCase() === 'english'
            );
            
            updateSettings({
              ...settings,
              quran: {
                ...DEFAULT_SETTINGS,
                selectedLanguage: defaultLanguage?.iso_code || 'en',
                selectedTranslation: defaultTranslation?.id?.toString() || '20',
                selectedReciter: recitersData.recitations?.[0]?.id?.toString() || '1'
              }
            });
          }
        }
      } catch (error) {
        console.error("Error loading API data:", error);
      }
    };

    loadApiData();
  }, [isSettingsInitialized, settings, apiData.reciters.length, updateSettings]);

  // Update useQuranAudio call with correct props
  const audioState = useGaplessQuranAudio(
    quranContent.selectedSurah,
    settings?.quran?.selectedReciter,
    quranContent.currentVerse,
    quranContent.setCurrentVerse
  );

  // Add a new function to fetch verse text in V2 format
  const fetchVerseTextV2 = async (chapterNumber, verseNumber) => {
    try {
      const response = await fetch(
        `https://api.quran.com/api/v2/verses/code_v2/${chapterNumber}/${verseNumber}`
      );
      const data = await response.json();
      return data.verse; // This will give us properly formatted text
    } catch (error) {
      console.error('Error fetching verse text:', error);
      return null;
    }
  };

  // Add this function to fetch all verses for a surah in V2 format
  const fetchSurahVerses = async (surahId) => {
    try {
      const response = await fetch(
        `https://api.quran.com/api/v4/verses/by_chapter/${surahId}?language=en&words=false&translations=131`
      );
      const data = await response.json();
      return data.verses;
    } catch (error) {
      console.error('Error fetching surah verses:', error);
      return null;
    }
  };

  // Add helper function to get reading progress for a surah
  const getReadingProgress = useCallback((surahId) => {
    if (!quranContent?.readingState?.history?.[surahId]) {
      return { readCount: 0, totalVerses: 0 };
    }

    const surah = quranContent.surahs.find(s => s.id.toString() === surahId);
    if (!surah) return { readCount: 0, totalVerses: 0 };

    const readVerses = Object.keys(quranContent.readingState.history[surahId] || {}).length;
    return {
      readCount: readVerses,
      totalVerses: surah.verses_count
    };
  }, [quranContent]);

  // Add note loading effect
  useEffect(() => {
    const loadNotes = async () => {
      if (currentUser && quranContent.selectedSurah) {
        try {
          const userNotesRef = doc(db, 'userNotes', currentUser.uid);
          const userNotesSnap = await getDoc(userNotesRef);
          if (userNotesSnap.exists()) {
            const userData = userNotesSnap.data();
            setNotes(userData[quranContent.selectedSurah] || {});
          }
        } catch (error) {
          console.error("Error loading notes from Firestore:", error);
        }
      }
    };

    loadNotes();
  }, [currentUser, quranContent.selectedSurah]);

  // Add note saving function
  const handleSaveNote = async (verseKey, noteText) => {
    if (!currentUser || !quranContent.selectedSurah) return;

    const newNotes = { ...notes, [verseKey]: noteText };
    setNotes(newNotes);

    try {
      const userNotesRef = doc(db, 'userNotes', currentUser.uid);
      await setDoc(userNotesRef, { 
        [quranContent.selectedSurah]: newNotes 
      }, { merge: true });
    } catch (error) {
      console.error("Error saving note to Firestore:", error);
      throw error;
    }
  };

  // Memoize the return object to prevent unnecessary re-renders
  return useMemo(() => ({
    // Core data from useQuranContent
    ...quranContent,
    
    // API data
    reciters: apiData.reciters,
    translations: apiData.translations,
    languages: apiData.languages,
    ...footnoteData,
    
    // Audio state from useQuranAudio
    ...audioState,
    audioDuration: audioState.audioDuration,
    audioProgress: audioState.audioProgress,
    
    // Memorization data
    ...memorizationData,

    // Reading progress data
    ...readingProgressData,
    
    // Settings with guaranteed defaults
    displayMode: quranSettings.displayMode,
    showTransliteration: quranSettings.showTransliteration,
    quranMode: quranSettings.mode,
    fontSize: quranSettings.fontSize,
    selectedReciter: quranSettings.selectedReciter,
    selectedLanguage: quranSettings.selectedLanguage,
    selectedTranslation: quranSettings.selectedTranslation,
    memorization: quranSettings.memorization,
    readingProgress: safeReadingProgress,
    lastReadingSession: quranSettings.lastReadingSession,

    // Loading states
    loading,
    isLoadingMore,
    hasMore,
    currentPage,
    
    // Actions
    loadSurahContent,

    // New function
    getReadingProgress,

    // Notes
    notes,
    handleSaveNote,
  }), [
    quranContent,
    apiData,
    footnoteData,
    audioState,
    memorizationData,
    readingProgressData,
    quranSettings,
    loading,
    currentPage,
    loadSurahContent,
    getReadingProgress,
    notes,
    handleSaveNote,
  ]);
};

export default useQuranData;
