import React from 'react';
import { Link } from 'react-router-dom';
import { Moon, ChevronRight, Clock, Book, Calendar, ArrowRight, Check, LineChart, Globe, Headphones, Sparkles, Layout, Zap, BookOpen } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';

const LandingPage = () => {
  const { isDarkMode } = useDarkMode();

  const prayerTimes = [
    { name: 'Fajr', time: '05:42', active: false },
    { name: 'Dhuhr', time: '12:30', active: false },
    { name: 'Asr', time: '15:45', active: false },
    { name: 'Maghrib', time: '18:23', active: true },
    { name: 'Isha', time: '19:53', active: false }
  ];

  return (
    <div className={`transition-colors duration-200 ${
      isDarkMode 
        ? 'bg-[#0A0A0A]' 
        : 'bg-gray-50'
    }`}>
      <div className={`${
        isDarkMode 
          ? 'bg-gradient-to-b from-[#0A0A0A] via-[#0A0A0A] to-[#041E1B]' 
          : 'bg-gradient-to-b from-gray-50 via-white to-emerald-50/30'
      }`}>
        {/* Hero Section - Match padding to negative margin */}
        <div className="relative pt-[72px] -mt-[72px] pb-20 overflow-hidden">
          {/* Updated background gradient */}
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-br from-emerald-950/30 via-transparent to-transparent backdrop-blur-3xl' 
              : 'bg-gradient-to-br from-emerald-100/50 via-transparent to-transparent backdrop-blur-3xl'
          }`} />
          
          {/* Replace decorative circles with more organic shapes */}
          <div className="absolute top-0 right-0 -translate-y-1/4 translate-x-1/4">
            <div className={`w-[800px] h-[800px] rounded-full blur-3xl animate-pulse-slow ${
              isDarkMode 
                ? 'bg-gradient-to-br from-emerald-900/10 to-transparent' 
                : 'bg-gradient-to-br from-emerald-100/50 to-transparent'
            }`} />
          </div>
          
          {/* Add floating elements */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute top-1/4 left-1/4 w-64 h-64 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float ${
              isDarkMode ? 'bg-emerald-900/20' : 'bg-emerald-200/30'
            }`} />
            <div className={`absolute bottom-1/4 right-1/4 w-96 h-96 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float-delayed ${
              isDarkMode ? 'bg-emerald-800/20' : 'bg-emerald-100/30'
            }`} />
          </div>

          {/* Update the hero content */}
          <div className="relative max-w-7xl mx-auto px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center pt-[96px] lg:pt-[192px]">
              <div className="relative">
                {/* Updated typography and spacing */}
                <h1 className={`text-6xl lg:text-7xl font-bold leading-tight tracking-tight mb-8 animate-fade-in ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>
                  Your Personal{' '}
                  <span className={`inline-block bg-gradient-to-r ${
                    isDarkMode 
                      ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                      : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                  }`}>Islamic</span>{' '}
                  <span className="inline-block">Companion</span>
                </h1>
                
                {/* Updated description with modern typography */}
                <p className={`text-xl lg:text-2xl font-light mb-12 leading-relaxed animate-fade-in delay-100 ${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Stay connected with your faith through accurate prayer times, Quran tracking, and daily spiritual guidance.
                </p>

                {/* Modern button styling */}
                <div className="flex flex-col sm:flex-row items-center gap-6 animate-fade-in delay-200">
                  <Link
                    to="/signup"
                    className={`hidden sm:flex group w-full sm:w-auto px-8 py-4 rounded-full text-lg font-medium transition-all duration-500 relative overflow-hidden ${
                      isDarkMode 
                        ? 'bg-gradient-to-r from-emerald-500 to-emerald-400 text-black hover:shadow-2xl hover:shadow-emerald-500/20' 
                        : 'bg-gradient-to-r from-gray-900 to-gray-800 text-white hover:shadow-2xl hover:shadow-gray-500/20'
                    }`}
                  >
                    <span className="flex items-center justify-center gap-2 relative z-10">
                      Get Started Free
                      <ArrowRight className="w-5 h-5 transition-transform duration-500 group-hover:translate-x-2" />
                    </span>
                    <div className={`absolute inset-0 w-full h-full transition-transform duration-500 ${
                      isDarkMode
                        ? 'bg-gradient-to-r from-emerald-400 to-emerald-300'
                        : 'bg-gradient-to-r from-gray-800 to-gray-700'
                    } opacity-0 group-hover:opacity-100`} />
                  </Link>
                  
                  <Link
                    to="/login"
                    className={`hidden sm:flex w-full sm:w-auto px-8 py-4 rounded-full text-lg font-medium transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-white/5 text-white hover:bg-white/10 backdrop-blur-lg border border-white/10' 
                        : 'bg-white text-gray-900 hover:bg-gray-50 shadow-lg shadow-gray-200/50 border border-gray-200'
                    }`}
                  >
                    Sign In
                  </Link>
                </div>
              </div>
              
              {/* Updated Mac mockup container with dark mode image */}
              <div className="relative w-full animate-fade-in delay-300">
                <div className={`absolute inset-0 rounded-2xl ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-emerald-500/5 to-transparent' 
                    : 'bg-gradient-to-br from-emerald-100/50 to-transparent'
                } blur-2xl -z-10`} />
                <img 
                  src={isDarkMode ? "/images/mockup_mac_dark.png" : "/images/mockup_mac.png"}
                  alt="Ummahti Dashboard" 
                  className="w-full h-auto relative z-10 transform hover:scale-[1.02] transition-transform duration-700"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Add these animations to your CSS */}
        <style jsx>{`
          @keyframes float {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
          }
          @keyframes float-delayed {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-15px); }
          }
          .animate-float {
            animation: float 6s ease-in-out infinite;
          }
          .animate-float-delayed {
            animation: float-delayed 8s ease-in-out infinite;
          }
          .animate-pulse-slow {
            animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          }
        `}</style>

        {/* Continue with the rest of the sections, applying similar modern styling */}

        {/* Prayer Times Section */}
        <div className={`py-24 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-white'} relative overflow-hidden`}>
          {/* Background decorative elements */}
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-br from-emerald-950/20 via-transparent to-transparent' 
              : 'bg-gradient-to-br from-emerald-50/50 via-transparent to-transparent'
          }`} />
          
          {/* Floating decorative elements */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute -left-20 top-40 w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-float-delayed ${
              isDarkMode ? 'bg-emerald-900/10' : 'bg-emerald-100/50'
            }`} />
          </div>

          <div className="max-w-7xl mx-auto px-8 relative">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
              {/* Prayer Times Card */}
              <div className="relative w-full max-w-md mx-auto lg:mx-0">
                {/* Rotated colored background card */}
                <div className={`absolute inset-0 rounded-[2rem] transform rotate-3 transition-all duration-200 ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-emerald-500/20 to-emerald-600/20' 
                    : 'bg-gradient-to-br from-emerald-500 to-emerald-600'
                }`} />
                
                {/* Card glow effect */}
                <div className={`absolute inset-0 rounded-[2rem] blur-2xl ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-emerald-500/10 to-transparent' 
                    : 'bg-gradient-to-br from-emerald-100/80 to-transparent'
                }`} />
                
                {/* Main card */}
                <div className={`relative transform -rotate-1 w-full rounded-[2rem] backdrop-blur-xl transition-all duration-200 ${
                  isDarkMode 
                    ? 'bg-white/5 border border-white/10' 
                    : 'bg-white/80 border border-gray-200'
                }`}>
                  <div className="p-8">
                    <div className="flex items-center justify-between mb-8">
                      <div>
                        <div className={`text-sm font-medium ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>Next Prayer</div>
                        <div className={`text-2xl font-semibold mt-1 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Maghrib</div>
                      </div>
                      <div className="text-right">
                        <div className={`text-4xl font-mono font-bold bg-gradient-to-r ${
                          isDarkMode 
                            ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                            : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                        }`}>
                          01:23:45
                        </div>
                        <div className={`text-sm font-medium mt-1 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>Until Adhan</div>
                      </div>
                    </div>

                    <div className="space-y-3">
                      {prayerTimes.map((prayer) => (
                        <div 
                          key={prayer.name}
                          className={`group flex items-center justify-between p-4 rounded-xl transition-all duration-300 ${
                            prayer.active
                              ? isDarkMode 
                                ? 'bg-emerald-500/10 border border-emerald-500/20' 
                                : 'bg-emerald-50 border border-emerald-100'
                              : isDarkMode 
                                ? 'hover:bg-white/5' 
                                : 'hover:bg-gray-50'
                          }`}
                        >
                          <div className="flex items-center gap-4">
                            <span className={`text-base font-medium ${
                              prayer.active
                                ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                                : isDarkMode ? 'text-gray-300' : 'text-gray-700'
                            }`}>
                              {prayer.name}
                            </span>
                          </div>
                          <span className={`text-base font-medium ${
                            prayer.active
                              ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                              : isDarkMode ? 'text-gray-300' : 'text-gray-700'
                          }`}>
                            {prayer.time}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Prayer Times Features */}
              <div className="lg:pl-12">
                <h2 className={`text-4xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Precise Prayer Times{' '}
                  <span className="block mt-2">at Your Fingertips</span>
                </h2>
                <p className={`text-xl mb-12 leading-relaxed ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Never miss a prayer with accurate calculations and customizable settings tailored to your location.
                </p>
                <ul className="space-y-6">
                  {[
                    {
                      title: "Multiple Calculation Methods",
                      description: "Choose from various calculation methods trusted by Islamic scholars worldwide."
                    },
                    {
                      title: "Location-Based Accuracy",
                      description: "Automatically detect your location or manually set it for precise prayer times."
                    },
                    {
                      title: "Custom Adjustments",
                      description: "Fine-tune prayer times to match your local mosque or preferences."
                    },
                    {
                      title: "Beautiful Adhan Notifications",
                      description: "Get notified with your choice of adhan from our curated collection."
                    }
                  ].map((feature) => (
                    <li key={feature.title} className="flex gap-4">
                      <div className={`flex-shrink-0 w-12 h-12 rounded-xl flex items-center justify-center ${
                        isDarkMode 
                          ? 'bg-emerald-500/10' 
                          : 'bg-emerald-50'
                      }`}>
                        <Check className={`w-6 h-6 ${
                          isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                        }`} />
                      </div>
                      <div>
                        <h3 className={`text-lg font-semibold mb-1 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>{feature.title}</h3>
                        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                          {feature.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Quran Section */}
        <div className={`py-24 ${isDarkMode ? 'bg-[#0A0A0A]/50' : 'bg-gray-50'} relative overflow-hidden`}>
          {/* Background decorative elements */}
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-tr from-emerald-950/20 via-transparent to-transparent' 
              : 'bg-gradient-to-tr from-emerald-50/80 via-transparent to-transparent'
          }`} />
          
          {/* Floating decorative elements */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute right-0 top-20 w-96 h-96 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-float ${
              isDarkMode ? 'bg-emerald-900/10' : 'bg-emerald-100/50'
            }`} />
          </div>

          <div className="max-w-7xl mx-auto px-8 relative">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
              <div className="lg:pr-12 order-2 lg:order-1">
                <h2 className={`text-4xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Your Digital{' '}
                  <span className={`bg-gradient-to-r ${
                    isDarkMode 
                      ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                      : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                  }`}>Quran</span>{' '}
                  Companion
                </h2>
                <p className={`text-xl mb-12 leading-relaxed ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Enhance your Quran journey with powerful features designed for learning and reflection.
                </p>
                <ul className="space-y-8">
                  {[
                    {
                      title: "Track Your Progress",
                      description: "Set personal reading goals and track your daily progress with detailed statistics.",
                      icon: <LineChart className="w-6 h-6 text-emerald-500" />
                    },
                    {
                      title: "Multiple Translations",
                      description: "Access various translations and tafsir from renowned scholars worldwide.",
                      icon: <Globe className="w-6 h-6 text-emerald-500" />
                    },
                    {
                      title: "Beautiful Recitations",
                      description: "Listen to high-quality recitations from your favorite Qaris.",
                      icon: <Headphones className="w-6 h-6 text-emerald-500" />
                    },
                    {
                      title: "Memorization Tools",
                      description: "Advanced tools to help you memorize and retain verses effectively.",
                      icon: <Sparkles className="w-6 h-6 text-emerald-500" />
                    }
                  ].map((feature) => (
                    <li key={feature.title} className="flex gap-6">
                      <div className={`flex-shrink-0 w-14 h-14 rounded-xl flex items-center justify-center ${
                        isDarkMode 
                          ? 'bg-white/5 backdrop-blur-xl border border-white/10' 
                          : 'bg-white shadow-lg shadow-emerald-100/50 border border-emerald-50'
                      }`}>
                        {feature.icon}
                      </div>
                      <div>
                        <h3 className={`text-lg font-semibold mb-2 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>{feature.title}</h3>
                        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                          {feature.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* iPhone Mockup */}
              <div className="relative order-1 lg:order-2">
                <div className={`absolute inset-0 rounded-[2.5rem] blur-2xl ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-emerald-500/10 to-transparent' 
                    : 'bg-gradient-to-br from-emerald-100/80 to-transparent'
                }`} />
                <div className="relative">
                  <img 
                    src="/images/mockup_iphone.png" 
                    alt="Ummahti Quran App" 
                    className="w-full max-w-sm mx-auto transform hover:scale-[1.02] transition-transform duration-700"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TV App Section */}
        <div className={`py-24 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-white'} relative overflow-hidden`}>
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-bl from-emerald-950/20 via-transparent to-transparent' 
              : 'bg-gradient-to-bl from-emerald-50/50 via-transparent to-transparent'
          }`} />

          <div className="max-w-7xl mx-auto px-8 relative">
            <div className="text-center mb-16">
              <h2 className={`text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Perfect for{' '}
                <span className={`bg-gradient-to-r ${
                  isDarkMode 
                    ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                    : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                }`}>Mosques & Islamic Stores</span>
              </h2>
              <p className={`text-xl max-w-2xl mx-auto ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Transform your space with our beautiful TV display app, designed specifically for Islamic establishments.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
              <div className="relative lg:col-span-2 mb-16">
                <div className={`absolute inset-0 rounded-[2rem] blur-2xl ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-emerald-500/10 to-transparent' 
                    : 'bg-gradient-to-br from-emerald-100/80 to-transparent'
                }`} />
                <div className="relative rounded-[2rem] overflow-hidden">
                  <img 
                    src="/images/mockup_tv.png" 
                    alt="Ummahti TV App" 
                    className={`w-full transform hover:scale-[1.02] transition-transform duration-700 ${
                      isDarkMode ? 'opacity-90' : 'opacity-100'
                    }`}
                  />
                </div>
              </div>

              <div className="lg:col-span-2">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                  {[
                    {
                      title: "Beautiful Display",
                      description: "Choose from elegant themes and layouts that match your space.",
                      icon: <Layout className="w-6 h-6 text-emerald-500" />
                    },
                    {
                      title: "Prayer Times",
                      description: "Display accurate prayer times with automatic Adhan notifications.",
                      icon: <Clock className="w-6 h-6 text-emerald-500" />
                    },
                    {
                      title: "Play Quran",
                      description: "Listen to your favorite Qaris anytime with our extensive collection of reciters.",
                      icon: <BookOpen className="w-6 h-6 text-emerald-500" />
                    },
                    {
                      title: "Easy Setup",
                      description: "Simple setup process with remote management capabilities.",
                      icon: <Zap className="w-6 h-6 text-emerald-500" />
                    }
                  ].map((feature) => (
                    <div key={feature.title} className={`p-6 rounded-2xl transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-white/5 backdrop-blur-xl border border-white/10 hover:bg-white/10' 
                        : 'bg-white border border-gray-200 hover:border-emerald-200 hover:shadow-lg hover:shadow-emerald-100/50'
                    }`}>
                      <div className="flex gap-4 items-start">
                        <div className={`flex-shrink-0 w-12 h-12 rounded-xl flex items-center justify-center ${
                          isDarkMode 
                            ? 'bg-emerald-500/10' 
                            : 'bg-emerald-50'
                        }`}>
                          {feature.icon}
                        </div>
                        <div>
                          <h3 className={`text-lg font-semibold mb-2 ${
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          }`}>{feature.title}</h3>
                          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="relative py-32 overflow-hidden">
          {/* Background effects */}
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-b from-[#0A0A0A] to-emerald-950/30' 
              : 'bg-gradient-to-b from-white to-emerald-50'
          }`} />
          
          {/* Floating elements */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute -left-20 bottom-0 w-96 h-96 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-float ${
              isDarkMode ? 'bg-emerald-900/20' : 'bg-emerald-100/50'
            }`} />
            <div className={`absolute -right-20 top-0 w-96 h-96 rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-float-delayed ${
              isDarkMode ? 'bg-emerald-800/20' : 'bg-emerald-200/50'
            }`} />
          </div>

          <div className="relative max-w-7xl mx-auto px-8">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className={`text-4xl lg:text-6xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Begin Your{' '}
                <span className={`bg-gradient-to-r ${
                  isDarkMode 
                    ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                    : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                }`}>Deen Journey</span>{' '}
                Today
              </h2>
              
              <p className={`text-xl lg:text-2xl mb-12 leading-relaxed ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Join thousands of Muslims who have enhanced their daily spiritual practices with Ummahti. 
                Start for free and experience the difference.
              </p>

              <Link
                to="/signup"
                className={`inline-flex group px-8 py-4 rounded-full text-lg font-medium transition-all duration-500 relative overflow-hidden ${
                  isDarkMode 
                    ? 'bg-gradient-to-r from-emerald-500 to-emerald-400 text-black hover:shadow-2xl hover:shadow-emerald-500/20' 
                    : 'bg-gradient-to-r from-gray-900 to-gray-800 text-white hover:shadow-2xl hover:shadow-gray-500/20'
                }`}
              >
                <span className="flex items-center justify-center gap-2 relative z-10">
                  Get Started Free
                  <ArrowRight className="w-5 h-5 transition-transform duration-500 group-hover:translate-x-2" />
                </span>
                <div className={`absolute inset-0 w-full h-full transition-transform duration-500 ${
                  isDarkMode
                    ? 'bg-gradient-to-r from-emerald-400 to-emerald-300'
                    : 'bg-gradient-to-r from-gray-800 to-gray-700'
                } opacity-0 group-hover:opacity-100`} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage; 