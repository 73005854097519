import React from 'react';
import { MobilePageTransition } from './MobilePageTransition';
import { motion } from 'framer-motion';

export const SelectionView = ({ 
  show, 
  onClose, 
  title, 
  options, 
  selectedValue, 
  onSelect, 
  isDarkMode,
  preview,
  className = ''
}) => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
      className="fixed inset-0 z-50"
    >
      <MobilePageTransition
        show={show}
        onClose={onClose}
        title={title}
        isDarkMode={isDarkMode}
      >
        {preview}
        
        <div className={`rounded-xl overflow-hidden flex-1 ${
          isDarkMode ? 'bg-[#111111]' : 'bg-white'
        }`}>
          <div className={`h-[calc(100vh-180px)] overflow-y-auto pb-20 ${className}`}>
            {options.map((option) => (
              <button
                key={option.value}
                className={`w-full px-4 py-3 text-left border-b ${
                  isDarkMode ? 'border-gray-800' : 'border-gray-200'
                } last:border-0`}
                onClick={() => onSelect(option)}
              >
                <div className="flex items-center justify-between">
                  <span className={`${
                    option.value === selectedValue?.value 
                      ? 'text-emerald-500' 
                      : isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {option.label}
                  </span>
                  {option.value === selectedValue?.value && (
                    <div className="w-2 h-2 rounded-full bg-emerald-500" />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      </MobilePageTransition>
    </motion.div>
  );
}; 