import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Navbar, Dropdown, Footer } from 'flowbite-react';
import { Gear, SignOut, Sun, Moon, House, Book, Question, Compass, DotsThree, StarAndCrescent, MessageCircle } from '@phosphor-icons/react';
import { SalahIcon, Mosque01Icon, Kaaba01Icon, MoreHorizontalCircle01Icon, RubElHizbIcon, Quran01Icon, Ramadhan01Icon, Settings02Icon, Message01Icon, Logout02Icon, Sun03Icon, Moon02Icon  } from "hugeicons-react";
import CustomAvatar from './CustomAvatar';
import { useDarkMode } from '../contexts/DarkModeContext';
import { motion } from 'framer-motion';

const Layout = () => {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const isSimpleDashboard = location.pathname === '/simpledashboard';
  const isQuranPage = location.pathname === '/quran';

  // Handle scroll effect for navbar
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  console.log('Current user:', {
    photoURL: currentUser?.photoURL,
    displayName: currentUser?.displayName,
    email: currentUser?.email
  });

  useEffect(() => {
    if (currentUser) {
      console.log('Current user data:', {
        photoURL: currentUser.photoURL,
        providerId: currentUser.providerData[0]?.providerId,
        providerPhotoURL: currentUser.providerData[0]?.photoURL
      });
    }
  }, [currentUser]);

  // Add this function to determine device type
  const getDeviceType = () => {
    const width = window.innerWidth;
    if (width < 768) return 'mobile';
    if (width < 1024) return 'tablet';
    return 'desktop';
  };

  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add this component before the MobileNavigation component
  const MobileDropup = ({ isOpen, onClose, currentUser, logout, isDarkMode, toggleDarkMode }) => {
    if (!isOpen) return null;
    
    const getUserInitials = () => {
      if (currentUser.displayName) {
        return currentUser.displayName.split(' ').map(n => n[0]).join('').toUpperCase();
      } else if (currentUser.email) {
        return currentUser.email[0].toUpperCase();
      }
      return '?';
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={`fixed inset-0 z-50 ${isOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}
        onClick={onClose}
      >
        <motion.div
          initial={{ y: '100%' }}
          animate={{ y: isOpen ? 0 : '100%' }}
          exit={{ y: '100%' }}
          transition={{ type: 'spring', damping: 25, stiffness: 200 }}
          className={`absolute bottom-[72px] left-0 right-0 rounded-t-3xl overflow-hidden ${
            isDarkMode ? 'bg-[#111111]' : 'bg-white'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <div className={`p-6 border-b ${isDarkMode ? 'border-white/[0.05]' : 'border-black/[0.02]'}`}>
            <div className="flex items-center gap-4">
              <CustomAvatar 
                src={currentUser.photoURL}
                alt={currentUser.displayName || "User"}
                initials={getUserInitials()}
                size={48}
              />
              <div className="flex-1 min-w-0">
                <div className={`font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {currentUser.displayName || currentUser.email}
                </div>
                <div className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {currentUser.email}
                </div>
              </div>
            </div>
          </div>
          
          {/* Menu items */}
          <div className="p-2">
            {/* Settings */}
            <Link
              to="/settings"
              onClick={onClose}
              className={`flex items-center gap-3 w-full p-4 rounded-xl transition-colors ${
                isDarkMode 
                  ? 'text-gray-300 hover:bg-white/[0.05] active:bg-white/[0.08]' 
                  : 'text-gray-700 hover:bg-black/[0.02] active:bg-black/[0.05]'
              }`}
            >
              <Settings02Icon size={20} weight="regular" className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
              <span className="font-medium">Settings</span>
            </Link>

            {/* Feedback */}
            <Link
              to="/feedback"
              onClick={onClose}
              className={`flex items-center gap-3 w-full p-4 rounded-xl transition-colors ${
                isDarkMode 
                  ? 'text-gray-300 hover:bg-white/[0.05] active:bg-white/[0.08]' 
                  : 'text-gray-700 hover:bg-black/[0.02] active:bg-black/[0.05]'
              }`}
            >
              <Message01Icon size={20} weight="regular" className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
              <span className="font-medium">Send Feedback</span>
            </Link>

            {/* Theme Toggle */}
            <button
              onClick={() => {
                toggleDarkMode();
                onClose();
              }}
              className={`flex items-center gap-3 w-full p-4 rounded-xl transition-colors ${
                isDarkMode 
                  ? 'text-gray-300 hover:bg-white/[0.05] active:bg-white/[0.08]' 
                  : 'text-gray-700 hover:bg-black/[0.02] active:bg-black/[0.05]'
              }`}
            >
              {isDarkMode ? (
                <>
                  <Sun03Icon size={20} weight="regular" className="text-emerald-400" />
                  <span className="font-medium">Light Mode</span>
                </>
              ) : (
                <>
                  <Moon02Icon size={20} weight="regular" className="text-gray-500" />
                  <span className="font-medium">Dark Mode</span>
                </>
              )}
            </button>

            {/* Sign Out */}
            <button
              onClick={() => {
                onClose();
                logout();
              }}
              className={`flex items-center gap-3 w-full p-4 rounded-xl transition-colors mt-2 ${
                isDarkMode 
                  ? 'text-red-400 hover:bg-red-500/10 active:bg-red-500/20' 
                  : 'text-red-600 hover:bg-red-50 active:bg-red-100'
              }`}
            >
              <Logout02Icon size={20} weight="regular" className="current-color" />
              <span className="font-medium">Sign out</span>
            </button>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  // Modify the MobileNavigation component
  const MobileNavigation = () => {
    const [isDropupOpen, setIsDropupOpen] = useState(false);
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    const NavItem = ({ to, icon, label }) => (
      <Link 
        to={to} 
        className="flex flex-col items-center py-2 px-3 space-y-1"
      >
        <div className={`${
          isActive(to) 
            ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
            : 'text-gray-400'
        } transition-colors hover:text-emerald-600`}>
          {icon}
        </div>
        <span className={`text-xs ${
          isActive(to) 
            ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
            : 'text-gray-400'
        } transition-colors group-hover:text-emerald-600`}>
          {label}
        </span>
      </Link>
    );

    return (
      <>
        {/* Gradient overlay - different for dark/light mode */}
        <div className={`fixed bottom-0 left-0 right-0 h-28 bg-gradient-to-t pointer-events-none z-[90] ${
          isDarkMode
            ? 'from-emerald-800/30 via-emerald-900/20 to-transparent'
            : 'from-emerald-100/50 via-emerald-50/30 to-transparent'
        }`} />
        
        {/* Navigation bar with glass effect */}
        <div className="fixed left-0 right-0 bottom-0 z-[100]">
          <nav className={`backdrop-blur-xl px-6 py-2 safe-area-bottom ${
            isDarkMode
              ? 'bg-black/25 border-t border-white/10'
              : 'bg-white/70 border-t border-emerald-100'
          }`}>
            <div className="max-w-md mx-auto flex justify-between items-center">
              <NavItem 
                to="/" 
                icon={<Mosque01Icon size={24} />} 
                label="Home" 
              />
              <NavItem 
                to="/quran" 
                icon={<Quran01Icon size={24} />} 
                label="Quran" 
              />
              <NavItem 
                to="/deen-helpers" 
                icon={<SalahIcon size={24} />} 
                label="Deen"
              />
              <NavItem 
                to="/qibla-finder" 
                icon={<Kaaba01Icon size={24} />} 
                label="Qibla" 
              />
              <button 
                onClick={() => setIsDropupOpen(prev => !prev)}
                className="flex flex-col items-center py-2 px-3 space-y-1"
              >
                <div className={`${
                  isDropupOpen 
                    ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                    : 'text-gray-400'
                } transition-colors hover:text-emerald-600`}>
                  <MoreHorizontalCircle01Icon size={24} />
                </div>
                <span className={`text-xs ${
                  isDropupOpen 
                    ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                    : 'text-gray-400'
                } transition-colors group-hover:text-emerald-600`}>
                  More
                </span>
              </button>
            </div>
          </nav>
        </div>
        
        <MobileDropup
          isOpen={isDropupOpen}
          onClose={() => setIsDropupOpen(false)}
          currentUser={currentUser}
          logout={logout}
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
        />
      </>
    );
  };

  if (isSimpleDashboard) {
    return <Outlet />;
  }

  const getUserInitials = () => {
    if (currentUser.displayName) {
      return currentUser.displayName.split(' ').map(n => n[0]).join('').toUpperCase();
    } else if (currentUser.email) {
      return currentUser.email[0].toUpperCase();
    }
    return '?';
  };

  const getFirstAndLastName = () => {
    if (currentUser.displayName) {
      const names = currentUser.displayName.split(' ');
      return {
        firstName: names[0],
        lastName: names.slice(1).join(' ')
      };
    }
    return {
      firstName: currentUser.email?.split('@')[0] || '',
      lastName: ''
    };
  };

  // Modify the main return statement
  return (
    <div 
      className={`flex flex-col min-h-[100dvh] has-safe-area transition-colors duration-200 ${
        isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
      }`}
    >
      {/* Only show navbar on tablet and desktop */}
      {deviceType !== 'mobile' && (
        <Navbar 
          fluid 
          className={`sticky top-0 z-50 transition-all duration-300 ${
            isQuranPage 
              ? 'bg-white dark:bg-[#0A0A0A] border-b border-gray-200 dark:border-[#0A0A0A]' : 
            scrolled 
              ? 'bg-white/80 dark:bg-[#0A0A0A]/80 backdrop-blur-sm border-b border-gray-200 dark:border-[#0A0A0A]' : 
            'bg-transparent border-transparent'
          }`}
        >
          <div className={isQuranPage ? "w-full px-6" : "container mx-auto px-6"}>
            <div className="w-full flex justify-between items-center py-4">
              <div className="flex items-center gap-8">
                <Navbar.Brand 
                  as={Link} 
                  to="/" 
                  className={`flex items-center gap-2 px-4 py-2.5 rounded-full transition-all duration-300 ${
                    scrolled 
                      ? 'hover:bg-gray-100 dark:hover:bg-[#111111]' 
                      : isDarkMode 
                        ? 'bg-[#111111] hover:bg-[#1A1A1A] shadow-sm' 
                        : 'bg-white hover:bg-gray-50 shadow-sm'
                  }`}
                >
                  <img
                    src={isDarkMode ? "/logo_dark_mode.svg" : "/logo.svg"}
                    className="h-8 transition-opacity duration-300"
                    alt="Islamic Dashboard Logo"
                  />
                </Navbar.Brand>
                
                {currentUser && (
                  <div className={`hidden md:flex items-center gap-3 px-4 py-2 rounded-full transition-all duration-300 ${
                    scrolled ? '' : isDarkMode ? 'bg-[#111111] shadow-sm' : 'bg-white shadow-sm'
                  }`}>
                    <Link 
                      to="/" 
                      className={`px-5 py-2.5 rounded-full transition-all duration-300 ${
                        location.pathname === '/' 
                          ? isDarkMode ? 'bg-emerald-500 text-black font-medium shadow-lg shadow-emerald-500/20' : 'bg-gray-900 text-white'
                          : isDarkMode ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      Dashboard
                    </Link>
                    <Link 
                      to="/quran" 
                      className={`px-5 py-2.5 rounded-full transition-all duration-300 ${
                        location.pathname === '/quran' 
                          ? isDarkMode ? 'bg-emerald-500 text-black font-medium shadow-lg shadow-emerald-500/20' : 'bg-gray-900 text-white'
                          : isDarkMode ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      Quran
                    </Link>
                    <Link 
                      to="/deen-helpers" 
                      className={`px-5 py-2.5 rounded-full transition-all duration-300 ${
                        location.pathname === '/deen-helpers' 
                          ? isDarkMode ? 'bg-emerald-500 text-black font-medium shadow-lg shadow-emerald-500/20' : 'bg-gray-900 text-white'
                          : isDarkMode ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      Deen Helpers
                    </Link>
                    <Link 
                      to="/qibla-finder" 
                      className={`lg:hidden px-5 py-2.5 rounded-full transition-all duration-300 ${
                        location.pathname === '/qibla-finder' 
                          ? isDarkMode ? 'bg-emerald-500 text-black font-medium shadow-lg shadow-emerald-500/20' : 'bg-gray-900 text-white'
                          : isDarkMode ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      Qibla Finder
                    </Link>
                  </div>
                )}
              </div>

              <div className="flex items-center gap-4">
                {currentUser ? (
                  <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                      <div className={`flex items-center gap-4 cursor-pointer rounded-full transition-all duration-300 h-11 ${
                        scrolled 
                          ? 'hover:bg-gray-100 dark:hover:bg-[#111111]' 
                          : isDarkMode 
                            ? 'bg-[#111111] hover:bg-[#1A1A1A] shadow-sm' 
                            : 'bg-white hover:bg-gray-50 shadow-sm'
                      }`}>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleDarkMode();
                          }}
                          className="h-full px-4 rounded-full transition-colors hover:bg-gray-100 dark:hover:bg-[#1A1A1A] flex items-center"
                        >
                          <Sun size={18} weight="thin" className="w-5 h-5 text-emerald-400 hover:text-emerald-300 transition-colors" />
                        </button>
                        <div className="pr-4 flex items-center">
                          <CustomAvatar 
                            src={currentUser.photoURL}
                            alt={currentUser.displayName || "User settings"}
                            initials={getUserInitials()}
                          />
                        </div>
                      </div>
                    }
                    className="!p-0 [&>div]:!p-0 [&>div]:!bg-transparent [&>div]:!border-0 [&>div]:!rounded-none [&>div]:!m-0 [&>div>ul]:!py-0"
                  >
                    <div className={`rounded-[24px] overflow-hidden border transition-all duration-200 min-w-[240px] ${
                      isDarkMode 
                        ? 'bg-[#111111] border-gray-800 shadow-lg shadow-black/5' 
                        : 'bg-white border-gray-100 shadow-sm'
                    }`}>
                      <Dropdown.Header className={`p-4 border-b ${isDarkMode ? 'bg-[#111111] text-white border-gray-800' : 'border-gray-100'}`}>
                        <span className="block text-sm">{currentUser.displayName || currentUser.email}</span>
                        <span className={`block truncate text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          {currentUser.email}
                        </span>
                      </Dropdown.Header>
                      <ul className="p-2">
                        <li>
                          <Dropdown.Item 
                            as={Link} 
                            to="/settings"
                            className={`flex items-center gap-2 p-2 rounded-lg transition-colors ${
                              isDarkMode 
                                ? 'text-gray-300 hover:bg-[#1A1A1A]' 
                                : 'text-gray-700 hover:bg-gray-50'
                            }`}
                          >
                            <Gear size={18} weight="thin" className={isDarkMode ? 'text-gray-400' : 'text-gray-500'} />
                            Settings
                          </Dropdown.Item>
                        </li>
                        <li>
                          <Dropdown.Item 
                            onClick={logout}
                            className={`flex items-center gap-2 p-2 rounded-lg transition-colors ${
                              isDarkMode 
                                ? 'text-red-400 hover:bg-red-500/10' 
                                : 'text-red-600 hover:bg-red-50'
                            }`}
                          >
                            <SignOut size={18} weight="thin" className="current-color" />
                            Sign out
                          </Dropdown.Item>
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                ) : (
                  <div className={`flex items-center gap-3 px-4 py-2 rounded-full transition-all duration-300 relative ${
                    scrolled 
                      ? 'hover:bg-gray-100 dark:hover:bg-[#111111]' 
                      : isDarkMode 
                        ? 'bg-[#111111] hover:bg-[#1A1A1A] shadow-sm' 
                        : 'bg-white hover:bg-gray-50 shadow-sm'
                  }`}>
                    <div 
                      className={`absolute inset-2 transition-all duration-500 ease-in-out rounded-full ${
                        isDarkMode 
                          ? 'bg-emerald-500 shadow-lg shadow-emerald-500/20' 
                          : 'bg-gray-900'
                      }`}
                      style={{
                        left: location.pathname === '/login' ? '8px' : '50%',
                        right: location.pathname === '/signup' ? '8px' : '50%',
                        opacity: (location.pathname === '/login' || location.pathname === '/signup') ? 1 : 0,
                      }}
                    />
                    
                    <Link 
                      to="/login" 
                      className={`px-5 py-2.5 rounded-full transition-all duration-300 relative z-10 ${
                        location.pathname === '/login' 
                          ? isDarkMode 
                            ? 'text-black font-medium' 
                            : 'text-white font-medium'
                          : isDarkMode 
                            ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' 
                            : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      Login
                    </Link>
                    <Link 
                      to="/signup" 
                      className={`px-5 py-2.5 rounded-full transition-all duration-300 relative z-10 ${
                        location.pathname === '/signup' 
                          ? isDarkMode 
                            ? 'text-black font-medium' 
                            : 'text-white font-medium'
                          : isDarkMode 
                            ? 'text-gray-400 hover:text-white hover:bg-[#1A1A1A]' 
                            : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      Sign Up
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Navbar>
      )}

      {/* Main content wrapper */}
      <main className={`flex-1 ${deviceType === 'mobile' ? 'mb-16' : ''}`}>
        <div className="h-full">
          <div className="h-full">
            <Outlet />
          </div>
        </div>
      </main>

      {deviceType === 'mobile' && <MobileNavigation />}

      {/* Show footer only on tablet and desktop */}
      {deviceType !== 'mobile' && (
        <Footer container={false} className={`mt-auto transition-all duration-200 !rounded-none ${
          isDarkMode 
            ? 'bg-[#111111] shadow-lg shadow-black/5' 
            : 'bg-white shadow-sm'
        }`}>
          <div className="w-full text-center">
            <div className="container px-9 pt-6 mx-auto justify-between sm:flex sm:items-center sm:justify-between">
              <Footer.Copyright
                by="Ummahti"
                year={new Date().getFullYear()}
                className={isDarkMode ? 'text-gray-400' : ''}
              />
              <Footer.LinkGroup>
                <Footer.Link 
                  href="/about"
                  className={isDarkMode ? 'text-gray-400 hover:text-white' : ''}
                >
                  About
                </Footer.Link>
                <Footer.Link 
                  href="/privacy"
                  className={isDarkMode ? 'text-gray-400 hover:text-white' : ''}
                >
                  Privacy Policy
                </Footer.Link>
                <Footer.Link 
                  href="/contact"
                  className={isDarkMode ? 'text-gray-400 hover:text-white' : ''}
                >
                  Contact
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </Footer>
      )}
    </div>
  );
};

export default Layout;
