import React from 'react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';

export const TemplateGrid = ({ templates, selectedTemplate, onSelect }) => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className="grid grid-cols-2 gap-3 relative z-0">
      {templates.map((template) => {
        // Handle divider template
        if (template.isDivider) {
          return (
            <div key={template.id} className="col-span-2 pt-6 pb-3">
              <h3 className={`text-lg font-medium mb-1 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {template.name}
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                {template.description}
              </p>
              <div className={`mt-3 border-b ${
                isDarkMode ? 'border-white/10' : 'border-gray-200'
              }`} />
            </div>
          );
        }

        // Regular template card
        return (
          <div
            key={template.id}
            onClick={() => onSelect(template)}
            className={`p-4 rounded-xl cursor-pointer transition-all duration-200 ${
              isDarkMode 
                ? 'bg-[#1A1A1A] hover:bg-[#222222]' 
                : 'bg-gray-100 hover:bg-gray-200'
            } ${
              selectedTemplate === template.id 
                ? isDarkMode
                  ? 'ring-2 ring-emerald-500'
                  : 'ring-2 ring-emerald-600'
                : ''
            }`}
          >
            <h3 className={`text-base font-medium mb-1 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {template.name}
            </h3>
            <p className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-600'
            }`}>
              {template.description}
            </p>
          </div>
        );
      })}
    </div>
  );
}; 