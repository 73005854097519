import { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSettings } from '../contexts/SettingsContext';
import { PRAYER_NAMES } from '../types/goals';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { format, addDays } from 'date-fns';
import { HIZB_RANGES, quranHelpers } from '../constants/quranConstants';
import {
  PROGRESS_STATUS,
  createQuranProgress,
  createDhikrProgress,
  createPrayerProgress,
  validateQuranProgress,
  validateDhikrProgress,
  validatePrayerProgress,
  mergeProgressDetails,
  calculateCompletionPercentage
} from '../types/progress';

const generateTasksForGoal = (goal, startDate, existingProgress = null) => {
  console.log('Generating tasks for goal:', { goal, startDate, existingProgress });
  
  const tasks = {};
  const today = new Date().toISOString().split('T')[0];

  // For Quran reading goals, generate tasks based on hizb ranges
  if (goal.type === 'quran_reading') {
    let currentDate = new Date(startDate);
    let dayNumber = 0;
    
    // Generate tasks for the duration
    while (dayNumber < 30) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      const startHizbIndex = dayNumber * goal.details.dailyHizb;
      
      // Get hizbs to read for this day
      const hizbsToRead = [];
      for (let i = 0; i < goal.details.dailyHizb; i++) {
        const hizbIndex = startHizbIndex + i;
        if (hizbIndex < HIZB_RANGES.length) {
          hizbsToRead.push(HIZB_RANGES[hizbIndex]);
        }
      }

      if (hizbsToRead.length > 0) {
        const firstHizb = hizbsToRead[0];
        const lastHizb = hizbsToRead[hizbsToRead.length - 1];
        const totalVerses = quranHelpers.getVerseCount(
          firstHizb.range.start.surah,
          firstHizb.range.start.verse,
          lastHizb.range.end.surah,
          lastHizb.range.end.verse
        );

        tasks[dateStr] = createQuranProgress(
          `${firstHizb.range} - ${lastHizb.range}`,
          totalVerses
        );
      }

      currentDate = addDays(currentDate, 1);
      dayNumber++;
    }
  } else if (goal.type === 'dhikr') {
    let currentDate = new Date(startDate);
    const duration = 30; // Default to 30 days
    
    for (let day = 0; day < duration; day++) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      tasks[dateStr] = createDhikrProgress(goal.details.targetCount || 33);
      currentDate = addDays(currentDate, 1);
    }
  } else if (goal.recurrenceType === 'prayer_based') {
    let currentDate = new Date(startDate);
    const duration = 30; // Default to 30 days
    
    for (let day = 0; day < duration; day++) {
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      tasks[dateStr] = createPrayerProgress();
      currentDate = addDays(currentDate, 1);
    }
  }

  return tasks;
};

export const useGoals = () => {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const { settings } = useSettings();

  useEffect(() => {
    if (!currentUser) {
      setGoals([]);
      setLoading(false);
      return;
    }

    const loadGoals = async () => {
      try {
        const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
        const snapshot = await getDoc(goalsRef);
        
        if (snapshot.exists()) {
          const data = snapshot.data();
          setGoals(data.goals || []);
        } else {
          await setDoc(goalsRef, { goals: [] });
          setGoals([]);
        }
      } catch (err) {
        console.error('Error loading goals:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadGoals();
  }, [currentUser]);

  const createGoal = async (type, title, recurrenceType, details) => {
    if (!currentUser) return;

    try {
      const today = new Date().toISOString().split('T')[0];
      const tasks = generateTasksForGoal({
        type,
        details
      }, today);

      const newGoal = {
        id: Math.random().toString(36).substr(2, 9),
        type,
        title,
        recurrenceType,
        details: details || {},
        status: 'active',
        createdAt: new Date().toISOString(),
        progress: tasks
      };

      const updatedGoals = [...goals, newGoal];
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: updatedGoals });
      setGoals(updatedGoals);
      return newGoal;
    } catch (err) {
      console.error('Error creating goal:', err);
      setError(err.message);
      throw err;
    }
  };

  const updateGoalProgress = async (goalId, progressUpdate) => {
    if (!currentUser) return;

    try {
      const { date = new Date().toISOString().split('T')[0], details } = progressUpdate;
      const goal = goals.find(g => g.id === goalId);
      if (!goal) throw new Error('Goal not found');

      console.log('⚠️ updateGoalProgress called for goal:', {
        goalId,
        date,
        type: goal.type,
        existingProgress: goal.progress[date],
        newDetails: details
      });

      const existingProgress = goal.progress[date] || {};
      const mergedDetails = mergeProgressDetails(goal.type, existingProgress.details, details);
      
      console.log('⚠️ Merged details:', mergedDetails);
      
      let updatedProgress = {
        ...existingProgress,
        details: mergedDetails,
        lastUpdated: new Date().toISOString()
      };

      // Validate and update status
      switch (goal.type) {
        case 'quran_reading':
          updatedProgress = validateQuranProgress(goal, updatedProgress);
          break;
        case 'dhikr':
          updatedProgress = validateDhikrProgress(goal, updatedProgress);
          break;
        case 'prayer_based':
          updatedProgress = validatePrayerProgress(goal, updatedProgress);
          break;
      }

      console.log('⚠️ Validated progress:', updatedProgress);

      // Update completion percentage
      updatedProgress.completionPercentage = calculateCompletionPercentage(goal, updatedProgress);

      // Update the goal's progress
      const updatedGoal = {
        ...goal,
        progress: {
          ...goal.progress,
          [date]: updatedProgress
        }
      };

      console.log('⚠️ Final updated goal:', {
        id: updatedGoal.id,
        progress: updatedGoal.progress[date]
      });

      // Update in Firestore
      const updatedGoals = goals.map(g => g.id === goalId ? updatedGoal : g);
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: updatedGoals });
      
      // Update local state
      setGoals(updatedGoals);
      
      return updatedGoal;
    } catch (err) {
      console.error('Error updating goal progress:', err);
      setError(err.message);
      throw err;
    }
  };

  const deleteGoal = async (goalId) => {
    if (!currentUser) return;

    try {
      const updatedGoals = goals.filter(g => g.id !== goalId);
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: updatedGoals });
      setGoals(updatedGoals);
    } catch (err) {
      console.error('Error deleting goal:', err);
      setError(err.message);
      throw err;
    }
  };

  const getTodayProgress = (goalId) => {
    const today = new Date().toISOString().split('T')[0];
    const goal = goals.find(g => g.id === goalId);
    if (!goal) return null;
    return goal.progress[today];
  };

  return {
    goals,
    loading,
    error,
    createGoal,
    updateGoalProgress,
    deleteGoal,
    getTodayProgress,
  };
};

export function useGoalsOld() {
  console.warn('useGoalsOld is deprecated and should not be used');
  const { currentUser } = useAuth();
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('useGoals hook state:', { 
    hasUser: !!currentUser, 
    userId: currentUser?.uid,
    goalsCount: goals.length,
    loading,
    error 
  });

  const loadGoals = useCallback(async () => {
    if (!currentUser) {
      console.log('No user authenticated');
      return;
    }

    try {
      setLoading(true);
      console.log('Loading goals for user:', currentUser?.uid);
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      const snapshot = await getDoc(goalsRef);
      
      if (snapshot.exists()) {
        const data = snapshot.data();
        setGoals(data.goals || []);
      } else {
        await setDoc(goalsRef, { goals: [] });
        setGoals([]);
      }
      setError(null);
    } catch (err) {
      console.error('Error loading goals:', err);
      setError('Failed to load goals');
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    console.log('useGoals effect running');
    loadGoals();
  }, [loadGoals]);

  const createGoal = async (type, title, recurrence, details) => {
    if (!currentUser) return;

    try {
      console.log('Creating goal:', { type, title, recurrence, details });
      const today = new Date().toISOString().split('T')[0];
      const tasks = generateTasksForGoal({
        type,
        details
      }, today);

      const newGoal = {
        id: Math.random().toString(36).substr(2, 9),
        type,
        title,
        recurrence,
        details: details || {},
        status: 'active',
        createdAt: new Date().toISOString(),
        progress: tasks
      };

      const updatedGoals = [...goals, newGoal];
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: updatedGoals });
      setGoals(updatedGoals);
      return newGoal;
    } catch (err) {
      console.error('Error creating goal:', err);
      setError('Failed to create goal');
      throw err;
    }
  };

  const updateGoal = async (goalId, updates) => {
    if (!currentUser) return;

    try {
      const goal = goals.find(g => g.id === goalId);
      if (!goal) throw new Error('Goal not found');

      const updatedGoal = {
        ...goal,
        ...updates,
        modified: new Date()
      };

      const updatedGoals = goals.map(g => g.id === goalId ? updatedGoal : g);
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: updatedGoals });
      
      setGoals(updatedGoals);
    } catch (err) {
      setError('Failed to update goal');
      console.error('Error updating goal:', err);
      throw err;
    }
  };

  const updateGoalProgress = async (goalId, completed, amount = null, prayer = null) => {
    if (!currentUser) return;

    try {
      const goal = goals.find(g => g.id === goalId);
      if (!goal) throw new Error('Goal not found');

      const today = new Date().toISOString().split('T')[0];
      const timestamp = new Date();

      let updatedProgress = {
        ...goal.progress,
        [today]: {
          ...goal.progress[today],
          completed,
          lastUpdated: timestamp
        }
      };

      // Handle prayer-based progress
      if (prayer) {
        updatedProgress[today].prayers = {
          ...updatedProgress[today].prayers,
          [prayer]: { completed, timestamp }
        };
      }

      // Handle amount-based progress
      if (amount !== null) {
        updatedProgress[today].amount = amount;
      }

      const goalsRef = doc(db, 'goals', currentUser.uid);
      await updateDoc(goalsRef, {
        [`${goalId}.progress`]: updatedProgress
      });

      setGoals(prev => prev.map(g => 
        g.id === goalId 
          ? { ...g, progress: updatedProgress }
          : g
      ));
    } catch (err) {
      console.error('Error updating goal progress:', err);
      setError(err.message);
      throw err;
    }
  };

  const deleteGoal = async (goalId) => {
    if (!currentUser) return;

    try {
      const updatedGoals = goals.filter(g => g.id !== goalId);
      const goalsRef = doc(db, 'userGoals', currentUser.uid, 'goals', 'active');
      await setDoc(goalsRef, { goals: updatedGoals });
      setGoals(updatedGoals);
    } catch (err) {
      setError('Failed to delete goal');
      console.error('Error deleting goal:', err);
      throw err;
    }
  };

  // Helper functions for creating recurrence patterns
  const createPrayerBasedPattern = (prayers, trigger = 'after_prayer') => {
    if (!currentUser) throw new Error('No user authenticated');
    // Implementation of createPrayerBasedPattern
  };

  const createTimeBasedPattern = (window, frequency = 1, times) => {
    if (!currentUser) throw new Error('No user authenticated');
    // Implementation of createTimeBasedPattern
  };

  const createDurationBasedPattern = (totalAmount, duration) => {
    if (!currentUser) throw new Error('No user authenticated');
    // Implementation of createDurationBasedPattern
  };

  // Get goal progress for today
  const getTodayProgress = useCallback((goalId) => {
    const goal = goals.find(g => g.id === goalId);
    if (!goal) return null;

    const today = new Date().toISOString().split('T')[0];
    return goal.progress[today] || null;
  }, [goals]);

  return {
    goals,
    loading,
    error,
    createGoal,
    updateGoal,
    updateGoalProgress,
    deleteGoal,
    createPrayerBasedPattern,
    createTimeBasedPattern,
    createDurationBasedPattern,
    refresh: loadGoals,
    getTodayProgress
  };
} 