import React from 'react';
import { useDarkMode } from '../../../../contexts/DarkModeContext';

export const TemplateToggle = ({ showImageTemplates, setShowImageTemplates }) => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className="px-0 py-3 flex gap-2">
      <button
        onClick={() => setShowImageTemplates(true)}
        className={`flex-1 py-2.5 px-4 rounded-xl font-medium transition-colors ${
          showImageTemplates
            ? isDarkMode
              ? 'bg-emerald-500 text-black'
              : 'bg-emerald-600 text-white'
            : isDarkMode
              ? 'bg-[#1A1A1A] text-gray-400'
              : 'bg-gray-100 text-gray-600'
        }`}
      >
        Image Templates
      </button>
      <button
        onClick={() => setShowImageTemplates(false)}
        className={`flex-1 py-2.5 px-4 rounded-xl font-medium transition-colors ${
          !showImageTemplates
            ? isDarkMode
              ? 'bg-emerald-500 text-black'
              : 'bg-emerald-600 text-white'
            : isDarkMode
              ? 'bg-[#1A1A1A] text-gray-400'
              : 'bg-gray-100 text-gray-600'
        }`}
      >
        Basic Templates
      </button>
    </div>
  );
}; 