import React from 'react';
import { MapPin, X, ChevronRight, Settings2 } from 'lucide-react';
import { Spinner } from 'flowbite-react';
import Autosuggest from 'react-autosuggest';
import { useLocationSettings } from '../hooks/useLocationSettings';
import { MenuSection } from './MenuSection';

export const LocationSettings = ({ isDarkMode, onOpenCalculation, onOpenTune }) => {
  const {
    location,
    suggestions,
    isLoading,
    settings,
    handleLocationChange,
    handleAutoDetectLocation,
    handleDeleteLocation,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    getSuggestionValue,
  } = useLocationSettings();

  const renderSuggestion = (suggestion) => (
    <div className="text-sm py-2 px-4 hover:bg-gray-100 cursor-pointer">
      {suggestion.display_name}
    </div>
  );

  const inputProps = {
    placeholder: 'Enter location (city or street)',
    value: location,
    onChange: handleLocationChange,
    className: `w-full h-[42px] px-4 rounded-xl border ${
      isDarkMode 
        ? 'bg-[#1A1A1A] border-gray-800 text-white' 
        : 'bg-white border-gray-200 text-gray-900'
    } focus:ring-emerald-500 focus:border-emerald-500 outline-none`,
  };

  const isMobile = window.innerWidth < 768;

  return (
    <MenuSection title="Location Settings" icon={MapPin} isDarkMode={isDarkMode}>
      <div className="px-4 py-3">
        <label className={`block text-sm mb-2 ${
          isDarkMode ? 'text-gray-400' : 'text-gray-600'
        }`}>Location</label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={{
            container: 'relative flex-grow',
            suggestionsContainer: `absolute z-10 w-full mt-1 rounded-xl ${
              isDarkMode ? 'bg-[#1A1A1A]' : 'bg-white'
            }`,
            suggestionsContainerOpen: `border shadow-lg ${
              isDarkMode ? 'border-gray-800' : 'border-gray-200'
            }`,
            suggestionsList: 'list-none p-0 m-0 max-h-60 overflow-auto rounded-xl',
            suggestion: `p-3 cursor-pointer ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`,
            suggestionHighlighted: isDarkMode ? 'bg-gray-800' : 'bg-gray-50',
          }}
        />
        <div className="flex gap-2 mt-3">
          <button
            onClick={handleAutoDetectLocation}
            disabled={isLoading}
            className={`flex items-center justify-center gap-2 ${
              isDarkMode 
                ? 'bg-emerald-600/20 text-emerald-400' 
                : 'bg-emerald-600 text-white'
            } px-4 py-2 rounded-xl flex-1`}
          >
            {isLoading ? (
              <>
                <Spinner size="sm" />
                <span>Detecting...</span>
              </>
            ) : (
              <>
                <MapPin className="w-4 h-4" />
                <span className="text-sm">Detect</span>
              </>
            )}
          </button>
          <button
            onClick={handleDeleteLocation}
            disabled={!settings.location}
            className={`flex items-center justify-center gap-2 ${
              isDarkMode 
                ? 'bg-red-600/20 text-red-400' 
                : 'bg-red-600 text-white'
            } px-4 py-2 rounded-xl flex-1`}
          >
            <X className="w-4 h-4" />
            <span className="text-sm">Remove</span>
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <button
          onClick={onOpenCalculation}
          className={`w-full flex items-center justify-between px-4 py-3 border-t ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <div className="flex-1">
            <span className={`block text-left ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Prayer Times Calculation
            </span>
            <span className={`block text-left text-xs font-normal ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              {calculationMethodLabels[settings.calculationMethod] || 'Muslim World League'}
            </span>
          </div>
          {isMobile && (
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          )}
        </button>

        <button
          onClick={onOpenTune}
          className={`w-full flex items-center justify-between px-4 py-3 border-t ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Fine-tune Prayer Times
          </span>
          {isMobile && (
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          )}
        </button>
      </div>
    </MenuSection>
  );
};

const calculationMethodLabels = {
  1: 'Muslim World League',
  2: 'Islamic Society of North America',
  3: 'Egyptian General Authority of Survey',
  4: 'Umm Al-Qura University, Makkah',
  5: 'University of Islamic Sciences, Karachi',
  7: 'Institute of Geophysics, University of Tehran',
  8: 'Gulf Region',
  9: 'Kuwait',
  10: 'Qatar',
  11: 'Majlis Ugama Islam Singapura, Singapore',
  12: 'Union Organization islamic de France',
  13: 'Diyanet İşleri Başkanlığı, Turkey',
  14: 'Spiritual Administration of Muslims of Russia',
  15: 'Moonsighting Committee Worldwide'
}; 