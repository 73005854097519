import api from './api';
import { getAuth } from 'firebase/auth';
import { API_BASE_URL } from '../config';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

export const requestLinkingCode = async (deviceId) => {
  try {
    const response = await api.post('/tv/request-code', { device_id: deviceId });
    return response.data;
  } catch (error) {
    console.error('Error requesting linking code:', error);
    throw error;
  }
};

export const linkTVDevice = async (linkingCode) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const token = await user.getIdToken();

    console.log('Linking TV device with code:', linkingCode);

    const response = await api.post(
      '/tv/link',
      { linking_code: linkingCode },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    
    console.log('Link TV response:', response.data);
    
    // After successful backend linking, we'll add a deviceType field
    // to the document in Firestore to help differentiate from Adhan Clocks
    if (response.data && response.data.device_id) {
      try {
        const db = getFirestore();
        const deviceRef = doc(db, 'tv_devices', response.data.device_id);
        const deviceDoc = await getDoc(deviceRef);
        
        if (deviceDoc.exists()) {
          // Update the document to add the deviceType field
          await updateDoc(deviceRef, {
            deviceType: 'tv',
            lastUpdated: new Date()
          });
          console.log('Added deviceType to TV device:', response.data.device_id);
        }
      } catch (err) {
        // Non-critical error, just log it
        console.warn('Error updating TV device type:', err);
      }
    }
    
    return response.data;
  } catch (error) {
    console.error('Error linking TV device:', error);
    throw error;
  }
};

export const setupWebSocket = (deviceId) => {
  // Convert http/https to ws/wss
  const wsBaseUrl = API_BASE_URL.replace('http://', 'ws://').replace('https://', 'wss://');
  const ws = new WebSocket(`${wsBaseUrl}/tv/ws/${deviceId}`);
  
  ws.onopen = () => {
    console.log('WebSocket connected');
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  ws.onclose = () => {
    console.log('WebSocket disconnected');
  };

  return ws;
};

export const fetchUserSettings = async (deviceId) => {
  try {
    const response = await api.get(`/tv-settings/${deviceId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user settings for TV:', error);
    throw error;
  }
};

export const checkTVLinkStatus = async (deviceId) => {
  try {
    const response = await api.get(`/tv-link-status/${deviceId}`);
    return response.data.isLinked;
  } catch (error) {
    console.error('Error checking TV link status:', error);
    return false;
  }
};

export const getUniqueId = async () => {
  // This is a placeholder. In a real app, you'd use a library or native module
  // to get a unique identifier for the device.
  // For example, you might use react-native-device-info:
  // import DeviceInfo from 'react-native-device-info';
  // return DeviceInfo.getUniqueId();
  
  // For now, we'll just return a random string
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
