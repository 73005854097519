// Dhikr Categories
export const DHIKR_CATEGORIES = {
  MORNING_ADHKAR: 'morning_adhkar',
  EVENING_ADHKAR: 'evening_adhkar',
  AFTER_PRAYER: 'after_prayer',
  BEFORE_SLEEP: 'before_sleep',
  GENERAL: 'general'
};

// Registry of all available dhikrs
export const DHIKR_REGISTRY = {
  // Full Tasbih (33-33-33-1)
  FULL_TASBIH: {
    id: 'fullTasbih',
    type: 'sequence',
    category: DHIKR_CATEGORIES.AFTER_PRAYER,
    text: 'Full Tasbih',
    sequence: [
      { 
        id: 'subhanAllah',
        text: 'Subhan Allah',
        arabicText: 'سبحان الله',
        count: 33
      },
      {
        id: 'alhamdulillah',
        text: 'Alhamdulillah',
        arabicText: 'الحمد لله',
        count: 33
      },
      {
        id: 'allahuAkbar',
        text: 'Allahu Akbar',
        arabicText: 'الله أكبر',
        count: 33
      },
      {
        id: 'tahlil',
        text: 'La ilaha illa Allah',
        arabicText: 'لا إله إلا الله وحده لا شريك له، له الملك وله الحمد وهو على كل شيء قدير',
        count: 1
      }
    ]
  },

  // Individual Dhikrs
  SUBHANALLAH_WA_BIHAMDIHI: {
    id: 'subhanAllahiWaBihamdihi',
    type: 'single',
    category: DHIKR_CATEGORIES.GENERAL,
    text: 'SubhanAllahi wa bihamdihi',
    arabicText: 'سبحان الله وبحمده',
    count: 100
  },

  LA_HAWLA: {
    id: 'laHawla',
    type: 'single',
    category: DHIKR_CATEGORIES.GENERAL,
    text: 'La hawla wala quwwata illa billah',
    arabicText: 'لا حول ولا قوة إلا بالله',
    count: 100
  },

  ASTAGHFIRULLAH: {
    id: 'astaghfirullah',
    type: 'single',
    category: DHIKR_CATEGORIES.GENERAL,
    text: 'Astaghfirullah',
    arabicText: 'أستغفر الله',
    count: 100
  },

  LA_ILAHA: {
    id: 'laIlaha',
    type: 'single',
    category: DHIKR_CATEGORIES.GENERAL,
    text: 'La ilaha illa Allah',
    arabicText: 'لا إله إلا الله',
    count: 100
  }
};

// Helper function to get dhikrs by category
export const getDhikrsByCategory = (category) => {
  return Object.values(DHIKR_REGISTRY).filter(dhikr => 
    dhikr.category === category
  );
};

// Helper function to get a dhikr by ID
export const getDhikrById = (id) => {
  return Object.values(DHIKR_REGISTRY).find(dhikr => dhikr.id === id);
};

// Default dhikr goals configuration
export const DEFAULT_DHIKR_GOALS = {
  MORNING_ADHKAR: {
    type: 'dhikr',
    category: DHIKR_CATEGORIES.MORNING_ADHKAR,
    requiredDhikrs: [
      { dhikrId: 'subhanAllahiWaBihamdihi', count: 100, repetitions: 1 },
      { dhikrId: 'astaghfirullah', count: 100, repetitions: 1 }
    ]
  },
  EVENING_ADHKAR: {
    type: 'dhikr',
    category: DHIKR_CATEGORIES.EVENING_ADHKAR,
    requiredDhikrs: [
      { dhikrId: 'laHawla', count: 100, repetitions: 1 },
      { dhikrId: 'laIlaha', count: 100, repetitions: 1 }
    ]
  },
  AFTER_PRAYER: {
    type: 'dhikr',
    category: DHIKR_CATEGORIES.AFTER_PRAYER,
    requiredDhikrs: [
      { dhikrId: 'fullTasbih', count: 1, repetitions: 1 }
    ]
  }
}; 