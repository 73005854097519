import React from 'react';

export const MenuSection = ({ title, icon: Icon, children, isDarkMode }) => {
  return (
    <section className="space-y-6">
      <div className="flex items-center gap-3 mb-6">
        <Icon className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
        <h3 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {title}
        </h3>
      </div>

      <div className={`rounded-xl ${
        isDarkMode ? 'bg-[#161616]' : 'bg-white'
      } border ${
        isDarkMode ? 'border-gray-800' : 'border-gray-100'
      }`}>
        {children}
      </div>
    </section>
  );
}; 