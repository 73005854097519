import logger from '../utils/logger';
import { cachedRequest } from '../utils/apiCache';

const ALADHAN_API_BASE = 'https://api.aladhan.com/v1';

export const fetchPrayerTimes = async (settings, targetDate = null) => {
    try {
        if (!settings?.coordinates?.lat || !settings?.coordinates?.lon) {
            logger.warn('Missing coordinates for prayer times');
            return { times: {}, isAdjusted: false };
        }

        // Use provided date or default to today
        const date = targetDate || new Date();
        
        // Format date in YYYY-MM-DD format for the cache key
        const dateStr = date.toISOString().split('T')[0];

        // Create a cache key based on the settings and date
        const cacheKey = `prayer_times_${settings.coordinates.lat}_${settings.coordinates.lon}_${settings.calculationMethod}_${dateStr}`;

        // Extract forceFresh from settings if provided
        const forceFresh = settings.forceFresh || false;

        return await cachedRequest(cacheKey, async () => {
            const params = new URLSearchParams({
                latitude: settings.coordinates.lat,
                longitude: settings.coordinates.lon,
                method: settings.calculationMethod || 2,
                // Format date in DD-MM-YYYY format for the API
                date: date.toLocaleDateString('en-GB').split('/').join('-')
            });

            // No longer sending tunedTimes to the API - we'll handle adjustments locally
            let isAdjusted = false;

            if (settings.timezone) {
                params.append('timezone', settings.timezone);
            }

            logger.debug('Fetching prayer times with params:', params.toString(), 'for date:', dateStr);

            const response = await fetch(`${ALADHAN_API_BASE}/timings?${params}`);
            
            if (!response.ok) {
                throw new Error(`Prayer times API error: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.code === 200 && data.data && data.data.timings) {
                const timings = data.data.timings;
                return {
                    times: {
                        Fajr: timings.Fajr,
                        Dhuhr: timings.Dhuhr,
                        Asr: timings.Asr,
                        Maghrib: timings.Maghrib,
                        Isha: timings.Isha
                    },
                    isAdjusted
                };
            }

            throw new Error('Invalid response format from prayer times API');
        }, {
            // Cache prayer times for 12 hours
            cacheDuration: 12 * 60 * 60 * 1000,
            // Use forceFresh parameter if provided
            forceFresh
        });

    } catch (error) {
        logger.error('Error fetching prayer times:', error, 'for date:', targetDate);
        throw error;
    }
};

// Add calculation method constants
export const CALCULATION_METHODS = {
    MUSLIM_WORLD_LEAGUE: 3,
    ISLAMIC_SOCIETY_NA: 2,
    EGYPTIAN: 5,
    UMM_AL_QURA: 4,
    DUBAI: 8,
    QATAR: 10,
    KUWAIT: 9,
    MOONSIGHTING_COMMITTEE: 15,
    SINGAPORE: 11,
    TURKEY: 13,
    TEHRAN: 7,
    NORTH_AMERICA: 2
};
