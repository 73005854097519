import React, { useState, useEffect, useRef } from 'react';
import { loadFont, loadBismillahFont } from '../../../utils/fontLoader';

const ArabicOnlyDisplay = React.forwardRef(({
  verses,
  currentVerse,
  handleVerseClick,
  isDarkMode,
  fontSize = 'text-base',
  isRead = {},
  onVerseRead,
}, ref) => {
  const [loadedFonts, setLoadedFonts] = useState(new Set());
  const [isBismillahFontLoaded, setIsBismillahFontLoaded] = useState(false);
  const containerRef = useRef(null);

  const getMushafFont = (pageNumber) => {
    const paddedNumber = pageNumber.toString().padStart(3, '0');
    return `QCF_P${paddedNumber}`;
  };

  // Load fonts for all verses
  useEffect(() => {
    if (!verses) return;

    const loadFonts = async () => {
      // Get unique page numbers
      const pageNumbers = [...new Set(verses.map(verse => verse.page_number))];
      
      // Load bismillah font
      await loadBismillahFont();
      setIsBismillahFontLoaded(true);

      // Load fonts for each page
      for (const pageNumber of pageNumbers) {
        if (!loadedFonts.has(pageNumber)) {
          await loadFont(pageNumber);
          setLoadedFonts(prev => new Set([...prev, pageNumber]));
        }
      }
    };

    loadFonts();
  }, [verses, loadedFonts]);

  if (!verses || !isBismillahFontLoaded) return null;

  // Helper function to render verse number
  const renderVerseNumber = (verseNumber) => (
    <span className={`
      ${fontSize} 
      font-arabic 
      select-none
      opacity-70
      mx-2
      inline-block
    `}
    style={{
      fontFamily: 'KfgqpcHafsUthmanicScriptRegular-1jGEe'
    }}>
      {verseNumber?.toString().replace(/[0-9]/g, d => '٠١٢٣٤٥٦٧٨٩'[d])}
    </span>
  );

  // Helper function to render Bismillah
  const renderBismillah = (verse) => {
    const [surahNumber, verseNumber] = verse.verse_key.split(':').map(Number);
    const shouldShowBismillah = verseNumber === 1 && surahNumber !== 1 && surahNumber !== 9;
    
    if (!shouldShowBismillah) return null;
    
    return (
      <div 
        className={`text-center mb-8 text-xl ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}
        style={{
          fontFamily: getMushafFont(verse.page_number)
        }}
      >
        ﷽
      </div>
    );
  };

  return (
    <div 
      ref={(element) => {
        containerRef.current = element;
        if (typeof ref === 'function') ref(element);
        else if (ref) ref.current = element;
      }}
      className="px-4 py-2 text-justify w-full"
      dir="rtl"
    >
      {verses.map((verse, index) => {
        const [surahId, verseNum] = verse.verse_key.split(':');
        const isVerseRead = isRead[verseNum];

        return (
          <React.Fragment key={verse.verse_key}>
            {renderBismillah(verse)}
            <span
              data-verse-id={verse.verse_key}
              className={`
                ${fontSize === 'text-sm' ? 'text-base' :
                  fontSize === 'text-base' ? 'text-lg' :
                  fontSize === 'text-lg' ? 'text-xl' :
                  fontSize === 'text-xl' ? 'text-2xl' :
                  fontSize === 'text-2xl' ? 'text-3xl' :
                  fontSize === 'text-3xl' ? 'text-4xl' :
                  fontSize === 'text-4xl' ? 'text-5xl' :
                  fontSize === 'text-5xl' ? 'text-6xl' :
                  fontSize === 'text-6xl' ? 'text-7xl' : 'text-lg'}
                font-uthmani 
                cursor-pointer
                inline
                transition-colors duration-300
                leading-[2]
                ${isVerseRead
                  ? isDarkMode ? 'text-emerald-400/80' : 'text-emerald-600/80'
                  : currentVerse === index 
                    ? isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                    : isDarkMode ? 'text-white' : 'text-black'}
              `}
              style={{
                fontFamily: getMushafFont(verse.page_number)
              }}
              onClick={() => handleVerseClick(index)}
            >
              {verse.text_uthmani}
              {renderVerseNumber(verseNum)}
              {' '}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default React.memo(ArabicOnlyDisplay); 