import React from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { defaultSettings } from '../../config/simpleDashboardConfig';
import { User, RotateCcw, AlertCircle } from 'lucide-react';

const PersonalInfo = () => {
  const { settings, updateSettings } = useSettings();
  const { isDarkMode } = useDarkMode();

  const sectionStyles = {
    container: `space-y-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`,
    header: `flex items-center gap-3 mb-6`,
    headerIcon: isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600",
    headerText: `text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`,
    card: `p-6 rounded-[24px] border transition-all duration-200 ${
      isDarkMode 
        ? 'bg-[#111111] border-gray-800 shadow-lg shadow-black/5' 
        : 'bg-white border-gray-100 shadow-sm'
    }`,
    input: `w-full px-4 py-2.5 rounded-xl border transition-colors ${
      isDarkMode
        ? 'bg-[#1A1A1A] border-gray-800 text-white focus:border-emerald-500/50'
        : 'bg-white border-gray-200 text-gray-900 focus:border-emerald-500'
    }`,
    label: `block text-sm font-medium mb-2 ${
      isDarkMode ? 'text-gray-300' : 'text-gray-700'
    }`,
    button: {
      primary: `px-4 py-2.5 rounded-xl transition-all duration-300 shadow-sm hover:shadow-md flex items-center justify-center gap-2 ${
        isDarkMode
          ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
          : 'bg-emerald-600 text-white hover:bg-emerald-700'
      }`,
      danger: `px-4 py-2.5 rounded-xl transition-all duration-300 shadow-sm hover:shadow-md flex items-center justify-center gap-2 ${
        isDarkMode
          ? 'bg-red-600/20 text-red-400 hover:bg-red-600/30'
          : 'bg-red-600 text-white hover:bg-red-700'
      }`
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSettings({
        ...settings,
        nickname: e.target.nickname.value,
        email: e.target.email.value,
      });
      // You could add a success toast here
    } catch (error) {
      console.error('Error updating settings:', error);
      // You could add an error toast here
    }
  };

  const handleResetDashboard = async () => {
    if (window.confirm('Are you sure you want to reset your dashboard settings? This cannot be undone.')) {
      try {
        await updateSettings({
          ...settings,
          simpleDashboard: defaultSettings
        });
        // You could add a success toast here
      } catch (error) {
        console.error('Error resetting dashboard:', error);
        // You could add an error toast here
      }
    }
  };

  return (
    <div className="space-y-12">
      {/* Personal Information Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <User className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Personal Information</h3>
        </div>

        <div className={sectionStyles.card}>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="nickname" className={sectionStyles.label}>
                Nickname
              </label>
              <input
                id="nickname"
                type="text"
                defaultValue={settings.nickname}
                placeholder="Your nickname"
                required
                className={sectionStyles.input}
              />
            </div>

            <div>
              <label htmlFor="email" className={sectionStyles.label}>
                Email Address
              </label>
              <input
                id="email"
                type="email"
                defaultValue={settings.email}
                placeholder="name@email.com"
                required
                className={sectionStyles.input}
              />
            </div>

            <button type="submit" className={sectionStyles.button.primary}>
              Save Changes
            </button>
          </form>
        </div>
      </section>

      {/* Dashboard Reset Section */}
      <section className={sectionStyles.container}>
        <div className={sectionStyles.header}>
          <RotateCcw className={sectionStyles.headerIcon} />
          <h3 className={sectionStyles.headerText}>Reset Dashboard</h3>
        </div>

        <div className={sectionStyles.card}>
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <AlertCircle className={isDarkMode ? "text-red-400" : "text-red-600"} size={20} />
              <p className={isDarkMode ? "text-gray-400" : "text-gray-600"}>
                This will reset your dashboard layout and settings to their default values. This action cannot be undone.
              </p>
            </div>
            
            <button 
              onClick={handleResetDashboard}
              className={sectionStyles.button.danger}
            >
              <RotateCcw size={18} />
              Reset Dashboard Settings
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PersonalInfo;