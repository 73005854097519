import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';

const LoadingAnimation = () => {
  const { isDarkMode } = useDarkMode();
  
  return (
    <div className="flex items-center justify-center">
      <div className={`animate-spin rounded-full h-12 w-12 border-b-2 ${
        isDarkMode ? 'border-emerald-400' : 'border-emerald-600'
      }`} />
    </div>
  );
};

export default LoadingAnimation;
