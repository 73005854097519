import React, { useEffect, useState, useMemo } from 'react';
import { Clock, MapPin, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../../contexts/SettingsContext';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { getCountdown, adjustPrayerTimes, haveAllPrayersPassed } from '../../utils/prayerTimeUtils';

const PrayerTimesCard = ({ prayerTimes, tomorrowPrayerTimes, error, hideTitle, isLoading }) => {
  const navigate = useNavigate();
  const { settings, updateSettings } = useSettings();
  const { isDarkMode } = useDarkMode();
  const [showTomorrow, setShowTomorrow] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isSettingLocation, setIsSettingLocation] = useState(false);
  const [locationError, setLocationError] = useState(null);

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  // Handle automatic location setting
  const handleSetLocation = async () => {
    setIsSettingLocation(true);
    setLocationError(null);

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      });

      const { latitude, longitude } = position.coords;

      // Get place name using reverse geocoding
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      
      // Extract just the place name (city)
      let placeName = data.display_name;
      if (data.address) {
        // Try to get the most relevant part of the address
        const addressParts = [
          data.address.city,
          data.address.town,
          data.address.village,
          data.address.hamlet,
          data.address.suburb,
          data.address.county
        ].filter(Boolean);
        
        if (addressParts.length > 0) {
          placeName = addressParts[0];
        }
      }

      await updateSettings({
        ...settings,
        coordinates: { lat: latitude, lng: longitude },
        location: placeName
      });

      setLocationError(null);
    } catch (error) {
      console.error('Error getting location:', error);
      setLocationError(
        error.code === 1 
          ? 'Please enable location access in your browser settings.'
          : 'Could not get your location. Please try again.'
      );
    } finally {
      setIsSettingLocation(false);
    }
  };

  // Check if all prayers have passed and we should show tomorrow's times
  useEffect(() => {
    if (prayerTimes && Object.keys(prayerTimes).length > 0) {
      const allPrayersPassed = haveAllPrayersPassed(prayerTimes, currentTime, settings.tunedTimes);
      setShowTomorrow(allPrayersPassed && tomorrowPrayerTimes && Object.keys(tomorrowPrayerTimes).length > 0);
    }
  }, [prayerTimes, tomorrowPrayerTimes, currentTime, settings.tunedTimes]);

  // Determine which prayer times to display
  const displayPrayerTimes = showTomorrow ? tomorrowPrayerTimes : prayerTimes;

  // Debug logging for Maghrib time
  useEffect(() => {
    if (displayPrayerTimes?.times?.Maghrib) {
      console.log('[PrayerTimesCard] Maghrib time before adjustment:', {
        time: displayPrayerTimes.times.Maghrib,
        isAdjusted: displayPrayerTimes.isAdjusted,
        tuning: settings.tunedTimes?.Maghrib || 0
      });
    }
  }, [displayPrayerTimes, settings.tunedTimes]);

  // Apply tuned prayer times only if they haven't been adjusted yet
  const adjustedPrayerTimes = useMemo(() => {
    // Return empty object if no coordinates or location are set
    if (!settings.coordinates || !settings.location) return {};
    
    if (!displayPrayerTimes?.times) return {};

    // Always apply local adjustments for consistency across components
    console.log('[PrayerTimesCard] Always applying local adjustments to prayer times');
    
    const adjusted = Object.entries(displayPrayerTimes.times).reduce((acc, [prayer, time]) => {
      const adjustment = settings.tunedTimes?.[prayer] || 0;
      
      if (adjustment === 0) {
        acc[prayer] = time;
        return acc;
      }

      const [hours, minutes] = time.split(':').map(num => parseInt(num, 10));
      let totalMinutes = (hours * 60 + minutes + adjustment) % (24 * 60);
      if (totalMinutes < 0) totalMinutes += 24 * 60;
      
      const adjustedHours = Math.floor(totalMinutes / 60);
      const adjustedMinutes = totalMinutes % 60;
      
      acc[prayer] = `${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}`;
      return acc;
    }, {});

    if (adjusted.Maghrib) {
      console.log('[PrayerTimesCard] Maghrib time after adjustment:', adjusted.Maghrib);
    }

    return adjusted;
  }, [displayPrayerTimes, settings.tunedTimes, settings.coordinates, settings.location]);

  // Check loading state first
  if (isLoading) {
    return (
      <div className="flex flex-col h-full">
        <div className={`flex items-center ${hideTitle ? 'hidden' : 'gap-2 mb-4'}`}>
          <Clock className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
          <h2 className={`text-lg font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
            Prayer Times
          </h2>
        </div>
        
        <div className="flex-1 flex flex-col justify-between">
          <div className="flex h-full flex-col justify-between animate-pulse">
            {[...Array(5)].map((_, index) => (
              <div 
                key={index}
                className={`flex items-center justify-between px-4 py-2 rounded-xl`}
              >
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-3">
                    <div className="w-6 h-5 bg-gray-200 dark:bg-gray-700 rounded" />
                    <div className="w-20 h-5 bg-gray-200 dark:bg-gray-700 rounded" />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="w-14 h-5 bg-gray-200 dark:bg-gray-700 rounded" />
                  <div className="w-2 h-2 rounded-full bg-gray-200 dark:bg-gray-700" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Then check for coordinates or location
  if (!settings.coordinates || !settings.location) {
    return (
      <div className="h-full flex flex-col">
        <div className={`flex items-center justify-between ${hideTitle ? 'hidden' : 'mb-4'}`}>
          <div className="flex items-center gap-2">
            <Clock className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
            <h2 className={`text-lg font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
              Prayer Times
            </h2>
          </div>
        </div>

        <div className="flex-1 flex flex-col items-center justify-center text-center p-8">
          <MapPin className={`w-16 h-16 mb-6 ${
            isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
          }`} />
          <h3 className={`text-xl font-medium mb-3 ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Set Your Location
          </h3>
          <p className={`text-sm max-w-xs mx-auto mb-6 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Enable location access to get accurate prayer times for your area.
          </p>
          {locationError && (
            <p className="text-sm text-red-500 mb-4">{locationError}</p>
          )}
          <button
            onClick={handleSetLocation}
            disabled={isSettingLocation}
            className={`px-4 py-2 rounded-xl transition-all duration-200 ${
              isDarkMode
                ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
                : 'bg-emerald-600 text-white hover:bg-emerald-700'
            } ${isSettingLocation ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSettingLocation ? 'Getting Location...' : 'Use My Location'}
          </button>
        </div>
      </div>
    );
  }

  // Then check for empty prayer times
  if (!displayPrayerTimes?.times || Object.keys(adjustedPrayerTimes).length === 0) {
    return (
      <div className="text-center py-4">
        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          No prayer times available.
        </p>
      </div>
    );
  }

  // Finally check for errors
  if (error) {
    return (
      <div className="text-center py-4">
        <p className={`text-sm ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
          {error}
        </p>
      </div>
    );
  }

  // Get the next prayer using adjusted times
  const getNextPrayer = () => {
    if (!adjustedPrayerTimes || Object.keys(adjustedPrayerTimes).length === 0) return null;
    const { nextPrayer } = getCountdown(adjustedPrayerTimes, currentTime);
    return nextPrayer;
  };

  const nextPrayer = getNextPrayer();

  // Add this helper function to extract city name
  const getDisplayLocation = (location) => {
    if (!location) return '';
    // Split by commas and take first part (usually the city)
    return location.split(',')[0].trim();
  };

  return (
    <div className="flex flex-col h-full">
      <div className={`flex items-center justify-between ${hideTitle ? 'hidden' : 'mb-4'}`}>
        <div className="flex items-center gap-2">
          <Clock className={isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600"} />
          <h2 className={`text-lg font-medium ${isDarkMode ? "text-white" : "text-gray-900"}`}>
            Prayer Times
          </h2>
        </div>
        
        <div className="flex items-center gap-2">
          {settings.location && (
            <div className="flex items-center gap-1">
              <MapPin className={`w-3.5 h-3.5 ${isDarkMode ? "text-gray-400" : "text-gray-600"}`} />
              <span className={`text-sm ${isDarkMode ? "text-gray-400" : "text-gray-600"}`}>
                {settings.location}
              </span>
            </div>
          )}
          {showTomorrow && (
            <div className="flex items-center gap-1">
              <Calendar className={`w-4 h-4 ${isDarkMode ? "text-emerald-400" : "text-emerald-600"}`} />
              <span className={`text-sm ${isDarkMode ? "text-emerald-400" : "text-emerald-600"}`}>
                Tomorrow
              </span>
            </div>
          )}
        </div>
      </div>
      
      <div className="flex-1 flex flex-col justify-between">
        <div className="flex h-full flex-col justify-between">
          {Object.entries(adjustedPrayerTimes).map(([prayer, time]) => {
            const isNext = prayer === nextPrayer;
            
            return (
              <div 
                key={prayer} 
                className={`group relative flex items-center justify-between px-4 py-2 rounded-xl transition-all duration-300 ${
                  isNext 
                    ? isDarkMode 
                      ? 'bg-gradient-to-r from-emerald-950/40 to-emerald-900/30 border border-emerald-500/20'
                      : 'bg-gradient-to-r from-emerald-50/80 to-white/60 border border-emerald-100/30'
                    : ''
                }`}
              >
                <div className="flex items-center gap-4 relative z-10 text-rendering-optimizeLegibility">
                  <div className="flex items-center gap-3">
                    <span className={`text-sm font-medium antialiased ${
                      isNext 
                        ? isDarkMode ? 'text-emerald-300' : 'text-emerald-700'
                        : isDarkMode ? 'text-gray-500' : 'text-gray-500'
                    }`}>
                      {(Object.keys(adjustedPrayerTimes).indexOf(prayer) + 1).toString().padStart(2, '0')}
                    </span>
                    <span className={`text-base ${
                      isNext 
                        ? isDarkMode ? 'text-emerald-400 font-medium' : 'text-emerald-800 font-medium'
                        : isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    } ${isDarkMode ? 'group-hover:text-gray-100' : 'group-hover:text-gray-900'}`}>
                      {prayer}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-4 relative z-10 text-rendering-optimizeLegibility">
                  <span className={`text-base font-medium antialiased ${
                    isNext 
                      ? isDarkMode ? 'text-emerald-300' : 'text-emerald-700'
                      : isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  }`}>
                    {time}
                  </span>
                  <div className={`w-2 h-2 rounded-full ${
                    isNext 
                      ? 'bg-emerald-500 animate-pulse' 
                      : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                  }`} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PrayerTimesCard;