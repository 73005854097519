import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft } from 'lucide-react';

export const MobilePageTransition = ({ 
  children, 
  show, 
  onClose, 
  title, 
  isDarkMode,
  hideHeader = false,
  rightButton
}) => {
  if (!show) return null;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
          className={`fixed inset-0 z-50 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}
        >
          {!hideHeader && (
            <header className={`sticky top-0 px-4 z-50 ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
              <div className="safe-area-padding">
                <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="py-4">
                  <div className="flex items-center justify-between">
                    <button 
                      onClick={onClose} 
                      className={`flex items-center ${
                        isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                      }`}
                    >
                      <ChevronLeft className="w-5 h-5" />
                      <span className="text-xl">Back</span>
                    </button>
                    <h1 className={`text-xl font-semibold flex-1 text-center ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {title}
                    </h1>
                    {rightButton && (
                      <button
                        onClick={rightButton.onClick}
                        className={`${
                          isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                        } text-xl`}
                      >
                        {rightButton.text}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </header>
          )}
          <div className="px-4 pb-24">
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}; 