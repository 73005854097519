import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'lucide-react';
import { useAdhanSettings } from '../hooks/useAdhanSettings';

export const MobilePageTransition = ({ 
  show, 
  onClose, 
  title, 
  children, 
  isDarkMode 
}) => {
  const { adhanAudioRef, setIsPlayingAdhan } = useAdhanSettings();

  // Handle cleanup of audio
  const stopAudio = () => {
    if (adhanAudioRef?.current) {
      adhanAudioRef.current.pause();
      adhanAudioRef.current.currentTime = 0;
      setIsPlayingAdhan(false);
    }
  };

  // Cleanup on unmount or when show changes
  useEffect(() => {
    if (!show) {
      stopAudio();
    }
    return () => {
      stopAudio();
    };
  }, [show]);

  const handleBack = () => {
    stopAudio();
    onClose();
  };

  if (!show) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onAnimationComplete={() => {
        if (!show) {
          stopAudio();
        }
      }}
      className="fixed inset-0 z-50"
    >
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
        className={`absolute inset-0 h-full overflow-y-auto ${
          isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
        }`}
      >
        {/* Header */}
        <header className={`sticky top-0 px-4 z-50 ${
          isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
        }`}>
          <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="py-4">
            <div className="flex items-center">
              <button 
                onClick={handleBack}
                className={`flex items-center ${
                  isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                } absolute left-4`}
              >
                <ChevronLeft className="w-5 h-5" />
                <span className="text-xl">Back</span>
              </button>
              <h1 className={`text-xl font-semibold flex-1 text-center ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {title}
              </h1>
            </div>
          </div>
        </header>

        {/* Content */}
        <div className="max-w-xl mx-auto pb-6">
          <div className="px-4">
            {children}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}; 