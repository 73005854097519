import React, { useState, useEffect, useRef } from 'react';

const PortraitSlideshow = ({ images = [], duration = 60, fadeDuration = 1000, className = '' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);

  // Refs for timers
  const displayTimerRef = useRef(null);
  const fadeTimerRef = useRef(null);

  // Schedule the next image transition
  useEffect(() => {
    if (images.length <= 1) return;
    const delay = duration * 1000 - fadeDuration;
    displayTimerRef.current = setTimeout(() => {
      const next = (currentIndex + 1) % images.length;
      setNextIndex(next);
    }, delay);
    return () => clearTimeout(displayTimerRef.current);
  }, [currentIndex, images, duration, fadeDuration]);

  // When nextIndex gets set, trigger the overlay fade in on next tick
  useEffect(() => {
    if (nextIndex !== null) {
      const t = setTimeout(() => {
        setOverlayVisible(true);
      }, 20); // small delay to ensure the overlay mounts with initial opacity 0
      return () => clearTimeout(t);
    }
  }, [nextIndex]);

  // When overlay is visible, wait for fadeDuration then commit the transition
  useEffect(() => {
    if (overlayVisible && nextIndex !== null) {
      fadeTimerRef.current = setTimeout(() => {
        setCurrentIndex(nextIndex);
        setNextIndex(null);
        setOverlayVisible(false);
      }, fadeDuration);
      return () => clearTimeout(fadeTimerRef.current);
    }
  }, [overlayVisible, nextIndex, fadeDuration]);

  // Preload images
  useEffect(() => {
    images.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, [images]);

  if (!images.length) return null;

  return (
    <div className={`relative w-full h-full overflow-hidden ${className}`}>
      {/* Base (current) image */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${images[currentIndex]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1,
          opacity: 1
        }}
      ></div>

      {/* Overlay (next) image rendered only during transition */}
      {nextIndex !== null && (
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${images[nextIndex]})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 2,
            opacity: overlayVisible ? 1 : 0,
            transition: `opacity ${fadeDuration}ms ease-in-out`
          }}
        ></div>
      )}
    </div>
  );
};

export default PortraitSlideshow; 