import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

const StatusBadge = ({ isDarkMode, isNext, isOverdue, isMissed }) => {
  if (!isNext && !isOverdue && !isMissed) return null;

  let bgColor = isDarkMode ? 'bg-emerald-500/20' : 'bg-emerald-100';
  let textColor = isDarkMode ? 'text-emerald-400' : 'text-emerald-600';

  if (isOverdue) {
    bgColor = isDarkMode ? 'bg-amber-500/20' : 'bg-amber-100';
    textColor = isDarkMode ? 'text-amber-400' : 'text-amber-600';
  } else if (isMissed) {
    bgColor = isDarkMode ? 'bg-red-500/20' : 'bg-red-100';
    textColor = isDarkMode ? 'text-red-400' : 'text-red-600';
  }

  return (
    <span className={`text-xs px-2 py-1 rounded-full ${bgColor} ${textColor}`}>
      {isMissed ? 'Missed' : isOverdue ? 'Overdue' : 'Next'}
    </span>
  );
};

export const DhikrSelectionView = ({
  show,
  onClose,
  title,
  isDarkMode,
  pendingGoalDhikrs = [],
  categorizedDhikrs = {},
  onSelectDhikr,
}) => {
  const [showAllDhikrs, setShowAllDhikrs] = useState(false);

  const renderMainView = () => (
    <div className="px-4 space-y-6 pt-4">
      {/* Upcoming Dhikrs from Goals */}
      {pendingGoalDhikrs.length > 0 ? (
        <div className="mb-6">
          <h3 className={`text-sm font-medium mb-3 ${
            isDarkMode ? 'text-white/60' : 'text-gray-500'
          }`}>
            Up Next
          </h3>
          <div className="space-y-2">
            {pendingGoalDhikrs.map(dhikr => (
              <button
                key={`${dhikr.id}-${dhikr.goalId}-${dhikr.prayer || ''}`}
                className={`w-full p-4 rounded-xl transition-all duration-300 ${
                  isDarkMode 
                    ? 'bg-emerald-500/10 hover:bg-emerald-500/20'
                    : 'bg-emerald-50 hover:bg-emerald-100'
                }`}
                onClick={() => onSelectDhikr(dhikr)}
              >
                <div className="flex justify-between items-center">
                  <div className="text-left">
                    <div className="flex items-center gap-2">
                      <h4 className={`font-medium ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {dhikr.text}
                      </h4>
                      <StatusBadge 
                        isDarkMode={isDarkMode}
                        isNext={dhikr.isNext}
                        isOverdue={dhikr.isOverdue}
                        isMissed={dhikr.isMissed}
                      />
                    </div>
                    <p className={`text-sm mt-1 ${
                      isDarkMode ? 'text-white/60' : 'text-gray-600'
                    }`}>
                      {dhikr.goalTitle}
                    </p>
                  </div>
                  <div className={`text-sm ${
                    isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
                  }`}>
                    {dhikr.progress}/{dhikr.target}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className={`p-6 rounded-xl text-center ${
          isDarkMode ? 'bg-white/5' : 'bg-gray-50'
        }`}>
          <p className={`text-sm ${
            isDarkMode ? 'text-white/60' : 'text-gray-600'
          }`}>
            No upcoming dhikrs from goals
          </p>
        </div>
      )}

      {/* All Dhikrs Button */}
      <div>
        <button
          onClick={() => setShowAllDhikrs(true)}
          className={`w-full p-4 rounded-xl transition-all duration-300 flex items-center justify-between ${
            isDarkMode
              ? 'bg-white/5 hover:bg-white/10'
              : 'bg-gray-50 hover:bg-gray-100'
          }`}
        >
          <span className={`font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            All Dhikrs
          </span>
          <ChevronRight className={
            isDarkMode ? 'text-white/40' : 'text-gray-400'
          } />
        </button>
      </div>
    </div>
  );

  const renderAllDhikrsView = () => (
    <div className="px-4 space-y-6 pt-4">
      {Object.entries(categorizedDhikrs).map(([category, dhikrs]) => (
        <div key={category}>
          <h3 className={`text-sm font-medium mb-3 ${
            isDarkMode ? 'text-white/60' : 'text-gray-500'
          }`}>
            {category.split('_').map(word => 
              word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')}
          </h3>
          <div className="space-y-2">
            {dhikrs.map(dhikr => (
              <button
                key={dhikr.id}
                className={`w-full p-4 rounded-xl transition-all duration-300 ${
                  isDarkMode
                    ? 'bg-white/5 hover:bg-white/10'
                    : 'bg-gray-50 hover:bg-gray-100'
                }`}
                onClick={() => {
                  onSelectDhikr(dhikr);
                  setShowAllDhikrs(false);
                }}
              >
                <div className="flex justify-between items-center">
                  <div className="text-left">
                    <h4 className={`font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {dhikr.text}
                    </h4>
                    {dhikr.goalAssociations?.length > 0 && (
                      <div className="flex gap-2 mt-2">
                        {dhikr.goalAssociations.map(goal => (
                          <div
                            key={goal.goalId}
                            className={`text-xs px-2 py-1 rounded-full ${
                              isDarkMode
                                ? 'bg-emerald-500/10 text-emerald-400'
                                : 'bg-emerald-50 text-emerald-600'
                            }`}
                          >
                            {goal.progress}/{goal.target}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {dhikr.totalCompletions > 0 && (
                    <div className={`text-sm ${
                      isDarkMode ? 'text-white/40' : 'text-gray-400'
                    }`}>
                      {dhikr.totalCompletions} today
                    </div>
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50"
        >
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
            className={`fixed inset-0 z-50 ${isDarkMode ? 'bg-[#111111]' : 'bg-white'}`}
          >
            {/* Header */}
            <header className="sticky top-0 z-40">
              <div className={`${
                isDarkMode ? 'bg-[#111111]' : 'bg-white'
              } backdrop-blur-xl border-b ${
                isDarkMode ? 'border-white/5' : 'border-black/5'
              }`}>
                <div style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1rem)' }} className="px-4 py-4">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => {
                        if (showAllDhikrs) {
                          setShowAllDhikrs(false);
                        } else {
                          onClose();
                        }
                      }}
                      className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}
                    >
                      {showAllDhikrs ? 'Back' : 'Cancel'}
                    </button>
                    <h2 className={`text-lg font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {showAllDhikrs ? 'All Dhikrs' : title}
                    </h2>
                    <div className="w-12" /> {/* Spacer for alignment */}
                  </div>
                </div>
              </div>
            </header>

            {/* Content */}
            <div className="overflow-y-auto h-full pb-8" style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}>
              {showAllDhikrs ? renderAllDhikrsView() : renderMainView()}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}; 