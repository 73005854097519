import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSettings } from '../contexts/SettingsContext';
import { fetchDateTime, getTimezoneFromCoordinates } from '../services/dateTimeService';
import { fetchPrayerTimes } from '../services/prayerService';
import { fetchDuas } from '../services/duaService';
import { fetchHadiths } from '../services/hadithService';
import { useDarkMode } from '../contexts/DarkModeContext';
import DashboardTemplate from './DashboardTemplate';
import { useDeviceLinking } from '../hooks/useDeviceLinking';
import ErrorDisplay from './ErrorDisplay';
import DeviceLinkingScreen from './DeviceLinkingScreen';
import DashboardLoadingScreen from './DashboardLoadingScreen';
import { haveAllPrayersPassed } from '../utils/prayerTimeUtils';

const SimpleDashboard = () => {
  const { currentUser } = useAuth();
  const { settings, isInitialized } = useSettings();
  const [dashboardData, setDashboardData] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const { isDarkMode } = useDarkMode();
  const deviceLinking = useDeviceLinking();
  const [lastRefreshDate, setLastRefreshDate] = useState(new Date().toDateString());
  const [refreshedAfterIsha, setRefreshedAfterIsha] = useState(false);

  // Add debug logging
  console.log('SimpleDashboard render:', {
    hasSettings: !!settings,
    isInitialized,
    deviceLinkingState: deviceLinking,
    currentUser: !!currentUser
  });

  // Handle audio format detection
  useEffect(() => {
    const audio = document.createElement('audio');
    const canPlayOgg = audio.canPlayType('audio/ogg; codecs="vorbis"');
    
    if (window) {
      window.preferredAudioFormat = canPlayOgg ? 'ogg' : 'mp3';
    }
  }, []);

  // Handle timezone detection
  useEffect(() => {
    if (settings?.coordinates) {
      const { lat, lon } = settings.coordinates;
      const tz = getTimezoneFromCoordinates(lat, lon);
      setTimezone(tz);
      console.log('Detected timezone:', tz);
      
      if (window) {
        window.deviceTimezone = tz;
      }
    }
  }, [settings?.coordinates]);

  // Handle dashboard data loading
  useEffect(() => {
    if (!isInitialized || !settings?.simpleDashboard) {
      console.log('Skipping dashboard data load:', { 
        isInitialized, 
        hasSimpleDashboard: !!settings?.simpleDashboard 
      });
      return;
    }

    const loadDashboardData = async () => {
      try {
        const [dateTimeData, duasData, hadithsData] = await Promise.all([
          fetchDateTime(),
          fetchDuas(),
          fetchHadiths()
        ]);

        let prayerTimesData = {};
        let prayerTimesError = null;
        let tomorrowPrayerTimesData = {};
        let tomorrowPrayerTimesError = null;
        
        if (settings?.location && settings?.coordinates && timezone) {
          try {
            // Fetch today's prayer times
            prayerTimesData = await fetchPrayerTimes({
              location: settings.location,
              coordinates: settings.coordinates,
              calculationMethod: settings.calculationMethod,
              timezone
            });
            
            // Fetch tomorrow's prayer times
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            
            tomorrowPrayerTimesData = await fetchPrayerTimes({
              location: settings.location,
              coordinates: settings.coordinates,
              calculationMethod: settings.calculationMethod,
              timezone
            }, tomorrow);
            
          } catch (error) {
            console.error("Error fetching prayer times:", error);
            prayerTimesError = "Error fetching prayer times";
          }
        }

        const newDashboardData = {
          dateTime: dateTimeData,
          prayerTimes: prayerTimesData,
          prayerTimesError,
          tomorrowPrayerTimes: tomorrowPrayerTimesData,
          tomorrowPrayerTimesError,
          timezone,
          duas: duasData,
          currentDuaIndex: Math.floor(Math.random() * duasData.length),
          hadiths: hadithsData,
          currentHadithIndex: Math.floor(Math.random() * hadithsData.length),
        };

        setDashboardData(newDashboardData);
        
        // Reset the refreshedAfterIsha flag when loading new data
        setRefreshedAfterIsha(false);
        
        // Update the last refresh date
        setLastRefreshDate(new Date().toDateString());
      } catch (error) {
        console.error("Error loading dashboard data:", error);
      }
    };

    loadDashboardData();
  }, [settings, isInitialized, timezone]);

  // Add a timer to refresh prayer times at midnight or after Isha
  useEffect(() => {
    if (!dashboardData || !dashboardData.prayerTimes) return;
    
    // Function to check if we need to refresh prayer times
    const checkForPrayerTimesRefresh = async () => {
      const now = new Date();
      const currentDate = now.toDateString();
      
      // Check if date has changed since last refresh
      const dateChanged = lastRefreshDate !== currentDate;
      
      // Check if all prayers for the day have passed (after Isha)
      const allPrayersPassed = haveAllPrayersPassed(
        dashboardData.prayerTimes, 
        now, 
        settings.tunedTimes
      );
      
      // Determine if we should refresh
      const shouldRefresh = 
        dateChanged || 
        (allPrayersPassed && !refreshedAfterIsha);
      
      if (shouldRefresh) {
        console.log('Refreshing prayer times:', 
          dateChanged ? 'Date changed' : 'After Isha');
        
        if (settings?.location && settings?.coordinates && timezone) {
          try {
            // Force fresh data by setting forceFresh to true
            const prayerTimesData = await fetchPrayerTimes({
              location: settings.location,
              coordinates: settings.coordinates,
              calculationMethod: settings.calculationMethod,
              timezone,
              forceFresh: true // Force fresh data
            });
            
            // Also refresh tomorrow's prayer times
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            
            const tomorrowPrayerTimesData = await fetchPrayerTimes({
              location: settings.location,
              coordinates: settings.coordinates,
              calculationMethod: settings.calculationMethod,
              timezone,
              forceFresh: true
            }, tomorrow);
            
            setDashboardData(prev => ({
              ...prev,
              prayerTimes: prayerTimesData,
              prayerTimesError: null,
              tomorrowPrayerTimes: tomorrowPrayerTimesData,
              tomorrowPrayerTimesError: null
            }));
            
            // Update state to prevent repeated refreshes
            setLastRefreshDate(currentDate);
            if (allPrayersPassed) {
              setRefreshedAfterIsha(true);
            }
          } catch (error) {
            console.error("Error refreshing prayer times:", error);
            setDashboardData(prev => ({
              ...prev,
              prayerTimesError: "Error refreshing prayer times"
            }));
          }
        }
      }
    };
    
    // Check immediately
    checkForPrayerTimesRefresh();
    
    // Set up an interval to check every minute
    const interval = setInterval(checkForPrayerTimesRefresh, 60000);
    
    return () => clearInterval(interval);
  }, [dashboardData, settings, timezone, lastRefreshDate, refreshedAfterIsha]);

  if (!isInitialized || !settings?.simpleDashboard || !dashboardData) {
    return <DashboardLoadingScreen />;
  }

  if (deviceLinking.error) {
    return <ErrorDisplay error={deviceLinking.error} />;
  }

  if (!deviceLinking.isLinked && !currentUser) {
    if (deviceLinking.isChecking) {
      return <DashboardLoadingScreen />;
    }
    return <DeviceLinkingScreen 
      code={deviceLinking.linkingCode}
      isLoading={deviceLinking.isChecking} 
    />;
  }

  return (
    <DashboardTemplate 
      templateId={settings.simpleDashboard.templateId || 'adhanClock'}
      dashboardData={{
        ...dashboardData,
        settings,
        onDateChange: (newDateTime) => {
          setDashboardData(prev => ({
            ...prev,
            dateTime: newDateTime
          }));
        }
      }}
      onDuaChange={(index) => setDashboardData(prev => ({
        ...prev,
        currentDuaIndex: index
      }))}
    />
  );
};

export default SimpleDashboard;
