import { useState, useEffect } from 'react';
import { permissionManager } from '../services/PermissionManager';

export function usePermissions() {
  const [permissions, setPermissions] = useState({
    location: permissionManager.locationPermission,
    motion: permissionManager.motionPermission
  });

  useEffect(() => {
    const handleUpdate = (state) => {
      setPermissions(state);
    };

    permissionManager.addListener(handleUpdate);
    return () => permissionManager.removeListener(handleUpdate);
  }, []);

  return permissions;
} 