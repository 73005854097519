import React, { useState, useEffect, useRef } from 'react';
import { MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import CrossfadingBackground from '../shared/CrossfadingBackground';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';
import PortraitSlideshow from '../shared/PortraitSlideshow';

const PortraitGalleryTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="relative w-full h-screen bg-gray-950 overflow-hidden">
      {/* Main Grid Layout */}
      <div className="h-full grid grid-cols-12 gap-4 p-8">
        {/* Left Content Section */}
        <div className="col-span-8 flex flex-col justify-between pr-8">
          {/* Top Section */}
          <div>
            <div className="flex items-center gap-2 mb-12">
              <MapPin className={`w-5 h-5 ${accentColor.light}`} />
              <span className="text-white/90 text-lg tracking-wide">
                {settings.location?.split(',')[0].toUpperCase()}
              </span>
            </div>

            <div className="mb-16">
              <p className={`${accentColor.base} text-sm tracking-widest mb-3`}>CURRENT TIME</p>
              <SimpleTimeCard 
                render={({ time }) => (
                  <h1 className="text-[140px] font-extralight text-white tracking-tight leading-none mb-8">
                    {time}
                  </h1>
                )}
              />
              <div className="flex gap-12 items-baseline">
                <SimplePrayerCountdownCard
                  prayerTimes={prayerTimes}
                  render={({ countdown, nextPrayer }) => (
                    <div>
                      <p className={`${accentColor.base} text-sm tracking-widest mb-3`}>
                        UNTIL {nextPrayer}
                      </p>
                      <p className={`text-4xl font-light ${accentColor.light} tracking-tight`}>
                        {countdown}
                      </p>
                    </div>
                  )}
                />
                <div className="text-right" dir="rtl">
                  <p className="text-white/80 text-2xl leading-relaxed">
                    {duas?.[currentDuaIndex]?.arabic}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Prayer Times Grid */}
          <SimplePrayerTimesCard
            prayerTimes={prayerTimes}
            settings={settings}
            render={({ prayers }) => (
              <div className="grid grid-cols-5 gap-8">
                {prayers.map(([prayer, time]) => {
                  const isNext = prayer === nextPrayer;
                  return (
                    <div 
                      key={prayer}
                      className="text-center"
                    >
                      <p className={`text-sm tracking-widest mb-3
                        ${isNext ? accentColor.base : `${accentColor.base}/60`}`}>
                        {prayer}
                      </p>
                      <p className={`text-3xl font-light
                        ${isNext ? accentColor.light : 'text-white/80'}`}>
                        {time}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          />

          {/* Date Section */}
          <SimpleDateCard 
            dateTime={dateTime}
            onDateChange={onDateChange}
            render={({ gregorian, hijri }) => (
              <div className="flex gap-12 pt-8 mt-8 border-t border-white/10">
                <div>
                  <p className={`${accentColor.base}/60 text-xs tracking-widest mb-2`}>GREGORIAN</p>
                  <p className="text-white/80 text-xl font-light">{gregorian}</p>
                </div>
                <div>
                  <p className={`${accentColor.base}/60 text-xs tracking-widest mb-2`}>HIJRI</p>
                  <p className="text-white/80 text-xl font-light">{hijri}</p>
                </div>
              </div>
            )}
          />
        </div>

        {/* Right Portrait Gallery Section */}
        <div className="col-span-4 relative">
          {/* Portrait Container with proper aspect ratio */}
          <div className="absolute inset-0 rounded-2xl overflow-hidden bg-emerald-900/20">
            <div className="relative h-full w-full">
              <PortraitSlideshow
                images={settings.simpleDashboard?.backgroundImages || []}
                duration={settings.simpleDashboard?.slideshowDuration || 60}
                className="rounded-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-950/80 via-transparent to-transparent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortraitGalleryTemplate; 