import React, { createContext, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import { useSettings } from './SettingsContext';
import iosBridge from '../services/iosBridge';

const BridgeContext = createContext();

export function useBridge() {
  return useContext(BridgeContext);
}

export function BridgeProvider({ children }) {
  const { settings, isInitialized: isSettingsInitialized } = useSettings();

  useEffect(() => {
    let unsubscribe;
    let initializationAttempts = 0;
    const maxAttempts = 5;
    const retryDelay = 2000; // 2 seconds

    const initializeBridge = async () => {
      try {
        console.log('[DEBUG] Attempting bridge initialization...');
        await iosBridge.initializeBridge(settings);
        console.log('[DEBUG] Bridge initialization successful');
      } catch (error) {
        console.error('[ERROR] Bridge initialization failed:', error);
        
        // Retry logic
        if (initializationAttempts < maxAttempts) {
          initializationAttempts++;
          console.log(`[DEBUG] Retrying bridge initialization in ${retryDelay/1000} seconds (attempt ${initializationAttempts}/${maxAttempts})`);
          setTimeout(() => initializeBridge(), retryDelay);
        } else {
          console.error('[ERROR] Failed to initialize bridge after', maxAttempts, 'attempts');
        }
      }
    };

    // Only initialize when both auth and settings are ready
    unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && isSettingsInitialized) {
        console.log('[DEBUG] Auth and settings ready, proceeding with bridge initialization');
        iosBridge.updateSettings(settings);
        await initializeBridge();
      } else {
        console.log('[DEBUG] Waiting for auth and settings...', {
          hasUser: !!user,
          isSettingsInitialized
        });
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [isSettingsInitialized, settings]);

  return (
    <BridgeContext.Provider value={iosBridge}>
      {children}
    </BridgeContext.Provider>
  );
} 