import React, { useState, useEffect, useRef } from 'react';
import { MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';
import PortraitSlideshow from '../shared/PortraitSlideshow';

const AvantGardeTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);
  const [isDayTime, setIsDayTime] = useState(true);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Check if it's before or after Maghrib
  useEffect(() => {
    if (prayerTimes?.times && prayerTimes.times.Maghrib) {
      const now = new Date();
      const maghribTime = new Date(`${now.toDateString()} ${prayerTimes.times.Maghrib}`);
      setIsDayTime(now < maghribTime);
    }
  }, [prayerTimes]);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className={`w-full h-screen overflow-hidden flex ${isDayTime ? 'bg-gray-50' : 'bg-black'}`}>
      {/* Left Content Section */}
      <div className="flex-1 flex flex-col p-8">
        {/* Top Bar */}
        <div className="flex justify-between items-start">
          <div className="flex items-center gap-3">
            <MapPin className={`w-5 h-5 ${accentColor.light}`} />
            <span className={`tracking-wide ${isDayTime ? 'text-gray-600' : 'text-white/70'}`}>
              {settings.location?.split(',')[0].toUpperCase()}
            </span>
          </div>
          <div className="text-right" dir="rtl">
            <p className={isDayTime ? 'text-gray-600 text-lg' : 'text-white/60 text-lg'}>
              {duas?.[currentDuaIndex]?.arabic}
            </p>
          </div>
        </div>

        {/* Main Time Display - Dramatic Scaling */}
        <div className="flex-1 flex flex-col justify-center">
          <p className={`${accentColor.base}/70 text-sm tracking-[0.2em] mb-4`}>CURRENT TIME</p>
          <SimpleTimeCard 
            render={({ time }) => {
              const [hours, minutes] = time.split(':');
              return (
                <div className="flex items-start">
                  <span className={`text-[240px] font-extralight leading-none tracking-tighter ${
                    isDayTime ? 'text-gray-800' : 'text-white'
                  }`}>
                    {hours}
                  </span>
                  <div className="mt-8 -ml-4">
                    <span className={`text-[160px] font-thin leading-none ${
                      isDayTime ? 'text-gray-700' : 'text-white/80'
                    }`}>
                      {minutes}
                    </span>
                  </div>
                </div>
              );
            }}
          />
        </div>

        {/* Prayer Times */}
        <SimplePrayerTimesCard
          prayerTimes={prayerTimes}
          settings={settings}
          render={({ prayers }) => (
            <div className="flex gap-16 mb-16">
              {prayers.map(([prayer, time]) => {
                const isNext = prayer === nextPrayer;
                return (
                  <div 
                    key={prayer}
                    className={`group relative ${isNext ? 'translate-y-4' : ''} 
                              transition-all duration-500`}
                  >
                    <div className="relative">
                      <span className={`block text-sm tracking-[0.2em] mb-2
                        ${isNext ? accentColor.light : `${accentColor.base}/60`}`}>
                        {prayer.toUpperCase()}
                      </span>
                      <span className={`block text-3xl font-light
                        ${isNext 
                          ? accentColor.light 
                          : isDayTime ? 'text-gray-700' : 'text-white/70'
                        }`}>
                        {time}
                      </span>
                      {isNext && (
                        <div className={`absolute -left-4 top-1/2 w-2 h-2 ${accentColor.light} rounded-full`} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />

        {/* Bottom Bar */}
        <div className="flex justify-between items-end">
          {/* Countdown */}
          <SimplePrayerCountdownCard
            prayerTimes={prayerTimes}
            render={({ countdown, nextPrayer }) => (
              <div>
                <p className={`${accentColor.base}/70 text-sm tracking-[0.2em] mb-2`}>
                  UNTIL {nextPrayer}
                </p>
                <p className={`text-4xl font-light ${accentColor.light}`}>
                  {countdown}
                </p>
              </div>
            )}
          />

          {/* Dates */}
          <SimpleDateCard 
            dateTime={dateTime}
            onDateChange={onDateChange}
            render={({ gregorian, hijri }) => (
              <div className="flex gap-12">
                <div className="text-right">
                  <p className={`${accentColor.base}/50 text-xs tracking-[0.2em] mb-1`}>GREGORIAN</p>
                  <p className={isDayTime ? 'text-gray-700 text-lg' : 'text-white/70 text-lg'}>
                    {gregorian}
                  </p>
                </div>
                <div className="text-right">
                  <p className={`${accentColor.base}/50 text-xs tracking-[0.2em] mb-1`}>HIJRI</p>
                  <p className={isDayTime ? 'text-gray-700 text-lg' : 'text-white/70 text-lg'}>
                    {hijri}
                  </p>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {/* Right Portrait Section */}
      <div className="w-[600px]">
        <div className="h-full">
          <PortraitSlideshow
            images={settings.simpleDashboard?.backgroundImages || []}
            duration={settings.simpleDashboard?.slideshowDuration || 60}
          />
        </div>
      </div>
    </div>
  );
};

export default AvantGardeTemplate; 