import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

const AppPrivacyPolicy = () => {
  return (
    <div className="min-h-screen relative bg-[#0A0A0A] text-white">
      {/* Fixed Background Elements */}
      <div className="fixed inset-0 z-0">
        {/* Background Image Layer */}
        <img
          src="/images/headerbg.png"
          alt=""
          className="absolute top-0 left-1/2 -translate-x-1/2 w-[1600px] max-w-none h-full object-cover opacity-30 object-top"
        />
        {/* Primary Gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-[#111111]/95 to-[#111111]" />
        {/* Accent Gradient */}
        <div className="absolute inset-0 bg-gradient-to-t from-transparent via-emerald-500/[0.03] to-transparent" />
        
        {/* Gradient Orbs */}
        <div className="fixed top-1/4 left-1/4 w-[600px] h-[600px] rounded-full blur-3xl -translate-x-1/2 bg-emerald-500/[0.07]" />
        <div className="fixed bottom-1/4 right-1/4 w-[600px] h-[600px] rounded-full blur-3xl translate-x-1/2 bg-emerald-500/[0.07]" />
      </div>

      {/* Fixed Back Button - stays visible at the top */}
      <div className="fixed top-0 left-0 z-50 w-full" style={{ paddingTop: 'calc(env(safe-area-inset-top))', paddingLeft: '1rem', background: 'linear-gradient(to bottom, rgba(10,10,10,0.9) 0%, rgba(10,10,10,0.6) 60%, rgba(10,10,10,0) 100%)', paddingBottom: '1.5rem' }}>
        <div className="pt-4 pb-2">
          <Link
            to="/login"
            className="inline-flex items-center justify-center w-10 h-10 rounded-full transition-all duration-200 bg-white/10 hover:bg-white/20"
          >
            <ChevronLeft className="text-white" size={20} />
          </Link>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="relative z-10 min-h-screen overflow-y-auto" style={{ paddingTop: 'calc(env(safe-area-inset-top) + 4rem)', paddingBottom: 'calc(env(safe-area-inset-bottom) + 2rem)' }}>
        <div className="px-8">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
            
            <div className="space-y-6">
              <section>
                <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
                <p className="mb-3">This Privacy Policy explains how Ummahti ("we," "our," or "us") collects, uses, and protects your personal data in accordance with the General Data Protection Regulation (GDPR) and other applicable privacy laws.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">2. Data Controller</h2>
                <p className="mb-3">Ummahti is the data controller responsible for your personal data. For any privacy-related questions, please contact us at privacy@ummahti.com.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">3. Information We Collect</h2>
                <p className="mb-3">We collect and process the following types of information:</p>
                <ul className="list-disc pl-6 mb-3">
                  <li>Account information (email, name, profile picture)</li>
                  <li>Location data (for prayer times and qibla direction)</li>
                  <li>Device information (device ID, operating system)</li>
                  <li>Usage data (app interactions, preferences)</li>
                  <li>Prayer and religious preferences</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">4. Legal Basis for Processing</h2>
                <p className="mb-3">We process your data based on:</p>
                <ul className="list-disc pl-6 mb-3">
                  <li>Your consent</li>
                  <li>Performance of our contract with you</li>
                  <li>Our legitimate interests</li>
                  <li>Legal obligations</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">5. How We Use Your Information</h2>
                <p className="mb-3">We use your information to:</p>
                <ul className="list-disc pl-6 mb-3">
                  <li>Provide accurate prayer times and qibla direction</li>
                  <li>Personalize your app experience</li>
                  <li>Send prayer time notifications</li>
                  <li>Improve our services</li>
                  <li>Communicate with you about our services</li>
                  <li>Ensure app security and prevent fraud</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">6. Data Sharing and Third Parties</h2>
                <p className="mb-3">We share your data with:</p>
                <ul className="list-disc pl-6 mb-3">
                  <li>Service providers (hosting, analytics)</li>
                  <li>Authentication providers (Google)</li>
                  <li>Third-party integrations (when you enable them)</li>
                </ul>
                <p className="mb-3">We ensure all third parties respect the security of your data and treat it in accordance with the law.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">7. International Transfers</h2>
                <p className="mb-3">When we transfer data outside the EEA, we ensure appropriate safeguards are in place through standard contractual clauses or other legal mechanisms.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">8. Data Security</h2>
                <p className="mb-3">We implement appropriate technical and organizational measures to protect your data, including encryption and secure data storage.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">9. Your Rights</h2>
                <p className="mb-3">Under GDPR, you have the right to:</p>
                <ul className="list-disc pl-6 mb-3">
                  <li>Access your personal data</li>
                  <li>Rectify inaccurate data</li>
                  <li>Request erasure of your data</li>
                  <li>Restrict or object to processing</li>
                  <li>Data portability</li>
                  <li>Withdraw consent</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">10. Data Retention</h2>
                <p className="mb-3">We retain your data only for as long as necessary to provide our services or comply with legal obligations.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">11. Children's Privacy</h2>
                <p className="mb-3">Our services are not intended for children under 13. We do not knowingly collect data from children under 13.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">12. Cookies and Tracking</h2>
                <p className="mb-3">We use cookies and similar technologies to improve your experience. You can control these through your browser settings.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">13. Changes to This Policy</h2>
                <p className="mb-3">We may update this policy periodically. We will notify you of any significant changes.</p>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">14. Contact Us</h2>
                <p className="mb-3">For any privacy-related questions or to exercise your rights, please contact us at:</p>
                <p className="mb-3">
                  Email: privacy@ummahti.com<br />
                  Address: [Your Company Address]
                </p>
                <p className="mb-3">You have the right to lodge a complaint with your local data protection authority.</p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppPrivacyPolicy; 