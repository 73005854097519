export const sectionStyles = (isDarkMode) => ({
  container: `space-y-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`,
  header: `flex items-center gap-3`,
  headerIcon: isDarkMode ? "w-5 h-5 text-emerald-400" : "w-5 h-5 text-emerald-600",
  headerText: `text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`,
  subheaderText: `text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`,
  card: `p-6 rounded-[24px] border transition-all duration-200 ${
    isDarkMode 
      ? 'bg-[#111111] border-gray-800 shadow-lg shadow-black/5' 
      : 'bg-white border-gray-100 shadow-sm'
  }`,
  // ... other shared styles
}); 