const customTheme = {
  button: {
    color: {
      primary: 'text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800',
      secondary: 'text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700',
      success: 'focus:outline-none text-white bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:ring-emerald-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-emerald-600 dark:hover:bg-emerald-700 dark:focus:ring-emerald-800',
      danger: 'focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900',
    },
  },
  card: {
    base: 'block bg-white dark:bg-gray-800 rounded-lg shadow dark:border-gray-700',
  },
  navbar: {
    root: {
      base: "px-2 py-2.5 sm:px-4 sticky top-0 z-50 transition-all duration-200",
      bordered: "border",
      rounded: "rounded",
      inner: {
        base: "mx-auto flex flex-wrap items-center justify-between",
        fluid: "w-full",
      },
    },
    link: {
      base: 'block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-green-600 md:p-0 dark:text-white md:dark:hover:text-green-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent',
      active: 'text-white bg-green-700 rounded md:bg-transparent md:text-green-700 md:p-0 dark:text-white md:dark:text-green-500',
    },
  },
  dropdown: {
    floating: {
      base: 'z-10 w-fit rounded-lg divide-y divide-gray-100 shadow',
      content: 'py-2 text-sm text-gray-700 dark:text-gray-200',
      target: 'w-fit justify-between',
    },
    item: {
      base: 'flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white',
    },
  },
  fontFamily: {
    sans: ['Roboto', 'sans-serif'],
  },
  footer: {
    root: {
      base: "w-full rounded-lg shadow md:flex md:items-center md:justify-between",
    },
    container: {
      base: "w-full p-6",
    },
    groupLink: {
      base: "flex flex-wrap text-sm text-gray-500 dark:text-gray-400",
    },
    link: {
      base: "last:mr-0 mr-4 hover:underline",
    },
    copyright: {
      base: "text-sm text-gray-500 dark:text-gray-400 sm:text-center",
    },
  },
  // Add more component styles as needed
};

export default customTheme;