import { useMemo } from 'react';
import { format, differenceInDays } from 'date-fns';
import { HIZB_RANGES, quranHelpers } from '../constants/quranConstants';

// Helper function to calculate progress for a single goal on a specific date
const calculateGoalProgress = (goal, date) => {
  if (!goal) {
    console.log('calculateGoalProgress: No goal provided');
    return null;
  }

  console.log('Calculating progress for goal:', {
    goalId: goal.id,
    type: goal.type,
    date: format(date, 'yyyy-MM-dd'),
    details: goal.details
  });

  const dateStr = format(date, 'yyyy-MM-dd');
  // Check both formats: old structure and new structure with 'details'
  const progressData = goal.progress?.[dateStr] || { amount: 0, completed: false, details: {} };
  
  // Extract values from either the root or the details object
  const completed = progressData.details?.completed !== undefined 
    ? progressData.details.completed 
    : progressData.completed || false;
    
  const amount = progressData.details?.amount !== undefined
    ? progressData.details.amount
    : progressData.amount || 0;
    
  const versesRead = progressData.details?.versesRead || [];

  if (goal.type === 'quran_reading') {
    // Calculate which hizb(s) to read for this day
    const dayNumber = differenceInDays(date, new Date(goal.createdAt));
    const startHizbIndex = dayNumber * goal.details.dailyHizb;
    
    console.log('Quran reading calculation:', {
      dayNumber,
      startHizbIndex,
      dailyHizb: goal.details.dailyHizb,
      createdAt: goal.createdAt
    });

    // Get hizbs to read
    const hizbsToRead = [];
    for (let i = 0; i < goal.details.dailyHizb; i++) {
      const hizbIndex = startHizbIndex + i;
      if (hizbIndex < HIZB_RANGES.length) {
        hizbsToRead.push(HIZB_RANGES[hizbIndex]);
      }
    }

    console.log('Hizbs to read:', hizbsToRead);

    if (hizbsToRead.length > 0) {
      const firstHizb = hizbsToRead[0];
      const lastHizb = hizbsToRead[hizbsToRead.length - 1];
      
      // Calculate total verses using helper function
      const totalVerses = quranHelpers.getVerseCount(
        firstHizb.range.start.surah,
        firstHizb.range.start.verse,
        lastHizb.range.end.surah,
        lastHizb.range.end.verse
      );

      // Format the range for display using helper function
      const displayRange = quranHelpers.formatVerseRange(
        firstHizb.range.start,
        lastHizb.range.end
      );

      // Check if progress exists with versesRead for completion calculation
      const hasExplicitCompletion = completed !== undefined;
      const hasVersesRead = versesRead && versesRead.length > 0;
      const percentComplete = hasExplicitCompletion && completed
        ? 100 // If explicitly completed, show 100%
        : hasVersesRead
          ? Math.round((versesRead.length / totalVerses) * 100) // Calculate based on verses
          : totalVerses > 0 
            ? Math.round((amount || 0) / totalVerses * 100) // Fall back to amount 
            : 0;

      const result = {
        dailyTarget: `${goal.details.dailyHizb} hizb`,
        currentAmount: amount,
        totalAmount: totalVerses,
        percentComplete: percentComplete,
        isCompleted: completed,
        hizbsToRead,
        displayRange,
        hizbRange: firstHizb.fullRange + (hizbsToRead.length > 1 ? ` - ${lastHizb.fullRange}` : '')
      };

      console.log('Calculated progress result:', result);
      return result;
    }

    console.log('No hizbs to read for this day');
    return {
      dailyTarget: `${goal.details.dailyHizb} hizb`,
      currentAmount: 0,
      totalAmount: 0,
      percentComplete: 0,
      isCompleted: false,
      hizbsToRead: [],
      displayRange: null,
      hizbRange: null
    };
  }

  if (goal.type === 'memorization') {
    const targetAmount = goal.details.dailyVerses;
    return {
      dailyTarget: `${targetAmount} verses`,
      currentAmount: amount,
      totalAmount: targetAmount,
      percentComplete: Math.round((amount || 0) / targetAmount * 100),
      isCompleted: completed
    };
  }

  // For other goal types
  return {
    dailyTarget: '',
    currentAmount: amount,
    totalAmount: 1,
    percentComplete: completed ? 100 : 0,
    isCompleted: completed,
  };
};

// Hook for single goal progress
export const useGoalProgress = (goal, date = new Date()) => {
  return useMemo(() => calculateGoalProgress(goal, date), [goal, date]);
};

// Hook for multiple goals progress
export const useMultiGoalProgress = (goals, dates = [new Date()]) => {
  return useMemo(() => {
    const progressMap = new Map();
    
    dates.forEach(date => {
      const dateStr = format(date, 'yyyy-MM-dd');
      const dayProgress = new Map();
      
      if (goals) {
        goals.forEach(goal => {
          const progress = calculateGoalProgress(goal, date);
          dayProgress.set(goal.id, progress);
        });
      }
      
      progressMap.set(dateStr, dayProgress);
    });
    
    return progressMap;
  }, [goals, dates]);
}; 