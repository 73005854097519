import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { useDarkMode } from '../../../contexts/DarkModeContext';
import { useSettings } from '../../../contexts/SettingsContext';
import { MenuSection } from './MenuSection';
import { SelectionView } from './SelectionView';
import { MobilePageTransition } from './MobilePageTransition';
import { fetchReciters, fetchTranslations, fetchLanguages } from '../../../services/quranService';

const fontSizes = [
  { label: 'XXS', value: 'text-sm' },
  { label: 'XS', value: 'text-base' },
  { label: 'S', value: 'text-lg' },
  { label: 'M', value: 'text-xl' },
  { label: 'L', value: 'text-2xl' },
  { label: 'XL', value: 'text-3xl' },
  { label: 'XXL', value: 'text-4xl' },
  { label: '3XL', value: 'text-5xl' },
  { label: '4XL', value: 'text-6xl' },
];

const QuranSettings = ({ onClose, hideHeader = false }) => {
  const { isDarkMode } = useDarkMode();
  const { settings, updateSettings } = useSettings();

  // Use values from settings.quran
  const selectedReciter = settings.quran.selectedReciter;
  const selectedLanguage = settings.quran.selectedLanguage;
  const selectedTranslation = settings.quran.selectedTranslation;

  // State for UI
  const [showReciterSheet, setShowReciterSheet] = useState(false);
  const [showLanguageSheet, setShowLanguageSheet] = useState(false);
  const [showTranslationSheet, setShowTranslationSheet] = useState(false);
  const [showModeSheet, setShowModeSheet] = useState(false);
  const [showFontSizeSheet, setShowFontSizeSheet] = useState(false);

  // Add state for options
  const [reciterOptions, setReciterOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [translationOptions, setTranslationOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Add state for languages data
  const [languages, setLanguages] = useState([]);

  // Fetch options on mount
  useEffect(() => {
    const loadOptions = async () => {
      try {
        setLoading(true);
        const [recitersData, translationsData, languagesData] = await Promise.all([
          fetchReciters(),
          fetchTranslations(),
          fetchLanguages()
        ]);

        // Store full languages data
        setLanguages(languagesData.languages);

        // Transform data into options format
        setReciterOptions(
          recitersData.recitations.map(reciter => ({
            value: reciter.id.toString(),
            label: reciter.reciter_name
          }))
        );

        setLanguageOptions(
          languagesData.languages.map(language => ({
            value: language.iso_code,
            label: language.native_name || language.name
          }))
        );

        // Filter translations using language name
        const selectedLangName = languagesData.languages.find(
          lang => lang.iso_code === settings.quran.selectedLanguage
        )?.name;

        if (selectedLangName) {
          setTranslationOptions(
            translationsData.translations
              .filter(translation => 
                translation.language_name?.toLowerCase() === selectedLangName?.toLowerCase()
              )
              .map(translation => ({
                value: translation.id.toString(),
                label: translation.name
              }))
          );
        }
      } catch (error) {
        console.error('Error loading options:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOptions();
  }, [settings.quran.selectedLanguage]); // Reload when language changes

  // Update translation options when language changes
  useEffect(() => {
    if (settings.quran.selectedLanguage) {
      const loadTranslations = async () => {
        try {
          const data = await fetchTranslations();
          const selectedLang = languageOptions.find(
            lang => lang.value === settings.quran.selectedLanguage
          );

          if (!selectedLang) {
            console.warn('Selected language not found in options');
            return;
          }

          // Get the full language name from languages data
          const selectedLangName = languages.find(
            lang => lang.iso_code === selectedLang.value
          )?.name;

          const langTranslations = data.translations.filter(translation => 
            translation.language_name?.toLowerCase() === selectedLangName?.toLowerCase()
          );

          console.log('Translations loaded:', {
            selectedLang,
            selectedLangName,
            availableTranslations: langTranslations
          });

          if (langTranslations.length === 0) {
            console.warn(`No translations available for language: ${selectedLang.label}`);
            setTranslationOptions([]);
          } else {
            setTranslationOptions(
              langTranslations.map(translation => ({
                value: translation.id.toString(),
                label: translation.name
              }))
            );

            // If current translation is not available in this language, select the first one
            const isCurrentTranslationValid = langTranslations.some(
              t => t.id.toString() === settings.quran.selectedTranslation
            );

            if (!isCurrentTranslationValid) {
              updateSettings({
                ...settings,
                quran: {
                  ...settings.quran,
                  selectedTranslation: langTranslations[0].id.toString()
                }
              });
            }
          }
        } catch (error) {
          console.error('Error loading translations:', error);
        }
      };

      loadTranslations();
    }
  }, [settings.quran.selectedLanguage, languageOptions]);

  // Add debug logs for props
  console.log('QuranSettings - Props:', {
    reciterOptions: settings.quran.reciterOptions,
    languageOptions: settings.quran.languageOptions,
    translationOptions: settings.quran.translationOptions,
    selectedReciter: settings.quran.selectedReciter,
    selectedLanguage: settings.quran.selectedLanguage,
    selectedTranslation: settings.quran.selectedTranslation
  });

  console.log('QuranSettings - Settings from context:', settings);

  const modeOptions = [
    { value: 'reading', label: 'Reading' },
    { value: 'memorization', label: 'Memorization' }
  ];

  const handleQuranModeChange = (mode) => {
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        mode
      }
    });
  };

  const handleDisplayModeChange = (mode) => {
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        displayMode: mode
      }
    });
  };

  const handleFontSizeChange = (size) => {
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        fontSize: size
      }
    });
  };

  const handleTransliterationToggle = (show) => {
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        showTransliteration: show
      }
    });
  };

  // Handle settings updates
  const handleReciterChange = (option) => {
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        selectedReciter: option.value
      }
    });
    setShowReciterSheet(false);
  };

  const handleLanguageChange = async (option) => {
    try {
      const data = await fetchTranslations();
      const selectedLang = languages.find(lang => lang.iso_code === option.value);
      
      if (!selectedLang) {
        console.warn('Selected language not found:', option.value);
        return;
      }

      const langTranslations = data.translations.filter(translation => 
        translation.language_name?.toLowerCase() === selectedLang.name?.toLowerCase()
      );

      console.log('Language change:', {
        selectedLang,
        availableTranslations: langTranslations
      });

      if (langTranslations.length === 0) {
        console.warn(`No translations available for language: ${selectedLang.name}`);
        updateSettings({
          ...settings,
          quran: {
            ...settings.quran,
            selectedLanguage: option.value,
          }
        });
      } else {
        const defaultTranslation = langTranslations[0];
        updateSettings({
          ...settings,
          quran: {
            ...settings.quran,
            selectedLanguage: option.value,
            selectedTranslation: defaultTranslation.id.toString()
          }
        });

        setTranslationOptions(
          langTranslations.map(translation => ({
            value: translation.id.toString(),
            label: translation.name
          }))
        );
      }

      setShowLanguageSheet(false);
    } catch (error) {
      console.error('Error handling language change:', error);
    }
  };

  const handleTranslationChange = (option) => {
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        selectedTranslation: option.value
      }
    });
    setShowTranslationSheet(false);
  };

  // Use local options instead of settings.quran.options
  const selectedReciterLabel = reciterOptions.find(r => r.value === settings.quran.selectedReciter)?.label;
  const selectedLanguageLabel = languageOptions.find(l => l.value === settings.quran.selectedLanguage)?.label;
  const selectedTranslationLabel = translationOptions.find(t => t.value === settings.quran.selectedTranslation)?.label;

  console.log('QuranSettings - Selected Labels:', {
    selectedReciterLabel,
    selectedLanguageLabel,
    selectedTranslationLabel
  });

  const mainContent = (
    <div className="space-y-6">
      {/* Mode Selection Section */}
      <MenuSection>
        <div className={`flex items-center justify-between px-4 py-3 ${
          isDarkMode ? 'border-gray-800' : 'border-gray-200'
        }`}>
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Quran Mode
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.quran.mode === 'memorization'}
              onChange={() => handleQuranModeChange(
                settings.quran.mode === 'memorization' ? 'reading' : 'memorization'
              )}
              className="sr-only peer"
            />
            <div className={`w-11 h-6 rounded-full peer ${
              isDarkMode 
                ? 'bg-gray-700 peer-checked:bg-emerald-500' 
                : 'bg-gray-200 peer-checked:bg-emerald-600'
            } peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`} />
          </label>
        </div>
        <div className="px-4 py-1">
          <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {settings.quran.mode === 'memorization' ? 'Memorization Mode' : 'Reading Mode'}
          </span>
        </div>
      </MenuSection>

      {/* Reciter, Language, Translation Section */}
      <MenuSection>
        <button
          onClick={() => setShowReciterSheet(true)}
          className={`w-full flex items-center justify-between px-4 py-3 border-b ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Reciter
          </span>
          <div className="flex items-center gap-2">
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
              {selectedReciterLabel || 'Select Reciter'}
            </span>
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          </div>
        </button>

        <button
          onClick={() => setShowLanguageSheet(true)}
          className={`w-full flex items-center justify-between px-4 py-3 border-b ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Language
          </span>
          <div className="flex items-center gap-2">
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
              {selectedLanguageLabel || 'Select Language'}
            </span>
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          </div>
        </button>

        <button
          onClick={() => setShowTranslationSheet(true)}
          className={`w-full flex items-center justify-between px-4 py-3 ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Translation
          </span>
          <div className="flex items-center gap-2">
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
              {selectedTranslationLabel || 'Select Translation'}
            </span>
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          </div>
        </button>
      </MenuSection>

      {/* Display Options Section */}
      <MenuSection>
        <div className={`flex items-center justify-between px-4 py-3 ${
          isDarkMode ? 'border-gray-800' : 'border-gray-200'
        }`}>
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Arabic Only
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.quran.displayMode === 'arabic-only'}
              onChange={() => handleDisplayModeChange(
                settings.quran.displayMode === 'arabic-only' ? 'with-translation' : 'arabic-only'
              )}
              className="sr-only peer"
            />
            <div className={`w-11 h-6 rounded-full peer ${
              isDarkMode 
                ? 'bg-gray-700 peer-checked:bg-emerald-500' 
                : 'bg-gray-200 peer-checked:bg-emerald-600'
            } peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`} />
          </label>
        </div>

        <div className={`flex items-center justify-between px-4 py-3`}>
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Show Transliteration
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.quran.showTransliteration}
              onChange={(e) => handleTransliterationToggle(e.target.checked)}
              className="sr-only peer"
            />
            <div className={`w-11 h-6 rounded-full peer ${
              isDarkMode 
                ? 'bg-gray-700 peer-checked:bg-emerald-500' 
                : 'bg-gray-200 peer-checked:bg-emerald-600'
            } peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all`} />
          </label>
        </div>
      </MenuSection>

      {/* Font Size Section */}
      <MenuSection>
        <button
          onClick={() => setShowFontSizeSheet(true)}
          className={`w-full flex items-center justify-between px-4 py-3 ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
            Font Size
          </span>
          <div className="flex items-center gap-2">
            <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
              {fontSizes.find(size => size.value === settings.quran.fontSize)?.label || 'Medium'}
            </span>
            <ChevronRight className={`w-5 h-5 ${
              isDarkMode ? 'text-gray-600' : 'text-gray-400'
            }`} />
          </div>
        </button>
      </MenuSection>

      {/* Progress Management Section */}
      <MenuSection>
        <button
          onClick={() => updateSettings({
            ...settings,
            quran: {
              ...settings.quran,
              memorizationProgress: 0
            }
          })}
          className={`w-full flex items-center justify-between px-4 py-3 border-b ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className="text-red-600 dark:text-red-500">
            Reset Memorization Progress
          </span>
        </button>

        <button
          onClick={() => updateSettings({
            ...settings,
            quran: {
              ...settings.quran,
              readingProgress: {
                currentSession: {
                  surah: null,
                  verse: null,
                  timestamp: null
                },
                history: {}
              }
            }
          })}
          className={`w-full flex items-center justify-between px-4 py-3 ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <span className="text-red-600 dark:text-red-500">
            Reset Reading Progress
          </span>
        </button>
      </MenuSection>
    </div>
  );

  if (loading) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  return (
    <>
      <MobilePageTransition
        show={true}
        onClose={onClose}
        title="Quran Settings"
        isDarkMode={isDarkMode}
        hideHeader={hideHeader}
      >
        {mainContent}
      </MobilePageTransition>

      {/* Selection sheets */}
      <AnimatePresence>
        {showReciterSheet && (
          <SelectionView
            show={true}
            onClose={() => setShowReciterSheet(false)}
            title="Select Reciter"
            options={reciterOptions}
            selectedValue={{ value: settings.quran.selectedReciter }}
            onSelect={handleReciterChange}
            isDarkMode={isDarkMode}
          />
        )}

        {showLanguageSheet && (
          <SelectionView
            show={true}
            onClose={() => setShowLanguageSheet(false)}
            title="Select Language"
            options={languageOptions}
            selectedValue={{ value: settings.quran.selectedLanguage }}
            onSelect={handleLanguageChange}
            isDarkMode={isDarkMode}
          />
        )}

        {showTranslationSheet && (
          <SelectionView
            show={true}
            onClose={() => setShowTranslationSheet(false)}
            title="Select Translation"
            options={translationOptions}
            selectedValue={{ value: settings.quran.selectedTranslation }}
            onSelect={handleTranslationChange}
            isDarkMode={isDarkMode}
          />
        )}

        {showFontSizeSheet && (
          <SelectionView
            show={true}
            onClose={() => setShowFontSizeSheet(false)}
            title="Font Size"
            options={fontSizes.map(size => ({
              value: size.value,
              label: size.label
            }))}
            selectedValue={{ value: settings.quran.fontSize }}
            onSelect={(option) => {
              handleFontSizeChange(option.value);
            }}
            isDarkMode={isDarkMode}
            className="overflow-y-auto max-h-[80vh]"
            preview={
              <div className={`mb-6 p-6 rounded-xl ${
                isDarkMode ? 'bg-[#111111]' : 'bg-white'
              } border ${
                isDarkMode ? 'border-gray-800' : 'border-gray-200'
              }`}>
                <p className={`text-right font-uthmani mb-4 ${settings.quran.fontSize} ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`} dir="rtl">
                  بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ
                </p>
                <p className={`${
                  isDarkMode ? 'text-gray-400' : 'text-gray-600'
                } ${settings.quran.fontSize === 'text-sm' ? 'text-xs' :
                   settings.quran.fontSize === 'text-base' ? 'text-sm' :
                   settings.quran.fontSize === 'text-lg' ? 'text-base' :
                   settings.quran.fontSize === 'text-xl' ? 'text-lg' :
                   settings.quran.fontSize === 'text-2xl' ? 'text-xl' :
                   settings.quran.fontSize === 'text-3xl' ? 'text-2xl' :
                   settings.quran.fontSize === 'text-4xl' ? 'text-3xl' :
                   settings.quran.fontSize === 'text-5xl' ? 'text-4xl' :
                   settings.quran.fontSize === 'text-6xl' ? 'text-5xl' : 'text-base'
                }`}>
                  In the name of Allah, the Entirely Merciful, the Especially Merciful
                </p>
              </div>
            }
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default QuranSettings; 