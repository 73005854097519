import { useState, useEffect, useCallback } from 'react';
import { useSettings } from '../../../contexts/SettingsContext';

const useQuranFootnotes = (verses) => {
  const { settings } = useSettings();
  const [footnotes, setFootnotes] = useState({});
  const [activeFootnote, setActiveFootnote] = useState(null);

  // Extract footnotes from verses
  useEffect(() => {
    if (!verses || !settings.quran?.selectedTranslation) return;

    try {
      const extractedFootnotes = {};
      verses.forEach(verse => {
        if (!verse?.translations) return;

        const translation = verse.translations.find(
          t => t.resource_id.toString() === settings.quran.selectedTranslation
        );
        
        if (translation?.text) {
          const footnoteMatches = translation.text.matchAll(/<sup foot_note=(\d+)>(\d+)<\/sup>/g);
          for (const match of footnoteMatches) {
            const footnoteId = match[1];
            if (verse.footnotes?.[footnoteId]) {
              extractedFootnotes[footnoteId] = verse.footnotes[footnoteId];
            }
          }
        }
      });

      setFootnotes(extractedFootnotes);
    } catch (error) {
      console.error("Error extracting footnotes:", error);
      setFootnotes({});
    }
  }, [verses, settings.quran?.selectedTranslation]);

  // Handle footnote activation
  const handleFootnoteActivation = useCallback((footnoteId, isActive) => {
    setActiveFootnote(isActive ? footnoteId : null);
  }, []);

  const formatFootnotes = useCallback((text) => {
    if (!text) return '';
    
    try {
      return text.split(/(<sup foot_note=\d+>\d+<\/sup>)/).map((part, index) => {
        const footnoteMatch = part.match(/<sup foot_note=(\d+)>(\d+)<\/sup>/);
        if (footnoteMatch) {
          const footnoteId = footnoteMatch[1];
          const footnoteNumber = footnoteMatch[2];
          
          if (!footnotes[footnoteId]) {
            return footnoteNumber; // Return just the number if footnote content is missing
          }
          
          return (
            <span key={index} className="relative inline-block">
              <sup 
                className="text-xs text-green-600 dark:text-green-400 ml-0.5 cursor-pointer hover:text-green-800 dark:hover:text-green-300"
                onMouseEnter={() => handleFootnoteActivation(footnoteId, true)}
                onMouseLeave={() => handleFootnoteActivation(footnoteId, false)}
                onClick={() => handleFootnoteActivation(footnoteId, !activeFootnote)}
              >
                {footnoteNumber}
              </sup>
              {activeFootnote === footnoteId && (
                <div 
                  className="fixed md:absolute bottom-0 md:bottom-full left-0 md:left-1/2 transform md:-translate-x-1/2 w-full md:w-64 p-2 bg-white dark:bg-gray-700 text-sm text-gray-900 dark:text-gray-100 rounded shadow-lg border border-gray-200 dark:border-gray-600 z-[100] md:mb-2"
                  onMouseEnter={() => handleFootnoteActivation(footnoteId, true)}
                  onMouseLeave={() => handleFootnoteActivation(footnoteId, false)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="relative">
                    <div className="p-2">{footnotes[footnoteId]}</div>
                    <div className="hidden md:block absolute -bottom-2 left-1/2 transform -translate-x-1/2 rotate-45 w-3 h-3 bg-white dark:bg-gray-700 border-r border-b border-gray-200 dark:border-gray-600"></div>
                  </div>
                </div>
              )}
            </span>
          );
        }
        return part;
      });
    } catch (error) {
      console.error("Error formatting footnotes:", error);
      return text; // Return original text if formatting fails
    }
  }, [footnotes, activeFootnote, handleFootnoteActivation]);

  // Close footnote on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeFootnote && !event.target.closest('.footnote-tooltip')) {
        setActiveFootnote(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [activeFootnote]);

  return {
    footnotes,
    formatFootnotes,
    activeFootnote,
    setActiveFootnote: handleFootnoteActivation
  };
};

export default useQuranFootnotes; 