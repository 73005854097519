import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Navbar, Footer } from 'flowbite-react';
import { Sun, Moon, Menu, X } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';
import { useAuth } from '../contexts/AuthContext';
import AppLanding from './app/AppLanding';
import AppLogin from './app/AppLogin';
import AppSignUp from './app/AppSignUp';
import AppTermsOfService from './app/AppTermsOfService';
import AppPrivacyPolicy from './app/AppPrivacyPolicy';

const PublicLayout = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Check if we're in the native app environment
  const isNative = typeof window !== 'undefined' && window.isUmmahtiNative;
  const currentPath = location.pathname;

  // Mobile menu component
  const MobileMenu = () => (
    <>
      {/* Backdrop */}
      <div 
        className={`fixed inset-0 bg-black/20 dark:bg-black/40 z-50 transition-opacity duration-300 ${
          isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMenuOpen(false)}
      />
      
      {/* Menu content */}
      <div 
        className={`fixed right-0 top-0 bottom-0 w-[320px] z-50 transform transition-transform duration-300 ease-out ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } ${isDarkMode ? 'bg-[#111111]' : 'bg-white'}`}
      >
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex items-center justify-between p-6 border-b border-gray-800/10 dark:border-white/10" 
            style={{ paddingTop: 'calc(env(safe-area-inset-top) + 1.5rem)' }}
          >
            <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Menu
            </h2>
            <button
              onClick={() => setIsMenuOpen(false)}
              className={`p-2 rounded-full transition-colors ${
                isDarkMode 
                  ? 'hover:bg-white/10' 
                  : 'hover:bg-gray-100'
              }`}
            >
              <X className={isDarkMode ? 'text-white' : 'text-gray-900'} />
            </button>
          </div>

          {/* Content */}
          <div className="flex-1 overflow-y-auto">
            <div className="p-6 space-y-6">
              {/* Theme Toggle */}
              <div className={`p-4 rounded-xl ${
                isDarkMode ? 'bg-white/5' : 'bg-gray-50'
              }`}>
                <div className="flex items-center justify-between">
                  <span className={`font-medium ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {isDarkMode ? 'Dark Mode' : 'Light Mode'}
                  </span>
                  <button
                    onClick={toggleDarkMode}
                    className={`p-2 rounded-lg transition-colors ${
                      isDarkMode 
                        ? 'bg-white/10 hover:bg-white/20' 
                        : 'bg-white hover:bg-gray-100 shadow-sm'
                    }`}
                  >
                    {isDarkMode ? (
                      <Sun className="w-5 h-5 text-emerald-400" />
                    ) : (
                      <Moon className="w-5 h-5 text-gray-600" />
                    )}
                  </button>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="space-y-2">
                <Link 
                  to="/login"
                  onClick={() => setIsMenuOpen(false)}
                  className={`block w-full px-4 py-3 rounded-lg transition-colors ${
                    isDarkMode 
                      ? 'text-white hover:bg-white/10' 
                      : 'text-gray-900 hover:bg-gray-100'
                  }`}
                >
                  Login
                </Link>
                <Link 
                  to="/signup"
                  onClick={() => setIsMenuOpen(false)}
                  className={`block w-full px-4 py-3 rounded-lg transition-colors ${
                    isDarkMode 
                      ? 'text-white hover:bg-white/10' 
                      : 'text-gray-900 hover:bg-gray-100'
                  }`}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // For native app, show the appropriate screen
  if (isNative) {
    if (currentPath === '/') {
      return <AppLanding />;
    } else if (currentPath === '/login') {
      return <AppLogin />;
    } else if (currentPath === '/signup') {
      return <AppSignUp />;
    } else if (currentPath === '/app/terms') {
      return <AppTermsOfService />;
    } else if (currentPath === '/app/privacy') {
      return <AppPrivacyPolicy />;
    }
  }

  // For web, show the regular layout
  return (
    <div className={`flex flex-col min-h-screen transition-colors duration-200 ${
      isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'
    }`}>
      <Navbar 
        fluid 
        className={`fixed w-full z-40 transition-all duration-300 ${
          scrolled 
            ? 'bg-white/80 dark:bg-[#0A0A0A]/80 backdrop-blur-lg border-b border-gray-200 dark:border-gray-800' 
            : 'bg-transparent border-transparent'
        }`}
        style={{ paddingTop: 'env(safe-area-inset-top)' }}
      >
        <div className="max-w-7xl mx-auto px-8 w-full">
          <div className="w-full flex justify-between items-center py-3">
            {/* Logo - visible on all screens */}
            <Navbar.Brand 
              as={Link} 
              to="/" 
              className={`flex items-center gap-2 px-4 py-2.5 rounded-full transition-all duration-300 ${
                isDarkMode 
                  ? 'bg-[#111111] hover:bg-[#1A1A1A] shadow-sm' 
                  : 'bg-white hover:bg-gray-50 shadow-sm'
              }`}
            >
              <img
                src={isDarkMode ? "/logo_dark_mode.svg" : "/logo.svg"}
                className="h-8 transition-opacity duration-300"
                alt="Ummahti Logo"
              />
            </Navbar.Brand>

            {/* Desktop Navigation */}
            <div className="hidden sm:flex items-center gap-4">
              <div className={`flex items-center gap-3 px-4 py-2 rounded-full transition-all duration-300 relative ${
                isDarkMode 
                  ? 'bg-[#111111] hover:bg-[#1A1A1A] shadow-sm' 
                  : 'bg-white hover:bg-gray-50 shadow-sm'
              }`}>
                <div 
                  className={`absolute inset-2 transition-all duration-500 ease-in-out rounded-full ${
                    isDarkMode 
                      ? 'bg-emerald-500 shadow-lg shadow-emerald-500/20' 
                      : 'bg-gray-900'
                  }`}
                  style={{
                    left: location.pathname === '/login' ? '8px' : '50%',
                    right: location.pathname === '/signup' ? '8px' : '50%',
                    opacity: (location.pathname === '/login' || location.pathname === '/signup') ? 1 : 0,
                  }}
                />
                <Link 
                  to="/login" 
                  className={`px-5 py-2.5 rounded-full transition-all duration-300 relative z-10 ${
                    location.pathname === '/login' 
                      ? isDarkMode 
                        ? 'text-black font-medium' 
                        : 'text-white font-medium'
                      : isDarkMode 
                        ? 'text-gray-400 hover:text-white' 
                        : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  Login
                </Link>
                <Link 
                  to="/signup" 
                  className={`px-5 py-2.5 rounded-full transition-all duration-300 relative z-10 ${
                    location.pathname === '/signup' 
                      ? isDarkMode 
                        ? 'text-black font-medium' 
                        : 'text-white font-medium'
                      : isDarkMode 
                        ? 'text-gray-400 hover:text-white' 
                        : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  Sign Up
                </Link>
              </div>

              <button
                onClick={toggleDarkMode}
                className={`h-11 px-4 rounded-full transition-all duration-300 ${
                  isDarkMode 
                    ? 'bg-[#111111] hover:bg-[#1A1A1A] shadow-sm' 
                    : 'bg-white hover:bg-gray-50 shadow-sm'
                }`}
              >
                {isDarkMode ? (
                  <Sun className="w-5 h-5 text-emerald-400 hover:text-emerald-300 transition-colors" />
                ) : (
                  <Moon className="w-5 h-5 text-gray-600 hover:text-gray-900 transition-colors" />
                )}
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMenuOpen(true)}
              className={`sm:hidden p-3 rounded-full transition-colors ${
                isDarkMode 
                  ? 'bg-[#111111] hover:bg-[#1A1A1A]' 
                  : 'bg-white hover:bg-gray-50'
              }`}
            >
              <Menu className={`w-6 h-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`} />
            </button>
          </div>
        </div>
      </Navbar>

      {/* Mobile menu overlay - only visible on mobile */}
      <MobileMenu />

      <main className={`flex-1`}>
        <Outlet />
      </main>

      {/* Footer */}
      <Footer container={false} className={`mt-auto transition-all duration-200 !rounded-none border-t ${
        isDarkMode 
          ? 'bg-[#111111] border-gray-800' 
          : 'bg-white border-gray-200'
      }`}>
        <div className="w-full">
          <div className="container mx-auto px-8">
            {/* Logo and Description */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 py-12">
              <div className="space-y-6">
                <img
                  src={isDarkMode ? "/logo_dark_mode.svg" : "/logo.svg"}
                  className="h-8"
                  alt="Ummahti Logo"
                />
                <div className="space-y-4">
                  <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Empowering Muslims worldwide to strengthen their connection with Allah through innovative technology
                  </p>
                  <p className={`pt-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    © {new Date().getFullYear()} Ummahti. All rights reserved.
                  </p>
                </div>
              </div>

              {/* Quick Links */}
              <div>
                <h3 className={`text-sm font-semibold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Quick Links</h3>
                <ul className="space-y-3">
                  <li>
                    <a 
                      href="/about" 
                      className={`text-sm transition-colors ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-white' 
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/privacy" 
                      className={`text-sm transition-colors ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-white' 
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/contact" 
                      className={`text-sm transition-colors ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-white' 
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              {/* Features */}
              <div>
                <h3 className={`text-sm font-semibold mb-4 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Features</h3>
                <ul className="space-y-3">
                  <li>
                    <a 
                      href="/login" 
                      className={`text-sm transition-colors ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-white' 
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      Prayer Times
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/login" 
                      className={`text-sm transition-colors ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-white' 
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      Quran
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/login" 
                      className={`text-sm transition-colors ${
                        isDarkMode 
                          ? 'text-gray-400 hover:text-white' 
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      Deen Helpers
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </div>
  );
};

export default PublicLayout; 