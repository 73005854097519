import { useState, useEffect, useRef, useMemo } from 'react';
import { useSettings } from '../../../../contexts/SettingsContext';
import { getAdhanFiles } from '../../../../utils/adhanUtils';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../../firebase';

export const useAdhanSettings = () => {
  const { settings, updateSettings } = useSettings();
  const [adhanFiles, setAdhanFiles] = useState([]);
  const [selectedAdhan, setSelectedAdhan] = useState(settings.selectedAdhan?.id || '');
  const [isPlayingAdhan, setIsPlayingAdhan] = useState(false);
  const adhanAudioRef = useRef(new Audio());

  useEffect(() => {
    const loadAdhanFiles = async () => {
      const files = await getAdhanFiles();
      setAdhanFiles(files);
      
      if (settings.selectedAdhan?.id && !selectedAdhan) {
        setSelectedAdhan(settings.selectedAdhan.id);
      }
    };
    loadAdhanFiles();
  }, [settings.selectedAdhan, selectedAdhan]);

  useEffect(() => {
    const cleanup = () => {
      if (adhanAudioRef.current) {
        adhanAudioRef.current.pause();
        adhanAudioRef.current.currentTime = 0;
        setIsPlayingAdhan(false);
      }
    };

    return cleanup;
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && adhanAudioRef.current) {
        adhanAudioRef.current.pause();
        adhanAudioRef.current.currentTime = 0;
        setIsPlayingAdhan(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('popstate', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('popstate', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (settings.selectedAdhan?.id) {
      setSelectedAdhan(settings.selectedAdhan.id);
    }
  }, [settings.selectedAdhan]);

  const cleanupAudio = () => {
    if (adhanAudioRef.current) {
      adhanAudioRef.current.pause();
      adhanAudioRef.current.currentTime = 0;
      setIsPlayingAdhan(false);
    }
  };

  const handleAdhanChange = async (newAdhan) => {
    cleanupAudio();
    
    setSelectedAdhan(newAdhan);

    const selectedAdhanData = adhanFiles.find(file => file.id === newAdhan);
    if (selectedAdhanData) {
      await updateSettings({
        selectedAdhan: {
          id: selectedAdhanData.id,
          name: selectedAdhanData.name,
          regularPath: selectedAdhanData.regularPath,
          fajrPath: selectedAdhanData.fajrPath
        }
      });

      if (window.innerWidth < 768) {
        try {
          const url = await getDownloadURL(ref(storage, selectedAdhanData.regularPath));
          adhanAudioRef.current.src = url;
          await adhanAudioRef.current.play();
          setIsPlayingAdhan(true);
        } catch (error) {
          console.error("Error playing audio:", error);
        }
      }
    }
  };

  const handlePlayAdhan = async () => {
    if (isPlayingAdhan) {
      adhanAudioRef.current.pause();
      adhanAudioRef.current.currentTime = 0;
      setIsPlayingAdhan(false);
      return;
    }

    const selectedAdhanData = adhanFiles.find(file => file.id === selectedAdhan);
    if (selectedAdhanData) {
      try {
        const url = await getDownloadURL(ref(storage, selectedAdhanData.regularPath));
        adhanAudioRef.current.src = url;
        await adhanAudioRef.current.play();
        setIsPlayingAdhan(true);
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  };

  const adhanOptions = useMemo(() => 
    adhanFiles.map(adhan => ({
      value: adhan.id,
      label: adhan.name
    }))
  , [adhanFiles]);

  return {
    selectedAdhan,
    adhanOptions,
    isPlayingAdhan,
    handleAdhanChange,
    handlePlayAdhan,
    settings,
    adhanAudioRef,
    setIsPlayingAdhan,
    cleanupAudio,
  };
}; 