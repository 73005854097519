import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { cachedRequest } from '../utils/apiCache';
import { fetchDateTime } from './dateTimeService';
import { fetchPrayerTimes } from './prayerService';
import { fetchDuas } from './duaService';
import { fetchHadiths } from './hadithService';
import { fetchSurahs } from './quranService';
import logger from '../utils/logger';

// Separate cache durations for different types of data
const CACHE_DURATIONS = {
  PRAYER_TIMES: 12 * 60 * 60 * 1000, // 12 hours
  SURAHS: 7 * 24 * 60 * 60 * 1000,   // 7 days
  DUAS: 24 * 60 * 60 * 1000,         // 24 hours
  HADITHS: 24 * 60 * 60 * 1000,      // 24 hours
  GOALS: 5 * 60 * 1000,              // 5 minutes
  DATE_TIME: 60 * 1000               // 1 minute
};

// Track pending requests to prevent duplicate calls
const pendingRequests = new Map();

const loadDataWithCache = async (key, loader, duration) => {
  // Check if there's a pending request
  if (pendingRequests.has(key)) {
    return pendingRequests.get(key);
  }

  // Create the promise for this request
  const promise = cachedRequest(
    key,
    loader,
    { cacheDuration: duration }
  );

  // Store the promise
  pendingRequests.set(key, promise);

  try {
    const result = await promise;
    return result;
  } finally {
    // Clean up the pending request
    pendingRequests.delete(key);
  }
};

const loadPrayerTimes = async (settings, date = null) => {
  if (!settings?.location || !settings?.coordinates) {
    logger.warn('Missing location or coordinates for prayer times');
    return { prayerTimes: {}, prayerTimesError: "Location not set" };
  }

  try {
    // Get date in YYYY-MM-DD format for the cache key
    const targetDate = date || new Date();
    const dateStr = targetDate.toISOString().split('T')[0];
    
    // Create a cache key based on the settings and date
    const cacheKey = `prayer_times_${settings.coordinates.lat}_${settings.coordinates.lon}_${settings.calculationMethod}_${dateStr}`;
    
    // Check if we should force fresh data
    const shouldForceFresh = () => {
      try {
        // Only apply force fresh logic for today's prayer times
        if (date) return false;
        
        // Try to get cached data from localStorage
        const cachedData = localStorage.getItem(cacheKey);
        if (!cachedData) return true;
        
        const { timestamp } = JSON.parse(cachedData);
        const now = new Date();
        const cachedTime = new Date(timestamp);
        
        // Force refresh if:
        // 1. Cached data is from a different day
        if (cachedTime.toDateString() !== now.toDateString()) return true;
        
        // 2. It's after Isha prayer (use a helper function if available)
        if (typeof window !== 'undefined' && window.haveAllPrayersPassed) {
          const cachedPrayerTimes = JSON.parse(cachedData).data;
          return window.haveAllPrayersPassed(cachedPrayerTimes, now, settings.tunedTimes);
        }
        
        return false;
      } catch (error) {
        logger.error("Error checking if should force fresh prayer times:", error);
        return true;
      }
    };
    
    const forceFresh = shouldForceFresh();

    const prayerTimes = await loadDataWithCache(
      cacheKey,
      () => fetchPrayerTimes({
        location: settings.location,
        coordinates: settings.coordinates,
        calculationMethod: settings.calculationMethod,
        timezone: settings.timezone,
        tunedTimes: settings.tunedTimes,
        forceFresh
      }, date),
      CACHE_DURATIONS.PRAYER_TIMES
    );
    
    // Store in localStorage for easier access
    localStorage.setItem(cacheKey, JSON.stringify({
      data: prayerTimes,
      timestamp: Date.now()
    }));

    return { prayerTimes, prayerTimesError: null };
  } catch (error) {
    logger.error("Error fetching prayer times:", error);
    return { prayerTimes: {}, prayerTimesError: "Error fetching prayer times" };
  }
};

const loadTomorrowPrayerTimes = async (settings) => {
  // Create a date object for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  return loadPrayerTimes(settings, tomorrow);
};

export const fetchDashboardData = async (user, settings, onUpdate) => {
  if (!user) return null;
  
  const today = new Date().toISOString().split('T')[0];
  const baseKey = `dashboard_${user?.uid || 'anonymous'}_${today}`;
  
  try {
    let dashboard = {};

    // Load date/time first as it's fastest and most critical
    const dateTime = await loadDataWithCache(
      `${baseKey}_datetime`,
      fetchDateTime,
      CACHE_DURATIONS.DATE_TIME
    );
    
    dashboard = { dateTime };
    if (onUpdate) onUpdate(dashboard);

    // Load today's prayer times
    const { prayerTimes, prayerTimesError } = await loadPrayerTimes(settings);
    
    // Load tomorrow's prayer times
    const { prayerTimes: tomorrowPrayerTimes, prayerTimesError: tomorrowPrayerTimesError } = 
      await loadTomorrowPrayerTimes(settings);
    
    dashboard = {
      ...dashboard,
      prayerTimes,
      prayerTimesError,
      tomorrowPrayerTimes,
      tomorrowPrayerTimesError,
      location: settings.location,
      coordinates: settings.coordinates,
      adhkarCompleted: Object.keys(prayerTimes).reduce((acc, prayer) => {
        acc[prayer] = false;
        return acc;
      }, {})
    };
    if (onUpdate) onUpdate(dashboard);

    // Load non-critical data in parallel
    const [surahs, duas, hadiths, goalsSnap] = await Promise.allSettled([
      loadDataWithCache(
        `${baseKey}_surahs`,
        fetchSurahs,
        CACHE_DURATIONS.SURAHS
      ),
      loadDataWithCache(
        `${baseKey}_duas`,
        fetchDuas,
        CACHE_DURATIONS.DUAS
      ),
      loadDataWithCache(
        `${baseKey}_hadiths`,
        fetchHadiths,
        CACHE_DURATIONS.HADITHS
      ),
      loadDataWithCache(
        `${baseKey}_goals_${user.uid}`,
        () => getDoc(doc(db, 'userGoals', user.uid)),
        CACHE_DURATIONS.GOALS
      )
    ]);

    // Update dashboard with additional data
    dashboard = {
      ...dashboard,
      surahs: surahs.status === 'fulfilled' ? surahs.value?.chapters || [] : [],
      duas: duas.status === 'fulfilled' ? duas.value : [],
      currentDuaIndex: duas.status === 'fulfilled' ? Math.floor(Math.random() * duas.value.length) : 0,
      hadiths: hadiths.status === 'fulfilled' ? hadiths.value : [],
      currentHadithIndex: hadiths.status === 'fulfilled' ? Math.floor(Math.random() * hadiths.value.length) : 0,
      goals: goalsSnap.status === 'fulfilled' && goalsSnap.value.exists() 
        ? goalsSnap.value.data().goals 
        : []
    };
    if (onUpdate) onUpdate(dashboard);

    return dashboard;
  } catch (error) {
    logger.error('Error fetching dashboard data:', error);
    throw error;
  }
}; 