import React, { useState, useEffect, useRef } from 'react';
import { MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const PinkMinimalistTemplate = ({ prayerTimes, dateTime, duas, currentDuaIndex, onDateChange }) => {
  const { settings } = useSettings();
  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // State to detect prayer time changes
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  // Determine the next upcoming prayer
  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  // Periodically check for exact prayer time
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          setIsPrayerTime(false);
          setCurrentPrayer(null);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  return (
    <div className="relative w-full h-screen bg-pink-50 overflow-hidden p-16">
      {/* Subtle background elements */}
      <div className="absolute top-0 right-0 w-[1000px] h-[1000px] bg-gradient-to-br from-pink-100/50 via-pink-50/30 to-transparent rounded-full blur-3xl" />
      
      {/* Main Layout */}
      <div className="relative h-full flex">
        {/* Left Column */}
        <div className="flex-1 flex flex-col justify-between pr-24 border-r border-pink-200/30">
          {/* Location and Current Time Section */}
          <div>
            <div className="flex items-center gap-2 mb-16">
              <MapPin className="w-5 h-5 text-pink-300" />
              <span className="text-pink-950 text-lg tracking-wide">
                {settings.location ? settings.location.split(',')[0].toUpperCase() : 'LOCATION'}
              </span>
            </div>
  
            <div className="mb-24">
              <p className="text-pink-400 text-sm tracking-widest mb-3">CURRENT TIME</p>
              <SimpleTimeCard
                render={({ time }) => (
                  <h1 className="text-[180px] font-extralight text-pink-950 tracking-tight leading-none">
                    {time}
                  </h1>
                )}
              />
            </div>
  
            <div>
              <SimplePrayerCountdownCard
                prayerTimes={prayerTimes}
                render={({ countdown, nextPrayer: countdownNextPrayer }) => (
                  <div>
                    <p className="text-pink-400 text-sm tracking-widest mb-3">
                      UNTIL {countdownNextPrayer.toUpperCase()}
                    </p>
                    <p className="text-5xl font-light text-pink-600 tracking-tight">
                      {countdown}
                    </p>
                  </div>
                )}
              />
            </div>
          </div>
  
          {/* Date Section */}
          <SimpleDateCard
            dateTime={dateTime}
            onDateChange={onDateChange}
            render={({ gregorian, hijri }) => (
              <div className="space-y-6">
                <div>
                  <p className="text-pink-400 text-xs tracking-widest mb-2">GREGORIAN</p>
                  <p className="text-pink-950 text-2xl font-light">{gregorian}</p>
                </div>
                <div>
                  <p className="text-pink-400 text-xs tracking-widest mb-2">HIJRI</p>
                  <p className="text-pink-950 text-2xl font-light">{hijri}</p>
                </div>
              </div>
            )}
          />
        </div>
  
        {/* Right Column */}
        <div className="w-[600px] flex flex-col justify-between pl-24">
          {/* Arabic Text */}
          <p className="text-pink-950/90 text-2xl text-right leading-relaxed" dir="rtl">
            {duas && duas[currentDuaIndex] ? duas[currentDuaIndex].arabic : 'دعاء placeholder'}
          </p>
  
          {/* Prayer Times */}
          <SimplePrayerTimesCard
            prayerTimes={prayerTimes}
            settings={settings}
            render={({ prayers }) => (
              <div className="space-y-12">
                {prayers.map(([prayer, time]) => {
                  const isCurrent = prayer === nextPrayer;
                  return (
                    <div
                      key={prayer}
                      className={`flex items-baseline justify-between ${isCurrent ? 'text-pink-600' : 'text-pink-950'}`}
                    >
                      <span className={`text-sm tracking-widest ${isCurrent ? 'text-pink-600' : 'text-pink-400'}`}>
                        {prayer.toUpperCase()}
                      </span>
                      <div className="flex-1 mx-4 h-px bg-current opacity-20 self-center" />
                      <span className={`text-3xl font-light tracking-wide ${isCurrent ? 'text-pink-600' : 'text-pink-950'}`}>
                        {time}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PinkMinimalistTemplate;