export const dashboardTemplates = {
  imageTemplates: [
    {
      id: 'adhanClock',
      name: 'Adhan Clock',
      description: 'A beautiful prayer times display with gradient overlay',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'modernAdhanClock',
      name: 'Modern Adhan Clock',
      description: 'A modern design with boxed prayer times and vertical split layout',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'modernDramatic',
      name: 'Modern Dramatic',
      description: 'A dramatic design with large countdown and frosted glass effects',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'minimalistAdhanClock',
      name: 'Minimalist Adhan Clock',
      description: 'A clean, minimalist design with large time display and elegant layout',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'horizontalAdhanClock',
      name: 'Horizontal Adhan Clock',
      description: 'A horizontal layout with large time display and prayer times bar',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'bentoBoxAdhanClock',
      name: 'Bento Box Adhan Clock',
      description: 'A modern bento box layout with card-based components',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'elegantLandscape',
      name: 'Elegant Landscape',
      description: 'A sophisticated landscape layout with elegant typography',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'modernMinimalist',
      name: 'Modern Minimalist',
      description: 'A clean and modern design with elegant typography and hover effects',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'modernGlass',
      name: 'Modern Glass',
      description: 'A modern design with glass morphism effects',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'portraitDivider',
      name: 'Portrait Photo Templates',
      description: 'Templates optimized for portrait orientation photos',
      isDivider: true
    },
    {
      id: 'portraitGallery',
      name: 'Portrait Gallery',
      description: 'An elegant dark theme with portrait photo display',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'avantGarde',
      name: 'Avant Garde',
      description: 'A bold, artistic design with dramatic typography and unconventional portrait placement',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    },
    {
      id: 'verticalHorizon',
      name: 'Vertical Horizon',
      description: 'A sophisticated dark theme with vertical prayer times and portrait display',
      supportsSlideshow: true,
      defaultSettings: {
        slideshowDuration: 60,
        maxImages: 20,
        accentColor: 'emerald'
      }
    }
  ],
  
  basicTemplates: [
    {
      id: 'modernBasic',
      name: 'Modern Basic',
      description: 'A clean and modern design with gradient background',
      supportsSlideshow: false,
      defaultSettings: {
        accentColor: 'amber'
      }
    },
    {
      id: 'modernVertical',
      name: 'Modern Vertical',
      description: 'A sleek vertical layout with gradient accents',
      supportsSlideshow: false,
      defaultSettings: {
        accentColor: 'emerald'
      }
    },
    {
      id: 'lightAndBright',
      name: 'Light and Bright',
      description: 'A bright and airy design with soft gradients',
      supportsSlideshow: false,
      defaultSettings: {
        accentColor: 'emerald'
      }
    },
    {
      id: 'pinkMinimalist',
      name: 'Pink Minimalist',
      description: 'A clean and minimalist design with a soft pink color scheme',
      supportsSlideshow: false,
      defaultSettings: {
        accentColor: 'pink'
      },
      preview: '/previews/pink-minimalist.png'
    }
  ]
}; 

export const ACCENT_COLORS = {
  emerald: {
    name: 'Emerald',
    base: 'text-emerald-500',
    light: 'text-emerald-400',
    dark: 'text-emerald-600',
    bg: 'bg-emerald-500/10',
    border: 'border-emerald-500/20'
  },
  orange: {
    name: 'Orange',
    base: 'text-orange-500',
    light: 'text-orange-400',
    dark: 'text-orange-600',
    bg: 'bg-orange-500/10',
    border: 'border-orange-500/20'
  },
  sky: {
    name: 'Sky',
    base: 'text-sky-500',
    light: 'text-sky-400',
    dark: 'text-sky-600',
    bg: 'bg-sky-500/10',
    border: 'border-sky-500/20'
  },
  rose: {
    name: 'Rose',
    base: 'text-rose-500',
    light: 'text-rose-400',
    dark: 'text-rose-600',
    bg: 'bg-rose-500/10',
    border: 'border-rose-500/20'
  },
  pink: {
    name: 'Pink',
    base: 'text-pink-600',
    light: 'text-pink-400',
    dark: 'text-pink-700',
    bg: 'bg-pink-500/10',
    border: 'border-pink-500/20'
  }
}; 

export const backgroundImages = [
  {
    path: 'default_backgrounds/original/image1.jpg',
    display: 'default_backgrounds/display/image1.jpg',
    thumbnail: 'default_backgrounds/thumbnails/image1.jpg',
    isDefault: true
  },
  // ... other images
]; 