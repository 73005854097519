import React from 'react';
import { Volume2, Play, X, ChevronRight } from 'lucide-react';
import Select from 'react-select';
import { useAdhanSettings } from '../hooks/useAdhanSettings';
import { MenuSection } from './MenuSection';
import { selectStyles } from '../styles';

const BUTTON_WIDTH = "w-[120px]";

export const AdhanSettings = ({ isDarkMode, onOpenReciter }) => {
  const {
    selectedAdhan,
    adhanOptions,
    isPlayingAdhan,
    handleAdhanChange,
    handlePlayAdhan,
  } = useAdhanSettings();

  const isMobile = window.innerWidth < 768;

  if (isMobile) {
    return (
      <MenuSection title="Reciter" icon={Volume2} isDarkMode={isDarkMode}>
        <button
          onClick={onOpenReciter}
          className={`w-full flex items-center justify-between px-4 py-3 ${
            isDarkMode ? 'border-gray-800' : 'border-gray-200'
          }`}
        >
          <div className="flex items-center flex-1">
            <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
              {adhanOptions.find(option => option.value === selectedAdhan)?.label || 'Select Reciter'}
            </span>
          </div>
          <ChevronRight className={`w-5 h-5 ${
            isDarkMode ? 'text-gray-600' : 'text-gray-400'
          }`} />
        </button>
      </MenuSection>
    );
  }

  return (
    <MenuSection title="Adhan Settings" icon={Volume2} isDarkMode={isDarkMode}>
      <div className="p-6 space-y-4">
        <div className="flex flex-col sm:flex-row items-stretch gap-2">
          <Select
            options={adhanOptions}
            value={adhanOptions.find(option => option.value === selectedAdhan)}
            onChange={(option) => handleAdhanChange(option.value)}
            styles={selectStyles(isDarkMode)}
            placeholder="Select Reciter"
            isSearchable={false}
          />
          <button
            onClick={handlePlayAdhan}
            disabled={!selectedAdhan}
            className={`${BUTTON_WIDTH} px-4 py-2.5 rounded-xl transition-all duration-300 ${
              isPlayingAdhan
                ? isDarkMode
                  ? 'bg-red-600/20 text-red-400 hover:bg-red-600/30'
                  : 'bg-red-600 text-white hover:bg-red-700'
                : isDarkMode
                  ? 'bg-emerald-600/20 text-emerald-400 hover:bg-emerald-600/30'
                  : 'bg-emerald-600 text-white hover:bg-emerald-700'
            } shadow-sm hover:shadow-md flex items-center justify-center gap-2`}
          >
            {isPlayingAdhan ? (
              <>
                <X size={18} />
                <span>Stop</span>
              </>
            ) : (
              <>
                <Play size={18} />
                <span>Play</span>
              </>
            )}
          </button>
        </div>
      </div>
    </MenuSection>
  );
}; 