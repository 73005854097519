import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';
import { Heart, Users, BookOpen, Sparkles, ArrowRight, Shield, Gem } from 'lucide-react';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className={isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}>
      <div className={`${
        isDarkMode 
          ? 'bg-gradient-to-b from-[#0A0A0A] via-[#0A0A0A] to-[#041E1B]' 
          : 'bg-gradient-to-b from-gray-50 via-white to-emerald-50/30'
      }`}>
        {/* Hero Section */}
        <div className="relative pt-[72px] -mt-[72px] pb-20 overflow-hidden">
          {/* Background Effects */}
          <div className="absolute inset-0 overflow-hidden">
            <div className={`absolute top-1/4 left-1/4 w-64 h-64 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float ${
              isDarkMode ? 'bg-emerald-900/20' : 'bg-emerald-200/30'
            }`} />
            <div className={`absolute bottom-1/4 right-1/4 w-96 h-96 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float-delayed ${
              isDarkMode ? 'bg-emerald-800/20' : 'bg-emerald-100/30'
            }`} />
          </div>

          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center max-w-3xl mx-auto pt-[96px] lg:pt-[192px]">
              <h1 className={`text-4xl lg:text-6xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Building the Future of{' '}
                <span className={`inline-block bg-gradient-to-r ${
                  isDarkMode 
                    ? 'from-emerald-400 to-emerald-500 text-transparent bg-clip-text' 
                    : 'from-emerald-600 to-emerald-500 text-transparent bg-clip-text'
                }`}>Islamic Technology</span>
              </h1>
              <p className={`text-xl lg:text-2xl mb-12 leading-relaxed ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Empowering Muslims worldwide to strengthen their connection with Allah through innovative technology
              </p>
            </div>
          </div>
        </div>

        {/* Mission Section */}
        <div className="py-6 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
              <div>
                <h2 className={`text-3xl lg:text-4xl font-bold mb-8 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Our Mission</h2>
                <div className="space-y-6">
                  {[
                    {
                      icon: <Heart className="w-6 h-6" />,
                      title: "Spiritual Connection",
                      description: "Making it easier for Muslims to maintain their daily prayers and spiritual practices"
                    },
                    {
                      icon: <Users className="w-6 h-6" />,
                      title: "Community Building",
                      description: "Creating a global community of Muslims supporting each other in their faith"
                    },
                    {
                      icon: <BookOpen className="w-6 h-6" />,
                      title: "Knowledge Sharing",
                      description: "Providing accessible Islamic knowledge and resources for continuous learning"
                    }
                  ].map((item) => (
                    <div key={item.title} className={`flex gap-6 p-6 rounded-2xl transition-all duration-300 ${
                      isDarkMode 
                        ? 'bg-white/5 hover:bg-white/10 border border-white/10' 
                        : 'bg-white hover:bg-gray-50 border border-gray-200'
                    }`}>
                      <div className={`flex-shrink-0 w-12 h-12 rounded-xl flex items-center justify-center ${
                        isDarkMode ? 'bg-emerald-500/10 text-emerald-400' : 'bg-emerald-50 text-emerald-600'
                      }`}>
                        {item.icon}
                      </div>
                      <div>
                        <h3 className={`text-lg font-semibold mb-2 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>{item.title}</h3>
                        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={`relative rounded-[2rem] overflow-hidden ${
                isDarkMode ? 'bg-white/5' : 'bg-white'
              }`}>
                <div className={`absolute inset-0 ${
                  isDarkMode 
                    ? 'bg-gradient-to-br from-emerald-500/10 via-transparent to-transparent' 
                    : 'bg-gradient-to-br from-emerald-50 via-transparent to-transparent'
                }`} />
                <img 
                  src="/images/empty_states/handshaking_men.svg" 
                  alt="Mission Illustration" 
                  className="relative z-10 w-full h-auto max-w-lg mx-auto p-8"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Values Section */}
        <div className="py-6 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h2 className={`text-3xl lg:text-4xl font-bold mb-6 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>Our Values</h2>
              <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                The principles that guide everything we do
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Sparkles className="w-8 h-8" />,
                  title: "Innovation",
                  description: "Pushing the boundaries of what's possible with Islamic technology while staying true to Islamic principles"
                },
                {
                  icon: <Shield className="w-8 h-8" />,
                  title: "Trust & Privacy",
                  description: "Ensuring your data is protected and your privacy is respected according to Islamic guidelines"
                },
                {
                  icon: <Heart className="w-8 h-8" />,
                  title: "Community First",
                  description: "Building features and tools based on real feedback from our Muslim community"
                }
              ].map((value) => (
                <div 
                  key={value.title}
                  className={`p-8 rounded-2xl transition-all duration-300 ${
                    isDarkMode 
                      ? 'bg-gradient-to-br from-white/10 to-white/5 hover:from-white/15 hover:to-white/10 border border-white/10' 
                      : 'bg-white hover:shadow-xl hover:shadow-emerald-100/50 border border-gray-200'
                  }`}
                >
                  <div className={`w-16 h-16 rounded-xl flex items-center justify-center mb-6 ${
                    isDarkMode ? 'bg-emerald-500/10 text-emerald-400' : 'bg-emerald-50 text-emerald-600'
                  }`}>
                    {value.icon}
                  </div>
                  <h3 className={`text-xl font-semibold mb-4 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>{value.title}</h3>
                  <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {value.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="py-6 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className={`rounded-[2.5rem] overflow-hidden relative ${
              isDarkMode 
                ? 'bg-gradient-to-br from-emerald-500/20 to-emerald-500/5' 
                : 'bg-gradient-to-br from-emerald-50 to-white'
            }`}>
              <div className="relative z-10 px-8 py-16 lg:px-16 lg:py-24 text-center">
                <h2 className={`text-3xl lg:text-4xl font-bold mb-6 ${
                  isDarkMode ? 'text-white' : 'text-gray-900'
                }`}>Ready to Start Your Journey?</h2>
                <p className={`text-xl mb-12 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Join thousands of Muslims who are already using Ummahti
                </p>
                <Link
                  to="/signup"
                  className={`inline-flex items-center px-8 py-4 rounded-full text-lg font-medium transition-all duration-300 ${
                    isDarkMode 
                      ? 'bg-white text-gray-900 hover:bg-gray-100' 
                      : 'bg-gray-900 text-white hover:bg-gray-800'
                  }`}
                >
                  Get Started
                  <ArrowRight className="ml-2 w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage; 