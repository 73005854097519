import { fetchDashboardData } from './dashboardService';
import { auth } from '../firebase';
import { adjustPrayerTimes } from '../utils/prayerTimeUtils';
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';

class IosBridge {
    constructor() {
        console.log('[DEBUG] Initializing IosBridge...');
        
        // Initialize immediately on construction
        this.lastDataSentDate = null;
        this.settings = null;
        
        // Create global window object for iOS
        window.ummahtiIosBridge = {
            getPrayerTimesAndLocation: async () => {
                try {
                    console.log('[DEBUG] Starting getPrayerTimesAndLocation...');
                    console.log('[DEBUG] Current bridge state:', {
                        hasSettings: !!this.settings,
                        settingsSnapshot: this.settings,
                        user: auth.currentUser?.uid
                    });
                    
                    // Get current user
                    const user = auth.currentUser;
                    if (!user) {
                        console.error('[ERROR] No authenticated user available');
                        throw new Error('No authenticated user available');
                    }
                    
                    // Validate settings
                    if (!this.settings) {
                        console.error('[ERROR] Settings not initialized. Current settings:', this.settings);
                        throw new Error('Settings not initialized');
                    }
                    
                    // Wait for dashboard data with proper parameters
                    console.log('[DEBUG] Fetching dashboard data with:', {
                        userId: user.uid,
                        hasSettings: !!this.settings,
                        settingsKeys: this.settings ? Object.keys(this.settings) : []
                    });
                    
                    const dashboardData = await fetchDashboardData(user, this.settings);
                    console.log('[DEBUG] Got dashboard data:', {
                        hasPrayerTimes: !!dashboardData?.prayerTimes,
                        hasTomorrowPrayerTimes: !!dashboardData?.tomorrowPrayerTimes,
                        hasCoordinates: !!dashboardData?.coordinates,
                        hasLocation: !!dashboardData?.location,
                        fullData: dashboardData
                    });
                    
                    // Validate data
                    if (!dashboardData || !dashboardData.prayerTimes || !dashboardData.prayerTimes.times) {
                        console.error('[ERROR] Invalid dashboard data:', {
                            dashboardData,
                            user: user.uid,
                            settings: this.settings
                        });
                        throw new Error('Invalid dashboard data received');
                    }

                    // If times are already adjusted, use them directly
                    const prayerTimesToUse = dashboardData.prayerTimes.isAdjusted 
                        ? dashboardData.prayerTimes.times
                        : adjustPrayerTimes(dashboardData.prayerTimes.times, this.settings.tunedTimes);
                    
                    // Same for tomorrow's times
                    const tomorrowPrayerTimesToUse = dashboardData.tomorrowPrayerTimes?.isAdjusted
                        ? dashboardData.tomorrowPrayerTimes.times
                        : dashboardData.tomorrowPrayerTimes
                            ? adjustPrayerTimes(dashboardData.tomorrowPrayerTimes.times, this.settings.tunedTimes)
                            : null;
                    
                    // Format data exactly as iOS expects
                    const bridgeData = {
                        prayerTimes: {
                            fajr: prayerTimesToUse.Fajr,
                            dhuhr: prayerTimesToUse.Dhuhr,
                            asr: prayerTimesToUse.Asr,
                            maghrib: prayerTimesToUse.Maghrib,
                            isha: prayerTimesToUse.Isha
                        },
                        tomorrowPrayerTimes: tomorrowPrayerTimesToUse ? {
                            fajr: tomorrowPrayerTimesToUse.Fajr,
                            dhuhr: tomorrowPrayerTimesToUse.Dhuhr,
                            asr: tomorrowPrayerTimesToUse.Asr,
                            maghrib: tomorrowPrayerTimesToUse.Maghrib,
                            isha: tomorrowPrayerTimesToUse.Isha
                        } : null,
                        location: {
                            latitude: Number(dashboardData.coordinates?.latitude) || 0,
                            longitude: Number(dashboardData.coordinates?.longitude) || 0,
                            cityName: dashboardData.location || ''
                        },
                        timestamp: new Date().toISOString(),
                        // Include tuned times for iOS app to use directly
                        tunedTimes: this.settings.tunedTimes || {}
                    };
                    
                    // Log the data we're about to send
                    console.log('[DEBUG] Sending bridge data:', {
                        prayerTimes: bridgeData.prayerTimes,
                        tomorrowPrayerTimes: bridgeData.tomorrowPrayerTimes,
                        location: bridgeData.location,
                        tunedTimes: bridgeData.tunedTimes,
                        timestamp: bridgeData.timestamp
                    });
                    
                    // Update last sent date
                    this.lastDataSentDate = new Date().toDateString();
                    
                    // Send to iOS
                    if (window.webkit?.messageHandlers?.prayerTimesHandler) {
                        window.webkit.messageHandlers.prayerTimesHandler.postMessage(bridgeData);
                        console.log('[DEBUG] Successfully sent data to iOS');
                    } else {
                        console.error('[ERROR] iOS message handler not available:', {
                            webkit: !!window.webkit,
                            messageHandlers: !!window.webkit?.messageHandlers,
                            prayerTimesHandler: !!window.webkit?.messageHandlers?.prayerTimesHandler
                        });
                        throw new Error('iOS message handler not available');
                    }
                    
                    return bridgeData;
                } catch (error) {
                    console.error('[ERROR] Error in getPrayerTimesAndLocation:', {
                        error,
                        message: error.message,
                        stack: error.stack
                    });
                    throw error;
                }
            },
            notifySettingsChanged: async () => {
                console.log('[DEBUG] Settings changed, refreshing prayer times...');
                return window.ummahtiIosBridge.getPrayerTimesAndLocation();
            },
            
            // New adhan methods
            downloadAdhan: (fileName, fileURL) => {
                console.log('[DEBUG] Downloading adhan file:', fileName, 'from URL:', fileURL);
                if (window.webkit?.messageHandlers?.adhanHandler) {
                    window.webkit.messageHandlers.adhanHandler.postMessage({
                        action: 'download',
                        fileName: fileName,
                        fileURL: fileURL
                    });
                    return true;
                } else {
                    console.error('[ERROR] adhanHandler not available');
                    return false;
                }
            },
            
            playAdhan: (fileName, fileURL = null) => {
                console.log('[DEBUG] Playing adhan:', fileName);
                if (window.webkit?.messageHandlers?.adhanHandler) {
                    window.webkit.messageHandlers.adhanHandler.postMessage({
                        action: 'play',
                        fileName: fileName,
                        fileURL: fileURL // Optional fallback URL if file needs to be downloaded
                    });
                    return true;
                } else {
                    console.error('[ERROR] adhanHandler not available');
                    return false;
                }
            },
            
            stopAdhan: () => {
                console.log('[DEBUG] Stopping adhan playback');
                if (window.webkit?.messageHandlers?.adhanHandler) {
                    window.webkit.messageHandlers.adhanHandler.postMessage({
                        action: 'stop'
                    });
                    return true;
                } else {
                    console.error('[ERROR] adhanHandler not available');
                    return false;
                }
            },
            
            scheduleAdhan: (prayerName, time, fileName) => {
                console.log('[DEBUG] Scheduling adhan for', prayerName, 'at', time);
                if (window.webkit?.messageHandlers?.adhanHandler) {
                    window.webkit.messageHandlers.adhanHandler.postMessage({
                        action: 'schedule',
                        prayerName: prayerName,
                        time: time, // ISO8601 formatted time string
                        fileName: fileName
                    });
                    return true;
                } else {
                    console.error('[ERROR] adhanHandler not available');
                    return false;
                }
            },
            
            cancelScheduledAdhan: (prayerName) => {
                console.log('[DEBUG] Cancelling scheduled adhan for', prayerName);
                if (window.webkit?.messageHandlers?.adhanHandler) {
                    window.webkit.messageHandlers.adhanHandler.postMessage({
                        action: 'cancelSchedule',
                        prayerName: prayerName
                    });
                    return true;
                } else {
                    console.error('[ERROR] adhanHandler not available');
                    return false;
                }
            },
            
            cancelAllScheduledAdhans: () => {
                console.log('[DEBUG] Cancelling all scheduled adhans');
                if (window.webkit?.messageHandlers?.adhanHandler) {
                    window.webkit.messageHandlers.adhanHandler.postMessage({
                        action: 'cancelAllSchedules'
                    });
                    return true;
                } else {
                    console.error('[ERROR] adhanHandler not available');
                    return false;
                }
            },
            
            // New method to update adhan settings
            updateAdhanSettings: (adhanSettings) => {
                console.log('[DEBUG] Updating adhan settings:', adhanSettings);
                
                try {
                    // Validate required parameters
                    if (!adhanSettings.regularAdhanURL || !adhanSettings.fajrAdhanURL) {
                        console.error('[ERROR] Missing required adhan URLs');
                        return false;
                    }
                    
                    // Save to localStorage for persistence
                    localStorage.setItem('adhanSettings', JSON.stringify({
                        regularAdhanURL: adhanSettings.regularAdhanURL,
                        fajrAdhanURL: adhanSettings.fajrAdhanURL,
                        timestamp: Date.now()
                    }));
                    
                    // Extract file names from URLs
                    const regularFileName = adhanSettings.regularAdhanURL.split('/').pop();
                    const fajrFileName = adhanSettings.fajrAdhanURL.split('/').pop();
                    
                    // Notify native app about new settings
                    if (window.webkit?.messageHandlers?.adhanHandler) {
                        // Send settings to native app
                        window.webkit.messageHandlers.adhanHandler.postMessage({
                            action: 'updateSettings',
                            regularAdhanURL: adhanSettings.regularAdhanURL,
                            fajrAdhanURL: adhanSettings.fajrAdhanURL,
                            regularFileName: regularFileName,
                            fajrFileName: fajrFileName
                        });
                        
                        // Trigger downloads of the adhan files
                        window.ummahtiIosBridge.downloadAdhan(regularFileName, adhanSettings.regularAdhanURL);
                        window.ummahtiIosBridge.downloadAdhan(fajrFileName, adhanSettings.fajrAdhanURL);
                        
                        console.log('[DEBUG] Adhan settings updated successfully');
                        return true;
                    } else {
                        console.log('[DEBUG] Native app not available, settings saved to localStorage only');
                        return true;
                    }
                } catch (error) {
                    console.error('[ERROR] Error updating adhan settings:', error);
                    return false;
                }
            },
            
            // New method to get adhan URLs directly
            getAdhanURLs: async () => {
                console.log('[DEBUG] Getting adhan URLs from settings');
                
                try {
                    if (!this.settings?.selectedAdhan?.regularPath || !this.settings?.selectedAdhan?.fajrPath) {
                        console.error('[ERROR] No adhan settings available');
                        return null;
                    }
                    
                    // Import Firebase storage functions if needed
                    let storageModule;
                    if (typeof getDownloadURL === 'undefined') {
                        storageModule = await import('firebase/storage');
                    }
                    
                    // Use imported functions or global ones
                    const getURL = storageModule ? storageModule.getDownloadURL : getDownloadURL;
                    const createRef = storageModule ? storageModule.ref : ref;
                    
                    // Get download URLs for both regular and Fajr adhan files
                    const regularURL = await getURL(createRef(storage, this.settings.selectedAdhan.regularPath));
                    const fajrURL = await getURL(createRef(storage, this.settings.selectedAdhan.fajrPath));
                    
                    const result = {
                        regularAdhanURL: regularURL,
                        fajrAdhanURL: fajrURL,
                        regularPath: this.settings.selectedAdhan.regularPath,
                        fajrPath: this.settings.selectedAdhan.fajrPath,
                        adhanName: this.settings.selectedAdhan.name
                    };
                    
                    console.log('[DEBUG] Got adhan URLs:', result);
                    return result;
                } catch (error) {
                    console.error('[ERROR] Error getting adhan URLs:', error);
                    return null;
                }
            }
        };

        // Add debug functions to window
        window.getDashboardData = async () => {
            try {
                console.log('[DEBUG] getDashboardData called');
                const user = auth.currentUser;
                if (!user) {
                    console.error('[ERROR] No authenticated user for getDashboardData');
                    throw new Error('No authenticated user available');
                }
                console.log('[DEBUG] Fetching dashboard data with settings:', this.settings);
                return await fetchDashboardData(user, this.settings);
            } catch (error) {
                console.error('[DEBUG] Error getting dashboard data:', {
                    error,
                    message: error.message,
                    stack: error.stack
                });
                throw error;
            }
        };

        window.debugBridge = {
            getSettings: () => {
                console.log('[DEBUG] Current settings:', this.settings);
                return this.settings;
            },
            getUser: () => {
                console.log('[DEBUG] Current user:', auth.currentUser);
                return auth.currentUser;
            },
            getBridgeState: () => {
                const state = {
                    lastDataSentDate: this.lastDataSentDate,
                    hasSettings: !!this.settings,
                    settings: this.settings,
                    user: auth.currentUser,
                    bridgeExists: !!window.ummahtiIosBridge,
                    methods: window.ummahtiIosBridge ? Object.keys(window.ummahtiIosBridge) : [],
                    webkitExists: !!window.webkit,
                    messageHandlersExist: !!window.webkit?.messageHandlers,
                    availableHandlers: window.webkit?.messageHandlers ? Object.keys(window.webkit.messageHandlers) : []
                };
                console.log('[DEBUG] Current bridge state:', state);
                return state;
            }
        };

        // Log bridge initialization state
        const initState = {
            exists: !!window.ummahtiIosBridge,
            methods: window.ummahtiIosBridge ? Object.keys(window.ummahtiIosBridge) : [],
            webkitExists: !!window.webkit,
            messageHandlersExist: !!window.webkit?.messageHandlers,
            availableHandlers: window.webkit?.messageHandlers ? Object.keys(window.webkit.messageHandlers) : []
        };
        console.log('[DEBUG] Bridge initialized:', initState);

        // Set up event handler for adhan events from native code
        window.handleAdhanEvent = function(eventData) {
            console.log('[DEBUG] Received adhan event:', eventData);
            
            // Extract event type and data
            const { event, data } = eventData;
            
            // Create and dispatch a custom event
            const customEvent = new CustomEvent('adhan-' + event, {
                detail: data
            });
            
            // Dispatch the event
            window.dispatchEvent(customEvent);
        };
    }

    updateSettings(settings) {
        console.log('[DEBUG] Updating bridge settings:', {
            oldSettings: this.settings,
            newSettings: settings,
            hasNewSettings: !!settings
        });
        this.settings = settings;
        
        // If settings include adhan settings, update them in the native app
        if (settings?.selectedAdhan?.regularPath && settings?.selectedAdhan?.fajrPath) {
            // Use our getAdhanURLs method for consistency
            const updateAdhanSettings = async () => {
                try {
                    // Get adhan URLs using our helper method
                    const adhanURLs = await window.ummahtiIosBridge.getAdhanURLs();
                    
                    if (adhanURLs) {
                        // Update adhan settings with the resolved URLs
                        window.ummahtiIosBridge.updateAdhanSettings({
                            regularAdhanURL: adhanURLs.regularAdhanURL,
                            fajrAdhanURL: adhanURLs.fajrAdhanURL
                        });
                    }
                } catch (error) {
                    console.error('[ERROR] Error updating adhan settings:', error);
                }
            };
            
            // Execute the async function
            updateAdhanSettings();
        }
    }

    async initializeBridge(settings) {
        console.log('[DEBUG] initializeBridge called with settings:', {
            hasSettings: !!settings,
            settingsKeys: settings ? Object.keys(settings) : []
        });
        
        // Update settings if provided
        if (settings) {
            this.updateSettings(settings);
        }
        
        // Check for saved adhan settings
        const savedAdhanSettings = localStorage.getItem('adhanSettings');
        if (savedAdhanSettings) {
            try {
                const adhanSettings = JSON.parse(savedAdhanSettings);
                // Only use if not too old (7 days)
                const isRecent = (Date.now() - adhanSettings.timestamp) < (7 * 24 * 60 * 60 * 1000);
                
                if (isRecent && adhanSettings.regularAdhanURL && adhanSettings.fajrAdhanURL) {
                    console.log('[DEBUG] Using saved adhan settings:', adhanSettings);
                    
                    // Only update if we don't already have settings with adhan info
                    if (!settings?.selectedAdhan?.regularPath) {
                        window.ummahtiIosBridge.updateAdhanSettings({
                            regularAdhanURL: adhanSettings.regularAdhanURL,
                            fajrAdhanURL: adhanSettings.fajrAdhanURL
                        });
                    }
                }
            } catch (error) {
                console.error('[ERROR] Error parsing saved adhan settings:', error);
            }
        }

        const today = new Date().toDateString();
        if (this.lastDataSentDate !== today) {
            console.log('[DEBUG] First initialization of the day, getting fresh data...');
            return window.ummahtiIosBridge.getPrayerTimesAndLocation();
        } else {
            console.log('[DEBUG] Bridge already initialized today');
            return null;
        }
    }
}

// Create instance immediately
const iosBridge = new IosBridge();

// Make sure it's globally available
window.iosBridge = iosBridge;

export default iosBridge; 