import React from 'react';
import { Book } from '@phosphor-icons/react';
import DateTimeCard from './DateTimeCard';
import PrayerTimesCard from './PrayerTimesCard';
import DailyDuaCard from './DailyDuaCard';
import DailyHadithCard from './DailyHadithCard';
import QuranProgressCard from './QuranProgressCard';
import AdhkarTrackerCard from './AdhkarTrackerCard';
import MobileGoalsCard from './MobileGoalsCard';
import { useSettings } from '../../contexts/SettingsContext';
import { useNavigate } from 'react-router-dom';

const formatLastRead = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const now = new Date();
  const diff = now - date;
  
  if (diff < 60000) return 'Just now';
  if (diff < 3600000) return `${Math.floor(diff/60000)}m ago`;
  if (diff < 86400000) return `${Math.floor(diff/3600000)}h ago`;
  return `${Math.floor(diff/86400000)}d ago`;
};

const LastReadCTA = ({ isDarkMode, lastSession, surahs, onClick }) => {
  const surah = surahs?.find(s => s.id.toString() === lastSession?.surahId);
  
  return (
    <div 
      onClick={onClick}
      className="relative overflow-hidden transition-all duration-300 cursor-pointer group"
    >
      {/* Background Image with Gradient Overlay */}
      <div className="absolute inset-0">
        <img
          src="/images/quran.jpg"
          alt=""
          className="h-full w-full object-cover transition-transform duration-700 group-hover:scale-105"
        />
        <div className={`absolute inset-0 z-10 ${
          isDarkMode
            ? 'bg-[#022c22ed]'
            : 'bg-emerald-600/90'
        }`} />
      </div>

      {/* Content */}
      <div className="relative p-6 py-12 mt-8 z-20">
        <div className="flex items-center gap-2 mb-3">
          <h3 className="text-base font-medium tracking-wide text-white">
            {lastSession?.surahId ? 'Continue Reading' : 'Start Reading'}
          </h3>
        </div>
        <div className="flex items-center gap-3">
          {lastSession?.surahId ? (
            <>
              <p className="text-lg font-medium text-emerald-300">
                {surah?.name_simple}
              </p>
              <span className="text-sm text-emerald-100/90">
                • Verse {lastSession.verseId}
              </span>
              <span className="text-xs text-emerald-200/70">
                {formatLastRead(lastSession.timestamp)}
              </span>
            </>
          ) : (
            <p className="text-lg font-medium text-emerald-300">
              Begin your Quran journey
            </p>
          )}
        </div>
        
        {/* Glassmorphism Pill */}
        <div className={`absolute top-1/2 right-12 -translate-y-1/2 px-3 py-1.5 rounded-full backdrop-blur-md flex items-center justify-center ${
          isDarkMode
            ? 'bg-white/10 border border-white/[0.05]'
            : 'bg-black/10 border border-white/[0.1]'
        }`}>
          <span className="text-xs font-medium text-white">
            {lastSession?.surahId ? 'Resume' : 'Start'}
          </span>
        </div>
      </div>
    </div>
  );
};

const MobileLayout = ({ 
  currentUser, 
  settings, 
  isDarkMode, 
  dashboardData, 
  greeting, 
  setDashboardData,
  isLoading
}) => {
  const { settings: quranSettings } = useSettings();
  const navigate = useNavigate();

  const handleToggleAdhkar = (prayer) => {
    setDashboardData(prevData => ({
      ...prevData,
      adhkarCompleted: {
        ...prevData.adhkarCompleted,
        [prayer]: !prevData.adhkarCompleted[prayer]
      }
    }));
  };

  const handleNextDua = () => {
    console.log('[MobileLayout] handleNextDua called', {
      currentIndex: dashboardData?.currentDuaIndex,
      duasLength: dashboardData?.duas?.length
    });
    
    if (!dashboardData?.duas?.length) {
      console.warn('[MobileLayout] Cannot navigate - no duas available');
      return;
    }
    
    // Create a completely new object to ensure the reference changes and forces a re-render
    const newDashboardData = {
      ...dashboardData,
      currentDuaIndex: (dashboardData.currentDuaIndex + 1) % dashboardData.duas.length
    };
    
    console.log('[MobileLayout] Setting new dua index:', newDashboardData.currentDuaIndex);
    setDashboardData(newDashboardData);
  };

  const handlePreviousDua = () => {
    console.log('[MobileLayout] handlePreviousDua called', {
      currentIndex: dashboardData?.currentDuaIndex,
      duasLength: dashboardData?.duas?.length
    });
    
    if (!dashboardData?.duas?.length) {
      console.warn('[MobileLayout] Cannot navigate - no duas available');
      return;
    }
    
    // Create a completely new object to ensure the reference changes and forces a re-render
    const newDashboardData = {
      ...dashboardData,
      currentDuaIndex: (dashboardData.currentDuaIndex - 1 + dashboardData.duas.length) % dashboardData.duas.length
    };
    
    console.log('[MobileLayout] Setting new dua index:', newDashboardData.currentDuaIndex);
    setDashboardData(newDashboardData);
  };

  const handleNextHadith = () => {
    console.log('[MobileLayout] handleNextHadith called', {
      currentIndex: dashboardData?.currentHadithIndex,
      hadithsLength: dashboardData?.hadiths?.length
    });
    
    if (!dashboardData?.hadiths?.length) {
      console.warn('[MobileLayout] Cannot navigate - no hadiths available');
      return;
    }
    
    // Create a completely new object to ensure the reference changes and forces a re-render
    const newDashboardData = {
      ...dashboardData,
      currentHadithIndex: (dashboardData.currentHadithIndex + 1) % dashboardData.hadiths.length
    };
    
    console.log('[MobileLayout] Setting new hadith index:', newDashboardData.currentHadithIndex);
    setDashboardData(newDashboardData);
  };

  const handlePreviousHadith = () => {
    console.log('[MobileLayout] handlePreviousHadith called', {
      currentIndex: dashboardData?.currentHadithIndex,
      hadithsLength: dashboardData?.hadiths?.length
    });
    
    if (!dashboardData?.hadiths?.length) {
      console.warn('[MobileLayout] Cannot navigate - no hadiths available');
      return;
    }
    
    // Create a completely new object to ensure the reference changes and forces a re-render
    const newDashboardData = {
      ...dashboardData,
      currentHadithIndex: (dashboardData.currentHadithIndex - 1 + dashboardData.hadiths.length) % dashboardData.hadiths.length
    };
    
    console.log('[MobileLayout] Setting new hadith index:', newDashboardData.currentHadithIndex);
    setDashboardData(newDashboardData);
  };

  // Add an effect to monitor changes to the dua and hadith indices
  React.useEffect(() => {
    console.log('[MobileLayout] dashboardData indices changed:', {
      currentDuaIndex: dashboardData?.currentDuaIndex,
      currentHadithIndex: dashboardData?.currentHadithIndex
    });
  }, [dashboardData?.currentDuaIndex, dashboardData?.currentHadithIndex]);

  return (
    <div className="flex flex-col min-h-full overscroll-none">
      {/* Header section - Always render since it doesn't depend on async data */}
      <div className="relative px-4 pb-20 overflow-hidden">
        <div className="absolute inset-0 top-[-100px] bottom-[-100px]">
          <img
            src="/images/headerbg.png"
            alt=""
            className="absolute inset-0 w-full h-full object-cover opacity-50"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-emerald-600/95 via-emerald-600/90 to-emerald-600/90 dark:from-emerald-600/95 dark:via-emerald-600/90 dark:to-transparent"></div>
        </div>
        <div className="relative z-10 w-full pt-6">
          <h1 className="text-lg font-light mb-1 text-white" style={{ paddingTop: 'env(safe-area-inset-top)' }}>
            {currentUser.displayName}    ٱلسَّلَامُ عَلَيْكمْ
          </h1>
          <p className="text-sm mb-12 text-white/80">
            {greeting}
          </p>
          {settings.dashboardItems.dateTime && (
            <DateTimeCard 
              dateTime={dashboardData?.dateTime} 
              prayerTimes={dashboardData?.prayerTimes}
              isLoading={isLoading}
              className="space-y-1"
              timeClassName="text-4xl font-medium text-white"
              untilClassName="text-md text-gray-300"
              dateClassName="text-sm text-white/80"
              dividerClassName="text-white/40"
            />
          )}
        </div>
      </div>
      
      {/* Main content section */}
      <div className={`flex-1 -mt-16 rounded-[64px] px-4 pt-8 relative z-30 overflow-hidden backdrop-blur-md ${
        isDarkMode ? 'bg-[#111111]/80' : 'bg-white/80'
      }`}>
        <div className="absolute inset-0">
          <div className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-gradient-to-b from-emerald-600/10 via-[#111111]/90 to-emerald-900/10'
              : 'bg-gradient-to-b from-emerald-50/90 via-white/95 to-emerald-50/90'
          }`} />
          
          <div className={`absolute top-0 left-1/3 w-64 h-64 rounded-full blur-3xl -translate-x-1/2 ${
            isDarkMode
              ? 'bg-emerald-500/5'
              : 'bg-emerald-200/30'
          }`} />
          <div className={`absolute bottom-0 right-1/3 w-64 h-64 rounded-full blur-3xl translate-x-1/2 ${
            isDarkMode
              ? 'bg-emerald-500/5'
              : 'bg-emerald-100/40'
          }`} />
        </div>

        <div className="space-y-8 pb-8 md:pb-16 relative z-10">
          {/* Prayer Times Section */}
          {settings.dashboardItems.prayerTimes && (
            <div className="relative">
              <h2 className={`text-lg font-medium mb-4 pl-4 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Prayer Times
              </h2>
              <div className="relative">
                <PrayerTimesCard 
                  prayerTimes={dashboardData?.prayerTimes} 
                  error={dashboardData?.prayerTimesError}
                  isLoading={isLoading}
                  hideTitle
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* LastReadCTA - Full Width */}
      <div className={`relative z-10 -mt-16 ${isDarkMode ? 'bg-[#111111]' : 'bg-white/95'}`}>
        <LastReadCTA 
          isDarkMode={isDarkMode} 
          lastSession={{
            surahId: quranSettings?.quran?.readingProgress?.currentSession?.surah,
            verseId: quranSettings?.quran?.readingProgress?.currentSession?.verse,
            timestamp: quranSettings?.quran?.readingProgress?.currentSession?.timestamp
          }}
          surahs={dashboardData?.surahs}
          onClick={() => navigate('/quran', { 
            state: { 
              surahId: quranSettings?.quran?.readingProgress?.currentSession?.surah,
              verseId: `${quranSettings?.quran?.readingProgress?.currentSession?.surah}:${quranSettings?.quran?.readingProgress?.currentSession?.verse}`
            } 
          })}
        />
      </div>

      {/* Quran Progress Section with gradient background */}
      <div className="z-30 relative px-4 pb-8 -mt-8 overflow-hidden rounded-[32px]">
        {/* Background with gradient and image */}
        <div className="absolute inset-0">
          <img
            src="/images/headerbg.png"
            alt=""
            className="absolute inset-0 w-full h-full object-cover opacity-30"
          />
          <div className="absolute inset-0 z-10 bg-gradient-to-b from-emerald-900 via-emerald-900/80 to-emerald-900/90 dark:from-[#111111] dark:via-emerald-900/50 dark:to-emerald-900/80"></div>
        </div>
        
        {/* Content */}
        {settings.dashboardItems.quranTracker && (
          <div className="mx-0 mt-8 relative z-20">
            <QuranProgressCard isMobile={true} isLoading={isLoading} />
          </div>
        )}
      </div>

      {/* Combined Goals, Dua and Hadith section */}
      <div className="relative overflow-hidden py-16 -mt-16 mb-8">
        {/* Main background gradient */}
        <div className={`absolute inset-0 ${
          isDarkMode
            ? 'bg-gradient-to-b from-[#111111] via-emerald-900/20 to-[#111111]'
            : 'bg-gradient-to-b from-gray-50 via-emerald-50 to-gray-50'
        }`} />
        
        {/* Decorative gradient orbs */}
        <div className={`absolute top-0 left-1/4 w-96 h-96 rounded-full blur-3xl -translate-x-1/2 ${
          isDarkMode
            ? 'bg-emerald-500/10'
            : 'bg-emerald-200/40'
        }`} />
        <div className={`absolute bottom-0 right-1/4 w-96 h-96 rounded-full blur-3xl translate-x-1/2 ${
          isDarkMode
            ? 'bg-emerald-500/10'
            : 'bg-emerald-100/60'
        }`} />
        
        {/* Additional light mode decorative elements */}
        {!isDarkMode && (
          <>
            <div className="absolute inset-0 bg-gradient-to-b from-white/80 via-transparent to-white/80" />
            <div className="absolute inset-0 backdrop-blur-[100px]" />
          </>
        )}
        
        {/* Content container */}
        <div className="relative px-4 space-y-8">
          {/* Goals Section */}
          {settings.dashboardItems.goals && (
            <div className="relative">
              <h2 className={`text-lg font-medium mt-8 mb-4 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Goals
              </h2>
              <MobileGoalsCard isLoading={isLoading} />
            </div>
          )}
          
          {/* Daily Dua Section */}
          {settings.dashboardItems.dailyDua && (
            <>
              {console.log('[MobileLayout] Rendering DailyDuaCard', {
                currentIndex: dashboardData?.currentDuaIndex,
                dua: dashboardData?.duas?.[dashboardData?.currentDuaIndex]
              })}
              <DailyDuaCard 
                dailyDua={dashboardData?.duas?.[dashboardData?.currentDuaIndex]} 
                onNext={handleNextDua}
                onPrevious={handlePreviousDua}
                isLoading={isLoading}
              />
            </>
          )}
          
          {/* Daily Hadith Section */}
          {settings.dashboardItems.dailyHadith && (
            <>
              {console.log('[MobileLayout] Rendering DailyHadithCard', {
                currentIndex: dashboardData?.currentHadithIndex,
                hadith: dashboardData?.hadiths?.[dashboardData?.currentHadithIndex]
              })}
              <DailyHadithCard 
                dailyHadith={dashboardData?.hadiths?.[dashboardData?.currentHadithIndex]} 
                onNext={handleNextHadith}
                onPrevious={handlePreviousHadith}
                isLoading={isLoading}
              />
            </>
          )}

          {/* Adhkar Tracker Section */}
          {settings.dashboardItems.adhkarTracker && (
            <div>
              <h2 className={`text-lg font-medium mb-4 pl-4 ${
                isDarkMode ? 'text-white' : 'text-gray-800'
              }`}>
                Adhkar Tracker
              </h2>
              <AdhkarTrackerCard 
                adhkarCompleted={dashboardData?.adhkarCompleted} 
                onToggleAdhkar={handleToggleAdhkar} 
                hideTitle
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileLayout; 