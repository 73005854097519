import React, { useRef, useState, useEffect } from 'react';
import { Clock, Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { getAdhanFiles } from '../../utils/adhanUtils';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const styles = {
  smallText: 'text-lg [@media(min-height:769px)]:text-3xl',
  dateText: 'text-sm [@media(min-height:769px)]:text-base',
  hadithText: 'text-4xl [@media(min-height:769px)]:text-6xl',
  timeText: 'text-7xl [@media(min-height:769px)]:text-[10rem]',
  countdownLabel: 'text-lg [@media(min-height:769px)]:text-xl',
  countdownText: 'text-4xl [@media(min-height:769px)]:text-5xl',
  prayerText: 'text-2xl [@media(min-height:769px)]:text-3xl'
};

const ModernBasicTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  // Use the hook
  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  return (
    <div className="h-screen w-full bg-gradient-to-br from-slate-900 to-slate-800 text-white p-8 flex">
      {/* Left Panel */}
      <div className="flex-1 flex flex-col justify-between">
        {/* Top Section */}
        <div>
          {/* Location with icon */}
          <div className="flex items-center gap-2">
            <MapPin className="w-4 h-4" />
            <h1 className={`${styles.smallText} font-light`}>
              {settings.location.split(',')[0]}
            </h1>
          </div>
          <div className="space-y-1 mt-2">
            <SimpleDateCard 
              dateTime={dateTime}
              onDateChange={onDateChange}
              render={({ gregorian, hijri }) => (
                <>
                  <p className={`${styles.dateText} text-slate-400`}>{hijri}</p>
                  <p className={styles.dateText}>{gregorian}</p>
                </>
              )}
            />
          </div>
        </div>
        
        {/* Hadith */}
        <div className="text-center flex-1 flex items-center justify-center">
          <p className={`${styles.hadithText} text-slate-400 leading-relaxed font-uthmani px-12`}>
            {duas?.[currentDuaIndex]?.arabic}
          </p>
        </div>
        
        {/* Time and Countdown Section */}
        <div className="space-y-2">
          <SimpleTimeCard 
            render={({ time }) => (
              <div className={`${styles.timeText} font-extralight`}>
                {time}
              </div>
            )}
          />
          
          <SimplePrayerCountdownCard
            prayerTimes={prayerTimes}
            render={({ countdown, nextPrayer }) => (
              <div className="space-y-1">
                <p className={`${styles.countdownLabel} text-slate-400 tracking-widest uppercase`}>
                  Until {nextPrayer}
                </p>
                <div className={`${styles.countdownText} font-light`}>
                  {countdown}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {/* Right Panel */}
      <div className="w-80 bg-white/5 backdrop-blur-lg rounded-3xl p-6 flex flex-col">
        <div className="flex-1">
          <div className="h-full flex flex-col justify-between">
            <SimplePrayerTimesCard
              prayerTimes={prayerTimes}
              settings={settings}
              render={({ prayers }) => (
                <>
                  {prayers.map(([prayer, time]) => {
                    const Icon = prayer === 'Fajr' ? Sunrise :
                               prayer === 'Maghrib' ? Sunset :
                               prayer === 'Isha' ? Moon : Sun;
                    
                    const isNext = prayer === nextPrayer;
                    
                    return (
                      <div key={prayer} className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <Icon className={`w-6 h-6 ${isNext ? 'text-white' : 'text-slate-400'}`} />
                          <span className={`${styles.prayerText} ${isNext ? 'text-white' : 'text-slate-300'}`}>
                            {prayer}
                          </span>
                        </div>
                        <span className={`${styles.prayerText} font-light ${isNext ? 'text-white' : 'text-slate-300'}`}>
                          {time}
                        </span>
                      </div>
                    );
                  })}
                </>
              )}
            />
          </div>
        </div>

        <div className="mt-8 pt-6 border-t border-white/10">
          <div className="text-center">
            <p className="text-sm text-slate-400">Next Prayer</p>
            <p className={`${styles.prayerText} mt-1`}>{nextPrayer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModernBasicTemplate; 