import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Spinner } from 'flowbite-react';
import { HiMenu } from 'react-icons/hi';
import { Book, ChevronUp } from 'lucide-react';
import LoadingAnimation from '../LoadingAnimation';
import { useDarkMode } from '../../contexts/DarkModeContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useReadingProgress } from '../../hooks/useReadingProgress';
import { useSettings } from '../../contexts/SettingsContext';
import { loadFontsForPages } from '../../utils/fontLoader';
import { useLocation } from 'react-router-dom';

// Import components
import QuranSidebar from './components/QuranSidebar';
import AudioPlayer from './components/AudioPlayer';
import MobilePlayer from './components/MobilePlayer';
import VerseDisplay from './components/VerseDisplay';
import NoteModal from './components/NoteModal';
import QuranNavigationPage from './components/QuranNavigationPage';
import QuranSettingsWrapper from '../settings/QuranSettingsWrapper';
import QuranHeader from './components/QuranHeader';
import QuranSettings from '../Quran/components/QuranSettings';
import NoteSheet from './components/NoteSheet';
import SkeletonVerse from './components/SkeletonVerse';
import ArabicOnlyDisplay from './components/ArabicOnlyDisplay';

// Import hooks
import useGaplessQuranAudio from './hooks/useGaplessQuranAudio';
import useQuranData from './hooks/useQuranData';
import useQuranNotes from './hooks/useQuranNotes';
import useQuranMemorization from './hooks/useQuranMemorization';
import useVerseReadTracking from '../../hooks/useVerseReadTracking';
import useEnhancedScroll from './hooks/useEnhancedScroll';

const logRender = (componentName, props) => {
  // Remove this function or comment it out
  // console.log(`${componentName} rendered`, props);
};

// Add debounce utility at the top level
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Add this before the Quran component
const SCROLL_RETRY_COUNT = 3;
const SCROLL_RETRY_DELAY = 500;

function Quran() {
  // Remove these console.log statements
  // console.log('Quran component rendered');

  // Core hooks and state
  const { settings, updateSettings, isInitialized: isSettingsInitialized } = useSettings();
  const { isDarkMode } = useDarkMode();
  const { markVerseAsRead, getReadingProgress } = useReadingProgress();
  const location = useLocation();
  const quranData = useQuranData();

  // All refs
  const observerTarget = useRef(null);
  const footnoteRef = useRef(null);
  const verseRefs = useRef({});
  const scrollContainerRef = useRef(null);
  const contentContainerRef = useRef(null);
  const observedVerses = useRef(new Set());
  const isObserverSetup = useRef(false);
  const topObserverTarget = useRef(null);
  const previousSurahRef = useRef(null);

  // All memoized values
  const quranSettings = useMemo(() => {
    return settings?.quran || {};
  }, [settings?.quran]);
  
  const isReadingMode = useMemo(() => {
    return quranSettings.mode === 'reading';
  }, [quranSettings.mode]);

  // All state
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 1024);
  const [activeFootnote, setActiveFootnote] = useState(null);
  const [repeatingVerse, setRepeatingVerse] = useState(null);
  const [showNavigation, setShowNavigation] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isInternalNavigation, setIsInternalNavigation] = useState(undefined);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [navigationHistory, setNavigationHistory] = useState({
    previousPage: null,
    currentPage: 'quran'
  });
  const [showNoteSheet, setShowNoteSheet] = useState(false);
  const [currentNoteVerse, setCurrentNoteVerse] = useState(null);
  const [noteText, setNoteText] = useState('');
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  // Replace useQuranAudio with useGaplessQuranAudio
  const quranAudio = useGaplessQuranAudio(quranData.selectedSurah, quranData.selectedReciter, quranData.currentVerse, quranData.setCurrentVerse);

  // Add error state for audio
  const [audioError, setAudioError] = useState(null);

  // Add centralized verse read tracking using the useVerseReadTracking hook
  const { readingState } = useMemo(() => {
    return getReadingProgress() || {};
  }, [getReadingProgress]);

  // Safely get the isRead object with null checks
  const safeIsRead = useMemo(() => {
    try {
      if (!readingState || !quranData.selectedSurah) {
        return {};
      }
      return readingState[quranData.selectedSurah] || {};
    } catch (error) {
      console.error('Error accessing reading state:', error);
      return {};
    }
  }, [readingState, quranData.selectedSurah]);

  // Use centralized tracking at the parent level with proper error handling
  useVerseReadTracking({
    containerRef: contentContainerRef,
    verses: quranData.surahContent || [],
    isRead: safeIsRead,
    onVerseRead: (verseNum) => {
      if (quranData.selectedSurah && verseNum) {
        try {
          // markVerseAsRead expects two separate parameters (surahId, verseId)
          // not a combined string like "10:32"
          markVerseAsRead(quranData.selectedSurah, verseNum);
        } catch (error) {
          console.error('Error marking verse as read:', error);
        }
      }
    }
  });

  // Wrap audio controls with error handling
  const handleTogglePlay = useCallback(() => {
    try {
      quranAudio.togglePlay();
      setAudioError(null);
    } catch (error) {
      console.error('Error toggling audio playback:', error);
      setAudioError('Unable to play audio. Please try again.');
      
      // If we have an audio context, try to resume it
      if (quranAudio.audioContext && quranAudio.audioContext.state === 'suspended') {
        quranAudio.audioContext.resume().catch(console.error);
      }
    }
  }, [quranAudio]);

  // Add enhanced scroll hook
  const { scrollToVerse, clearScrollAttempts } = useEnhancedScroll();
  const [isScrolling, setIsScrolling] = useState(false);

  // Inside Quran component, add new refs to control scroll behavior
  const mountedVerses = useRef(new Set());
  const scrollTimeoutRef = useRef(null);
  const initialAutoScrollCompleteRef = useRef(false);
  const ignoreReadingProgressUpdatesRef = useRef(false);

  // Update handleVerseScroll to use multiple scroll methods
  const handleVerseScroll = useCallback((verseIndex, options = {}) => {
    const { immediate = false } = options;
    
    if (!mountedVerses.current.has(verseIndex) || !verseRefs.current[verseIndex]) {
      console.error('Verse not mounted yet:', verseIndex);
      options.onError?.(new Error('Verse not mounted'));
      return;
    }
    
    setIsScrolling(true);
    console.log('🚀 Starting scroll execution for verse:', verseIndex);
    
    const executeScroll = () => {
      try {
        const verseElement = verseRefs.current[verseIndex];
        const container = scrollContainerRef.current;
        
        if (!container || !verseElement) {
          throw new Error('Required elements not found');
        }
        
        // Check if we should abort the scroll 
        if (!mountedVerses.current.has(verseIndex)) {
          console.error('Verse unmounted during scroll:', verseIndex);
          setIsScrolling(false);
          options.onError?.(new Error('Verse unmounted during scroll'));
          return;
        }
        
        console.log('📏 Scroll container dimensions:', {
          height: container.clientHeight,
          scrollHeight: container.scrollHeight,
          scrollTop: container.scrollTop
        });
        
        console.log('📏 Verse element position:', {
          offsetTop: verseElement.offsetTop,
          offsetHeight: verseElement.offsetHeight,
          getBoundingClientRect: JSON.stringify(verseElement.getBoundingClientRect())
        });
        
        // METHOD 1: Use scrollIntoView - more reliable for general cases
        try {
          verseElement.scrollIntoView({
            behavior: immediate ? 'auto' : 'smooth',
            block: 'start'
          });
          console.log('📜 METHOD 1: Used scrollIntoView');
        } catch (error) {
          console.error('METHOD 1 failed:', error);
          
          // METHOD 2: Manual calculation as fallback
          const containerRect = container.getBoundingClientRect();
          const verseRect = verseElement.getBoundingClientRect();
          
          // Calculate the absolute position within the scroll container
          const scrollTop = container.scrollTop + verseRect.top - containerRect.top - 100;
          
          console.log('📜 METHOD 2: Manual scroll calculation:', { 
            containerTop: containerRect.top,
            verseTop: verseRect.top,
            currentScrollTop: container.scrollTop,
            targetScrollTop: scrollTop
          });
          
          // Perform the scroll
          container.scrollTo({
            top: scrollTop,
            behavior: immediate ? 'auto' : 'smooth'
          });
        }
        
        // Reset scrolling state after animation
        const resetScrolling = () => {
          console.log('🔄 Completing scroll for verse:', verseIndex);
          setIsScrolling(false);
          options.onSuccess?.();
        };
        
        if (immediate) {
          resetScrolling();
        } else {
          if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
          }
          scrollTimeoutRef.current = setTimeout(resetScrolling, 1000);
        }
      } catch (error) {
        console.error('Scroll execution failed:', error);
        setIsScrolling(false);
        options.onError?.(error);
      }
    };
    
    // Start in the next animation frame for better timing
    requestAnimationFrame(() => {
      // Then add a slight delay to ensure layout is complete
      setTimeout(executeScroll, 50);
    });
  }, []);

  // Add new function to track verse mounting
  const handleVerseMounted = useCallback((index, element) => {
    verseRefs.current[index] = element;
    mountedVerses.current.add(index);
  }, []);

  // Replace waitForRef and waitForVerseElements with a single robust function
  const waitForVerse = useCallback((verseIndex, retryCount = 0) => {
    return new Promise((resolve, reject) => {
      const maxRetries = SCROLL_RETRY_COUNT;
      
      const checkVerse = () => {
        // Check if component is still mounted
        if (!contentContainerRef.current) {
          reject(new Error('Component unmounted'));
          return;
        }

        // Check if we have the verse ref
        if (mountedVerses.current.has(verseIndex) && verseRefs.current[verseIndex]) {
          resolve(verseRefs.current[verseIndex]);
          return;
        }

        // If we've exceeded retries, reject
        if (retryCount >= maxRetries) {
          reject(new Error(`Failed to find verse ${verseIndex} after ${maxRetries} attempts`));
          return;
        }

        // Try again after delay
        setTimeout(() => {
          checkVerse(retryCount + 1);
        }, SCROLL_RETRY_DELAY);
      };

      checkVerse();
    });
  }, []);

  // Update verse click handler
  const handleVerseClick = useCallback((index) => {
    quranData.setCurrentVerse(index);
    handleVerseScroll(index, {
      immediate: false,
      onSuccess: () => {
        if (quranAudio.isPlaying) {
          quranAudio.clearSources();
          setTimeout(() => {
            quranAudio.playVerse(index);
          }, 100);
        }
      }
    });
  }, [quranData.setCurrentVerse, quranAudio.isPlaying, handleVerseScroll]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      mountedVerses.current.clear();
      verseRefs.current = {};
    };
  }, []);

  // Update the scroll effect to use a one-time flag
  useEffect(() => {
    // Skip if we're already scrolling or if we should ignore reading progress updates
    if (quranData.loading || 
        isScrolling || 
        !quranData.surahContent?.length || 
        !quranData.selectedSurah ||
        ignoreReadingProgressUpdatesRef.current) {
      return;
    }
    
    const surahHistory = settings.quran?.readingProgress?.history?.[quranData.selectedSurah] || {};
    const verseNumbers = Object.keys(surahHistory)
      .map(num => parseInt(num))
      .filter(num => !isNaN(num));
    
    // Only scroll to the last read verse on initial load, not on subsequent read progress updates
    if (verseNumbers.length > 0 && !initialAutoScrollCompleteRef.current) {
      const lastReadVerseNum = Math.max(...verseNumbers);
      const verseIndex = quranData.surahContent.findIndex(
        verse => verse.verse_key === `${quranData.selectedSurah}:${lastReadVerseNum}`
      );
      
      if (verseIndex !== -1) {
        console.log('🎯 Attempting initial auto-scroll to verse:', verseIndex);
        
        // Set the flag to prevent further auto-scrolls
        initialAutoScrollCompleteRef.current = true;
        
        // Temporarily ignore reading progress updates during auto-scroll
        ignoreReadingProgressUpdatesRef.current = true;
        
        const attemptScroll = async (attempt = 1) => {
          try {
            console.log(`📜 Initial scroll attempt ${attempt} for verse ${verseIndex}`);
            
            // Wait for the verse to be available
            const verseElement = await waitForVerse(verseIndex);
            console.log('✅ Verse element found, executing initial scroll');
            
            // Scroll to the verse
            handleVerseScroll(verseIndex, {
              immediate: true,
              onSuccess: () => {
                console.log('✨ Initial scroll completed successfully');
                
                // Re-enable reading progress updates after a delay
                setTimeout(() => {
                  ignoreReadingProgressUpdatesRef.current = false;
                }, 5000); // 5 second delay to let the user start reading
              },
              onError: (error) => {
                console.error('❌ Initial scroll failed:', error);
                ignoreReadingProgressUpdatesRef.current = false;
                
                if (attempt < SCROLL_RETRY_COUNT) {
                  console.log(`🔄 Retrying initial scroll (attempt ${attempt + 1})`);
                  setTimeout(() => attemptScroll(attempt + 1), SCROLL_RETRY_DELAY);
                }
              }
            });
          } catch (error) {
            console.error(`❌ Failed to find verse for initial scroll (attempt ${attempt}):`, error);
            ignoreReadingProgressUpdatesRef.current = false;
            
            if (attempt < SCROLL_RETRY_COUNT) {
              console.log(`🔄 Retrying verse lookup for initial scroll (attempt ${attempt + 1})`);
              setTimeout(() => attemptScroll(attempt + 1), SCROLL_RETRY_DELAY);
            }
          }
        };
        
        // Start the scroll attempt
        attemptScroll();
      }
    }
  }, [
    quranData.loading,
    quranData.surahContent,
    quranData.selectedSurah,
    settings.quran?.readingProgress?.history,
    isScrolling,
    handleVerseScroll,
    waitForVerse
  ]);

  // Reset the initial scroll flag when surah changes
  useEffect(() => {
    console.log('🔄 Surah changed to:', quranData.selectedSurah, '- Resetting auto-scroll flags');
    
    // Reset all auto-scroll flags when surah changes
    initialAutoScrollCompleteRef.current = false;
    ignoreReadingProgressUpdatesRef.current = false;
    
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      mountedVerses.current.clear();
      verseRefs.current = {};
    };
  }, [quranData.selectedSurah]);

  // Memoize the verse intersection handler
  const handleVerseIntersection = useCallback((entries) => {
    if (!isReadingMode || !quranData.selectedSurah || !isSettingsInitialized) {
      return;
    }

    const versesToUpdate = new Set();
    const processedVerseNumbers = new Set(); // Track verse numbers being processed

    entries.forEach(entry => {
      const verseId = entry.target.getAttribute('data-verse-id');
      if (!verseId) return;

      // Use an even lower threshold for considering a verse as read (0.2 instead of 0.3)
      // This increases the chance of capturing all verses during rapid scrolling
      if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
        const [surahId, verseNum] = verseId.split(':');
        // Get the verse number as a number
        const verseNumInt = parseInt(verseNum);
        processedVerseNumbers.add(verseNumInt);
        
        // Mark the current verse as read
        if (!settings.quran?.readingProgress?.history?.[surahId]?.[verseNum] && !observedVerses.current.has(verseId)) {
          versesToUpdate.add(verseId);
          observedVerses.current.add(verseId);
          
          // Also mark up to 5 previous verses to ensure continuity (increased from 3)
          // This helps catch verses that might have been missed during fast scrolling
          for (let i = 1; i <= 5; i++) {
            if (verseNumInt - i > 0) {
              const prevVerseId = `${surahId}:${verseNumInt - i}`;
              const prevVerseNum = `${verseNumInt - i}`;
              
              // Only mark if not already marked
              if (!settings.quran?.readingProgress?.history?.[surahId]?.[prevVerseNum] && 
                  !observedVerses.current.has(prevVerseId)) {
                versesToUpdate.add(prevVerseId);
                observedVerses.current.add(prevVerseId);
                console.log(`📚 Filling in previous verse: ${prevVerseId}`);
              } else {
                // Stop filling in previous verses once we hit one that's already marked
                break;
              }
            }
          }
        }
      }
    });

    // After processing all entries, look for gaps in the sequence of verses being processed
    if (processedVerseNumbers.size > 1) {
      const surahId = quranData.selectedSurah;
      const sortedVerseNumbers = Array.from(processedVerseNumbers).sort((a, b) => a - b);
      
      for (let i = 0; i < sortedVerseNumbers.length - 1; i++) {
        const currentVerse = sortedVerseNumbers[i];
        const nextVerse = sortedVerseNumbers[i + 1];
        
        // If there's a gap and it's reasonable (less than 5 verses), fill it
        if (nextVerse - currentVerse > 1 && nextVerse - currentVerse < 5) {
          for (let j = currentVerse + 1; j < nextVerse; j++) {
            const gapVerseId = `${surahId}:${j}`;
            
            // Only mark if not already marked
            if (!settings.quran?.readingProgress?.history?.[surahId]?.[j] && 
                !observedVerses.current.has(gapVerseId)) {
              versesToUpdate.add(gapVerseId);
              observedVerses.current.add(gapVerseId);
              console.log(`🔍 Filling sequence gap for verse: ${gapVerseId}`);
            }
          }
        }
      }
    }

    // Debug log for tracking
    if (versesToUpdate.size > 0) {
      console.log(`🔖 Marking ${versesToUpdate.size} verses as read:`, Array.from(versesToUpdate));
    }

    // Only update if we have new verses to mark as read
    if (versesToUpdate.size > 0) {
      const [surahId] = Array.from(versesToUpdate)[0].split(':');
      const updatedContent = quranData.surahContent ? [...quranData.surahContent] : [];

      // Sort verses numerically for ordered processing
      const orderedVerses = Array.from(versesToUpdate)
        .map(id => {
          const [sid, vnum] = id.split(':');
          return { id, surahId: sid, verseNum: parseInt(vnum) };
        })
        .sort((a, b) => a.verseNum - b.verseNum);

      // Mark verses in ascending order to ensure proper sequence
      orderedVerses.forEach(({ id, surahId }) => {
        try {
          markVerseAsRead(surahId, id);
          const verseIndex = updatedContent.findIndex(v => v.verse_key === id);
          if (verseIndex !== -1) {
            updatedContent[verseIndex] = { ...updatedContent[verseIndex] };
          }
        } catch (error) {
          console.error(`Error marking verse ${id} as read:`, error);
        }
      });

      // Only update content if we have changes
      if (updatedContent.length > 0) {
        quranData.setSurahContent(updatedContent);
      }
    }
  }, [
    isReadingMode,
    quranData.selectedSurah,
    isSettingsInitialized,
    settings.quran?.readingProgress?.history,
    markVerseAsRead,
    quranData.surahContent,
    quranData.setSurahContent
  ]);

  // Setup unified intersection observer with cleanup
  useEffect(() => {
    if (!quranData.surahContent || !isReadingMode || !quranData.selectedSurah || !contentContainerRef.current) {
      return;
    }

    // Reset observer setup state when surah changes
    if (quranData.selectedSurah !== previousSurahRef.current) {
      isObserverSetup.current = false;
      observedVerses.current = new Set();
      previousSurahRef.current = quranData.selectedSurah;
    }

    // Only set up observer if not already setup for this surah
    if (isObserverSetup.current) {
      return;
    }

    isObserverSetup.current = true;
    let isObserverActive = true;
    
    // Use multiple thresholds to better catch verses during fast scrolling
    const observer = new IntersectionObserver(
      (entries) => {
        if (!isObserverActive) return;
        handleVerseIntersection(entries);
      },
      {
        threshold: [0.1, 0.2, 0.3, 0.5, 0.7], // Added more thresholds for finer granularity
        rootMargin: '150px 0px 150px 0px' // Increased vertical margins further
      }
    );

    // Debounced version of observe verses with cleanup check
    const debouncedObserveVerses = debounce(() => {
      if (!isObserverActive || !contentContainerRef.current) return;
      
      const verseElements = contentContainerRef.current.querySelectorAll('[data-verse-id]');
      
      // Count of newly observed elements for debug
      let newlyObserved = 0;
      
      verseElements.forEach(element => {
        if (!element.dataset.isObserved) {
          observer.observe(element);
          element.dataset.isObserved = 'true';
          newlyObserved++;
        }
      });
      
      if (newlyObserved > 0) {
        console.log(`👁️ Observing ${newlyObserved} new verse elements`);
      }
    }, 25); // Ultra-reduced debounce time (25ms instead of 50ms) for faster response

    // Initial observation
    debouncedObserveVerses();

    // Setup mutation observer with debouncing and cleanup check
    const mutationObserver = new MutationObserver((mutations) => {
      if (!isObserverActive) return;
      
      const hasNewVerses = mutations.some(mutation => 
        Array.from(mutation.addedNodes).some(node => 
          node.nodeType === 1 && (
            node.hasAttribute('data-verse-id') || 
            node.querySelector('[data-verse-id]')
          )
        )
      );

      if (hasNewVerses) {
        debouncedObserveVerses();
      }
    });

    if (contentContainerRef.current) {
      mutationObserver.observe(contentContainerRef.current, {
        childList: true,
        subtree: true
      });
    }

    // Add a scroll event listener for more reliable verse tracking
    const handleScroll = debounce(() => {
      if (!isObserverActive || !contentContainerRef.current) return;
      
      // Force re-calculation of intersections on scroll
      const verseElements = contentContainerRef.current.querySelectorAll('[data-verse-id]');
      
      // Only process elements that are likely to be in or near viewport to improve performance
      const viewportTop = window.scrollY;
      const viewportBottom = viewportTop + window.innerHeight;
      const viewportBuffer = 300; // Check elements within 300px of viewport
      
      verseElements.forEach(element => {
        if (element.dataset.isObserved === 'true') {
          const rect = element.getBoundingClientRect();
          const elementTop = rect.top + viewportTop;
          const elementBottom = rect.bottom + viewportTop;
          
          // Only recalculate for elements near the viewport
          if (elementBottom + viewportBuffer >= viewportTop && elementTop - viewportBuffer <= viewportBottom) {
            // This forces the browser to recalculate the element's position
            observer.unobserve(element);
            observer.observe(element);
          }
        }
      });
      
      // Also check for any gaps during scroll
      // This helps catch verses that may have been missed
      const allVerseIds = Array.from(verseElements)
        .map(el => el.getAttribute('data-verse-id'))
        .filter(Boolean);
      
      if (allVerseIds.length > 0) {
        const [firstSurahId] = allVerseIds[0].split(':');
        
        // Find gaps in the observed verses
        allVerseIds.forEach((verseId, index) => {
          if (index === 0) return; // Skip first verse
          
          const [_, currentVerseNum] = verseId.split(':');
          const [__, prevVerseNum] = allVerseIds[index - 1].split(':');
          
          const currNum = parseInt(currentVerseNum);
          const prevNum = parseInt(prevVerseNum);
          
          // If there's a gap and gap is small (<=5), fill it
          if (currNum - prevNum > 1 && currNum - prevNum <= 5) {
            for (let i = prevNum + 1; i < currNum; i++) {
              const gapVerseId = `${firstSurahId}:${i}`;
              
              // Only mark if not already marked
              if (!settings.quran?.readingProgress?.history?.[firstSurahId]?.[i] && 
                  !observedVerses.current.has(gapVerseId)) {
                try {
                  markVerseAsRead(firstSurahId, gapVerseId);
                  observedVerses.current.add(gapVerseId);
                  console.log(`🧩 Filling scroll gap for verse: ${gapVerseId}`);
                } catch (error) {
                  console.error(`Error filling gap for verse ${gapVerseId}:`, error);
                }
              }
            }
          }
        });
      }
    }, 50); // Faster response time during scrolling

    scrollContainerRef.current?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      isObserverActive = false;
      observer.disconnect();
      mutationObserver.disconnect();
      isObserverSetup.current = false;
      scrollContainerRef.current?.removeEventListener('scroll', handleScroll);
      
      if (contentContainerRef.current) {
        contentContainerRef.current.querySelectorAll('[data-verse-id]').forEach(el => {
          delete el.dataset.isObserved;
        });
      }
    };
  }, [handleVerseIntersection, isReadingMode, quranData.selectedSurah, quranData.surahContent]);

  // Handle surah loading from navigation with proper cleanup
  useEffect(() => {
    // Remove console.log statements
    // console.log('📖 Checking navigation state:', {
    //   isSettingsInitialized,
    //   targetSurah: location.state?.surahId,
    //   targetVerse: location.state?.verseId
    // });

    if (!isSettingsInitialized) return;

    let isEffectActive = true;
    const targetSurah = location.state?.surahId;
    const targetVerse = location.state?.verseId;

    if (targetSurah && quranData.loadSurahContent && targetSurah !== quranData.selectedSurah) {
      // Remove console.log
      // console.log('🎯 Loading target surah and verse:', { targetSurah, targetVerse });
      setShowNavigation(false);
      quranData.loadSurahContent(targetSurah, targetVerse);
    }

    return () => {
      isEffectActive = false;
    };
  }, [isSettingsInitialized, location.state?.surahId, location.state?.verseId, quranData.loadSurahContent, quranData.selectedSurah]);

  // Click outside handler for footnotes
  useEffect(() => {
    // Remove console.log
    // console.log('Quran - Click outside effect');
    const handleClickOutside = (event) => {
      if (footnoteRef.current && !footnoteRef.current.contains(event.target)) {
        setActiveFootnote(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add useEffect to handle first load
  useEffect(() => {
    // Remove console.log
    // console.log('Quran - First load effect');
    setIsFirstLoad(false);
  }, []);

  // Add useEffect to reset isInternalNavigation when unmounting
  useEffect(() => {
    // Remove console.log
    // console.log('Quran - Internal navigation reset effect');
    return () => {
      setIsInternalNavigation(undefined);
    };
  }, []);

  // Load last position effect - simplified
  useEffect(() => {
    // Remove console.log
    // console.log('📖 Last position effect:', {
    //   isSettingsInitialized,
    //   hasLastSession: !!quranSettings.lastReadingSession,
    //   selectedSurah: quranData.selectedSurah,
    //   locationSurahId: location.state?.surahId
    // });

    // Skip if we have a surahId in the location state
    if (location.state?.surahId) return;
    
    if (!isSettingsInitialized || !quranSettings.lastReadingSession) return;
    
    const { surahId } = quranSettings.lastReadingSession;
    if (surahId && !quranData.selectedSurah) {
      // Get the reading progress for this surah
      const surahHistory = settings.quran?.readingProgress?.history?.[surahId] || {};
      
      // Remove console.log
      // console.log('📖 Surah history:', {
      //   surahId,
      //   history: surahHistory
      // });
      
      // Find the highest verse number that was read
      const verseNumbers = Object.keys(surahHistory)
        .map(num => parseInt(num))
        .filter(num => !isNaN(num));
      
      const highestVerse = verseNumbers.length > 0 ? Math.max(...verseNumbers) : null;

      // Format the verse ID correctly
      const formattedVerseId = highestVerse ? `${surahId}:${highestVerse}` : null;
      
      // Remove console.log
      // console.log('📍 Loading last position with highest verse:', {
      //   formattedVerseId,
      //   allVerses: verseNumbers,
      //   highestVerse
      // });

      setShowNavigation(false);
      quranData.loadSurahContent(surahId, formattedVerseId);
    }
  }, [
    isSettingsInitialized,
    quranSettings.lastReadingSession,
    quranData.selectedSurah,
    quranData.loadSurahContent,
    location.state?.surahId,
    settings.quran?.readingProgress?.history
  ]);

  // Memoize the verse intersection handler for loading previous verses
  const handleTopIntersection = useCallback((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && !quranData.loading && !quranData.isLoadingMore && quranData.currentPage > 1) {
        quranData.loadPreviousVerses();
      }
    });
  }, [quranData.loading, quranData.isLoadingMore, quranData.currentPage, quranData.loadPreviousVerses]);

  // Setup top intersection observer
  useEffect(() => {
    if (!contentContainerRef.current) return;

    const observer = new IntersectionObserver(
      handleTopIntersection,
      {
        root: null,
        threshold: 0.1,
        rootMargin: '200px'
      }
    );

    if (topObserverTarget.current) {
      observer.observe(topObserverTarget.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [handleTopIntersection]);

  // Add scroll handler to show/hide scroll to top button
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const handleScroll = () => {
      const scrollTop = scrollContainerRef.current.scrollTop;
      setShowScrollToTop(scrollTop > 500);
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, []);

  // Add scroll to top function
  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  // Other utility functions
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  // Use state to track device/orientation
  const [isMobileOrTabletPortrait, setIsMobileOrTabletPortrait] = useState(() => {
    const width = window.innerWidth;
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    return width <= 768 || (width <= 1024 && isPortrait);
  });

  // Handle resize and orientation changes
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      setIsMobileOrTabletPortrait(width <= 768 || (width <= 1024 && isPortrait));
    };

    window.addEventListener('resize', handleResize);
    
    // Some devices report orientation change as resize, but add this just in case
    window.addEventListener('orientationchange', () => {
      // Small delay to ensure dimensions have updated
      setTimeout(handleResize, 100);
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const toggleRepeat = (index) => {
    if (repeatingVerse === index) {
      setRepeatingVerse(null);
    } else {
      setRepeatingVerse(index);
    }
  };

  // Format footnotes
  const formatFootnotes = (text) => {
    if (!text) return '';
    
    return text.split(/(<sup foot_note=\d+>\d+<\/sup>)/).map((part, index) => {
      const footnoteMatch = part.match(/<sup foot_note=(\d+)>(\d+)<\/sup>/);
      if (footnoteMatch) {
        const footnoteId = footnoteMatch[1];
        const footnoteNumber = footnoteMatch[2];
        
        return (
          <span key={index} className="relative inline-block">
            <sup 
              ref={activeFootnote === footnoteId ? footnoteRef : null}
              className="text-xs text-green-600 dark:text-green-400 ml-0.5 cursor-pointer hover:text-green-800 dark:hover:text-green-300"
              onMouseEnter={() => setActiveFootnote(footnoteId)}
              onMouseLeave={() => setActiveFootnote(null)}
            >
              {footnoteNumber}
            </sup>
            {activeFootnote === footnoteId && quranData.footnotes[footnoteId] && (
              <div 
                className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-64 p-2 bg-white dark:bg-gray-700 text-sm text-gray-900 dark:text-gray-100 rounded shadow-lg border border-gray-200 dark:border-gray-600 z-50"
                onMouseEnter={() => setActiveFootnote(footnoteId)}
                onMouseLeave={() => setActiveFootnote(null)}
              >
                <div className="relative">
                  <div className="p-2">{quranData.footnotes[footnoteId]}</div>
                  <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 rotate-45 w-3 h-3 bg-white dark:bg-gray-700 border-r border-b border-gray-200 dark:border-gray-600"></div>
                </div>
              </div>
            )}
          </span>
        );
      }
      return part;
    });
  };

  // Prepare options for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#111111' : 'white',
      borderColor: state.isFocused ? '#10B981' : isDarkMode ? '#1A1A1A' : '#D1D5DB',
      boxShadow: state.isFocused ? '0 0 0 1px #10B981' : null,
      color: isDarkMode ? 'white' : 'black',
      '&:hover': {
        borderColor: '#10B981'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected 
        ? '#10B981' 
        : state.isFocused 
          ? isDarkMode ? '#1A1A1A' : '#E5E7EB'
          : isDarkMode ? '#111111' : null,
      color: state.isSelected 
        ? 'white' 
        : isDarkMode 
          ? 'white' 
          : 'black',
      padding: '8px 12px',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#0A0A0A' : 'white',
      borderColor: isDarkMode ? '#1A1A1A' : '#D1D5DB',
      borderWidth: '1px',
      borderRadius: '0.75rem',
      padding: '0.5rem',
      boxShadow: isDarkMode ? '0 10px 15px -3px rgba(0, 0, 0, 0.3)' : '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#0A0A0A' : 'white',
      borderRadius: '0.5rem',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? 'white' : 'black',
    }),
  };

  const surahOptions = quranData.surahs.map(surah => ({
    value: surah.id.toString(),
    label: `${surah.id}. ${surah.name_simple} (${quranData.getMemorizedCount(surah.id.toString())})`
  }));

  const reciterOptions = quranData.reciters.map(reciter => ({
    value: reciter.id.toString(),
    label: reciter.reciter_name
  }));

  const languageOptions = quranData.languages.map(language => ({
    value: language.iso_code,
    label: language.native_name || language.name || language.iso_code
  }));

  const translationOptions = quranData.translations
    .filter(translation => {
      const selectedLangName = quranData.languages.find(lang => lang.iso_code === quranData.selectedLanguage)?.name;
      return translation.language_name.toLowerCase() === selectedLangName?.toLowerCase() &&
        (quranData.selectedLanguage !== 'en' || translation.name.toLowerCase() !== 'transliteration');
    })
    .map(translation => ({
      value: translation.id.toString(),
      label: translation.name || `Translation ${translation.id}`
    }));

  // Settings handlers - simplified
  const handleQuranModeChange = useCallback((mode) => {
    if (!isSettingsInitialized) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, mode }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  const handleDisplayModeChange = useCallback((displayMode) => {
    if (!isSettingsInitialized) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, displayMode }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  const handleTransliterationChange = useCallback((showTransliteration) => {
    if (!isSettingsInitialized) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, showTransliteration }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  const handleLanguageChange = useCallback((option) => {
    if (!isSettingsInitialized || !option?.value) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, selectedLanguage: option.value }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  const handleFontSizeChange = useCallback((fontSize) => {
    if (!isSettingsInitialized) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, fontSize }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  const handleReciterChange = useCallback((option) => {
    if (!isSettingsInitialized || !option?.value) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, selectedReciter: option.value }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  const handleTranslationChange = useCallback((option) => {
    if (!isSettingsInitialized || !option?.value) return;
    updateSettings({
      ...settings,
      quran: { ...quranSettings, selectedTranslation: option.value }
    });
  }, [isSettingsInitialized, settings, quranSettings, updateSettings]);

  // Navigation handlers
  const handleSurahSelect = useCallback((surahId) => {
    if (!isSettingsInitialized || !surahId) return;
    
    // Get the reading progress for this surah
    const surahHistory = settings.quran?.readingProgress?.history?.[surahId] || {};
    
    // Remove console.log
    // console.log('📖 Surah history:', {
    //   surahId,
    //   history: surahHistory
    // });
    
    // Find the highest verse number that was read
    const verseNumbers = Object.keys(surahHistory)
      .map(num => parseInt(num))
      .filter(num => !isNaN(num));
    
    const highestVerse = verseNumbers.length > 0 ? Math.max(...verseNumbers) : null;

    // Format the verse ID correctly
    const formattedVerseId = highestVerse ? `${surahId}:${highestVerse}` : null;
    
    // Remove console.log
    // console.log('📍 Highest verse read:', {
    //   formattedVerseId,
    //   allVerses: verseNumbers,
    //   highestVerse
    // });
    
    quranData.setSelectedSurah(surahId);
    setShowNavigation(false);
    setNavigationHistory(prev => ({
      previousPage: prev.currentPage,
      currentPage: 'quran'
    }));
    
    // Pass the verseId to loadSurahContent to scroll to that verse
    quranData.loadSurahContent(surahId, formattedVerseId);
  }, [isSettingsInitialized, quranData.loadSurahContent, quranData.setSelectedSurah, settings.quran?.readingProgress?.history]);

  const handleHeaderClick = useCallback(() => {
    setShowNavigation(true);
    setIsInternalNavigation(true);
    setNavigationHistory(prev => ({
      previousPage: prev.currentPage,
      currentPage: 'navigation'
    }));
  }, []);

  const handleSettingsClick = useCallback(() => {
    setShowSettings(true);
    setIsSettingsOpen(true);
  }, []);

  const handleBack = useCallback(() => {
    setShowSettings(false);
    setIsSettingsOpen(false);
  }, []);

  const handleSettingsClose = useCallback(() => {
    setShowSettings(false);
    setIsSettingsOpen(false);
    setShowNavigation(settings.openedFrom === 'navigation');
  }, [settings.openedFrom]);

  // Reset handlers
  const handleResetMemorization = useCallback(() => {
    if (!isSettingsInitialized || !settings.quran) return;
    
    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        memorization: {}
      }
    });
  }, [settings, updateSettings, isSettingsInitialized]);

  const handleResetReadingProgress = useCallback(() => {
    if (!isSettingsInitialized || !settings.quran) return;

    updateSettings({
      ...settings,
      quran: {
        ...settings.quran,
        readingProgress: {
          currentSession: {
            surah: null,
            verse: null,
            timestamp: null
          },
          history: {}
        }
      }
    });
  }, [settings, updateSettings, isSettingsInitialized]);

  // Add note handling functions
  const handleNoteClick = (verseKey) => {
    console.log('Note button clicked for verse:', verseKey);
    setCurrentNoteVerse(verseKey);
    setNoteText(quranData.notes[verseKey] || '');
    setShowNoteSheet(true);
  };

  const handleSaveNote = async () => {
    if (currentNoteVerse) {
      try {
        await quranData.handleSaveNote(currentNoteVerse, noteText);
        setShowNoteSheet(false);
      } catch (error) {
        console.error("Error saving note:", error);
      }
    }
  };

  const handleNextSurah = useCallback(() => {
    if (!quranData.selectedSurah || !quranData.surahs) return;
    
    const currentSurahId = parseInt(quranData.selectedSurah);
    const nextSurah = quranData.surahs.find(s => s.id === currentSurahId + 1);
    
    if (nextSurah) {
      handleSurahSelect(nextSurah.id.toString());
      scrollContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [quranData.selectedSurah, quranData.surahs, handleSurahSelect]);

  // Render helpers
  const renderVerseContent = useCallback(() => {
    // Don't render anything until settings are initialized
    if (!isSettingsInitialized) {
      return renderLoadingState();
    }

    // Core state checks
    const isLoading = quranData.loading;
    const hasLastReadingSession = quranSettings.lastReadingSession?.surahId;
    const hasSelectedSurah = quranData.selectedSurah;
    const hasValidContent = quranData.surahContent?.length > 0;
    const hasLocationSurah = location.state?.surahId;

    // Loading states - Show skeletons during loading and transitions
    const shouldShowLoading = isLoading || (isFirstLoad && hasLastReadingSession && !hasValidContent);

    // Show loading state first - this takes precedence
    if (shouldShowLoading) {
      return (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="space-y-4"
        >
          {Array.from({ length: 15 }).map((_, index) => (
            <SkeletonVerse 
              key={index} 
              isDarkMode={isDarkMode}
              className="mx-4 my-2"
            />
          ))}
        </motion.div>
      );
    }

    // Then check if we should show navigation
    if (!hasSelectedSurah && 
        !hasLocationSurah && 
        !hasLastReadingSession) {
      setShowNavigation(true);
      return (
        <QuranNavigationPage
          surahs={quranData.surahs}
          onSurahSelect={handleSurahSelect}
          selectedSurah={quranData.selectedSurah}
          getMemorizedCount={quranData.getMemorizedCount}
          getReadingProgress={quranData.getReadingProgress}
          onSettingsClick={handleSettingsClick}
          onClose={() => setShowNavigation(false)}
          animate={isInternalNavigation}
          isSettingsOpen={isSettingsOpen}
          quranMode={settings.quran?.mode}
          lastReadingSession={settings.quran?.lastReadingSession}
        />
      );
    }

    // Finally, render content if we have it
    if (hasValidContent) {
      return (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.1 }}
            ref={scrollContainerRef} 
            className="space-y-6 md:px-8 pt-4"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className={`${quranData.displayMode === 'arabic-only' ? 'px-4' : ''}`}
            >
              {quranData.displayMode === 'arabic-only' ? (
                <ArabicOnlyDisplay
                  verses={quranData.surahContent}
                  currentVerse={quranData.currentVerse}
                  handleVerseClick={(index) => {
                    quranData.setCurrentVerse(index);
                    handleVerseScroll(index);
                  }}
                  isDarkMode={isDarkMode}
                  fontSize={settings.quran?.fontSize}
                  isRead={settings.quran?.readingProgress?.history?.[quranData.selectedSurah] || {}}
                  onVerseRead={(verseNum) => markVerseAsRead(quranData.selectedSurah, `${quranData.selectedSurah}:${verseNum}`)}
                />
              ) : (
                quranData.surahContent.map((verse, index) => {
                  const verseKey = verse.verse_key;
                  const [surahId, verseNum] = verseKey.split(':');
                  const isRead = settings.quran?.readingProgress?.history?.[surahId]?.[verseNum] || false;
                  
                  return (
                    <VerseDisplay
                      key={verseKey}
                      ref={(el) => {
                        if (el) handleVerseMounted(index, el);
                      }}
                      verse={verse}
                      index={index}
                      isDarkMode={isDarkMode}
                      displayMode={quranData.displayMode}
                      currentVerse={quranData.currentVerse}
                      handleVerseClick={handleVerseClick}
                      selectedTranslation={quranData.selectedTranslation}
                      memorizedVerses={quranData.memorizedVerses}
                      handleMemorizedToggle={quranData.handleMemorizedToggle}
                      handleNoteClick={handleNoteClick}
                      repeatingVerse={repeatingVerse}
                      toggleRepeat={toggleRepeat}
                      notes={quranData.notes}
                      isRead={isRead}
                      fontSize={settings.quran?.fontSize}
                      showTransliteration={quranData.showTransliteration}
                      formatFootnotes={formatFootnotes}
                      onVerseRead={(verseNum) => markVerseAsRead(surahId, `${surahId}:${verseNum}`)}
                    />
                  );
                })
              )}
            </motion.div>

            {/* Next Surah Button */}
            {quranData.selectedSurah && quranData.surahs && parseInt(quranData.selectedSurah) < 114 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                className="flex justify-center py-8"
              >
                <motion.button
                  onClick={handleNextSurah}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`flex items-center gap-2 px-6 py-3 rounded-lg shadow-lg transition-colors ${
                    isDarkMode
                      ? 'bg-[#111111] text-emerald-400 hover:bg-[#1A1A1A] hover:text-emerald-300 ring-1 ring-white/5'
                      : 'bg-white text-emerald-600 hover:bg-gray-50 hover:text-emerald-500 ring-1 ring-black/5'
                  }`}
                >
                  <Book className="w-5 h-5" />
                  <span className="font-medium">Go to Next Surah</span>
                </motion.button>
              </motion.div>
            )}
            
            {/* Scroll to Top Button */}
            <AnimatePresence>
              {showScrollToTop && !showNavigation && (
                <motion.button
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  onClick={scrollToTop}
                  className={`fixed right-4 p-3 rounded-full shadow-lg backdrop-blur-sm transition-colors z-50 ${
                    isDarkMode
                      ? 'bg-[#111111]/80 text-emerald-400 hover:bg-[#1A1A1A]/80 hover:text-emerald-300 ring-1 ring-white/5'
                      : 'bg-white/80 text-emerald-600 hover:bg-white hover:text-emerald-500 ring-1 ring-black/5'
                  } ${window.innerWidth <= 768 ? 'bottom-24' : 'bottom-[88px]'}`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <ChevronUp className="w-5 h-5" />
                </motion.button>
              )}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      );
    }

    return null;
  }, [
    quranData.loading,
    quranData.selectedSurah,
    quranData.surahContent,
    isDarkMode,
    quranData.displayMode,
    quranData.currentVerse,
    quranData.selectedTranslation,
    quranData.memorizedVerses,
    repeatingVerse,
    quranData.notes,
    settings.quran?.readingProgress?.history,
    settings.quran?.fontSize,
    quranData.showTransliteration,
    handleVerseScroll,
    quranData.handleMemorizedToggle,
    handleNoteClick,
    toggleRepeat,
    formatFootnotes,
    isInternalNavigation,
    isSettingsOpen,
    settings.quran?.mode,
    settings.quran?.lastReadingSession,
    quranData.surahs,
    quranData.getMemorizedCount,
    quranData.getReadingProgress,
    handleSurahSelect,
    handleSettingsClick,
    location,
    showScrollToTop,
    showNavigation,
    scrollToTop,
    handleNextSurah,
    isFirstLoad,
    quranSettings.lastReadingSession
  ]);

  const renderMobileView = () => (
    <div className={`min-h-screen h-full ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}>
      {!showNavigation && (
        <QuranHeader
          surah={quranData.surahs.find(s => s.id.toString() === quranData.selectedSurah)}
          onHeaderClick={handleHeaderClick}
          onSettingsClick={handleSettingsClick}
          isDarkMode={isDarkMode}
          isNavigationOpen={showNavigation}
          isNavigationPage={showNavigation}
        />
      )}

      <div className="relative h-full">
        <motion.div
          initial={false}
          animate={{ x: showSettings ? '-15%' : 0 }}
          transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
          className={`relative h-full ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-gray-50'}`}
        >
          <AnimatePresence>
            {showNavigation && (
              <QuranNavigationPage
                surahs={quranData.surahs}
                onSurahSelect={handleSurahSelect}
                selectedSurah={quranData.selectedSurah}
                getMemorizedCount={quranData.getMemorizedCount}
                getReadingProgress={quranData.getReadingProgress}
                onSettingsClick={handleSettingsClick}
                onClose={() => setShowNavigation(false)}
                animate={isInternalNavigation}
                isSettingsOpen={isSettingsOpen}
                quranMode={settings.quran?.mode}
                lastReadingSession={settings.quran?.lastReadingSession}
              />
            )}
          </AnimatePresence>

          <div 
            ref={contentContainerRef}
            className={`h-full overflow-y-auto pb-40 ${showNavigation ? 'hidden' : 'block'}`}
          >
            <AnimatePresence mode="wait">
            {renderVerseContent()}
            </AnimatePresence>
          </div>
        </motion.div>

        <AnimatePresence>
          {showSettings && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
              className="fixed inset-0 z-50"
            >
              <QuranSettings
                show={true}
                onClose={() => setShowSettings(false)}
                onTransitionStart={() => setIsSettingsOpen(true)}
                onTransitionEnd={() => setIsSettingsOpen(false)}
                isDarkMode={isDarkMode}
                quranMode={settings.quran?.mode}
                onQuranModeChange={handleQuranModeChange}
                displayMode={quranData.displayMode}
                onDisplayModeChange={handleDisplayModeChange}
                showTransliteration={quranData.showTransliteration}
                onTransliterationChange={handleTransliterationChange}
                fontSize={settings.quran?.fontSize}
                onFontSizeChange={handleFontSizeChange}
                selectedReciter={quranData.selectedReciter}
                onReciterChange={handleReciterChange}
                selectedLanguage={quranData.selectedLanguage}
                onLanguageChange={handleLanguageChange}
                selectedTranslation={quranData.selectedTranslation}
                onTranslationChange={handleTranslationChange}
                reciterOptions={reciterOptions}
                languageOptions={languageOptions}
                translationOptions={translationOptions}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <MobilePlayer
        isDarkMode={isDarkMode}
        isPlaying={quranAudio.isPlaying}
        togglePlay={handleTogglePlay}
        handleVerseClick={handleVerseClick}
        currentVerse={quranData.currentVerse}
        repeatMode={quranData.repeatMode}
        handleRepeatClick={quranData.handleRepeatClick}
        isMuted={quranData.isMuted}
        toggleMute={quranData.toggleMute}
        volume={quranData.volume}
        handleVolumeChange={quranData.handleVolumeChange}
        selectedSurah={quranData.selectedSurah}
        surahs={quranData.surahs}
        surahContent={quranData.surahContent}
        audioProgress={quranAudio.audioProgress}
        audioDuration={quranAudio.audioDuration}
      />
    </div>
  );

  const renderDesktopView = () => (
    <div className={`flex flex-col overflow-hidden ${isDarkMode ? 'bg-[#0A0A0A]' : 'bg-white'}`}>
      <button
        className={`lg:hidden fixed z-50 ${isDarkMode ? 'bg-green-600' : 'bg-green-500'} text-white p-2 rounded-full shadow-lg`}
        onClick={toggleSidebar}
        style={{
          top: 'calc(env(safe-area-inset-top) + 2rem)',
          left: 'calc(env(safe-area-inset-left) + 1rem)'
        }}
      >
        <HiMenu className="h-6 w-6" />
      </button>

      <div className="flex flex-1 overflow-hidden">
        <QuranSidebar
          isOpen={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
          surahs={quranData.surahs}
          selectedSurah={quranData.selectedSurah}
          onSurahSelect={handleSurahSelect}
          isDarkMode={isDarkMode}
          memorizedCount={quranData.memorizedCount}
          totalVerses={quranData.totalVerses}
          memorizedPercentage={quranData.memorizedPercentage}
        />

        <div className="flex-1 overflow-hidden">
          <div ref={contentContainerRef} className="h-full overflow-y-auto pb-40">
            {renderVerseContent()}
          </div>
        </div>
      </div>

      <AudioPlayer
        isDarkMode={isDarkMode}
        isPlaying={quranAudio.isPlaying}
        togglePlay={handleTogglePlay}
        handleVerseClick={handleVerseClick}
        currentVerse={quranData.currentVerse}
        repeatMode={quranData.repeatMode}
        handleRepeatClick={quranData.handleRepeatClick}
        isMuted={quranData.isMuted}
        toggleMute={quranData.toggleMute}
        volume={quranData.volume}
        handleVolumeChange={quranData.handleVolumeChange}
        selectedSurah={quranData.selectedSurah}
        surahs={quranData.surahs}
        surahContent={quranData.surahContent}
      />
    </div>
  );

  // Render modals and error messages
  const renderModals = () => (
    <>
      <NoteModal
        showNoteModal={quranData.showNoteModal}
        setShowNoteModal={quranData.setShowNoteModal}
        noteText={quranData.noteText}
        setNoteText={quranData.setNoteText}
        handleSaveNote={quranData.handleSaveNote}
      />

      {quranData.firestoreError && (
        <div className="fixed bottom-20 right-4 m-4 p-3 bg-red-50 dark:bg-red-900/20 text-red-800 dark:text-red-200 rounded z-50">
          {quranData.firestoreError}
        </div>
      )}
    </>
  );

  // Add error message display
  const renderErrorMessage = () => {
    if (!audioError) return null;

    return (
      <div className="fixed bottom-24 left-4 right-4 bg-red-100 dark:bg-red-900/20 text-red-800 dark:text-red-200 px-4 py-2 rounded-lg shadow-lg z-50">
        {audioError}
      </div>
    );
  };

  // Add console.log before return
  // console.log('Quran - Rendering with state:', {
  //   showNavigation,
  //   showSettings,
  //   isSettingsOpen,
  //   selectedSurah: quranData.selectedSurah,
  //   currentVerse: quranData.currentVerse
  // });

  // Preload fonts for visible and upcoming verses
  useEffect(() => {
    if (!quranData.surahContent) return;

    // Get unique page numbers for current and next few verses
    const currentIndex = quranData.currentVerse;
    const pagesToLoad = quranData.surahContent
      .slice(currentIndex, currentIndex + 10) // Load next 10 verses' fonts
      .map(verse => verse.page_number)
      .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates

    loadFontsForPages(pagesToLoad);
  }, [quranData.surahContent, quranData.currentVerse]);

  // Add effect to handle initial surah selection from location state
  useEffect(() => {
    if (location.state?.surahId && isSettingsInitialized) {
      // Remove console.log
      // console.log('Loading surah from location state:', location.state.surahId);
      setShowNavigation(false);
      quranData.setSelectedSurah(location.state.surahId);
      quranData.loadSurahContent(location.state.surahId);
    }
  }, [location.state?.surahId, isSettingsInitialized]);

  // Update auto-scroll effect to run only once per surah load
  useEffect(() => {
    // Skip if auto-scroll has already been completed or we're ignoring progress updates
    if (initialAutoScrollCompleteRef.current || ignoreReadingProgressUpdatesRef.current) return;
    
    // Only proceed if we're not loading and have content to work with
    if (!quranData.loading && quranData.surahContent?.length > 0 && quranData.selectedSurah) {
      console.log('🔍 Checking for last read verse in surah', quranData.selectedSurah);
      
      const surahHistory = settings.quran?.readingProgress?.history?.[quranData.selectedSurah] || {};
      const verseNumbers = Object.keys(surahHistory)
        .map(num => parseInt(num))
        .filter(num => !isNaN(num));
      
      if (verseNumbers.length > 0) {
        const lastReadVerseNum = Math.max(...verseNumbers);
        const verseIndex = quranData.surahContent.findIndex(
          verse => verse.verse_key === `${quranData.selectedSurah}:${lastReadVerseNum}`
        );
        
        console.log('📍 Last read verse found:', `${quranData.selectedSurah}:${lastReadVerseNum}`, 'at index:', verseIndex);
        
        if (verseIndex !== -1) {
          console.log('🎯 Preparing auto-scroll to verse index:', verseIndex);
          
          // Set flag to prevent duplicate scroll attempts during the timeout
          ignoreReadingProgressUpdatesRef.current = true;
          
          // Add a small delay to ensure the content is rendered
          setTimeout(() => {
            console.log('⏲️ Executing delayed auto-scroll to verse:', verseIndex);
            
            // Execute the scroll
            handleVerseScroll(verseIndex, {
              immediate: true,
              onSuccess: () => {
                console.log('✅ Initial auto-scroll complete');
                // Set the flag AFTER successful scroll
                initialAutoScrollCompleteRef.current = true;
                // Re-enable reading progress updates after a delay
                setTimeout(() => {
                  ignoreReadingProgressUpdatesRef.current = false;
                }, 2000);
              },
              onError: () => {
                console.log('❌ Initial auto-scroll failed');
                // Re-enable reading progress updates if scroll fails
                ignoreReadingProgressUpdatesRef.current = false;
              }
            });
          }, 500);
        } else {
          // No valid verse found, mark as complete to avoid further attempts
          initialAutoScrollCompleteRef.current = true;
        }
      } else {
        // No reading history, mark as complete to avoid further attempts
        initialAutoScrollCompleteRef.current = true;
      }
    }
  }, [quranData.loading, quranData.surahContent, quranData.selectedSurah, settings.quran?.readingProgress?.history, handleVerseScroll]);

  // Add effect to handle navigation visibility after settings are initialized
  useEffect(() => {
    if (isSettingsInitialized) {
      const hasLastReadingSession = settings?.quran?.lastReadingSession?.surahId;
      const hasSelectedSurah = quranData.selectedSurah;
      
      // Show navigation if there's no last reading session AND no selected surah
      if (!hasLastReadingSession && !hasSelectedSurah) {
        setShowNavigation(true);
      }
    }
  }, [isSettingsInitialized, settings?.quran?.lastReadingSession, quranData.selectedSurah]);

  // Render functions
  const renderLoadingState = () => (
    <div className="flex items-center justify-center min-h-screen">
      <LoadingAnimation />
    </div>
  );

  // Main render
  if (!isSettingsInitialized) {
    return renderLoadingState();
  }

  // Show navigation page by default if no surah is selected
  if (!quranData.selectedSurah && !quranData.loading) {
    return (
      <div className="h-full">
        <QuranNavigationPage
          surahs={quranData.surahs}
          onSurahSelect={(surahId) => {
            setShowNavigation(false);
            quranData.setSelectedSurah(surahId);
            quranData.loadSurahContent(surahId);
          }}
          selectedSurah={quranData.selectedSurah}
          getMemorizedCount={quranData.getMemorizedCount}
          onSettingsClick={() => setShowSettings(true)}
          onClose={() => setShowNavigation(false)}
          animate={false}
          isSettingsOpen={showSettings}
          quranMode={settings?.quran?.mode}
          getReadingProgress={quranData.getReadingProgress}
          lastReadingSession={settings?.quran?.lastReadingSession}
        />
      </div>
    );
  }

  return (
    <div className="h-full">
      {isMobileOrTabletPortrait ? renderMobileView() : renderDesktopView()}
      {renderModals()}
      {renderErrorMessage()}
    </div>
  );
}

export default React.memo(Quran);