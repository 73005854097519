export const selectStyles = (isDarkMode) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: isDarkMode ? '#111111' : 'white',
    borderColor: state.isFocused ? '#10B981' : isDarkMode ? '#1A1A1A' : '#D1D5DB',
    boxShadow: state.isFocused ? '0 0 0 1px #10B981' : null,
    color: isDarkMode ? 'white' : 'black',
    height: '42px',
    borderRadius: '0.75rem',
    '&:hover': {
      borderColor: '#10B981'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected 
      ? '#10B981' 
      : state.isFocused 
        ? isDarkMode ? '#1A1A1A' : '#E5E7EB'
        : isDarkMode ? '#111111' : null,
    color: state.isSelected 
      ? 'white' 
      : isDarkMode 
        ? 'white' 
        : 'black',
    padding: '8px 12px',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: isDarkMode ? '#0A0A0A' : 'white',
    borderColor: isDarkMode ? '#1A1A1A' : '#D1D5DB',
    borderWidth: '1px',
    borderRadius: '0.75rem',
    padding: '0.5rem',
    boxShadow: isDarkMode ? '0 10px 15px -3px rgba(0, 0, 0, 0.3)' : '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: isDarkMode ? '#0A0A0A' : 'white',
    borderRadius: '0.5rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: isDarkMode ? 'white' : 'black',
  }),
}); 