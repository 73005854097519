import React, { useEffect } from 'react';
import { MobilePageTransition } from './MobilePageTransition';
import { useAdhanSettings } from '../hooks/useAdhanSettings';

export const SelectionView = ({ 
  show, 
  onClose, 
  title, 
  options, 
  selectedValue, 
  onSelect, 
  isDarkMode 
}) => {
  return (
    <MobilePageTransition
      show={show}
      onClose={onClose}
      title={title}
      isDarkMode={isDarkMode}
    >
      <div className={`rounded-xl overflow-hidden ${
        isDarkMode ? 'bg-[#111111]' : 'bg-white'
      }`}>
        {options.map((option) => (
          <button
            key={option.value}
            className={`w-full px-4 py-3 text-left border-b ${
              isDarkMode ? 'border-gray-800' : 'border-gray-200'
            } last:border-0`}
            onClick={() => onSelect(option)}
          >
            <div className="flex items-center justify-between">
              <span className={`${
                option.value === selectedValue?.value 
                  ? 'text-emerald-500' 
                  : isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {option.label}
              </span>
              {option.value === selectedValue?.value && (
                <div className="w-2 h-2 rounded-full bg-emerald-500" />
              )}
            </div>
          </button>
        ))}
      </div>
    </MobilePageTransition>
  );
}; 