import React, { useRef, useState, useEffect } from 'react';
import { Sun, Sunrise, Sunset, Moon, MapPin } from 'lucide-react';
import { useSettings } from '../../contexts/SettingsContext';
import { isExactPrayerTime } from '../../utils/prayerTimeUtils';
import SimpleTimeCard from '../simpledashboard/SimpleTimeCard';
import SimplePrayerCountdownCard from '../simpledashboard/SimplePrayerCountdownCard';
import SimplePrayerTimesCard from '../simpledashboard/SimplePrayerTimesCard';
import { ACCENT_COLORS } from '../../config/dashboardTemplates';
import SimpleDateCard from '../simpledashboard/SimpleDateCard';
import { useAdhanPlayer } from '../../hooks/useAdhanPlayer';

const ModernVerticalTemplate = ({ 
  prayerTimes,
  dateTime,
  duas,
  currentDuaIndex,
  onDateChange,
}) => {
  const { settings } = useSettings();
  const accentColor = ACCENT_COLORS[settings.simpleDashboard?.accentColor || 'emerald'];
  const [isPrayerTime, setIsPrayerTime] = useState(false);
  const [currentPrayer, setCurrentPrayer] = useState(null);
  const lastPlayedPrayerRef = useRef(null);

  const { adhanLoaded, adhanPlayed } = useAdhanPlayer(prayerTimes, settings);

  // Check for prayer times and play Adhan
  useEffect(() => {
    const timer = setInterval(() => {
      if (prayerTimes?.times && Object.keys(prayerTimes.times).length > 0) {
        const now = new Date();
        const prayerTimeCheck = isExactPrayerTime(prayerTimes.times, now);
        
        if (prayerTimeCheck.isPrayerTime && !isPrayerTime && prayerTimeCheck.currentPrayer !== lastPlayedPrayerRef.current) {
          setIsPrayerTime(true);
          setCurrentPrayer(prayerTimeCheck.currentPrayer);
          lastPlayedPrayerRef.current = prayerTimeCheck.currentPrayer;
        } else if (!prayerTimeCheck.isPrayerTime && isPrayerTime) {
          resetPrayerState();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [prayerTimes, isPrayerTime]);

  const resetPrayerState = () => {
    setIsPrayerTime(false);
    setCurrentPrayer(null);
  };

  const getNextPrayer = () => {
    if (!prayerTimes?.times || Object.keys(prayerTimes.times).length === 0) return 'Loading...';
    
    const now = new Date();
    const times = Object.entries(prayerTimes.times);
    
    for (const [prayer, time] of times) {
      if (new Date(`${now.toDateString()} ${time}`) > now) {
        return prayer;
      }
    }
    return times[0]?.[0] || 'Fajr';
  };

  const nextPrayer = getNextPrayer();

  // Add styles object at the top
  const styles = {
    smallText: 'text-lg [@media(min-height:769px)]:text-3xl',
    dateText: 'text-sm [@media(min-height:769px)]:text-base',
    hadithText: 'text-4xl [@media(min-height:769px)]:text-6xl',
    timeText: 'text-7xl [@media(min-height:769px)]:text-[10rem]',
    countdownLabel: 'text-lg [@media(min-height:769px)]:text-xl',
    countdownText: 'text-4xl [@media(min-height:769px)]:text-5xl',
    prayerText: 'text-2xl [@media(min-height:769px)]:text-3xl',
    sidebarWidth: 'w-60 [@media(min-height:769px)]:w-80',
    prayerBoxPadding: 'p-4 [@media(min-height:769px)]:p-8'
  };

  return (
    <div className="h-screen w-full bg-gradient-to-br from-slate-950 via-gray-900 to-slate-950 text-white">
      {/* Background Accents */}
      <div className="absolute inset-0 overflow-hidden">
        <div className={`absolute top-[20%] left-[45%] w-[800px] h-[800px] ${accentColor.bg} rounded-full opacity-20 blur-3xl`}></div>
        <div className={`absolute -top-40 -right-40 w-80 h-80 ${accentColor.bg} rounded-full opacity-20 blur-3xl`}></div>
      </div>

      {/* Main Layout */}
      <div className="relative h-full flex">
        {/* Content Side */}
        <div className="flex-1 flex flex-col p-8">
          {/* Top Section - Location and Date */}
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4 text-white/60" />
                <h1 className="text-base font-light text-white/60">
                  {settings.location.split(',')[0]}
                </h1>
                <div className={`h-px w-24 bg-gradient-to-r ${accentColor.base}/50 to-transparent self-end mb-2 hidden sm:block`}></div>
              </div>
            </div>

            {/* Date Display */}
            <div className="bg-white/5 backdrop-blur-sm rounded-xl p-4 border border-white/5 ml-4 shrink-0">
              <div className="flex flex-col items-center">
                <div className="flex gap-4 mb-1">
                  <p className="text-white/40 text-xs tracking-widest uppercase">Gregorian</p>
                  <p className="text-white/40 text-xs tracking-widest uppercase">Hijri</p>
                </div>
                <SimpleDateCard 
                  dateTime={dateTime}
                  onDateChange={onDateChange}
                  render={({ gregorian, hijri }) => (
                    <div className="flex items-center gap-3">
                      <p className="text-sm">{gregorian}</p>
                      <span className="text-white/20">|</span>
                      <p className="text-sm">{hijri}</p>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="grow flex flex-col">
            {/* Centered Dua */}
            <div className="grow flex items-center justify-center">
              <div className="w-full text-center px-4">
                <p className="text-4xl [@media(min-height:769px)]:text-6xl text-white/80 leading-loose font-['Decotype_Thuluth']">
                  {duas?.[currentDuaIndex]?.arabic}
                </p>
              </div>
            </div>

            {/* Bottom Time and Countdown Section */}
            <div className="flex justify-between items-end mb-8">
              {/* Current Time */}
              <div>
                <p className="text-sm tracking-widest uppercase text-white/40 mb-2">Current Time</p>
                <SimpleTimeCard 
                  render={({ time }) => (
                    <div className={`${styles.timeText} font-thin tracking-tight leading-[0.75] pb-[0.12em] bg-gradient-to-b from-white to-white/80 bg-clip-text text-transparent`}>
                      {time}
                    </div>
                  )}
                />
              </div>

              {/* Countdown */}
              <SimplePrayerCountdownCard
                prayerTimes={prayerTimes}
                render={({ countdown, nextPrayer }) => (
                  <div className="flex flex-col items-end">
                    <div className={`inline-flex items-center gap-2 px-3 py-1 ${accentColor.bg} rounded-full mb-2`}>
                      <div className={`w-1.5 h-1.5 rounded-full ${accentColor.base}`}></div>
                      <p className="text-sm tracking-widest uppercase whitespace-nowrap ${accentColor.base}">
                        Until {nextPrayer}
                      </p>
                    </div>
                    <div className={`${styles.countdownText} font-thin tracking-tight leading-[0.75] pb-[0.12em] ${accentColor.base}`}>
                      {countdown}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        {/* Prayer Times Side Panel */}
        <div className={`${styles.sidebarWidth} bg-white/5 backdrop-blur-md border-l border-white/10`}>
          <div className="h-full flex flex-col divide-y divide-white/10">
            <SimplePrayerTimesCard
              prayerTimes={prayerTimes}
              settings={settings}
              render={({ prayers }) => (
                <>
                  {prayers.map(([prayer, time]) => {
                    const Icon = prayer === 'Fajr' ? Sunrise :
                               prayer === 'Maghrib' ? Sunset :
                               prayer === 'Isha' ? Moon : Sun;
                    
                    const isNext = prayer === nextPrayer;
                    const isPast = new Date(`${new Date().toDateString()} ${time}`) < new Date();
                    
                    return (
                      <div 
                        key={prayer}
                        className={`flex-1 flex flex-col justify-center ${styles.prayerBoxPadding}
                          ${isNext ? `${accentColor.bg} relative` : ''}
                          ${isPast ? 'opacity-60' : ''}`}
                      >
                        {isNext && (
                          <div className={`absolute inset-y-0 left-0 w-1 ${accentColor.base}`}></div>
                        )}
                        <div className="flex items-center gap-2 mb-2">
                          <Icon className={`w-5 h-5 ${isNext ? accentColor.base : 'text-white'}`} />
                          <p className={`${styles.prayerText} tracking-widest uppercase ${isNext ? accentColor.base : 'text-white'}`}>
                            {prayer}
                          </p>
                        </div>
                        <p className={`${styles.prayerText} font-light ${isNext ? accentColor.base : 'text-white'}`}>
                          {time}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModernVerticalTemplate; 